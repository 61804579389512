import {
  SET_CARD_INFORMATION,
  SET_CARD_BENEFITS,
  SET_CARD_VALIDATION_RESULT,
  CLEAN_CARD_INFORMATION,
  VALIDATE_CARD_SUCCEEDED
} from 'actions/feature/card';
import {
  SET_GENCODE_CARD,
  CARD_SUBMIT_SUCCESS,
  CARD_SUBMIT_FAILURE
} from 'actions/actionTypes';
import initialState from '../initialState';

export default function card(state = initialState.card, action) {
  switch (action.type) {
    case SET_CARD_BENEFITS:
      return {
        ...state,
        benefits: action.payload,
        productId: action.meta?.productId
      };
    case SET_CARD_INFORMATION:
      return {
        ...state,
        gencodeCard: {
          value: action.payload.gencode,
          obfuscated: action.payload.obfuscated,
          productId: action.payload.productId
        }
      };
    case SET_CARD_VALIDATION_RESULT:
      return {
        ...state,
        validation: action.payload
      };
    case CLEAN_CARD_INFORMATION:
      return initialState.card;
    case SET_GENCODE_CARD:
      if (action.gencodeCard)
        return {
          ...state,
          gencodeCard: {
            value: action.gencodeCard.value,
            obfuscated: action.gencodeCard.obfuscated
          }
        };
      return state;
    case CARD_SUBMIT_SUCCESS:
      return {
        ...state,
        gencodeCard: {
          value: action.gencodeCard.value,
          obfuscated: action.gencodeCard.obfuscated
        }
      };
    case CARD_SUBMIT_FAILURE:
      return { ...state, gencodeCard: initialState.card.gencodeCard };
    default:
      return state;
  }
}
