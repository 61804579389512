import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Button } from 'components/shared/Forms';
import { Row, Col } from 'components/shared/Table';

import { event } from 'utils/analytics';

import { GA_EVENTS, GA_ACTIONS } from 'constants/googleAnalytics';
import { appInsights } from 'ApplicationInsights';

const YesNoQuestion = ({
  userAnswer,
  onUserAnswer,
  id,
  properties,
  guideWireField
}) => {
  const { t } = useTranslation();

  const selectedStyle = buttonValue => {
    const isSelected = value => userAnswer && userAnswer.value === value;
    return isSelected(buttonValue) ? 'btn-amex-selected' : '';
  };

  const onClick = buttonValue => {
    const nextGroupId =
      buttonValue === properties.valueYes
        ? properties.nextGroupIdYes
        : properties.nextGroupIdNo;
    event(GA_EVENTS.QUESTION, GA_ACTIONS.ANSWER, {
      label: `${guideWireField} - ${buttonValue}`
    });
    appInsights.trackEvent(
      {
        name:`${guideWireField} - ${buttonValue}`
      },
      {
        EventCategory: GA_EVENTS.QUESTION,
        EventAction: GA_ACTIONS.ANSWER,
        EventLabel: `${guideWireField} - ${buttonValue}`
      }
      )
    onUserAnswer(id, buttonValue, nextGroupId);
  };

  return (
    <Row>
      <Col>
        <Button
          id="btn-yesNoQuestion-Yes"
          type="button"
          className={`btn btn-outline-primary btn-lg btn-amex mr-1 ${selectedStyle(
            properties.valueYes
          )}`}
          onClick={() => onClick(properties.valueYes)}
        >
          {t('commonYes')}
        </Button>
        <Button
          id="btn-yesNoQuestion-No"
          type="button"
          className={`btn btn-outline-primary btn-lg btn-amex ${selectedStyle(
            properties.valueNo
          )}`}
          onClick={() => onClick(properties.valueNo)}
        >
          {t('commonNo')}
        </Button>
      </Col>
    </Row>
  );
};

YesNoQuestion.propTypes = {
  onUserAnswer: PropTypes.func.isRequired,
  userAnswer: PropTypes.shape({
    value: PropTypes.string.isRequired,
    questionId: PropTypes.number.isRequired
  }),
  id: PropTypes.number.isRequired,
  properties: PropTypes.shape({
    nextGroupIdYes: PropTypes.number,
    nextGroupIdNo: PropTypes.number,
    valueYes: PropTypes.string,
    valueNo: PropTypes.string
  }).isRequired,
  guideWireField: PropTypes.string
};

YesNoQuestion.defaultProps = {
  userAnswer: undefined,
  guideWireField: 'yesNoQuestion'
};

export default YesNoQuestion;
