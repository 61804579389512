import { string, ref, object } from 'yup';
import { REGEX } from 'constants/constants';
import {
  minLengthPassword,
  commonEmailValidations,
  invalidTextLabel
} from 'components/shared/CommonSchemaValidations';

export const emailValidations = {
  ...commonEmailValidations,
  newEmailConfirmation: string()
    .required(invalidTextLabel)
    .oneOf([ref('email'), ''], 'commonFromEmailNotMatchingErrorMsg')
};

export const passwordValidations = {
  password: string()
    .min(minLengthPassword, 'passwordErrorNoMinLength')
    .matches(REGEX.CONTAINS_ONE_LOWER_CASE_LETTER, 'passwordErrorNoLowercase')
    .matches(REGEX.CONTAINS_ONE_SPECIAL_CHAR, 'passwordErrorNoSymbol')
    .matches(REGEX.CONTAINS_ONE_UPPER_CASE_LETTER, 'passwordErrorNoUppercase')
    .matches(REGEX.CONTAINS_ONE_NUMBER, 'passwordErrorNoDigit')
    .required(invalidTextLabel)
};

export const emailSchemaValidations = object({ ...emailValidations });
export const passwordSchemaValidations = object({
  ...passwordValidations
});

export const schemaValidation = object({
  ...emailValidations,
  ...passwordValidations
});
