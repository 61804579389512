// TODO: technical debts
/* eslint-disable jsx-a11y/no-autofocus */
import React, { Component } from 'react';
import Moment from 'moment';
import PropTypes from 'prop-types';
import Popover from '@material-ui/core/Popover';
import {
  CALENDAR_DATETIME_TYPE,
  CALENDAR_DATETIME_ROUGHLY_TYPE,
  CALENDAR_DATETIME_SCHEDULED_TYPE,
  CALENDAR_DATETIME_ACTUAL_TYPE,
  DATE_FORMAT
} from 'constants/constants';
import { withTranslation } from 'react-i18next';
import { Row, Col } from 'components/shared/Table';
import { Button } from 'components/shared/Forms';
import { withConfig } from 'ConfigurationProvider';

export class CalendarDayComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      popOverOpen: false,
      popOverLabel: this.props.t('calendarDayPopoverQuestion'),
      day: this.props.day,
      valueTime: ''
    };

    this.dayHasMarkers = this.dayHasMarkers.bind(this);
    this.getDayMarkers = this.getDayMarkers.bind(this);
    this.compareDate = this.compareDate.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleTimeChange = this.handleTimeChange.bind(this);
    this.handleClosePopoverAndCallDaySelect = this.handleClosePopoverAndCallDaySelect.bind(
      this
    );
    this.preventPropagation = this.preventPropagation.bind(this);
  }

  getDayMarkers() {
    const items = this.props.questions.filter(this.compareDate);
    const markers = items.map(marker => (
      <i
        key={marker.id}
        className={`material-icons align-middle small-date-icon icon-calendar-color-${marker.color}`}
        data-toggle="tooltip"
        data-placement="bottom"
        title={this.props.questions.label}
      >
        fiber_manual_record
      </i>
    ));
    return markers;
  }

  dayHasMarkers() {
    const items = this.props.questions.filter(this.compareDate);
    let marker = '';
    if (items && items.length > 0) {
      marker = `circle-color-${items[0].color}`;
    }
    return marker;
  }

  popOverLabel() {
    let popOverLabel;
    if (this.props.activeQuestion.type === CALENDAR_DATETIME_TYPE) {
      popOverLabel = this.props.t('calendarDayPopoverQuestion');
    } else if (
      this.props.activeQuestion.type === CALENDAR_DATETIME_ROUGHLY_TYPE
    ) {
      popOverLabel = this.props.t('calendarDayPopoverRoughlyQuestion');
    } else if (
      this.props.activeQuestion.type === CALENDAR_DATETIME_SCHEDULED_TYPE
    ) {
      popOverLabel = this.props.t('calendarDayPopoverScheduledQuestion');
    } else if (
      this.props.activeQuestion.type === CALENDAR_DATETIME_ACTUAL_TYPE
    ) {
      popOverLabel = this.props.t('calendarDayPopoverActualQuestion');
    } else {
      popOverLabel = '???';
    }
    return popOverLabel;
  }

  handleClick(event) {
    if (this.props.activeQuestion) {
      if (
        this.props.activeQuestion.type === CALENDAR_DATETIME_TYPE ||
        this.props.activeQuestion.type === CALENDAR_DATETIME_ROUGHLY_TYPE ||
        this.props.activeQuestion.type === CALENDAR_DATETIME_SCHEDULED_TYPE ||
        this.props.activeQuestion.type === CALENDAR_DATETIME_ACTUAL_TYPE
      ) {
        const popOverLabel = this.popOverLabel();
        this.setState({
          anchorEl: event.currentTarget,
          popOverOpen: true,
          popOverLabel
        });
      } else {
        this.props.handleDaySelect(this.props.day);
      }
    }
  }

  handleTimeChange(event) {
    this.setState({
      valueTime: event.target.value
    });
  }

  handleClosePopoverAndCallDaySelect() {
    let newDate = `${this.props.day.format(DATE_FORMAT)}`;
    if (this.state.valueTime) {
      newDate = newDate.concat(` ${this.state.valueTime}`);
    }
    const newday = Moment(newDate).format(
      `${DATE_FORMAT} ${this.props.config.TIME_FORMAT}`
    );
    this.setState(
      {
        anchorEl: null,
        popOverOpen: false
      },
      this.props.handleDaySelect(newday)
    );
  }

  handleClose() {
    this.setState({
      anchorEl: null,
      popOverOpen: false
    });
  }

  compareDate(date) {
    const dateAnswer = Moment(date.answer);
    return this.state.day.isSame(dateAnswer, 'day');
  }

  preventPropagation(e) {
    e.stopPropagation();
  }

  render() {
    /** ================================================== */
    /** this is for positioning the number in the center of the circle.
     * Numbers that start or end with 1 are thiner then other numbers so the seem off center */
    /** ================================================== */
    let belongsToDisplayMonth = '';

    if (this.state.day.format('MM') !== this.props.displayMonth) {
      belongsToDisplayMonth = 'belongs-to-another-display-month';
    }

    return (
      <Col className="calendar-day" key={this.state.day.format('DD')}>
        <Row className="no-gutters text-center ">
          <Col className="text-center">
            <Button
              id={`btn-calendarDay-${this.state.day.format('DD')}`}
              type="button"
              onClick={this.handleClick}
              className={`${belongsToDisplayMonth} content-circle text-center ${this.dayHasMarkers(
                this.props.questions
              )}`}
            >
              {this.state.day.format('DD')}
            </Button>
          </Col>
            </Row>
            <div>
                {belongsToDisplayMonth ?(""
                ): (<Row className = "no-gutters">
          <Col className = "pl-1 pr-1 text-center icon-row">
            {this.getDayMarkers(this.props.questions)}
          </Col>
                </Row>)}
            </div>
        <Popover
          open={this.state.popOverOpen}
          anchorEl={this.state.anchorEl}
          onClose={this.handleClose}
          className="testclass"
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'center'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
        >
          <div
            className="pop-up-daytime"
            onClick={this.preventPropagation}
            onKeyDown={this.preventPropagation}
          >
            <Row>
              <Col className="pb-4">{this.state.popOverLabel}</Col>
            </Row>
            <Row>
              <Col className="col-6">
                <input
                  data-testid="input-calendarDay-time"
                  type="time"
                  className="time-of-day"
                  placeholder="HH:MM"
                  value={this.state.valueTime}
                  onChange={this.handleTimeChange}
                  autoFocus
                />
              </Col>
              <Col className="col-6">
                <Button
                  id="btn-calendarDay-ok"
                  type="submit"
                  className="btn btn-primary"
                  onClick={this.handleClosePopoverAndCallDaySelect}
                >
                  {this.props.t('commonOk')}
                </Button>
              </Col>
            </Row>
          </div>
        </Popover>
      </Col>
    );
  }
}

CalendarDayComponent.propTypes = {
  handleDaySelect: PropTypes.func.isRequired,
  questions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  day: PropTypes.shape({
    isSame: PropTypes.func.isRequired,
    format: PropTypes.func.isRequired
  }).isRequired,
  displayMonth: PropTypes.string.isRequired,
  activeQuestion: PropTypes.shape({
    type: PropTypes.string.isRequired
  }),
  config: PropTypes.shape({
    TIME_FORMAT: PropTypes.string.isRequired
  }).isRequired,
  t: PropTypes.func.isRequired
};

CalendarDayComponent.defaultProps = {
  activeQuestion: null
};

export default withConfig(withTranslation()(CalendarDayComponent));
