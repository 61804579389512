import { isEmpty } from 'lodash';

export default function getNextOrder(people) {
  if (isEmpty(people)) return 1;
  return Math.max(...people.map(person => person.payload.order)) + 1;
}

export function isFileIdValid(fileId) {
  const paramType = typeof fileId;
  if (paramType === 'undefined') return false;
  if (paramType === 'string' && fileId.length > 0) return true;
  if (paramType === 'number' && fileId > 0) return true;
  return false;
}
