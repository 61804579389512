import { object, string, number, date } from 'yup';
import moment from 'moment';
import { REGEX, ERRORS } from 'constants/constants';

const invalidTextLabel = 'isInValidText';

const maxLengthConstants = {
  firstName: 100,
  surnames: 100
};

const dobAndPNSchemaValidation = {
  personalNumber: string().matches(REGEX.FINNISH_PERSONAL_NUMBER, {
    message: ERRORS.PERSONAL_NUMBER_INCORRECT
  }),
  dob: date()
    .transform(rawValue => moment(rawValue).toDate())
    .max(moment(), ERRORS.DOB_INCORRECT)
    .required(invalidTextLabel)
    .typeError(ERRORS.DOB_INCORRECT)
};
const personalNumberSchemaValidation = object({
  ...dobAndPNSchemaValidation
});

const schemaValidation = object({
  firstName: string()
    .max(maxLengthConstants.firstName)
    .required(invalidTextLabel)
    .typeError(invalidTextLabel),
  surnames: string()
    .max(maxLengthConstants.surnames)
    .required(invalidTextLabel)
    .typeError(invalidTextLabel),
  ...dobAndPNSchemaValidation,
  relationship: number()
    .required(invalidTextLabel)
    .typeError(invalidTextLabel),
  isSecondaryCardHolder: string().required(invalidTextLabel),
  isCoLiving: string().required(invalidTextLabel)
});

export { maxLengthConstants, schemaValidation, personalNumberSchemaValidation };
