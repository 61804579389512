import React, { Component } from 'react';
import PropTypes from 'prop-types';

import QuestionItem from '../QuestionItem';

class GroupQuestion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      answeredQuestions: []
    };
    this.handleQuestionAnswer = this.handleQuestionAnswer.bind(this);
  }

  // TODO: technical debt
  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(newProps) {
    const oldBenefit = this.props.currentClaim.benefitId;
    const newBenefit = newProps.currentClaim.benefitId;
    if (newProps !== this.props && !(oldBenefit && newBenefit == null)) {
      this.setState({ answeredQuestions: [] });
    }
  }

  handleQuestionAnswer(questionId, value, nextGroupId) {
    const newUserAnswer = {
      questionId,
      value,
      nextGroupId
    };
    this.setState(prevState => {
      let answeredQuestions = prevState.answeredQuestions.slice(0);
      const answerIndex = answeredQuestions.findIndex(
        answer => answer.questionId === questionId
      );
      const isAlreadyAnswered = answerIndex > -1;

      if (isAlreadyAnswered) {
        answeredQuestions[answerIndex] = newUserAnswer;
      } else {
        answeredQuestions = [...answeredQuestions, newUserAnswer];
      }
      return { answeredQuestions };
    }, this.callbackIfGroupAnswered);
  }

  callbackIfGroupAnswered() {
    const isAllQuestionsAnswered = (answeredQuestions, groupQuestions) =>
      groupQuestions.every(
        question =>
          !question.mandatory ||
          (question.mandatory &&
            answeredQuestions.some(answer => answer.questionId === question.id))
      );
    if (
      isAllQuestionsAnswered(
        this.state.answeredQuestions,
        this.props.groupQuestions
      )
    ) {
      this.props.onGroupUserAnswer(this.state.answeredQuestions);
    }
  }

  render() {
    const getAnswerForQuestionId = (questionId, userAnswers) => {
      const provisionalAnswerIndex = this.state.answeredQuestions.findIndex(
        a => a.questionId === questionId
      );

      return provisionalAnswerIndex >= 0
        ? this.state.answeredQuestions[provisionalAnswerIndex]
        : userAnswers.find(answer => answer.questionId === questionId);
    };
    const displayQuestions = questions =>
      questions.map(question => (
        <QuestionItem
          key={question.id}
          {...question}
          userAnswer={getAnswerForQuestionId(
            question.id,
            this.props.userAnswers
          )}
          onUserAnswer={this.handleQuestionAnswer}
          onValidCreditCard={this.props.onValidCreditCard}
          onValidPolicyNumber={this.props.onValidPolicyNumber}
          onDeclineCurrentClaim={this.props.onDeclineCurrentClaim}
          onDeclineConfirmationNO={this.props.onDeclineConfirmationNO}
          onDeleteAnswersByQuestionId={this.props.onDeleteAnswersByQuestionId}
          updateClaimData={this.props.updateClaimData}
          gotoLandingPage={this.props.gotoLandingPage}
          labels={this.props.labels}
          updateContacts={this.props.updateContacts}
          contacts={this.props.contacts}
          cardsList={this.props.cardsList}
          policiesList={this.props.policiesList}
          activeCard={this.props.activeCard}
          claimId={this.props.claimId}
          benefitId={this.props.benefitId}
          onAddAlert={this.props.onAddAlert}
          languageId={this.props.languageId}
          userInfo={this.props.userInfo}
          activePage={this.props.activePage}
          currentClaim={this.props.currentClaim}
          guideWireField={question.guideWireField || undefined}
        />
      ));

    return (
      <div className="group-question-wrapper">
        {displayQuestions(this.props.groupQuestions)}
      </div>
    );
  }
}

GroupQuestion.propTypes = {
  contacts: PropTypes.arrayOf(PropTypes.shape({})),
  cardsList: PropTypes.arrayOf(PropTypes.shape({})),
  policiesList: PropTypes.arrayOf(PropTypes.shape({})),
  activeCard: PropTypes.shape({}),
  groupQuestions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  userAnswers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onGroupUserAnswer: PropTypes.func.isRequired,
  onValidCreditCard: PropTypes.func.isRequired,
  onValidPolicyNumber: PropTypes.func.isRequired,
  updateClaimData: PropTypes.func.isRequired,
  updateContacts: PropTypes.func.isRequired,
  onDeclineCurrentClaim: PropTypes.func.isRequired,
  onDeclineConfirmationNO: PropTypes.func.isRequired,
  onDeleteAnswersByQuestionId: PropTypes.func.isRequired,
  gotoLandingPage: PropTypes.func.isRequired,
  labels: PropTypes.shape({}).isRequired,
  claimId: PropTypes.string,
  benefitId: PropTypes.number,
  onAddAlert: PropTypes.func.isRequired,
  languageId: PropTypes.number.isRequired,
  userInfo: PropTypes.shape({}),
  activePage: PropTypes.string.isRequired,
  currentClaim: PropTypes.shape({
    benefitId: PropTypes.number
  }).isRequired
};
GroupQuestion.defaultProps = {
  contacts: [],
  cardsList: [],
  policiesList: [],
  activeCard: {},
  userInfo: {},
  claimId: '',
  benefitId: 0
};

export default GroupQuestion;
