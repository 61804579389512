import React from 'react';
import PropTypes from 'prop-types';
import loadBingMapsScript from 'utils/bingHelper';
import {
  BING_MAPS_URL,
  LETTERS,
  MEDICAL_FACILITIES
} from 'constants/constants';

import MedicalProvidersResults from './MedicalProvidersResults';

const BING_MAPS_KEY = process.env.REACT_APP_BING_MAPS_KEY;

class MedicalProvidersMap extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      displayRoute: false,
      routeMode: 'driving',
      selectedFacility: null
    };

    this.createBingMap = this.createBingMap.bind(this);
    this.createRoute = this.createRoute.bind(this);
    this.setRouteMode = this.setRouteMode.bind(this);
    this.setMapPin = this.setMapPin.bind(this);
  }

  componentDidMount() {
    this.Microsoft = window.Microsoft;
    if (
      document.querySelector(
        `script[src="${BING_MAPS_URL}bingmapsCallback"]`
      ) === null
    ) {
      window.bingmapsCallback = () => {
        this.Microsoft = window.Microsoft;
        this.createBingMap();
      };
      loadBingMapsScript('bingmapsCallback');
    }

    if (this.Microsoft && this.Microsoft.Maps) {
      this.props.onLoading(true);
      this.createBingMap();
      this.props.onLoading(false);
    }
  }

  setResultsInMap() {
    this.props.results.forEach((facility, key) => {
      const label = key >= LETTERS.length ? '-' : LETTERS[key];
      const pushpin = this.setMapPin(
        facility.location.latitude,
        facility.location.longitude,
        false,
        label
      );
      this.Microsoft.Maps.Events.addHandler(pushpin, 'click', () => {
        this.createRoute(
          facility.location.latitude,
          facility.location.longitude,
          facility.id
        );
      });
    });
  }

  setRouteMode(mode) {
    this.setState(
      {
        routeMode: mode
      },
      () => {
        // All waypoints must be repainted after changing route method
        const wayPoints = this.directionsManager.getAllWaypoints();
        if (wayPoints.length) {
          const targetWayPoint = wayPoints[1].getLocation();
          this.createRoute(targetWayPoint.latitude, targetWayPoint.longitude);
        }
      }
    );
  }

  setMapPin(latitude, longitude, isCenter, pinLabel = '') {
    const coords = new this.Microsoft.Maps.Location(latitude, longitude);
    const color = isCenter ? 'red' : 'blue';
    const pin = new this.Microsoft.Maps.Pushpin(coords, {
      text: pinLabel,
      color
    });
    this.map.entities.push(pin);
    return pin;
  }

  createMapDirection = (latitude, longitude, address) =>
    new this.Microsoft.Maps.Directions.Waypoint({
      address,
      location: new this.Microsoft.Maps.Location(latitude, longitude)
    });

  createRoute(targetLatitude, targetLongitude, id = null) {
    const originLocation = this.props.query.location;
    this.setState(prevState => ({
      displayRoute: true,
      selectedFacility: id || prevState.selectedFacility
    }));

    // clear all render and request routing options together with waypoints.
    this.directionsManager.clearAll();

    this.directionsManager.setRequestOptions({
      routeMode: this.Microsoft.Maps.Directions.RouteMode[this.state.routeMode]
    });
    // Origin (user's current location)
    const origin = this.createMapDirection(
      originLocation.latitude,
      originLocation.longitude,
      'origin'
    );
    this.directionsManager.addWaypoint(origin);
    const target = this.createMapDirection(
      targetLatitude,
      targetLongitude,
      'target'
    );
    this.directionsManager.addWaypoint(target);

    this.directionsManager.setRenderOptions({
      itineraryContainer: document.getElementById('panel')
    });
    this.directionsManager.calculateDirections();
  }

  createBingMap() {
    const { location } = this.props.query;
    this.map = new this.Microsoft.Maps.Map(document.getElementById('map'), {
      center: new this.Microsoft.Maps.Location(
        location.latitude,
        location.longitude
      ),
      zoom: 13,
      credentials: BING_MAPS_KEY
    });

    // Declare directionManager
    this.Microsoft.Maps.loadModule('Microsoft.Maps.Directions', () => {
      this.directionsManager = new this.Microsoft.Maps.Directions.DirectionsManager(
        this.map
      );
      this.directionsManager.setRequestOptions({
        routeMode: this.Microsoft.Maps.Directions.RouteMode.driving
      });
      this.directionsManager.setRenderOptions({
        itineraryContainer: document.getElementById('panel')
      });
    });

    this.setMapPin(location.latitude, location.longitude, true);
    // Results passed in props displayed in map
    this.setResultsInMap();
  }

  render() {
    let mapClss = 'map';
    let panelClss = 'd-none';
    if (this.state.displayRoute) {
      mapClss = 'map col-12 col-md-8 col-lg-9';
      panelClss = 'panel col-12 col-md-4 col-lg-3';
    }
    const mapStyle = {
      position: 'relative'
    };
    return (
      <div className="map-container row no-gutters">
        <div id="map" className={mapClss} style={mapStyle} />
        <div id="panel" className={panelClss} style={mapStyle} />
        <MedicalProvidersResults
          onSelect={this.createRoute}
          facilities={this.props.results}
          selectedId={this.state.selectedFacility}
          onFilter={this.props.onFilter}
        />
        <div className="route-bullets">
          <div>
            <button
              type="submit"
              className={`${
                this.state.routeMode === MEDICAL_FACILITIES.ROUTE_MODES.driving
                  ? 'selected'
                  : ''
              }`}
              onClick={() => {
                this.setRouteMode('driving');
              }}
            >
              <i className="material-icons">directions_car</i>
            </button>
          </div>
          <div>
            <button
              type="submit"
              className={`${
                this.state.routeMode === MEDICAL_FACILITIES.ROUTE_MODES.transit
                  ? 'selected'
                  : ''
              }`}
              onClick={() => {
                this.setRouteMode('transit');
              }}
            >
              <i className="material-icons">directions_transit</i>
            </button>
          </div>
          <div>
            <button
              type="submit"
              className={`${
                this.state.routeMode === MEDICAL_FACILITIES.ROUTE_MODES.walking
                  ? 'selected'
                  : ''
              }`}
              onClick={() => {
                this.setRouteMode('walking');
              }}
            >
              <i className="material-icons">directions_walk</i>
            </button>
          </div>
        </div>
      </div>
    );
  }
}

MedicalProvidersMap.propTypes = {
  results: PropTypes.arrayOf(
    PropTypes.shape({
      category: PropTypes.string,
      identifier: PropTypes.shape({
        providerId: PropTypes.string,
        name: PropTypes.string
      }),
      contactInformation: PropTypes.shape({
        phoneNumber: PropTypes.shape({
          internationalPrefix: PropTypes.string,
          number: PropTypes.string
        })
      }),
      rating: PropTypes.shape({
        isPreferred: PropTypes.bool
      }),
      location: PropTypes.shape({
        longitude: PropTypes.string,
        latitude: PropTypes.string
      }),
      distance: PropTypes.shape({
        value: PropTypes.string,
        unit: PropTypes.string
      })
    })
  ),
  query: PropTypes.shape({
    location: PropTypes.shape({
      latitude: PropTypes.string,
      longitude: PropTypes.string
    }),
    category: PropTypes.string
  }),
  onFilter: PropTypes.func.isRequired,
  onLoading: PropTypes.func.isRequired
};

MedicalProvidersMap.defaultProps = {
  results: [],
  query: null
};

export default MedicalProvidersMap;
