import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { LANGUAGE_ID_MAPS, COUNTRIES_BY_MARKET } from 'constants/constants';
import Label from 'components/shared/Forms/Label';
import LabelFor from 'components/shared/Forms/LabelFor';
import PhoneInput from 'react-phone-number-input';
import fr from './CountriesLanguages/fr.json';
import de from './CountriesLanguages/de.json';
import es from './CountriesLanguages/es.json';
import it from './CountriesLanguages/it.json';
import fi from './CountriesLanguages/fi.json';
import nb from './CountriesLanguages/nb.json';
import nl from './CountriesLanguages/nl.json';
import en from './CountriesLanguages/en.json';
import HelpText from './HelpText';

const PhoneField = props => {
  const { t } = useTranslation();
  const languageId = useSelector(
    state => state.marketLanguages.selectedLanguage
  );
  const market = useSelector(state => state.configuration.market);

  const getCountryIsoCode = () => {
    return COUNTRIES_BY_MARKET[market];
  };

  const languageCode = LANGUAGE_ID_MAPS[languageId].substring(0, 2);

  const getLanguageIsoCodeOrDefault = () => {
    let languageIsoCode;
    switch (languageCode) {
      case 'en':
        languageIsoCode = en;
        break;
      case 'fr':
        languageIsoCode = fr;
        break;
      case 'de':
        languageIsoCode = de;
        break;
      case 'es':
        languageIsoCode = es;
        break;
      case 'it':
        languageIsoCode = it;
        break;
      case 'fi':
        languageIsoCode = fi;
        break;
      case 'no':
        languageIsoCode = nb;
        break;
      case 'nl':
        languageIsoCode = nl;
        break;
      default:
        languageIsoCode = en;
        break;
    }
    return languageIsoCode;
  };

  /**
   *
   * @param {Array} errorMessages Renders validation messages under the field.
   * Supports multiple fields
   * TODO: stop supporting pure object, it should only receive array of error object for code simplicity
   */
  const renderErrorMessages = (errorMessages, showAllErrors) => {
    if (typeof errorMessages == 'string')
      return <div className="invalid-feedback">{t(errorMessages)}</div>;
    if (showAllErrors) {
      return errorMessages.map(errMsg => (
        <div key={errMsg} className="invalid-feedback">
          {t(errMsg)}
        </div>
      ));
    }
    return <div className="invalid-feedback">{t(errorMessages[0])}</div>;
  };

  /**
   * this expression defines if the field must paint the board red
   */
  const isInvalid =
    props.errorFields[props.name] && props.errorFields[props.name].hasError;

  /**
   * Determines red colorful boards when field is not valid
   * @param {string} base base CSS to expand from
   */
  const getClass = base =>
    // eslint-disable-next-line no-nested-ternary
    `${base}  ${isInvalid ? 'is-invalid' : props.value ? 'is-valid' : ''}`;

  const handleOnBlur = e => {
    props.onValidate(e.target.name, e.target.value);
  };

  return (
    <div className={getClass('form-group')}>
      <LabelFor
        labelRef={props.inputRef}
        name={props.name}
        className={`col col-md-${props.colSize} col-lg-${props.colSize} px-0`}
      >
        <Label text={props.label} isRequired={props.required} />
        {props.helpText && <HelpText name={props.name} text={props.helpText} />}

        <PhoneInput
          data-testid={`${props.id}-textInput`}
          className={getClass('form-control')}
          name={props.name}
          placeholder={props.placeholder}
          disabled={props.disabled}
          limitMaxLength
          value={props.value}
          onChange={value =>
            props.onValueChange({
              target: {
                name: props.name,
                value
              }
            })
          }
          onBlur={handleOnBlur}
          international
          defaultCountry={getCountryIsoCode()}
          countryCallingCodeEditable={false}
          labels={getLanguageIsoCodeOrDefault()}
        />

        {isInvalid &&
          renderErrorMessages(
            props.errorFields[props.name].message,
            props.showAllErrors
          )}
      </LabelFor>
    </div>
  );
};

PhoneField.propTypes = {
  id: PropTypes.string,
  inputRef: PropTypes.shape({}),
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  value: PropTypes.string,
  onValueChange: PropTypes.func,
  placeholder: PropTypes.string,
  helpText: PropTypes.string,
  disabled: PropTypes.bool,
  showAllErrors: PropTypes.bool,
  required: PropTypes.bool,
  errorFields: PropTypes.shape({
    hasError: PropTypes.bool,
    message: PropTypes.string
  }),
  onValidate: PropTypes.func,
  colSize: PropTypes.number
};

PhoneField.defaultProps = {
  id: 'input-textField',
  inputRef: null,
  label: '',
  value: undefined,
  placeholder: '',
  helpText: '',
  disabled: false,
  showAllErrors: false,
  required: false,
  errorFields: {},
  onValidate: () => {},
  onValueChange: () => {},
  colSize: 12
};

export default PhoneField;
