import {
  VALIDATE_POLICY_REQUESTED,
  VALIDATE_POLICY_SUCCEEDED,
  VALIDATE_POLICY_FAILED,
  setPolicyValidationResult
} from 'actions/feature/policy';

import { VALIDATE_POLICY } from 'api/apiRoutes';
import { apiRequest } from 'actions/core/api';
import { setError } from 'actions/core/error';
import { hideSpinner, showSpinner } from 'actions/core/ui';

export const validatePolicyFlow = ({ dispatch }) => next => action => {
  next(action);

  if (action.type === VALIDATE_POLICY_REQUESTED) {
    dispatch(showSpinner());
    dispatch(
      apiRequest(
        'POST',
        `${VALIDATE_POLICY}`,
        {},
        {
          ccNumber: action.payload.number,
          name: action.payload.name,
          surname: action.payload.surname,
          dateOfBirth: action.payload.date
        },
        {
          ...action.meta,
          name: action.payload.name,
          surname: action.payload.surname,
          date: action.payload.date,
          number: action.payload.number
        },
        VALIDATE_POLICY_SUCCEEDED,
        VALIDATE_POLICY_FAILED
      )
    );
  }
};

export const validatePolicySuccess = ({ dispatch }) => next => action => {
  next(action);

  if (action.type === VALIDATE_POLICY_SUCCEEDED) {
    dispatch(setPolicyValidationResult(true));

    const { onValidationSuccess } = action.meta;
    // validation returns the product id and i want to have all policy data in the payload to call the next action
    dispatch({
      type: onValidationSuccess,
      payload: {
        productId: action.payload.productId,
        number: action.meta.number,
        name: action.meta.name,
        surname: action.meta.surname,
        date: action.meta.date
      },
      meta: { ...action.meta }
    });
    dispatch(hideSpinner());
  }
};

export const validatePolicyFail = ({ dispatch }) => next => action => {
  next(action);

  if (action.type === VALIDATE_POLICY_FAILED) {
    const { status, data } = action.payload;
    if (status === 400) {
      dispatch(setPolicyValidationResult(false, data));
    } else {
      dispatch(setError(action.payload));
    }
    dispatch(hideSpinner());
  }
};

export default [validatePolicyFlow, validatePolicySuccess, validatePolicyFail];
