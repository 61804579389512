import actionProvider from 'utils/action-provider';
import { getMarketFromUrl, inBodiam } from 'utils/urlHelpers';
import { redirectToBodiam, redirectToBodiamLogin } from 'utils/historyHelper';
import {
  APP_GO_TO_REQUESTED,
  goToBodiamTo,
  APP_GO_TO_BODIAM_REQUESTED
} from 'actions/core/app.navigation';
import { onSignInAccepted } from 'actions/authenticationActions';
import { resetDecisionTree } from 'actions/decisionTreeActions';
import { setActiveCard } from 'actions/feature/card';
import { showSpinner, hideSpinner } from 'actions/core/ui';
import { isUserLoggedIn } from 'utils/sessionController';
import {
  PAGES_REQUIRE_AUTHENTICATION,
  MY_CLAIMS_PAGE,
  CHECK_POLICY_COVER,
  UPLOAD_CLAIM_DOCUMENTS,
  SIGNIN_PAGE,
  TASKLIST,
  COVER_PAGE,
    COVER_PAGE_FORM,
    COVER_PAGE_BYDATE,
  GENERATE_CERTIFICATE_SUCCESS
} from 'constants/constants';

const isItAvailable = newPage =>
  (PAGES_REQUIRE_AUTHENTICATION.includes(newPage) && isUserLoggedIn()) ||
  !PAGES_REQUIRE_AUTHENTICATION.includes(newPage);

export const appNavigationFlow = ({ dispatch }) => next => action => {
  next(action);

  const redirectToDefault = () =>
    dispatch(actionProvider.getActivePageActions().gotoLandingPage());

  if (action.type === APP_GO_TO_REQUESTED) {
    if (inBodiam()) {
      dispatch(goToBodiamTo(action.payload));
    } else if (isItAvailable(action.payload)) {
      switch (action.payload) {
        case MY_CLAIMS_PAGE:
          dispatch(showSpinner());
          dispatch(onSignInAccepted());
          dispatch(resetDecisionTree());
          dispatch(actionProvider.getActivePageActions().goToMyClaimsPage());
          dispatch(hideSpinner());
          break;
        case TASKLIST:
          dispatch(resetDecisionTree());
          dispatch(actionProvider.getActivePageActions().gotoClaimWrapper());
          break;
        case CHECK_POLICY_COVER:
          dispatch(
            actionProvider.getActivePageActions().gotoCheckPolicyCover()
          );
          break;
        case COVER_PAGE:
          dispatch(actionProvider.getActivePageActions().goToCoverPage());
              break;
          case COVER_PAGE_BYDATE:
              dispatch(actionProvider.getActivePageActions().goToCoverPageByDate());
              break;
        case COVER_PAGE_FORM:
          dispatch(actionProvider.getActivePageActions().goToCheckCoverForm());
          break;
        case GENERATE_CERTIFICATE_SUCCESS:
          dispatch(
            actionProvider
              .getActivePageActions()
              .goToGenerateCertificateSuccess()
          );
          break;
        case UPLOAD_CLAIM_DOCUMENTS:
          dispatch(
            actionProvider.getActivePageActions().gotoUploadClaimDocumentsPage()
          );
          break;
        default:
          redirectToDefault();
          break;
      }
    } else {
      redirectToDefault();
    }
  }
};

export const appBodiamNavigationFlow = ({ dispatch }) => next => action => {
  next(action);

  if (action.type === APP_GO_TO_BODIAM_REQUESTED) {
    dispatch(showSpinner());
    if (action.payload === SIGNIN_PAGE) {
      redirectToBodiamLogin(getMarketFromUrl());
    }
    if (action.payload === MY_CLAIMS_PAGE && isItAvailable(MY_CLAIMS_PAGE)) {
      dispatch(showSpinner());
      dispatch(onSignInAccepted());
      dispatch(setActiveCard(null));
      dispatch(actionProvider.getActivePageActions().goToMyClaimsPage());
      dispatch(hideSpinner());
    } else {
      dispatch(onSignInAccepted());
      redirectToBodiam(getMarketFromUrl());
    }
    dispatch(hideSpinner());
  }
};

export default [appNavigationFlow, appBodiamNavigationFlow];
