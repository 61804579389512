import React, { useState, useEffect } from 'react';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';

import { Button } from 'components/shared/Forms';
import { withConfig } from 'ConfigurationProvider/index';

import { event } from 'utils/analytics';

import { GA_EVENTS, GA_ACTIONS } from 'constants/googleAnalytics';
import { appInsights } from 'ApplicationInsights';

export const NumberQuestion = props => {
  const { t } = useTranslation();
  const [numberField, setNumberField] = useState(undefined);

  useEffect(() => {
    if (!isEmpty(props.userAnswer)) {
      setNumberField(props.userAnswer.value);
    }
  }, [props.userAnswer]);

  const numberFieldIsValid = () => {
    const { min, max } = props.properties;

    const shouldHasValue =
      (props.mandatory && numberField >= 0) || !props.mandatory;
    const isMoreThanMin = min ? numberField >= min : true;
    const isLessThanMax = max ? numberField <= max : true;

    return (
      !isEmpty(numberField) && shouldHasValue && isMoreThanMin && isLessThanMax
    );
  };

  const handleSubmit = e => {
    const { id, nextGroup } = props;
    event(GA_EVENTS.QUESTION, GA_ACTIONS.ANSWER, {
      label: `${props.guideWireField}`
    });
    appInsights.trackEvent(
      {
        name:`${props.guideWireField}`
      },
      {
        EventCategory: GA_EVENTS.QUESTION,
        EventAction: GA_ACTIONS.ANSWER,
        EventLabel: `${props.guideWireField}`
      }
      )
    props.onUserAnswer(id, numberField, nextGroup);

    e.preventDefault();
  };

  const handleOnValueChange = e => setNumberField(e.value);

  const buttonClass = numberFieldIsValid() ? 'btn-primary' : 'btn-disabled';

  return (
    <form
      onSubmit={handleSubmit}
      className="form-inline needs-validation mb-2 "
      noValidate
    >
      <div className="input-group col mb-3 px-0 px-lg-3">
        <NumberFormat
          thousandSeparator={props.config.REGIONAL_SETTINGS.thousandSeparator}
          decimalSeparator={props.config.REGIONAL_SETTINGS.decimalSeparator}
          decimalScale={0}
          thousandSpacing="3"
          type="text"
          className="form-control col-md-6 col-sm-12"
          max={props.properties.max}
          min={props.properties.min}
          value={numberField}
          onValueChange={handleOnValueChange}
          aria-label="Amount "
        />
        <div className="input-group-append">
          <Button
            type="submit"
            className={`btn btn-default  ${buttonClass} `}
            disabled={!numberFieldIsValid()}
            text={t('commonOk')}
          />
        </div>
      </div>
    </form>
  );
};

NumberQuestion.propTypes = {
  userAnswer: PropTypes.shape({
    value: PropTypes.number.isRequired,
    questionId: PropTypes.number.isRequired
  }),
  properties: PropTypes.shape({
    max: PropTypes.number,
    min: PropTypes.number
  }).isRequired,
  onUserAnswer: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired,
  nextGroup: PropTypes.number,
  config: PropTypes.shape({
    REGIONAL_SETTINGS: PropTypes.shape({
      thousandSeparator: PropTypes.string.isRequired,
      decimalSeparator: PropTypes.string.isRequired
    }).isRequired
  }).isRequired,
  mandatory: PropTypes.bool.isRequired,
  guideWireField: PropTypes.string
};

NumberQuestion.defaultProps = {
  userAnswer: undefined,
  nextGroup: null,
  guideWireField: 'numberQuestion'
};

export default withConfig(NumberQuestion);
