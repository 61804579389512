import React from 'react';
import PropTypes from 'prop-types';

import CustomModal from 'shared/components/CustomModal';

import { event } from 'utils/analytics';

import { GA_EVENTS, GA_ACTIONS } from 'constants/googleAnalytics';
import { appInsights } from 'ApplicationInsights';

const WarningQuestion = props => {
  const onClickOk = () => {
    event(GA_EVENTS.QUESTION, GA_ACTIONS.ANSWER, {
      label: props.guideWireField
    });
    appInsights.trackEvent(
      {
        name:props.guideWireField
      },
      {
        EventCategory: GA_EVENTS.QUESTION,
        EventAction: GA_ACTIONS.ANSWER,
        EventLabel: props.guideWireField
      }
      )
    props.onUserAnswer(props.id, true, props.nextGroup);
  };

  return (
    <CustomModal
      isOpen={props.userAnswer === undefined}
      contentLabel="Warning question"
      shouldCloseOnOverlayClick={false}
      overlayClassName="overlay"
      className="modal-frame"
      handleConfirmationOk={() => onClickOk()}
      description={props.properties.description}
      labelOk={props.labels.commonOk}
    />
  );
};

WarningQuestion.propTypes = {
  labels: PropTypes.shape({
    commonOk: PropTypes.string.isRequired
  }).isRequired,
  properties: PropTypes.shape({
    description: PropTypes.string.isRequired
  }).isRequired,
  onUserAnswer: PropTypes.func.isRequired,
  userAnswer: PropTypes.shape({
    value: PropTypes.bool,
    questionId: PropTypes.number,
    nextGroupId: PropTypes.number
  }),
  id: PropTypes.number.isRequired,
  nextGroup: PropTypes.number,
  guideWireField: PropTypes.string
};

WarningQuestion.defaultProps = {
  userAnswer: undefined,
  nextGroup: null,
  guideWireField: 'warningQuestion'
};

export default WarningQuestion;
