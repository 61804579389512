import { useState, useEffect } from 'react';

const useWidthDevice = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const [isMobileDevice, setIsDeviceMobile] = useState(false);
  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  useEffect(() => {
    setIsDeviceMobile(width <= 680);
  }, [width]);

  return { isMobileDevice };
};

export default useWidthDevice;
