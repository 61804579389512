import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { event } from 'utils/analytics';

import { TextField } from 'components/shared/Forms';
import { Row, Col } from 'components/shared/Table';

import { GA_EVENTS, GA_ACTIONS } from 'constants/googleAnalytics';
import { appInsights } from 'ApplicationInsights';

const TextQuestion = props => {
  const [textValue, setTextValue] = useState(
    props.userAnswer ? props.userAnswer.value : ''
  );

  const handleValueChange = e => {
    const { id, nextGroup, mandatory } = props;
    const newValue = e.target.value;
    setTextValue(newValue);

      if (!mandatory || (mandatory && newValue && (newValue.length >= 3 || (newValue.length >= 2 && props != undefined && props.guideWireField != undefined && (props.guideWireField == 'MostRecentClaimInsuranceCompany' || props.guideWireField == 'SecondMostRecentClaimInsuranceCompany' || props.guideWireField == 'OtherTravelInsurance' || props.guideWireField == 'HasOtherTravelInsurance' ))))) {
      event(GA_EVENTS.QUESTION, GA_ACTIONS.ANSWER, {
        label: props.guideWireField
      });
      appInsights.trackEvent(
        {
          name:props.guideWireField
        },
        {
          EventCategory: GA_EVENTS.QUESTION,
          EventAction: GA_ACTIONS.ANSWER,
          EventLabel: props.guideWireField
        }
        )
      props.onUserAnswer(id, newValue, nextGroup);
    }
  };

  return (
    <Row>
      <Col className="col-12 col-md-6">
        <TextField
          name={props.label}
          onValueChange={handleValueChange}
          ariaDescribedby={props.label}
          value={textValue}
        />
      </Col>
    </Row>
  );
};

TextQuestion.propTypes = {
  userAnswer: PropTypes.shape({
    value: PropTypes.string
  }),
  onUserAnswer: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired,
  nextGroup: PropTypes.number.isRequired,
  label: PropTypes.string,
  mandatory: PropTypes.bool.isRequired,
  guideWireField: PropTypes.string
};
TextQuestion.defaultProps = {
  label: '',
  userAnswer: {
    value: ''
  },
  guideWireField: 'textQuestion'
};

export default TextQuestion;
