import React from 'react';
import PropTypes from 'prop-types';
import Spinner from './Spinner';

const Loading = ({ loading, text }) => {
  if (!loading) {
    return null;
  }
  return (
    <div className="loading">
      <Spinner text={text} />
    </div>
  );
};

Loading.propTypes = {
  loading: PropTypes.bool,
  text: PropTypes.string
};

Loading.defaultProps = {
  loading: false,
  text: null
};

export default Loading;
