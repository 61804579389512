import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'components/shared/Table';
import { Button } from 'components/shared/Forms';
import { useTranslation } from 'react-i18next';

const StickySaveButton = props => {
  const { t } = useTranslation();
  const moveScrollToAcceptConditionCard = () => {
    const acceptConditionsCard = document.getElementById(
      'checkbox-acceptConditionsCard-condition'
    );
    if (acceptConditionsCard) {
      try {
        const shiftTop = acceptConditionsCard.getBoundingClientRect().bottom;
        window.scroll({ top: shiftTop, left: 0, behavior: 'smooth' });
      } catch (error) {
        acceptConditionsCard.scrollIntoView();
      }
    }
  };

  return (
    <>
      <Row className="no-gutters">
        <Col className="save-card-cel">
          <Button
            className="btn btn-outline-primary btn-block btn-amex"
            onClick={moveScrollToAcceptConditionCard}
            type="button"
            tooltip={t('claimSaverSubmitTooltip')}
            text={t('claimSaverSubmit')}
            disabled={props.submitDisabled}
          />
        </Col>
      </Row>
      <Col className="save-card-cel-slim ">
        <span className="submit-caption">
          {t('claimSaverSubmitCaptionReview')}
        </span>
      </Col>
      <Col className="save-card-cel-slim ">
        <span className="submit-reminder">{t('claimSaverSubmitReminder')}</span>
      </Col>
    </>
  );
};

StickySaveButton.propTypes = {
  submitDisabled: PropTypes.bool.isRequired
};

StickySaveButton.defaultProps = {
  saveDisabled: false
};

export default StickySaveButton;
