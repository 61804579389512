import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function triageStatus(state = initialState.inTriage, action) {
  switch (action.type) {
    case types.SET_IN_TRIAGE:
      return true;
    case types.SET_NOT_TRIAGE:
      return false;
    default:
      return state;
  }
}
