import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useTranslation } from 'react-i18next';

import { getClaimReasonByFileId } from 'actions/feature/file';
import { provideReasonForDocAbsense } from 'actions/feature/claim';

import { event } from 'utils/analytics';
import { FILE_ANSWER } from 'constants/constants';
import { GA_EVENTS, GA_ACTIONS } from 'constants/googleAnalytics';

import TextAreaUploadQuestion from 'components/TextAreaQuestion/TextAreaUploadQuestion';
import EmailAdditionalText from './EmailAdditionalText';
import UploadComponent from './UploadComponent';
import UnavailableAdditionalText from './UnavailableAdditionalText';
import { appInsights } from 'ApplicationInsights';
import UploadQuestionOption, {
  uploadQuestionClasses
} from './UploadQuestionOption';

const styles = {
  textAreaLabel: {
    marginTop: '10px'
  }
};
const isFileSelected = receivedOption =>
  receivedOption === null || receivedOption === FILE_ANSWER.FILE;

export const UploadQuestion = props => {
  const { t } = useTranslation();
  const [option, setOption] = useState(null);
  const [reasonValue, setReasonValue] = useState('');

  const [reasonTxtAreaEnabled, setReasonTxtAreaEnabled] = useState(false);

  const checkSelectOptionByAnswerType = answerType => {
    const answeredPreviously = props.answer === answerType;

    return option === answerType || (!option && answeredPreviously);
  };

  const isEmailSelected = () =>
    checkSelectOptionByAnswerType(FILE_ANSWER.EMAIL);

  const isUnavailableSelected = () =>
    checkSelectOptionByAnswerType(FILE_ANSWER.UNAVAILABLE);

  const handleReasonChange = value => {
    if (value && value.length >= 3) {
      setReasonTxtAreaEnabled(true);
    }
    setReasonValue(value);
  };

  const handleSelectFileOption = () => {
    // if the user select file option, if the user have a file attached we have to assign it. if not has one, we need to clean it.
    onSelectOption(FILE_ANSWER.FILE, getFileIdByAnswer(props.answer));
  };

  /**
   * @param {'1'|'2'|'3'} selectedOption
   * @param {string} uploadedFileId
   */
  const onSelectOption = (selectedOption, uploadedFileId = null) => {
    if (!props.isFromQuestionItem) {
      onUserAnswerUpdateGeneral(selectedOption, uploadedFileId);
    } else {
      const isFile = selectedOption === FILE_ANSWER.FILE;
      onUserAnswerUpdateForUploadQuestion(
        props.id,
        isFile ? uploadedFileId : selectedOption,
        props.nextGroup
      );
    }

    setOption(selectedOption);
  };

  const onUserAnswerUpdateGeneral = (selectedOption, fileId) => {
    event(GA_EVENTS.QUESTION, GA_ACTIONS.ANSWER, {
      label: `${props.guideWireField} - ${getOptionDescriptionForGA(fileId)}`
    });
    appInsights.trackEvent(
      {
        name:`${props.guideWireField} - ${getOptionDescriptionForGA(fileId)}`
      },
      {
        EventCategory: GA_EVENTS.QUESTION,
        EventAction: GA_ACTIONS.ANSWER,
        EventLabel: `${props.guideWireField} - ${getOptionDescriptionForGA(fileId)}`
      }
      )
    props.onUserAnswer(selectedOption, fileId);
  };

  const onUserAnswerUpdateForUploadQuestion = (
    componentId,
    fileId,
    nextGroup
  ) => {
    event(GA_EVENTS.QUESTION, GA_ACTIONS.ANSWER, {
      label: `${props.guideWireField} - ${getOptionDescriptionForGA(fileId)}`
    });
    appInsights.trackEvent(
      {
        name:`${props.guideWireField} - ${getOptionDescriptionForGA(fileId)}`
      },
      {
        EventCategory: GA_EVENTS.QUESTION,
        EventAction: GA_ACTIONS.ANSWER,
        EventLabel: `${props.guideWireField} - ${getOptionDescriptionForGA(fileId)}`
      }
      )
    props.onUserAnswer(componentId, fileId, nextGroup);
  };

  const getOptionDescriptionForGA = itemId => {
    if (itemId === FILE_ANSWER.EMAIL) return 'Email';
    if (itemId === FILE_ANSWER.UNAVAILABLE) return 'Unavailable';
    return 'File';
  };

  const getFileIdByAnswer = answer =>
    ![FILE_ANSWER.UNAVAILABLE, FILE_ANSWER.EMAIL].includes(answer)
      ? answer
      : null;

  useEffect(() => {
    const getOptionByProps = answer => {
      if (!answer) return FILE_ANSWER.FILE;
      if (answer === FILE_ANSWER.EMAIL) return FILE_ANSWER.EMAIL;
      if (answer === FILE_ANSWER.UNAVAILABLE) return FILE_ANSWER.UNAVAILABLE;
      if (
        props.answerData[props.id] &&
        props.answerData[props.id].answerType &&
        props.answerData[props.id].answerType.toString() ===
          FILE_ANSWER.UNAVAILABLE
      )
        return FILE_ANSWER.UNAVAILABLE;

      // By default
      return FILE_ANSWER.FILE;
    };

    if (!props.answerData[props.id] && !!props.answer && props.claimId) {
      props.actions.getClaimReasonByFileId(
        props.claimId,
        props.answer,
        props.id
      );
    } else if (props.answerData[props.id]) {
      setReasonValue(props.answerData[props.id].reason);
    }

    const optionCalculated = getOptionByProps(props.answer);
    setOption(optionCalculated);
  }, [props, props.claimId, props.answer, props.answerData]);

  return (
    <div data-testid="container-uploadQuestion">
      <UploadQuestionOption
              id={props.id}
              checked={isFileSelected(option)}
              handleCheckboxClick={handleSelectFileOption}
              title={t('uploadComponentUpload') ? <><span dangerouslySetInnerHTML={{ __html: t('uploadComponentUpload') }} /><br /> {t('uploadComponentDescrption')}</> : t('uploadComponentUpload') } 
              description={t('uploadComponentDescrption')}
      />
      <UploadComponent
        id={props.id}
        fileName={props.answerData[props.id]!=undefined&&props.answerData[props.id].fileName!=undefined?props.answerData[props.id].fileName:null}
        onFileUploaded={uploadedFileId =>
          onSelectOption(FILE_ANSWER.FILE, uploadedFileId)
        }
        claimId={props.claimId}
        benefitId={props.benefitId}
        fileId={getFileIdByAnswer(props.answer)}
        shouldShow={isFileSelected(option)}
        answerType={option}
      />
      <UploadQuestionOption
        id={props.id}
        checked={isEmailSelected()}
        handleCheckboxClick={() => onSelectOption(FILE_ANSWER.EMAIL)}
        title={t('uploadComponentEmail')}
        additionalText={
          option === FILE_ANSWER.EMAIL ? <EmailAdditionalText /> : null
        }
      />
      <UploadQuestionOption
        id={props.id}
        checked={isUnavailableSelected()}
        handleCheckboxClick={() => onSelectOption(FILE_ANSWER.UNAVAILABLE)}
        title={t('uploadComponentUnavailable')}
        additionalText={
          option === FILE_ANSWER.UNAVAILABLE ? (
            <div>
              <UnavailableAdditionalText
                text={
                  props.isMedical
                    ? t('uploadUnavailableTextMedical')
                    : t('uploadUnavailableText')
                }
              />

              {props.shouldProvideReason && (
                <>
                  <div style={styles.textAreaLabel}>
                    <strong>{t('commonPleaseExplainWhy')}</strong>
                  </div>
                  <TextAreaUploadQuestion
                    claimId={props.claimId}
                    questionId={props.id}
                    provideReasonForDocAbsense={
                      props.actions.provideReasonForDocAbsense
                    }
                    enabled={reasonTxtAreaEnabled}
                    updateReasonValue={handleReasonChange}
                    value={reasonValue}
                    answerType={option}
                  />
                </>
              )}
            </div>
          ) : null
        }
        className={uploadQuestionClasses.radioContainer}
      />
    </div>
  );
};

const mapStateToProps = state => ({
  answerData: state.fileUpload
});

const mapDispatchToProps = dispatch => ({
  actions: {
    ...bindActionCreators(
      {
        provideReasonForDocAbsense,
        getClaimReasonByFileId
      },
      dispatch
    )
  }
});
UploadQuestion.propTypes = {
  claimId: PropTypes.string.isRequired,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onUserAnswer: PropTypes.func.isRequired,
  answer: PropTypes.string,
  benefitId: PropTypes.number,
  isMedical: PropTypes.bool,
  shouldProvideReason: PropTypes.bool,
  isFromQuestionItem: PropTypes.bool,
  nextGroup: PropTypes.number,
  actions: PropTypes.shape({
    provideReasonForDocAbsense: PropTypes.func,
    getClaimReasonByFileId: PropTypes.func
  }).isRequired,
  answerData: PropTypes.shape({}).isRequired,
  guideWireField: PropTypes.string
};

UploadQuestion.defaultProps = {
  answer: undefined,
  benefitId: 0,
  isMedical: false,
  isFromQuestionItem: false,
  nextGroup: null,
  shouldProvideReason: false,
  guideWireField: 'uploadQuestion'
};

export default connect(mapStateToProps, mapDispatchToProps)(UploadQuestion);
