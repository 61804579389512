import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';

import Snackbar from '@material-ui/core/Snackbar';
import { withStyles } from '@material-ui/core/styles';
import green from '@material-ui/core/colors/green';
import amber from '@material-ui/core/colors/amber';

import * as alertsActions from 'actions/alertsActions';
import AlertsComponent from 'components/AlertsComponent';
import { Row } from 'components/shared/Table';

const styles1 = theme => ({
  success: {
    backgroundColor: green[600]
  },
  error: {
    backgroundColor: theme.palette.error.dark
  },
  info: {
    backgroundColor: theme.palette.primary.dark
  },
  warning: {
    backgroundColor: amber[700]
  },
  icon: {
    fontSize: 20
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing.unit
  },
  message: {
    display: 'flex',
    alignItems: 'center'
  },
  messageIe: {
    display: 'inline-block'
  }
});

const styles2 = theme => ({
  margin: {
    margin: theme.spacing.unit
  },
  close: {
    width: theme.spacing.unit * 4,
    height: theme.spacing.unit * 4
  }
});

const AlertsComponentWrapper = withStyles(styles1)(AlertsComponent);

const Alerts = props => {
  const [isOpen, setIsOpen] = useState(false);
  const [message, setMessage] = useState({});

  useEffect(() => {
    if (props.alerts.length > 0) {
      const { type, msg, code } = props.alerts.slice(-1)[0];
      const key = generateKey('alert');
        setIsOpen(true);
      setMessage({ type, value: msg, key, code });
    }
  }, [props.alerts]);

  const generateKey = pre => `${pre}_${new Date().getTime()}`;

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setIsOpen(false);
    props.actions.deleteAllAlert();
  };

  const handleExited = () => {
    props.actions.deleteAllAlert();
    };

  const getAutoHideDuration = (type) => {
      switch (type) {
            case 'success':
                return 10000; 
            case 'error':
                return 10000; 
            case 'info':
                return 10000; 
            case 'warning':
                return 30000; 
            default:
                return 10000; 
      }
  };

  const messageValue = message.code ? message.code : message.value;
  return (
    <Row className="no-gutters">
      <Snackbar
        key={message.key}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        open={isOpen}
        autoHideDuration={getAutoHideDuration(message.type)} 
        onClose={handleClose}
        onExited={handleExited}
      >
        <AlertsComponentWrapper
          onClose={handleClose}
          variant={message.type}
          message={messageValue}
        />
      </Snackbar>
    </Row>
  );
};

const mapStateToProps = state => ({
  alerts: state.alerts
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(alertsActions, dispatch)
});

Alerts.propTypes = {
  alerts: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  actions: PropTypes.shape({
    deleteAllAlert: PropTypes.func
  }).isRequired
};

export default withStyles(styles2)(
  connect(mapStateToProps, mapDispatchToProps)(Alerts)
);
