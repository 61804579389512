import React from 'react';
import PropTypes from 'prop-types';

const UploadInfoText = ({ text }) => (
  <span
    className="upload__info-text"
    // eslint-disable-next-line react/no-danger
    dangerouslySetInnerHTML={{ __html: text }}
  />
);

UploadInfoText.propTypes = {
  text: PropTypes.string.isRequired
};

export default UploadInfoText;
