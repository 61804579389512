import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ReactModal from 'react-modal';
import { Row, Col } from 'components/shared/Table';
import { COVER_PAGE_BYDATE } from 'constants/constants';
import { useTranslation } from 'react-i18next';
import { goTo } from 'actions/core/app.navigation';
import { Button } from 'components/shared/Forms';
import './_CheckCoverPage.scss';
import { useDispatch } from 'react-redux';
import * as types from 'actions/actionTypes';
import { gotoLandingMainPage } from 'actions/activePageActions';

export function addAlert(type, msg) {
    return { type: types.ADD_ALERT, alert: { type, msg } };
}
export const CheckCoverPageByDate = props => {    
    const { t } = useTranslation();    
    const dispatch = useDispatch();
    const [showPopup, setShowPopup] = useState(false);        

    const handleSecondButtonClick = () => {        
        props.actions.goTo(COVER_PAGE_BYDATE);
    };    
    
    return (
        <Row>
            <Col className="col-12 col-md-6 mb-2">
                <Button
                    type="button"
                    className="call-to-action-button d-flex start"
                    onClick={handleSecondButtonClick}

                >
                    <span className="mr-auto mt-auto mb-auto" dangerouslySetInnerHTML={{
                        __html: (t('checkCover2024'))
                    }}>                                           
                    </span>
                    <div className="rounded-circle call-to-action-button-chevron mr-4 ml-2 text-center">
                        <i className="material-icons">navigate_next</i>
                    </div>
                </Button>
            </Col>
            <Col className="col-12 col-md-6 mb-2">
                <Button
                    id="btn-body-continue"
                    type="submit"
                    className="call-to-action-button d-flex continue"
                    onClick={() => setShowPopup(true)}
                >
                    <span className="mr-auto mt-auto mb-auto" dangerouslySetInnerHTML={{
                        __html: (t('checkCover2025'))
                    }}>                        
                    </span>
                    <div className="rounded-circle call-to-action-button-chevron mr-4 ml-2 text-center">
                        <i className="material-icons">navigate_next</i>
                    </div>
                </Button>               
            </Col>
            <ReactModal
                isOpen={showPopup}
                contentLabel="contentLabel"
               
                className="modal-frame"
            >
                <div className="row">
                    <div className="col text-center py-4">
                        <span className="modal-description" dangerouslySetInnerHTML={{
                            __html: (t('option2Selected'))
                        }} >
                        </span>
                    </div>
                </div>
                <div className="row border-top pt-2 mt-3">
                    <div className="col text-center">
                        <button
                            type="submit"
                            className="btn btn-primary btn-lg mt-3 btn-amex"
                            onClick={() => dispatch(gotoLandingMainPage())}
                        >
                           Ok
                        </button>
                    </div>
                </div>
            </ReactModal>
        </Row>                    
    );
}
const
    mapDispatchToProps
        = dispatch => ({
            actions
                :
                bindActionCreators
                    ({
                        goTo                        
                    }, dispatch)
        });
export
    default
    connect
        (null, mapDispatchToProps)(
            CheckCoverPageByDate
        );