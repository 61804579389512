import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation, Trans } from 'react-i18next';
import ReactModal from 'react-modal';

import { event } from 'utils/analytics';
import { GA_EVENTS, GA_ACTIONS } from 'constants/googleAnalytics';

import { ACCOUNT_TYPE } from 'constants/constants';
import { Row, Col } from 'components/shared/Table';
import { Button } from 'components/shared/Forms';

import { withConfig } from 'ConfigurationProvider';

import CheckBenefitsComponent from './CheckBenefitsComponent';
import { appInsights } from 'ApplicationInsights';
import {
  isMarketAmexFR,
  getMarketFromUrl
} from 'utils/urlHelpers';

export const Body = props => {
  const { t } = useTranslation();

  const renderCheckBenefitComponent = (
    title,
    description,
    onCheckModal,
    buttonLabel,
    displaylink
  ) => (
      <CheckBenefitsComponent
        checkQuestionTitle={title}
        checkDescription={description}
        onOpenCheckModal={onCheckModal}
        checkBtnLabel={buttonLabel}
        displaylink={displaylink}
      />
    );

  const handleRetailCheckOverOpen = e => {
    e.preventDefault();
    props.onCheckPolicyCoverClick();
  };

  const handleSelectUserAccountType = accountType => {
    event(GA_EVENTS.HOME, GA_ACTIONS.CLICK, { label: accountType });
    appInsights.trackEvent(
      {
        name:accountType
      },
      {
        EventCategory: GA_EVENTS.HOME,
        EventAction: GA_ACTIONS.CLICK,
        EventLabel: accountType
      }
      )
    props.onSelectUserAccount(accountType);
  };

  return (
    <Row className="no-gutters">
      <Col>
        <div className="container">
          <Row className="no-gutters">
            <Col className="pt-4 pb-3">
              <h1 className="landing-title">{t('landingPageTitle')}</h1>
            </Col>
          </Row>
          <Row>
            <Col className="col-12 col-md-6 mb-2">
              <Button
                id="btn-body-continue"
                type="submit"
                className="call-to-action-button d-flex continue"
                onClick={props.onContinueExistingClaim}
              >
                <span className="mr-auto mt-auto mb-auto">
                  {t('landingPageButtonCont')}
                  <br />
                  {t('landingPageButtonContSubtitle')}
                </span>
                <div className="rounded-circle call-to-action-button-chevron mr-4 ml-2 text-center">
                  <i className="material-icons">navigate_next</i>
                </div>
              </Button>
            </Col>
            <Col className="col-12 col-md-6 mb-2">
              <Button
                type="button"
                className="call-to-action-button d-flex start"
                onClick={props.onStartNewClaim}
              >
                <span className="mr-auto mt-auto mb-auto">
                  {t('landingPageButtonStrt')}
                </span>
                <div className="rounded-circle call-to-action-button-chevron mr-4 ml-2 text-center">
                  <i className="material-icons">navigate_next</i>
                </div>
              </Button>
            </Col>
          </Row>
          <Row className="no-gutters">
            <Col />
            <Col className="col-9 landing-banner">
              <Trans i18nKey="landingBanner">landingBanner</Trans>
            </Col>
            <Col />
          </Row>
          <hr />
          {props.config.CREDITCARD.checkCover &&
            renderCheckBenefitComponent(
              t('landingCardQuestionH3'),
              t('landingCardQuestionText'),
              props.onOpenCheckCoverModal,
              t('checkCover'),
              false
            )}
          {props.config.RETAIL.checkCover &&
            renderCheckBenefitComponent(
              t('landingPolicyQuestionH3'),
              t('landingPolicyQuestionText'),
              handleRetailCheckOverOpen,
              t('checkPolicy')
            )}
          {/*<Row className="no-gutters">*/}
          {/*  <Col className="landing-card">*/}
          {/*    <Row className="no-gutters align-items-center">*/}
          {/*      <Col className="pr-3">*/}
          {/*        <h4>{t('landingCardEmergencyH3')}</h4>*/}
          {/*        {t('landingCardEmergencyText')}*/}
          {/*      </Col>*/}
          {/*      <Col className="align-middle text-center d-none d-md-block" />*/}
          {/*    </Row>*/}
          {/*  </Col>*/}
          {/*</Row>*/}
        </div>
      </Col>
      {/*{props.isOpenCheckUserAccountModal && (*/}
      {/*  <ReactModal*/}
      {/*    isOpen={props.isOpenCheckUserAccountModal}*/}
      {/*    shouldCloseOnOverlayClick={false}*/}
      {/*    className="modal-frame user-account-modal-frame"*/}
      {/*  >*/}
      {/*    <div className="h-100 user-account-modal">*/}
          
      {/*    <div className="w-100 py-2">*/}
      {/*        <Col>*/}
      {/*        <Button*/}
      {/*            type="button"*/}
      {/*            className="btn btn-primary btn-large btn-block btn-amex"*/}
      {/*            onClick={() => handleSelectUserAccountType(ACCOUNT_TYPE.NAC)}*/}
      {/*            id="nac-claim-button"*/}
      {/*          >*/}
      {/*            {t('userAccountModalNacBtn')}*/}
      {/*          </Button>*/}
      {/*        </Col>*/}
      {/*      </div>*/}
      {/*      <div className="w-100 py-2">*/}
      {/*        <Col>               */}
      {/*          <Button*/}
      {/*            type="button"*/}
      {/*            className="btn btn-primary btn-large btn-block btn-amex"*/}
      {/*            onClick={() =>*/}
      {/*              handleSelectUserAccountType(ACCOUNT_TYPE.RETAIL)*/}
      {/*            }*/}
      {/*            id="retail-claim-button"*/}
      {/*          >*/}
      {/*            {t('userAccountModalRetailBtn')}*/}
      {/*          </Button>*/}
      {/*        </Col>*/}
      {/*      </div>      */}
      {/*    </div>*/}
      {/*  </ReactModal>*/}
      {/*)}*/}
    </Row>
  );
};

Body.propTypes = {
  onContinueExistingClaim: PropTypes.func.isRequired,
  onStartNewClaim: PropTypes.func.isRequired,
  onOpenCheckCoverModal: PropTypes.func.isRequired,
  config: PropTypes.shape({
    RETAIL: PropTypes.shape({
      checkList: PropTypes.bool,
      checkCover: PropTypes.bool
    }).isRequired,
    CREDITCARD: PropTypes.shape({
      checkCover: PropTypes.bool.isRequired
    }).isRequired
  }).isRequired,
  userInfo: PropTypes.shape({}).isRequired,
  onCheckPolicyCoverClick: PropTypes.func,
  onSelectUserAccount: PropTypes.func.isRequired,
  isOpenCheckUserAccountModal: PropTypes.bool.isRequired
};
Body.defaultProps = {
  onCheckPolicyCoverClick: () => { }
};

export default withConfig(Body);
