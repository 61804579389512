import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Grow from '@material-ui/core/Grow';
import { Row, Col } from 'components/shared/Table';
import { Button } from 'components/shared/Forms';
import {
  YOUR_PURCHASES_ONLY_ME,
  YOUR_PURCHASES_ME_AND_OTHERS
} from 'constants/constants';
import CustomModal from 'shared/components/CustomModal';
import { getMarket } from 'store/selectors/market';
import ExpensesBeneficiaries from './ExpensesBeneficiaries';

const ExpensesWhoPurchases = props => {
  const market = useSelector(getMarket);
  const { t } = useTranslation();
  const [hasAdditionalClaimant, setHasAdditionalClaimant] = useState(
    props.contacts && props.contacts.length ? YOUR_PURCHASES_ME_AND_OTHERS : ''
  );
  const [isClaimOnBehalfConsent, setIsClaimOnBehalfConsent] = useState(
    props.contacts && props.contacts.length
  );
  const [isWarningModalOpen, setWarningModalOpen] = useState(false);

  const handleSelectWho = type => {
    if (type === YOUR_PURCHASES_ONLY_ME && props.contacts.length > 0) {
      setWarningModalOpen(true);
    }
    setHasAdditionalClaimant(type);
  };

  const handleClaimOnBehalfConsentChange = value => {
    if (!value && props.contacts.length > 0) {
      setWarningModalOpen(true);
    }
    setIsClaimOnBehalfConsent(value);
  };

  const handleContinueButtonClick = () => {
    if (
      hasAdditionalClaimant === YOUR_PURCHASES_ONLY_ME ||
      !isClaimOnBehalfConsent
    ) {
      props.updateContacts([]);
    }

    props.handleDisplayPurchases();
  };

  const handleConfirmationDelete = () =>
    setWarningModalOpen(!isWarningModalOpen);

  const isActive = formType => hasAdditionalClaimant === formType;

  const selectedStyle = buttonValue =>
    isActive(buttonValue) ? 'btn-amex-selected' : '';

  const selectedStyleConcent = buttonValue =>
    buttonValue ? 'btn-amex-selected' : '';

  const title = label => <strong className="expenses-title">{t(label)}</strong>;

  const continueButton = (
    <Grow in style={{ transformOrigin: '0 0 0' }} timeout={1000}>
      <Row className="mb-2 mt-2">
        <Col>
          <Button
            id="btn-expenseWhoPurchases-continue"
            type="submit"
            className="btn btn-primary btn-large btn-block btn-amex"
            onClick={() => handleContinueButtonClick()}
            text={t('commonContinue')}
          />
        </Col>
      </Row>
    </Grow>
  );

  const whoPurchasedOther = (
    <Grow in style={{ transformOrigin: '0 0 0' }}>
      <Row className="mb-2 mt-2">
        <Col>
          <ExpensesBeneficiaries
            contacts={props.contacts}
            market={market}
            updateContacts={props.updateContacts}
            handleDisplayPurchases={props.handleDisplayPurchases}
            languageId={props.languageId}
          />
        </Col>
      </Row>
    </Grow>
  );

  const consent = (
    <Grow in style={{ transformOrigin: '0 0 0' }} timeout={1000}>
      <Row className="expenses">
        <Col>
          {title('beneficiaryContactFormConsentLabel')}
          <Row className="mb-2 mt-3">
            <Col>
              <Button
                id="btn-expenseWhoPurchases-consentYes"
                type="button"
                className={`btn btn-outline-primary btn-lg btn-amex mr-1 ${selectedStyleConcent(
                  isClaimOnBehalfConsent
                )}`}
                onClick={() =>
                  handleClaimOnBehalfConsentChange(!isClaimOnBehalfConsent)
                }
                text={t('commonYes')}
              />
              <Button
                id="btn-expenseWhoPurchases-consentNo"
                type="button"
                className={`btn btn-outline-primary btn-lg btn-amex ${selectedStyleConcent(
                  !isClaimOnBehalfConsent
                )}`}
                onClick={() =>
                  handleClaimOnBehalfConsentChange(!isClaimOnBehalfConsent)
                }
                text={t('commonNo')}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </Grow>
  );

  const whoPurchasedForm = (
    <Grow in style={{ transformOrigin: '0 0 0' }} timeout={1000}>
      <Row className="expenses">
        <Col>
          {title('expenseBeneficiaryContactFormAddAdditional')}
          <Row className="mb-2 mt-3">
            <Col>
              <Button
                id="btn-expenseWhoPurchases-whoPurchasedYes"
                type="button"
                className={`btn btn-outline-primary btn-lg btn-amex mr-1 ${selectedStyle(
                  YOUR_PURCHASES_ME_AND_OTHERS
                )}`}
                onClick={() => handleSelectWho(YOUR_PURCHASES_ME_AND_OTHERS)}
                text={t('commonYes')}
              />
              <Button
                id="btn-expenseWhoPurchases-whoPurchasedNo"
                type="button"
                className={`btn btn-outline-primary btn-lg btn-amex ${selectedStyle(
                  YOUR_PURCHASES_ONLY_ME
                )}`}
                onClick={() => handleSelectWho(YOUR_PURCHASES_ONLY_ME)}
                text={t('commonNo')}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </Grow>
  );

  const renderWarningModal = (
    <CustomModal
      isOpen={isWarningModalOpen}
      contentLabel="contentLabel"
      shouldCloseOnOverlayClick={false}
      overlayClassName="overlay"
      className="modal-frame"
      handleConfirmationOk={() => handleConfirmationDelete()}
      description={t('AdviseAdditionalClaimantDelete')}
      labelOk={t('commonOk')}
    />
  );

  const hasConsentToProvideAddionalClaimantInfo =
    isActive(YOUR_PURCHASES_ME_AND_OTHERS) && isClaimOnBehalfConsent;

  return (
    <Row className="mt-3">
      <Col>
        {isWarningModalOpen && renderWarningModal}
        {whoPurchasedForm}
        {isActive(YOUR_PURCHASES_ME_AND_OTHERS) && consent}
        {hasConsentToProvideAddionalClaimantInfo ? whoPurchasedOther : null}
        {(isActive(YOUR_PURCHASES_ONLY_ME) ||
          (isActive(YOUR_PURCHASES_ME_AND_OTHERS) &&
            !isClaimOnBehalfConsent)) &&
          continueButton}
      </Col>
    </Row>
  );
};

ExpensesWhoPurchases.propTypes = {
  contacts: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired,
  updateContacts: PropTypes.func.isRequired,
  handleDisplayPurchases: PropTypes.func.isRequired,
  languageId: PropTypes.number.isRequired
};

export default ExpensesWhoPurchases;
