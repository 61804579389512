import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'components/shared/Forms';

/**
 * Renders Title Selection Button, reacts to the selected title
 * @param {{
 *  handleTitleSelection: () => void,
 *  selectedTitle: string,
 *  titleLabel: string,
 *  titleLabelCode: string
 * }} param0
 */
const TitleSelectionButton = ({
  handleTitleSelection,
  selectedTitle,
  titleLabel,
  titleLabelCode
}) => {
  const notSelectedClassnames = 'btn btn-outline-primary btn-lg btn-amex';
  const isSelectedClassname =
    titleLabelCode === selectedTitle ? 'btn-amex-selected' : '';
  return (
    <div
      className="btn-group mr-2"
      role="group"
      aria-label={titleLabel}
      key={titleLabelCode}
    >
      <Button
        id={`btn-titleSelection-${titleLabelCode}`}
        type="button"
        className={`${notSelectedClassnames} ${isSelectedClassname}`}
        onClick={handleTitleSelection}
      >
        {titleLabel}
      </Button>
    </div>
  );
};

TitleSelectionButton.propTypes = {
  handleTitleSelection: PropTypes.func.isRequired,
  selectedTitle: PropTypes.string.isRequired,
  titleLabel: PropTypes.string.isRequired,
  titleLabelCode: PropTypes.string.isRequired
};

export default TitleSelectionButton;
