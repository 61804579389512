import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { REGEX, PLACEHOLDER_CARD } from 'constants/constants';
import { obfuscateCard } from 'utils/CardHelper';
import BenefitsList from './BenefitsList';
import './_CheckCoverPage.scss';

const isAcceptableCardNumber = numberField =>
  REGEX.AMEX_CARD.test(String(numberField).toLowerCase());

const CheckCoverBody = ({
  handleSubmit,
  cardNumberField,
  handleUserInput,
  modalErrorMessage,
  benefits,
  goTo
}) => {
  const { t } = useTranslation();
  const buttonClass = value =>
    isAcceptableCardNumber(value) ? 'btn-primary' : 'btn-disabled';

  return (
    <>
      <form onSubmit={handleSubmit} className="form-inline">
        <div className="row">
          <div className="col">
            <input
              id="cardNumber"
              type="text"
              required
              className="form-control mb-2 mr-sm-2"
              name="cardNumber"
              placeholder={obfuscateCard(PLACEHOLDER_CARD)}
              value={cardNumberField.replace(/ /g, '')}
              onChange={handleUserInput}
              maxLength="15"
            />
            <button
              className={`btn btn-default btn-amex btn-amex-selected btn-lg ok-button ${buttonClass(
                cardNumberField
              )} mb-2`}
              disabled={
                !isAcceptableCardNumber(cardNumberField.replace(/ /g, ''))
              }
              onClick={() => handleSubmit()}
              type="button"
            >
              {t('commonOk')}
            </button>
          </div>
        </div>
      </form>
      {modalErrorMessage && (
        <div className="row py-4">
          <div className="col">{t(modalErrorMessage)}</div>
        </div>
      )}
      <BenefitsList
        benefits={benefits}
        modalErrorMessage={modalErrorMessage}
        goTo={goTo}
      />
    </>
  );
};

CheckCoverBody.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  cardNumberField: PropTypes.string.isRequired,
  handleUserInput: PropTypes.func.isRequired,
  modalErrorMessage: PropTypes.string,
  benefits: PropTypes.arrayOf(PropTypes.string),
  goTo: PropTypes.func.isRequired
};

CheckCoverBody.defaultProps = {
  modalErrorMessage: '',
  benefits: undefined
};

export default CheckCoverBody;
