import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
  DATES_STEP,
  TASKLIST_STEP,
  WHAT_HAPPENED_STEP,
  EXPENSES_STEP,
  PREVIOUS_STEP,
  BANK_STEP
} from 'constants/claim';
import StepListItem from './StepListItem';

const StepList = props => {
  const { t } = useTranslation();
  const btnLabel =
    props.claimStep === TASKLIST_STEP
      ? t('stepBtnStart')
      : t('stepBtnContinue');
  return (
    <div className="mb-3 mt-4">
      <h5 className="mb-3">{t('stepListTitle')}</h5>
      <div className="container">
        <StepListItem
          title={t('stepHappenTitle')}
          description={t('stepHappenDescription')}
          button={
            props.claimStep === WHAT_HAPPENED_STEP ||
            props.claimStep === TASKLIST_STEP
          }
          continue={props.claimStep === TASKLIST_STEP}
          onStepClick={() => props.setActivePage(WHAT_HAPPENED_STEP)}
          btnLabel={btnLabel}
        />
        <StepListItem
          title={t('stepDatesTitle')}
          description={t('stepDatesDescription')}
          button={props.claimStep === DATES_STEP}
          continue
          onStepClick={() => props.setActivePage(DATES_STEP)}
          btnLabel={btnLabel}
        />
        <StepListItem
          title={t('stepExpensesTitle')}
          description={t('stepExpensesDescription')}
          button={props.claimStep === EXPENSES_STEP}
          continue
          onStepClick={() => props.setActivePage(EXPENSES_STEP)}
          btnLabel={btnLabel}
        />
        <StepListItem
          title={t('stepPreviousInsuranceTitle')}
          description={t('stepPreviousInsuranceDescription')}
          button={props.claimStep === PREVIOUS_STEP}
          continue
          onStepClick={() => props.setActivePage(PREVIOUS_STEP)}
          btnLabel={btnLabel}
        />
        <StepListItem
          title={t('stepBankTitle')}
          description={t('stepBankDescription')}
          button={props.claimStep === BANK_STEP}
          continue
          onStepClick={() => props.setActivePage(BANK_STEP)}
          btnLabel={btnLabel}
        />
      </div>
    </div>
  );
};

StepList.propTypes = {
  claimStep: PropTypes.number.isRequired,
  setActivePage: PropTypes.func.isRequired
};

export default StepList;
