import { PAGE_BY_STEP_ID } from 'constants/claim';

export const getNextStep = step => {
  return step in PAGE_BY_STEP_ID ? PAGE_BY_STEP_ID[step].nextStep : step;
};

export const getActivePage = step => {
  return step in PAGE_BY_STEP_ID ? PAGE_BY_STEP_ID[step].activePage : step;
};

export const getStepName = step => {
  return step in PAGE_BY_STEP_ID ? PAGE_BY_STEP_ID[step].stepName : step;
};
