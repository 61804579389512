import React from 'react';
import PropTypes from 'prop-types';

const ThankYouContact = props => (
  <div className="mb-4">
    <div className="main-card text-center">
      <h3 className="mb-3">{props.labels.thanksContactInformation}</h3>
      <p
        className="mb-4"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: props.labels.thanksCallCustomerServices
        }}
      />
    </div>
  </div>
);

ThankYouContact.propTypes = {
  userDetails: PropTypes.shape({}).isRequired,
  labels: PropTypes.shape({
    thanksContactInformation: PropTypes.string.isRequired,
    thanksCallCustomerServices: PropTypes.string.isRequired
  }).isRequired
};

export default ThankYouContact;
