import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Label from 'components/shared/Forms/Label';
import LabelFor from 'components/shared/Forms/LabelFor';
import { Row, Col } from 'components/shared/Table';
import './_TextFieldButton.scss';
import LinkButton from 'components/Buttons/LinkButton';
import HelpText from './HelpText';

const TextFieldButton = props => {
  const { t } = useTranslation();

  /**
   *
   * @param {Array} errorMessages Renders validation messages under the field.
   * Supports multiple fields
   * TODO: stop supporting pure object, it should only receive array of error object for code simplicity
   */
  const renderErrorMessages = (errorMessages, showAllErrors) => {
    if (typeof errorMessages == 'string')
      return <div className="invalid-feedback">{t(errorMessages)}</div>;
    if (showAllErrors) {
      return errorMessages.map(errMsg => (
        <div key={errMsg} className="invalid-feedback">
          {t(errMsg)}
        </div>
      ));
    }
    return <div className="invalid-feedback">{t(errorMessages[0])}</div>;
  };
  const isInvalid =
    props.errorFields[props.name] && props.errorFields[props.name].hasError;
  const getClass = base =>
    // eslint-disable-next-line no-nested-ternary
    `${base}  ${isInvalid ? 'is-invalid' : props.value ? 'is-valid' : ''}`;

  const handleOnBlur = e => {
    if (props.onBlur) props.onBlur(e);
    props.onValidate(e.target.name, e.target.value);
  };
  return (
    <div className={getClass('form-group')}>
      <LabelFor name={props.name} className="col col-md-12 col-lg-12 px-0">
        <Row>
          <Col className="col-md-8">
            <Label text={props.label} isRequired={props.required} />
          </Col>
          <Col className="d-flex justify-content-end col-md-4">
            <LinkButton
              text={props.labelLink}
              className="textFieldButton"
              action={props.buttonAction}
            />
          </Col>
        </Row>
        {props.helpText && <HelpText name={props.name} text={props.helpText} />}
        <input
          type={props.type}
          className={getClass('form-control')}
          onChange={e => {
            props.onValueChange(e);
          }}
          name={props.name}
          placeholder={props.placeholder}
          disabled={props.disabled}
          aria-describedby={props.ariaDescribedby}
          maxLength={props.maxLength}
          value={props.value}
          onBlur={handleOnBlur}
          onPaste={props.onPaste}
          autoComplete={props.autoComplete ? 'on' : 'off'}
        />
        {isInvalid &&
          renderErrorMessages(
            props.errorFields[props.name].message,
            props.showAllErrors
          )}
      </LabelFor>
    </div>
  );
};

TextFieldButton.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  labelLink: PropTypes.string,
  value: PropTypes.string,
  onValueChange: PropTypes.func,
  maxLength: PropTypes.number,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  helpText: PropTypes.string,
  disabled: PropTypes.bool,
  showAllErrors: PropTypes.bool,
  required: PropTypes.bool,
  errorFields: PropTypes.shape({
    hasError: PropTypes.bool,
    message: PropTypes.string
  }),
  ariaDescribedby: PropTypes.string,
  onValidate: PropTypes.func,
  onPaste: PropTypes.func,
  onBlur: PropTypes.func,
  autoComplete: PropTypes.bool,
  buttonAction: PropTypes.func
};

TextFieldButton.defaultProps = {
  label: '',
  labelLink: '',
  value: undefined,
  maxLength: 100,
  placeholder: '',
  type: 'text',
  helpText: '',
  disabled: false,
  showAllErrors: false,
  required: false,
  ariaDescribedby: '',
  errorFields: {},
  onValidate: () => {},
  onPaste: () => {},
  onValueChange: () => {},
  autoComplete: false,
  onBlur: undefined,
  buttonAction: () => {}
};

export default TextFieldButton;
