import i18n from 'i18next';
import Backend from 'i18next-xhr-backend';
import { initReactI18next } from 'react-i18next';

export const initI18Next = (defaultLanguage, marketLanguages = []) => {
  i18n
    .use(Backend)
    .use(initReactI18next)
    .init({
      fallbackLng: false,
      preload: marketLanguages,
      lng: defaultLanguage,
      backend: {
        loadPath: `assets/locales/{{lng}}.json`
      },
      react: {
        useSuspense: true
      },
      debug: false,
      load: 'currentOnly',
      returnEmptyString: true,
      interpolation: {
        escapeValue: false
      }
    });

  return i18n;
};

export default i18n;
