import React from 'react';
import PropTypes from 'prop-types';
import CustomModal from '../../shared/components/CustomModal';

const CardNumberCustomModal = props => {
  const {
    modalErrorMessage,
    modalContentLabel,
    notFound,
    openPhoneAXAModal,
    openNotRecognisedCard,
    closeModal
  } = props;

  return (
    <>
      <CustomModal
        isOpen={modalErrorMessage !== ''}
        contentLabel={modalContentLabel}
        shouldCloseOnOverlayClick={false}
        overlayClassName="overlay"
        className="modal-frame"
        handleConfirmationOk={closeModal}
        description={modalErrorMessage}
        labelOk="commonOk"
      />
      <CustomModal
        isOpen={notFound}
        contentLabel={modalContentLabel}
        shouldCloseOnOverlayClick={false}
        overlayClassName="overlay"
        className="modal-frame"
        handleConfirmationOk={openPhoneAXAModal}
        handleConfirmationCancel={openNotRecognisedCard}
        description="cardIsCurrencyCorporate"
        labelOk="commonYes"
        labelCancel="commonNo"
      />
    </>
  );
};

CardNumberCustomModal.propTypes = {
  modalErrorMessage: PropTypes.string.isRequired,
  modalContentLabel: PropTypes.string.isRequired,
  notFound: PropTypes.bool.isRequired,
  openPhoneAXAModal: PropTypes.func.isRequired,
  openNotRecognisedCard: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired
};

export default CardNumberCustomModal;
