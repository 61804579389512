import {
  SET_POLICY_VALIDATION_RESULT,
  CLEAN_POLICY_VALIDATION_RESULT
} from 'actions/feature/policy';

import initialState from './initialState';

export default function policyValidation(
  state = initialState.policyValidation,
  action
) {
  switch (action.type) {
    case SET_POLICY_VALIDATION_RESULT:
      return {
        ...state,
        isValid: action.payload.isValid,
        message: action.payload.message
      };
    case CLEAN_POLICY_VALIDATION_RESULT:
      return initialState.policyValidation;
    default:
      return state;
  }
}
