import {
  hasCaptcha,
  generateCaptchaScript,
  makeRecaptchaVisible
} from './core';
import { CAPTCHA_KEY } from '../../constants/constants';

export const initializeRecaptcha = () => {
  const RECAPTCHA_URL = `https://www.google.com/recaptcha/api.js?render=${CAPTCHA_KEY}`;
  if (hasCaptcha()) {
    return makeRecaptchaVisible(false);
  }
  try {
    const script = generateCaptchaScript(RECAPTCHA_URL);
    document.head.appendChild(script);
  } catch (error) {
    return error;
  }
};

export const getRecaptchaToken = async () => {
  if (window.grecaptcha) {
    const token = await window.grecaptcha.execute(CAPTCHA_KEY, {
      action: 'ecommerce'
    });
    if (!token) {
      throw new Error(`There's a problem generating the token`);
    }
    return token;
  }
  throw new Error('Error, Recaptcha Is not Initialized');
};
