export const API_REQUEST = '[app] API Request';

export const apiRequest = (
  method,
  url,
  headers,
  body,
  meta,
  onSuccess,
  onError
) => ({
  type: API_REQUEST,
  payload: body,
  meta: { ...meta, method, url, headers, onSuccess, onError }
});
