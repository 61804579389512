/* eslint-disable react/no-did-update-set-state */
import 'flatpickr/dist/themes/material_blue.css';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Moment from 'moment';
import { withTranslation } from 'react-i18next';

import {
  CARDNUMBER_INPUT_NAME,
  PERSONALNUMBER_INPUT_NAME,
  DATE_FORMAT,
  LANGUAGE_ID_MAPS
} from 'constants/constants';
import {
  validateEmail,
  validateCard,
  validatePersonalNumber
} from 'utils/validationHelper';
import FlatPickrWithBlur from '../FlatPickrWithBlur';

export class RegistrationFormInputComponent extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.validateEmail = this.validateInputEmail.bind(this);
    this.validateCard = this.validateInputCard.bind(this);
    this.validatePersonalNumber = this.validateInputPersonalNumber.bind(this);
    this.handleOnBlurField = this.handleOnBlurField.bind(this);
    this.handleOnTyping = this.handleOnTyping.bind(this);
    this.handleChangeInputEmail = this.handleChangeInputEmail.bind(this);
    this.handleChangeInputPersonalNumber = this.handleChangeInputPersonalNumber.bind(
      this
    );
    this.handleChangeInputPersonalNumberClaimant = this.handleChangeInputPersonalNumberClaimant.bind(
      this
    );
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.isMatching !== this.props.isMatching ||
      (!this.props.isMatching &&
        this.props.validation.formControl === 'is-valid')
    ) {
      if (this.props.isMatching) {
        this.props.onValidationChange({
          formControl: 'is-valid',
          isInValidText: ''
        });
      } else {
        this.props.onValidationChange({
          formControl: 'is-invalid',
          isInValidText: this.props.emailMatchErrorMsg
        });
      }
    }
  }

  validateInputEmail(email) {
    return validateEmail(email);
  }

  validateInputPersonalNumber(personalNumber) {
    return validatePersonalNumber(personalNumber);
  }

  validateInputCard(cardNumber) {
    return validateCard(cardNumber);
  }

  handleChange(e) {
    this.props.onInputChange(e);
  }

  handleChangeInputEmail(e) {
    if (!this.props.value || this.validateInputEmail(this.props.value)) {
      this.props.onValidationChange({
        formControl: 'is-valid'
      });
      if (this.props.onBlur) {
        this.props.onBlur(e);
      }
    } else {
      this.props.onValidationChange({
        formControl: 'is-invalid',
        isInValidText: this.props.t('commonFromEmailValidErrorMsg')
      });
    }
  }

  handleChangeInputPersonalNumber(e) {
    if (this.validateInputPersonalNumber(this.props.value)) {
      this.props.onValidationChange({
        formControl: 'is-valid'
      });
      if (this.props.onBlur) {
        this.props.onBlur(e);
      }
    } else {
      this.props.onValidationChange({
        formControl: 'is-invalid',
        isInValidText: this.props.t('commonFormPersonalNumberValidErrorMsg')
      });
    }
  }

  handleChangeInputPersonalNumberClaimant(e) {
    if (
      !this.props.value ||
      this.validateInputPersonalNumber(this.props.value)
    ) {
      this.props.onValidationChange({
        formControl: 'is-valid'
      });
      if (this.props.onBlur) {
        this.props.onBlur(e);
      }
    } else {
      this.props.onValidationChange({
        formControl: 'is-invalid',
        isInValidText: this.props.t('commonFormPersonalNumberValidErrorMsg')
      });
    }
  }

  handleValidation() {
    const isValueDefined = this.props.value != null;
    const isValidMandatoryField =
      this.props.mandatory && this.props.value && this.props.value.length > 0;

    if (isValueDefined && (!this.props.mandatory || isValidMandatoryField)) {
      if (this.props.type === 'email') {
        if (this.validateInputEmail(this.props.value)) {
          this.props.onValidationChange({ formControl: 'is-valid' });
        } else {
          this.props.onValidationChange({
            formControl: 'is-invalid',
            isInValidText: this.props.t('commonFromEmailValidErrorMsg')
          });
        }
      } else if (this.props.name === CARDNUMBER_INPUT_NAME) {
        if (this.validateInputCard(this.props.value)) {
          this.props.onValidationChange({ formControl: 'is-valid' });
        } else {
          this.props.onValidationChange({
            formControl: 'is-invalid',
            isInValidText: this.props.t('commonFormCardValidErrorMsg')
          });
        }
      } else if (this.props.type === PERSONALNUMBER_INPUT_NAME) {
        if (this.validateInputPersonalNumber(this.props.value)) {
          this.props.onValidationChange({ formControl: 'is-valid' });
        } else {
          this.props.onValidationChange({
            formControl: 'is-invalid',
            isInValidText: this.props.t('commonFormPersonalNumberValidErrorMsg')
          });
        }
      } else {
        this.props.onValidationChange({ formControl: 'is-valid' });
      }
    } else {
      this.props.onValidationChange({ formControl: 'is-invalid' });
    }
  }

  handleOnBlurField(e) {
    // onBlur email triggers field validation
    switch (this.props.type) {
      case 'email':
        this.handleChangeInputEmail(e);
        break;
      case 'personalNumber':
        this.handleChangeInputPersonalNumber(e);
        break;
      case 'personalNumberClaimant':
        this.handleChangeInputPersonalNumberClaimant(e);
        break;
      case 'date':
        this.handleChange(e);
        break;
      default:
        this.handleValidation();
        break;
    }
  }

  handleOnTyping() {
    this.props.onValidationChange({
      formControl: ''
    });
  }

  render() {
    const labelTitle = this.props.label && (
      <strong>{`${this.props.label}`}</strong>
    );
    const helpText = this.props.helpText && (
      <small id={`${this.props.name}Help`} className="form-text text-muted">
        {`${this.props.helpText}`}
      </small>
    );

    let locationClass = '';
    let locationInputClass = '';
    let idLocation = '';
    let idLocationInput = '';
    if (this.props.isLocation) {
      locationClass = 'row no-gutters location-input';
      locationInputClass = '';
      idLocation = 'searchBoxContainer';
      idLocationInput = 'searchBox';
    }

    const className = `form-control ${this.props.validation.formControl} ${locationInputClass}`;
    const { disabled } = this.props;
    const commonProps = {
      className,
      disabled,
      id: this.props.isLocation ? idLocationInput : `${this.props.name}`,
      style: styles.getInputStyle(this.props.isLocation),
      name: `${this.props.name}`,
      value: this.props.value,
      'aria-describedby': this.props.ariaDescribedby,
      placeholder: this.props.placeholder,
      required: this.props.mandatory,
      maxLength: this.props.maxLength,
      onPaste: this.props.onPaste,
      onBlur: this.handleOnBlurField,
      autoComplete: this.props.autoComplete,
      onFocus: this.handleOnTyping,
      max: this.props.type === 'date' ? '2999-12-01' : undefined
    };

    const inputComp =
      this.props.type === 'date' ? (
        <FlatPickrWithBlur
          onChange={e => {
            this.handleChange({
              target: {
                name: commonProps.name,
                value: e[0] && Moment(e[0]).format(DATE_FORMAT)
              }
            });
            this.handleValidation();
          }}
          options={{
            altInput: true,
            altInputClass: className,
            altFormat: 'd/m/Y',
            allowInput: true
          }}
          {...commonProps}
          language={LANGUAGE_ID_MAPS[this.props.language]}
        />
      ) : (
        <input
          type={`${this.props.type}`}
          onChange={this.handleChange}
          {...commonProps}
        />
      );

    return (
      <div className="form-group">
        <label
          htmlFor={`${this.props.name}`}
          className={`label-input col col-md-8 col-lg-${this.props.lgColSize} px-0`}
        >
          {labelTitle}
          {helpText}
          <div id={idLocation} className={locationClass}>
            {inputComp}
            {this.props.isLocation ? (
              <div style={styles.divStyle}>
                <button
                  className="btn btn-default btn-amex btn-amex-selected"
                  onClick={() => this.props.locateMe()}
                  type="button"
                >
                  <i
                    style={styles.buttonWidth}
                    className="material-icons align-middle"
                  >
                    gps_fixed
                  </i>
                </button>
              </div>
            ) : (
              ''
            )}
            {this.props.validation.formControl === 'is-invalid' ? (
              <div className="invalid-feedback">
                {this.props.validation.isInValidText}
              </div>
            ) : (
              ''
            )}
          </div>
        </label>
      </div>
    );
  }
}

const styles = {
  divStyle: {
    display: 'flex'
  },
  buttonContainer: {
    flex: 1
  },
  buttonWidth: {
    width: 30
  },
  getInputStyle: isLocation => (isLocation ? { flex: 1 } : {})
};

RegistrationFormInputComponent.propTypes = {
  onInputChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  type: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  ariaDescribedby: PropTypes.string,
  helpText: PropTypes.string,
  mandatory: PropTypes.bool.isRequired,
  onBlur: PropTypes.func.isRequired,
  onValidationChange: PropTypes.func.isRequired,
  validation: PropTypes.shape({
    formControl: PropTypes.string.isRequired,
    isInValidText: PropTypes.string
  }).isRequired,
  isMatching: PropTypes.bool,
  emailMatchErrorMsg: PropTypes.string,
  maxLength: PropTypes.number,
  autoComplete: PropTypes.string,
  isLocation: PropTypes.bool,
  locateMe: PropTypes.func,
  onPaste: PropTypes.func.isRequired,
  max: PropTypes.string,
  language: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  t: PropTypes.func.isRequired,
  lgColSize: PropTypes.number
};
RegistrationFormInputComponent.defaultProps = {
  max: undefined,
  maxLength: 100,
  autoComplete: 'off',
  type: 'text',
  helpText: '',
  ariaDescribedby: '',
  placeholder: '',
  isMatching: true,
  emailMatchErrorMsg: '',
  isLocation: false,
  locateMe: () => {},
  disabled: false,
  lgColSize: 6
};

export default withTranslation()(RegistrationFormInputComponent);
