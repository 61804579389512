import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import ReactModal from 'react-modal';
import DocumentLinkComponent from 'components/HowToClaim/Shared/DocumentLinkComponent';
import { Row, Col } from 'components/shared/Table';
import { Button } from 'components/shared/Forms';
import HowToClaimLearnMore from './HowToClaimLearnMore';

class HowToClaimInnerCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false
    };
    this.closeModal = this.closeModal.bind(this);
  }

  closeModal() {
    this.setState({ showModal: false });
  }

    render() {
        const handleShowCards = () => {
            this.setState(prevState => ({ showModal: !prevState.displayCards }));
        };
        const { t } = this.props;        

        const paragraphSection = text => <p className="content">{t(text)}</p>;

        const cardItems =
         
               ( this.props.cardContainer.cards.length > 1 ? this.props.cardContainer.cards.splice(0, 2) : null,
                this.props.cardContainer.cards.map(item => {
                    return (
                        <div className="col-xs-12 col-sm-12 col p-2">

                            {paragraphSection('SupportingDocuments1')}
                            <br />
                            {paragraphSection('SupportingDocuments2')}
                            <br />
                            {paragraphSection('IfSomeoneWasIll')}
                            <br />
                            <DocumentLinkComponent
                                buttonMedicalReport={item.buttonMedicalReport}
                                buttonGDPR={item.buttonGDPR}
                            />
                            <br />
                            {paragraphSection('ReceiptsForPurchase')}
                        </div>)
                }))
       
    return (
      <Row>
        <Col>
          <HowToClaimLearnMore
            title={this.props.learnMoreTitle}
            onLearnMoreAction={handleShowCards}
            learnMoreFooter={this.props.learnMoreFooter}
          />

          <ReactModal
            className="howToClaim-modal"
            isOpen={this.state.showModal}
            contentLabel="contentLabel"
            onRequestClose={this.closeModal}
          >
            {this.state.showModal && (
              <h3 key={this.props.learnMoreCardTitle}>
                {this.props.learnMoreCardTitle}
              </h3>
            )}
            {this.state.showModal && (
              <Row className="no-gutters how-to-claim">{cardItems}</Row>
            )}
            <Row>
              <Col className="col-12 text-center">
                {t('Remember')}
              </Col>
            </Row>
            <Row>
              <Col className="col-12 text-center">
                <Button
                  type="submit"
                  className="btn btn-primary btn-lg mt-3 btn-amex-continue"
                  onClick={this.closeModal}
                >
                  {t(this.props.modalButton.title)}
                </Button>
              </Col>
            </Row>
          </ReactModal>
        </Col>
      </Row>
    );
  }
}

HowToClaimInnerCard.propTypes = {
  t: PropTypes.func.isRequired,
  learnMoreFooter: PropTypes.string.isRequired,
  learnMoreModalFooter: PropTypes.string.isRequired,
  learnMoreTitle: PropTypes.string.isRequired,
  learnMoreCardTitle: PropTypes.string.isRequired,
  modalButton: PropTypes.shape({ title: PropTypes.string.isRequired })
    .isRequired,
  cardContainer: PropTypes.shape({
    cards: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        items: PropTypes.arrayOf(PropTypes.string).isRequired,
        button: PropTypes.shape({})
      })
    ).isRequired
  }).isRequired
};

export default withTranslation()(HowToClaimInnerCard);
