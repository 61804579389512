export const GET_DOCUMENT_LINK_REQUESTED = '[how to claim] get document link';
export const GET_DOCUMENT_LINK_SUCCEEDED =
  '[how to claim] get document link success';
export const GET_DOCUMENT_LINK_FAILED = '[how to claim] get document link fail';
export const SET_DOCUMENT_LINK = '[how to claim] set document link';

export const getDocumentLink = type => ({
  type: GET_DOCUMENT_LINK_REQUESTED,
  payload: { type }
});

export const setDocumentLink = (link, type) => ({
  type: SET_DOCUMENT_LINK,
  payload: { link, type }
});
