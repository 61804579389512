import { BING_MAPS_URL } from 'constants/constants';
/**
 * Adds the Bing Maps API script in html head
 *
 * @param {Object}   answers           Object containing all questions answered
 */

const loadBingMapsScript = callback => {
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.async = true;
  script.defer = true;
  script.src = `${BING_MAPS_URL}${callback}`;
  document.getElementsByTagName('head')[0].appendChild(script);
};

export default loadBingMapsScript;
