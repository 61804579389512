import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'components/shared/Table';
import { Button } from 'components/shared/Forms';
import ButtonBack from 'components/shared/Custom/ButtonBack';
import {
    MY_CLAIMS_PAGE,
    ALERT_TYPES,
    MAX_LIMITS_DOCUMENTS
} from 'constants/constants';
import UploadComponent from 'components/UploadQuestion/UploadComponent';
import DocumentsListComponent from 'components/DocumentsListComponent/DocumentsListComponent';
import { goTo } from 'actions/core/app.navigation';
import { getDocuments, setClaimDocumentsList } from 'actions/feature/claim';
import { addAlert } from 'actions/alertsActions';
import { Grid } from '@material-ui/core';

import { pageview } from 'utils/analytics';
import { getRoute } from 'utils/routeHelper';
import { appInsights } from 'ApplicationInsights';

export const UploadClaimDocumentPage = props => {    
    let claims = props.claim == undefined ? props.claim1 : props.claim;
    let ISREVIEWPAGE = props.claim1 == undefined ? true : false
    debugger;
    useEffect(() => {
        pageview({
            title: 'Claim-Documents',
            page: getRoute('documents', 'claim')
        });
        appInsights.trackPageView(
            {
                name: 'Claim-Documents',
                uri: getRoute('documents', 'claim')
            }
        )
    }, [claims.id]);
    useEffect(() => {
        props.actions.getDocuments(claims.id);
    }, [claims, props.actions]);
    const { t } = useTranslation();
    const onBackClick = () => {
        props.actions.setClaimDocumentsList({});
        props.actions.goTo(MY_CLAIMS_PAGE);
    };
    const onDocumentUploded = uploadedFileId => {
        if (uploadedFileId) {
            props.actions.addAlert(ALERT_TYPES.SUCCESS, t('fileUploadSuccess'));
            props.actions.getDocuments(claims.id);
        }
    };
    const Title = `${t('uploadPageDocumentsTitle')} ${claims.id}`;    
    const showListDocuments = props.documentList && props.documentList.length > 0;
    const isMaxLimitsOfDocuments =
        props.documentList && props.documentList.length === MAX_LIMITS_DOCUMENTS;
    const RenderComponents = <Row className="upload-container">
        <Col className="upload-items">
            <Row>

                <Col>
                    {ISREVIEWPAGE ? null :
                        <ButtonBack
                            onClick={onBackClick}
                            labelText={t('buttonBackAddDocuments')}
                        />}
                </Col>
            </Row>
            <Row className="spaces-items">
                <Col>
                    <Row>                        
                        <h3>{ISREVIEWPAGE ? t('Additional Document Evidence Title') : Title}</h3>
                    </Row>
                    <Row>
                        <br />
                        <span className="notes-preview">{ISREVIEWPAGE ? t('Additional Document Evidence Description') : t('uploadPageDocumentsDescription')}</span>                        
                    </Row>
                </Col>
            </Row>
            <Grid container direction="column" justify="center" alignItems="center">
                <Grid item xs={12} sm={7} style={{ marginLeft: -35 }}>
                    {isMaxLimitsOfDocuments ? (
                        <span className="checked-header mb-2"><h3>{t('fileUploadLimitsMax')}</h3></span>
                    ) : (
                        <div>
                            <UploadComponent
                                onFileUploaded={onDocumentUploded}
                                claimId={claims.id}
                                benefitLabel={claims.benefit}
                                onAddAlert={props.actions.addAlert}
                                learnMoreHide={ISREVIEWPAGE}
                                shouldShow
                            />
                        </div>
                    )}
                </Grid>

                <Grid
                    item
                    xs={12}
                    sm={6}
                    style={ISREVIEWPAGE ? { maxHeight: 150, overflow: 'auto', width: '100%' } : { maxHeight: 250, overflow: 'auto', width: '100%' }}
                >
                    {showListDocuments && (
                        <DocumentsListComponent
                            documentsList={props.documentList}
                            claimId={claims.id}
                            email={props.userInfo.email}
                            hideDownloadbutton={ISREVIEWPAGE}
                        />
                    )}
                </Grid>
            </Grid>
            <Row>
                <Col>
                    {ISREVIEWPAGE ? null :
                        <Button
                            type="button"
                            className="btn btn-primary btn-lg btn-block mt-3 btn-amex-continue"
                            onClick={onBackClick}
                        >
                            {t('uploadPageBackToHome')}
                        </Button>}
                </Col>
            </Row>
        </Col>
    </Row>
    return (
        ISREVIEWPAGE ? <div className="main-card"> <div className="checked-header mb-3">{RenderComponents} </div> </div> : RenderComponents
                      
    );
};

const mapStateToProps = state => ({
    claim1: state.claimDocumentsList.claim,
    documentList: state.claimDocumentsList.documentList,
    userInfo: state.userInfo
});

const mapDispatchToProps = dispatch => ({
    actions: {
        ...bindActionCreators({ goTo }, dispatch),
        ...bindActionCreators({ getDocuments, setClaimDocumentsList }, dispatch),
        ...bindActionCreators({ addAlert }, dispatch)
    }
});

UploadClaimDocumentPage.propTypes = {
    claim: PropTypes.shape({
        id: PropTypes.string.isRequired,
        status: PropTypes.number,
        benefit: PropTypes.string
    }).isRequired,
    claim1: PropTypes.shape({
        id: PropTypes.string.isRequired,
        status: PropTypes.number,
        benefit: PropTypes.string
    }).isRequired,
    documentList: PropTypes.arrayOf({
        claimDataDocumentId: PropTypes.number,
        claimDataId: PropTypes.number,
        externalId: PropTypes.string,
        name: PropTypes.string,
        uploadedDate: PropTypes.string
    }),
    userInfo: PropTypes.shape({
        name: PropTypes.string,
        surname: PropTypes.string,
        email: PropTypes.string
    }).isRequired,
    actions: PropTypes.shape({
        goTo: PropTypes.func.isRequired,
        setClaimDocumentsList: PropTypes.func.isRequired,
        addAlert: PropTypes.func.isRequired,
        getDocuments: PropTypes.func.isRequired
    }).isRequired
};

UploadClaimDocumentPage.defaultProps = {
    documentList: []
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UploadClaimDocumentPage);
