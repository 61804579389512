import React from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import { useTranslation } from 'react-i18next';
import CardItemList from './CardItemList';
import classNames from '../classNames';

import './_UploadModal.scss';

const uploadModalClasses = {
  mt3: 'mt-3'
};

const colCenterClass = `${classNames.col} ${classNames.textCenter}`;
const learnMoreBtnClass = `${classNames.btn} ${classNames.btnPrimary} ${classNames.btnLg} ${uploadModalClasses.mt3} ${classNames.btnAmexContinue}`;
const UploadModal = ({ closeModal, learnMore, showModal }) => {
  const { t } = useTranslation();

  return (
    <ReactModal
      isOpen={showModal}
      contentLabel="contentLabel"
      className="upload-modal"
      onRequestClose={closeModal}
    >
      {showModal && learnMore.title && (
        <h3 id="learnMoreTitle">{t(learnMore.title)}</h3>
      )}
      {showModal && <CardItemList cardContainer={learnMore.cardContainer} />}
      <div className={classNames.row}>
        <div className={colCenterClass} id="learnMoreFooter">
          {t('Remember')}
        </div>
      </div>
      <div className={classNames.row}>
        <div className={colCenterClass}>
          <button
            type="submit"
            className={learnMoreBtnClass}
            onClick={closeModal}
          >
            {learnMore.modalButton && t(learnMore.modalButton.title)}
          </button>
        </div>
      </div>
    </ReactModal>
  );
};

UploadModal.propTypes = {
  learnMore: PropTypes.shape({
    modalFooter: PropTypes.string,
    modalButton: PropTypes.shape({
      title: PropTypes.string
    }),
    title: PropTypes.string
  }),
  closeModal: PropTypes.func.isRequired,
  showModal: PropTypes.bool.isRequired
};

UploadModal.defaultProps = {
  learnMore: {}
};

export default UploadModal;
