import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { pageview } from 'utils/analytics';
import { getRouteForClaim } from 'utils/routeHelper';
import { EXPENSES_STEP } from 'constants/claim';

import { updateClaimData } from 'actions/claimActions';
import { saveClaim, saveClaimAndContinue } from 'actions/feature/claim';

import ExpensesComponent from 'components/ExpensesComponent';
import { ADDITIONAL_RELATIONSHIPS } from 'constants/constants';
import { appInsights } from 'ApplicationInsights';

export const ExpensesPage = props => {
  React.useEffect(() => {
    pageview({
      title: 'Claim-Wizard',
      page: getRouteForClaim(
        props.languageId,
        props.currentClaim.benefitId,
        EXPENSES_STEP
      )
    });
    appInsights.trackPageView(
      {
        name: 'Claim-Wizard',
      uri: getRouteForClaim(
        props.languageId,
        props.currentClaim.benefitId,
        EXPENSES_STEP
      )
      }
    )
  }, [props.currentClaim.benefitId, props.languageId]);

  const updateContacts = contacts => {
    const primaryClaimant = props.currentClaim.contacts.find(
      c => c.relationship === ADDITIONAL_RELATIONSHIPS.PRIMARY_CLAIMANT
    );
    props.actions.updateClaimData({
      ...props.currentClaim,
      contacts: [primaryClaimant, ...contacts]
    });
  };

  const updateExpenses = expenses =>
    props.actions.updateClaimData({
      ...props.currentClaim,
      expenses
    });

  const updateDisplaySection = section => props.setDisplaySection(section);

  const handleOnSaveClaimAndContinue = () =>
    props.actions.saveClaimAndContinue();

  const additionClaimant = props.currentClaim.contacts.filter(
    c => c.relationship !== ADDITIONAL_RELATIONSHIPS.PRIMARY_CLAIMANT
  );

  return (
    <ExpensesComponent
      typeOfExpenses={props.typeOfExpenses}
      claimId={props.currentClaim.id}
      benefitId={props.currentClaim.benefitId}
      updateContacts={updateContacts}
      updateExpenses={updateExpenses}
      contacts={additionClaimant}
      expenses={props.currentClaim.expenses}
      setDisplaySection={updateDisplaySection}
      saveClaimAndContinue={handleOnSaveClaimAndContinue}
      showHowPaid={props.currentClaim.showHowPaid}
      languageId={props.languageId}
    />
  );
};

const mapStateToProps = state => ({
  currentClaim: state.currentClaim,
  languageId: state.marketLanguages.selectedLanguage,
  actions: PropTypes.shape({
    updateClaimData: PropTypes.func
  }).isRequired
});

const mapDispatchToProps = dispatch => ({
  actions: {
    ...bindActionCreators(
      {
        updateClaimData,
        saveClaim,
        saveClaimAndContinue
      },
      dispatch
    )
  }
});

ExpensesPage.propTypes = {
  typeOfExpenses: PropTypes.arrayOf(PropTypes.number).isRequired,
  setDisplaySection: PropTypes.func.isRequired,
  actions: PropTypes.shape({
    updateClaimData: PropTypes.func,
    saveClaim: PropTypes.func,
    saveClaimAndContinue: PropTypes.func
  }).isRequired,
  currentClaim: PropTypes.shape({
    id: PropTypes.string.isRequired,
    benefitId: PropTypes.number.isRequired,
    showHowPaid: PropTypes.string.isRequired,
    contacts: PropTypes.arrayOf(PropTypes.shape({})),
    expenses: PropTypes.arrayOf(PropTypes.shape({})),
    step: PropTypes.number.isRequired
  }).isRequired,
  languageId: PropTypes.number.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(ExpensesPage);
