import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Grow from '@material-ui/core/Grow';
import { RichText } from 'prismic-reactjs';

import { withTranslation } from 'react-i18next';
import { LinkResolver } from 'utils/PrismicHelper';
import RatingModal from 'Bodiam/components/RatingModal';

class FAQDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visibleAnswer: false,
      visibleModal: false
    };

    this.toggleAnswer = this.toggleAnswer.bind(this);
    this.toggleFaqRatingModal = this.toggleFaqRatingModal.bind(this);
    this.handleSubmitEvaluation = this.handleSubmitEvaluation.bind(this);
  }

  getFaqAnswer() {
    const timeout = { enter: 500, exit: 300 };
    return (
      <Grow
        direction="down"
        in={this.state.visibleAnswer}
        timeout={timeout}
        mountOnEnter
        unmountOnExit
        exit
      >
        <div className="faq-answer px-4 text-left">
          {RichText.render(this.props.data.answer, LinkResolver)}
          <div className="helpfulBtn py-3">
            <button
              onClick={this.toggleFaqRatingModal}
              className="helpfulBtn px-0"
              type="submit"
            >
              {this.props.t('helpfulButton')}
            </button>
          </div>
        </div>
      </Grow>
    );
  }

  toggleAnswer() {
    this.setState(prevState => ({
      visibleAnswer: !prevState.visibleAnswer
    }));
  }

  toggleFaqRatingModal() {
    this.setState(prevState => ({
      visibleModal: !prevState.visibleModal
    }));
  }

  handleSubmitEvaluation(evaluation) {
    this.setState({
      visibleModal: false
    });
    this.props.onSubmitEvaluation(evaluation);
  }

  render() {
    const { data } = this.props;
    const iconVisible = data.icon && data.icon.url;
    const img = iconVisible ? (
      <img
        className="vertical-align-middle"
        src={data.icon.url}
        alt={data.icon.alt}
        width={data.icon.dimensions.width}
        height={data.icon.dimensions.height}
      />
    ) : null;
    return data ? (
      <div className="faq-detail">
        <div className="faq-question row no-gutters px-4">
          <div className="col-2 col-sm-1 question-icon">{img}</div>
          <div className="col-9 col-sm-10 px-2 text-left">
            {RichText.render(data.question, LinkResolver)}
          </div>
          <div className="col-1 toggle-icon">
            <i
              className="material-icons"
              onClick={this.toggleAnswer}
              onKeyUp={this.toggleAnswer}
            >
              {this.state.visibleAnswer ? 'remove_circle' : 'add_circle'}
            </i>
          </div>
        </div>
        {this.getFaqAnswer()}
        {this.state.visibleModal && (
          <RatingModal
            faq={this.props.data}
            version={this.props.version}
            prismicId={this.props.prismicId}
            isOpen={this.state.visibleModal}
            onClose={this.toggleFaqRatingModal}
            onSubmit={this.handleSubmitEvaluation}
          />
        )}
      </div>
    ) : null;
  }
}

FAQDetail.propTypes = {
  t: PropTypes.func.isRequired,
  prismicId: PropTypes.string.isRequired,
  version: PropTypes.string.isRequired,
  data: PropTypes.shape({
    question: PropTypes.object,
    answer: PropTypes.object,
    icon: PropTypes.object
  }).isRequired,
  onSubmitEvaluation: PropTypes.func.isRequired
};

export default withTranslation()(FAQDetail);
