import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ReactModal from 'react-modal';

import { RichText } from 'prismic-reactjs';
import { withTranslation } from 'react-i18next';
import { LinkResolver } from 'utils/PrismicHelper';

export class RatingModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      comment: null,
      like: null
    };

    this.handleCommentChange = this.handleCommentChange.bind(this);
    this.handleLike = this.handleLike.bind(this);
    this.sendRating = this.sendRating.bind(this);
  }

  handleCommentChange(e) {
    this.setState({
      comment: e.target.value
    });
  }

  handleLike(like) {
    this.setState({
      like
    });
  }

  isFormComplete() {
    return this.state.like !== null;
  }

  sendRating() {
    if (this.isFormComplete()) {
      const evaluation = {
        ...this.state,
        prismicId: this.props.prismicId,
        version: this.props.version,
        question: this.props.faq.question[0].text,
        answer: this.props.faq.answer[0].text
      };
      this.props.onSubmit(evaluation);
    }
  }

  renderLikeButton(like) {
    const selectedClass = this.state.like === like ? 'selected' : '';
    return (
      <i
        className={`material-icons vertical-align-middle ${selectedClass}`}
        onClick={() => this.handleLike(like)}
        onKeyDown={() => this.handleLike(like)}
      >
        {like ? 'thumb_up' : 'thumb_down'}
      </i>
    );
  }

  render() {
    return (
      <ReactModal
        isOpen={this.props.isOpen}
        shouldCloseOnOverlayClick={false}
        className="modal-frame rating-modal my-2"
      >
        <div className="h-xs-100 internal-div">
          <div className="row no-gutters w-100 py-2">
            <div className="col-10 px-2 text-left">
              <h3>{this.props.t('faqRatingTitle')}</h3>
            </div>
            <div className="col-2 text-right">
              <button
                className="btn-close p-0"
                onClick={this.props.onClose}
                type="submit"
              >
                <i className="material-icons vertical-align-middle">close</i>
              </button>
            </div>
          </div>

          <div className="">
            {RichText.render(this.props.faq.question, LinkResolver)}
          </div>
          <div className="">
            {RichText.render(this.props.faq.answer, LinkResolver)}
          </div>

          <div className="row no-gutters w-100">
            <div className="col-6 text-center thumb thumb-up">
              {this.renderLikeButton(true)}
            </div>

            <div className="col-6 text-center thumb thumb-down">
              {this.renderLikeButton(false)}
            </div>
          </div>

          <div className="comment-label">
            {this.props.t('ratingWhyTextAreaTitle')}
          </div>
          <div className="description">
            {this.props.t('ratingWhyTextAreaSubtitle')}
          </div>
          <div mt-1>
            <textarea
              onChange={this.handleCommentChange}
              rows={5}
              cols={36}
              className="d-block mb-2 mt-2"
              maxLength="1000"
            />
          </div>

          <div className="row no-gutters w-100 py-2">
            <div className="col">
              <div className="row w-100">
                <div className="col">
                  <button
                    className="btn btn-primary btn-large btn-block btn-amex"
                    onClick={this.sendRating}
                    disabled={this.state.like === null}
                    type="submit"
                  >
                    {this.props.t('commonSubmit')}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ReactModal>
    );
  }
}

RatingModal.propTypes = {
  t: PropTypes.func.isRequired,
  prismicId: PropTypes.string.isRequired,
  version: PropTypes.string.isRequired,
  faq: PropTypes.shape({
    question: PropTypes.object,
    answer: PropTypes.object
  }).isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
};

export default withTranslation()(RatingModal);
