import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { orderBy, maxBy } from 'lodash';

import Grow from '@material-ui/core/Grow';
import Collapse from '@material-ui/core/Collapse';

import { event } from 'utils/analytics';
import { GA_EVENTS, GA_ACTIONS } from 'constants/googleAnalytics';

import { Row, Col } from 'components/shared/Table';
import { Button } from 'components/shared/Forms';
import PurchasesCategoryItem from './PurchasesCategoryItem';
import PurchasesForm from './PurchasesForm';
import { appInsights } from 'ApplicationInsights';

const PurchasesSection = props => {
  const { t } = useTranslation();
  const [showForm, setShowForm] = useState({});
  const [expensesToEdit, setExpensesToEdit] = useState([]);

  const getNewId = () => {
    // This is not a bussines id, It's just to handle the edit operation on expenses. It will not have impacto on the server.
    const max = maxBy(props.expenses, 'id');
    return max ? max.id + Math.floor(Math.random() * 100) : 1;
  };

  const getSelectedCategories = () => Object.values(props.categories);

  const getPurchasesListByCat = category =>
    props.expenses.filter(x => x.type === category);

  const createNewExpense = cat => ({
    id: getNewId(),
    description: '',
    date: '',
    amount: '',
    currencyCode: '',
    sendType: '',
    contactId: null,
    type: cat,
    file: null
  });

  const handleOnAddExpenseClick = category => {
    event(GA_EVENTS.PURCHASEITEM, GA_ACTIONS.ADD, {
      label: category.toString()
    });
    appInsights.trackEvent(
      {
        name:category.toString()
      },
      {
        EventCategory: GA_EVENTS.PURCHASEITEM,
        EventAction: GA_ACTIONS.ADD,
        EventLabel: category.toString()
      }
      )
    const newExpense = createNewExpense(category);
    setExpensesToEdit([...expensesToEdit, newExpense]);

    setShowForm({ ...showForm, [category]: true });
  };

  const handleOnEditeExpenseClick = (category, expenseId) => {
    event(GA_EVENTS.PURCHASEITEM, GA_ACTIONS.EDIT, {
      label: category.toString()
    });
    appInsights.trackEvent(
      {
        name:category.toString()
      },
      {
        EventCategory: GA_EVENTS.PURCHASEITEM,
        EventAction: GA_ACTIONS.EDIT,
        EventLabel: category.toString()
      }
      )
    setExpensesToEdit([
      ...expensesToEdit,
      props.expenses.find(x => x.id === expenseId)
    ]);
    setShowForm({ ...showForm, [category]: true });
  };

  const handleOnRemoveExpenseClick = expenseId => {
    event(GA_EVENTS.PURCHASEITEM, GA_ACTIONS.REMOVE);
    appInsights.trackEvent(
      {
        name:'Remove-Expense'
      },
      {
        EventCategory: GA_EVENTS.PURCHASEITEM,
        EventAction: GA_ACTIONS.REMOVE,
        EventLabel: 'Remove-Expense'
      }
    )
    props.updateExpenses(props.expenses.filter(x => x.id !== expenseId));
  };

  const handleOnUpdateExpenseSubmit = (category, expenseUpdated) => {
    event(GA_EVENTS.PURCHASEITEM, GA_ACTIONS.SAVE, {
      label: category.toString()
    });
    appInsights.trackEvent(
      {
        name:category.toString()
      },
      {
        EventCategory: GA_EVENTS.PURCHASEITEM,
        EventAction: GA_ACTIONS.SAVE,
        EventLabel: category.toString()
      }
    )
    function filterOutExpense(expenseComp) {
      return expenseComp.id !== expenseUpdated.id;
    }

    const clone = props.expenses.filter(filterOutExpense);
    clone.push(expenseUpdated);

    hideFormAndSetFocusOnCategory(category);
    setExpensesToEdit(expensesToEdit.filter(filterOutExpense));
    props.updateExpenses(orderBy(clone, 'id'));
  };

  const handleOnCancelEditClick = category =>
    hideFormAndSetFocusOnCategory(category);

  const hideFormAndSetFocusOnCategory = category => {
    setShowForm({ ...showForm, [category]: false });
    moveToTop();
  };

  const moveToTop = () => {
    try {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    } catch (error) {
      // just a fallback for older browsers
      window.scrollTo(0, 0);
    }
  };

  const saveExpensesToClaim = () => {
    event(GA_EVENTS.PURCHASEITEM, GA_ACTIONS.SAVE);
    appInsights.trackEvent(
      {
        name:'Save-ExpesesToClaim'
      },
      {
        EventCategory: GA_EVENTS.PURCHASEITEM,
        EventAction: GA_ACTIONS.SAVE,
        EventLabel: 'Save-ExpesesToClaim'
      }
    )
    props.handleDisplayPurchases();
  };

  const saveExpensesToClaimAndContinue = () => {
    event(GA_EVENTS.PURCHASEITEM, GA_ACTIONS.SUBMIT);
    appInsights.trackEvent(
      {
        name:'Save-ExpesesToClaimandContinue'
      },
      {
        EventCategory: GA_EVENTS.PURCHASEITEM,
        EventAction: GA_ACTIONS.SUBMIT,
        EventLabel: 'Save-ExpesesToClaimandContinue'
      }
    )
    props.updateExpensesAndContinue();
  };

  const canContinue = props.expenses.length > 0;

  const isRedy = () => Object.keys(showForm).every(k => !showForm[k]);

  const categoryList = getSelectedCategories().map(category => (
    <>
      <Grow in key={category}>
        <PurchasesCategoryItem
          key={`item${category}`}
          category={category}
          showHowPaid={props.showHowPaid}
          onAddExpenseClick={handleOnAddExpenseClick}
          onEditeExpenseClick={handleOnEditeExpenseClick}
          onRemoveExpenseClick={handleOnRemoveExpenseClick}
          expenses={getPurchasesListByCat(category)}
          claimId={props.claimId}
          benefitId={props.benefitId}
          isEditing={!!showForm[category]}
        />
      </Grow>
      <Collapse in={!!showForm[category]} timeout={1000}>
        <PurchasesForm
          key={`form${category}`}
          showForm={!!showForm[category]}
          showHowPaid={props.showHowPaid}
          onSubmitClick={handleOnUpdateExpenseSubmit}
          onCancelClick={handleOnCancelEditClick}
          expense={expensesToEdit.find(x => x.type === category)}
          category={category}
          claimId={props.claimId}
          benefitId={props.benefitId}
        />
      </Collapse>
    </>
  ));

  return (
    <Row className="purchases-list no-gutters">
      <Col>
        <Row className="no-gutters">
          <Col>{categoryList}</Col>
        </Row>
        {isRedy() && (
          <>
            <Row className="m-3">
              <Col>
                <Button
                  type="button"
                  className="btn btn-amex btn-lg"
                  onClick={saveExpensesToClaim}
                  text={t('purchasesAddAnotherBtnLabel')}
                />
              </Col>
            </Row>
            {canContinue && (
              <Row className="m-3">
                <Col>
                  <Button
                    id="btn-purchasesSection-continue"
                    type="button"
                    className="btn btn-amex btn-lg btn-block continue"
                    onClick={saveExpensesToClaimAndContinue}
                    text={t('commonContinue')}
                  />
                </Col>
              </Row>
            )}
          </>
        )}
      </Col>
    </Row>
  );
};

PurchasesSection.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.number).isRequired,
  expenses: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired,
  handleDisplayPurchases: PropTypes.func.isRequired,
  updateExpenses: PropTypes.func.isRequired,
  updateExpensesAndContinue: PropTypes.func.isRequired,
  claimId: PropTypes.string.isRequired,
  benefitId: PropTypes.number.isRequired,
  showHowPaid: PropTypes.bool.isRequired
};

export default PurchasesSection;
