import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import { RichText } from 'prismic-reactjs';
import Grow from '@material-ui/core/Grow';

import { LinkResolver } from 'utils/PrismicHelper';

class BenefitDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false
    };
  }

  toggleVisible = () =>
    this.setState(prevState => ({
      visible: !prevState.visible
    }));

  render() {
    const {
      props: { benefit, t },
      state: { visible },
      toggleVisible
    } = this;
    return (
      <div className="benefit-details text-center">
        {benefit && benefit.example && (
          <p className="benefit-specific">
            {RichText.render(benefit.example, LinkResolver)}
          </p>
        )}
        <div className="text-left">
          <div
            className="key-extensions mb-2"
            onClick={toggleVisible}
            onKeyDown={toggleVisible}
            tabIndex="-1"
            role="button"
          >
            <span className="mr-2 exclusions-label">
              {t('coverPageKeyExclusions')}
            </span>
            {!visible && <i className="material-icons">add_circle</i>}
            {visible && <i className="material-icons">remove_circle</i>}
          </div>
          <Grow
            direction="down"
            in={visible}
            timeout={{ enter: 500, exit: 300 }}
            mountOnEnter
            unmountOnExit
            exit
          >
            {benefit && RichText.render(benefit.key_exclusions, LinkResolver)}
          </Grow>
        </div>
      </div>
    );
  }
}

BenefitDetails.propTypes = {
  t: PropTypes.func.isRequired,
  benefit: PropTypes.shape({
    example: PropTypes.arrayOf(PropTypes.shape({})),
    key_exclusions: PropTypes.arrayOf(PropTypes.shape({}))
  }).isRequired
};

export default withTranslation()(BenefitDetails);
