import {
  SELECT_POLICY_REQUESTED,
  ADD_POLICY_FAILED
} from 'actions/feature/policy';

import initialState from './initialState';

export default function policy(state = initialState.policy, action) {
  switch (action.type) {
    case SELECT_POLICY_REQUESTED:
      return {
        ...state,
        number: action.payload.number,
        name: action.payload.name,
        lastName: action.payload.surname,
        date: action.payload.date
      };
    case ADD_POLICY_FAILED:
      return { ...state };
    default:
      return state;
  }
}
