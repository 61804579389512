export const CREATE_USER_AND_CLAIM_REQUESTED = '[user] create and claim';
export const CREATE_USER_AND_CLAIM_SUCCEEDED =
  '[user] create and claim success';
export const CREATE_USER_AND_CLAIM_FAILED = '[user] create and claim fail';

export const VALIDATE_CARD_NUMBER_AND_CREATE_USER_REQUESTED =
  '[user] validate card number and create user';
export const CREATE_USER_REQUESTED = '[user] validate card succeeded & create';
export const CREATE_USER_SUCCEEDED = '[user] create success';
export const CREATE_USER_FAILED = '[user] create fail';

export const UPDATE_USER_SESSION = '[user] session update';

export const SIGNIN_USER_AND_CREATE_CLAIM_REQUESTED =
  '[user] sign in and create claim';
export const SIGNIN_USER_AND_CREATE_CLAIM_SUCCEEDED =
  '[user] sign in and create claim success';
export const SIGNIN_USER_AND_CREATE_CLAIM_FAILED =
  '[user] sign in and create claim fail';

export const SIGNIN_USER_ADD_NEW_CARD_REQUESTED =
  '[user] sign in and add new card request';
export const SIGNIN_USER_ADD_NEW_CARD_SUCCEEDED =
  '[user] sign in and add new card success';
export const SIGNIN_USER_ADD_NEW_CARD_FAILED =
  '[user] sign in and add new card fail';

export const SIGNIN_USER_REQUESTED = '[user] sign in';
export const SIGNIN_USER_SUCCEEDED = '[user] sign in success';
export const SIGNIN_USER_FAILED = '[user] sign in fail';

export const UPDATE_USER_EMAIL_REQUESTED = '[user] udpate email';
export const UPDATE_USER_EMAIL_SUCCEEDED = '[user] udpate email success';
export const UPDATE_USER_EMAIL_FAILED = '[user] udpate email fail';

export const UPDATE_USER_PHONE_REQUESTED = '[user] udpate phone';
export const UPDATE_USER_PHONE_SUCCEEDED = '[user] udpate phone success';
export const UPDATE_USER_PHONE_FAILED = '[user] udpate phone fail';

export const createUser = (userData, redirectTo, isInBodiam) =>
  isInBodiam
    ? {
        type: VALIDATE_CARD_NUMBER_AND_CREATE_USER_REQUESTED,
        payload: userData,
        meta: { redirectTo }
      }
    : {
        type: CREATE_USER_AND_CLAIM_REQUESTED,
        payload: userData,
        meta: { redirectTo }
      };

export const signInUserAndCreateClaim = (username, password, redirectTo) => ({
  type: SIGNIN_USER_AND_CREATE_CLAIM_REQUESTED,
  payload: { username, password },
  meta: { redirectTo }
});

export const signInUser = (username, password, redirectTo) => ({
  type: SIGNIN_USER_REQUESTED,
  payload: { username, password },
  meta: { redirectTo }
});

/**
 *
 * @param {object: { email: string, password: string }} updateEmail
 * @param {string} redirectTo
 */
export const updateEmailUser = updateEmail => ({
  type: UPDATE_USER_EMAIL_REQUESTED,
  payload: updateEmail
});

export const updatePhoneUser = userInfo => ({
  type: UPDATE_USER_PHONE_REQUESTED,
  payload: userInfo
});
