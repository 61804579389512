import { MARKETS } from 'constants/constants';

import { getFaqIdByName } from './PrismicHelper';
import useTranslation  from 'i18next';

// eslint-disable-next-line no-unused-vars

export function getMarketFromUrl() {
  // eslint-disable-next-line no-restricted-globals
  const urlFields = window.location.pathname.split('/');
  const welcomeIndex = urlFields.findIndex(f => f.toLowerCase() === 'welcome');
  const recoverIndex = urlFields.findIndex(f => f.toLowerCase() === 'recover');
  const portalIndex = urlFields.findIndex(f => f.toLowerCase() === 'portal');
  if (recoverIndex > 0)
    return urlFields.slice(recoverIndex + 1)[0].toLowerCase();
  if (welcomeIndex > 0)
    return urlFields.slice(welcomeIndex + 1)[0].toLowerCase();
  if (portalIndex > 0) return urlFields.slice(portalIndex + 1)[0].toLowerCase();
}
export const isMarketLufthansa = () =>
  getMarketFromUrl()==null?false:getMarketFromUrl().toUpperCase() === MARKETS.LUFTHANSA_DE.toUpperCase();

export function getLanguageFromRecoverPath() {
  const urlFields = window.location.pathname.split('/');
  const langIndex = urlFields.findIndex(f => f.toLowerCase() === 'lang');
  if (langIndex > 0) return urlFields.slice(langIndex + 1)[0];
  return false;
}
export function isMaintenance() {
  return useTranslation.t('isMaintenance');
}

export function isLufthansaDecomm() {
    return useTranslation.t('isLufthansaDecomm');
}

export function isGermanyDecomm() {    
    return useTranslation.t('isGermanyDecomm');
}

export function isFranceDecomm() {
    return useTranslation.t('isFranceDecomm');
}

export function isUKDecomm() {
    return useTranslation.t('isUKDecomm');
}

export function isSpainDecomm() {
    return useTranslation.t('isSpainDecomm');
}

export function inBodiam() {
  return window.location.pathname.toLowerCase().search('portal') > 0;
}

export function fromBodiam(bodiamAction) {
  return bodiamAction;
}

export function isUKMarket(market) {
  return (
    market &&
    (market.toLowerCase() === MARKETS.AMEX_UK.toLowerCase() ||
      market.toLowerCase() === MARKETS.AXA_UK.toLowerCase())
  );
}

export function isMarketAmexUK(market) {
  return market && market.toLowerCase() === MARKETS.AMEX_UK.toLowerCase();
}
export function isMarketAmexFR(market) {
  if (market != null)
    return market && market.toLowerCase() === MARKETS.AMEX_FR.toLowerCase();
  else
    return getMarketFromUrl()==null?false:getMarketFromUrl().toUpperCase() === MARKETS.AMEX_FR.toUpperCase();;
}
function getFaqNameFromUrl() {
  const urlFields = window.location.pathname.split('/');
  const faqIndex = urlFields.findIndex(f => f.toLowerCase() === 'your-cover');
  if (faqIndex > 0) return urlFields.slice(faqIndex + 1)[0];
}

export function getFaqIdFromUrl() {
  return getFaqIdByName(getFaqNameFromUrl());
}