import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from '../classNames';

const activeModificator = '--active';
export const uploadFileLabelCode = 'uploadComponentAddForm';

const UploadFile = () => {
  const { t } = useTranslation();
  return (
    <span className={`${classNames.uploadDropText}${activeModificator}`}>
      {t(uploadFileLabelCode)}
    </span>
  );
};

export default UploadFile;
