import { SET_ACTIVE_CARD } from 'actions/feature/card';
import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function claim(state = initialState.activeCard, action) {
  switch (action.type) {
    // TODO: DELETE
    case types.SET_ACTIVE_CARD:
      return action.card;
    case SET_ACTIVE_CARD:
      return action.payload;
    default:
      return state;
  }
}
