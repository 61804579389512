import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Row, Col } from 'components/shared/Table';
import { Button } from 'components/shared/Forms';
import { CLAIM_STATUS_CODES } from 'constants/constants';
import ClaimListComponent from './ClaimListComponent';

const ClaimsListBoxComponent = props => {
  const { t } = useTranslation();
  const isOpenClaim = claim =>
    claim.status === CLAIM_STATUS_CODES.IN_PROGRESS ||
    claim.status === CLAIM_STATUS_CODES.SUBMITTED;
  const isClosedClaim = claim =>
    claim.status === CLAIM_STATUS_CODES.DECLINED ||
    claim.status === CLAIM_STATUS_CODES.APPROVED ||
    claim.status === CLAIM_STATUS_CODES.WITHDRAWN ||
    claim.status === CLAIM_STATUS_CODES.SETTLED;
  const isUnavailableClaim = claim =>
    claim.status === CLAIM_STATUS_CODES.UNAVAILABLE;

  const openClaims = props.myClaims.filter(isOpenClaim);
  const closedClaims = props.myClaims.filter(isClosedClaim);
  const unavailableClaims = props.myClaims.filter(isUnavailableClaim);
  return (
    <Row>
      <Col>
        <Row className="pt-3 pt-md-0">
          <Col>
            <h5>{t('commonOpenClaims')}</h5>
            <ClaimListComponent
              list={openClaims}
              gotoClaim={props.gotoClaim}
              gotoUploadClaimDocuments={props.gotoUploadClaimDocuments}
            />
          </Col>
        </Row>
        <Row className="pt-3 pt-md-0">
          <Col>
            <h5>{t('commonClosedClaims')}</h5>
            <ClaimListComponent
              list={closedClaims}
              gotoClaim={props.gotoClaim}
            />
          </Col>
        </Row>
        {unavailableClaims.length > 0 && (
          <Row className="pt-3 pt-md-0">
            <Col>
              <h5>{t('commonUnavailableClaims')}</h5>
              <ClaimListComponent
                list={unavailableClaims}
                gotoClaim={props.gotoClaim}
              />
            </Col>
          </Row>
        )}
        <Row>
          <Col>
            <Button
              type="button"
              className="btn btn-lg btn-block text-center btn-amex "
              id="btn-my-claims-create-claim"
              onClick={() => {
                props.gotoTriageWrapper();
              }}
            >
              {t('landingPageButtonStrt')}
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

ClaimsListBoxComponent.propTypes = {
  myClaims: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  gotoClaim: PropTypes.func.isRequired,
  gotoTriageWrapper: PropTypes.func.isRequired,
  gotoUploadClaimDocuments: PropTypes.func
};

ClaimsListBoxComponent.defaultProps = {
  gotoUploadClaimDocuments: () => {}
};

export default ClaimsListBoxComponent;
