import { object, string, number } from 'yup';

const invalidTextLabel = 'isInValidText';

const maxLengthConstants = {
  firstName: 100,
  lastName: 100
};

const schemaValidation = object({
  firstName: string()
    .max(maxLengthConstants.firstName)
    .required(invalidTextLabel)
    .typeError(invalidTextLabel),
  lastName: string()
    .max(maxLengthConstants.lastName)
    .required(invalidTextLabel)
    .typeError(invalidTextLabel),
  relationship: number()
    .required(invalidTextLabel)
    .typeError(invalidTextLabel)
});

export { maxLengthConstants, schemaValidation };
