import React, { Component } from 'react';
import PropTypes from 'prop-types';
import LanguageSelector from '../../../components/LanguageSelector';
import MenuButton from './MenuButton';
import LogOutButton from './LogoutButton';
import PartnerLogo from './PartnerLogo';

class TopHeaderBodiam extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hambCollapsed: true
    };

    this.toggleHambCollapsed = this.toggleHambCollapsed.bind(this);
  }

  toggleHambCollapsed() {
    this.setState(prevState => ({
      hambCollapsed: !prevState.hambCollapsed
    }));
  }

  render() {
    const {
      props: { actions, isLoggedIn, onLogOut, makeAClaim }
    } = this;

    const headerClass = this.state.hambCollapsed
      ? 'logo-navbar-box'
      : 'logo-navbar-box uncollapsed';

    return (
      <div className="row no-gutters amex-header-row">
        <div className="amex-header-container mx-md-auto mx-xs-0 w-100">
          <div className="top-header">
            <div className=" second-bar">
              <div className="row no-gutters h-100 pt-xl-0">
                <div className="nav-bar-container col m-auto h-100">
                  <div className={headerClass}>
                    <div className="headerBodiam">
                      <div className="item item-menu">
                        <MenuButton
                          actions={actions}
                          makeAClaim={makeAClaim}
                          onCollapseMenu={this.toggleHambCollapsed}
                          onLogOut={onLogOut}
                          isLoggedIn={isLoggedIn}
                        />
                      </div>
                      <PartnerLogo collapsed={this.state.hambCollapsed} />
                      <div className="spacer" />
                      <LanguageSelector />
                      <div className="item" />
                      <div className="item item-menu">
                        {isLoggedIn && <LogOutButton onLogOut={onLogOut} />}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

TopHeaderBodiam.propTypes = {
  actions: PropTypes.shape({}),
  isLoggedIn: PropTypes.bool,
  makeAClaim: PropTypes.func,
  onLogOut: PropTypes.func
};

TopHeaderBodiam.defaultProps = {
  isLoggedIn: false,
  onLogOut: null,
  actions: null,
  makeAClaim: null
};

export default TopHeaderBodiam;
