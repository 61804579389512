import React from 'react';
import PropTypes from 'prop-types';

const DeleteIconButton = props => (
  <div
    className="delete-icon"
    onClick={props.onClick}
    onKeyDown={props.onClick}
  >
    <i className="material-icons">delete</i>
  </div>
);

DeleteIconButton.propTypes = {
  onClick: PropTypes.func.isRequired
};

export default DeleteIconButton;
