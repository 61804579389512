export const GET_COUNTRIES_BY_LANGUAGE_ID =
  '[country] get country list by language id';

export const SET_COUNTRY_OPTIONS = '[country] set country options';

export const FAIL_COUNTRY_OPTIONS = '[country] fail country options';

export const getCountryOptions = languageId => ({
  type: GET_COUNTRIES_BY_LANGUAGE_ID,
  payload: { languageId }
});

export const setCountryOptions = countries => ({
  type: SET_COUNTRY_OPTIONS,
  payload: countries
});

export const failCountryOptions = () => ({
  type: FAIL_COUNTRY_OPTIONS
});
