import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { PURCHASES_FORM_PAID_METHODS } from 'constants/constants';

import { Button } from 'components/shared/Forms';

const PurchasesFormPaymentMethod = props => {
  const { t } = useTranslation();

  const noGroupedWhoPaid = PURCHASES_FORM_PAID_METHODS.notGrouped.map(
    noGrouped => {
      const className = `btn btn-outline-primary btn-lg btn-amex ${
        props.formValues.paymentMethod === noGrouped.id
          ? 'btn-amex-selected'
          : ''
      }`;

      const label = t(`PurchasesFormPaidMethods${noGrouped.key}`);

      return (
        <div
          className="btn-group mr-2"
          role="group"
          aria-label={label}
          key={noGrouped.id}
        >
          <Button
            id={`btn-purchaseFormPaymentMethod-${noGrouped.id}`}
            type="button"
            className={className}
            onClick={() => props.handleOnChangePaymentMethod(noGrouped.id)}
            text={label}
          />
        </div>
      );
    }
  );

  return noGroupedWhoPaid;
};

PurchasesFormPaymentMethod.propTypes = {
  formValues: PropTypes.shape({
    paymentMethod: PropTypes.number
  }).isRequired,
  handleOnChangePaymentMethod: PropTypes.func.isRequired
};

export default PurchasesFormPaymentMethod;
