import {
  addAffectedPerson,
  removeAffectedPerson,
  updateAffectedPerson,
  UPDATE_AFFECTED_PERSON_REQUESTED,
  ADD_AFFECTED_PERSON_REQUESTED,
  REMOVE_AFFECTED_PERSON_REQUESTED
} from 'actions/feature/claim';

export const addAffectedPersonRquested = ({ dispatch }) => next => action => {
  next(action);

  if (action.type === ADD_AFFECTED_PERSON_REQUESTED) {
    dispatch(addAffectedPerson(action.payload));
  }
};

export const updateAffectedPersonRquested = ({
  dispatch
}) => next => action => {
  next(action);

  if (action.type === UPDATE_AFFECTED_PERSON_REQUESTED) {
    dispatch(updateAffectedPerson(action.payload));
  }
};

export const removeAffectedPersonRequested = ({
  dispatch
}) => next => action => {
  next(action);

  if (action.type === REMOVE_AFFECTED_PERSON_REQUESTED) {
    dispatch(removeAffectedPerson(action.payload));
  }
};

export default [
  updateAffectedPersonRquested,
  addAffectedPersonRquested,
  removeAffectedPersonRequested
];
