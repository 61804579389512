import React from 'react';
import PropTypes from 'prop-types';

const Content = ({ text }) => (
  // eslint-disable-next-line react/no-danger
  <p className="content" dangerouslySetInnerHTML={{ __html: text }} />
);

Content.propTypes = {
  text: PropTypes.string.isRequired
};

export default Content;
