import React from 'react';
import PropTypes from 'prop-types';

const ValidatorText = props => (
  <small
    id={`${props.name}Help`}
    className={`mb-3 form-text ${
      props.isSelected ? 'text-muted' : 'text-danger'
    } ${props.className}`}
  >
    {`${props.text}`}
  </small>
);

ValidatorText.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  isSelected: PropTypes.bool.isRequired
};

ValidatorText.defaultProps = {
  className: ''
};

export default ValidatorText;
