import { SET_NEW_CARD_TO_LIST } from 'actions/feature/card';
import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function claim(state = initialState.cardsList, action) {
  switch (action.type) {
    case types.SET_CARDS_LIST:
      return action.cards;
    case SET_NEW_CARD_TO_LIST:
      return [...state.filter(x => x.id !== action.payload.id), action.payload];
    default:
      return state;
  }
}
