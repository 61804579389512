import React from 'react';
import PropTypes from 'prop-types';

import Select from 'components/shared/Select';
import { withTranslation } from 'react-i18next';

import { BING_MAPS_URL } from 'constants/constants';
import InputComp from 'components/RegistrationForm/RegistrationInputComponent';

import getUserGeolocation from 'utils/GeolocationHelper';
import loadBingMapsScript from 'utils/bingHelper';

const BING_MAPS_KEY = process.env.REACT_APP_BING_MAPS_KEY;

export class MedicalProvidersSearchForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      locationSelected: null,
      categorySelected: null,
      specialitySelected: null
    };

    this.handleLocateMeClick = this.handleLocateMeClick.bind(this);
    this.onLocationChange = this.onLocationChange.bind(this);
    this.setLocation = this.setLocation.bind(this);

    this.onSearch = this.onSearch.bind(this);
    this.getLocationLabel = this.getLocationLabel.bind(this);
    this.setLocationFromBing = this.setLocationFromBing.bind(this);

    this.onCategoryChange = this.onCategoryChange.bind(this);
    this.onSpecialityChange = this.onSpecialityChange.bind(this);
    this.mapCategoriesWithText = this.mapCategoriesWithText.bind(this);
    this.mapSpecialityWithText = this.mapSpecialityWithText.bind(this);
  }

  componentDidMount() {
    this.Microsoft = window.Microsoft;
    if (
      document.querySelector(
        `script[src="${BING_MAPS_URL}bingmapsCallback"]`
      ) === null
    ) {
      window.bingmapsCallback = () => {
        this.Microsoft = window.Microsoft;
        this.createAutoSuggest();
      };
      loadBingMapsScript('bingmapsCallback');
    }

    if (this.props.query) {
      const category = this.props.categoryList.find(
        x => x.label === this.props.query.category
      );

      const speciality = this.props.specialityList.find(
        x => x.label === this.props.query.speciality
      );

      this.setState({
        locationSelected: this.props.query.location,
        categorySelected: category ? this.getCategoryWithText(category) : null,
        specialitySelected: speciality
          ? this.getSpecialityWithText(speciality)
          : null
      });

      if (this.Microsoft && this.Microsoft.Maps) {
        this.createAutoSuggest();
      }
    }
  }

  onLocationChange = e => {
    const location = this.createLocation(null, null, e.target.value);
    this.setState({
      locationSelected: location
    });
  };

  onCategoryChange = newValue =>
    this.setState({ categorySelected: newValue, specialitySelected: null });

  onSpecialityChange = newValue =>
    this.setState({ specialitySelected: newValue });

  onSearch(event) {
    event.preventDefault();
    const {
      categorySelected,
      locationSelected,
      specialitySelected
    } = this.state;

    if (this.validateSearch(locationSelected, categorySelected)) {
      const category = categorySelected.value;
      const speciality = specialitySelected ? specialitySelected.value : null;
      const location = locationSelected;

      this.props.onSubmit(category, location, speciality);
    } else {
      this.props.onAddAlert(
        'error',
        this.props.t('medicalFacilitiesFormSubmitComplete')
      );
    }
  }

  setLocation(position) {
    const location = this.createLocation(
      position.coords.latitude.toString(),
      position.coords.longitude.toString()
    );
    this.setState({
      locationSelected: location
    });
  }

  getLocationLabel(locationSelected) {
    if (locationSelected) {
      if (locationSelected.title) {
        return locationSelected.title;
      }

      if (locationSelected.latitude || locationSelected.longitude) {
        return `${locationSelected.latitude}, ${locationSelected.longitude}`;
      }
    }

    return '';
  }

  getCategoryWithText = category => ({
    value: category.label,
    label: this.props.t(`medicalFacilitiesKinds${category.label}`),
    hasSpecialities: category.hasSpecialities
  });

  getSpecialityWithText = speciality => ({
    value: speciality.label,
    label: this.props.t(`medicalFacilitiesSpec${speciality.label}`)
  });

  setLocationFromBing(suggestionResult) {
    const location = this.createLocation(
      suggestionResult.location.latitude.toString(),
      suggestionResult.location.longitude.toString(),
      suggestionResult.title
    );
    this.setState({
      locationSelected: location
    });
  }

  mapCategoriesWithText = categoryList =>
    categoryList ? categoryList.map(this.getCategoryWithText) : [];

  mapSpecialityWithText = specialityList =>
    specialityList ? specialityList.map(this.getSpecialityWithText) : [];

  handleLocateMeClick = () =>
    getUserGeolocation(this.setLocation, this.props.handleLocationErrors);

  validateSearch = (location, category) =>
    ((location.latitude && location.longitude) || location.title) &&
    category.label;

  createLocation(latitude, longitude, title = '') {
    return {
      latitude,
      longitude,
      title
    };
  }

  createAutoSuggest() {
    const options = { maxResults: 5 };
    this.Microsoft.Maps.loadModule('Microsoft.Maps.AutoSuggest', {
      callback: () => {
        const manager = new this.Microsoft.Maps.AutosuggestManager(options);
        if (
          document.querySelectorAll('#searchBox').length > 0 &&
          document.querySelectorAll('#searchBoxContainer').length > 0
        ) {
          manager.attachAutosuggest(
            '#searchBox',
            '#searchBoxContainer',
            this.setLocationFromBing
          );
        }
      },
      errorCallback: error => {
        this.props.onAddAlert('error', this.props.t('errorLoadBingMapsApi'));
      },
      credentials: BING_MAPS_KEY
    });
  }

  render() {
    const shouldDisableSpecialitiesDropDown = this.state.categorySelected
      ? !this.state.categorySelected.hasSpecialities
      : true;
    return (
      <div className="col-12">
        <div className="section-container">
          <form className="provider-search" onSubmit={this.onSearch}>
            <div className="row">
              <div className="col">
                <InputComp
                  mandatory
                  label={`${this.props.t('medicalFacilitiesFormLocation')}*`}
                  onInputChange={this.onLocationChange}
                  fullSize
                  isLocation
                  locateMe={this.handleLocateMeClick}
                  value={this.getLocationLabel(this.state.locationSelected)}
                  name="target"
                />
                <Select
                  label={this.props.t('medicalFacilitiesFormType')}
                  isRequired
                  isClearable={false}
                  disabled={!this.state.locationSelected}
                  onChange={this.onCategoryChange}
                  options={this.mapCategoriesWithText(this.props.categoryList)}
                  value={this.state.categorySelected}
                  className="mr-2 mb-2 col-md-8 col-lg-6 px-0"
                  name="type"
                />
                <Select
                  label={this.props.t('medicalFacilitiesFormSpeciality')}
                  isRequired
                  disabled={shouldDisableSpecialitiesDropDown}
                  isClearable={false}
                  onChange={this.onSpecialityChange}
                  options={this.mapSpecialityWithText(
                    this.props.specialityList
                  )}
                  value={this.state.specialitySelected}
                  className="mr-2 mb-2 col-md-8 col-lg-6 px-0"
                  name="speciality"
                />
                <button
                  className="btn btn-default btn-lg btn-amex btn-amex-selected btn-primary col-12 col-sm-6 col-md-3 col-lg-3"
                  type="button"
                  onClick={this.onSearch}
                >
                  {this.props.t('medicalFacilitiesSearchBtnLabel')}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

MedicalProvidersSearchForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onAddAlert: PropTypes.func.isRequired,
  handleLocationErrors: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  categoryList: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      hasSpecialities: PropTypes.bool
    })
  ).isRequired,
  specialityList: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string
    })
  ).isRequired,
  query: PropTypes.shape({
    location: PropTypes.shape({
      latitude: PropTypes.string,
      longitude: PropTypes.string,
      title: PropTypes.string
    }),
    category: PropTypes.string,
    speciality: PropTypes.string
  })
};

MedicalProvidersSearchForm.defaultProps = {
  query: null
};

export default withTranslation()(MedicalProvidersSearchForm);
