import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import WarningIcon from '@material-ui/icons/Warning';

import { Trans } from 'react-i18next';

import { ALERT_TYPES } from 'constants/constants';

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon
};

const ieClasses = {
  error: 'AlertsComponent-error-203',
  icon: 'AlertsComponent-icon-206',
  iconVariant: 'AlertsComponent-iconVariant-207',
  info: 'AlertsComponent-info-204',
  message: 'AlertsComponent-message-208',
  success: 'AlertsComponent-success-202',
  warning: 'AlertsComponent-warning-205'
};

const AlertsComponent = props => {
  const { classes, className, message, onClose, variant, ...other } = props;
  const Icon = variantIcon[variant];

  const isIE = false || !!document.documentMode;
  const finalClasses = !classes && isIE ? ieClasses : classes;
  const ieMessage = isIE ? finalClasses.messageIe : '';
  const finalClassName = `${finalClasses.message} ${ieMessage}`.trim();
  return (
    <SnackbarContent
      className={classNames(finalClasses[variant], className)}
      aria-describedby="client-snackbar"
      message={
        // eslint-disable-next-line react/jsx-wrap-multilines
        <span id="client-snackbar" className={finalClassName}>
          <Icon
            className={classNames(finalClasses.icon, finalClasses.iconVariant)}
          />
          <Trans i18nKey={message}>
            <span
              className="modal-description"
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: message }}
            />
          </Trans>
        </span>
      }
      action={[
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          className={finalClasses.close}
          onClick={onClose}
        >
          <CloseIcon className={finalClasses.icon} />
        </IconButton>
      ]}
      {...other}
    />
  );
};

AlertsComponent.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  className: PropTypes.string,
  message: PropTypes.node,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf([
    ALERT_TYPES.SUCCESS,
    ALERT_TYPES.WARNING,
    ALERT_TYPES.ERROR,
    ALERT_TYPES.INFO
  ]).isRequired
};

AlertsComponent.defaultProps = {
  className: '',
  message: '',
  onClose: () => {}
};

export default AlertsComponent;
