import {
  UPDATE_FILE_RESULT,
  UPDATE_SHOW_FILE_SPINNER,
  CLEAN_FILE,
  ADD_REASON_FOR_NO_DOC,
  CLEAN_FILE_STORE
} from 'actions/feature/file';
import initialState from './initialState';

export default function fileUpload(state = initialState.fileUpload, action) {
  switch (action.type) {
    case UPDATE_FILE_RESULT:
      return {
        ...state,
        [action.payload.componentId]: {
          ...state[action.payload.componentId],
            answerType: action.payload.answerType,
            fileName: null,
          result: {
            isSuccess: action.payload.isSuccess,
            fileId: action.payload.fileId,
            fileName: action.payload.fileName
          }
        }
      };
    case UPDATE_SHOW_FILE_SPINNER:
      return {
        ...state,
        [action.payload.componentId]: {
          ...state[action.payload.componentId],
          showSpinner: action.payload.show
        }
      };
    case ADD_REASON_FOR_NO_DOC:
      return {
        ...state,
        [action.payload.componentId]: {
          ...state[action.payload.componentId],
          showSpinner: action.payload.show,
          answerType: action.payload.answerType,
          reason: action.payload.reason,
          fileName: action.payload.filename
          
        }
      };
    case CLEAN_FILE: {
      const clone = { ...state };
      delete clone[action.payload.componentId];
      return clone;
    }
    case CLEAN_FILE_STORE: {
      return initialState.fileUpload;
    }
    default:
      return state;
  }
}
