import {
  CREATE_CLAIM_REQUESTED,
  CREATE_CLAIM_SUCCEEDED,
  CREATE_CLAIM_FAILED,
  updateClaim
} from '../../../actions/feature/claim';

import * as apiRoutes from '../../../api/apiRoutes';

import { apiRequest } from '../../../actions/core/api';
import { setError } from '../../../actions/core/error';
import {
  hideSpinner,
  showSpinner,
  notifySuccess
} from '../../../actions/core/ui';
import { goTo } from '../../../actions/core/app.navigation';

export const createClaimFlow = ({ dispatch, getState }) => next => action => {
  next(action);

  if (action.type === CREATE_CLAIM_REQUESTED) {
    const language = getState().marketLanguages.selectedLanguage;
    dispatch(showSpinner());
    dispatch(
      apiRequest(
        'POST',
        `${apiRoutes.CLAIMS}/${language}`,
        {},
        action.payload,
        { redirectTo: action.meta.redirectTo },
        CREATE_CLAIM_SUCCEEDED,
        CREATE_CLAIM_FAILED
      )
    );
  }
};

export const claimCreationSuccess = ({ dispatch }) => next => action => {
  next(action);

  if (action.type === CREATE_CLAIM_SUCCEEDED) {
    dispatch(updateClaim(action.payload.claim));
    dispatch(goTo(action.meta.redirectTo));
    dispatch(hideSpinner());
    dispatch(notifySuccess('alertProgressClaim'));
  }
};

export const claimCreationFail = ({ dispatch }) => next => action => {
  next(action);

  if (action.type === CREATE_CLAIM_FAILED) {
    dispatch(hideSpinner());
    dispatch(setError(action.payload));
  }
};

export default [createClaimFlow, claimCreationSuccess, claimCreationFail];
