export const LOAD_LABELS_SUCCESS = 'LOAD_LABELS_SUCCESS';

export const SIGN_IN_SUCCESS = 'SIGN_IN_SUCCESS';
export const LOG_OUT_SUCCESS = 'LOG_OUT_SUCCESS';

export const USER_INFO_SUCCESS = 'USER_INFO_SUCCESS';
export const USER_INFO_FAILURE = 'USER_INFO_FAILURE';
export const SET_USER_INFO = 'SET_USER_INFO';
export const SET_CURRENT_ACCOUNT = 'SET_CURRENT_ACCOUNT';
export const CLEAN_CURRENT_ACCOUNT = 'CLEAN_CURRENT_ACCOUNT';
export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS';
export const UPDATE_PROFILE_FAILURE = 'UPDATE_PROFILE_FAILURE';

export const ADD_POLICY_SUCCESS = 'ADD_POLICY_SUCCESS';
export const ADD_POLICY_FAILURE = 'ADD_POLICY_FAILURE';

export const SET_CARDS_LIST = 'SET_CARDS_LIST';
export const SET_CARD_PRODUCT = 'SET_CARD_PRODUCT';
export const GET_CARDS_SUCCESS = 'GET_CARDS_SUCCESS';
export const GET_CARDS_FAILURE = 'GET_CARDS_FAILURE';
export const SET_ACTIVE_CARD = 'SET_ACTIVE_CARD';
export const DELETE_CARD_SUCCESS = 'DELETE_CARD_SUCCESS';
export const SET_GENCODE_CARD = 'SET_GENCODE_CARD';

export const SET_POLICIES_LIST = 'SET_POLICIES_LIST';
export const GET_POLICIES_SUCCESS = 'GET_POLICIES_SUCCESS';
export const GET_POLICIES_FAILURE = 'GET_POLICIES_FAILURE';
export const POLICY_DETAIL_SUCCESS = 'POLICY_DETAIL_SUCCESS';
export const DELETE_POLICY_SUCCESS = 'DELETE_POLICY_SUCCESS';

export const SET_PRODUCT_DETAIL = 'SET_PRODUCT_DETAIL';

export const RECOVER_PASSWORD_SUCCESS = 'RECOVER_PASSWORD_SUCCESS';
export const RECOVER_PASSWORD_FAILURE = 'RECOVER_PASSWORD_FAILURE';

export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE';

export const BEGIN_AJAX_CALL = 'BEGIN_AJAX_CALL';
export const AJAX_CALL_ERROR = 'AJAX_CALL_ERROR';

export const ADD_ALERT = 'ADD_ALERT';
export const DELETE_ALERT = 'DELETE_ALERT';
export const DELETE_ALL_ALERT = 'DELETE_ALL_ALERT';
export const HANDLE_API_ERROR = 'HANDLE_API_ERROR';

export const UPDATE_ACTIVE_PAGE = 'UPDATE_ACTIVE_PAGE';

export const UPDATE_BODIAM_ACTION = 'UPDATE_BODIAM_ACTION';

export const LOAD_TREE_SUCCESS = 'LOAD_TREE_SUCCESS';
export const LOAD_CREDIT_CARD_TREE_SUCCESS = 'LOAD_CREDIT_CARD_TREE_SUCCESS';
export const LOAD_BENEFITS_TREE_SUCCESS = 'LOAD_BENEFITS_TREE_SUCCESS';
export const ADD_VISIBLE_GROUP = 'ADD_VISIBLE_GROUP';
export const RESET_VISIBLE_GROUPS = 'RESET_VISIBLE_GROUPS';
export const RESET_USER_ANSWERS = 'RESET_USER_ANSWERS';
export const RESET_DECISION_TREE = 'RESET_DECISION_TREE';
export const UPDATE_VISIBLE_GROUPS = 'UPDATE_VISIBLE_GROUPS';
export const ADD_USER_ANSWER = 'ADD_USER_ANSWER';
export const UPDATE_USER_ANSWERS = 'UPDATE_USER_ANSWERS';
export const UPDATE_CURRENT_TREE = 'UPDATE_CURRENT_TREE';

export const SET_IN_TRIAGE = 'SET_IN_TRIAGE';
export const SET_NOT_TRIAGE = 'SET_NOT_TRIAGE';

export const SHOW_POPUP_V2 = 'SHOW_POPUP_V2';

// CLAIMS
export const CREATE_CLAIM_SUCCESS = 'CREATE_CLAIM_SUCCESS';
export const LOAD_CLAIMS_SUCCESS = 'LOAD_CLAIMS_SUCCESS';
export const GET_CLAIM_SUCCESS = 'GET_CLAIM_SUCCESS';
export const SAVE_CLAIM_SUCCESS = 'SAVE_CLAIM_SUCCESS';
export const SUBMIT_CLAIM_SUCCESS = 'SUBMIT_CLAIM_SUCCESS';
export const DECLINE_CLAIM_SUCCESS = 'DECLINE_CLAIM_SUCCESS';
export const UPDATE_CLAIM_DATA = 'UPDATE_CLAIM_DATA';
export const EMPTY_CURRENT_CLAIM = 'EMPTY_CURRENT_CLAIM';
export const SET_CLAIMS_LIST = 'SET_CLAIMS_LIST';
export const CLAIMS_LIST_SUCCESS = 'CLAIMS_LIST_SUCCESS';
export const CLAIMS_LIST_FAILURE = 'CLAIMS_LIST_FAILURE';
export const CLEAR_BENEFIT_ID = 'CLEAR_BENEFIT_ID';

export const UPDATE_CURRENT_STEP = 'UPDATE_CURRENT_STEP';
export const SET_CURRENT_ACCOUNT_SUCCESS = 'SET_CURRENT_ACCOUNT_SUCCESS';

export const CHECK_POLICY_COVERAGE_SUCCESS = 'CHECK_POLICY_COVERAGE_SUCCESS';
export const GET_PRODUCT_BENEFITS_SUCCESS = 'GET_PRODUCT_BENEFITS_SUCCESS';

export const ADD_FAQ_EVALUATION = 'ADD_FAQ_EVALUATION';
export const ADD_FAQ_EVALUATION_SUCCESS = 'ADD_FAQ_EVALUATION_SUCCESS';

export const PRISMIC_CALL_SUCCESS = 'PRISMIC_CALL_SUCCESS';
export const SET_SELECTED_LANGUAGE_SUCCESS = 'SET_SELECTED_LANGUAGE_SUCCESS';

// prismic
export const EVENTS_CALL_SUCCESS = 'EVENTS_CALL_SUCCESS';
export const REMOVE_PRISMIC_EVENT = 'REMOVE_PRISMIC_EVENT';
export const SET_READ_PRISMIC_EVENT = 'SET_READ_PRISMIC_EVENT';
export const SET_ALL_PRISMIC_EVENTS_TO_READ = 'SET_ALL_PRISMIC_EVENTS_TO_READ';
export const SET_ALL_PRISMIC_EVENTS_TO_UNREAD =
  'SET_ALL_PRISMIC_EVENTS_TO_UNREAD';

export const CARD_SUBMIT_SUCCESS = 'CARD_SUBMIT_SUCCESS';
export const CARD_SUBMIT_FAILURE = 'CARD_SUBMIT_FAILURE';

export const MEDICAL_NEW_SEARCH = 'MEDICAL_NEW_SEARCH';
export const MEDICAL_PROVIDER_GET_CATEGORIES =
  'MEDICAL_PROVIDER_GET_CATEGORIES';
export const MEDICAL_PROVIDER_GET_SPECIALITIES =
  'MEDICAL_PROVIDER_GET_SPECIALITIES';
export const MEDICAL_PROVIDER_FACILITIES_REQUEST =
  'MEDICAL_PROVIDER_FACILITIES_REQUEST';
export const MEDICAL_PROVIDER_FACILITIES_SUCCESS =
  'MEDICAL_PROVIDER_FACILITIES_SUCCESS';
export const MEDICAL_PROVIDER_FACILITIES_FAILURE =
  'MEDICAL_PROVIDER_FACILITIES_FAILURE';
export const MEDICAL_PROVIDER_FACILITIES_START_LOAD_MAP =
  'MEDICAL_PROVIDER_FACILITIES_START_LOAD_MAP';
export const MEDICAL_PROVIDER_FACILITIES_STOP_LOAD_MAP =
  'MEDICAL_PROVIDER_FACILITIES_STOP_LOAD_MAP';
