import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import { RichText } from 'prismic-reactjs';
import { LinkResolver } from '../../../utils/PrismicHelper';
import PolicySummaryDescriptionWithIcon from './PolicySummaryDescriptionWithIcon';
import Loading from '../../../components/Loading/Loading';
import ExploreCover from '../ExploreCover';

class PolicySummary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      doc: null
    };

    this.loadData = this.loadData.bind(this);
  }

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(prevProps) {
    if (this.props.language !== prevProps.language) {
      this.loadData();
    }
    // eslint-disable-next-line react/no-did-update-set-state
  }

  loadData() {
    this.props
      .getCoverSummaryAction(
        this.props.marketCode,
        this.props.productCode,
        this.props.language
      )
      .then(data => {
        if (data.results.length) {
          this.setState({ doc: data.results[0].data, errorMessage: null });
        } else {
          this.setState({ errorMessage: this.props.t('noBenefitsFound') });
        }
      });
  }

  render() {
    let content = null;
    const { doc, errorMessage } = this.state;

    if (doc) {
      content = (
        <div className="text-center policy-summary">
          {doc.title.length > 0 && RichText.render(doc.title, LinkResolver)}
          <PolicySummaryDescriptionWithIcon
            description={doc.block1}
            icon={doc.icon1}
          />
          <ExploreCover
            marketCode={this.props.marketCode}
            productCode={this.props.productCode}
            labels={this.props.labels}
            getBenefitListAction={this.props.getBenefitListAction}
            loading={this.props.loading}
            language={this.props.language}
          />
          <PolicySummaryDescriptionWithIcon
            description={doc.block2}
            icon={doc.icon2}
          />
          <div className="policy-specific">
            {doc.block3.length > 0 && RichText.render(doc.block3, LinkResolver)}
          </div>
          <div>
            <Loading
              loading={this.props.loading}
              text={this.props.t('loading')}
            />
          </div>
        </div>
      );
    }
    if (errorMessage) {
      content = <p>{errorMessage}</p>;
    }

    return content;
  }
}

PolicySummary.propTypes = {
  marketCode: PropTypes.string.isRequired,
  productCode: PropTypes.string.isRequired,
  labels: PropTypes.shape({
    noResultsFound: PropTypes.string.isRequired,
    noBenefitsFound: PropTypes.string.isRequired
  }).isRequired,
  loading: PropTypes.bool.isRequired,
  getCoverSummaryAction: PropTypes.func.isRequired,
  language: PropTypes.string.isRequired,
  getBenefitListAction: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired
};

export default withTranslation()(PolicySummary);
