
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'components/shared/Table';
import { pageview } from 'utils/analytics';
import { getRoute } from 'utils/routeHelper';
import { appInsights } from 'ApplicationInsights';

export const MaintenancePage = () => {
    const { t } = useTranslation();

    useEffect(() => {
        pageview({ title: 'Maintenance Page', page: getRoute('Maintenance Page') });
        appInsights.trackPageView(
          {
            name: 'Maintenance Page', uri: getRoute('Maintenance Page') 
          }
        )
      }, []);

      return (
        <Row className="Maintenance-page-box">
        <div className="no-gutters Maintenance-page col-lg-12 col-md-12 col-sm-12">
            <Col className="col">
            <center>
          <div>                   
          <span dangerouslySetInnerHTML={{ __html: t('[MaintenancePage_Content]') }} />    
          </div>
          </center>
          </Col>
</div>
</Row>
      )}
export default MaintenancePage;



