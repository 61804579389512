import { PrismicConf } from 'constants/constants';

const ROOT_URL = process.env.REACT_APP_ROOT_URL;
const BING_MAPS_URL = 'https://dev.virtualearth.net';

// const ROOT_URL = 'https://aa-arundel-dev-webapi.azurewebsites.net/';

// TEMPORARY UNTIL PRISMIC PARAMS ARE IN ENV VARIABLES
/**
const getAppSettingsOrTestingEnvVars = () => {
  // NOTE: we need this to not break the tests!
  const TESTING_ENV_VARS = {
    ROOT_URL: 'testUrl'
  };
  return window.__APP_SETTINGS__ || TESTING_ENV_VARS; // eslint-disable-line no-underscore-dangle
};

const { ROOT_URL } = getAppSettingsOrTestingEnvVars();
 */

export const LABELS = `${ROOT_URL}/api/labels`;
export const SIGN_IN = `${ROOT_URL}/api/accounts/authenticate`;
export const SIGN_UP = `${ROOT_URL}/api/accounts/create`;
export const UPDATE_EMAIL = `${ROOT_URL}/api/accounts/updateEmail`;
export const GET_ACCOUNT = `${ROOT_URL}/api/accounts`;
export const GET_CARDS = `${ROOT_URL}/api/cards`;
export const GET_CARDS_GENCODE = `${ROOT_URL}/api/gencode/cards`;
export const GET_GENCODE = `${ROOT_URL}/api/gencode/transform`;
export const GET_POLICIES = `${ROOT_URL}/api/policy`;
export const PUT_ACCOUNT = `${ROOT_URL}/api/accounts`;
export const RECOVER_PASSWORD = `${ROOT_URL}/api/accounts/forgot`;
export const RESET_PASSWORD = `${ROOT_URL}/api/accounts/reset_password`;
export const ADD_CARDS = `${ROOT_URL}/api/cards`;
export const ADD_POLICIES = `${ROOT_URL}/api/policy`;
export const TRIAGED_DECISION_TREE = `${ROOT_URL}/api/trees/market`;
export const TRIAGED_DECISION_TREE_BY_PRODUCT_ID = `${ROOT_URL}/api/trees/product`;
export const VALIDATE_CREDIT_CARD = `${ROOT_URL}/api/cards/validate`;
export const VALIDATE_GENCODE = `${ROOT_URL}/api/gencode/validate`;
export const ADD_CARD_GENCODE = `${ROOT_URL}/api/gencode/card`;
export const VALIDATE_POLICY = `${ROOT_URL}/api/policy/validate`;
export const HOW_TO_CLAIM = `${ROOT_URL}/api/trees/productId`;
export const CLAIMS = `${ROOT_URL}/api/claims`;
export const CURRENCIES = `${ROOT_URL}/api/currencies`;
export const FACILITIES = `${ROOT_URL}/api/medicalproviders/search`;
export const SEARCHES = `${ROOT_URL}/api/userlocations/searches`;
export const CLAIMS_DECLINE = 'decline';
export const CLAIMS_SUBMIT = 'submit';
export const REASON = 'reason';
export const FILES = `${ROOT_URL}/api/files`;
export const EXPENSEFILES = `${ROOT_URL}/api/files/expensesFile`;
export const CLAIM_DOCUMENTS = `${ROOT_URL}/api/files/documents`;
export const CLAIM_DOCUMENT = `${ROOT_URL}/api/files/document`;
export const VALIDATE_DATES = '/dates/check';
export const PRISMIC_API = PrismicConf.apiEndpoint;
export const BING = {
  LOCATIONS: `${BING_MAPS_URL}/REST/v1/Locations/`
};
export const FAQ_EVALUATION = `${ROOT_URL}/api/faqrating/vote`;
export const GET_COUNTRIES = `${ROOT_URL}/api/countries`;
export const DOWNLOAD_BENEFIT_FILE = `${GET_CARDS}/benefits/report`;
