import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Row, Col } from 'components/shared/Table';
import { Button } from 'components/shared/Forms';
import Product from 'components/shared/Product';

import { obfuscateCard } from 'utils/CardHelper';
import { DEFAULT_CARD_IMAGE } from 'constants/constants';

const ProductNumberDetailsListItem = props => {
  const { t } = useTranslation();
  const defaultImageUrl = props.isCardsList ? DEFAULT_CARD_IMAGE : '';
  const imgURL = props.item.imageURL || defaultImageUrl;

  const number =
    props.isCardsList && props.item.obfuscated
      ? obfuscateCard(props.item.obfuscated)
      : props.item.number;
  const numberClss = !props.isCardsList ? 'policy-number' : null;

  return (
    <Row>
      <Col>
        <Button
          type="button"
          className="card-detail-item mb-4"
          onClick={() => {
            props.getItemInfo(props.item.id, props.toggleItemProfile);
          }}
        >
          <Row>
            <Col className="col-md-6 col-sm-12">
              <Product.Image
                defaultUrl={defaultImageUrl}
                url={props.item.imageURL}
              />
            </Col>
            <Col className={`${imgURL ? 'col-md-6' : 'col-md-12'} col-sm-12`}>
              <Product.Title value={props.item.product} />
              {props.isCardsList && (
                <Product.Type value={props.item.typeOfCard} />
              )}
            </Col>
          </Row>
          <Row>
            <Col className="text-center">
              {!props.isCardsList && <p>{t('myProfilePolicyNumber')}</p>}
              <Product.Number value={number} className={numberClss} />
            </Col>
          </Row>
        </Button>
      </Col>
    </Row>
  );
};

ProductNumberDetailsListItem.propTypes = {
  item: PropTypes.shape({
    number: PropTypes.string.isRequired,
    typeOfCard: PropTypes.number,
    product: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    imageURL: PropTypes.string,
    obfuscated: PropTypes.string
  }).isRequired,
  toggleItemProfile: PropTypes.func,
  getItemInfo: PropTypes.func,
  isCardsList: PropTypes.bool,
  obfuscated: PropTypes.string
};

ProductNumberDetailsListItem.defaultProps = {
  toggleItemProfile: () => {},
  getItemInfo: () => {},
  isCardsList: true,
  obfuscated: ''
};

export default ProductNumberDetailsListItem;
