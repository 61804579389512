import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const ProductItemDeleteDialogComponent = props => {
  const { t } = useTranslation();
  return (
    <Dialog
      open={props.opened}
      keepMounted
      onClose={props.handleCloseDialog}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title">
        {props.productNumber}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          {props.warningLabel}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleCloseDialog} color="primary">
          {t('commonDisagree')}
        </Button>
        <Button
          onClick={() => {
            props.deleteItem(
              props.productDetail.summary.id,
              props.toggleProductItemProfile,
              props.deletedLabel
            );
          }}
          color="primary"
        >
          {t('commonAgree')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
ProductItemDeleteDialogComponent.propTypes = {
  opened: PropTypes.bool.isRequired,
  toggleProductItemProfile: PropTypes.func.isRequired,
  deleteItem: PropTypes.func.isRequired,
  productDetail: PropTypes.shape({
    summary: PropTypes.shape({
      number: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired
    }).isRequired
  }).isRequired,
  handleCloseDialog: PropTypes.func.isRequired,
  warningLabel: PropTypes.string.isRequired,
  productNumber: PropTypes.string.isRequired,
  deletedLabel: PropTypes.string.isRequired
};

export default ProductItemDeleteDialogComponent;
