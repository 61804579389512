import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function popupStatus(state = initialState.showPopupV2, action) {
  switch (action.type) {
    case types.SHOW_POPUP_V2:
      return false;
    default:
      return state;
  }
}
