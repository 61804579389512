import { CLAIM_DOCUMENTS, CLAIM_DOCUMENT, CLAIMS } from 'api/apiRoutes';

import { apiRequest } from 'actions/core/api';
import { setError } from 'actions/core/error';
import { hideSpinner, showSpinner } from 'actions/core/ui';
import {
  GET_DOCUMENTS_REQUESTED,
  GET_DOCUMENTS_SUCCEEDED,
  GET_DOCUMENTS_FAILED,
  setClaimDocumentsList,
  SUBMIT_CLAIM_REASON,
  PROVIDE_REASON_SUCCEEDED,
  PROVIDE_REASON_FAILED
} from 'actions/feature/claim';
import * as fileActions from 'actions/feature/file';
import {
  GET_CLAIM_REASON,
  GET_CLAIM_REASON_SUCCEEDED,
  GET_CLAIM_REASON_FAILED
} from 'actions/feature/file';

import {
  gotoMyClaims
} from 'actions/activePageActions';
import {addAlert } from 'actions/alertsActions';



export const getDocumentsFlow = ({ dispatch }) => next => action => {
  next(action);

  if (action.type === GET_DOCUMENTS_REQUESTED) {
    dispatch(showSpinner());
    dispatch(
      apiRequest(
        'GET',
        `${CLAIM_DOCUMENTS}/${action.payload}`,
        {},
        {},
        {},
        GET_DOCUMENTS_SUCCEEDED,
        GET_DOCUMENTS_FAILED
      )
    );
  }
};

export const getDocumentsOnSuccess = ({ dispatch, getState }) => next => action => {
  next(action);
  if (action.type === GET_DOCUMENTS_SUCCEEDED) {
    if(action.payload.isOfflineClaim)
    {
    dispatch(gotoMyClaims()); 
    dispatch(
      addAlert('warning', getState().labels.OfflineClaim.replace('@@@', action.payload.id)));
    dispatch(hideSpinner());
    }
    else
    {
    dispatch(setClaimDocumentsList(action.payload));
    dispatch(hideSpinner());
    }
  }
};

export const getDocumentsOnFail = ({ dispatch }) => next => action => {
  next(action);
  if (action.type === GET_DOCUMENTS_FAILED) {
    dispatch(hideSpinner());
    dispatch(setError(action.payload));
  }
};

export const getClaimReasonByFileId = ({ dispatch }) => next => action => {
  next(action);
  if (action.type === GET_CLAIM_REASON) {
    dispatch(
      apiRequest(
        'GET',
        `${CLAIM_DOCUMENT}/${action.payload.claimId}/${action.payload.fileId}`,
        {},
        action.payload,
        action.payload,
        GET_CLAIM_REASON_SUCCEEDED,
        GET_CLAIM_REASON_FAILED
      )
    );
  }
};

export const getClaimReasonByFileIdSuccess = ({
  dispatch
}) => next => action => {
  next(action);
  if (action.type === GET_CLAIM_REASON_SUCCEEDED) {    
    dispatch(
      fileActions.addReasonForNoDoc(
        action.meta.questionId,
        false,
        action.payload.answerType,
        action.payload.additionalInformation,
        action.payload.name
      )
    );
  }
};

export const getClaimReasonByFileIdFail = ({ dispatch }) => next => action => {
  next(action);
  if (action.type === GET_CLAIM_REASON_FAILED) {
    dispatch(setError(action.payload));
  }
};

export const provideReasonForDocAbsense = ({ dispatch }) => next => action => {
  next(action);
  if (action.type === SUBMIT_CLAIM_REASON) {
    dispatch(showSpinner());
    dispatch(
      fileActions.addReasonForNoDoc(
        action.payload.questionId,
        false,
        action.payload.answerType,
        action.payload.reason
      )
    );
    dispatch(
      apiRequest(
        'PUT',
        `${CLAIMS}/${action.payload.claimId}/reason`,
        {},
        action.payload,
        action.payload,
        PROVIDE_REASON_SUCCEEDED,
        PROVIDE_REASON_FAILED
      )
    );
  }
};

export const provideReasonForDocAbsenseFail = ({
  dispatch
}) => next => action => {
  next(action);
  if (action.type === PROVIDE_REASON_FAILED) {
    dispatch(hideSpinner());
    dispatch(
      fileActions.updateFileResult(action.meta.questionId, false, null, null)
    );
    dispatch(setError(action.payload));
  }
};

export const provideReasonForDocAbsenseSuccess = ({
  dispatch
}) => next => action => {
  next(action);
  if (action.type === PROVIDE_REASON_SUCCEEDED) {
    dispatch(
      fileActions.updateFileResult(
        action.meta.questionId,
        true,
        action.payload,
        '',
        action.meta.answerType
      )
    );
    dispatch(hideSpinner());
  }
};

export default [
  getDocumentsFlow,
  getDocumentsOnSuccess,
  getDocumentsOnFail,
  provideReasonForDocAbsense,
  provideReasonForDocAbsenseSuccess,
  provideReasonForDocAbsenseFail,
  getClaimReasonByFileId,
  getClaimReasonByFileIdSuccess,
  getClaimReasonByFileIdFail
];
