import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { event } from 'utils/analytics';
import { GA_EVENTS, GA_ACTIONS } from 'constants/googleAnalytics';
import moment from 'moment';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { TextField, DateField, Button } from 'components/shared/Forms';
import { validateField, validateForm } from 'utils/formValidationHelper';
import { getCountryOptions } from 'actions/feature/country';
import { goTo } from 'actions/core/app.navigation';
import { downloadBenefitsFile } from 'actions/feature/card';
import Select from 'components/shared/Select';
import ButtonBack from 'components/shared/Custom/ButtonBack';
import './_CheckCoverPage.scss';
import { COVER_PAGE } from 'constants/constants';
import { getBenefitsEnglishTranslations } from 'utils/languageUtils';
import { schemaValidation } from './CheckCoverFormSchemaValidation';
import { appInsights } from 'ApplicationInsights';
import ReactModal from 'react-modal';
import { goToCheckCoverForm } from 'actions/activePageActions';
import { useDispatch } from 'react-redux';

export const CheckCoverForm = props => {
  const [errorFields, setErrorFields] = useState({});  
    const [showPopup, setShowPopup] = useState(false);
    const dispatch = useDispatch();
  const [formValid, setFormValid] = useState(false);
  const [countryOptions, setCountryOptions] = useState([]);
    const { t } = useTranslation();
    const [formValues, setFormValues] = useState({
        dateFrom: '' // Initialize dateFrom as an empty string or null
    });
  useEffect(() => {
    setFormValues({
      ...props.userInfo,
      dateFrom: '',
      dateTo: '',
      country: ''
    });
  }, [props.userInfo]);

  useEffect(() => {
    props.actions.getCountryOptions(props.selectedLanguage);
  }, [props.selectedLanguage]);

  useEffect(() => {
    if (formValues.dateFrom) {
      setFormValues({
        ...formValues,
        dateTo: ''
      });
    }
  }, [formValues.dateFrom]);

  useEffect(() => {
    const options = props.countries.reduce((acc, item) => {
      const option = { ...item, key: item.label, name: 'country' };
      return [...acc, option];
    }, []);
    setCountryOptions(options);
  }, [props.countries]);

  useEffect(() => {
    const result = validateForm(schemaValidation, formValues);
    setFormValid(result.isValid);
  }, [formValues]);

  const handleValidation = (fieldName, value) => {
    const result = validateField(schemaValidation, fieldName, value);
    setErrorFields({
      ...errorFields,
      ...result.errorFields
    });
  };

  const handleValueChange = e => {
    setNewValues(e.target.name, e.target.value);
  };

  //const setNewValues = (name, value) => {
  //  const newFormValues = {
  //    ...formValues,
  //    [name]: value
  //  };
  //  setFormValues(newFormValues);
  //};

  const onSelectionChange = ({ key, name }) => {
    const newFormValues = {
      ...formValues,
      [name]: key
    };
    setFormValues(newFormValues);
  };

  const downloadBenefits = async () => {
    const englishLabels = await getBenefitsEnglishTranslations();
    event(GA_EVENTS.CERTIFICATE, GA_ACTIONS.CLICK);
    appInsights.trackEvent(
      {
        name:'Download Certificate'
      },
      {
        EventCategory: GA_EVENTS.CERTIFICATE,
        EventAction: GA_ACTIONS.CLICK,
        EventLabel: 'Download Certificate'
      }
      )
    const translatedBenefits = Object.assign(
      {},
      ...props.benefits.map(benefit => ({      
        [englishLabels[benefit.benefitLabelCode]!=undefined ? englishLabels[benefit.benefitLabelCode]+" "+[englishLabels[benefit.benefitLimit]!=undefined ? englishLabels[benefit.benefitLimit]: benefit.benefitLimit] : undefined]: t(benefit.benefitLabelCode)+" "+ [t(benefit.benefitLimit)!=undefined? t(benefit.benefitLimit):benefit.benefitLimit]        
      }))
    );
    const title = formValues.title
      ? t(`BeneficiaryContactFormTitles${formValues.title}`)
      : '';
    props.actions.downloadBenefitsFile(
      {
        ...formValues,
        benefits: translatedBenefits,
        name: `${title} ${formValues.name}`,
        productId:props.productId
      },
      props.selectedLanguage,
      props.market
    );
  };

  const titleOptions = props.config.TITLES.values.reduce((acc, item) => {
    const option = {
      label: t(`BeneficiaryContactFormTitles${item}`),
      value: item,
      key: item,
      name: 'title'
    };
    return [...acc, option];
  }, []);

  const titleSelected = titleOptions.find(
    title => title.key === formValues.title
  );

  const countrySelected = countryOptions.find(
    country => country.key === formValues.country
  );

  const onBackClick = () => {
    props.actions.goTo(COVER_PAGE);
    };

    const setNewValues = (fieldName, value) => {
        // Custom logic to set dateFrom to December 31, 2024 if selected date is 2025
        if (fieldName === 'dateTo' && value.getFullYear() === 2025) {
            // Set dateFrom to December 31, 2024
            const defaultDate = new Date(2024, 11, 31); // Month is 0-indexed, so 11 is December
            setFormValues({ ...formValues, [fieldName]: defaultDate });
        } else {
            // Otherwise, update dateFrom normally
            setFormValues({ ...formValues, [fieldName]: value });
        }
    };


  return (
    <div className="div-container">
      <ButtonBack
        onClick={onBackClick}
        labelText={t('buttonBackCheckCoverPage')}
      />
      <div className="flex-container">
        {props.config.TITLES.enabled && (
          <div className="title">
            <Select
              label={t('commonFormUserTitleLabel')}
              isRequired
              name="title"
              isClearable={false}
              onChange={onSelectionChange}
              onValidate={handleValidation}
              options={titleOptions}
              value={titleSelected}
            />
          </div>
        )}
        <div className="flex-item">
          <TextField
            label={t('commonFormUserNameLabel')}
            name="name"
            value={formValues.name}
            onValueChange={handleValueChange}
            errorFields={errorFields}
            ariaDescribedby={t('commonFormUserNameLabel')}
            onValidate={handleValidation}
            required
          />
        </div>
        <div className="flex-item">
          <TextField
            label={t('commonFormSurnameLabel')}
            name="surname"
            value={formValues.surname}
            onValueChange={handleValueChange}
            onValidate={handleValidation}
            errorFields={errorFields}
            ariaDescribedby={t('commonFormSurnameLabel')}
            required
          />
        </div>
        <div className="flex-item">
          <TextField
            label={t('commonFromEmailLabel')}
            name="email"
            value={formValues.email}
            onValueChange={handleValueChange}
            onValidate={handleValidation}
            errorFields={errorFields}
            ariaDescribedby={t('commonFromEmailLabel')}
            required
            sanitize={false}
          />
        </div>
        <div className="flex-item">
          <Select
            label={t('destinationCountry')}
            isRequired
            name="country"
            isClearable={false}
            onChange={onSelectionChange}
            options={countryOptions}
            onValidate={handleValidation}
            value={countrySelected}
          />
        </div>
        <div className="flex-item">
          <DateField
            label={t('coverPolicyDateFrom')}
            name="dateFrom"
            value={formValues.dateFrom}
            onValueChange={value => setNewValues('dateFrom', value)}
            errorFields={errorFields}
            placeholder={t('commonFormDatePlaceholder')}
            ariaDescribedby={t('commonFormDatePlaceholder')}
            onValidate={handleValidation}
            required
          />
        </div>
        <div className="flex-item">
          <DateField
            label={t('coverPolicyDateTo')}
            name="dateTo"
            value={formValues.dateTo}
                      onValueChange={value => {
                          setNewValues('dateTo', value);
                          if (value && value.getFullYear()  > 2024) {
                              setShowPopup(true);
                          }
                      }}
            errorFields={errorFields}
            placeholder={t('commonFormDatePlaceholder')}
            ariaDescribedby={t('commonFormDatePlaceholder')}
            onValidate={handleValidation}
            // minDate={moment(formValues.dateTo).toDate()}
            disabled={!formValues.dateFrom}
            required
          />
        </div>
        <div className="flex-item disclaimer">
          <p>
            <strong>{t('BenefitCertificateDisclaimerTitle')}</strong>
          </p>
          <p>{t('BenefitCertificateDisclaimerParagraph1')}</p>
          <p>{t('BenefitCertificateDisclaimerParagraph2')}</p>
          <p>{t('BenefitCertificateDisclaimerParagraph3')}</p>
          <p>{t('BenefitCertificateDisclaimerParagraph4')}</p>
          <p>{t('BenefitCertificateDisclaimerParagraph5')}</p>
              </div>
              <ReactModal
                  isOpen={showPopup}
                  contentLabel="contentLabel"

                  className="modal-frame"
              >
                  <div className="row">
                      <div className="col text-center py-4">
                          <span className="modal-description" dangerouslySetInnerHTML={{
                              __html: (t('option1Selected'))
                          }}>                              
                          </span>
                      </div>
                  </div>
                  <div className="row border-top pt-2 mt-3">
                      <div className="col text-center">
                          <button
                              type="submit"
                              className="btn btn-primary btn-lg mt-3 btn-amex"
                              onClick={() => setShowPopup(false)}
                          >
                              OK
                          </button>
                      </div>
                  </div>
              </ReactModal>
      </div>
      <Button
        type="submit"
        onClick={downloadBenefits}
        className="btn btn-default btn-amex btn-amex-selected generate-certificate-button"
        text={t('generateCertificate')}
        disabled={!formValid}
          />          
      </div>

  );
};
const mapStateToProps = state => ({
  userInfo: state.userInfo,
  config: state.configuration,
  selectedLanguage: state.marketLanguages.selectedLanguage,
  countries: state.countries,
  market: state.configuration.market,
  benefits: state.card.benefits,
  productId: state.card.productId
});

const mapDispatchToProps = dispatch => ({
  actions: {
    ...bindActionCreators(
      { getCountryOptions, downloadBenefitsFile, goTo },
      dispatch
    )
  }
});

CheckCoverForm.propTypes = {
  benefits: PropTypes.arrayOf(PropTypes.string),
  productId: PropTypes.number,
  userInfo: PropTypes.shape({
    title: PropTypes.string,
    name: PropTypes.string,
    surname: PropTypes.string,
    email: PropTypes.string,
    marketURL: PropTypes.string
  }),
  selectedLanguage: PropTypes.number,
  countries: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string,
      label: PropTypes.string
    })
  ),
  market: PropTypes.string,
  config: PropTypes.shape({
    TITLES: PropTypes.shape({
      enabled: PropTypes.bool.isRequired,
      values: PropTypes.arrayOf(PropTypes.string).isRequired
    }).isRequired
  }).isRequired,
  actions: PropTypes.shape({
    getCountryOptions: PropTypes.func.isRequired,
    downloadBenefitsFile: PropTypes.func.isRequired,
    goTo: PropTypes.func.isRequired
  }).isRequired
};

CheckCoverForm.defaultProps = {
  countries: [],
  userInfo: {},
  benefits: [],
  productId:null,
  selectedLanguage: 1,
  market: ''
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckCoverForm);
