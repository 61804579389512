import './index.scss';

import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { createStore, applyMiddleware, compose } from 'redux';
import { Provider } from 'react-redux';
import reduxThunk from 'redux-thunk';
import ReactModal from 'react-modal';

import { LANDING_PAGE,DATAPROTECTION_PAGE } from 'constants/constants';
import { getMarketFromUrl } from 'utils/urlHelpers';
import Loading from 'components/Loading/Loading';
import * as serviceWorker from 'registerServiceWorker';
import { getUserInfo } from 'actions/authenticationActions';
import { updateActivePage } from 'actions/activePageActions';
import activePageHistory from 'middlewares/activePageHistory';
import { isUserLoggedIn, initGACookies } from 'utils/sessionController';
import { initializeRecaptcha } from 'utils/recaptcha';
import { oneTrustCookiesMiddleware } from '@contact_services/one-trust-cookies';
import middlewaresApp from './middlewares';
import startActionProvider from './action-imports';
import App from './App';
import reducers from './reducers';

startActionProvider();
// eslint-disable-next-line no-underscore-dangle
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  reducers,
  composeEnhancers(
    applyMiddleware(
      reduxThunk,
      activePageHistory,
      ...middlewaresApp,
      oneTrustCookiesMiddleware
    )
  )
);

initGACookies();
store.dispatch(updateActivePage(LANDING_PAGE));
if(window.location.pathname.toLowerCase().indexOf("lufthansade/dataprotection") >-1 ){
  store.dispatch(updateActivePage(DATAPROTECTION_PAGE));
}


(async function render() {
  const market = getMarketFromUrl();
  if (isUserLoggedIn()) {
    store.dispatch(getUserInfo());
  }
  initializeRecaptcha();

  ReactDOM.render(
    <Provider store={store}>
      <Suspense fallback={<Loading loading />}>
        <App marketCode={market} />
      </Suspense>
    </Provider>,
    document.getElementById('root')
  );

  ReactModal.setAppElement('body');
})();
const configuration = {
  onUpdate: (registration) => {
    console.log('Index registration'+registration);
  if (registration && registration.waiting) {
  registration.waiting.postMessage({ type: 'SKIP_WAITING' });
  window.location.reload();
  console.log('Index -- onUpdate registration')
  }
  }
  };
serviceWorker.register(configuration);
