import React from 'react';
import PropTypes from 'prop-types';

import './_NewsModalHeader.scss';

const NewsModalHeader = props =>
  props.titleClassName ? (
    <h4 className={props.titleClassName}>{props.title}</h4>
  ) : (
    <h4>{props.title}</h4>
  );

NewsModalHeader.propTypes = {
  title: PropTypes.string.isRequired,
  titleClassName: PropTypes.string
};

NewsModalHeader.defaultProps = {
  titleClassName: ''
};

export default NewsModalHeader;
