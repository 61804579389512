import { SET_ACTIVE_POLICY } from 'actions/feature/policy';
import initialState from '../initialState';

export default function activePolicy(
  state = initialState.activePolicy,
  action
) {
  switch (action.type) {
    case SET_ACTIVE_POLICY:
      return action.payload;
    default:
      return state;
  }
}
