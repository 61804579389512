import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { getCurrencyTypes } from 'utils/currencyHelper';

const CurrencyType = props => {
  const { t } = useTranslation();

  const allCurrencyTypes = getCurrencyTypes().map(item => (
    <option key={item.key} value={item.value}>
      {t(item.label)}
    </option>
  ));
  const blankOption = (
    <option disabled value="" key="placeholder">
      {t('currencySelectPlaceholder')}
    </option>
  );

  const coinSelectOptions = props.includeBlankOption
    ? [blankOption, ...allCurrencyTypes.slice()]
    : [...allCurrencyTypes.slice()];

  return (
    <select
      id="select-currencyType"
      name="currencyCode"
      className={props.className}
      onChange={e => props.onValueChange(e)}
      value={props.value}
    >
      {coinSelectOptions}
    </select>
  );
};

CurrencyType.propTypes = {
  className: PropTypes.string,
  onValueChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  includeBlankOption: PropTypes.bool
};
CurrencyType.defaultProps = {
  className: 'input-group-text',
  value: '',
  includeBlankOption: true
};

export default CurrencyType;
