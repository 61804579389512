import claimCreateMdl from './claim.create';
import claimSaveMdl from './claim.save';
import claimSaveAndContinueMdl from './claim.saveAndContinue';
import claimDeclineMdl from './claim.decline';
import claimDocumentMdl from './claim.documents';
import claimAffectedPersonMdl from './claim.affectedPerson';

export default [
  ...claimCreateMdl,
  ...claimSaveMdl,
  ...claimSaveAndContinueMdl,
  ...claimDeclineMdl,
  ...claimDocumentMdl,
  ...claimAffectedPersonMdl
];
