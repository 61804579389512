import { combineReducers } from 'redux';
import labels from './labelReducer';
import marketLanguages from './languageReducer';
import authentication from './authenticationReducer';
import activePage from './activePageReducer';
import ajaxStatus from './ajaxStatusReducer';
import decisionTree from './decisionTreeReducer';
import alerts from './alertsReducer';
import currentClaim from './claimReducer';
import inTriage from './triageStatusReducer';
import currentStep from './stepReducer';
import userInfo from './userInfoReducer';
import claimsList from './claimListsReducer';
import cardsList from './cardsListReducer';
import policiesList from './policiesListReducer';
import productDetail from './productDetailReducer';
import activeCard from './activeCardReducer';
import bodiamAction from './bodiamReducer';
import prismicEvents from './prismicEventsReducer';
import configuration from './configurationReducer';
import card from './feature/cardReducer';
import medicalProvider from './medicalProviderReducer';
import error from './core/errorReducer';
import howToClaimLinks from './howToClaimLinksReducer';
import policy from './policyReducer';
import showPopupV2 from './popupV2Reducer';
import fileUpload from './fileUploadReducer';
import claimDocumentsList from './claimDocumentsListReducer';
import policyValidation from './policyValidationReducer';
import datesValidation from './feature/datesReducer';
import files from './feature/filesReducer';
import activePolicy from './feature/activePolicyReducer';
import countries from './feature/countryReducer';

const rootReducer = combineReducers({
  labels,
  howToClaimLinks,
  marketLanguages,
  authentication,
  userInfo,
  activePage,
  decisionTree,
  alerts,
  ajaxCallsInProgress: ajaxStatus,
  currentClaim,
  currentStep,
  inTriage,
  claimsList,
  cardsList,
  policiesList,
  productDetail,
  activeCard,
  bodiamAction,
  prismicEvents,
  configuration,
  card,
  medicalProvider,
  error,
  policy,
  showPopupV2,
  claimDocumentsList,
  fileUpload,
  policyValidation,
  datesValidation,
  files,
  activePolicy,
  countries
});

export default rootReducer;
