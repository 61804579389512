export const RECAPTCHA_ID = 'recaptcha-id';
export const RECAPTCHA_CONTAINER = 'grecaptcha-badge';

export const generateCaptchaScript = url => {
  const script = document.createElement('script');
  script.id = RECAPTCHA_ID;
  script.type = 'text/javascript';
  script.async = true;
  script.defer = true;
  script.src = url;
  script.setAttribute('data-testid', 'recaptcha-script');
  return script;
};

export const makeRecaptchaVisible = isVisible => {
  const recaptcha = document.getElementsByClassName(RECAPTCHA_CONTAINER)[0];
  if (recaptcha) {
    recaptcha.style.display = isVisible ? 'block' : 'none';
    recaptcha.style.visibility = isVisible ? 'visible' : 'hidden';
  }
};

export const hasCaptcha = () =>
  document.getElementById(RECAPTCHA_ID) &&
  document.getElementsByClassName(RECAPTCHA_CONTAINER).length;
