import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { validateField, validateForm } from 'utils/formValidationHelper';
import { useTranslation } from 'react-i18next';
import { Form, TextField, Button } from 'components/shared/Forms';
import { Row, Col } from 'components/shared/Table';
import { formTypes } from 'constants/constants';
import { event } from 'utils/analytics';
import { userRecoverPassword } from 'actions/authenticationActions';
import { GA_EVENTS, GA_ACTIONS } from 'constants/googleAnalytics';
import getSchemaValidation from './RecoverPasswordFormSchemaValidation';
import { appInsights } from 'ApplicationInsights';

const RecoverPasswordForm = () => {
  const { t } = useTranslation();

  const [email, setEmail] = useState({ email: '' });
  const [errorFields, setErrorFields] = useState({});
  const dispatch = useDispatch();
  const schemaValidation = getSchemaValidation();

  function handleRecoverPasswordAction() {
    event(GA_EVENTS.USERACCOUNT, GA_ACTIONS.SUBMIT, {
      label: formTypes.FORGOT_PASSWORD
    });
    appInsights.trackEvent(
      {
        name:formTypes.FORGOT_PASSWORD
      },
      {
        EventCategory: GA_EVENTS.USERACCOUNT,
        EventAction: GA_ACTIONS.SUBMIT,
        EventLabel: formTypes.FORGOT_PASSWORD
      }
      )
    dispatch(userRecoverPassword(email.email));
  }

  const handleOnSubmit = e => {
    e.preventDefault();
    const result = validateForm(schemaValidation, email);
    setErrorFields({
      ...errorFields,
      ...result.errorFields
    });
    if (result.isValid) handleRecoverPasswordAction();
  };

  const handleValidation = (fieldName, value) => {
    const result = validateField(schemaValidation, fieldName, value);
    setErrorFields({
      ...errorFields,
      ...result.errorFields
    });
  };

  return (
    <Form className="mt-4">
      <Row>
        <Col className="col-12 col-md-6">
          <TextField
            label={t('commonFormForgotPasswordEmailLabel')}
            name="email"
            value={email.email}
            onValidate={handleValidation}
            onValueChange={e => setEmail({ email: e.target.value })}
            type="email"
            errorFields={errorFields}
            ariaDescribedby={t('commonFormForgotPasswordEmailLabel')}
            required
            sanitize={false}
          />
        </Col>
      </Row>

      <Row className="no-gutters">
        <Col>
          <Button
            type="submit"
            className="btn btn-primary btn-lg btn-block btn-amex"
            onClick={handleOnSubmit}
            text={t('formResetPasswordbtnLabel')}
          />
        </Col>
      </Row>
    </Form>
  );
};

export default RecoverPasswordForm;
