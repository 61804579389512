import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Row, Col } from 'components/shared/Table';
import { pageview } from 'utils/analytics';
import { getRoute } from 'utils/routeHelper';
import Title from './Title';
import SubTitle from './SubTitle';
import Content from './Content';

import './_FAQPage.scss';
import { appInsights } from 'ApplicationInsights';

const FAQPage = () => {
  const { t } = useTranslation();

  useEffect(() => {    
    pageview({ title: 'FAQ', page: getRoute('faq') });
    appInsights.trackPageView(
      { name: 'FAQ', uri: getRoute('faq') }
    )
  }, []);

  const boxes = [...Array(15).keys()].slice(1);
  const box = number => (
    <div key={number} className="faq-box mb-4 p-4 mt-4">
      <Title key={number} text={t(`FAQpage${number}Title`)} />
      <SubTitle key={number} text={t(`FAQpage${number}Subtitle`)} />
      <Content key={number} text={t(`FAQpage${number}Message`)} />
    </div>
  );

  return (
    <Row className="no-gutters faq-page-box">
      <div className="faq-page pt-4">
        <Col>
          <h4>{t('FAQpageTitle')}</h4>
          <p>{t('FAQpageSubTitle')}</p>
          {boxes.map(box)}
        </Col>
      </div>
    </Row>
  );
};

FAQPage.propTypes = {};

export default FAQPage;
