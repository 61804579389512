import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { colorStyles } from './SelectAttributes';

import './_SelectComponent.scss';

const SelectComponent = props => {
  const componentClass = `react-select-custom-container ${props.className}`;

  const borderColor = () => {
    if (props.required && !props.value) return colorStyles.error;
    if (props.value) return colorStyles.success;
    return colorStyles.generic;
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      border: `1px solid ${borderColor()}`
    })
  };
  return (
    <Select
      isClearable={props.isClearable}
      isDisabled={props.disabled}
      onChange={props.onChange}
      options={props.options}
      value={props.value}
      className={componentClass}
      classNamePrefix="react-select-custom"
      name={props.name}
      placeholder={props.placeholder}
      styles={customStyles}
      theme={theme => ({
        ...theme,
        borderRadius: 2,
        colors: {
          ...theme.colors
        }
      })}
      components={{
        IndicatorSeparator: () => null
      }}
    />
  );
};

SelectComponent.propTypes = {
  isClearable: PropTypes.bool,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string
    })
  ),
  value: PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string
  }),
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string
};

SelectComponent.defaultProps = {
  isClearable: false,
  disabled: false,
  required: false,
  className: '',
  options: [],
  value: null,
  placeholder: ''
};

export default SelectComponent;
