// Disabling Reason: prismic errors are printed out.
/* eslint-disable no-console */
import { ERRORS } from 'constants/constants';
import actionProvider from 'utils/action-provider/action-provider';
import * as types from 'actions/actionTypes';

export function addAlert(type, msg) {
  return { type: types.ADD_ALERT, alert: { type, msg } };
}
export function deleteAllAlert() {
  return { type: types.DELETE_ALL_ALERT, alert: {} };
}

export function deleteAlert(alert) {
  return { type: types.DELETE_ALERT, alert };
}

export function handleApiError(error, isSignIn = false) {
  const type = 'error';

  return (dispatch, getState) => {
    if (error.response && error.response.status) {
      switch (error.response.status) {
        case 500:
          dispatch(addAlert(type, error.response.statusText));
          break;
        case 403:
          dispatch(actionProvider.getActivePageActions().gotoLandingPage());
          break;
        case 401:
          dispatch(addAlert(type, ERRORS.EXCEPTION_NOTAUTHORIZE));
          break;
        case 400:
          dispatch(addAlert(type, error.response.data));
          break;
        default:
          dispatch(addAlert(type, ERRORS.EXCEPTION_GENERIC));
          break;
      }
    } else {
      dispatch(addAlert(type, ERRORS.EXCEPTION_GENERIC));
    }
  };
}

export function handlePrismicError(error) {
  return (dispatch, getState) => {
    let msg = getState().labels.GenericErrorMessage;
    if (msg === undefined) {
      msg = 'Error';
    }
    dispatch(addAlert('error', msg));
  };
}
