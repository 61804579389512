/* eslint-disable no-prototype-builtins */
import { reach } from 'yup';

export function validateField(schemaValidation, fieldName, fieldValue) {
  try {
    // If the field dosen't exist on the schema, it shouldn't run validations.
    if (schemaValidation.fields[fieldName]) {
      const schema = reach(schemaValidation, fieldName);
      schema.validateSync(fieldValue, {
        abortEarly: false
      });
    }
    return resultObject(true, { [fieldName]: errorObject() });
  } catch (error) {
    return resultObject(false, { [fieldName]: errorObject(error.errors) });
  }
}

export function validateForm(schemaValidation, form) {
  try {
    schemaValidation.validateSync(form, {
      stripUnknown: false,
      abortEarly: false
    });

    let errorFields = { ...form };
    for (const propertyElement in form) {
      if (form.hasOwnProperty(propertyElement)) {
        errorFields = { ...errorFields, [propertyElement]: errorObject() };
      }
    }

    return resultObject(true, errorFields);
  } catch (error) {
    const errorFields = error.inner.reduce(
      (x, i) => ({
        ...x,
        [i.path]: errorObject(i.message)
      }),
      {}
    );
    return resultObject(false, errorFields);
  }
}

const errorObject = (message = undefined) => ({ hasError: !!message, message });

const resultObject = (isValid, errorFields = undefined) => ({
  isValid,
  errorFields
});
