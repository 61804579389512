import { handleApiError, addAlert } from 'actions/alertsActions';
import {
  updateActivePage,
  gotoClaimWrapper,
  gotoClaimWrapperCompleted,
  gotoMyClaims
} from 'actions/activePageActions';
import { beginAjaxCall, ajaxCallError } from 'actions/ajaxStatusActions';
import { setSelectedLanguage } from 'actions/labelActions';
import * as types from 'actions/actionTypes';
import claimsApi from 'api/claimsApi';

import { ALERT_TYPES, THANKS } from 'constants/constants';
import { BANK_STEP, MAX_STEP } from 'constants/claim';
import { hideSpinner } from './core/ui';


export const updateClaimData = claim => {
  return { type: types.UPDATE_CLAIM_DATA, claim };
};

export function emptyCurrentClaim() {
  return { type: types.EMPTY_CURRENT_CLAIM };
}

export function clearBenefitId() {
  return { type: types.CLEAR_BENEFIT_ID };
}

export function getClaimSuccess() {
  return { type: types.GET_CLAIM_SUCCESS };
}

export function submitClaimSuccess() {
  return { type: types.SUBMIT_CLAIM_SUCCESS };
}

export function updateCurrentStep(step) {
  return { type: types.UPDATE_CURRENT_STEP, step };
}

export function gotoClaim(claimid) {
  return (dispatch, getState) => {
    const currentLang = getState().marketLanguages.selectedLanguage;   
    dispatch(beginAjaxCall());
      return claimsApi
          .getClaim(claimid, currentLang)
          .then(claim => {
            //var conditionstring=claim.split('*');
              if (!claim.isOfflineClaim) {
                  if (claim.languageId && claim.languageId !== currentLang) {
                      dispatch(
                          addAlert('warning', getState().labels.warningClaimDifferentLang)
                      );
                      dispatch(setSelectedLanguage(claim.languageId));
                  }
                  dispatch(getClaimSuccess());
                  dispatch(
                      updateCurrentStep({
                          step: claim.step,
                          lastStep: claim.step
                      })
                  );
                  dispatch(updateClaimData(claim));
                  if (claim.step > BANK_STEP) {
                      dispatch(gotoClaimWrapperCompleted());
                  } else {
                      dispatch(gotoClaimWrapper());
                  }
              }
              else
              {
               dispatch(
                addAlert('warning', getState().labels.OfflineClaim.replace('@@@', claim.id)));
               dispatch(gotoMyClaims()); 
               dispatch(hideSpinner());
                 
              }
      })
      .catch(error => {
        dispatch(handleApiError(error));
        dispatch(ajaxCallError());
      });
  };
}

export function updateClaimStep(step) {
  return (dispatch, getState) => {
    const currentClaimStep = getState().currentClaim.step;

    if (currentClaimStep !== MAX_STEP && currentClaimStep - 1 <= step) {
      const newClaimStepData = { step };
      dispatch(updateClaimData(newClaimStepData));
    }
  };
}

const removeUnusedAttributesInBackend = currentClaim => {
  const { createDelayIncident, createCaseInput, ...values } = currentClaim;
  return values;
};

export function submitClaim(claimId) {
  return (dispatch, getState) => {
    dispatch(beginAjaxCall());
    return claimsApi
      .submitClaim(
        claimId,
        removeUnusedAttributesInBackend(getState().currentClaim),
        getState().marketLanguages.selectedLanguage
      )
      .then(() => {
        dispatch(submitClaimSuccess());
        dispatch(
          updateCurrentStep({
            step: MAX_STEP,
            lastStep: MAX_STEP
          })
        );
        dispatch(updateActivePage(THANKS));
      })
      .catch(error => {
        dispatch(handleApiError(error));
        dispatch(ajaxCallError());
      });
  };
}
