import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { bindActionCreators } from 'redux';
import { v4 as uuidv4 } from 'uuid';
import { sortBy, isEmpty } from 'lodash';

import {
  addAffectedPersonRequested,
  removeAffectedPersonRequested,
  updateAffectedPersonRequested
} from 'actions/feature/claim';
import { event } from 'utils/analytics';

import {
  CONTACTS_MEDICAL,
  CONTACTS_MEDICAL_REPORT,
  CONTACTS,
  CONTACTS_DEATH,
  CONTACTS_DOB_CARD,
  CONTACTS_MEDICAL_DEATH,
  CONTACTS_HEALTH,
  CONTACTS_MEDICAL_CERTIFICATE,
  CONTACTS_MEDICAL_DEATH_HEALTH,
  CONTACTS_DEATH_HEALTH,
  CONTACTS_MEDICAL_HEALTH,
  CONTACTS_MEDICAL_HEALTH_CERTIFICATE,
  CONTACTS_MEDICAL_HEALTH_REPORT,
  CONTACTS_MEDICAL_DEATH_DOB_PRIMARYCARD,
  CONTACTS_MEDICAL_DISABILITY_REPORT
} from 'constants/constants';

import { GA_EVENTS, GA_ACTIONS } from 'constants/googleAnalytics';

import { Row } from 'components/shared/Table';
import Button from 'components/shared/Forms/Button';
import CardContentOverride from 'components/ContactsQuestion/Shared/CardContentOverride';
import CardOverride from 'components/ContactsQuestion/Shared/CardOverride';
import SlideOverride from 'components/ContactsQuestion/Shared/SlideOverride';
import AffectedPerson from 'components/ContactsQuestion/AffectedPerson/Types/AffectedPerson';
import AffectedPersonWithDocument from 'components/ContactsQuestion/AffectedPerson/Types/AffectedPersonWithDocument';
import AffectedPersonMedicalDeathCertificate from 'components/ContactsQuestion/AffectedPerson/Types/AffectedPersonMedicalDeathCertificate';
import AffectedPersonMedicalDeathHealth from 'components/ContactsQuestion/AffectedPerson/Types/MedicalDeathHealth/AffectedPersonMedicalDeathHealth';
import AffectedPersonWithDateOfBirthMedicalDeathCertificate from 'components/ContactsQuestion/AffectedPerson/Types/DateOfBirthCard/AffectedPersonWithDateOfBirthMedicalDeathCertificate';
import AffectedPersonHealth from 'components/ContactsQuestion/AffectedPerson/Types/AffectedPersonHealth';
import AffectedPersonWithDateOfBirthCard from 'components/ContactsQuestion/AffectedPerson/Types/DateOfBirthCard/AffectedPersonWithDateOfBirthCard';
import AffectedPersonDocumentYesNo from 'components/ContactsQuestion/AffectedPerson/Types/AffectedPersonDocumentYesNo';
import getNextOrder from './AffectedPeopleUtils';
import { appInsights } from 'ApplicationInsights';

export const AffectedPeople = props => {
  const { t } = useTranslation();
  const [editMode, setEditMode] = useState(false);
  const [myPeople, setMyPeople] = useState(
    props.affectedPersons.map(x => {
      return {
        ...x,
        isValid: !!x.isValid || true,
        typeObjectId: props.typeObjectId
      };
    }) || []
  );

  useEffect(() => {
    if (myPeople && myPeople.length === 0) addContact();
  });

  const addContact = () => {
    const newPerson = {
      id: uuidv4(),
      typeObjectId: props.typeObjectId,
      isValid: false,
      payload: {
        order: getNextOrder(myPeople)
      }
    };
    setEditMode(true);
    setMyPeople([...myPeople, newPerson]);
  };

  const handleAddNewContact = () => {
    event(GA_EVENTS.AFFECTEDPERSON, GA_ACTIONS.ADD, {
      label: props.guideWireField
    });
    appInsights.trackEvent(
      {
        name:props.guideWireField
      },
      {
        EventCategory: GA_EVENTS.AFFECTEDPERSON,
        EventAction: GA_ACTIONS.ADD,
        EventLabel: props.guideWireField
      }
      )
    addContact();
  };

  const handleSubmit = e => {
    e.preventDefault();

    const { id, nextGroup } = props;

    event(GA_EVENTS.QUESTION, GA_ACTIONS.ANSWER, {
      label: props.guideWireField
    });
    appInsights.trackEvent(
      {
        name:props.guideWireField
      },
      {
        EventCategory: GA_EVENTS.QUESTION,
        EventAction: GA_ACTIONS.ANSWER,
        EventLabel: props.guideWireField
      }
      )
    props.onUserAnswer(id, JSON.stringify(affectedPeopleIds()), nextGroup);
    props.actions.updateAffectedPersonRequested(myPeople);
    setEditMode(false);
  };

  const continueButton = myPeople.every(x => x.isValid) && editMode && (
    <div>
      <Button
        className="btn btn-primary btn-large btn-block btn-amex mt-3"
        onClick={handleSubmit}
        text={t('commonContinue')}
      />
    </div>
  );

  const updateContact = person => {
    setEditMode(true);
    setMyPeople(
      myPeople.map(myPerson => {
        return myPerson.id === person.id
          ? { ...myPerson, ...person }
          : myPerson;
      })
    );
  };
  const removeContact = id => {
    event(GA_EVENTS.AFFECTEDPERSON, GA_ACTIONS.REMOVE, {
      label: props.guideWireField
    });
    appInsights.trackEvent(
      {
        name:props.guideWireField
      },
      {
        EventCategory: GA_EVENTS.AFFECTEDPERSON,
        EventAction: GA_ACTIONS.REMOVE,
        EventLabel: props.guideWireField
      }
      )
    setEditMode(true);
    setMyPeople(myPeople.filter(person => person.id !== id));
  };
  const affectedPeopleIds = () => myPeople.map(person => person.id);

  const isRemovable = myPeople && myPeople.length > 1;
  const sortByOrder = people => {
    return sortBy(people, person => person.payload.order);
  };

  const getAffectedComponentProps = person => ({
    key: person.id,
    person,
    isRemovable,
    onRemoveContact: () => removeContact(person.id),
    onUpdateContact: updateContact,
    onUserAnswer: props.onUserAnswer
  });

  const getCommonPropsByObjectTypeId = objectId => {
    let commonProps = {};
    switch (objectId) {
      case CONTACTS_DEATH:
      case CONTACTS_DEATH_HEALTH:
        commonProps = {
          label: 'contactsHasDeath',
          labelYesNo: 'contactsWasStable',
          isMedical: false,
          showHelp: false
        };
        break;
      case CONTACTS_MEDICAL_DISABILITY_REPORT:
        commonProps = {
          label: 'contactsHasMedicalReport',
          labelYesNo: 'contactHasPreviousDisability',
          isMedical: false,
          showHelp: false
        };
        break;
      case CONTACTS_MEDICAL:
      case CONTACTS_MEDICAL_CERTIFICATE:
      case CONTACTS_MEDICAL_HEALTH:
      case CONTACTS_MEDICAL_HEALTH_CERTIFICATE:
        commonProps = {
          label: 'contactsHasMedical',
          labelYesNo: 'contactsWasStable',
          isMedical: true,
          showHelp: true
        };
        break;
      case CONTACTS_MEDICAL_REPORT:
      case CONTACTS_MEDICAL_HEALTH_REPORT:
        commonProps = {
          label: 'contactsHasMedicalReport',
          labelYesNo: 'contactsWasStable',
          isMedical: false,
          showHelp: true
        };
        break;
      default:
        commonProps = null;
        break;
    }
    return commonProps;
  };
  const getAffectedWithUploadDocumentProps = person => ({
    ...getAffectedComponentProps(person),
    benefitId: props.benefitId,
    claimId: props.claimId
  });

  const renderChildren = people => {
    return people.map(person => {
      switch (props.typeObjectId) {
        case CONTACTS:
          return <AffectedPerson {...getAffectedComponentProps(person)} />;
        case CONTACTS_DEATH:
        case CONTACTS_MEDICAL:
        case CONTACTS_MEDICAL_CERTIFICATE:
        case CONTACTS_MEDICAL_REPORT:
          return (
            <AffectedPersonWithDocument
              {...getAffectedWithUploadDocumentProps(person)}
              {...getCommonPropsByObjectTypeId(props.typeObjectId)}
            />
          );
        case CONTACTS_MEDICAL_DEATH:
          return (
            <AffectedPersonMedicalDeathCertificate
              {...getAffectedWithUploadDocumentProps(person)}
            />
          );
        case CONTACTS_MEDICAL_DEATH_HEALTH:
          return (
            <AffectedPersonMedicalDeathHealth
              {...getAffectedWithUploadDocumentProps(person)}
            />
          );
        case CONTACTS_HEALTH:
          return (
            <AffectedPersonHealth {...getAffectedComponentProps(person)} />
          );
        case CONTACTS_DOB_CARD:
          return (
            <AffectedPersonWithDateOfBirthCard
              {...getAffectedComponentProps(person)}
              isPrimaryCard={false}
            />
          );
        case CONTACTS_MEDICAL_DEATH_DOB_PRIMARYCARD:
          return (
            <AffectedPersonWithDateOfBirthMedicalDeathCertificate
              {...getAffectedWithUploadDocumentProps(person)}
              isPrimaryCard
            />
          );
        case CONTACTS_DEATH_HEALTH:
        case CONTACTS_MEDICAL_HEALTH:
        case CONTACTS_MEDICAL_HEALTH_REPORT:
        case CONTACTS_MEDICAL_HEALTH_CERTIFICATE:
        case CONTACTS_MEDICAL_DISABILITY_REPORT:
          return (
            <AffectedPersonDocumentYesNo
              {...getAffectedWithUploadDocumentProps(person)}
              {...getCommonPropsByObjectTypeId(props.typeObjectId)}
            />
          );

        default:
          return <div />;
      }
    });
  };

  return (
    <>
      {!isEmpty(myPeople) && (
        <SlideOverride direction="up" in mountOnEnter unmountOnExit>
          <CardOverride className="mb-3">
            <CardContentOverride>
              {renderChildren(sortByOrder(myPeople))}
            </CardContentOverride>
          </CardOverride>
        </SlideOverride>
      )}
      <Row>
        <div className="col-12 col-md-6  mt-3">
          <Button
            className="btn btn-primary btn-large btn-amex"
            onClick={handleAddNewContact}
            text={t('expenseBeneficiaryContactFormAddAnotherButtonLabel')}
          />
        </div>
      </Row>
      <Row>
        <div className="col-12 mt-3">{continueButton}</div>
      </Row>
    </>
  );
};

const mapStateToProps = state => ({
  affectedPersons: state.currentClaim.affectedPersons,
  visibleGroups: state.decisionTree.visibleGroups,
  claimId: state.currentClaim.id,
  benefitId: state.currentClaim.benefitId
});

const mapDispatchToProps = dispatch => ({
  actions: {
    ...bindActionCreators(
      {
        addAffectedPersonRequested,
        updateAffectedPersonRequested,
        removeAffectedPersonRequested
      },
      dispatch
    )
  }
});

AffectedPeople.propTypes = {
  id: PropTypes.number.isRequired,
  nextGroup: PropTypes.number.isRequired,
  typeObjectId: PropTypes.number.isRequired,
  actions: PropTypes.shape({
    addAffectedPersonRequested: PropTypes.func,
    removeAffectedPersonRequested: PropTypes.func,
    updateAffectedPersonRequested: PropTypes.func
  }).isRequired,
  affectedPersons: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      typeObjectId: PropTypes.number.isRequired,
      payload: PropTypes.shape({})
    })
  ).isRequired,
  onUserAnswer: PropTypes.func.isRequired,
  claimId: PropTypes.string.isRequired,
  benefitId: PropTypes.number.isRequired,
  guideWireField: PropTypes.string
};

AffectedPeople.defaultProps = {
  guideWireField: 'affectedPeople'
};

export default connect(mapStateToProps, mapDispatchToProps)(AffectedPeople);
