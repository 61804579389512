import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { BENEFICIARY_CONTACT_FORM_RELATIONSHIPS } from 'constants/constants';

import Select from 'components/shared/Select/SelectComponent';
import {
  LabelFor,
  Label,
  ValidatorText,
  Button
} from 'components/shared/Forms';

const PersonRelationship = props => {
  const { t } = useTranslation();
  const isInvalid =
    props.errorFields[props.name] && props.errorFields[props.name].hasError;

  const renderErrorMessage = errorMessages => (
    <div className="invalid-feedback">{t(errorMessages)}</div>
  );

  const extraRelationships = BENEFICIARY_CONTACT_FORM_RELATIONSHIPS.grouped
    .map(item => {
      const temp = {
        value: item,
        label: t(`expenseBeneficiaryContactFormRelationShipType${item}`),
        key: item
      };
      return temp;
    })
    .sort((relationshipA, relationshipB) => {
      return relationshipA.label > relationshipB.label ? 1 : -1;
    });
  const noGroupedRelationships = BENEFICIARY_CONTACT_FORM_RELATIONSHIPS.notGrouped.map(
    noGrouped => (
      <div
        className="btn-group mr-2"
        role="group"
        aria-label="Mrs."
        key={noGrouped}
      >
        <Button
          id={`btn-personalRelationship-${noGrouped}`}
          type="button"
          className={`btn btn-outline-primary btn-lg btn-amex ${
            props.value === noGrouped ? 'btn-amex-selected' : ''
          }`}
          onClick={() =>
            onRelationshipChange({
              key: noGrouped,
              label: t(
                `expenseBeneficiaryContactFormRelationShipType${noGrouped}`
              )
            })
          }
        >
          {t(`expenseBeneficiaryContactFormRelationShipType${noGrouped}`)}
        </Button>
      </div>
    )
  );

  const selectedExtraRelationship = extraRelationships.find(
    element => element.key === props.value
  );

  const onRelationshipChange = ({ key, label }) =>
    props.onChange(props.name, key, label);

  const isSelected = selectedExtraRelationship ? 'amex-selected' : '';

  return (
    <LabelFor
      htmlFor={props.name}
      name={props.name}
      className="label-input col-12 px-0"
    >
      <Label text={t('beneficiaryContactFormRelationLabel')} isRequired />
      {props.hasHelp && (
        <ValidatorText
          name={props.name}
          text={t('commonSelectRelationship')}
          isSelected={props.isSelected}
        />
      )}
      <div
        className="btn-toolbar"
        role="toolbar"
        aria-label="Toolbar with button groups"
      >
        {noGroupedRelationships}

        <div className="person-relationship-container">
          <Select
            className={`w-100 ${isSelected}`}
            name="extraRelationship"
            placeholder={t('commonFormUserTitlePlaceHolder')}
            options={extraRelationships}
            onChange={onRelationshipChange}
            value={selectedExtraRelationship}
          />
        </div>
      </div>
      {isInvalid && renderErrorMessage(props.errorFields[props.name].message)}
    </LabelFor>
  );
};

PersonRelationship.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  hasHelp: PropTypes.bool,
  isSelected: PropTypes.bool,
  errorFields: PropTypes.shape({
    hasError: PropTypes.bool,
    message: PropTypes.string
  })
};
PersonRelationship.defaultProps = {
  value: null,
  errorFields: {},
  hasHelp: false,
  isSelected: undefined
};

export default PersonRelationship;
