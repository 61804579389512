import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select/creatable';
import { colorStyles } from './SelectAttributes';

import './_SelectComponent.scss';

const CreatableSelectComponent = props => {
  const handleOnInputChange = (inputValue, params) => {
    if (params.action === 'input-change') {
      const value =
        !props.maxLenght || inputValue.length <= props.maxLenght
          ? inputValue
          : inputValue.substr(0, props.maxLenght);

      return props.onInputChange(value);
    }

    return props.inputValue;
  };

  const componentClass = `react-select-custom-container ${props.className}`;

  const borderColor = () => {
    if (!props.isInputValueValid) return colorStyles.error;
    if (props.value) return colorStyles.success;
    return colorStyles.generic;
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      border: `1px solid ${borderColor()}`
    })
  };

  const handleIsValidNewOption = value => props.onValidateOption(value);

  return (
    <Select
      id={`creatableSelect-${props.name}`}
      isClearable={props.isClearable}
      isDisabled={props.disabled}
      isLoading={props.isLoading}
      loadingMessage={() => props.loadingMessage}
      onChange={props.onChange}
      options={props.options}
      value={props.value ? props.value : ''}
      inputValue={props.inputValue}
      className={componentClass}
      classNamePrefix="react-select-custom"
      name={props.name}
      placeholder={props.placeholder}
      styles={customStyles}
      theme={theme => ({
        ...theme,
        borderRadius: 2,
        colors: {
          ...theme.colors
        }
      })}
      components={{
        IndicatorSeparator: () => null
      }}
      formatCreateLabel={userInput =>
        `${props.createNewOptionText} ${userInput}`
      }
      isValidNewOption={handleIsValidNewOption}
      onInputChange={handleOnInputChange}
      noOptionsMessage={() => props.noOptionsMessage}
    />
  );
};

CreatableSelectComponent.propTypes = {
  isClearable: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  loadingMessage: PropTypes.string,
  noOptionsMessage: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string
    })
  ),
  inputValue: PropTypes.string,
  isInputValueValid: PropTypes.bool,
  value: PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string
  }),
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  createNewOptionText: PropTypes.string,
  onValidateOption: PropTypes.func,
  onInputChange: PropTypes.func.isRequired,
  maxLenght: PropTypes.number
};
CreatableSelectComponent.defaultProps = {
  isClearable: false,
  disabled: false,
  isLoading: false,
  loadingMessage: '',
  noOptionsMessage: '',
  className: '',
  options: [],
  inputValue: '',
  isInputValueValid: true,
  value: '',
  placeholder: '',
  createNewOptionText: '',
  onValidateOption: () => true,
  maxLenght: null
};
export default CreatableSelectComponent;
