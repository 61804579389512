import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const MedicalFacilitiesResultsItem = props => {
  const { t } = useTranslation();
  const cls = props.selected
    ? 'facilities-result selected'
    : 'facilities-result';

  const spanClassMr3AlignMiddle = 'mr-3 align-middle';
  const onRouteSelectClick = () => {
    props.onSelect(props.location.latitude, props.location.longitude, props.id);
  };

  return (
    <div className={cls}>
      <h4>{`${props.name.toLowerCase()} (${props.label})`}</h4>
      <p>
        {props.isPreferred && (
          <span className="mr-2">
            <i className="material-icons align-middle">star</i>
          </span>
        )}
        <span className="font-weight-bold mr-3 align-middle">
          {props.category}
        </span>
        <span className={spanClassMr3AlignMiddle}>
          {`${props.distanceInMeters}m`}
        </span>
        <span className={spanClassMr3AlignMiddle}>
          <i className="material-icons align-middle">smartphone</i>
          {props.phoneNumber}
        </span>
      </p>
      <p className="mb-0">
        <span
          className="result-route"
          onClick={() => onRouteSelectClick()}
          onKeyDown={() => onRouteSelectClick()}
        >
          {t('medicalFacilitiesResultRoute')}
        </span>
      </p>
    </div>
  );
};

MedicalFacilitiesResultsItem.propTypes = {
  id: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  location: PropTypes.shape({
    latitude: PropTypes.string,
    longitude: PropTypes.string
  }).isRequired,
  phoneNumber: PropTypes.string.isRequired,
  distanceInMeters: PropTypes.number.isRequired,
  isPreferred: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  selected: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired
};

export default MedicalFacilitiesResultsItem;
