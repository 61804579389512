import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useTranslation } from 'react-i18next';
import { isMarketLufthansa } from 'utils/urlHelpers';
import { Row, Col } from 'components/shared/Table';
import ButtonBack from 'components/shared/Custom/ButtonBack';
import AddPolicyComponent from 'components/AddPolicyComponent';
import BenefitList from 'components/BenefitListComponent';
import { appInsights } from 'ApplicationInsights';
import { LANDING_PAGE } from 'constants/constants';
import {
  getDetails,
  cleanDetails,
  cleanPolicyValidationResult
} from 'actions/feature/policy';
import { goTo } from 'actions/core/app.navigation';

import { pageview } from 'utils/analytics';
import { getRoute } from 'utils/routeHelper';

const CheckPolicyCoverPage = props => {
  useEffect(() => {
    pageview({
      title: 'CheckCover',
      page: getRoute('checkCover', 'policy')
    });
    appInsights.trackPageView(
      { 
        name: 'CheckCover', 
        uri: getRoute('checkCover', 'policy')
     }
    )
  }, []);
  useEffect(() => {
    props.actions.cleanDetails();
    props.actions.cleanPolicyValidationResult();
  }, [props.actions]);

  const { t } = useTranslation();
  const onBackClick = () => {
    props.policyDetails
      ? props.actions.cleanDetails()
      : props.actions.goTo(LANDING_PAGE);
  };
  const onSubmitClick = (
    policyName,
    policyLastName,
    policyNumber,
    policyDate
  ) => {
    props.actions.getDetails(
      policyNumber,
      policyName,
      policyLastName,
      policyDate
    );
  };

  return (
    <div className="d-flex flex-column mx-auto paper-frame">
      <Row>
        <Col>
          <Row>
            <Col>
              <ButtonBack
                onClick={onBackClick}
                labelText={
                  props.policyDetails
                    ? t('buttonBackToSearch')
                    : t('buttonBackCheckCover')
                }
              />
            </Col>
          </Row>
          <Row>
            {!props.policyDetails && (
              <Col>
                <AddPolicyComponent
                  addUserPolicy={onSubmitClick}
                  isLufthansa={isMarketLufthansa()}
                  policyValidation={props.policyValidation}
                  onCleanValidation={() =>
                    props.actions.cleanPolicyValidationResult()
                  }
                  userInfo={props.userInfo}
                />
              </Col>
            )}
            {props.policyDetails && (
              <Col className="section-detail">
                <div className="product-title">
                  {props.policyDetails.imageURL && (
                    <img
                      src={props.policyDetails.imageURL}
                      alt=""
                      className="product-image"
                    />
                  )}
                  <div className="coverage-product">
                    <div>{t('commonAmericanExpress')}</div>
                    <div>{props.policyDetails.description}</div>
                  </div>
                </div>
                {props.policyDetails &&
                  props.policyDetails.benefitsDescription && (
                    <BenefitList
                      benefits={props.policyDetails.benefitsDescription}
                    />
                  )}
              </Col>
            )}
          </Row>
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = state => ({
  policyDetails: state.productDetail,
  policyValidation: state.policyValidation,
  userInfo: state.userInfo,
  languageId: state.marketLanguages.selectedLanguage
});

const mapDispatchToProps = dispatch => ({
  actions: {
    ...bindActionCreators(
      { getDetails, cleanDetails, goTo, cleanPolicyValidationResult },
      dispatch
    )
  }
});

CheckPolicyCoverPage.propTypes = {
  policyDetails: PropTypes.shape({
    description: PropTypes.string,
    imageURL: PropTypes.string,
    benefitsDescription: PropTypes.arrayOf(
      PropTypes.shape({
        benefitLabelCode: PropTypes.string,
        benefitDescriptionLabel: PropTypes.string
      })
    )
  }),
  actions: PropTypes.shape({
    getDetails: PropTypes.func.isRequired,
    cleanDetails: PropTypes.func.isRequired,
    goTo: PropTypes.func.isRequired,
    cleanPolicyValidationResult: PropTypes.func.isRequired
  }).isRequired,
  policyValidation: PropTypes.shape({
    isValid: PropTypes.bool,
    message: PropTypes.string
  }),
  userInfo: PropTypes.shape({
    name: PropTypes.string.isRequired,
    surname: PropTypes.string.isRequired,
    dateOfBirth: PropTypes.string.isRequired
  })
};

CheckPolicyCoverPage.defaultProps = {
  policyDetails: null,
  policyValidation: null,
  userInfo: {
    name: '',
    surname: '',
    dateOfBirth: ''
  }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CheckPolicyCoverPage);
