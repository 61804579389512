import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import { useTranslation } from 'react-i18next';
import IconButton from '@material-ui/core/IconButton';

import { Button } from 'components/shared/Forms';
import { Row, Col } from 'components/shared/Table';
import AnythingElseModalContent from './AnythingElseModalContent';

import './_AnythingElse.scss';

const AnythingElse = props => {
  const { t } = useTranslation();
  const [addingComment, setAddingComment] = useState(false);

  const toggleAddComment = adding => setAddingComment(adding);

  return (
    <div className="main-card anything-else">
      <div className="checked-header mb-2">
        <IconButton
          className="material-icons md-36 mr-2"
          onClick={() => toggleAddComment(true)}
        >
          edit
        </IconButton>
        <h3>{t('anythingTitle')}</h3>
      </div>

      <Row>
        <Col className="col-7 offset-1 mr-2">
          <span className="optiona-note">{t('anythingDescription')}</span>
        </Col>
        <Col className="col-3 beneficiary-checkbox-label">
          <Button
            className="btn btn-primary btn-block btn-amex-continue"
            type="button"
            onClick={() => toggleAddComment(true)}
            text={t('commonAdd')}
          />
        </Col>
      </Row>
      <Row>
        <Col className="col-7 offset-1 mr-2">
          <br />
          <span className="notes-preview">{props.notes}</span>
        </Col>
      </Row>

      <ReactModal
        isOpen={addingComment}
        contentLabel="Anything Else Modal"
        shouldCloseOnOverlayClick
        overlayClassName="overlay"
        className="modal-frame modal-anything-else"
      >
        <AnythingElseModalContent
          onAddComment={props.onAddComment}
          onClose={() => toggleAddComment(false)}
          value={props.notes}
        />
      </ReactModal>
    </div>
  );
};

AnythingElse.propTypes = {
  onAddComment: PropTypes.func.isRequired,
  notes: PropTypes.string.isRequired
};

export default AnythingElse;
