import { SHOW_SPINNER, HIDE_SPINNER } from '../actions/core/ui';
import * as types from '../actions/actionTypes';
import initialState from './initialState';

function actionTypeEndsInSuccess(type) {
  return type.substring(type.length - 8) === '_SUCCESS';
}

export default function ajaxCallsInProgress(
  state = initialState.ajaxCallsInProgress,
  action
) {
  switch (action.type) {
    case types.BEGIN_AJAX_CALL:
      return state + 1;
    case SHOW_SPINNER:
      return state + 1;
    case HIDE_SPINNER:
      return state - 1;
    default:
      if (
        action.type === types.AJAX_CALL_ERROR ||
        (actionTypeEndsInSuccess(action.type) && state > 0)
      ) {
        return state - 1;
      }
      return state;
  }
}
