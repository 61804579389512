import {
  CREATE_USER_AND_CLAIM_REQUESTED,
  CREATE_USER_AND_CLAIM_SUCCEEDED,
  CREATE_USER_AND_CLAIM_FAILED,
  VALIDATE_CARD_NUMBER_AND_CREATE_USER_REQUESTED,
  CREATE_USER_REQUESTED,
  CREATE_USER_SUCCEEDED,
  CREATE_USER_FAILED,
  signInUserAndCreateClaim,
  signInUser
} from 'actions/feature/user';
import { transformNumberAndValidate } from 'actions/feature/card';
import { setUserInfo } from 'actions/userInfoActions';

import * as apiRoutes from 'api/apiRoutes';

import { apiRequest } from 'actions/core/api';
import {
  hideSpinner,
  showSpinner,
  notifySuccess,
  notifyError
} from 'actions/core/ui';
import { setError } from 'actions/core/error';
import { getRecaptchaToken } from 'utils/recaptcha';

const getCard = state =>
  state.card.gencodeCard
    ? {
        gencode: state.card.gencodeCard.value,
        obfuscated: state.card.gencodeCard.obfuscated
      }
    : { gencode: '', obfuscated: '' };

const getPolicy = state => ({
  name: state.policy.name,
  surname: state.policy.lastName,
  number: state.policy.number,
  dateOfBirth: state.policy.date
});

const addCardAndPolicyToUser = (card, policy, userData) => ({
  ...userData,
  card,
  policy
});

export const createUserAndClaimFlow = ({
  dispatch,
  getState
}) => next => async action => {
  next(action);

  if (action.type === CREATE_USER_AND_CLAIM_REQUESTED) {
    const state = getState();
    dispatch(showSpinner());
    const recaptcha = await getRecaptchaToken();
    dispatch(
      apiRequest(
        'POST',
        apiRoutes.SIGN_UP,
        { recaptcha },
        addCardAndPolicyToUser(getCard(state), getPolicy(state), {
          ...action.payload,
          languageId: state.marketLanguages.selectedLanguage
        }),
        {
          ...action.meta,
          username: action.payload.email,
          phone: action.payload.phone,
          password: action.payload.password
        },
        CREATE_USER_AND_CLAIM_SUCCEEDED,
        CREATE_USER_AND_CLAIM_FAILED
      )
    );
  }
};

export const userAndClaimCreationSuccess = ({ dispatch }) => next => action => {
  next(action);
  if (action.type === CREATE_USER_AND_CLAIM_SUCCEEDED) {
    dispatch(
      signInUserAndCreateClaim(
        action.meta.username,
        action.meta.password,
        action.meta.redirectTo
      )
    );
    dispatch(
      setUserInfo({
        username: action.meta.username,
        phone: action.meta.phone,
        email: action.meta.username
      })
    );
    dispatch(hideSpinner());
    dispatch(notifySuccess('registrationSuccessful'));
  }
};

export const userAndClaimCreationFail = ({ dispatch }) => next => action => {
  next(action);

  if (action.type === CREATE_USER_AND_CLAIM_FAILED) {
    dispatch(hideSpinner());
    dispatch(setError(action.payload));
  }
};

export const validateCardAndCreateUserFlow = ({
  dispatch
}) => next => action => {
  next(action);

  if (action.type === VALIDATE_CARD_NUMBER_AND_CREATE_USER_REQUESTED) {
    dispatch(
      transformNumberAndValidate(
        { number: action.payload.cardNumber },
        CREATE_USER_REQUESTED,
        CREATE_USER_FAILED,
        { ...action.meta, userData: action.payload }
      )
    );
  }
};

export const createUserFlow = ({
  dispatch,
  getState
}) => next => async action => {
  next(action);

  if (action.type === CREATE_USER_REQUESTED) {
    const card = {
      gencode: action.payload.gencode,
      obfuscated: action.payload.obfuscated
    };
    dispatch(showSpinner());
    const recaptcha = await getRecaptchaToken();
    dispatch(
      apiRequest(
        'POST',
        apiRoutes.SIGN_UP,
        { recaptcha },
        addCardAndPolicyToUser(
          card,
          {},
          {
            ...action.meta.userData,
            languageId: getState().marketLanguages.selectedLanguage
          }
        ),
        {
          ...action.meta,
          username: action.meta.userData.email,
          password: action.meta.userData.password
        },
        CREATE_USER_SUCCEEDED,
        CREATE_USER_FAILED
      )
    );
  }
};

export const userCreationSuccess = ({ dispatch }) => next => action => {
  next(action);

  if (action.type === CREATE_USER_SUCCEEDED) {
    dispatch(
      signInUser(
        action.meta.username,
        action.meta.password,
        action.meta.redirectTo
      )
    );
    dispatch(hideSpinner());
    dispatch(notifySuccess('registrationSuccessful'));
  }
};

export const userCreationFail = ({ dispatch }) => next => action => {
  next(action);

  if (action.type === CREATE_USER_FAILED) {
    const { status, data } = action.payload;
    if (status === 400 || status === 404) {
      dispatch(notifyError(data));
    } else {
      dispatch(setError(action.payload));
    }
    dispatch(hideSpinner());
  }
};

export default [
  createUserAndClaimFlow,
  userAndClaimCreationSuccess,
  userAndClaimCreationFail,
  validateCardAndCreateUserFlow,
  createUserFlow,
  userCreationSuccess,
  userCreationFail
];
