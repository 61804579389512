import React from 'react';
import PropTypes from 'prop-types';

const Row = props => (
  // eslint-disable-next-line react/prop-types
  <div className={`row ${props.className}`}>{props.children}</div>
);
Row.propTypes = {
  className: PropTypes.string
};

Row.defaultProps = {
  className: ''
};

export default Row;
