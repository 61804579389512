import axios from 'axios';

import { getToken } from '../utils/sessionController';
import { createHeader } from '../utils/apiHelper';

const getContentTypeHeader = type => ({ 'Content-Type': type });
const getContentTypeHeaderAppJson = () =>
  getContentTypeHeader('application/json');

const createHeaderWithToken = customValues => {
  let token = getToken();

  if (token === undefined) {
    token = { token: '' };
  }

  return createHeader({
    Authorization: `Bearer ${token.token}`,
    ...customValues
  });
};

export function endPointCallGet(url) {
  const headers = createHeaderWithToken();

  return axios
    .get(url, {
      headers
    })
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

export function endPointCallPost(url, params, mediaType, headers = {}) {
  const contentHeader = getContentTypeHeader(mediaType || 'application/json');
  const customHeaders = createHeaderWithToken({
    ...headers,
    ...contentHeader
  });

  return axios
    .post(url, params, {
      headers: customHeaders
    })
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

export function endPointCallPut(url, params) {
  const contentHeader = getContentTypeHeaderAppJson();
  const headers = createHeaderWithToken({
    ...contentHeader
  });

  return axios
    .put(url, params, {
      headers
    })
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

export function endPointCallDelete(url) {
  const contentHeader = getContentTypeHeaderAppJson();
  const headers = createHeaderWithToken({
    ...contentHeader
  });
  return axios
    .delete(url, {
      headers
    })
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

export function simpleGet(url) {
  return axios
    .get(url, {
      headers: createHeader()
    })
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}
