import React, { Component } from 'react';

import RegistrationFormInputComponent from './RegistrationFormInputComponent';

class RegistrationInputComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      validation: {
        formControl: '',
        isInValidText: ''
      }
    };
  }

  handleValidationInputUpdate = validation => {
    this.setState({
      validation
    });
  };

  render() {
    return (
      <RegistrationFormInputComponent
        validation={this.state.validation}
        onValidationChange={this.handleValidationInputUpdate}
        {...this.props}
      />
    );
  }
}

RegistrationInputComponent.propTypes = {};
RegistrationInputComponent.defaultProps = {};

export default RegistrationInputComponent;
