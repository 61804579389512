import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button } from 'components/shared/Forms';
import { Row, Col } from 'components/shared/Table';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import {
  validateIsDangerousCharacters,
  sanitizeValue
} from 'utils/validationHelper';

const AnythinElseModalContent = props => {
  const { t } = useTranslation();
  const [comment, setComment] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const styles = {
    popOver: {
      padding: '10px'
    }
  };
  useEffect(() => {
    setComment(props.value);
  }, [props.value]);

  React.useEffect(() => {
    if (anchorEl) {
      window.setTimeout(() => {
        setAnchorEl(null);
      }, 3000);
    }
  }, [anchorEl]);

  const handleDangerousChart = event => {
    const { name, value } = event.target;
    if (props.sanitize && validateIsDangerousCharacters(value)) {
      setAnchorEl(event.currentTarget);
      return { target: { name, value: sanitizeValue(value) } };
    }
    return event;
  };

  const handleSubmit = () => {
    props.onAddComment(comment);
    props.onClose();
  };

  const handleChange = event => {
    const eventChecked = handleDangerousChart(event);
    setComment(eventChecked.target.value);
  };

  return (
    <Row className="custom-modal-content h-100 d-block">
      <Col className="col-12">
        <Row className="modal-body d-block">
          <Col>
            <h3>{t('anythingTitle')}</h3>
            <p>
              <strong>{t('anythingSubtitle')}</strong>
            </p>
            <p className="modal-description">{t('anythingModalDescription')}</p>
            <textarea
              rows="5"
              cols="50"
              maxLength="1000"
              className="mb-2"
              value={comment}
              onChange={handleChange}
            />
            <Popover
              id="popover-advise"
              open={open}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'center',
                horizontal: 'right'
              }}
              transformOrigin={{
                vertical: 'center',
                horizontal: 'left'
              }}
            >
              <Typography color="error" style={styles.popOver}>
                {t('popOverRestrictedCharacters')}
              </Typography>
            </Popover>
          </Col>
        </Row>
        <div className="action-area">
          <Row>
            <Col className="col-xs-2">
              <Button
                type="button"
                className="btn btn-default btn-amex"
                onClick={props.onClose}
                text={t('commonCancel')}
              />
              <Button
                type="submit"
                className="btn btn-primary btn-amex"
                onClick={handleSubmit}
                text={t('commonAddComments')}
              />
            </Col>
          </Row>
        </div>
      </Col>
    </Row>
  );
};

AnythinElseModalContent.propTypes = {
  onAddComment: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  value: PropTypes.string,
  sanitize: PropTypes.bool
};

AnythinElseModalContent.defaultProps = {
  value: '',
  sanitize: true
};

export default AnythinElseModalContent;
