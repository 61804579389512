import React from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';

import UploadFile from './UploadFile';
import UploadingSpinner from './UploadingSpinner';
import UploadedDocument from './UploadedDocument';
import classNames from '../classNames';
import { propTypes } from 'react-recaptcha';

const uploadDropzoneStyle = {
  width: '300px',
  height: '70px',
  marginTop: '-30px',
  marginLeft: '35px',
  marginBottom: '1.5rem',
  borderWidth: '2px',
  borderColor: '#333333',
  borderStyle: 'dashed',
  borderRadius: '5px',
  cursor: 'pointer'
};

const uploadDropzoneClasses = {
  px2: 'px-2',
  upload: 'upload'
};

const UploadDropzone = ({ fileName, onDrop, uploaded, uploading, isHideFilename }) => {
  const dropzoneContent = (() => {
    if (uploading) return <UploadingSpinner />;
      return (fileName || uploaded) && !isHideFilename ? (
      <UploadedDocument fileName={fileName} />
    ) : (
      <UploadFile />
    );
  })();

  return (
    <div
      className={`${uploadDropzoneClasses.upload} ${classNames.inlineBlock}`}
    >
      <Dropzone
        onDrop={onDrop}
        style={uploadDropzoneStyle}
        className={`${classNames.textCenter} ${uploadDropzoneClasses.px2}`}
          >
              
        {dropzoneContent}
      </Dropzone>
    </div>
  );
};

UploadDropzone.propTypes = {
  fileName: PropTypes.string,
  onDrop: PropTypes.func.isRequired,
  uploaded: PropTypes.bool.isRequired,
    uploading: PropTypes.bool.isRequired,
    isHideFilename: propTypes.bool
};

UploadDropzone.defaultProps = {
  fileName: ''
};

export default UploadDropzone;
