import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import {
  validateIsDangerousCharacters,
  sanitizeValue
} from 'utils/validationHelper';

const PurchaseFormPaymentMethodDescription = ({
  paymentMethodTextValue,
  onPaymentMethodTextChange,
  sanitize
}) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const styles = {
    popOver: {
      padding: '10px'
    }
  };

  React.useEffect(() => {
    if (anchorEl) {
      window.setTimeout(() => {
        setAnchorEl(null);
      }, 3000);
    }
  }, [anchorEl]);

  const handleDangerousChart = event => {
    const { name, value } = event.target;
    if (sanitize && validateIsDangerousCharacters(value)) {
      setAnchorEl(event.currentTarget);
      return { target: { name, value: sanitizeValue(value) } };
    }
    return event;
  };

  const handleChange = event => {
    const eventChecked = handleDangerousChart(event);
    onPaymentMethodTextChange(eventChecked);
  };

  const size = {
    rows: 10,
    cols: 40
  };

  const maxCharacter = 50;

  return (
    <>
      <div className="form-group form-check form-check-inline w-100">
        <strong>{t('PurchasesFormPaidMethodsLabelOther')}</strong>
      </div>
      <div className="input-group">
        <textarea
          name="paymentMethodDescription"
          value={paymentMethodTextValue}
          onChange={handleChange}
          rows={size.rows}
          cols={size.cols}
          className="d-block mb-2"
          maxLength={maxCharacter}
          placeholder={t('Common_MaximumChar', { number: maxCharacter })}
        />
        <Popover
          id="popover-advise"
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'left'
          }}
        >
          <Typography color="error" style={styles.popOver}>
            {t('popOverRestrictedCharacters')}
          </Typography>
        </Popover>
      </div>
    </>
  );
};

PurchaseFormPaymentMethodDescription.defaultProps = {
  paymentMethodTextValue: undefined,
  sanitize: true
};

PurchaseFormPaymentMethodDescription.propTypes = {
  onPaymentMethodTextChange: PropTypes.func.isRequired,
  paymentMethodTextValue: PropTypes.string,
  sanitize: PropTypes.bool
};

export default PurchaseFormPaymentMethodDescription;
