import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const LogOutButton = props => {
  const { t } = useTranslation();
  const { onLogOut } = props;
  return (
    <>
      <button
        onClick={onLogOut}
        type="button"
        className="btn btn-amex-selected amex-logo-box text"
      >
        {t('genericLogOut')}
      </button>
      <button
        onClick={onLogOut}
        className="btn btn-amex-selected notext"
        type="button"
      >
        <i className="material-icons icon">power_settings_new</i>
      </button>
    </>
  );
};

LogOutButton.propTypes = {
  onLogOut: PropTypes.func
};

LogOutButton.defaultProps = {
  onLogOut: null
};

export default LogOutButton;
