import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'components/shared/Forms';

import './_NewsModalFooter.scss';

const NewsModalFooter = props => {
  const buttonContainerClassName = 'col';

  return (
    <div className="row news-modal-footer">
      {props.btnLabelCancel && (
        <div className={buttonContainerClassName}>
          <Button
            id="btn-newsModalFooter-Cancel"
            onClick={props.handleBtnCancelClick}
            className={props.btnCancelClassName}
            type="submit"
          >
            {props.btnLabelCancel}
          </Button>
        </div>
      )}
      {props.showOkBtn && (
        <div className={buttonContainerClassName}>
          <Button
            id="btn-newsModalFooter-Ok"
            onClick={props.handleBtnOkClick}
            className={props.btnOkClassName}
            type="submit"
          >
            {props.btnLabelOk}
          </Button>
        </div>
      )}
    </div>
  );
};

NewsModalFooter.propTypes = {
  btnLabelOk: PropTypes.string.isRequired,
  handleBtnOkClick: PropTypes.func.isRequired,
  btnOkClassName: PropTypes.string,
  showOkBtn: PropTypes.bool,

  btnLabelCancel: PropTypes.string,
  handleBtnCancelClick: PropTypes.func,
  btnCancelClassName: PropTypes.string
};

NewsModalFooter.defaultProps = {
  btnOkClassName: 'btn btn-primary btn-large btn-block btn-amex btn-ok',

  btnLabelCancel: '',
  handleBtnCancelClick: () => {},
  btnCancelClassName: 'btn btn-warning btn-large btn-block btn-amex btn-cancel',
  showOkBtn: true
};

export default NewsModalFooter;
