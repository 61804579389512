import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { validateField, validateForm } from 'utils/formValidationHelper';
import { TextField } from 'components/shared/Forms';
import DeleteIconButton from 'components/shared/DeleteButton/DeleteIconButton';
import { Row, Col } from 'components/shared/Table';
import PersonRelationship from 'components/ContactsQuestion/Shared/PersonRelationship';
import {
  maxLengthConstants,
  schemaValidation
} from './AffectedPersonSchemaValidation';

const AffectedPerson = props => {
  const person = {
    id: props.person.id,
    typeObjectId: props.person.typeObjectId,
    isValid: props.person.isValid,
    payload: {
      ...props.person.payload,
      firstName: props.person.payload.firstName,
      lastName: props.person.payload.lastName,
      relationship: props.person.payload.relationship,
      relationshipDescription: props.person.payload.relationshipDescription,
      order: props.person.payload.order
    }
  };

  const [errorFields, setErrorFields] = useState({});
  const [formValues, setFormValues] = useState(person);
  const { t } = useTranslation();

  const onInputChange = event => {
    const clone = {
      ...formValues,
      payload: {
        ...formValues.payload,
        [event.target.name]: event.target.value
      }
    };

    validateAndUpdate(clone);
  };

  const onRelationshipChange = (name, key, value) => {
    const clone = {
      ...formValues,
      payload: {
        ...formValues.payload,
        [name]: key,
        [`${name}Description`]: value
      }
    };

    validateAndUpdate(clone);
  };

  const validateAndUpdate = values => {
    const clone = { ...values, isValid: isFormValid(values) };
    setFormValues(clone);
    props.onUpdateContact(clone);
  };

  const isFormValid = values => {
    const result = validateForm(schemaValidation, values.payload);
    return result.isValid;
  };

  const handleValidation = (fieldName, value) => {
    const result = validateField(schemaValidation, fieldName, value);
    setErrorFields({
      ...errorFields,
      ...result.errorFields
    });
  };

  return (
    <>
      {props.isRemovable && (
        <Row className="d-flex flex-row-reverse">
          <Col className="col-12 col-md-6">
            <DeleteIconButton onClick={props.onRemoveContact} />
          </Col>
        </Row>
      )}
      <Row>
        <Col className="col-12 col-md-6">
          <TextField
            onValueChange={onInputChange}
            onValidate={handleValidation}
            value={formValues.payload.firstName}
            name="firstName"
            label={t('commonFormUserNameLabel')}
            required
            errorFields={errorFields}
            maxLength={maxLengthConstants.firstName}
            ariaDescribedby={t('commonFormUserNameLabel')}
          />
        </Col>
      </Row>
      <Row>
        <Col className="col-12 col-md-6">
          <TextField
            onValueChange={onInputChange}
            onValidate={handleValidation}
            value={formValues.payload.lastName}
            name="lastName"
            label={t('commonFormSurnameLabel')}
            required
            errorFields={errorFields}
            maxLength={maxLengthConstants.lastName}
            ariaDescribedby={t('commonFormSurnamePlaceholder')}
          />
        </Col>
      </Row>
      <div className="form-group form-check form-check-inline w-100">
        <PersonRelationship
          name="relationship"
          value={formValues.payload.relationship}
          onChange={onRelationshipChange}
        />
      </div>
    </>
  );
};

AffectedPerson.propTypes = {
  person: PropTypes.shape({
    id: PropTypes.string,
    typeObjectId: PropTypes.number.isRequired,
    isValid: PropTypes.bool.isRequired,
    payload: PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      relationship: PropTypes.number,
      relationshipDescription: PropTypes.string,
      order: PropTypes.number
    })
  }).isRequired,
  isRemovable: PropTypes.bool,
  onUpdateContact: PropTypes.func.isRequired,
  onRemoveContact: PropTypes.func.isRequired
};
AffectedPerson.defaultProps = {
  isRemovable: false
};

export default AffectedPerson;
