import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Slide from '@material-ui/core/Slide';

const styles = theme => ({
  root: {
    padding: theme.spacing.unit,
    [theme.breakpoints.down('xs')]: {
      boxShadow: 'none'
    }
  }
});

function SlideOverride(props) {
  const { classes, children } = props;
  return (
    <Slide {...props} className={classes.root}>
      {children}
    </Slide>
  );
}

SlideOverride.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.shape({})
  }).isRequired,
  children: PropTypes.shape({}).isRequired
};

export default withStyles(styles)(SlideOverride);
