import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useTranslation } from 'react-i18next';
import { getDocumentLink } from 'actions/feature/howToClaim';
import { DOCUMENT_TYPE } from 'constants/constants';

export const HowToClaimYouProvide = props => {
  useEffect(() => {
    props.actions.getDocumentLink(DOCUMENT_TYPE.MEDICAL_REPORT);
    props.actions.getDocumentLink(DOCUMENT_TYPE.GDPR_REPORT);
  });
  const { t } = useTranslation();
  const updateWithDocumentLink = subtitle => {
    const { documentLink, gdprLink } = props;
    const mapObject = {
      '{0}': `'${documentLink}'`,
      '{1}': `'${gdprLink}'`
    };

    return (
      subtitle &&
      subtitle.replace(/\{0\}|\{1\}/g, matched => mapObject[matched])
    );
  };

  const listItem = props.youProvide.list.map(item => (
    <li key={item.title}>
      <div className="row">
        <i className="material-icons md-36">description</i>
        <div className="col ">
          <strong>{t(item.title)}</strong>
          <br />
          <i
            className="subtitle"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: updateWithDocumentLink(t(item.subtitle))
            }}
          />
        </div>
      </div>
    </li>
  ));

  return (
    <div className="row how-to-claim-section">
      <div className="col">
        <h6>{t(props.youProvide.title)}</h6>
        <ul className="list-you-provide">{listItem}</ul>
      </div>
    </div>
  );
};

HowToClaimYouProvide.propTypes = {
  actions: PropTypes.shape(),
  documentLink: PropTypes.string,
  gdprLink: PropTypes.string,
  youProvide: PropTypes.shape({
    title: PropTypes.string.isRequired,
    list: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        subtitle: PropTypes.string.isRequired
      }).isRequired
    ).isRequired
  }).isRequired
};

HowToClaimYouProvide.defaultProps = {
  documentLink: '',
  gdprLink: '',
  actions: null
};

const mapStateToProps = state => ({
  documentLink: state.howToClaimLinks[DOCUMENT_TYPE.MEDICAL_REPORT],
  gdprLink: state.howToClaimLinks[DOCUMENT_TYPE.GDPR_REPORT]
});

const mapDispatchToProps = dispatch => ({
  actions: {
    ...bindActionCreators({ getDocumentLink }, dispatch)
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HowToClaimYouProvide);
