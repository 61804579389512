import * as types from 'actions/actionTypes';
import initialState from './initialState';

/**
 * @typedef {{
 *    type: string,
 *    text: string,
 *    spans: []
 * }} PrismicTextContent
 *
 *  @typedef {{
 *     title: Array.<PrismicTextContent>,
 *     description: Array.<PrismicTextContent>,
 *     link: {
 *       link_type: string,
 *       url: string
 *     }
 *   }} PrismicEvent
 *
 * @typedef {{
 *  title: string,
 *  description: string,
 *  url: string
 * }} PrismicEventDTO
 */

/**
 * @param {PrismicEvent} prismicEvent
 */
function mapPrismicEventToEventDto(prismicEvent) {
  const title =
    prismicEvent.title && prismicEvent.title[0] && prismicEvent.title[0].text
      ? prismicEvent.title[0].text
      : '';

  let description = '';
  prismicEvent.description.forEach(x => {
    description += x.text;
  });

  const {
    link: { url }
  } = prismicEvent;

  return {
    title,
    description,
    url,
    isRead: false
  };
}

/**
 * Prismic Events reducer.
 * Reacts to EVENTS actions that are liveevents in prismic.
 * @param {Array.<PrismicEventDTO>} state
 * @param {{
 *   type: string,
 *   prismicEvents?: Array.<PrismicEvent>,
 *   index?: number
 * }} action
 * @returns {Array.<PrismicEventDTO>}
 */
export default function prismicEventsReducer(
  state = initialState.prismicEvents,
  action
) {
  const prismicEvents =
    action.prismicEvents && action.prismicEvents.map(mapPrismicEventToEventDto);
  switch (action.type) {
    case types.EVENTS_CALL_SUCCESS:
      return prismicEvents;
    case types.REMOVE_PRISMIC_EVENT:
      return state
        .map((event, index) => (index !== action.index ? event : undefined))
        .filter(x => x);
    case types.SET_READ_PRISMIC_EVENT:
      return state.map((event, index) =>
        index !== action.index ? { ...event } : { ...event, isRead: true }
      );
    case types.SET_ALL_PRISMIC_EVENTS_TO_UNREAD:
      return state.map(event =>
        event ? { ...event, isRead: false } : undefined
      );
    case types.SET_ALL_PRISMIC_EVENTS_TO_READ:
      return state.map(event =>
        event ? { ...event, isRead: true } : undefined
      );
    default:
      return state;
  }
}
