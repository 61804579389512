import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { inBodiam } from 'utils/urlHelpers';
import { event } from 'utils/analytics';
import { GA_EVENTS, GA_ACTIONS } from 'constants/googleAnalytics';
import {
  CLAIM_WRAPPER,
  TRIAGE_WRAPPER,
  LANGUAGE_ID_MAPS
} from 'constants/constants';

import { changeLanguage } from 'actions/labelActions';
import { saveClaim } from 'actions/feature/claim';
import { gotoLandingPage } from 'actions/activePageActions';

import CustomModal from 'shared/components/CustomModal';

import Flag from './Flag';
import './_LanguageSelector.scss';
import { appInsights } from 'ApplicationInsights';

const LanguageSelector = props => {
  const [warning, setWarning] = useState(false);
  const [languageId, setLanguageId] = useState('');

  const getLanguageFlags = () => {
    if (props.languages) {
      return Object.keys(props.languages).map(key => {
        const langId = parseInt(key, 10);
        return (
          <Flag
            langId={langId}
            key={langId}
            selected={langId === props.selectedLanguage}
            onClick={handleSelectLanguage}
          />
        );
      });
    }
    return null;
  };

  const toggleModalOpen = () => {
    event(GA_EVENTS.LANGUAGESELECTOR, GA_ACTIONS.CLICK, {
      label: 'warning-Cancel'
    });
    appInsights.trackEvent(
      {
        name:'warning-Cancel'
      },
      {
        EventCategory: GA_EVENTS.LANGUAGESELECTOR,
        EventAction: GA_ACTIONS.CLICK,
        EventLabel: 'warning-Cancel'
      }
      )
    setWarning(!warning);
  };

  const handleModalOk = () => {
    try {
      event(GA_EVENTS.LANGUAGESELECTOR, GA_ACTIONS.CLICK, {
        label: 'warning-Ok'
      });
      appInsights.trackEvent(
        {
          name:'warning-Ok'
        },
        {
          EventCategory: GA_EVENTS.LANGUAGESELECTOR,
          EventAction: GA_ACTIONS.CLICK,
          EventLabel: 'warning-Ok'
        }
        )
      props.actions.changeLanguage(languageId);
      toggleModalOpen();
      if (props.activePage.includes(CLAIM_WRAPPER)) {
        props.actions.saveClaim(true);
        props.actions.gotoLandingPage();
      }
      // eslint-disable-next-line no-empty
    } catch (e) {}
  };

  const handleSelectLanguage = lang => {
    if (
      props.activePage.includes(CLAIM_WRAPPER) ||
      props.activePage.includes(TRIAGE_WRAPPER)
    ) {
      event(GA_EVENTS.LANGUAGESELECTOR, GA_ACTIONS.CLICK, {
        label: `Claim-${LANGUAGE_ID_MAPS[lang]}`
      });
      appInsights.trackEvent(
        {
          name:`Claim-${LANGUAGE_ID_MAPS[lang]}`
        },
        {
          EventCategory: GA_EVENTS.LANGUAGESELECTOR,
          EventAction: GA_ACTIONS.CLICK,
          EventLabel: `Claim-${LANGUAGE_ID_MAPS[lang]}`
        }
        )
      setWarning(true);
    } else {
      event(GA_EVENTS.LANGUAGESELECTOR, GA_ACTIONS.CLICK, {
        label: LANGUAGE_ID_MAPS[lang]
      });
      appInsights.trackEvent(
        {
          name:LANGUAGE_ID_MAPS[lang]
        },
        {
          EventCategory: GA_EVENTS.LANGUAGESELECTOR,
          EventAction: GA_ACTIONS.CLICK,
          EventLabel: LANGUAGE_ID_MAPS[lang]
        }
        )
      props.actions.changeLanguage(lang);
    }
    setLanguageId(lang);
  };

  const warningMessage = props.activePage.includes(CLAIM_WRAPPER)
    ? 'WarningChangeLanguageClaim'
    : 'WarningChangeLanguageTriage';
  const warningModal = (
    <CustomModal
      isOpen={warning}
      contentLabel={warningMessage}
      shouldCloseOnOverlayClick
      titleClassName=""
      overlayClassName="overlay"
      className="modal-frame"
      handleConfirmationOk={handleModalOk}
      handleConfirmationCancel={toggleModalOpen}
      description={warningMessage}
      labelOk="commonOk"
      labelCancel="commonCancel"
    />
  );
  const cls = inBodiam()
    ? 'language-selector mt-2 my-auto'
    : 'language-selector mt-2';

  return (
    <div className={cls}>
      {getLanguageFlags()}
      {warningModal}
    </div>
  );
};

const mapStateToProps = state => ({
  currentClaim: state.currentClaim,
  selectedLanguage: state.marketLanguages.selectedLanguage,
  activePage: state.activePage,
  languages: state.configuration.TRANSLATIONS
});

const mapDispatchToProps = dispatch => ({
  actions: {
    ...bindActionCreators(
      { saveClaim, gotoLandingPage, changeLanguage },
      dispatch
    )
  }
});

LanguageSelector.propTypes = {
  currentClaim: PropTypes.shape({}).isRequired,
  languages: PropTypes.shape({}).isRequired,
  activePage: PropTypes.string.isRequired,
  actions: PropTypes.shape({
    changeLanguage: PropTypes.func.isRequired,
    gotoLandingPage: PropTypes.func.isRequired,
    saveClaim: PropTypes.func.isRequired
  }).isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(LanguageSelector);
