import React from 'react';
import PropTypes from 'prop-types';
import CardItem from './CardItem';
import classNames from '../classNames';

const howToClaimClass = 'how-to-claim';

const cardItemListContainerClass = `${classNames.row} ${classNames.noGutters} ${howToClaimClass}`;
/**
 * @param {{
 *  cardContainer: {
 *    cards: Array<import('./CardItemParams').CardItemParams>
 *  }
 * }} param0
 */

const CardItemList =

    ({ cardContainer }) => (
         cardContainer.cards.length > 1 ? cardContainer.cards.splice(0, 2) : null ,
  <div className={cardItemListContainerClass}>
    {cardContainer
      ? cardContainer.cards.map(item => <CardItem {...item} key={item.title} />)
                : null}           
        </div>
);

CardItemList.propTypes = {
  cardContainer: PropTypes.shape({
    cards: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        items: PropTypes.arrayOf(PropTypes.string),
        buttonMedicalReport: PropTypes.shape({
          title: PropTypes.string
        }),
        buttonGDPR: PropTypes.shape({
          title: PropTypes.string
        })
      })
    ).isRequired
  })
};

CardItemList.defaultProps = {
  cardContainer: null
};

export default CardItemList;
