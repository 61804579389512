// TODO: technical debts
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { formTypes, REGEX } from 'constants/constants';
import InputComp from 'components/RegistrationForm/RegistrationInputComponent';
import { PhoneField } from 'components/shared/Forms';
import { validateField } from 'utils/formValidationHelper';
import { useTranslation } from 'react-i18next';
import { event } from 'utils/analytics';
import { GA_EVENTS, GA_ACTIONS } from 'constants/googleAnalytics';
import { schemaValidation } from '../EditProfileComponent/EditProfileSchemaValidation';
import { appInsights } from 'ApplicationInsights';

const UserDetails = props => {
  const { userDetails, onInfoSubmit, inThanks } = props;
  const { t } = useTranslation();
  const [errorFields, setErrorFields] = useState({});
  const [email] = useState(userDetails.email);
  const [phone, setPhone] = useState(userDetails.phone);
  const [editing, setEditing] = useState(false);
  const handleOnSubmit = userEvent => {
    userEvent.preventDefault();
    event(GA_EVENTS.USERACCOUNT, GA_ACTIONS.SUBMIT, {
      label: formTypes.UPDATE_USER
    });
    appInsights.trackEvent(
      {
        name:formTypes.UPDATE_USER
      },
      {
        EventCategory: GA_EVENTS.USERACCOUNT,
        EventAction: GA_ACTIONS.SUBMIT,
        EventLabel: formTypes.UPDATE_USER
      }
      )
    setEditing(false);
    onInfoSubmit({ email, phone });
  };

  const handleValueChange = e => {
    setPhone(e.target.value);
  };

  const toggleOnEdition = () => {
    setEditing(!editing);
  };
  const renderStaticContent = () => {
    return (
      <>
        <p
          onClick={() => {
            toggleOnEdition();
          }}
          onKeyDown={() => {
            toggleOnEdition();
          }}
        >
          <span className="edit-icon">{t('commonEdit')}</span>
        </p>
        <p className="font-weight-bold">{t('reviewContactDetailsEmail')}</p>
        <p className="user-details-item">{email}</p>
        <p className="font-weight-bold">{t('reviewContactDetailsPhone')}</p>
        <p className="user-details-item">{phone}</p>
      </>
    );
  };

  const renderForm = () => {
    return (
      <form onSubmit={handleOnSubmit}>
        <p
          onClick={() => {
            toggleOnEdition();
          }}
          onKeyDown={() => {
            toggleOnEdition();
          }}
        >
          <span className="edit-icon cancel">{t('commonCancel')}</span>
        </p>
        <InputComp
          value={email}
          name="email"
          type="email"
          disabled
          label={t('reviewContactDetailsEmail')}
          ariaDescribedby={t('reviewContactDetailsEmail')}
          fullSize
          lgColSize={8}
        />
        <PhoneField
          name="phone"
          label={t('reviewContactDetailsPhone')}
          value={phone}
          onValueChange={handleValueChange}
          onValidate={handleValidation}
          errorFields={errorFields}
          colSize={8}
        />
        <button
          type="submit"
          className="btn btn-primary btn-block btn-amex"
          disabled={!validate()}
        >
          {t('Common_Save_Label')}
        </button>
      </form>
    );
  };

  const handleValidation = (fieldName, value) => {
    const result = validateField(schemaValidation, fieldName, value);
    setErrorFields({
      ...errorFields,
      ...result.errorFields
    });
  };

  const validate = () => {
    return validEmail() && validPhone();
  };

  const validPhone = () => {
    return phone && (!errorFields.phone || !errorFields.phone.hasError);
  };

  const validEmail = () => {
    const re = REGEX.VALIDATE_EMAIL;
    return re.test(String(email).toLowerCase());
  };

  const renderCardTitle = () =>
    inThanks ? (
      <strong className="mb-0">{t('reviewContactDetailsTitle')}</strong>
    ) : (
      <h3 className="mb-0">{t('reviewContactDetailsTitle')}</h3>
    );

  return (
    <div className="row no-gutters ">
      <div className="col right-card user-details">
        <div className="card">
          <div className="card-header">{renderCardTitle()}</div>
          <div className="card-body">
            {editing ? renderForm() : renderStaticContent()}
          </div>
        </div>
      </div>
    </div>
  );
};

UserDetails.propTypes = {
  userDetails: PropTypes.shape({
    email: PropTypes.string,
    phone: PropTypes.string
  }).isRequired,
  onInfoSubmit: PropTypes.func.isRequired,
  inThanks: PropTypes.bool
};

UserDetails.defaultProps = {
  inThanks: false
};

export default UserDetails;
