// eslint-disable-next-line import/prefer-default-export
export function changeOneTrustLanguage(language) {
  if (window.OneTrust) {
    window.OneTrust.changeLanguage(language);
  } else {
    setTimeout(() => {
      if (window.OneTrust) {
        window.OneTrust.changeLanguage(language);
      }
    }, 700);
  }
}
