import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';

import {
  setActivityMetadata,
  getActivityMetadata
} from 'utils/sessionController';
import * as activePageActions from 'actions/activePageActions';
import * as authenticationActions from 'actions/authenticationActions';
import * as alertsActions from 'actions/alertsActions';
import * as labelActions from 'actions/labelActions';

import CustomModal from 'shared/components/CustomModal';

const LOGOUT_TIME = {
  WARN: process.env.REACT_APP_LOGOUT_TIME_WARN,
  SIGNOUT: process.env.REACT_APP_LOGOUT_TIME_SIGNOUT
};

export class AutoLogout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      warning: false
    };

    this.setTimeout = this.setTimeout.bind(this);
    this.clearTimeoutFunc = this.clearTimeoutFunc.bind(this);
    this.resetTimeout = this.resetTimeout.bind(this);
    this.warn = this.warn.bind(this);
    this.logout = this.logout.bind(this);
    this.isInactivityValidForAllTabs = this.isInactivityValidForAllTabs.bind(
      this
    );
  }

  componentDidMount() {
    const events = [
      'load',
      'mousemove',
      'mousedown',
      'click',
      'scroll',
      'keypress'
    ];

    for (let i = 0, len = events.length; i < len; i += 1) {
      window.addEventListener(events[i], this.resetTimeout);
    }

    this.setTimeout();
  }

  setTimeout() {
    setActivityMetadata();
    this.warnTimeout = setTimeout(this.warn, LOGOUT_TIME.WARN);
    this.logoutTimeout = setTimeout(this.logout, LOGOUT_TIME.SIGNOUT);
  }

  clearTimeoutFunc() {
    if (this.warnTimeout) clearTimeout(this.warnTimeout);

    if (this.logoutTimeout) clearTimeout(this.logoutTimeout);
  }

  resetTimeout() {
    this.setState({
      warning: false
    });
    this.clearTimeoutFunc();
    this.setTimeout();
  }

  isInactivityValidForAllTabs(expirationValue) {
    const activityValue = getActivityMetadata();
    const now = new Date();
    const diffInMinutes = Math.round((now - activityValue) / 60000);
    const expirationValueInMinutes = Math.round(expirationValue / 60000);

    return diffInMinutes >= expirationValueInMinutes;
  }

  warn() {
    if (this.isInactivityValidForAllTabs(LOGOUT_TIME.WARN)) {
      this.setState({
        warning: true
      });
    }
  }

  logout() {
    if (this.isInactivityValidForAllTabs(LOGOUT_TIME.SIGNOUT)) {
      this.setState({
        warning: false
      });
      this.props.actions.onLogout();
    }
  }

  render() {
    return (
      <>
        {this.props.children}
        {this.state.warning && (
          <CustomModal
            isOpen={this.state.warning}
            contentLabel="Credit Card Modal"
            shouldCloseOnOverlayClick={false}
            overlayClassName="overlay"
            className="modal-frame logout"
            handleConfirmationOk={this.resetTimeout}
            description={this.props.labels.sessionExpirationWarning}
            labelOk={this.props.labels.commonOk}
          />
        )}
      </>
    );
  }
}

AutoLogout.propTypes = {
  children: PropTypes.element.isRequired,
  labels: PropTypes.shape({
    sessionExpirationWarning: PropTypes.string,
    commonOk: PropTypes.string
  }).isRequired,
  actions: PropTypes.shape({
    onLogout: PropTypes.func.isRequired,
    updateActivePage: PropTypes.func.isRequired,
    cleanCurrentAccount: PropTypes.func.isRequired,
    setClaimsList: PropTypes.func.isRequired,
    setCardsList: PropTypes.func.isRequired,
    setPoliciesList: PropTypes.func.isRequired,
    addAlert: PropTypes.func.isRequired,
    loadLanguagesAndDefaultLabels: PropTypes.func.isRequired
  }).isRequired
};

const mapStateToProps = state => ({
  labels: state.labels,
  userInfo: state.userInfo
});

const mapDispatchToProps = dispatch => ({
  actions: {
    ...bindActionCreators(activePageActions, dispatch),
    ...bindActionCreators(authenticationActions, dispatch),
    ...bindActionCreators(alertsActions, dispatch),
    ...bindActionCreators(labelActions, dispatch)
  }
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AutoLogout)
);
