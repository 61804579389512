import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classNames from '../classNames';

const uploadDocumentClasses = {
  ellipsis: 'ellipsis',
  uploadDropText: 'upload__drop__text',
  mb1: 'mb-1'
};
export const UploadedDocumentFallbackName = 'uploadComponentUploaded';

/** @param {{ fileName: string }} param0 */
const UploadedDocument = ({ fileName }) => {
  const { t } = useTranslation();
  return (
    <span
      className={`${uploadDocumentClasses.uploadDropText} ${uploadDocumentClasses.ellipsis}`}
    >
      <i
        className={`${classNames.materialIcons} ${classNames.mr2} ${uploadDocumentClasses.mb1} ${classNames.alignMiddle}`}
      >
        check_circle
      </i>
      {fileName || t(UploadedDocumentFallbackName)}
    </span>
  );
};

UploadedDocument.propTypes = {
  fileName: PropTypes.string
};

UploadedDocument.defaultProps = {
  fileName: null
};

export default UploadedDocument;
