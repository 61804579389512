import { object, string, date } from 'yup';
import moment from 'moment';
import { ERRORS } from 'constants/constants';
import { maxLength } from 'constants/inputsLengths';
import { commonEmailValidations } from 'components/shared/CommonSchemaValidations';

// eslint-disable-next-line import/prefer-default-export
export const schemaValidation = object({
  name: string()
    .max(maxLength.firstName)
    .required(ERRORS.INVALID_TEXT)
    .typeError(ERRORS.INVALID_TEXT),
  surname: string()
    .max(maxLength.surnames)
    .required(ERRORS.INVALID_TEXT)
    .typeError(ERRORS.INVALID_TEXT),
  dateFrom: date()
    .transform(rawValue => moment(rawValue).toDate())
    .required(ERRORS.INVALID_TEXT),
  dateTo: date()
    .transform(rawValue => moment(rawValue).toDate())
    .required(ERRORS.INVALID_TEXT),
  country: string()
    .required(ERRORS.INVALID_TEXT)
    .typeError(ERRORS.INVALID_TEXT),
  ...commonEmailValidations
});
