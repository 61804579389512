import { HOW_TO_CLAIM, CARD_NUMBER, POLICY_NUMBER } from 'constants/constants';
import * as types from 'actions/actionTypes';
import DecisionTreeApi from 'api/DecisionTreeApi';
import { beginAjaxCall, ajaxCallError } from 'actions/ajaxStatusActions';
import { updateClaimData, clearBenefitId } from 'actions/claimActions';
import { handleApiError } from 'actions/alertsActions';

import {
  getUserAnswersFromTree,
  transferAnswers,
  getIndexOfObjectByProp
} from 'utils/AnswerHelper';

// I am using action creators here.

export function loadTreeSuccess(tree) {
  return { type: types.LOAD_TREE_SUCCESS, tree };
}

function loadCreditCardTreeSuccess(tree) {
  return { type: types.LOAD_CREDIT_CARD_TREE_SUCCESS, tree };
}

function loadBenefitsTreeSuccess(tree) {
  return { type: types.LOAD_BENEFITS_TREE_SUCCESS, tree };
}

export function addVisibleGroup(group) {
  return { type: types.ADD_VISIBLE_GROUP, group };
}
// TODO: move this logic to middleware
function isHowToClaim(visibleGroups) {
  return (
    visibleGroups.length &&
    visibleGroups[visibleGroups.length - 1][0].type === HOW_TO_CLAIM
  );
}

export function updateVisibleGroups(visibleGroups) {
  return dispatch => {
    if (isHowToClaim(visibleGroups)) dispatch(clearBenefitId());
    dispatch({ type: types.UPDATE_VISIBLE_GROUPS, visibleGroups });
  };
}

export function updateUserAnswers(userAnswers) {
  return { type: types.UPDATE_USER_ANSWERS, userAnswers };
}

export function updateCurrentTree(currentTree) {
  return { type: types.UPDATE_CURRENT_TREE, currentTree };
}

export function checkPolicyCoverageSuccess(tree) {
  return { type: types.CHECK_POLICY_COVERAGE_SUCCESS, tree };
}

function buildNextVisibleGroups(userAnswers, decisionTree) {
  let lastGroupId = null;

  const result = userAnswers.reduce((acc, userAnswer) => {
    const nextGroup =
      decisionTree.questionnaireTreeByGroupId[userAnswer.nextGroupId];
    // Avoid building next visible groups when nextGroup is null
    if (lastGroupId !== userAnswer.nextGroupId) {
      lastGroupId = userAnswer.nextGroupId;
      return nextGroup ? [...acc, nextGroup] : acc;
    }
    return acc;
  }, decisionTree.visibleGroups.slice(0, 1));
  return result;
}

function buildNextUserAnswers(newAnswers, prevUserAnswers) {
  const result = newAnswers.reduce((acc, newAnswer) => {
    let tempAcc = acc.slice(0);
    const index = prevUserAnswers.findIndex(
      userAnswer => userAnswer.questionId === newAnswer.questionId
    );
    const isAlreadyAnswered = index >= 0;
    const hasDifferentValue =
      isAlreadyAnswered && tempAcc[index].value !== newAnswer.value;
    const isNextGroupDifferent =
      isAlreadyAnswered &&
      newAnswer.nextGroupId !== prevUserAnswers[index].nextGroupId;

    if (!isAlreadyAnswered) {
      tempAcc = tempAcc.concat(newAnswer);
    } else if (hasDifferentValue) {
      tempAcc[index].value = newAnswer.value;
      if (isNextGroupDifferent) {
        tempAcc = [...tempAcc.slice(0, index), newAnswer];
      }
    }
    return tempAcc;
  }, prevUserAnswers.slice(0));

  return result;
}

export function resetVisibleGroups() {
  return { type: types.RESET_VISIBLE_GROUPS };
}

export function resetUserAnswers() {
  return { type: types.RESET_USER_ANSWERS };
}

export function resetDecisionTree() {
  return { type: types.RESET_DECISION_TREE };
}

export function addUserAnswers(answers) {
  return { type: types.ADD_USER_ANSWER, answers };
}

export function updateUserAnswersAndVisibleGroups(newGroupAnswer) {
  return (dispatch, getState) => {
    const { decisionTree } = getState();
    const nextUserAnswers = buildNextUserAnswers(
      newGroupAnswer,
      decisionTree.userAnswers
    );
    const nextVisibleGroups = buildNextVisibleGroups(
      nextUserAnswers,
      decisionTree
    );

    dispatch(updateUserAnswers(nextUserAnswers));
    dispatch(updateVisibleGroups(nextVisibleGroups));
  };
}

export function deleteLastUserAnswerAndUpdateVisibleGroups() {
  return (dispatch, getState) => {
    const { decisionTree } = getState();
    const userAnswers = decisionTree.userAnswers.slice(
      0,
      decisionTree.userAnswers.length
    );
    const newGroupAnswer = userAnswers.slice(0, userAnswers.length - 1);
    const nextVisibleGroups = buildNextVisibleGroups(
      newGroupAnswer,
      decisionTree
    );
    dispatch(updateUserAnswers(newGroupAnswer));
    dispatch(updateVisibleGroups(nextVisibleGroups));
  };
}

export function deleteAnswerByQuestionIdAndUpdateVisibleGroups(questionId) {
  return (dispatch, getState) => {
    const { decisionTree } = getState();
    const userAnswers = decisionTree.userAnswers.filter(
      x => x.questionId < questionId
    );

    const nextVisibleGroups = buildNextVisibleGroups(userAnswers, decisionTree);
    dispatch(updateUserAnswers(userAnswers));
    dispatch(updateVisibleGroups(nextVisibleGroups));
  };
}

export function addUserAnswer(answer) {
  return { type: types.ADD_USER_ANSWER, answer };
}

const mapByGroupId = treeArr =>
  treeArr.reduce((acc, question) => {
    // eslint-disable-next-line prefer-const
    let tempTreeMap = { ...acc };
    const groupValue = tempTreeMap[question.group];
    if (groupValue) {
      tempTreeMap[question.group] = groupValue.concat(question);
    } else {
      tempTreeMap[question.group] = [question];
    }
    return tempTreeMap;
  }, {});

const getFirstVisibleGroup = (treeArr, treeByGroupId) => {
  const firstGroupId = treeArr.initialQuestion;
  return treeByGroupId[firstGroupId];
};

export function loadDecisionTree(treeLabel) {
  return (dispatch, getState) => {
    dispatch(beginAjaxCall());
    const currentTree = getState().currentClaim.trees[treeLabel];
    const treeByGroupId = mapByGroupId(currentTree.questions);

    const savedUserAnswers = getUserAnswersFromTree(
      treeByGroupId,
      currentTree.initialQuestion
    );
    const firstVisibleGroup = getFirstVisibleGroup(currentTree, treeByGroupId);

    dispatch(loadTreeSuccess(treeByGroupId));
    dispatch(addVisibleGroup(firstVisibleGroup));

    if (
      savedUserAnswers.length > 0 &&
      !(savedUserAnswers.length === 1 && !savedUserAnswers[0])
    ) {
      const { decisionTree } = getState();
      const visibleGroups = buildNextVisibleGroups(
        savedUserAnswers,
        decisionTree
      );

      dispatch(updateUserAnswers(savedUserAnswers));
      dispatch(updateVisibleGroups(visibleGroups));
    }
  };
}

export function loadTriagedDecisionTree() {
  return (dispatch, getState) => {
    dispatch(beginAjaxCall());
    return DecisionTreeApi.getTriagedDecisionTree(
      getState().marketLanguages.selectedLanguage
    )
      .then(tree => {
        const treeByGroupId = mapByGroupId(tree.questions);
        const firstVisibleGroup = getFirstVisibleGroup(tree, treeByGroupId);

        dispatch(loadTreeSuccess(treeByGroupId));
        dispatch(addVisibleGroup(firstVisibleGroup));
      })
      .catch(error => {
        dispatch(handleApiError(error));
        dispatch(ajaxCallError());
      });
  };
}

export function loadBenefitsDecisionTree(productId, benefitId, questionId) {
  return (dispatch, getState) => {
    dispatch(beginAjaxCall());
    return DecisionTreeApi.getBenefitsdDecisionTree(
      getState().marketLanguages.selectedLanguage,
      productId,
      benefitId
    )
      .then(newTree => {
        const treeByGroupId = mapByGroupId(newTree.questions);
        const nextGroup = getFirstVisibleGroup(newTree, treeByGroupId);
        const benefitUserAnswer = {
          questionId,
          value: benefitId,
          nextGroupId: nextGroup[0].group
        };
        dispatch(addUserAnswer(benefitUserAnswer));
        dispatch(loadBenefitsTreeSuccess(treeByGroupId));
        dispatch(addVisibleGroup(nextGroup));
        return { treeByGroupId, nextGroup };
      })
      .catch(error => {
        dispatch(handleApiError(error));
        dispatch(ajaxCallError());
      });
  };
}

/**
 *
 * @param {string} productId
 * @param {string} questionId
 * @param {string} cardValue
 */
export function appendCreditCardTree(productId, questionId, cardValue) {
  return async (dispatch, getState) => {
    dispatch(beginAjaxCall());
    return DecisionTreeApi.getTriagedDecisionTree(
      getState().marketLanguages.selectedLanguage,
      productId
    )
      .then(newTree => {
        const treeByGroupId = mapByGroupId(newTree.questions);
        const nextGroup = getFirstVisibleGroup(newTree, treeByGroupId);
        const creditCardUserAnswer = {
          questionId,
          value: cardValue,
          nextGroupId: nextGroup[0].group
        };
        const cardQuestionIndex = getIndexOfObjectByProp(
          getState().decisionTree.userAnswers,
          'questionId',
          questionId,
          false
        );
        if (cardQuestionIndex > -1) {
          // Updating instead of adding card number question answer
          // Cleaning old answers after credit card number not to create conflicts with how to claim
          const clone = [
            ...getState().decisionTree.userAnswers.slice(
              0,
              cardQuestionIndex + 1
            )
          ];
          clone[cardQuestionIndex].value = cardValue;
          dispatch(updateUserAnswers(clone));
        } else dispatch(addUserAnswer(creditCardUserAnswer));

        const newClaim = {
          productId,
          trees: {
            GeneralTriage: {
              questions: transferAnswers(getState().decisionTree.userAnswers),
              initialQuestion: 0
            }
          }
        };
        // Avoids adding same tree multiple times
        const groups = getState().decisionTree.visibleGroups;
        const cardQuestionIndx = getIndexOfObjectByProp(
          groups,
          'type',
          CARD_NUMBER
        );
        const lastQuestionIndex =
          cardQuestionIndx > -1
            ? cardQuestionIndx
            : getIndexOfObjectByProp(groups, 'type', POLICY_NUMBER);
        const cleanVisibleGroups = groups.slice(0, lastQuestionIndex + 1);

        dispatch(updateVisibleGroups(cleanVisibleGroups));
        dispatch(updateClaimData(newClaim));
        dispatch(loadCreditCardTreeSuccess(treeByGroupId));
        dispatch(addVisibleGroup(nextGroup));
      })
      .catch(error => {
        dispatch(handleApiError(error));
        dispatch(ajaxCallError());
      });
  };
}

export function checkPolicyCoverage(productId, benefitId) {
  return (dispatch, getState) => {
    dispatch(beginAjaxCall());
    return new Promise(resolve => {
      DecisionTreeApi.getTriagedDecisionTree(
        getState().marketLanguages.selectedLanguage,
        productId,
        benefitId
      )
        .then(isCovered => {
          dispatch(checkPolicyCoverageSuccess());
          resolve(isCovered);
        })
        .catch(error => {
          dispatch(handleApiError(error));
          dispatch(ajaxCallError());
        });
    });
  };
}
