import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { event } from 'utils/analytics';
import history from 'utils/history';
import { getMarketFromUrl } from 'utils/urlHelpers';
import * as authenticationActions from 'actions/authenticationActions';
import {
  BODIAM_BASE_URL,
  ARUNDEL_BASE_URL,
  CERTIFICATE,
  PROVIDERS
} from 'constants/constants';
import { GA_EVENTS, GA_ACTIONS } from 'constants/googleAnalytics';
import SidebarDropdownToggleYourCover from './SidebarDropdownToggleYourCover';
import SidebarDropdownToggleItem from './SidebarDropdownToggleItem';
import { appInsights } from 'ApplicationInsights';

export const SidebarDropdownToggle = props => {
  const goHome = () => {
    history.push(`${BODIAM_BASE_URL}${getMarketFromUrl()}/`);
    props.onRedirect();
  };

  const goMyDetails = () => {
    event(GA_EVENTS.SIDEBAR, GA_ACTIONS.CLICK, {
      label: 'myAccount'
    });
    appInsights.trackEvent(
      {
        name:'myAccount'
      },
      {
        EventCategory: GA_EVENTS.SIDEBAR,
        EventAction: GA_ACTIONS.CLICK,
        EventLabel: 'myAccount'
      }
      )
    props.actions.bodiamViewProfileAction();
    props.actions.gotoMyClaims();
    history.push(`${ARUNDEL_BASE_URL}${getMarketFromUrl()}`);
  };
  const goMedicalFacilities = () => {
    history.push(`${BODIAM_BASE_URL}${getMarketFromUrl()}${PROVIDERS}`);
    props.onRedirect();
  };

  const goCertificates = () => {
    history.push(`${BODIAM_BASE_URL}${getMarketFromUrl()}${CERTIFICATE}`);
    props.onRedirect();
  };

  const onCreateClaim = () => {
    event(GA_EVENTS.SIDEBAR, GA_ACTIONS.CLICK, {
      label: 'bodiamMakeClaim'
    });
    appInsights.trackEvent(
      {
        name:'bodiamMakeClaim'
      },
      {
        EventCategory: GA_EVENTS.SIDEBAR,
        EventAction: GA_ACTIONS.CLICK,
        EventLabel: 'bodiamMakeClaim'
      }
      )
    props.onRedirect();
    props.makeAClaim();
  };

  const handleLogout = () => {
    event(GA_EVENTS.SIDEBAR, GA_ACTIONS.CLICK, {
      label: 'genericLogOut'
    });
    appInsights.trackEvent(
      {
        name:'genericLogOut'
      },
      {
        EventCategory: GA_EVENTS.SIDEBAR,
        EventAction: GA_ACTIONS.CLICK,
        EventLabel: 'genericLogOut'
      }
      )
    props.onRedirect();
    props.authenticationActions.onLogout();
  };

  const { t } = useTranslation();
  const { isLoggedIn } = props;
  return (
    <>
      {isLoggedIn && (
        <>
          <SidebarDropdownToggleItem
            label={t('navbarInsuranceHome')}
            onClick={goHome}
          />
          <SidebarDropdownToggleItem
            label={t('landingPageButtonContSubtitle')}
            onClick={goMyDetails}
          />

          <SidebarDropdownToggleYourCover onRedirect={props.onRedirect} />
          <SidebarDropdownToggleItem
            label={t('bodiamGetInsuranceCertificate')}
            onClick={goCertificates}
          />
          <SidebarDropdownToggleItem
            label={t('bodiamFindMedicalProviders')}
            onClick={goMedicalFacilities}
          />
        </>
      )}
      <SidebarDropdownToggleItem
        label={t('bodiamMakeClaim')}
        onClick={onCreateClaim}
      />
      {isLoggedIn && (
        <SidebarDropdownToggleItem
          label={t('genericLogOut')}
          onClick={handleLogout}
        />
      )}
    </>
  );
};

SidebarDropdownToggle.propTypes = {
  actions: PropTypes.shape({
    bodiamViewProfileAction: PropTypes.func.isRequired,
    gotoMyClaims: PropTypes.func.isRequired
  }).isRequired,
  authenticationActions: PropTypes.shape({
    onLogout: PropTypes.func
  }),
  isLoggedIn: PropTypes.bool,
  makeAClaim: PropTypes.func.isRequired,
  onRedirect: PropTypes.func.isRequired
};

SidebarDropdownToggle.defaultProps = {
  isLoggedIn: false,
  authenticationActions: {
    onLogout: () => false
  }
};

const mapDispatchToProps = dispatch => ({
  authenticationActions: bindActionCreators(authenticationActions, dispatch)
});

export default connect(null, mapDispatchToProps)(SidebarDropdownToggle);
