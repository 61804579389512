// Disabling Reason: errors regarding fetching data are printed out
/* eslint-disable no-console */
import Prismic from 'prismic-javascript';

export default class prismicApi {
  static queryPrismic(api, method, params) {
    // Might need to add some Predicates (filters)
    return new Promise((resolve, reject) =>
      api[method](...params).then((data, error) => {
        if (error) reject(error);
        resolve(data);
      })
    ).catch(err => console.warn(err));
  }

  static queryByID(api, id) {
    return this.queryPrismic(api, 'getByIDs', id);
  }

  static queryByIDs(api, ids) {
    return this.queryPrismic(api, 'getByIDs', [ids]);
  }

  static queryByTags(api, tags, lang) {
    const predicates = [Prismic.Predicates.at('document.tags', tags)];

    const params = [predicates, { lang }];
    // const params = orderings ? [predicates, { orderings: '[my.furtherquestion.order]' }] : [predicates];
    return this.queryPrismic(api, 'query', params);
  }

  static query(api, tags, types, lang) {
    const predicates = [Prismic.Predicates.at('document.tags', tags)];

    types.forEach(type => {
      predicates.push(Prismic.Predicates.at('document.type', type));
    });

    const params = [predicates, { lang }];

    return this.queryPrismic(api, 'query', params);
  }
}
