import {
  TASKLIST,
  WHAT_HAPPENED,
  DATES,
  EXPENSES,
  PREVIOUS,
  BANK,
  REVIEW,
  THANKS
} from './constants';

export const TASKLIST_STEP = 0;
export const WHAT_HAPPENED_STEP = 1;
export const DATES_STEP = 2;
export const EXPENSES_STEP = 3;
export const PREVIOUS_STEP = 4;
export const BANK_STEP = 5;
export const REVIEW_STEP = 6;
export const THANKS_STEP = 7;

export const MAX_STEP = 6;

export const TREE_SECTION_BY_STEP_ID = {
  1: 'incidentDescription',
  2: 'dates',
  4: 'insurances',
  5: 'bankData'
};

export const EXPENSES_SECTION = {
  purchases: 'purchases',
  beneficiaries: 'beneficiaries'
};

export const PAGE_BY_STEP_ID = {
  0: {
    activePage: TASKLIST,
    stepName: 'TASKLIST',
    nextStep: WHAT_HAPPENED_STEP
  },
  1: {
    activePage: WHAT_HAPPENED,
    stepName: 'WHAT_HAPPENED',
    nextStep: DATES_STEP
  },
  2: {
    activePage: DATES,
    stepName: 'DATES',
    nextStep: EXPENSES_STEP
  },
  3: {
    activePage: EXPENSES,
    stepName: 'EXPENSES',
    nextStep: PREVIOUS_STEP
  },
  4: {
    activePage: PREVIOUS,
    stepName: 'PREVIOUS',
    nextStep: BANK_STEP
  },
  5: {
    activePage: BANK,
    stepName: 'BANK',
    nextStep: REVIEW_STEP
  },
  6: {
    activePage: REVIEW,
    stepName: 'REVIEW',
    nextStep: MAX_STEP
  },
  7: {
    activePage: THANKS,
    stepName: 'THANKS',
    nextStep: MAX_STEP
  }
};

export const BAGGAGE_DELAY = 'BAGGAGE DELAY';
export const BAGGAGE_DELAY_EXTENDED = 'BAGGAGE DELAY EXTENDED';
export const BAGGAGE_MONEY_TRAVEL_DOCUMENT = 'BAGGAGE-MONEY & TRAVEL DOCS';
export const CANCELLATION = 'CANCELLATION';
export const CDW = 'CDW';
export const CURTAILMENT = 'CURTAILMENT';
export const MEDICAL_EXPENSES = 'MEDICAL EXPENSES';
export const MISSED_CONNECTION = 'MISSED CONNECTION';
export const MISSED_DEPARTURE = 'MISSED DEPARTURE';
export const TRAVEL_DELAY = 'TRAVEL DELAY';

export const BENEFIT_BY_ID = {
  1: BAGGAGE_DELAY,
  2: BAGGAGE_DELAY_EXTENDED,
  3: BAGGAGE_MONEY_TRAVEL_DOCUMENT,
  4: CANCELLATION,
  5: CDW,
  6: CURTAILMENT,
  8: MEDICAL_EXPENSES,
  9: MISSED_CONNECTION,
  10: MISSED_DEPARTURE,
  13: TRAVEL_DELAY
};
