import { UPDATE_USER_SESSION } from 'actions/feature/user';
import { SIGN_IN_SUCCESS } from 'actions/actionTypes';
import initialState from './initialState';

// IMPORTANT: Note that with Redux, state should NEVER be changed.
// State is considered immutable. Instead,
// create a copy of the state passed and set new values on the copy.
// Note that I'm using Object.assign to create a copy of current state
// and update values on the copy.
export default function labels(state = initialState.authentication, action) {
  switch (action.type) {
    case UPDATE_USER_SESSION:
      return { ...state, token: action.token };
    case SIGN_IN_SUCCESS:
      return { ...state, token: action.token, errorMessageOnLogin: null };

    default:
      return state;
  }
}
