import initialState from './initialState';
import { SET_APP_CONFIG } from '../actions/configurationActions';

/**
 * Set the configuration object into the store's state.
 * @param {import('../ConfigurationProvider/withConfig').MyConfig} state
 * @param {{
 *  type: import('../actions/configurationActions').SET_APP_CONFIG,
 *  config: import('../ConfigurationProvider/withConfig').MyConfig
 * }} param1
 */
const configurationReducer = (
  state = initialState.configuration,
  { type, config }
) => (type === SET_APP_CONFIG ? config : state);

export default configurationReducer;
