import React from 'react';
import Icon from '@material-ui/core/Icon';
import PropTypes from 'prop-types';

const IconLabel = props => (
  <div className="icon-label">
    <Icon className={props.iconClassName}>{props.icon}</Icon>
    <span className={props.labelClassName}>{props.label}</span>
  </div>
);

IconLabel.propTypes = {
  iconClassName: PropTypes.string.isRequired,
  icon: PropTypes.shape({}).isRequired,
  labelClassName: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired
};

export default IconLabel;
