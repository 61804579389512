import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import RegistrationPage from 'containers/RegistrationPage';
import Card from 'components/ProductItemComponent';
import Loading from 'components/Loading/Loading';
import NewsCarousel from 'Bodiam/containers/NewsCarousel/NewsCarousel';
import { withTranslation } from 'react-i18next';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentTitle: 'loginInsuranceCentre'
    };
    this.setCurrentTitle = this.setCurrentTitle.bind(this);
  }

  setCurrentTitle(label) {
    this.setState({
      currentTitle: label
    });
  }

  render() {
    return (
      <>
        <h1 className="text-center mb-4">
          {this.props.t(this.state.currentTitle)}
        </h1>
        <Card classes="bodiam-card">
          <RegistrationPage onSelect={this.setCurrentTitle} />
        </Card>
        <div>
          <Loading
            loading={this.props.loading}
            text={this.props.t('loading')}
          />
          <NewsCarousel />
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  loading: state.ajaxCallsInProgress > 0
});

Login.propTypes = {
  loading: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired
};

export default connect(mapStateToProps, null)(withTranslation()(Login));
