import PropTypes from 'prop-types';
import React, { Component } from 'react';
import NumberFormat from 'react-number-format';
import { withConfig } from '../../ConfigurationProvider';

export class Currency extends Component {
  onValueChange = ({ formattedValue, value, floatValue }) => {
    this.props.onValueChange({ formattedValue, value, floatValue });
  };

  render() {
    return (
      <>
        <NumberFormat
          id={this.props.id}
          thousandSeparator={
            this.props.config.REGIONAL_SETTINGS.thousandSeparator
          }
          decimalSeparator={
            this.props.config.REGIONAL_SETTINGS.decimalSeparator
          }
          allowNegative={false}
          isNumericString
          fixedDecimalScale
          decimalScale={2}
          prefix={this.props.prefix}
          type="text"
          className={this.props.className}
          max={this.props.max}
          min={this.props.min}
          value={this.props.amount}
          onValueChange={this.onValueChange}
          aria-label={this.props.ariaLabel}
          disabled={this.props.disabled}
          mask={this.props.mask}
          isAllowed={({ formattedValue, value, floatValue }) =>
            floatValue >= this.props.min && floatValue <= this.props.max
          }
        />
      </>
    );
  }
}

Currency.propTypes = {
  id: PropTypes.string.isRequired,
  prefix: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  amount: PropTypes.string.isRequired,
  max: PropTypes.number.isRequired,
  min: PropTypes.number.isRequired,
  ariaLabel: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  mask: PropTypes.string.isRequired,
  onValueChange: PropTypes.func.isRequired,
  config: PropTypes.shape({
    REGIONAL_SETTINGS: PropTypes.shape({
      thousandSeparator: PropTypes.string.isRequired,
      decimalSeparator: PropTypes.string.isRequired
    }).isRequired
  }).isRequired
};

export default withConfig(Currency);
