import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { LETTERS } from 'constants/constants';
import MedicalProvidersResultsItem from './MedicalProvidersResultsItem';

const MedicalProvidersResults = props => {
  const { t } = useTranslation();

  const getDistanceInMeters = (distance, unit) => {
    switch (unit.toUpperCase()) {
      case 'KMET':
        return Math.round(distance * 1000);
      case 'MTS':
        return distance;
      default:
        throw new Error('Error, unit not defined');
    }
  };

  const getInternationalPhoneNumber = (prefix, phoneNumber) =>
    prefix ? prefix + phoneNumber : phoneNumber;

  const getResultsInfo = () =>
    props.facilities.map((facility, key) => (
      <MedicalProvidersResultsItem
        key={facility.identifier.providerId}
        id={facility.identifier.providerId}
        name={facility.identifier.name}
        category={t(`medicalFacilitiesKinds${facility.category}`)}
        location={facility.location}
        phoneNumber={getInternationalPhoneNumber(
          facility.contactInformation.internationalPrefix,
          facility.contactInformation.phoneNumber.number
        )}
        distanceInMeters={getDistanceInMeters(
          facility.distance.value,
          facility.distance.unit
        )}
        isPreferred={facility.rating.isPreferred}
        onSelect={props.onSelect}
        selected={facility.identifier.providerId === props.selectedId}
        label={LETTERS[key] || '-'}
      />
    ));

  return (
    <div className="facilities-results">
      {getResultsInfo()}
      <div className="back-search__btn">
        <button
          type="submit"
          className="btn btn-primary btn-large btn-block btn-amex btn-amex-selected"
          onClick={props.onFilter}
        >
          {t('medicalFacilitiesResultsFilter')}
        </button>
      </div>
    </div>
  );
};

MedicalProvidersResults.propTypes = {
  onSelect: PropTypes.func.isRequired,
  facilities: PropTypes.arrayOf(
    PropTypes.shape({
      category: PropTypes.string,
      identifier: PropTypes.shape({
        providerId: PropTypes.string,
        name: PropTypes.string
      }),
      contactInformation: PropTypes.shape({
        phoneNumber: PropTypes.shape({
          internationalPrefix: PropTypes.string,
          number: PropTypes.string
        })
      }),
      rating: PropTypes.shape({
        isPreferred: PropTypes.bool
      }),
      location: PropTypes.shape({
        longitude: PropTypes.string,
        latitude: PropTypes.string
      }),
      distance: PropTypes.shape({
        value: PropTypes.string,
        unit: PropTypes.string
      })
    })
  ).isRequired,
  selectedId: PropTypes.string,
  onFilter: PropTypes.func.isRequired
};

MedicalProvidersResults.defaultProps = {
  selectedId: null
};

export default MedicalProvidersResults;
