import React from 'react';
import PropTypes from 'prop-types';
import { NA } from 'constants/constants';
import { useTranslation } from 'react-i18next';
import { Icon } from '@material-ui/core';
import { green } from '@material-ui/core/colors';

const QADataQuestion = props => {    
    const { t } = useTranslation();


  if (props.answer === undefined || props.answer === NA) {
    return <span />;
    }
    
  return (
    <div className="container mb-2 formatted-question">
      <div className="row">
        <div className="col-md-5 col-sm-12">
          <span className="font-weight-bold">{props.label}</span>
              </div>             
              {props.sendingtype == 2 ? (
                  <div className="col-md-6 col-sm-12 formatted-answer">
                      <div className="row">
                      <i style={{ color: "red" }} class="material-icons">error</i>
                         &nbsp;&nbsp;  {t('ActionRequired\n')}
                      </div></div>
              ) : (
                      <div className="col-md-6 col-sm-12 formatted-answer">
                     <div className="row">
                          {props.fileName != null && props.fileName != "" ? <Icon style={{ color: green[500] }} fontSize="small">
                              check_circle
                          </Icon> : ""}
                             &nbsp;&nbsp; {props.fileName} {props.fileName == null || props.fileName == "" ? props.answer:""}
                          </div></div>
                          
                  )}                     
      </div>
    </div>
  );
};

QADataQuestion.propTypes = {
  label: PropTypes.string.isRequired,
  answer: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({}),
    PropTypes.node
  ]).isRequired
};

export default QADataQuestion;
