import React from 'react';
import PropTypes from 'prop-types';
import PartnerLogo from './PartnerLogo';

const TopHeaderArundel = props => (
  <div className="row no-gutters amex-header-row">
    <div className="amex-header-container mx-md-auto mx-xs-0 w-100">
      <div className="top-header">
        <div className=" second-bar">
          <div className="row no-gutters h-100 pt-xl-0">
            <div className="nav-bar-container col m-auto h-100">
              <div className="headerArundel">
                <PartnerLogo collapsed />
                <div className="spacer" />
                <div className="item item-logout">
                  {props.isLoggedIn && (
                    <button
                      onClick={props.onLogOut}
                      type="button"
                      className="btn btn-amex-selected arundel"
                    >
                      {props.logoutText}
                    </button>
                  )}
                  <div />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

TopHeaderArundel.propTypes = {
  isLoggedIn: PropTypes.bool,
  onLogOut: PropTypes.func,
  logoutText: PropTypes.string.isRequired
};

TopHeaderArundel.defaultProps = {
  isLoggedIn: false,
  onLogOut: null
};

export default TopHeaderArundel;
