import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Questionnaire from 'containers/Questionnaire';
import NotificationModal from 'components/NotificationModal';

import { gotoRegistrationPage } from 'actions/activePageActions';
import {
  loadTriagedDecisionTree,
  loadBenefitsDecisionTree,
  resetDecisionTree
} from 'actions/decisionTreeActions';
import { updateClaimData } from 'actions/claimActions';
import { createClaim as newCreateClaim } from 'actions/feature/claim';
import { getUserCards, getUserPolicies } from 'actions/authenticationActions';

import { transferAnswers } from 'utils/AnswerHelper';
import { getToken } from 'utils/sessionController';
import { TASKLIST } from 'constants/constants';

import { pageview } from 'utils/analytics';
import { getRoute } from 'utils/routeHelper';
import { appInsights } from 'ApplicationInsights';

export class TriageWrapper extends Component {
  constructor(props) {
    super(props);

    this.gotoRegistrationPage = this.gotoRegistrationPage.bind(this);
    this.handleOnContinue = this.handleOnContinue.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.didLanguageJustChange = this.didLanguageJustChange.bind(this);
    this.hasBenefitJustBeenReset = this.hasBenefitJustBeenReset.bind(this);
    this.hasBenefitJustBeenSet = this.hasBenefitJustBeenSet.bind(this);

    this.state = {
      showModal: false
    };
  }

  componentDidMount() {
    pageview({ title: 'Triage', page: getRoute('triage') });
    appInsights.trackPageView(
      {
        name: 'Triage', uri: getRoute('triage') 
      }
    )
    this.props.actions.resetDecisionTree();
    this.props.actions.loadTriagedDecisionTree();

    if (this.isUserAlreadyLoggedIn()) {
      this.props.actions.getUserCards();
      this.props.actions.getUserPolicies();
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.hasBenefitJustBeenSet(prevProps.currentClaim) &&
      this.hasBenefitJustBeenReset()
    ) {
      const newClaimTrees = { ...this.props.currentClaim.trees };
      newClaimTrees.ProductTriage = {
        questions: transferAnswers(this.props.userAnswers),
        initialQuestion: 0
      };
      this.props.actions.updateClaimData({ trees: newClaimTrees });

      const lastGroupId = this.props.visibleGroups[
        this.props.visibleGroups.length - 1
      ][0].id;
      this.props.actions.loadBenefitsDecisionTree(
        this.props.currentClaim.productId,
        this.props.currentClaim.benefitId,
        lastGroupId
      );
    }

    if (this.didLanguageJustChange(prevProps.marketLanguages)) {
      this.props.actions.resetDecisionTree();
      this.props.actions.loadTriagedDecisionTree();
    }
  }

  isUserAlreadyLoggedIn = () => getToken();

  hasBenefitJustBeenSet(prevClaim) {
    return (
      (!prevClaim.benefitId && this.props.currentClaim.benefitId) ||
      prevClaim.benefitId !== this.props.currentClaim.benefitId
    );
  }

  hasBenefitJustBeenReset() {
    return !!this.props.currentClaim.benefitId;
  }

  didLanguageJustChange(prevLang) {
    return (
      prevLang.selectedLanguage !== this.props.marketLanguages.selectedLanguage
    );
  }

  showModal() {
    this.setState({ showModal: true });
  }

  closeModal() {
    this.setState({ showModal: false });
  }

  gotoRegistrationPage() {
    this.props.actions.gotoRegistrationPage();
  }

  handleOnContinue() {
    // setting BenefitTriage
    const newClaimTrees = { ...this.props.currentClaim.trees };
    newClaimTrees.BenefitTriage = {
      questions: transferAnswers(this.props.userAnswers),
      initialQuestion: 0
    };
    this.props.actions.updateClaimData({ trees: newClaimTrees });

    if (this.isUserAlreadyLoggedIn()) {
      const newClaim = { ...this.props.currentClaim };
      newClaim.trees = newClaimTrees;

      this.props.actions.newCreateClaim(newClaim, TASKLIST);
    } else {
      this.gotoRegistrationPage();
    }
  }

  render() {
    return (
      <>
        <Questionnaire onContinue={this.handleOnContinue} />
        <NotificationModal
          isOpen={this.state.showModal}
          onRequestClose={this.closeModal}
          onClick={this.closeModal}
          text="policyBenefitCoverageNotIncluded"
        />
      </>
    );
  }
}

const mapStateToProps = state => ({
  visibleGroups: state.decisionTree.visibleGroups,
  userAnswers: state.decisionTree.userAnswers,
  currentClaim: state.currentClaim,
  inTriage: state.inTriage,
  cardsList: state.cardsList,
  marketLanguages: state.marketLanguages
});

const mapDispatchToProps = dispatch => ({
  actions: {
    ...bindActionCreators(
      {
        gotoRegistrationPage,
        resetDecisionTree,
        loadTriagedDecisionTree,
        loadBenefitsDecisionTree,
        getUserCards,
        getUserPolicies,
        updateClaimData,
        newCreateClaim
      },
      dispatch
    )
  }
});

TriageWrapper.propTypes = {
  userAnswers: PropTypes.arrayOf(PropTypes.object).isRequired,
  visibleGroups: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.object))
    .isRequired,
  currentClaim: PropTypes.shape({
    trees: PropTypes.shape({}),
    benefitId: PropTypes.number,
    productId: PropTypes.number
  }).isRequired,
  actions: PropTypes.shape({
    gotoRegistrationPage: PropTypes.func.isRequired,
    loadTriagedDecisionTree: PropTypes.func.isRequired,
    loadBenefitsDecisionTree: PropTypes.func.isRequired,
    resetDecisionTree: PropTypes.func.isRequired,
    updateClaimData: PropTypes.func.isRequired,
    newCreateClaim: PropTypes.func.isRequired,
    getUserCards: PropTypes.func.isRequired,
    getUserPolicies: PropTypes.func.isRequired
  }).isRequired,
  marketLanguages: PropTypes.shape({
    selectedLanguage: PropTypes.number.isRequired
  }).isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(TriageWrapper);
