import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'components/shared/Table';
import { useTranslation } from 'react-i18next';
import getBankDataSchemaValidation, {
  maxLengthConstants
} from 'containers/BankData/BankDataSchemaValidation';
import { validateField, validateForm } from 'utils/formValidationHelper';
import {
  BANK_TYPE_LIST,
  BANK_LIST,
  IBAN,
  SWIFT_CODE,
  SORT_CODE,
  ACCOUNT
} from 'constants/bank';
import { getMarketFromUrl, isUKMarket } from 'utils/urlHelpers';
import { TextField } from 'components/shared/Forms';
import AutoCompleteInput from 'containers/BankData/AutoCompleteInput/AutoCompleteInput';
import { sanitizeTranslation } from 'utils/validationHelper';

const BankDataForm = props => {
  const MARKET = getMarketFromUrl();
  const isUkMarket = isUKMarket(MARKET);
  const bankDataSchemaValidation = getBankDataSchemaValidation(isUkMarket);
  const { t } = useTranslation();

  const [errorFields, setErrorFields] = useState({});
  const [formValues, setFormValues] = useState({
    name: (props.bankData && props.bankData.name) || '',
    holder: (props.bankData && props.bankData.holder) || '',
    account: (props.bankData && props.bankData.account) || '',
    accountType: (props.bankData && props.bankData.accountType) || '',
    sortCode: (props.bankData && props.bankData.sortCode) || '',
    iban: (props.bankData && props.bankData.iban) || '',
    swiftCode: (props.bankData && props.bankData.swiftCode) || ''
  });
  const tooltipIBANText = sanitizeTranslation('tooltipIBAN', t('tooltipIBAN'));
  const tooltipSwiftCodeText = sanitizeTranslation(
    'tooltipSwiftCode',
    t('tooltipSwiftCode')
  );
  const tooltipAccountNumberText = sanitizeTranslation(
    'tooltipAccountNumber',
    t('tooltipAccountNumber')
  );
  const tooltipSortCodeText = sanitizeTranslation(
    'tooltipSortCode',
    t('tooltipSortCode')
  );

  React.useEffect(() => {
    const result = validateForm(bankDataSchemaValidation, props.bankData);
    props.toggleFormValid(result.isValid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.bankData]);

  const handleValidation = (fieldName, value) => {
    const result = validateField(bankDataSchemaValidation, fieldName, value);
    setErrorFields({
      ...errorFields,
      ...result.errorFields
    });
  };

  const sanitizeformValue = (value, name) => {
    let sanitizedValue = value;
    name === IBAN ||
    name === SWIFT_CODE ||
    name === ACCOUNT ||
    name === SORT_CODE
      ? (sanitizedValue = value.replace(/\s/g, ''))
      : (sanitizedValue = value);
    return sanitizedValue;
  };
  const handleValueChange = event => {
    const value = sanitizeformValue(event.target.value, event.target.name);
    return handleChange(value, event.target.name);
  };
  const handleValueChangeUpper = event => {
    const value = sanitizeformValue(
      event.target.value,
      event.target.name
    ).toUpperCase();
    return handleChange(value, event.target.name);
  };
  const getBankTypes = () =>
    BANK_TYPE_LIST[formValues.name] !== undefined
      ? BANK_TYPE_LIST[formValues.name]
      : [];

  const handleChange = (value, name) => {
    const newState = {
      ...formValues,
      [name]: value
    };
    setFormValues(newState);

    if (!isUkMarket) newState.account = '';
    props.saveFormInfo(newState);
  };

  // eslint-disable-next-line no-return-assign
  return (
    <>
      {isUkMarket ? (
        <>
          <Row>
            <Col className="col-12 col-md-6">
              <AutoCompleteInput
                regExp={/\w+/g}
                required
                label={t('bankProviderAndName')}
                parentUpdateState={value => handleChange(value, 'name')}
                autoCompleteItems={BANK_LIST}
                maxSuggests={5}
                errorFields={errorFields}
                name="name"
                id="name"
                onValidate={handleValidation}
                includeSearchTerm
                value={formValues.name}
              />
            </Col>
          </Row>
          <Row>
            <Col className="col-12 col-md-6">
              <AutoCompleteInput
                regExp={/\w+/g}
                required
                toolTipText={t('bankType_tooltip')}
                parentUpdateState={value => handleChange(value, 'accountType')}
                autoCompleteItems={() => getBankTypes()}
                maxSuggests={5}
                errorFields={errorFields}
                label={t('bankType')}
                name="accountType"
                id="accountType"
                onValidate={handleValidation}
                includeSearchTerm
                value={formValues.accountType}
              />
            </Col>
          </Row>
        </>
      ) : null}

      <Row>
        <Col className="col-12 col-md-6">
          <TextField
            id="input-bankDataForm-holder"
            label={t('bankAccountHolder')}
            name="holder"
            value={formValues.holder}
            onValueChange={handleValueChange}
            errorFields={errorFields}
            maxLength={maxLengthConstants.holder}
            required
            ariaDescribedby={t('emailHelp')}
            onValidate={handleValidation}
          />
        </Col>
      </Row>

      {!isUkMarket ? (
        <>
          <Row>
            <Col className="col-12 col-md-6">
              <TextField
                id="input-bankDataForm-iban"
                label={t('bankIBANCode')}
                name={IBAN}
                value={formValues.iban && formValues.iban.replace(/\s/g, '')}
                onValueChange={handleValueChangeUpper}
                errorFields={errorFields}
                required
                toolTipText={tooltipIBANText}
                onValidate={handleValidation}
                maxLength={maxLengthConstants.iban}
              />
            </Col>
          </Row>
          <Row>
            <Col className="col-12 col-md-6">
              <TextField
                id="input-bankDataForm-swiftCode"
                label={t('bankSwiftCode')}
                name={SWIFT_CODE}
                value={formValues.swiftCode}
                onValueChange={handleValueChangeUpper}
                errorFields={errorFields}
                required
                toolTipText={tooltipSwiftCodeText}
                onValidate={handleValidation}
                maxLength={maxLengthConstants.swiftCode}
              />
            </Col>
          </Row>
        </>
      ) : (
        <>
          <Row>
            <Col className="col-12 col-md-6">
              <TextField
                id="input-bankDataForm-account"
                label={t('bankAccountNumber')}
                name={ACCOUNT}
                value={
                  formValues.account && formValues.account.replace(/[^\d]/g, '')
                }
                onValueChange={handleValueChange}
                errorFields={errorFields}
                required
                toolTipText={tooltipAccountNumberText}
                onValidate={handleValidation}
                maxLength={maxLengthConstants.account}
              />
            </Col>
          </Row>
          <Row>
            <Col className="col-12 col-md-6">
              <TextField
                id="input-bankDataForm-sortCode"
                label={t('bankSortCode')}
                name={SORT_CODE}
                value={
                  formValues.sortCode &&
                  formValues.sortCode.replace(/[^\d]/g, '')
                }
                onValueChange={handleValueChange}
                errorFields={errorFields}
                required
                toolTipText={tooltipSortCodeText}
                onValidate={handleValidation}
                maxLength={maxLengthConstants.sortCode}
              />
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

BankDataForm.propTypes = {
  saveFormInfo: PropTypes.func.isRequired,
  toggleFormValid: PropTypes.func.isRequired,
  bankData: PropTypes.shape({
    name: PropTypes.string.isRequired,
    accountType: PropTypes.string,
    holder: PropTypes.string.isRequired,
    account: PropTypes.string,
    sortCode: PropTypes.string,
    iban: PropTypes.string,
    swiftCode: PropTypes.string
  })
};

BankDataForm.defaultProps = {
  bankData: {
    name: '',
    accountType: '',
    holder: '',
    account: '',
    sortCode: '',
    iban: '',
    swiftCode: ''
  }
};

export default BankDataForm;
