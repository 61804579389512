import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';

import { event } from 'utils/analytics';
import {
  validateIsDangerousCharacters,
  sanitizeValue
} from 'utils/validationHelper';

import { GA_EVENTS, GA_ACTIONS } from 'constants/googleAnalytics';
import { appInsights } from 'ApplicationInsights';

const TextAreaQuestion = props => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const styles = {
    textArea: {
      width: '100%'
    },
    popOver: {
      padding: '10px'
    }
  };
  const [textValue, setTextValue] = useState(
    props.userAnswer ? props.userAnswer.value : ''
  );
  const [hiddenButton, setHiddenButton] = useState(props.mandatory);
  React.useEffect(() => {
    if (anchorEl) {
      window.setTimeout(() => {
        setAnchorEl(null);
      }, 3000);
    }
  }, [anchorEl]);

  const handleDangerousChart = e => {
    const { name, value } = e.target;
    if (props.sanitize && validateIsDangerousCharacters(value)) {
      setAnchorEl(e.currentTarget);
      return { target: { name, value: sanitizeValue(value) } };
    }
    return e;
  };

  const handleChange = e => {
    const eventChecked = handleDangerousChart(e);
    const { value } = eventChecked.target;
    setTextValue(value);
    setHiddenButton(!(props.mandatory && value && value.length >= 3));
  };

  const handleSubmit = e => {
    e.preventDefault();

    event(GA_EVENTS.QUESTION, GA_ACTIONS.ANSWER, {
      label: `${props.guideWireField}`
    });
    appInsights.trackEvent(
      {
        name:`${props.guideWireField}`
      },
      {
        EventCategory: GA_EVENTS.QUESTION,
        EventAction: GA_ACTIONS.ANSWER,
        EventLabel: `${props.guideWireField}`
      }
      )
	  
    props.onUserAnswer(props.id, textValue, props.nextGroup);
  };

  const { isWide } = props;
  const wide = {
    rows: 10,
    cols: 40
  };
  const regular = {
    rows: 5,
    cols: 20
  };
  const size = isWide ? wide : regular;
  return (
    <form onSubmit={handleSubmit}>
      <textarea
        value={textValue}
        onChange={handleChange}
        rows={size.rows}
        cols={size.cols}
        style={styles.textArea}
        className="d-block mb-2"
        maxLength="1000"
        placeholder={t('Common_Maximum1000')}
      />
      <Popover
        id="popover-advise"
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left'
        }}
      >
        <Typography color="error" style={styles.popOver}>
          {t('popOverRestrictedCharacters')}
        </Typography>
      </Popover>
      <button
        type="submit"
        disabled={hiddenButton}
        className="btn btn-outline-primary btn-lg btn-amex mr-1 btn-amex-selected"
      >
        {t('commonOk')}
      </button>
    </form>
  );
};

TextAreaQuestion.propTypes = {
  mandatory: PropTypes.bool.isRequired,
  userAnswer: PropTypes.shape({
    value: PropTypes.string
  }),
  onUserAnswer: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired,
  nextGroup: PropTypes.number.isRequired,
  isWide: PropTypes.bool,
  sanitize: PropTypes.bool,
  guideWireField: PropTypes.string
};

TextAreaQuestion.defaultProps = {
  isWide: false,
  userAnswer: {},
  sanitize: true,
  guideWireField: 'textAreaQuestion'
};

export default TextAreaQuestion;
