import React from 'react';
import PropTypes from 'prop-types';

const Image = ({ url, defaultUrl }) => {
  const imageUrl = url || defaultUrl;
  return imageUrl && <img src={imageUrl} alt="" className="cc-image" />;
};

Image.propTypes = {
  url: PropTypes.string,
  defaultUrl: PropTypes.string
};

Image.defaultProps = {
  url: null,
  defaultUrl: null
};

export default Image;
