import { PRISMIC_CONTENT_TYPES } from 'constants/constants';
import prismicApi from 'api/prismicApi';
import { getContext, buildMarketProductTag } from 'utils/PrismicHelper';
import * as types from 'actions/actionTypes';
import { ajaxCallError, beginAjaxCall } from 'actions/ajaxStatusActions';
import { handlePrismicError } from 'actions/alertsActions';
import { getLanguageIsoCode } from 'utils/languageUtils';

export function prismicCallSuccess() {
  return { type: types.PRISMIC_CALL_SUCCESS };
}

export function handlePrismicCallError(error) {
  return dispatch => {
    dispatch(handlePrismicError(error));
    dispatch(ajaxCallError());
  };
}

export function eventsCallSuccess(prismicEvents = []) {
  return { type: types.EVENTS_CALL_SUCCESS, prismicEvents };
}

function executePrismicCall(dispatch, tags, type, language) {
  return new Promise(resolve => {
    dispatch(beginAjaxCall());
    getContext().then(api => {
      prismicApi
        .query(api, tags, type, language)
        .then(data => {
          dispatch(prismicCallSuccess());
          resolve(data);
        })
        .catch(error => {
          dispatch(handlePrismicError(error));
          dispatch(ajaxCallError());
        });
    });
  });
}

export function getCardRealName(cardId) {
  return (dispatch, getState) =>
    executePrismicCall(
      dispatch,
      [cardId],
      [PRISMIC_CONTENT_TYPES.CARD_NAME],
      getLanguageIsoCode(getState().marketLanguages.selectedLanguage)
    );
}

export function getBenefitListAction(marketCode, productCode) {
  return (dispatch, getState) =>
    executePrismicCall(
      dispatch,
      [buildMarketProductTag(marketCode, productCode)],
      [PRISMIC_CONTENT_TYPES.BENEFIT_LIST],
      getLanguageIsoCode(getState().marketLanguages.selectedLanguage)
    );
}

export function getCoverSummaryAction(marketCode, productCode) {
  return (dispatch, getState) =>
    executePrismicCall(
      dispatch,
      [buildMarketProductTag(marketCode, productCode)],
      [PRISMIC_CONTENT_TYPES.COVER_SUMMARY],
      getLanguageIsoCode(getState().marketLanguages.selectedLanguage)
    );
}

export function getFaqPageDataAction(marketCode, productCode, idFaq) {
  return (dispatch, getState) => {
    const faqTag = `${PRISMIC_CONTENT_TYPES.FAQ_PREFIX}${idFaq}`;
    return executePrismicCall(
      dispatch,
      [buildMarketProductTag(marketCode, productCode), faqTag],
      [PRISMIC_CONTENT_TYPES.SINGLE_FAQ],
      getLanguageIsoCode(getState().marketLanguages.selectedLanguage)
    );
  };
}

export function getProductPdfDocumentAction(marketCode, productCode) {
  return (dispatch, getState) =>
    executePrismicCall(
      dispatch,
      [buildMarketProductTag(marketCode, productCode)],
      [PRISMIC_CONTENT_TYPES.COVER_FULL_POLICY_LINK],
      getLanguageIsoCode(getState().marketLanguages.selectedLanguage)
    );
}

/**
 * Request prismic API to get the live events from every market.
 * By language and market.
 * @param {string} marketCode
 * @param {string} language
 */
export function getEventsAction(marketCode, language = 'en-GB') {
  return dispatch =>
    executePrismicCall(
      dispatch,
      [marketCode.replace(/..$/g, placeCode => placeCode.toUpperCase())],
      [PRISMIC_CONTENT_TYPES.LIVE_EVENT],
      language
    ).then(eventApiCallResult => {
      const resultIsOk = eventApiCallResult && eventApiCallResult.results;
      const hasEvent =
        resultIsOk &&
        eventApiCallResult.results.length &&
        eventApiCallResult.results[0].data &&
        eventApiCallResult.results[0].data.event;

      const okResponseHasResultAndEvent =
        hasEvent && eventApiCallResult.results[0].data.event.length;

      const okResponseHasNoResult =
        resultIsOk && eventApiCallResult.results.length === 0;

      const okResponseHasResultHasNotEvents =
        hasEvent && eventApiCallResult.results[0].data.event.length === 0;

      if (okResponseHasResultAndEvent) {
        dispatch(eventsCallSuccess(eventApiCallResult.results[0].data.event));
      } else if (okResponseHasNoResult || okResponseHasResultHasNotEvents) {
        dispatch(eventsCallSuccess());
      }
    });
}

export function removeEventFromPrismicEvents(index) {
  return dispatch => dispatch({ type: types.REMOVE_PRISMIC_EVENT, index });
}
export function setReadPrismicEvent(index) {
  return dispatch => dispatch({ type: types.SET_READ_PRISMIC_EVENT, index });
}
export function setAllReadPrismicEvent() {
  return dispatch => dispatch({ type: types.SET_ALL_PRISMIC_EVENTS_TO_READ });
}
export function setAllUnreadPrismicEvent() {
  return dispatch => dispatch({ type: types.SET_ALL_PRISMIC_EVENTS_TO_UNREAD });
}
