import React from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';

import Footer from 'shared/components/Footer';
import TopHeader from 'shared/containers/TopHeader';

import Alerts from 'containers/Alerts';

import Header from 'components/LandingPage/components/header';
import SaveLayoutCard from 'components/Layout/SaveLayoutCard';
import UserDetails from 'components/Layout/UserDetails';
import WizardSteps from 'components/WizardSteps';
import { MAX_STEP } from 'constants/claim';
import StickySaveButton from 'components/Layout/StickySaveButton';
import useWidthDevice from 'shared/customHooks/use-device-width';

/**
 * @param {{
 *  sidebarIsVisible: boolean,
 *  onClaimDecline?: () => {}
 * }} props
 * @param {'onClaimDecline'} propName
 * @param {'LayoutPage'} componentName
 */
export function onClaimDeclineValidation(
  props,
  propName = 'onClaimDecline',
  componentName = 'LayoutPage'
) {
  let returnValue;
  const requiredIfPropertyIsTrue = 'sidebarIsVisible';
  if (
    props[requiredIfPropertyIsTrue] === true &&
    (props[propName] === undefined || typeof props[propName] !== 'function')
  ) {
    returnValue = new Error(
      `[${componentName}] - Please provide an ${propName} function. It is required if ${requiredIfPropertyIsTrue} is true.`
    );
  }
  return returnValue;
}

const LayoutPage = props => {
  const { t } = useTranslation();
  const { isMobileDevice } = useWidthDevice();
  const Title = props.Title && <h1>{props.Title}</h1>;
  const SubTitle = props.SubTitle && <h3>{props.SubTitle}</h3>;
  let leftCardClass = props.hasBackground ? ' p-4 left-card' : '';
  const calendarCardClass = props.isCalander ? 'p-0 left-card' : '';

  if (props.hasBgColor) {
    leftCardClass = `${leftCardClass} hasBgColor`;
  }

  const getSaverSubmitLabels = () => {
    if (props.currentStep.step === MAX_STEP) {
      return {
        claimSaverSubmit: t('claimSaverSubmitReview'),
        claimSaverSubmitCaption: t('claimSaverSubmitCaptionReview'),
        claimSaverSubmitReminder: t('claimSaverSubmitReminderReview'),
        claimSaverSubmitTooltip: t('claimSaverSubmitTooltipReview')
      };
    }
    return {
      claimSaverSubmit: t('claimSaverSubmit'),
      claimSaverSubmitCaption: t('claimSaverSubmitCaption'),
      claimSaverSubmitReminder: t('claimSaverSubmitReminder'),
      claimSaverSubmitTooltip: t('claimSaverSubmitTooltip')
    };
  };

  const saveLabels = {
    ...getSaverSubmitLabels(),
    claimSaverSave: t('claimSaverSave'),
    claimSaverDontWannaClaim: t('claimSaverDontWannaClaim'),
    claimSaverAXAClaim: t('claimSaverAXAClaim'),
    claimSaverContant: t('claimSaverContant'),
    claimSaverOpenDays: t('claimSaverOpenDays'),
    claimSaverContantPhone: t('claimSaverContantPhone'),
    ableToEditOnReview: t('ableToEditOnReview')
  };
  const sideBar = (
    <SaveLayoutCard
      onSave={props.onClaimSave}
      saveDisabled={
        props.lastStep === MAX_STEP && props.currentStep.step !== MAX_STEP
      }
      currentStep={props.currentStep.step}
      submitDisabled={
        !(
          props.currentStep.step === MAX_STEP &&
          props.currentStep.lastStep === MAX_STEP
        )
      }
      onCancel={props.onClaimDecline}
      claimId={props.claimId}
      labels={saveLabels}
    />
  );

  const userDetails = props.userDetailsIsVisible && (
    <UserDetails
      onInfoSubmit={props.onUserInfoChange}
      userDetails={props.userDetails}
    />
  );

  const wizardLabels = [
    t('stepHappenTitle'),
    t('stepDatesTitle'),
    t('stepExpensesTitle'),
    t('stepPreviousInsuranceTitle'),
    t('stepBankTitle'),
    t('stepReviewTitle')
  ];
  const Steps = props.stepsIsVisible && (
    <WizardSteps
      currentStep={props.currentStep.lastStep}
      lastStep={props.lastStep}
      labels={wizardLabels}
    />
  );

  const AmexHeader = props.topHeaderIsVisible && <TopHeader />;

  const containerWidth = props.fullWidth ? '' : 'col-md-8';

  return (
    <div className="container-fluid">
      <div className="row no-gutters">
        <div className="col">{AmexHeader}</div>
      </div>
      {props.headerFooterVisibles && <Header />}
      <div className="row no-gutters">
        <div className="col">{Steps}</div>
      </div>
      {isMobileDevice && props.currentStep.step === MAX_STEP && (
        <div id="sticky-container">
          <StickySaveButton
            submitDisabled={
              !(
                props.currentStep.step === MAX_STEP &&
                props.currentStep.lastStep === MAX_STEP
              )
            }
          />
        </div>
      )}

      <div className="row no-gutters">
        <div className="col">
          <div className="container layout">
            <div className="row no-gutters">
              <div className="col-8 layout-alerts">
                <Alerts />
              </div>
            </div>
            <div className="row no-gutters">
              <div className="col-12 layout-title mb-3">
                {Title}
                {SubTitle}
              </div>
            </div>
            <div className="row">
              <div className={`${containerWidth} col-sm-12 mb-3`}>
                <div className="row no-gutters">
                  <div className={`col${leftCardClass} ${calendarCardClass} `}>
                    {props.children}
                  </div>
                </div>
                <div className="row ">
                  <div className="col" id="questionar-bottom" />
                </div>
              </div>
              {props.sidebarIsVisible && (
                <div className="sideBarContainer col-md-4 col-sm-12">
                  {sideBar}
                  {userDetails}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {props.headerFooterVisibles && <Footer />}
    </div>
  );
};

LayoutPage.propTypes = {
  children: PropTypes.element.isRequired,
  Title: PropTypes.string,
  SubTitle: PropTypes.string,
  topHeaderIsVisible: PropTypes.bool,
  stepsIsVisible: PropTypes.bool,
  hasBackground: PropTypes.bool,
  onClaimSave: PropTypes.func,
  onClaimDecline: onClaimDeclineValidation,
  onUserInfoChange: PropTypes.func,
  sidebarIsVisible: PropTypes.bool,
  userDetailsIsVisible: PropTypes.bool,
  isCalander: PropTypes.bool,
  headerFooterVisibles: PropTypes.bool,
  fullWidth: PropTypes.bool,
  hasBgColor: PropTypes.bool,
  claimId: PropTypes.string,
  userDetails: PropTypes.shape({}),
  currentStep: PropTypes.shape({
    step: PropTypes.number,
    lastStep: PropTypes.number
  }),
  lastStep: PropTypes.number,
  actions: PropTypes.shape({
    gotoContactsPage: PropTypes.func.isRequired,
    gotoFAQPage: PropTypes.func.isRequired,
    gotoCookiesPage: PropTypes.func.isRequired
  }).isRequired
};

LayoutPage.defaultProps = {
  isCalander: false,
  Title: '',
  SubTitle: '',
  topHeaderIsVisible: true,
  headerFooterVisibles: true,
  stepsIsVisible: false,
  userDetailsIsVisible: false,
  fullWidth: false,
  onClaimSave: () => {},
  onUserInfoChange: () => {},
  onClaimDecline: undefined,
  sidebarIsVisible: false,
  claimId: '0',
  userDetails: {},
  currentStep: {
    step: 0,
    lastStep: 0
  },
  lastStep: 1,
  hasBgColor: false,
  hasBackground: false
};

export default LayoutPage;
