import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classNames from '../classNames';
import InfoIcon from '../InfoIcon';
import UploadInfoText from '../UploadInfoText';

export const learnMoreClasses = {
  col2: 'col-2',
  col10: 'col-10',
  colSm1: 'col-sm-1',
  colSm11: 'col-sm-11',
  dBlock: 'd-block',
  cursorPointer: 'cursor-pointer',
  mb2: 'mb-2',
  mbMd0: 'mb-md-0',
  ml4: 'ml-4',
  mlMd2: 'ml-md-2',
  mr2: 'mr-2',
  mt0: 'mt-0',
  mtMd1: 'mt-md-1',
  mtMd2: 'mt-md-2'
};

export const fileUploadLearnMoreLabelCode = 'fileUploadLearnMore';

const wrapperClassNames = `${classNames.inlineBlock} ${learnMoreClasses.ml4} ${learnMoreClasses.mlMd2} ${learnMoreClasses.mt0} ${learnMoreClasses.mtMd1} ${learnMoreClasses.mb2} ${learnMoreClasses.mbMd0} ${learnMoreClasses.cursorPointer}`;
const spanBlockClassNames = `${learnMoreClasses.dBlock} ${learnMoreClasses.mt0} ${learnMoreClasses.mtMd2}`;
const iconWrapperClassName = `${learnMoreClasses.col2} ${learnMoreClasses.colSm1}`;
const textWrapperClassName = `${learnMoreClasses.col10} ${learnMoreClasses.colSm11}`;

const LearnMore = ({ handleShowCards }) => {
  const { t } = useTranslation();
  return (
    <div
      className={wrapperClassNames}
      onClick={handleShowCards}
      onKeyDown={handleShowCards}
    >
      <span className={spanBlockClassNames}>
        <div className={classNames.row}>
          <div className={iconWrapperClassName}>
         <InfoIcon />
          </div>
          <div className={textWrapperClassName}>
            <UploadInfoText text={t(fileUploadLearnMoreLabelCode)} />
          </div>
        </div>
          </span>
          <br/>
      </div>      
  );
};

LearnMore.propTypes = {
  handleShowCards: PropTypes.func.isRequired
};

export default LearnMore;
