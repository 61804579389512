import { getMarketFromUrl, inBodiam } from './urlHelpers';

function getBaseHeader() {
  const marketFromUrl = getMarketFromUrl();
  const requestedFrom = inBodiam() ? 'BODIAM' : 'ARUNDEL';
  return {
    'X-Market': marketFromUrl,
    'X-Requested-From': requestedFrom
  };
}

export function createHeader(customValues) {
  const baseHeader = getBaseHeader();
  return {
    ...baseHeader,
    ...customValues
  };
}

export { createHeader as default };
