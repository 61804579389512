import React from 'react';
import PropTypes from 'prop-types';

const Number = ({ value, className }) => (
  <h2 className={`mt-3 ${className}`}>{value}</h2>
);

Number.propTypes = {
  value: PropTypes.string.isRequired,
  className: PropTypes.string
};

Number.defaultProps = {
  className: ''
};

export default Number;
