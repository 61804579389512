import {
  SAVE_CLAIM_AND_CONTINUE_REQUESTED,
  SAVE_CLAIM_AND_CONTINUE_SUCCEEDED,
  SAVE_CLAIM_AND_CONTINUE_FAILED,
  saveClaimEvent
} from 'actions/feature/claim';

import { setError } from 'actions/core/error';
import { updateActivePage } from 'actions/activePageActions';
import { hideSpinner, notifyWarning } from 'actions/core/ui';

import {
  updateClaimStep,
  updateClaimData,
  updateCurrentStep
} from 'actions/claimActions';
import { EXPENSES_STEP, REVIEW_STEP } from 'constants/claim';
import { findCurrentStep } from 'containers/ClaimWrapper/ClaimWrapper';
import { getNextStep, getActivePage } from 'utils/pageHelper';

export const saveClaimAndContinueFlow = ({
  dispatch,
  getState
}) => next => action => {
  next(action);

  if (action.type === SAVE_CLAIM_AND_CONTINUE_REQUESTED) {
    const { currentStep } = getState();
    const nextStep = getNextStep(currentStep.lastStep);
    dispatch(updateClaimStep(nextStep));

    dispatch(
      saveClaimEvent(
        false,
        {
          currentStep,
          nextStep
        },
        SAVE_CLAIM_AND_CONTINUE_SUCCEEDED,
        SAVE_CLAIM_AND_CONTINUE_FAILED
      )
    );
  }
};

export const saveClaimAndContinueSuccess = ({ dispatch }) => next => action => {
  next(action);

  if (action.type === SAVE_CLAIM_AND_CONTINUE_SUCCEEDED) {
      const { currentStep, nextStep } = action.meta;
      if (currentStep.step === EXPENSES_STEP || nextStep === REVIEW_STEP )
      dispatch(updateClaimData(action.payload)); // To save amount calculated on BE
    const step = findCurrentStep(currentStep, nextStep);
    const activePage = getActivePage(step.lastStep);
    dispatch(updateCurrentStep(step));
    dispatch(updateActivePage(activePage));
    dispatch(hideSpinner());
    dispatch(notifyWarning('alertSuccessSave'));
  }
};

export const saveClaimAndContinueFail = ({ dispatch }) => next => action => {
  next(action);

  if (action.type === SAVE_CLAIM_AND_CONTINUE_FAILED) {
    dispatch(updateClaimStep(action.meta.currentStep));
    dispatch(hideSpinner());
    dispatch(setError(action.payload));
  }
};

export default [
  saveClaimAndContinueFlow,
  saveClaimAndContinueSuccess,
  saveClaimAndContinueFail
];
