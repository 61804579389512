// import axios from 'axios';
import {
  endPointCallPost,
  endPointCallPut,
  endPointCallGet
} from './endPointCallerApi';
import * as apiRoutes from './apiRoutes';

class claimsApi {
  static createClaim(claim, language) {
    const url = `${apiRoutes.CLAIMS}/${language}`;
    return endPointCallPost(url, claim);
  }

  static declineClaim(claimId, reasonId, cancelType = null) {
    const url = `${apiRoutes.CLAIMS}/${claimId}/${apiRoutes.CLAIMS_DECLINE}/${reasonId}/${cancelType}`;
    return endPointCallPut(url, '');
  }

  static getClaims() {
    const url = `${apiRoutes.CLAIMS}/`;
    return endPointCallGet(url);
  }

  static getClaim(id, language) {
    const url = `${apiRoutes.CLAIMS}/${id}/${language}`;
    return endPointCallGet(url);
  }

  static submitClaim(id, claim, language) {
    const url = `${apiRoutes.CLAIMS}/${id}/${apiRoutes.CLAIMS_SUBMIT}/${language}`;
    return endPointCallPut(url, claim);
  }

  static getClaimDocuments(claimId) {
    const url = `${apiRoutes.CLAIM_DOCUMENTS}/${claimId}`;
    return endPointCallGet(url);
  }
}
export default claimsApi;
