import {
  SET_CLAIM_DOCUMENTS_LIST,
  CLAIM_SELECT_FOR_DOCUMENTS_LIST
} from 'actions/feature/claim';
import initialState from './initialState';

export default function claimDocumentsList(
  state = initialState.claimDocumentsList,
  action
) {
  switch (action.type) {
    case SET_CLAIM_DOCUMENTS_LIST:
      return {
        ...state,
        documentList: action.payload
      };
    case CLAIM_SELECT_FOR_DOCUMENTS_LIST:
      return {
        ...state,
        claim: action.payload
      };
    default:
      return state;
  }
}
