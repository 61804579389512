import React from 'react';
import PropTypes from 'prop-types';

import { RichText } from 'prismic-reactjs';
import { LinkResolver } from 'utils/PrismicHelper';
import { CAROUSEL_CLASSES } from 'constants/constants';

import CarouselComponent from 'components/shared/CarouselComponent';

const BenefitCarousel = props => {
  const convertItemToSlide = item => ({
    title:
      item.benefit_title && item.benefit_title[0] && item.benefit_title[0].text,
    imageURL: item.icon.url
  });

  const { benefits, onChangeSelected, selectedBenefit } = props;
  const benefit = benefits[selectedBenefit];
  return (
    <div className="benefit-carousel text-center">
      {benefits && benefit && benefit.title && benefit.title.length
        ? RichText.render(benefit.title, LinkResolver)
        : 'Title Missing'}
      <CarouselComponent
        slides={benefits.map(convertItemToSlide)}
        onSlideChange={onChangeSelected}
        currentSlide={selectedBenefit}
        extraClassName={CAROUSEL_CLASSES.BENEFITS}
      />
      {benefit && benefit.description && (
        <p className="benefit-specific description">
          {RichText.render(benefit.description, LinkResolver)}
        </p>
      )}
    </div>
  );
};

BenefitCarousel.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  benefits: PropTypes.array.isRequired,
  selectedBenefit: PropTypes.number.isRequired,
  onChangeSelected: PropTypes.func.isRequired,
  labels: PropTypes.shape({}).isRequired
};

export default BenefitCarousel;
