import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';

import { makeRecaptchaVisible } from 'utils/recaptcha/core';

import { validateAndGetBenefits } from 'actions/feature/card';
import CustomModal from 'shared/components/CustomModal';
import { Row, Col } from 'components/shared/Table';
import ButtonBack from 'components/shared/Custom/ButtonBack';
import { LANDING_PAGE } from 'constants/constants';
import { useTranslation } from 'react-i18next';
import { goTo } from 'actions/core/app.navigation';
import CheckCoverBody from './CheckCoverBody';
import CheckCoverHeader from './CheckCoverHeader';
import './_CheckCoverPage.scss';


export const CheckCoverPage = props => {
  const [showNotFound, setShowNotFound] = useState(false);
  const [showHelpDesc, setShowHelpDesc] = useState(false);
  const [cardNumberField, setCardNumberField] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const { t } = useTranslation();
  React.useEffect(() => {
    makeRecaptchaVisible(true);
    return () => {
      makeRecaptchaVisible(false);
    };
  }, []);

  React.useEffect(() => {
    if (props.validation && !props.validation.isValid) {
      if (props.validation.code === 404) {
        setShowNotFound(true);
      } else {
        setErrorMessage(props.validation.message);
      }
    } else {
      setErrorMessage('');
    }
  }, [props.validation]);

  const openNotRecognisedCard = () => {
    setErrorMessage('cardNumberNotRecognised');
    setShowNotFound(false);
  };
  
  const openPhoneAXAModal = () => {
    setErrorMessage('cardPhoneAXA');
    setShowNotFound(false);
  };

  const handleQuestionChange = value => setShowHelpDesc(value);

  const handleUserInput = e => {
    e.preventDefault();
    validateInput(e.target.value);
  };

  const handleSubmit = () =>
    props.actions.validateAndGetBenefits(cardNumberField, true);

  const validateInput = value => {
    const digitsOnly = value.match(/\d+/g);
    const newInput = digitsOnly ? digitsOnly[0] : '';
    if (newInput !== cardNumberField) {
      setCardNumberField(newInput);
    }
  };

  const onBackClick = () => {
    props.actions.goTo(LANDING_PAGE);
  };

  return (
    <>
      <div className="div-container">
        <Row>
          <ButtonBack
            onClick={onBackClick}
            labelText={t('buttonBackCheckCover')}
          />
        </Row>
        <Row className="h-100 card-number-modal">
          <Col>
          <Row className="">
              <Col>
               <CheckCoverHeader
                  handleQuestionChange={handleQuestionChange}
                  showHelpDescription={showHelpDesc}
                />
                
                <CheckCoverBody
                  cardNumberField={cardNumberField}
                  handleSubmit={handleSubmit}
                  handleUserInput={handleUserInput}
                  modalErrorMessage={errorMessage}
                  benefits={props.benefits}
                  goTo={props.actions.goTo}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <CustomModal
        isOpen={showNotFound}
        contentLabel="checkCoverModal"
        shouldCloseOnOverlayClick={false}
        overlayClassName="overlay"
        className="modal-frame"
        handleConfirmationOk={openPhoneAXAModal}
        handleConfirmationCancel={openNotRecognisedCard}
        description="cardIsCurrencyCorporate"
        labelOk="commonYes"
        labelCancel="commonNo"
      />
    </>
  );
};
const mapStateToProps = state => ({
  benefits: state.card.benefits,
  validation: state.card.validation
});

const mapDispatchToProps = dispatch => ({
  actions: {
    ...bindActionCreators({ validateAndGetBenefits, goTo }, dispatch)
  }
});

CheckCoverPage.propTypes = {
  benefits: PropTypes.arrayOf(PropTypes.string),
  validation: PropTypes.shape({
    isValid: PropTypes.bool,
    code: PropTypes.number,
    message: PropTypes.string
  }),
  actions: PropTypes.shape({
    validateAndGetBenefits: PropTypes.func.isRequired,
    goTo: PropTypes.func.isRequired
  }).isRequired
};

CheckCoverPage.defaultProps = {
  benefits: [],
  validation: {}
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckCoverPage);
