import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { DEFAULT_CARD_IMAGE } from 'constants/constants';
import { obfuscateCard } from 'utils/CardHelper';
import BenefitList from 'components/BenefitListComponent/BenefitList';

import { Row, Col } from 'components/shared/Table';
import { Button } from 'components/shared/Forms';
import Product from 'components/shared/Product';
import ProductItemDeleteDialogComponent from './ProductItemDeleteDialogComponent';

const ProductItemProfileDetailComponent = props => {
  const { t } = useTranslation();
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  const handleNewClaim = () => {
    props.setActiveProductItem(props.productDetail.summary.number);
    props.gotoTriageWrapper();
  };

  const productNumber =
    props.productDetail.summary && props.isCard
      ? obfuscateCard(props.productDetail.summary.obfuscated)
      : props.productDetail.summary.number;

  const newClaimLabel = props.isCard
    ? t('startNewClaimWithThisCard')
    : t('startNewClaimWithThisPolicy');

  const oneItemOrLess = props.list.length <= 1;

  return (
    <>
      <Row className="card-detail">
        <Col>
          <Row>
            <Col className="offset-md-1 col-11 col-md-4">
              <Row>
                <Col className="text-center mb-2">
                  {props.productDetail.summary && (
                    <Product.Image
                      defaultUrl={props.isCard ? DEFAULT_CARD_IMAGE : null}
                      url={props.productDetail.summary.imageURL}
                    />
                  )}
                </Col>
              </Row>
              <Row>
                <Col className="text-center">
                  <Product.Number value={productNumber} />
                </Col>
              </Row>
            </Col>
            <Col className="offset-md-2 col-12 col-md-4">
              <Product.Title value={props.productDetail.summary?.product} />
              {props.isCard && (
                <Product.Type value={props.productDetail.summary?.typeOfCard} />
              )}
            </Col>
          </Row>
          <Row>
            <Col>
              {props.productDetail && (
                <BenefitList benefits={props.productDetail.benefitsSummary} />
              )}
            </Col>
          </Row>
          <Row>
            <Col className="text-center">
              <Button
                type="button"
                className="btn btn-primary btn-large btn-amex mt-3"
                onClick={handleNewClaim}
              >
                {newClaimLabel}
              </Button>
            </Col>
          </Row>
          <Row>
            <Col className="text-center mt-4">
              {props.isCard
                ? t('commonDeletCardWarning')
                : t('commonDeletePolicyWarning')}
              <br />
              <Button
                type="button"
                className="btn btn-danger btn-link mt-3 mt-md-1 btn-remove-card"
                onClick={() => setShowDeleteDialog(true)}
                disabled={oneItemOrLess}
              >
                {props.isCard ? t('commonDeleteCard') : t('commonDeletePolicy')}
              </Button>
            </Col>
            {showDeleteDialog && (
              <ProductItemDeleteDialogComponent
                opened={showDeleteDialog}
                toggleProductItemProfile={props.toggleProductItemProfile}
                deleteItem={
                  props.isCard ? props.deleteCard : props.deletePolicy
                }
                productDetail={props.productDetail}
                handleCloseDialog={() => setShowDeleteDialog(false)}
                warningLabel={
                  props.isCard
                    ? t('commonDeletCardWarning')
                    : t('commonDeletePolicyWarning')
                }
                productNumber={productNumber}
                deletedLabel={
                  props.isCard
                    ? t('commonAlertCardDeleted')
                    : t('commonAlertPolicyDeleted')
                }
              />
            )}
          </Row>
        </Col>
      </Row>
    </>
  );
};

ProductItemProfileDetailComponent.propTypes = {
  deleteCard: PropTypes.func,
  deletePolicy: PropTypes.func,
  gotoTriageWrapper: PropTypes.func.isRequired,
  productDetail: PropTypes.shape({
    benefitsSummary: PropTypes.arrayOf(
      PropTypes.shape({
        benefitLabelCode: PropTypes.string,
        benefitDescriptionLabel: PropTypes.string
      })
    ),
    summary: PropTypes.shape({
      id: PropTypes.number.isRequired,
      imageURL: PropTypes.string,
      typeOfCard: PropTypes.number,
      product: PropTypes.string.isRequired,
      number: PropTypes.string.isRequired,
      obfuscated: PropTypes.string
    })
  }).isRequired,
  list: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setActiveProductItem: PropTypes.func.isRequired,
  toggleProductItemProfile: PropTypes.func.isRequired,
  isCard: PropTypes.bool
};

ProductItemProfileDetailComponent.defaultProps = {
  deleteCard: () => {},
  deletePolicy: () => {},
  isCard: true
};

export default ProductItemProfileDetailComponent;
