import React from 'react';
import { Route } from 'react-router';
import PropTypes from 'prop-types';

const ExternalRedirect = ({ path, exact, href }) => {
  return (
    <Route
      path={path}
      exact={exact}
      render={() => {
        window.location.assign(href);
        return <div id="redirect" />;
      }}
    />
  );
};

ExternalRedirect.propTypes = {
  path: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  exact: PropTypes.bool
};

ExternalRedirect.defaultProps = {
  exact: false
};

export default ExternalRedirect;
