import {
  VALIDATE_DETAILS_POLICY_REQUESTED,
  DETAILS_POLICY_REQUESTED,
  DETAILS_POLICY_SUCCEEDED,
  DETAILS_POLICY_FAILED,
  setPolicyDetails,
  validatePolicy
} from 'actions/feature/policy';
import * as apiRoutes from 'api/apiRoutes';

import { apiRequest } from 'actions/core/api';
import { setError } from 'actions/core/error';
import { hideSpinner, showSpinner } from 'actions/core/ui';

export const validateAndGetPolicyDetailsFlow = ({
  dispatch
}) => next => action => {
  next(action);

  if (action.type === VALIDATE_DETAILS_POLICY_REQUESTED) {
    dispatch(
      validatePolicy(
        action.payload.number,
        action.payload.name,
        action.payload.surname,
        action.payload.date,
        DETAILS_POLICY_REQUESTED,
        DETAILS_POLICY_FAILED
      )
    );
  }
};

export const getPolicyDetailsFlow = ({ dispatch }) => next => action => {
  next(action);

  if (action.type === DETAILS_POLICY_REQUESTED) {
    dispatch(showSpinner());
    dispatch(
      apiRequest(
        'GET',
        `${apiRoutes.GET_POLICIES}/${action.payload.productId}/details`,
        {},
        {},
        {},
        DETAILS_POLICY_SUCCEEDED,
        DETAILS_POLICY_FAILED
      )
    );
  }
};

export const getPolicyDetailsSuccess = ({ dispatch }) => next => action => {
  next(action);

  if (action.type === DETAILS_POLICY_SUCCEEDED) {
    dispatch(setPolicyDetails(action.payload));
    dispatch(hideSpinner());
  }
};

export const getPolicyDetailsFail = ({ dispatch }) => next => action => {
  next(action);

  if (action.type === DETAILS_POLICY_FAILED) {
    dispatch(hideSpinner());
    dispatch(setError(action.payload));
  }
};

export default [
  validateAndGetPolicyDetailsFlow,
  getPolicyDetailsFlow,
  getPolicyDetailsSuccess,
  getPolicyDetailsFail
];
