import {
  SIGNIN_USER_AND_CREATE_CLAIM_REQUESTED,
  SIGNIN_USER_AND_CREATE_CLAIM_SUCCEEDED,
  SIGNIN_USER_AND_CREATE_CLAIM_FAILED,
  SIGNIN_USER_REQUESTED,
  SIGNIN_USER_SUCCEEDED,
  SIGNIN_USER_FAILED,
  SIGNIN_USER_ADD_NEW_CARD_REQUESTED,
  SIGNIN_USER_ADD_NEW_CARD_SUCCEEDED,
  SIGNIN_USER_ADD_NEW_CARD_FAILED
} from 'actions/feature/user';
import { createClaim } from 'actions/feature/claim';
import * as types from 'actions/actionTypes';

import * as apiRoutes from 'api/apiRoutes';

import { apiRequest } from 'actions/core/api';
import { hideSpinner, showSpinner } from 'actions/core/ui';
import { setAllUnreadPrismicEvent } from 'actions/prismicActions';
import { setError } from 'actions/core/error';
import { goTo } from 'actions/core/app.navigation';
import { saveToken } from 'utils/sessionController';
import { addGencodeToUserEvent } from 'actions/feature/card';
import { addPolicyToUserEvent } from 'actions/feature/policy';
import { isEmpty } from 'lodash';
import { MY_CLAIMS_PAGE, RECOVER_PASSWORD } from 'constants/constants';

const getCardFromStore = getState => {
  const { card } = getState();
  if (
    card.gencodeCard &&
    card.gencodeCard.value &&
    card.gencodeCard.obfuscated
  ) {
    return card;
  }

  return undefined;
};

const getPolicyFromStore = getState => {
  const { policy } = getState();
  if (policy.number && policy.name && policy.lastName && policy.date) {
    return policy;
  }

  return undefined;
};

export const signInUserAndCreateClaimFlow = ({
  dispatch,
  getState
}) => next => action => {
  next(action);

  if (action.type === SIGNIN_USER_AND_CREATE_CLAIM_REQUESTED) {
    dispatch(showSpinner());
    const onSuccess =
      getCardFromStore(getState) || getPolicyFromStore(getState)
        ? SIGNIN_USER_ADD_NEW_CARD_REQUESTED
        : SIGNIN_USER_AND_CREATE_CLAIM_SUCCEEDED;

    dispatch(
      apiRequest(
        'POST',
        apiRoutes.SIGN_IN,
        {},
        action.payload,
        { redirectTo: action.meta.redirectTo },
        onSuccess,
        SIGNIN_USER_FAILED
      )
    );
  }
};

export const signInUserAndAddNewCardRequested = ({
  dispatch,
  getState
}) => next => action => {
  next(action);

  if (action.type === SIGNIN_USER_ADD_NEW_CARD_REQUESTED) {
    saveToken(action.payload);

    const card = getCardFromStore(getState);
    const policy = getPolicyFromStore(getState);

    if (!isEmpty(card)) {
      dispatch(
        addGencodeToUserEvent(
          card.gencodeCard.value,
          card.gencodeCard.obfuscated,
          SIGNIN_USER_ADD_NEW_CARD_SUCCEEDED,
          SIGNIN_USER_ADD_NEW_CARD_FAILED,
          { redirectTo: action.meta.redirectTo }
        )
      );
    } else if (!isEmpty(policy)) {
      dispatch(
        addPolicyToUserEvent(
          policy.number,
          policy.name,
          policy.lastName,
          policy.date,
          SIGNIN_USER_ADD_NEW_CARD_SUCCEEDED,
          SIGNIN_USER_ADD_NEW_CARD_FAILED,
          action.meta.redirectTo
        )
      );
    }
  }
};

export const signInUserAndCreateClaimRequested = ({
  dispatch,
  getState
}) => next => action => {
  next(action);

  if (
    [
      SIGNIN_USER_AND_CREATE_CLAIM_SUCCEEDED,
      SIGNIN_USER_ADD_NEW_CARD_SUCCEEDED
    ].includes(action.type)
  ) {
    dispatch(createClaim(getState().currentClaim, action.meta.redirectTo));
    dispatch(hideSpinner());
  }
};

export const signInAndClaimCreationFail = ({ dispatch }) => next => action => {
  next(action);

  if (
    [
      SIGNIN_USER_AND_CREATE_CLAIM_FAILED,
      SIGNIN_USER_ADD_NEW_CARD_FAILED
    ].includes(action.type)
  ) {
    dispatch(hideSpinner());
    dispatch(goTo(MY_CLAIMS_PAGE));
    dispatch(setError(action.payload));
  }
};

export const signInUserFlow = ({ dispatch }) => next => action => {
  next(action);

  if (action.type === SIGNIN_USER_REQUESTED) {
    dispatch(showSpinner());
    dispatch(
      apiRequest(
        'POST',
        apiRoutes.SIGN_IN,
        {},
        action.payload,
        { redirectTo: action.meta.redirectTo },
        SIGNIN_USER_SUCCEEDED,
        SIGNIN_USER_FAILED
      )
    );
  }
};

export const signInUserSuccess = ({ dispatch }) => next => action => {
  next(action);

  if (action.type === SIGNIN_USER_SUCCEEDED) {
    saveToken(action.payload);
    if (action.meta.redirectTo) dispatch(goTo(action.meta.redirectTo));
    dispatch(hideSpinner());
    dispatch(setAllUnreadPrismicEvent());
  }
};

export const signInUserFail = ({ dispatch }) => next => action => {
  next(action);

  if (action.type === SIGNIN_USER_FAILED) {
    dispatch(hideSpinner());
    dispatch(setError(action.payload));
    if (action.payload.data === 'exceptionAccountExpiredPassword') {
      dispatch({ type: types.UPDATE_ACTIVE_PAGE, page: RECOVER_PASSWORD });
    }
  }
};

export default [
  signInUserAndCreateClaimFlow,
  signInUserAndAddNewCardRequested,
  signInUserAndCreateClaimRequested,
  signInAndClaimCreationFail,
  signInUserFlow,
  signInUserSuccess,
  signInUserFail
];
