import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { orderBy, maxBy } from 'lodash';
import Grow from '@material-ui/core/Grow';

import { event } from 'utils/analytics';
import { GA_EVENTS, GA_ACTIONS } from 'constants/googleAnalytics';

import { Button } from 'components/shared/Forms';
import { Row, Col } from 'components/shared/Table';
import AdditionalClaimantBasic from 'components/ContactsQuestion/AdditionalClaimant/AdditionalClaimantBasic';
import AdditionalClaimantSweden from 'components/ContactsQuestion/AdditionalClaimant/AdditionalClaimantSweden';
import AdditionalClaimantFinland from 'components/ContactsQuestion/AdditionalClaimant/AdditionalClaimantFinland';
import AdditionalClaimantNorway from 'components/ContactsQuestion/AdditionalClaimant/AdditionalClaimantNorway';
import AdditionalClaimantLufthansa from 'components/ContactsQuestion/AdditionalClaimant/AdditionalClaimantLufthansa';
import SlideOverride from 'components/ContactsQuestion/Shared/SlideOverride';
import CardOverride from 'components/ContactsQuestion/Shared/CardOverride';
import CardContentOverride from 'components/ContactsQuestion/Shared/CardContentOverride';
import { withConfig } from 'ConfigurationProvider/index';
import { appInsights } from 'ApplicationInsights';

import {
  BLANK_CONTACT,
  ADDITIONAL_RELATIONSHIPS,
  MARKETS
} from 'constants/constants';

export const ExpensesBeneficiaries = props => {
  const { t } = useTranslation();

  const [contacts, setContacts] = useState(
    props.contacts.map(x => {
      return {
        ...x,
        isValid: !!x.isValid || true
      };
    }) || []
  );

  const getNewId = () => {
    const biggestId = maxBy(contacts, 'id');
    if (biggestId) return biggestId.id + 1;
    return 1;
  };

  const removeContact = id => {
    event(GA_EVENTS.EXPENSESADDITIONALCLAIMANTS, GA_ACTIONS.REMOVE);
    appInsights.trackEvent(
      {
        name:'Remove-ClaimantsContact'
      },
      {
        EventCategory: GA_EVENTS.EXPENSESADDITIONALCLAIMANTS,
        EventAction: GA_ACTIONS.REMOVE,
        EventLabel: 'Remove-ClaimantsContact'
      }
      )
	  
    setContacts(contacts.filter(contact => contact.id !== id));
  };

  const isRemovable = contacts && contacts.length > 1;

  const onAddContact = () => {
    event(GA_EVENTS.EXPENSESADDITIONALCLAIMANTS, GA_ACTIONS.CLICK);
    appInsights.trackEvent(
      {
        name:'Add-ClaimantsContact'
      },
      {
        EventCategory: GA_EVENTS.EXPENSESADDITIONALCLAIMANTS,
        EventAction: GA_ACTIONS.CLICK,
        EventLabel: 'Add-ClaimantsContact'
      }
      )
    const blankContact = {
      ...BLANK_CONTACT,
      id: getNewId()
    };
    setContacts([...contacts, blankContact]);
  };

  useEffect(() => {
    if (contacts && contacts.length === 0) onAddContact();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.contacts]);

  const isAdditionalClaimant = contact => !isCdwAdditionalContact(contact);

  const getAdditionalClaimants = conctactList =>
    conctactList.filter(isAdditionalClaimant);

  const getAdditionalClaimantsProps = contact => ({
    onUpdateContact: updateContact,
    onRemoveContact: () => removeContact(contact.id),
    isRemovable,
    contact
  });

  const renderAdditionalClaimant = (contact, market) => {
    switch (market) {
      case MARKETS.AMEX_SE:
        return (
          <AdditionalClaimantSweden {...getAdditionalClaimantsProps(contact)} />
        );
      case MARKETS.AMEX_FI:
        return (
          <AdditionalClaimantFinland
            {...getAdditionalClaimantsProps(contact)}
          />
        );
      case MARKETS.AMEX_NO:
        return (
          <AdditionalClaimantNorway {...getAdditionalClaimantsProps(contact)} />
        );
      case MARKETS.LUFTHANSA_DE:
        return (
          <AdditionalClaimantLufthansa
            availableTitles={props.config.TITLES}
            {...getAdditionalClaimantsProps(contact)}
          />
        );
      default:
        return (
          <AdditionalClaimantBasic
            availableTitles={props.config.TITLES}
            {...getAdditionalClaimantsProps(contact)}
          />
        );
    }
  };

  const getContactsForms = contactsList => {
    return orderBy(contactsList, ['id']).map(contact => (
      <SlideOverride
        key={contact.id}
        direction="up"
        in
        mountOnEnter
        unmountOnExit
      >
        <CardOverride className="mb-3">
          <CardContentOverride>
            {renderAdditionalClaimant(contact, props.market)}
          </CardContentOverride>
        </CardOverride>
      </SlideOverride>
    ));
  };

  const updateContact = contact => {
    setContacts([...contacts.filter(item => item.id !== contact.id), contact]);
  };

  const isCdwAdditionalContact = ({ relationship }) => {
    const {
      CDW_3RD_PARTY_INVOLVED_PERSON,
      CDW_INVOLVED_PERSON
    } = ADDITIONAL_RELATIONSHIPS;
    return (
      relationship === CDW_3RD_PARTY_INVOLVED_PERSON ||
      relationship === CDW_INVOLVED_PERSON
    );
  };

  const handleDisplayPurchases = () => {
    event(GA_EVENTS.EXPENSESADDITIONALCLAIMANTS, GA_ACTIONS.SUBMIT);
    appInsights.trackEvent(
      {
        name:'DisplayPurchase'
      },
      {
        EventCategory: GA_EVENTS.EXPENSESADDITIONALCLAIMANTS,
        EventAction: GA_ACTIONS.SUBMIT,
        EventLabel: 'DisplayPurchase'
      }
      )
    props.updateContacts(contacts);
    props.handleDisplayPurchases();
  };

  const renderContacts = () => {
    const additionalClaimants = getAdditionalClaimants(contacts);
    return additionalClaimants.length
      ? getContactsForms(additionalClaimants)
      : onAddContact();
  };

  const defaultProps = { timeout: 1000 };
  const continueButton = contacts.every(x => x.isValid) && (
    <Grow in style={{ transformOrigin: '0 0 0' }} {...defaultProps}>
      <Button
        id="btn-expensesBeneficiaries-continue"
        type="button"
        className="btn btn-primary btn-large btn-block btn-amex mt-3"
        onClick={handleDisplayPurchases}
        text={t('commonContinue')}
      />
    </Grow>
  );
  return (
    <Row>
      <Col>
        <Grow in style={{ transformOrigin: '0 0 0' }} {...defaultProps}>
          <Row>
            <Col className="expenses-beneficiaries">
              <Row>
                <Col>
                  <strong className="expenses-title">
                    {t('expenseBeneficiaryContactFormTitle')}
                  </strong>
                </Col>
              </Row>
              <Row>
                <Col className="mb-3 sub-title-expenses">
                  <i>{t('expenseBeneficiaryContactFormSubTitle')}</i>
                </Col>
              </Row>
              <Row>
                <Col className="mb-3 description-expenses">
                  <i>{t('expenseBeneficiaryContactFormDescription')}</i>
                </Col>
              </Row>
              {renderContacts()}
              <Row>
                <Col className="mt-3">
                  <Button
                    type="button"
                    className="btn btn-primary btn-large btn-amex"
                    onClick={() => onAddContact()}
                    text={t(
                      'expenseBeneficiaryContactFormAddAnotherButtonLabel'
                    )}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Grow>
        {continueButton}
      </Col>
    </Row>
  );
};

ExpensesBeneficiaries.propTypes = {
  contacts: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired,
  updateContacts: PropTypes.func.isRequired,
  handleDisplayPurchases: PropTypes.func.isRequired,
  config: PropTypes.shape({
    TITLES: PropTypes.shape({
      enabled: PropTypes.bool.isRequired,
      values: PropTypes.arrayOf(PropTypes.string).isRequired
    }).isRequired
  }).isRequired,
  market: PropTypes.string.isRequired
};

export default withConfig(ExpensesBeneficiaries);
