import { SET_SELECTED_LANGUAGE_SUCCESS } from 'actions/actionTypes';
import { getTwoCharacterLanguageIsoCode } from 'utils/languageUtils';
import { changeOneTrustLanguage } from 'utils/one-trust/change-language';

export const changeLanguageMiddleware = () => next => action => {
  next(action);

  if (action.type === SET_SELECTED_LANGUAGE_SUCCESS) {
    const languageIsoCode = getTwoCharacterLanguageIsoCode(
      action.selectedLanguage
    );
    changeOneTrustLanguage(languageIsoCode);
  }
};

export const changeOneTrustCookieLanguageMiddleware = ({
  getState
}) => next => action => {
  next(action);

  if (action.type === 'cookies_SUCCESS') {
    const languageIsoCode = getTwoCharacterLanguageIsoCode(
      getState().marketLanguages?.selectedLanguage
    );
    changeOneTrustLanguage(languageIsoCode);
  }
};

export default [
  changeLanguageMiddleware,
  changeOneTrustCookieLanguageMiddleware
];
