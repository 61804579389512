import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { getMarket } from 'store/selectors/market';
import { formatAnswer } from 'utils/AnswerHelper';
import { useTranslation } from 'react-i18next';
import { MARKETS, NA } from 'constants/constants';

import { withConfig } from 'ConfigurationProvider/index';
import QADataQuestion from 'components/Review/QADataQuestion';

export const CheckedContentQA = props => {
  const { t } = useTranslation();
  const market = useSelector(getMarket);
  const isMarketUK = market === MARKETS.AMEX_UK || market === MARKETS.AXA_UK;
  let accountNumber = NA;
  if (props.questions.account)
    accountNumber = `*****************${props.questions.account.slice(-4)}`;
  else if (props.questions.iban)
    accountNumber = `*****************${props.questions.iban.slice(-4)}`;

  let sortNumber = NA;
  if (props.questions.sortCode)
    sortNumber = `******${props.questions.sortCode.slice(-2)}`;
  else if (props.questions.swiftCode)
    sortNumber = `******${props.questions.sortCode.slice(-2)}`;

  const labels = {
    commonYes: t('commonYes'),
    commonNo: t('commonNo'),
    uploadComponentEmail: t('uploadComponentEmail'),
    uploadComponentUnavailable: t('uploadComponentUnavailable'),
    commonNoContacts: t('commonNoContacts')
  };

  const renderBankData = isUK => {
    if (isUK) {
      return (
        <>
          <QADataQuestion
            key={props.questions.account}
            label={t('checkedBankAccountNumber')}
            answer={accountNumber}
          />
          <QADataQuestion
            key={props.questions.sortCode}
            label={t('checkedBankSort')}
            answer={sortNumber}
          />
        </>
      );
    }
    return (
      <>
        <QADataQuestion
          key={props.questions.iban}
          label={t('bankIBANCode')}
          answer={accountNumber || NA}
        />
        <QADataQuestion
          key={props.questions.swiftCode}
          label={t('checkedBankSwift')}
          answer={sortNumber || NA}
        />
      </>
    );
  };

  return (
    <div className="checked-text-content">
      {!props.bankData &&
        props.questions.map(question => (
            <QADataQuestion
                key={question.id}
                label={question.label ? question.label.replace('*', '') : ''}
                sendingtype={question.properties != null && question.properties.hasOwnProperty('sendingType') ? question.properties.sendingType : ""}
                questionid={question.id}
                fileName={question.properties != null && question.properties.hasOwnProperty('fileName') ? question.properties.fileName : ""}                
            answer={formatAnswer(
              question,
              props.claimContacts,
              labels,
              props.config,
                props.userInfo,
                props.claim
            )}
          />
        ))}
      {props.bankData && (
        <>
          <QADataQuestion
            key={props.questions.name}
            label={t('checkedBankAccountName')}
            answer={props.questions.name || NA}
          />
          <QADataQuestion
            key={props.questions.accountType}
            label={t('bankType')}
            answer={props.questions.accountType || NA}
          />
          <QADataQuestion
            key={props.questions.holder}
            label={t('checkedBankAccountHolder')}
            answer={props.questions.holder}
          />
          {renderBankData(isMarketUK)}
        </>
      )}
    </div>
  );
};

CheckedContentQA.propTypes = {
  questions: PropTypes.oneOfType([PropTypes.arrayOf({}), PropTypes.shape({})])
    .isRequired,
  claimContacts: PropTypes.arrayOf({}),
  config: PropTypes.shape({}).isRequired,
  userInfo: PropTypes.shape({}).isRequired,
  bankData: PropTypes.bool,
  claim: PropTypes.shape({
    id: PropTypes.string
  }).isRequired
};

CheckedContentQA.defaultProps = {
  claimContacts: {},
  bankData: false
};

export default withConfig(CheckedContentQA);
