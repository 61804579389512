import React from 'react';
import PropTypes from 'prop-types';

const ProductItemComponent = props => (
  <div
    className={`card ${props.classes}`}
    onClick={props.onClick}
    onKeyDown={props.onClick}
  >
    <div className={props.onClick ? 'card-body clickable' : 'card-body'}>
      {props.children}
    </div>
  </div>
);

ProductItemComponent.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element
  ]).isRequired,
  classes: PropTypes.string,
  onClick: PropTypes.func
};

ProductItemComponent.defaultProps = {
  classes: '',
  onClick: null
};

export default ProductItemComponent;
