import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'components/shared/Table';
import { Button, TextField } from 'components/shared/Forms';
import ButtonBack from 'components/shared/Custom/ButtonBack';
import { addAlert } from 'actions/alertsActions';
import { updateEmailUser } from 'actions/feature/user';
import { validateField, validateForm } from 'utils/formValidationHelper';
import { pageview } from 'utils/analytics';
import { getRoute } from 'utils/routeHelper';
import {
  emailSchemaValidations,
  passwordSchemaValidations,
  schemaValidation
} from './EditEmailSchemaValidations';
import { appInsights } from 'ApplicationInsights';

const EditEmailPage = props => {
  const [errorFields, setErrorFields] = useState({});
  const [formValues, setFormValues] = useState({});
  const { t } = useTranslation();

  useEffect(() => {
    pageview({
      title: 'User',
      page: getRoute('user', 'profile', 'updateEmail')
    });
    appInsights.trackPageView(
      {
        name: 'User',
        uri: getRoute('user', 'profile', 'updateEmail')
      }
    )
  }, [props.languageId]);

  const handleValueChange = event => {
    const newFormValues = {
      ...formValues,
      [event.target.name]: event.target.value
    };
    setFormValues(newFormValues);
  };

  const handleValidation = (fieldName, value) => {
    const result = validateField(schemaValidation, fieldName, value);
    setErrorFields({
      ...errorFields,
      ...result.errorFields
    });
  };

  const handleEmailConfirmation = (fieldName, value) => {
    const result = validateForm(emailSchemaValidations, {
      email: formValues.email,
      [fieldName]: value
    });

    setErrorFields({
      ...errorFields,
      ...result.errorFields
    });
  };

  const handlePassworConfirmation = (fieldName, value) => {
    const result = validateForm(passwordSchemaValidations, {
      password: formValues.password,
      [fieldName]: value
    });

    setErrorFields({
      ...errorFields,
      ...result.errorFields
    });
  };

  const handleSubmit = () => {
    const result = validateForm(schemaValidation, formValues);
    if (!result.isValid) {
      setErrorFields(result.errorFields);
    } else {
      props.actions.updateEmailUser({
        email: formValues.email,
        password: formValues.password
      });
      onBackClick();
    }
  };

  const onBackClick = () => {
    props.toggleEditEmail();
  };

  return (
    <div className="d-flex flex-column mx-auto paper-frame">
      <Row>
        <Col>
          <Row>
            <Col className="col-12 col-md-6">
              <ButtonBack
                onClick={onBackClick}
                labelText={t('buttonBackEditEmail')}
              />
            </Col>
          </Row>
          <Row>
            <Col className="col-12 col-md-6">
              <TextField
                label={t('commonFromEmailLabel')}
                name="oldEmail"
                value={props.userInfo.email}
                ariaDescribedby={t('commonFromEmailPlaceholder')}
                disabled
              />
            </Col>
            <Col className="col-12 col-md-6">
              <TextField
                label={t('commonFromConfirmPasswordLabel')}
                name="password"
                value={formValues.password}
                type="password"
                onValueChange={handleValueChange}
                onValidate={handlePassworConfirmation}
                errorFields={errorFields}
                ariaDescribedby={t('commonFromPasswordLabel')}
                required
                sanitize={false}
              />
            </Col>
          </Row>
          <Row>
            <Col className="col-12 col-md-6">
              <TextField
                label={t('commonFromNewEmailLabel')}
                name="email"
                value={formValues.email}
                onValueChange={handleValueChange}
                onValidate={handleValidation}
                errorFields={errorFields}
                ariaDescribedby={t('commonFromEmailPlaceholder')}
                required
                sanitize={false}
              />
            </Col>
            <Col className="col-12 col-md-6">
              <TextField
                label={t('commonFromConfirmNewEmailLabel')}
                name="newEmailConfirmation"
                value={formValues.newEmailConfirmation}
                onValueChange={handleValueChange}
                onValidate={handleEmailConfirmation}
                errorFields={errorFields}
                ariaDescribedby={t('commonFromEmailPlaceholder')}
                required
                sanitize={false}
              />
            </Col>
          </Row>
          <Row>
            <Col className="col-md-4 col-sm-12 text-center mx-auto">
              <Button
                type="button"
                className="btn btn-primary btn-large btn-block btn-amex mt-3"
                onClick={onBackClick}
                text={t('commonCancel')}
              />
            </Col>
            <Col className="col-md-4 col-sm-12 text-center mx-auto">
              <Button
                type="button"
                className="btn btn-primary btn-large btn-block btn-amex mt-3"
                onClick={handleSubmit}
                text={t('commonUpdate')}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = state => ({
  userInfo: state.userInfo
});

const mapDispatchToProps = dispatch => ({
  actions: {
    ...bindActionCreators({ updateEmailUser, addAlert }, dispatch)
  }
});

EditEmailPage.propTypes = {
  userInfo: PropTypes.shape({
    name: PropTypes.string,
    surname: PropTypes.string,
    email: PropTypes.string
  }).isRequired,
  actions: PropTypes.shape({
    addAlert: PropTypes.func.isRequired,
    updateEmailUser: PropTypes.func.isRequired
  }).isRequired,
  toggleEditEmail: PropTypes.func.isRequired,
  languageId: PropTypes.number.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(EditEmailPage);
