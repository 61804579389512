import React from 'react';
import { useTranslation } from 'react-i18next';
import CircularProgress from '@material-ui/core/CircularProgress';

import classNames from '../classNames';

const circularProgressUploadingSize = 30;
const uploadingSpinnerClasses = {
  mt2: 'mt-2',
  uploadProgress: 'upload__progress'
};
export const uploadingLabel = 'uploadComponentUploading';

const UploadingSpinner = () => {
  const { t } = useTranslation();
  return (
    <div
      className={`${classNames.uploadDropText} ${uploadingSpinnerClasses.mt2}`}
    >
      <CircularProgress
        className={uploadingSpinnerClasses.uploadProgress}
        size={circularProgressUploadingSize}
      />
      <span>{t(uploadingLabel)}</span>
    </div>
  );
};

export default UploadingSpinner;
