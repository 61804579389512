import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'components/shared/Table';

import './_PolicyNumberSelect.scss';

const PolicyNumberInput = ({ onChange, regexValidation, maxLength }) => {
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState('');

  const createPolicyObject = newPolicy => ({
    value: newPolicy,
    label: newPolicy
  });

  const handleOnChange = e => setInputValue(e.target.value);

  const handleOnInputChange = () => onChange(createPolicyObject(inputValue));

  const isNotValid = text => !regexValidation.test(text);

  const getClass = base =>
    `${base}  ${
      // eslint-disable-next-line no-nested-ternary
      isNotValid(inputValue) ? 'is-invalid' : inputValue ? 'is-valid' : ''
    }`;

  return (
    <Row>
      <Col className="col-12 col-sm-12">
        <input
          type="text"
          className={getClass('form-control w-100')}
          name="policy-input"
          placeholder={t('policyComponentPlaceholder')}
          onChange={handleOnChange}
          onBlur={handleOnInputChange}
          value={inputValue}
          maxLength={maxLength}
        />
      </Col>
    </Row>
  );
};

PolicyNumberInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  regexValidation: PropTypes.instanceOf(RegExp).isRequired,
  maxLength: PropTypes.number.isRequired
};

PolicyNumberInput.defaultProps = {};

export default PolicyNumberInput;
