import React from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import { Row, Col } from 'components/shared/Table';
import { withConfig } from '../../ConfigurationProvider';

function SummaryCard(props) {
  return (
    <div>
      <div className="main-card review-successful">
        <Row className="no-gutters">
          <Col className="text-left">
            <h4 className="amount-label">{props.amountLabel}</h4>
          </Col>
          <Col className="text-right">
            <NumberFormat
              className="amount"
              thousandSeparator={
                props.config.REGIONAL_SETTINGS.thousandSeparator
              }
              decimalSeparator={props.config.REGIONAL_SETTINGS.decimalSeparator}
              displayType="text"
              isNumericString
              value={props.amount.value}
              prefix={`${props.amount.currency} `}
              fixedDecimalScale
              decimalScale={2}
            />
          </Col>
        </Row>
        {props.reviewClaimReturn}
        <div
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: props.reviewClaimPolicyLink
          }}
        />
      </div>
    </div>
  );
}

SummaryCard.propTypes = {
  amountLabel: PropTypes.string.isRequired,
  amount: PropTypes.shape({
    currency: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired
  }).isRequired,
  reviewClaimPolicyLink: PropTypes.string.isRequired,
  reviewClaimReturn: PropTypes.string.isRequired,
  config: PropTypes.shape({
    REGIONAL_SETTINGS: PropTypes.shape({
      thousandSeparator: PropTypes.string.isRequired,
      decimalSeparator: PropTypes.string.isRequired
    }).isRequired
  }).isRequired
};

export default withConfig(SummaryCard);
