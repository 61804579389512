import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { event } from 'utils/analytics';
import isEmpty from 'lodash/isEmpty';

import { Button } from 'components/shared/Forms';
import Currency from 'components/CurrencyComponent/CurrencyComponent';
import CurrencyType from 'components/CurrencyType';

import { GA_EVENTS, GA_ACTIONS } from 'constants/googleAnalytics';
import { appInsights } from 'ApplicationInsights';

class CurrencyQuestion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      numberField: '',
      coinType: '',
      coinChanged: false // Parameter used to control whether value in state is default or changed,
    };
    this.handleUserInput = this.handleUserInput.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleOnChangeCoinType = this.handleOnChangeCoinType.bind(this);
  }

  // TODO: technical debt
  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(newProps) {
    const userAnswerValue =
      newProps.userAnswer &&
      newProps.userAnswer.value &&
      newProps.userAnswer.value.split(' ').length === 2 &&
      newProps.userAnswer.value.split(' ')[1];
    if (
      !isEmpty(userAnswerValue) &&
      userAnswerValue !== this.state.numberField
    ) {
      this.setState({
        numberField: userAnswerValue
      });
    }
  }

  handleNumberFieldSet = value => {
    this.setState({
      numberField: value
    });
  };

  handleSubmit(eventHandler) {
    eventHandler.preventDefault();

    const { id, nextGroup, answer } = this.props;
    const splitAnswer = answer ? answer.split(' ') : '';
    const coin =
      splitAnswer && !this.state.coinChanged
        ? splitAnswer[0]
        : this.state.coinType;
    const amount =
      splitAnswer && !this.state.numberField
        ? splitAnswer[1]
        : this.state.numberField;
    const userAnswer = coin.concat(` ${amount}`);

    event(GA_EVENTS.QUESTION, GA_ACTIONS.ANSWER, {
      label: `${this.props.guideWireField} - ${coin}`
    });
    appInsights.trackEvent(
      {
        name:`${this.props.guideWireField} - ${coin}`
      },
      {
        EventCategory: GA_EVENTS.QUESTION,
        EventAction: GA_ACTIONS.ANSWER,
        EventLabel: `${this.props.guideWireField} - ${coin}`
      }
      )
	  
    this.props.onUserAnswer(id, userAnswer, nextGroup);
  }

  handleUserInput(eventHandler) {
    let numberField = eventHandler.floatValue;
    if (Number.isNaN(numberField)) numberField = '';
    if (numberField !== this.state.numberField) this.setState({ numberField });
  }

  handleOnChangeCoinType(eventHandler) {
    const coinType = eventHandler.target.value;
    const stateChange = this.state.coinChanged
      ? { coinType }
      : { coinType, coinChanged: true };
    this.setState(stateChange);
  }

  render() {
    const splitAnswer = this.props.answer ? this.props.answer.split(' ') : '';
    const coin =
      splitAnswer && !this.state.coinChanged
        ? splitAnswer[0]
        : this.state.coinType;
    const amount =
      splitAnswer && !this.state.numberField
        ? splitAnswer[1]
        : this.state.numberField;
    const btnDisabled = !amount;

    return (
      <form
        onSubmit={this.handleSubmit}
        className="form-inline needs-validation mb-2 "
        noValidate
      >
        <div className="row input-group mb-3 px-0 mx-0">
          <div className="input-group-prepend">
            <CurrencyType
              className="select-currency-input mr-sm-2"
              onValueChange={this.handleOnChangeCoinType}
              value={this.state.coinType}
            />
          </div>

          <Currency
            id="input-currencyQuestion-value"
            prefix={`${coin} `}
            className="form-control col-md-6 col-sm-12 currency-question"
            amount={amount}
            max={10000000}
            min={0}
            ariaLabel="Amount "
            disabled={coin === ''}
            mask="_"
            onValueChange={this.handleUserInput}
            numberField={this.state.numberField}
            onNumberFieldSet={this.handleNumberFieldSet}
          />
          <Button
            id="btn-currencyQuestion-ok"
            type="submit"
            className="btn btn-primary btn-amex-currency"
            disabled={btnDisabled}
          >
            {this.props.labels.commonOk}
          </Button>
        </div>
      </form>
    );
  }
}

CurrencyQuestion.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  answer: PropTypes.string,
  userAnswer: PropTypes.shape({
    value: PropTypes.string.isRequired,
    questionId: PropTypes.number.isRequired
  }),
  onUserAnswer: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired,
  nextGroup: PropTypes.number,
  labels: PropTypes.shape({
    commonOk: PropTypes.string.isRequired,
    currencySelectPlaceholder: PropTypes.string.isRequired
  }).isRequired,
  guideWireField: PropTypes.string
};

CurrencyQuestion.defaultProps = {
  answer: undefined,
  userAnswer: undefined,
  nextGroup: null,
  guideWireField: 'currencyQuestion'
};

export default CurrencyQuestion;
