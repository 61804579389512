import React from 'react';
import PropTypes from 'prop-types';

const CheckedCard = props => {
  const getEditVisibility = props.completed ? 'd-none' : '';

  return (
    <div className="main-card">
      <div className="checked-header mb-3">
        <i className="material-icons mr-3">check_circle_outline</i>
        <h3>{props.title}</h3>
        <span
          className={`edit-icon ${getEditVisibility}`}
          onClick={() => {
            props.onEdit();
          }}
          onKeyDown={() => {
            props.onEdit();
          }}
        >
          <i className="material-icons d-sm-block">edit</i>
          {props.labels.commonEdit}
        </span>
      </div>
      {props.children}
    </div>
  );
};

CheckedCard.propTypes = {
  title: PropTypes.string.isRequired,
  completed: PropTypes.bool,
  labels: PropTypes.shape({
    commonEdit: PropTypes.string.isRequired
  }).isRequired,
  onEdit: PropTypes.func.isRequired,
  children: PropTypes.element.isRequired
};

CheckedCard.defaultProps = {
  completed: false
};

export default CheckedCard;
