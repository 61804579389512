import * as types from './actionTypes';

import { saveActiveCardIndex } from '../utils/sessionController';

export default function setActiveCard(card) {
  return { type: types.SET_ACTIVE_CARD, card };
}

export function setActiveCardCookies(card, index) {
  return dispatch => {
    dispatch(setActiveCard(card.number));
    saveActiveCardIndex(index);
  };
}
