import policyAddMdl from './policy.add';
import policyDetailsMdl from './policy.details';
import policyValidationMdl from './policy.validation';
import policyAppendToTreeMdl from './policy.appendToTree';

export default [
  ...policyDetailsMdl,
  ...policyValidationMdl,
  ...policyAddMdl,
  ...policyAppendToTreeMdl
];
