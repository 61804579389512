import { endPointCallPost } from './endPointCallerApi';
import * as apiRoutes from './apiRoutes';

class FaqApi {
  static addEvaluation(evaluation) {
    const url = `${apiRoutes.FAQ_EVALUATION}`;
    return endPointCallPost(url, evaluation);
  }
}

export default FaqApi;
