import {
  UPDATE_USER_EMAIL_REQUESTED,
  UPDATE_USER_EMAIL_SUCCEEDED,
  UPDATE_USER_EMAIL_FAILED,
  UPDATE_USER_PHONE_REQUESTED,
  UPDATE_USER_PHONE_SUCCEEDED,
  UPDATE_USER_PHONE_FAILED
} from 'actions/feature/user';
import { apiRequest } from 'actions/core/api';
import { UPDATE_EMAIL, PUT_ACCOUNT } from 'api/apiRoutes';
import { hideSpinner, showSpinner, notifySuccess } from 'actions/core/ui';
import { setError } from 'actions/core/error';
import { setCurrentAccount } from 'actions/authenticationActions';
import { saveClaim } from 'actions/feature/claim';
import { REVIEW_STEP } from 'constants/claim';

export const updateEmail = ({ dispatch }) => next => action => {
  next(action);
  if (action.type === UPDATE_USER_EMAIL_REQUESTED) {
    dispatch(showSpinner());
    dispatch(
      apiRequest(
        'PUT',
        UPDATE_EMAIL,
        {},
        action.payload,
        { email: action.payload.email },
        UPDATE_USER_EMAIL_SUCCEEDED,
        UPDATE_USER_EMAIL_FAILED
      )
    );
  }
};

export const updateEmailSuccess = ({
  dispatch,
  getState
}) => next => action => {
  next(action);

  if (action.type === UPDATE_USER_EMAIL_SUCCEEDED) {
    const userInfo = {
      ...getState().userInfo,
      email: action.meta.email
    };
    dispatch(setCurrentAccount(userInfo));
    dispatch(hideSpinner());
    dispatch(notifySuccess('commonProfileEmailUpdated'));
  }
};

export const updateEmailOnFail = ({ dispatch }) => next => action => {
  next(action);
  if (action.type === UPDATE_USER_EMAIL_FAILED) {
    dispatch(hideSpinner());
    dispatch(setError(action.payload));
  }
};

export const updatePhone = ({ dispatch }) => next => action => {
  next(action);
  if (action.type === UPDATE_USER_PHONE_REQUESTED) {
    dispatch(showSpinner());
    dispatch(
      apiRequest(
        'PUT',
        PUT_ACCOUNT,
        {},
        action.payload,
        { phone: action.payload.phone },
        UPDATE_USER_PHONE_SUCCEEDED,
        UPDATE_USER_PHONE_FAILED
      )
    );
  }
};

export const updateUserPhoneNumberOnSuccess = ({
  dispatch
}) => next => action => {
  next(action);
  if (action.type === UPDATE_USER_PHONE_SUCCEEDED) {
    dispatch(
      saveClaim(REVIEW_STEP, false, true, notifySuccess('commonProfileUpdated'))
    );
  }
};

export const updatePhoneOnFail = ({ dispatch }) => next => action => {
  next(action);
  if (action.type === UPDATE_USER_PHONE_FAILED) {
    dispatch(setError(action.payload));
  }
};

export default [
  updateEmail,
  updateEmailSuccess,
  updateEmailOnFail,
  updatePhone,
  updateUserPhoneNumberOnSuccess,
  updatePhoneOnFail
];
