import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import ProductNumberDetailsListItem from './ProductNumberDetailsListItem';

const ProductNumberDetailsList = props => {
  const { t } = useTranslation();

  const list = props.list.map(productItem => (
    <ProductNumberDetailsListItem
      item={productItem}
      key={productItem.id}
      toggleItemProfile={props.toggleItemProfile}
      getItemInfo={props.getItemInfo}
      isCardsList={props.isCardsList}
    />
  ));

  return (
    <div className="row">
      <div className="col">
        <h4>{props.listTitle}</h4>
        {props.list.length ? list : <p>{t('myProfileNoItemsFound')}</p>}
      </div>
    </div>
  );
};

ProductNumberDetailsList.propTypes = {
  list: PropTypes.arrayOf(PropTypes.shape({})),
  toggleItemProfile: PropTypes.func,
  getItemInfo: PropTypes.func,
  listTitle: PropTypes.string.isRequired,
  isCardsList: PropTypes.bool
};

ProductNumberDetailsList.defaultProps = {
  toggleItemProfile: () => {},
  getItemInfo: () => {},
  list: [],
  isCardsList: true
};

export default ProductNumberDetailsList;
