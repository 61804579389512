import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

/**
 * @param {{
 *  logoConfiguration: import('../../../ConfigurationProvider/withConfig').MyConfig['LOGO'],
 *  collapsed: boolean
 * }} param0
 */
export const PartnerLogo = ({ logoConfiguration, collapsed }) => {
  const imgSrc = collapsed
    ? logoConfiguration.img.srcCollapsed || logoConfiguration.img.src
    : logoConfiguration.img.src;
  return (
    <div className="item item-logo">
      <a
        href={logoConfiguration.link.href}
        title={logoConfiguration.link.title}
        className="iNMb float-left item"
      >
        <img
          src={imgSrc}
          title={logoConfiguration.img.title}
          alt={logoConfiguration.img.alt}
          className="partner-logo"
        />
      </a>
    </div>
  );
};

PartnerLogo.propTypes = {
  logoConfiguration: PropTypes.shape({
    link: PropTypes.shape({
      href: PropTypes.string.isRequired,
      title: PropTypes.string
    }).isRequired,
    img: PropTypes.shape({
      alt: PropTypes.string,
      src: PropTypes.string.isRequired,
      srcCollapsed: PropTypes.string,
      title: PropTypes.string
    }).isRequired
  }).isRequired,
  collapsed: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
  logoConfiguration: state.configuration.LOGO
});

export default connect(mapStateToProps)(PartnerLogo);
