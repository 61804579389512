import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { BODIAM_BASE_URL } from 'constants/constants';
import { getToken } from 'utils/sessionController';
import { getMarketFromUrl, getLanguageFromRecoverPath } from 'utils/urlHelpers';

const PrivateRoute = ({ component: Component, ...props }) => {
  const lang = getLanguageFromRecoverPath();
  const redirectPath = lang
    ? `${BODIAM_BASE_URL}${getMarketFromUrl()}/login/lang/${lang}`
    : `${BODIAM_BASE_URL}${getMarketFromUrl()}/login`;
  return (
    <Route
      {...props}
      render={() =>
        getToken() ? <Component {...props} /> : <Redirect to={redirectPath} />
      }
    />
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.element.isRequired
};

export default PrivateRoute;
