import cardAddMdl from './card.add';
import cardSubmitMdl from './card.appendToTree';
import cardBenefitsMdl from './card.benefits';
import cardValidationMdl from './card.validation';

export default [
  ...cardAddMdl,
  ...cardSubmitMdl,
  ...cardBenefitsMdl,
  ...cardValidationMdl
];
