import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import { event } from 'utils/analytics';
import { GA_EVENTS, GA_ACTIONS } from 'constants/googleAnalytics';

import * as prismicActions from 'actions/prismicActions';

import NewsModal from 'Bodiam/components/NewsModal/NewsModal';
import NewsModalHeader from 'Bodiam/components/NewsModal/NewsModalHeader';
import NewsModalFooter from 'Bodiam/components/NewsModal/NewsModalFooter';
import CarouselComponent from 'components/shared/CarouselComponent';

import './_NewsCarousel.scss';
import { appInsights } from 'ApplicationInsights';
class NewsCarousel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedSlide: 0
    };

    this.renderCarouselItem = this.renderCarouselItem.bind(this);
    this.onChangeCurrentSlide = this.onChangeCurrentSlide.bind(this);
  }

  onChangeCurrentSlide(selectedSlide) {
    this.setState({
      selectedSlide
    });
  }

  renderCarouselItem(slide) {
    const selectedPrismicEvent = this.props.prismicEvents.find(
      (_, index) => index === this.state.selectedSlide
    );

    return (
      <>
        <NewsModalHeader titleClassName="title-text" title={slide.title} />
            <p className="news-description" >
                <span
                    className="modal-description"
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{ __html: slide.description }} />
            </p>
        <NewsModalFooter
          btnLabelOk={this.props.t('findoutmore')}
          handleBtnOkClick={() => {
            event(GA_EVENTS.NEWSMODAL, GA_ACTIONS.CLICK, {
              label: 'findoutmore'
            });
            appInsights.trackEvent(
              {
                name:'findoutmore'
              },
              {
                EventCategory: GA_EVENTS.NEWSMODAL,
                EventAction: GA_ACTIONS.CLICK,
                EventLabel: 'findoutmore'
              }
            )
            if (selectedPrismicEvent) {
              const win = window.open(selectedPrismicEvent.url, '_blank');
              win.focus();
            }
          }}
          showOkBtn={selectedPrismicEvent && !!selectedPrismicEvent.url}
          btnLabelCancel={this.props.t('nothanks')}
          handleBtnCancelClick={() => {
            event(GA_EVENTS.NEWSMODAL, GA_ACTIONS.CLICK, { label: 'nothanks' });
            this.setState(prevState => ({
              selectedSlide:
                prevState.selectedSlide - 1 < 0
                  ? 0
                  : prevState.selectedSlide - 1
            }));
            this.props.setReadPrismicEvent(this.state.selectedSlide);
          }}
          btnOkClassName="btn btn-large btn-block btn-amex ok-modal-button"
          btnCancelClassName="btn btn-large w-50 fs-1 btn-amex ok-modal-button"
        />
      </>
    );
  }

  render() {
    const shouldDisplayModal = this.props.prismicEvents.some(
      item => !item.isRead
    );

    const currentSlide =
      this.state.selectedSlide < 0 ? 0 : this.state.selectedSlide;

    const content = (
      <CarouselComponent
        extraClassName="news-carousel"
        slides={this.props.prismicEvents}
        onSlideChange={this.onChangeCurrentSlide}
        showIndicators
        showRemoveSlide={false}
        showAddSlide={false}
        selectedSlide={this.state.selectedSlide}
        currentSlide={currentSlide}
        enableNextButton={this.props.prismicEvents.length > 1}
        enablePreviousButton={this.props.prismicEvents.length > 1}
        hasCustomImplementation
        customImplementation={this.renderCarouselItem}
      />
    );

    return (
      <NewsModal
        isOpen={shouldDisplayModal}
        content={content}
        modalClassName="bodiam-modal-frame news-modal"
      />
    );
  }
}

const mapStateToProps = state => ({
  prismicEvents: state.prismicEvents
});

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators(prismicActions, dispatch)
});

NewsCarousel.propTypes = {
  prismicEvents: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired
    })
  ).isRequired,
  setReadPrismicEvent: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(NewsCarousel));
