import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function decisionTree(
  state = initialState.decisionTree,
  action
) {
  switch (action.type) {
    case types.LOAD_TREE_SUCCESS:
      return { ...state, questionnaireTreeByGroupId: action.tree };

    case types.LOAD_CREDIT_CARD_TREE_SUCCESS:
      return {
        ...state,
        questionnaireTreeByGroupId: {
          ...state.questionnaireTreeByGroupId,
          ...action.tree
        }
      };

    case types.LOAD_BENEFITS_TREE_SUCCESS:
      return {
        ...state,
        questionnaireTreeByGroupId: {
          ...state.questionnaireTreeByGroupId,
          ...action.tree
        }
      };

    case types.RESET_VISIBLE_GROUPS:
      return { ...state, visibleGroups: [] };

    case types.RESET_USER_ANSWERS:
      return { ...state, userAnswers: [] };

    case types.RESET_DECISION_TREE:
      return initialState.decisionTree;

    case types.ADD_VISIBLE_GROUP:
      return {
        ...state,
        visibleGroups: [...state.visibleGroups, action.group]
      };

    case types.ADD_USER_ANSWER:
      return { ...state, userAnswers: [...state.userAnswers, action.answer] };

    case types.UPDATE_USER_ANSWERS:
      return { ...state, userAnswers: action.userAnswers };

    case types.UPDATE_VISIBLE_GROUPS:
      return { ...state, visibleGroups: action.visibleGroups };

    case types.UPDATE_CURRENT_TREE:
      return { ...state, currentTree: action.currentTree };
    default:
      return state;
  }
}
