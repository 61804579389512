import { gotoMyClaims } from 'actions/activePageActions';
import {
  DECLINE_CLAIM_REQUESTED,
  DECLINE_CLAIM_SUCCEEDED,
  DECLINE_CLAIM_FAILED
} from '../../../actions/feature/claim';

import { CLAIMS, CLAIMS_DECLINE } from '../../../api/apiRoutes';
import { apiRequest } from '../../../actions/core/api';
import { setError } from '../../../actions/core/error';
import {
  hideSpinner,
  showSpinner,
  notifyError,
  notifyWarning
} from '../../../actions/core/ui';

export const declineClaim = ({ dispatch }) => next => action => {
  next(action);

  if (action.type === DECLINE_CLAIM_REQUESTED) {
    const isWithdrawn = !action.meta.reasonId;
    dispatch(showSpinner());
    dispatch(
      apiRequest(
        'PUT',
        `${CLAIMS}/${action.payload.id}/${CLAIMS_DECLINE}/${action.meta.reasonId}/${action.meta.languageId}/${action.meta.cancelType}`,
        {},
        {},
        { isWithdrawn },
        DECLINE_CLAIM_SUCCEEDED,
        DECLINE_CLAIM_FAILED
      )
    );
  }
};

export const declineClaimSuccess = ({ dispatch }) => next => action => {
  next(action);

  if (action.type === DECLINE_CLAIM_SUCCEEDED) {
    dispatch(gotoMyClaims());
    if (action.meta.isWithdrawn) {
      dispatch(notifyWarning('alertSuccessWithdrawn'));
    } else {
      dispatch(notifyError('alertSuccessDecline'));
    }
    dispatch(hideSpinner());
  }
};

export const declineClaimFail = ({ dispatch }) => next => action => {
  next(action);

  if (action.type === DECLINE_CLAIM_FAILED) {
    dispatch(hideSpinner());
    dispatch(setError(action.payload));
  }
};

export default [declineClaim, declineClaimSuccess, declineClaimFail];
