/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

class CarouselComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentSlide:
        this.props.currentSlide && this.props.currentSlide >= 0
          ? this.props.currentSlide
          : 0
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.currentSlide !== this.props.currentSlide) {
      // TODO: technical debts
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        currentSlide:
          this.props.currentSlide && this.props.currentSlide >= 0
            ? this.props.currentSlide
            : 0
      });
    }
  }

  prev = () => {
    let { currentSlide } = this.state;
    currentSlide -= 1;
    if (currentSlide < 0) {
      currentSlide = this.props.slides.length - 1;
    }
    this.selectSlide(currentSlide);
  };

  next = () => {
    let { currentSlide } = this.state;
    currentSlide += 1;
    if (currentSlide > this.props.slides.length - 1) {
      currentSlide = 0;
    }
    this.selectSlide(currentSlide);
  };

  selectSlide = index => {
    this.setState({
      currentSlide: index
    });
    this.props.onSlideChange(index);
  };

  buildLisItems = () => {
    if (this.props.slides) {
      return this.props.slides.map((item, key) => {
        const isCurrentSlide = key === this.state.currentSlide;
        const className = isCurrentSlide
          ? 'carousel-item active img-fluid my-carousel-item'
          : 'carousel-item img-fluid my-carousel-item';
        const image = item.imageURL ? (
          <img className="d-block" src={item.imageURL} alt="" />
        ) : (
          <i className="material-icon d-block">image</i>
        );

        return (
          <div className={className} key={item.id}>
            <div className="carousel-caption">
              {item.title && (
                <h5 className="title">
                  {item.title}
                  <br />
                  {item.cardName}
                </h5>
              )}
              {item.description && <p>{item.description}</p>}
            </div>
            <div className="d-block image-container">{image}</div>
          </div>
        );
      });
    }
  };

  buildListIndicators = () => {
    if (this.props.slides) {
      return this.props.slides.map((item, key) => {
        const className = key === this.state.currentSlide ? 'active' : '';
        return (
          <li
            data-target=""
            data-slide-to={key}
            // eslint-disable-next-line react/no-array-index-key
            key={key}
            className={className}
            onClick={() => this.selectSlide(key)}
            onKeyDown={() => this.selectSlide(key)}
          />
        );
      });
    }
  };

  buildListItemsCustom = () =>
    this.props.slides.map((slide, key) => {
      const isCurrentSlide = key === this.state.currentSlide;
      const className = isCurrentSlide
        ? 'carousel-item active img-fluid my-carousel-item'
        : 'carousel-item img-fluid my-carousel-item';
      return (
        <div className={className} key={slide}>
          {this.props.customImplementation(slide, key)}
        </div>
      );
    });

  render() {
    const {
      props: {
        extraClassName,
        onAddSlide,
        onRemoveSlide,
        showAddSlide,
        showRemoveSlide,
        showIndicators,
        t,
        enableNextButton,
        enablePreviousButton
      }
    } = this;
    const listItem = this.props.hasCustomImplementation
      ? this.buildListItemsCustom()
      : this.buildLisItems();
    const listIndicators = this.buildListIndicators();

    return (
      <>
        <div
          className={`carousel carousel-fade slide pb-3 ${extraClassName}`}
          data-ride="carousel"
        >
          {showRemoveSlide && showIndicators && (
            <ul className="carousel-indicators">{listIndicators}</ul>
          )}
          <div className="carousel-inner">
            {listItem}
            <div className="add-remove">
              {showRemoveSlide && (
                <span
                  className="add-remove__label mr-2"
                  onClick={onRemoveSlide}
                  onKeyDown={onRemoveSlide}
                >
                  {t('bodiamRemoveCard')}
                </span>
              )}
              {showRemoveSlide && showAddSlide ? '/' : ''}
              {showAddSlide && (
                <span
                  className="add-remove__label ml-2"
                  onClick={onAddSlide}
                  onKeyDown={onAddSlide}
                >
                  {t('bodiamAddCard')}
                </span>
              )}
            </div>
            {(showRemoveSlide || enablePreviousButton) && (
              <a
                className="carousel-control-prev"
                role="button"
                data-slide="prev"
                onClick={this.prev}
                onKeyDown={this.prev}
                tabIndex="-1"
              >
                <span
                  className="carousel-control-prev-icon"
                  aria-hidden="true"
                />
                <span className="sr-only">Previous</span>
              </a>
            )}
            {(showRemoveSlide || enableNextButton) && (
              <a
                className="carousel-control-next"
                role="button"
                data-slide="next"
                onClick={this.next}
                onKeyDown={this.next}
                tabIndex="-1"
              >
                <span
                  className="carousel-control-next-icon"
                  aria-hidden="true"
                />
                <span className="sr-only">Next</span>
              </a>
            )}
          </div>
        </div>
      </>
    );
  }
}

CarouselComponent.propTypes = {
  extraClassName: PropTypes.string,
  showAddSlide: PropTypes.bool,
  showRemoveSlide: PropTypes.bool,
  slides: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        imageURL: PropTypes.string.isRequired,
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        number: PropTypes.string,
        product: PropTypes.string,
        typeOfCard: PropTypes.number,
        title: PropTypes.string,
        description: PropTypes.string
      })
    ),
    PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired
      })
    )
  ]).isRequired,
  onAddSlide: PropTypes.func,
  currentSlide: PropTypes.number,
  onRemoveSlide: PropTypes.func,
  onSlideChange: PropTypes.func,
  showIndicators: PropTypes.bool,
  hasCustomImplementation: PropTypes.bool,
  customImplementation: PropTypes.func,
  enableNextButton: PropTypes.bool,
  enablePreviousButton: PropTypes.bool,
  t: PropTypes.func.isRequired
};

CarouselComponent.defaultProps = {
  extraClassName: '',
  showAddSlide: true,
  showRemoveSlide: true,
  onAddSlide: null,
  showIndicators: true,
  hasCustomImplementation: false,
  customImplementation: () => {},
  enableNextButton: false,
  enablePreviousButton: false,
  onSlideChange: () => {},
  onRemoveSlide: () => {},
  currentSlide: 0
};

export default withTranslation()(CarouselComponent);
