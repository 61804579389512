import React, { useState } from 'react';
import PropTypes from 'prop-types';

import YesNo from 'components/ContactsQuestion/Shared/YesNo';
import { validateForm } from 'utils/formValidationHelper';
import AffectedPerson from './AffectedPerson';
import schemaValidation from './AffectedPersonHealthSchemaValidation';

const AffectedPersonHealth = props => {
  const person = {
    id: props.person.id,
    typeObjectId: props.person.typeObjectId,
    isValid: props.person.isValid,
    payload: {
      ...props.person.payload,
      wasStable: props.person.payload.wasStable
    }
  };

  const [errorFields, setErrorFields] = useState({});

  const [formValues, setFormValues] = useState(person);

  const handleFieldFormValueChange = (name, value) => {
    const clone = {
      ...formValues,
      payload: { ...formValues.payload, [name]: value }
    };
    validateAndUpdate(clone);
  };

  const validateAndUpdate = values => {
    setFormValues(values);

    const clone = { ...values, isValid: values.isValid && isFormValid(values) };
    props.onUpdateContact(clone);
  };

  const isFormValid = values => {
    const result = validateForm(schemaValidation, values.payload);

    setErrorFields({
      ...errorFields,
      ...result.errorFields
    });
    return values.isValid && result.isValid;
  };

  const handleOnUpdateContactBase = values => {
    const clone = {
      ...formValues,
      ...values,
      payload: { ...formValues.payload, ...values.payload }
    };
    validateAndUpdate(clone);
  };

  return (
    <>
      <AffectedPerson
        person={props.person}
        isRemovable={props.isRemovable}
        onRemoveContact={props.onRemoveContact}
        onUpdateContact={handleOnUpdateContactBase}
      />
      <YesNo
        name="wasStable"
        label="contactsWasStable"
        errorFields={errorFields}
        value={props.person.payload.wasStable}
        onChange={handleFieldFormValueChange}
      />
    </>
  );
};

AffectedPersonHealth.propTypes = {
  person: PropTypes.shape({
    id: PropTypes.string,
    typeObjectId: PropTypes.number.isRequired,
    isValid: PropTypes.bool.isRequired,
    payload: PropTypes.shape({
      wasStable: PropTypes.string
    })
  }).isRequired,
  isRemovable: PropTypes.bool,
  onUpdateContact: PropTypes.func.isRequired,
  onRemoveContact: PropTypes.func.isRequired
};
AffectedPersonHealth.defaultProps = {
  isRemovable: false
};

export default AffectedPersonHealth;
