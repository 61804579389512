import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import EditProfileComponent from 'components/EditProfileComponent';
import { Row, Col } from 'components/shared/Table';
import actionProvider from 'utils/action-provider';
import { useTranslation } from 'react-i18next';
import ButtonBack from 'components/shared/Custom/ButtonBack';

import { pageview } from 'utils/analytics';
import { getRoute } from 'utils/routeHelper';
import { appInsights } from 'ApplicationInsights';

const EditProfilePage = props => {
  useEffect(() => {
    pageview({ title: 'User', page: getRoute('user', 'profile', 'update') });
    appInsights.trackPageView(
      {
        name: 'User', uri: getRoute('user', 'profile', 'update')
      }
    )
  }, []);
  const { t } = useTranslation();
  const handleUpdateEmailClick = () => props.toggleEditEmail();
  return (
    <div className="d-flex flex-column mx-auto paper-frame">
      <Row>
        <Col>
          <Row>
            <Col>
              <ButtonBack
                onClick={() => {
                  props.toggleEditProfile();
                }}
                labelText={t('buttonBackMyProfile')}
              />
            </Col>
          </Row>
          <EditProfileComponent
            availableTitles={props.availableTitles}
            userInfo={props.userInfo}
            handleUpdate={props.actions.userUpdateProfile}
            toggleEditProfile={props.toggleEditProfile}
            personalNumber={props.personalNumber}
            handleUpdateEmail={handleUpdateEmailClick}
          />
        </Col>
      </Row>
    </div>
  );
};

/**
 * @param {{ configuration: import('../../ConfigurationProvider/withConfig').MyConfig }} state
 */
const mapStateToProps = state => ({
  availableTitles: state.configuration.TITLES,
  userInfo: state.userInfo,
  personalNumber: state.configuration.PERSONALNUMBER
});

const mapDispatchToProps = dispatch => ({
  actions: {
    ...bindActionCreators(actionProvider.getAuthenticationActions(), dispatch)
  }
});

EditProfilePage.propTypes = {
  actions: PropTypes.shape({
    userUpdateProfile: PropTypes.func.isRequired
  }).isRequired,
  availableTitles: PropTypes.shape({
    enabled: PropTypes.bool.isRequired,
    values: PropTypes.arrayOf(PropTypes.string).isRequired
  }).isRequired,
  userInfo: PropTypes.shape({
    name: PropTypes.string.isRequired,
    surname: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired
  }).isRequired,
  toggleEditProfile: PropTypes.func.isRequired,
  toggleEditEmail: PropTypes.func.isRequired,
  personalNumber: PropTypes.shape({
    enabled: PropTypes.bool.isRequired,
    maxLenght: PropTypes.number.isRequired
  }).isRequired
};

EditProfilePage.defaultProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(EditProfilePage);
