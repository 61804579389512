import { LANDING_PAGE } from 'constants/constants';
import { UPDATE_ACTIVE_PAGE } from 'actions/actionTypes';

const activePageHistory = () => next => action => {
  if (action.type === UPDATE_ACTIVE_PAGE && action.page) {
    const history = JSON.parse(localStorage.getItem('history'));
    // pass in the path that we want to block
    const urlFields = window.location.pathname.split('/');
    const isWelcomePage =
      urlFields.findIndex(f => f.toLowerCase() === 'welcome') > -1;

    // If the path is included within location.pathname and the current page is diferent to landing page then...
    if (isWelcomePage && action.page !== LANDING_PAGE) {
      // If this new page already exist in the localStorage history property dont add it to the array.
      if (
        !history ||
        (history[history.length - 2] !== action.page &&
          history[history.length - 1] !== action.page)
      ) {
        // Since the behavior of the browser when someone press back button trigger the event twice
        // I have had to add 2 current pagesto the end of the localStage history property
        const hist = !history ? [] : history;
        localStorage.setItem(
          'history',
          JSON.stringify([...hist, action.page, action.page])
        );
      }
    }
    if (action.page === LANDING_PAGE) {
      // Reseting localStorage
      localStorage.setItem('history', JSON.stringify([action.page]));
    }
  }
  next(action);
};

export default activePageHistory;
