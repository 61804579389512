import { apiRequest } from 'actions/core/api';
import {
  GET_COUNTRIES_BY_LANGUAGE_ID,
  SET_COUNTRY_OPTIONS,
  FAIL_COUNTRY_OPTIONS
} from 'actions/feature/country';
import { GET_COUNTRIES } from 'api/apiRoutes';

export const countries = ({ dispatch }) => next => action => {
  next(action);

  if (action.type === GET_COUNTRIES_BY_LANGUAGE_ID) {
    dispatch(
      apiRequest(
        'GET',
        `${GET_COUNTRIES}/${action.payload.languageId}`,
        {},
        {},
        {
          ...action.meta
        },
        SET_COUNTRY_OPTIONS,
        FAIL_COUNTRY_OPTIONS
      )
    );
  }
};

export default [countries];
