import React from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import { useTranslation } from 'react-i18next';
import { Button } from 'components/shared/Forms';
import { Row, Col } from 'components/shared/Table';

const ModalContent = props => {
  const { t } = useTranslation();
  const btnsClass = props.inBodiam
    ? 'row buttons border-top'
    : 'row buttons border-top pt-3';

  const okBtnClss =
    props.labelCancel !== '' && props.inBodiam
      ? 'btn btn-primary btn-large btn-block btn-amex btn-ok'
      : 'btn btn-primary btn-large btn-block btn-amex btn-ok only';

  return (
    <ReactModal
      isOpen={!!props.isOpen}
      contentLabel={t(props.contentLabel)}
      shouldCloseOnOverlayClick={props.shouldCloseOnOverlayClick}
      overlayClassName={props.overlayClassName}
      className={props.className}
    >
      <Row className="custom-modal-content h-100">
        <Col>
          <Row className="modal-body">
            <Col>
              {props.titleClassName !== '' ? (
                <h2 className={props.titleClassName}>{t(props.title)}</h2>
              ) : (
                <h2>{t(props.title)}</h2>
              )}

              <span
                className="modal-description"
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: t(props.description, {
                    keySeparator: '[',
                    nsSeparator: '|'
                  })
                }}
              />
            </Col>
          </Row>
          <div className={btnsClass}>
            <Col>
              <Button
                type="submit"
                className={okBtnClss}
                onClick={props.handleConfirmationOk}
              >
                {t(props.labelOk)}
              </Button>
            </Col>
            {props.labelCancel !== '' && props.handleConfirmationCancel && (
              <Col>
                <Button
                  type="submit"
                  className="btn btn-warning btn-large btn-block btn-amex btn-cancel"
                  onClick={props.handleConfirmationCancel}
                >
                  {t(props.labelCancel)}
                </Button>
              </Col>
            )}
          </div>
        </Col>
      </Row>
    </ReactModal>
  );
};

ModalContent.propTypes = {
  isOpen: PropTypes.bool,
  contentLabel: PropTypes.string,
  shouldCloseOnOverlayClick: PropTypes.bool.isRequired,
  overlayClassName: PropTypes.string,
  titleClassName: PropTypes.string,
  className: PropTypes.string,
  handleConfirmationOk: PropTypes.func.isRequired,
  handleConfirmationCancel: PropTypes.func,
  title: PropTypes.string,
  description: PropTypes.string,
  labelOk: PropTypes.string,
  labelCancel: PropTypes.string,
  inBodiam: PropTypes.bool
};

ModalContent.defaultProps = {
  isOpen: false,
  overlayClassName: '',
  titleClassName: '',
  className: '',
  handleConfirmationCancel: () => {},
  title: '',
  description: '',
  labelCancel: '',
  inBodiam: false,
  labelOk: '',
  contentLabel: ''
};

export default ModalContent;
