import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Button from 'components/shared/Forms/Button';
import Checkbox from '@material-ui/core/Checkbox';

const AcceptConditionsCard = ({ onSubmit }) => {
  const [accepted, setAccepted] = useState(false);
  const { t } = useTranslation();
  const toggleAccepted = () => {
    setAccepted(!accepted);
  };

  return (
    <div className="main-card accept-conditions">
      <div className="check-question">
        <div className="row beneficiary-form-checkbox mb-2 mx-0">
          <div className="col-1 beneficiary-checkbox mr-2 pl-0">
            <Checkbox
              id="checkbox-acceptConditionsCard-condition"
              onClick={toggleAccepted}
              checked={accepted}
              className="checkbox"
            />
          </div>
          <div className="col beneficiary-checkbox-label">
            {accepted
              ? t('acceptTermsConditionsChecked')
              : t('acceptTermsConditions')}
          </div>
        </div>
      </div>
      <Button
        id="btn-acceptConditionsCard-continue"
        className="btn btn-primary btn-block btn-amex"
        onClick={() => onSubmit()}
        disabled={!accepted}
        text={t('acceptSubmitClaim')}
      />
    </div>
  );
};

AcceptConditionsCard.propTypes = {
  onSubmit: PropTypes.func.isRequired
};

export default AcceptConditionsCard;
