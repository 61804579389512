import { SET_COUNTRY_OPTIONS } from 'actions/feature/country';
import initialState from '../initialState';

export default function countries(state = initialState.countries, action) {
  switch (action.type) {
    case SET_COUNTRY_OPTIONS:
      return [...action.payload];
    default:
      return state;
  }
}
