import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Row, Col } from 'components/shared/Table';
import PolicyNumberComponent from 'components/PolicyNumberComponent';
import PolicyNumberLufthansaComponent from 'components/PolicyNumberLufthansaComponent/PolicyNumberLufthansaComponent';
import PolicyNumberValidationResultModal from 'components/PolicyNumberValidationResultModal';

const AddPolicyComponent = props => {
  const { t } = useTranslation();

  // It's a hack to go to My claim page, because it's handling all by state
  useEffect(() => {
    if (props.policyValidation && props.policyValidation.isValid) {
      props.toggleMethod();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.policyValidation]);

  const handleSubmit = (
    _questionId,
    policyNumber,
    policyName,
    policyLastName,
    policyDate
  ) => {
    props.addUserPolicy(policyName, policyLastName, policyNumber, policyDate);
  };

  const handleOnCloseModal = () => {
    props.onCleanValidation();
  };

  const showPolicyValidation = () =>
    !props.policyValidation.isValid && (
      <PolicyNumberValidationResultModal
        modalErrorMessage={props.policyValidation.message}
        modalContentLabel={t('commonCardNumberTitle')}
        closeModal={handleOnCloseModal}
      />
    );

  return (
    <>
      <Row>
        <Col className="question-item mt-3">
          {props.isLufthansa ? (
            <PolicyNumberLufthansaComponent
              textLabel="myProfilePolicyNumber"
              warningLabel="personalDataPolicyWarning"
              onValidPolicyNumber={handleSubmit}
              userInfo={props.userInfo}
              isAdding
            />
          ) : (
            <PolicyNumberComponent
              textLabel="myProfilePolicyNumber"
              warningLabel="personalDataPolicyWarning"
              onValidPolicyNumber={handleSubmit}
              userInfo={props.userInfo}
              isAdding
            />
          )}
        </Col>
      </Row>
      {props.policyValidation && showPolicyValidation()}
    </>
  );
};

AddPolicyComponent.propTypes = {
  isLufthansa: PropTypes.bool.isRequired,
  addUserPolicy: PropTypes.func.isRequired,
  userInfo: PropTypes.shape({
    name: PropTypes.string,
    surname: PropTypes.string,
    dateOfBirth: PropTypes.string
  }),
  policyValidation: PropTypes.shape({
    isValid: PropTypes.bool,
    message: PropTypes.string
  }),
  onCleanValidation: PropTypes.func.isRequired,
  toggleMethod: PropTypes.func
};

AddPolicyComponent.defaultProps = {
  policyValidation: null,
  userInfo: {
    name: '',
    surname: '',
    dateOfBirth: ''
  },
  toggleMethod: () => {}
};

export default AddPolicyComponent;
