import { LANGUAGE_SELECTOR_CODES, LANGUAGE_ID_MAPS } from 'constants/constants';

export function getLanguageSelectorCountryCode(languageId) {
  return LANGUAGE_SELECTOR_CODES[languageId];
}

export function getLanguageIsoCode(languageId) {
  if (!LANGUAGE_ID_MAPS[languageId]) return 'en';

  return LANGUAGE_ID_MAPS[languageId];
}

export const getTwoCharacterLanguageIsoCode = languageId =>
  getLanguageIsoCode(languageId).split('-')[0];

export const getLanguageIdByCode = code => {
  const languageId = Object.keys(LANGUAGE_ID_MAPS).find(key =>
    LANGUAGE_ID_MAPS[key].includes(code.substring(0, 2))
  );
  return Number(languageId);
};

export const getBenefitsEnglishTranslations = async () => {
  const englishTranslations = await fetch('assets/locales/en-GB.json');
  const labels = await englishTranslations.json();
  return labels;
};
