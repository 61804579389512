import React from 'react';
import PropTypes from 'prop-types';

const InfoCard = props => (
  <div className="left-card px-3 pt-2 pb-1 p-md-4 mb-1 mb-md-4">
    <div className="task__info-line mb-2">
      <div className="row no-gutters">
        <i className="material-icons md-48 align-middle mr-3">access_time</i>
        <span className="col">{props.labels.taskListInfoMinutes}</span>
      </div>
    </div>
    <div className="task__info-line">
      <div className="row no-gutters">
        <i className="material-icons md-48 align-middle mr-3">save</i>
        <span className="col">{props.labels.taskListInfoSave}</span>
      </div>
    </div>
  </div>
);

InfoCard.propTypes = {
  labels: PropTypes.shape({
    taskListInfoMinutes: PropTypes.string,
    taskListInfoSave: PropTypes.string
  }).isRequired
};

export default InfoCard;
