import React from 'react';
import PropTypes from 'prop-types';

export const uploadQuestionClasses = {
  radioContainer: 'radio-container',
  mb5: 'mb-5'
};
export const labelClass = `${uploadQuestionClasses.radioContainer} ${uploadQuestionClasses.mb5}`;

const UploadQuestionOption = props => (    
    <label className={props.className}>
        {props.title}
        {/*{props.title == 'Upload a document' ? <><span dangerouslySetInnerHTML={{ __html: props.title }} /><br /> {props.description}</> : props.title}*/}
        <input
            data-testid="rb-uploadQuestionOption"
            type="radio"
            name={`radio${props.id}`}
            checked={props.checked}
            onClick={props.handleCheckboxClick}
            readOnly
        />
        <span className="checkmark" />
        {props.additionalText}
    </label>
);

UploadQuestionOption.propTypes = {
  className: PropTypes.string,
  checked: PropTypes.bool.isRequired,
  handleCheckboxClick: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
  additionalText: PropTypes.element
};

UploadQuestionOption.defaultProps = {
  additionalText: null,
  className: `${uploadQuestionClasses.radioContainer} ${uploadQuestionClasses.mb5}`
};

export default UploadQuestionOption;
