import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import BackgroundLayout from 'components/Layout/BackgroundLayout';

import { gotoLandingMainPage } from 'actions/activePageActions';
import RecoverPasswordForm from './RecoverPasswordForm';

const RecoverPasswordPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  return (
    <BackgroundLayout
      title={t('SignInPageTitle')}
      onBackButtonClick={() => dispatch(gotoLandingMainPage())}
      backButtonText={t('commonBack')}
    >
      <RecoverPasswordForm />
    </BackgroundLayout>
  );
};

export default RecoverPasswordPage;
