import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'components/shared/Table';
import DocumentLinkComponent from 'components/HowToClaim/Shared/DocumentLinkComponent';

import classNames from '../classNames';

const cardItemClasses = {
  btnAmexDoc: 'btn-amex-doc',
  cardInteriorCol: 'card-interior-col',
  columnTitle: 'column-title',
  listWeAcceptCards: 'list-we-accept-cards'
};

const cardItemWrapperClass = 'col-xs-12 col-sm-12 col-md-4 p-2';
const cardItemTitleClass = `${cardItemClasses.columnTitle} ${classNames.textCenter}`;
export const buttonClass = `${classNames.btn} ${classNames.btnPrimary} ${cardItemClasses.btnAmexDoc}`;

/** @param {import('./CardItemParams').CardItemParams} param0 */
const CardItem = ({ title, items, buttonMedicalReport, buttonGDPR }) => {
    const { t } = useTranslation();
    const paragraphSection = text => <p className="content">{t(text)}</p>;
  return (
      <div className="col-xs-12 col-sm-12 col p-2">
                
          {paragraphSection('SupportingDocuments1')}
          <br />
          {paragraphSection('SupportingDocuments2')}
          <br />
          {paragraphSection('IfSomeoneWasIll')}
          <br />
          <DocumentLinkComponent
              buttonMedicalReport={buttonMedicalReport}
              buttonGDPR={buttonGDPR}
          />
          <br/>
          {paragraphSection('ReceiptsForPurchase')}                      
       
    </div>
  );
};

CardItem.propTypes = {
  title: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  buttonMedicalReport: PropTypes.shape({
    title: PropTypes.string
  }),
  buttonGDPR: PropTypes.shape({
    title: PropTypes.string
  })
};

CardItem.defaultProps = {
  buttonMedicalReport: null,
  buttonGDPR: null
};

export default CardItem;
