export const VALIDATE_DATES_REQUEST = '[dates] validate';
export const VALIDATE_DATES_SUCCEEDED = '[dates] validation success';
export const VALIDATE_DATES_FAILED = '[dates] validate fail';

export const SET_DATES_VALIDATION_RESULT = '[dates] set validation result';
export const RESET_DATES_VALIDATION_RESULT = '[dates] reset validation result';
export const CLAIM_DECLINED_BY_DATES_VALIDATIONS =
  '[dates] claim declined by validation dates';

export const validateDates = claim => ({
  type: VALIDATE_DATES_REQUEST,
  payload: { claim }
});

export const setDatesValidation = result => ({
  type: SET_DATES_VALIDATION_RESULT,
  payload: { result }
});

export const resetDatesValidation = () => ({
  type: RESET_DATES_VALIDATION_RESULT
});
