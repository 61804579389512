/**
 * This method retrieves the geolocation from the browser of the current user
 * In case user did not activate geolocation it should return an error message
 *
 * @return {Object} Object containing the latitude and longitude of the user geolocation.
 */
const getUserGeolocation = (handlePosition, handleError) => {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(handlePosition, handleError);
  }
  return 'Geolocation is not supported by this browser.';
};

export default getUserGeolocation;
