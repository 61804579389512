import React from 'react';
import PropTypes from 'prop-types';

const LabelFor = props => {
  const classes = props.className
    ? `label-input ${props.className}`
    : 'label-input';
  return (
    <label ref={props.labelRef} htmlFor={`${props.name}`} className={classes}>
      {
        // eslint-disable-next-line react/prop-types
        props.children
      }
    </label>
  );
};

LabelFor.propTypes = {
  labelRef: PropTypes.shape({}),
  className: PropTypes.string,
  name: PropTypes.string.isRequired
};

LabelFor.defaultProps = {
  labelRef: null,
  className: ''
};

export default LabelFor;
