import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useTranslation } from 'react-i18next';

import ProductItemProfileDetailComponent from 'components/ProductItemProfileDetailComponent';
import { Row, Col } from 'components/shared/Table';
import ButtonBack from 'components/shared/Custom/ButtonBack';

import * as activePageActions from 'actions/activePageActions';
import * as authenticationActions from 'actions/authenticationActions';
import setActiveCard from 'actions/activeCardActions';
import { setActivePolicy } from 'actions/feature/policy';

import { pageview } from 'utils/analytics';
import { getRoute } from 'utils/routeHelper';
import { appInsights } from 'ApplicationInsights';

export const ProductItemProfileDetailPage = props => {
  const { t } = useTranslation();
  useEffect(() => {
    pageview({
      title: 'User',
      page: getRoute('user', 'profile', 'productDetails')
    });
    appInsights.trackPageView(
      {
        name: 'User',
      uri: getRoute('user', 'profile', 'productDetails')
      }
    )
  }, []);
  return (
    <Row>
      <Col>
        <Row>
          <Col>
            <ButtonBack
              onClick={props.toggleProductItemProfile}
              labelText={t('buttonBackCardDetails')}
            />
          </Col>
        </Row>
        {props.productDetail &&
        Object.prototype.hasOwnProperty.call(
          props.productDetail.summary,
          'typeOfCard'
        ) ? (
          <ProductItemProfileDetailComponent
            productDetail={props.productDetail}
            list={props.cardsList}
            deleteCard={props.actions.deleteCardAction}
            toggleProductItemProfile={props.toggleProductItemProfile}
            gotoTriageWrapper={props.actions.gotoTriageWrapper}
            setActiveProductItem={props.actions.setActiveCard}
          />
        ) : (
          <ProductItemProfileDetailComponent
            productDetail={props.productDetail}
            list={props.policiesList}
            deletePolicy={props.actions.deletePolicyAction}
            toggleProductItemProfile={props.toggleProductItemProfile}
            gotoTriageWrapper={props.actions.gotoTriageWrapper}
            setActiveProductItem={props.actions.setActivePolicy}
            isCard={false}
          />
        )}
      </Col>
    </Row>
  );
};

const mapStateToProps = state => ({
  productDetail: state.productDetail,
  cardsList: state.cardsList,
  policiesList: state.policiesList,
  userInfo: state.userInfo
});

const mapDispatchToProps = dispatch => ({
  actions: {
    ...bindActionCreators(authenticationActions, dispatch),
    ...bindActionCreators(activePageActions, dispatch),
    ...bindActionCreators({ setActiveCard, setActivePolicy }, dispatch)
  }
});

ProductItemProfileDetailPage.propTypes = {
  actions: PropTypes.shape({
    deleteCardAction: PropTypes.func.isRequired,
    deletePolicyAction: PropTypes.func.isRequired,
    gotoTriageWrapper: PropTypes.func.isRequired,
    setActiveCard: PropTypes.func.isRequired,
    setActivePolicy: PropTypes.func.isRequired
  }).isRequired,
  productDetail: PropTypes.shape({
    summary: PropTypes.shape({}).isRequired,
    policySummary: PropTypes.shape({})
  }).isRequired,
  cardsList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  policiesList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  toggleProductItemProfile: PropTypes.func.isRequired,
  userInfo: PropTypes.shape({
    name: PropTypes.string.isRequired,
    surname: PropTypes.string.isRequired,
    dateOfBirth: PropTypes.string.isRequired
  })
};

ProductItemProfileDetailPage.defaultProps = {
  userInfo: {
    name: '',
    surname: '',
    dateOfBirth: ''
  }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductItemProfileDetailPage);
