import React from 'react';
import PropTypes from 'prop-types';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import { Icon } from '@material-ui/core';
import { green, blue } from '@material-ui/core/colors';
import DownloadButton from 'components/shared/DownloadButton';

export const DocumentsListComponent = props => {    
  const listItems = props.documentsList.map(file => {
    const documentInfo = `${file.uploadedDate} ${file.name}`;
    return (
      <ListItem key={file.claimDataDocumentId} divider>
        <Icon style={{ color: green[500] }} fontSize="small">
          check_circle
        </Icon>
        <ListItemText
          style={{
            color: blue[600],
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            wordBreak: 'break-word'
          }}
          primary={documentInfo}
            />
            {props.hideDownloadbutton ? null :
                <ListItemSecondaryAction>
                    <DownloadButton fileId={file.externalId} claimId={props.claimId} />
                </ListItemSecondaryAction>}
      </ListItem>
    );
  });
  return <List>{listItems}</List>;
};

DocumentsListComponent.propTypes = {
  documentsList: PropTypes.arrayOf({}).isRequired,
    claimId: PropTypes.string.isRequired,
  hideDownloadbutton: PropTypes.bool
};

export default DocumentsListComponent;
