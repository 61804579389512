import React from 'react';
import PropTypes from 'prop-types';
import spinner from './img/spinner.svg';

const Spinner = props => (
  <div className="loading__wrapper">
    <img
      className="loading__wrapper__spinner"
      src={spinner}
      alt="Loading Spinner"
    />
    {props.text && (
      <span className="loading__wrapper__caption">{props.text}</span>
    )}
  </div>
);

Spinner.propTypes = {
  text: PropTypes.string
};

Spinner.defaultProps = {
  text: null
};

export default Spinner;
