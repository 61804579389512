import { object, string } from 'yup';
import { REGEX } from 'constants/constants';

const invalidTextLabel = 'isInValidText';

const maxLengthConstants = {
  phone: 100,
  address: 100,
  addressNumber: 50,
  addressPC: 20,
  addressCity: 100
};

const schemaValidation = object({
  phone: string()
    .max(maxLengthConstants.phone)
    .matches(REGEX.VALIDATE_PHONE_NUMBER, 'commonFormPhoneValidErrorMsg')
    .required(invalidTextLabel)
    .typeError(invalidTextLabel),
  address: string()
    .max(maxLengthConstants.address)
    .required(invalidTextLabel)
    .typeError(invalidTextLabel),
  addressNumber: string()
    .max(maxLengthConstants.addressNumber)
    .nullable(),
  addressPC: string()
    .max(maxLengthConstants.addressPC)
    .required(invalidTextLabel)
    .typeError(invalidTextLabel),
  addressCity: string()
    .max(maxLengthConstants.addressCity)
    .required(invalidTextLabel)
    .typeError(invalidTextLabel)
});

export { maxLengthConstants, schemaValidation };
