import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import Grow from '@material-ui/core/Grow';

const CheckCoverHeader = ({ showHelpDescription, handleQuestionChange }) => {
  const { t } = useTranslation();
  const helpDescription = t('commonCardNumberDescription');
  const showHelp = () => {
    const growHelpDescription = (
      <Grow
        in={showHelpDescription}
        style={{ transformOrigin: '0 0 0' }}
        {...(showHelpDescription ? { timeout: 1000 } : {})}
      >
        <div className="row">
          <div className="col description">{helpDescription}</div>
        </div>
      </Grow>
    );

    return (
      <div className="row mb-2">
        <div className="col">
          <div className="row">
            <div className="col label">
              {t('commonCardNumberTitle')}
              {helpDescription && (
                <button
                  type="button"
                  className="btn btn-link"
                  onClick={() => handleQuestionChange(!showHelpDescription)}
                >
                  <HelpOutlineIcon />
                </button>
              )}
            </div>
          </div>
          {showHelpDescription && growHelpDescription}
        </div>
      </div>
    );
  };
  return (
    <div className="row">
      <div className="col-10">{showHelp()}</div>
    </div>
  );
};

CheckCoverHeader.propTypes = {
  showHelpDescription: PropTypes.bool.isRequired,
  handleQuestionChange: PropTypes.func.isRequired
};

export default CheckCoverHeader;
