import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Grow from '@material-ui/core/Grow';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

const ToolTipButton = props => {
  const [showTip, setShowTip] = useState(props.showToolTip);
  //setShowTip(showTip,props.showToolTip);
  const toolTipTextField = (
    <Grow
      in={showTip}
      style={{ transformOrigin: '0 0 0' }}
      {...(showTip ? { timeout: 1000 } : {})}
    >
      <div className="row">
        <div className="col toolTipText">{props.text}</div>
      </div>
    </Grow>
  );

  return (
    <>
      {props.text && !props.showToolTip && (
        <button
          type="button" 
          className="btn btn-link"
          onClick={() => setShowTip(!showTip)}
        >
          <HelpOutlineIcon />
        </button>
      )}
      {showTip && toolTipTextField}
    </>
  );
};

ToolTipButton.propTypes = {
  text: PropTypes.string,
  showToolTip: PropTypes.bool
};

ToolTipButton.defaultProps = {
  text: '',
  showToolTip:false
};

export default ToolTipButton;
