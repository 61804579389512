import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { event } from 'utils/analytics';
import { makeRecaptchaVisible } from 'utils/recaptcha/core';

import { cleanPolicyValidationResult } from 'actions/feature/policy';

import { GA_EVENTS, GA_ACTIONS } from 'constants/googleAnalytics';

import PolicyNumberComponent from 'components/PolicyNumberComponent';
import PolicyNumberLufthansaComponent from 'components/PolicyNumberLufthansaComponent/PolicyNumberLufthansaComponent';
import PolicyNumberValidationResultModal from 'components/PolicyNumberValidationResultModal';
import { appInsights } from 'ApplicationInsights';

const PolicyNumberQuestion = props => {
  const { t } = useTranslation();

  const showPolicyValidation = () =>
    !props.policyValidation.isValid && (
      <PolicyNumberValidationResultModal
        modalErrorMessage={props.policyValidation.message}
        modalContentLabel={t('commonCardNumberTitle')}
        closeModal={() => props.actions.cleanPolicyValidationResult()}
      />
    );

  React.useEffect(() => {
    props.actions.cleanPolicyValidationResult();
    makeRecaptchaVisible(true);
    return () => makeRecaptchaVisible(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnValidPolicyNumber = (...args) => {
    event(GA_EVENTS.QUESTION, GA_ACTIONS.ANSWER, {
      label: props.guideWireField
    });
    appInsights.trackEvent(
      {
        name:props.guideWireField
      },
      {
        EventCategory: GA_EVENTS.QUESTION,
        EventAction: GA_ACTIONS.ANSWER,
        EventLabel: props.guideWireField
      }
      )
	  
	  
    props.onValidPolicyNumber(...args);
  };

  return (
    <>
      {props.isLufthansa ? (
        <PolicyNumberLufthansaComponent
          id={props.id}
          userAnswer={props.userAnswer}
          onValidPolicyNumber={handleOnValidPolicyNumber}
          policiesList={props.policiesList}
          activePolicy={props.activePolicy}
          userInfo={props.userInfo}
          onChange={props.onPolicyInformationChange}
        />
      ) : (
        <PolicyNumberComponent
          id={props.id}
          userAnswer={props.userAnswer}
          onValidPolicyNumber={handleOnValidPolicyNumber}
          policiesList={props.policiesList}
          activePolicy={props.activePolicy}
          userInfo={props.userInfo}
          onChange={props.onPolicyInformationChange}
        />
      )}
      {props.policyValidation && showPolicyValidation()}
    </>
  );
};

PolicyNumberQuestion.propTypes = {
  id: PropTypes.number.isRequired,
  isLufthansa: PropTypes.bool.isRequired,
  onValidPolicyNumber: PropTypes.func.isRequired,
  onPolicyInformationChange: PropTypes.func,
  userInfo: PropTypes.shape({
    name: PropTypes.string.isRequired,
    surname: PropTypes.string.isRequired,
    dateOfBirth: PropTypes.string.isRequired
  }),
  userAnswer: PropTypes.shape({
    questionId: PropTypes.number,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    nextQuestionId: PropTypes.number
  }),
  policiesList: PropTypes.arrayOf(PropTypes.shape({})),
  activePolicy: PropTypes.shape({}),
  policyValidation: PropTypes.shape({
    isValid: PropTypes.bool,
    message: PropTypes.string
  }),
  actions: PropTypes.shape({
    cleanPolicyValidationResult: PropTypes.func.isRequired
  }).isRequired,
  guideWireField: PropTypes.string
};

PolicyNumberQuestion.defaultProps = {
  onPolicyInformationChange: () => {},
  policyValidation: null,
  userInfo: {
    name: '',
    surname: '',
    dateOfBirth: ''
  },
  userAnswer: undefined,
  policiesList: [],
  activePolicy: '',
  guideWireField: 'policyNumberQuestion'
};

const mapStateToProps = state => ({
  userInfo: state.userInfo,
  languageId: state.marketLanguages.selectedLanguage,
  policiesList: state.policiesList,
  policyValidation: state.policyValidation,
  activePolicy: state.activePolicy
});

const mapDispatchToProps = dispatch => ({
  actions: {
    ...bindActionCreators({ cleanPolicyValidationResult }, dispatch)
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PolicyNumberQuestion);
