/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';

const Button = props => (
  // eslint-disable-next-line react/button-has-type
  <button title={props.tooltip} data-testid={props.id} {...props}>
    {props.children || props.text}
  </button>
);

Button.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  type: PropTypes.oneOf(['submit', 'button', 'reset']),
  onClick: PropTypes.func,
  text: PropTypes.string,
  tooltip: PropTypes.string,
  disabled: PropTypes.bool
};

Button.defaultProps = {
  id: 'btn-button',
  className: '',
  text: '',
  onClick: () => {},
  type: 'button',
  tooltip: ''
};

export default Button;
