import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function claim(state = initialState.claimsList, action) {
  switch (action.type) {
    case types.SET_CLAIMS_LIST:
      return action.claims;
    default:
      return state;
  }
}
