import * as types from './actionTypes';

export function setNotInTriage() {
  return dispatch => {
    dispatch({ type: types.SET_NOT_TRIAGE });
  };
}

export function setInTriage() {
  return dispatch => {
    dispatch({ type: types.SET_IN_TRIAGE });
  };
}
