import { DEFAULT_LANGUAGES } from 'constants/constants';
import { getLanguageIdByCode, getLanguageIsoCode } from 'utils/languageUtils';
import i18n, { initI18Next } from '../i18n';
import * as types from './actionTypes';
import { beginAjaxCall, ajaxCallError } from './ajaxStatusActions';
import { handleApiError } from './alertsActions';

import { getEventsAction } from './prismicActions';

export const loadLabelsSuccess = labels => ({
  type: types.LOAD_LABELS_SUCCESS,
  labels
});

/**
 * @param {import('../ConfigurationProvider/withConfig').MyConfig} configuration
 * @param {string} languageId
 */
const getLanguageAndAvailableLanguages = (configuration, languageId) => {
  const languages = configuration.TRANSLATIONS;

  const selectedLanguage = languages[languageId]?.languageCode;

  const availableLanguages = Object.keys(languages)
    .map(id => languages[id]?.languageCode)
    .filter(Boolean);

  return [selectedLanguage, availableLanguages];
};

/**
 * @param {string} languageCode
 * @returns {Promise<(dispatch: import('redux').Dispatch) => void>}
 */
const loadLabelsFromResources = languageCode => async dispatch => {
  dispatch(beginAjaxCall());
  try {
    const labels = await (
      await fetch(`assets/locales/${languageCode}.json`)
    ).json();

    dispatch(loadLabelsSuccess(labels));
  } catch (error) {
    dispatch(handleApiError(error));
    dispatch(ajaxCallError());
  }
};

/**
 * @param {number} languageId
 * @returns {(
 *  dispatch: import('redux').Dispatch,
 *  getState: () => import('../reducers/initialState').default
 * ) => void}
 */
export const loadLabelsByLanguage = languageId => (dispatch, getState) => {
  const [languageCode, marketLanguages] = getLanguageAndAvailableLanguages(
    getState().configuration,
    languageId
  );

  initI18Next(languageCode, marketLanguages);
  return dispatch(loadLabelsFromResources(languageCode));
};

export function setSelectedLanguageSuccess(selectedLanguage) {
  return { type: types.SET_SELECTED_LANGUAGE_SUCCESS, selectedLanguage };
}

export function loadLanguagesAndDefaultLabels(market) {
  return (dispatch, getState) => {
    const browserLanguageId = getLanguageIdByCode(navigator.language);
    const translations = getState().configuration.TRANSLATIONS;
    let defaultLanguageId =
      browserLanguageId &&
      isBrowserLanguageSupported(browserLanguageId, translations)
        ? browserLanguageId
        : DEFAULT_LANGUAGES[market];
        if(window.location.pathname.toLowerCase().indexOf("lufthansade/dataprotection") >-1 ){
          let languageId=window.location.pathname.toLowerCase().replace('/olc_v3/welcome/lufthansade/dataprotection/','');
          if(languageId!=undefined && languageId !=''){
            defaultLanguageId=languageId;
          }
        }
    dispatch(setSelectedLanguageSuccess(defaultLanguageId));
    dispatch(loadLabelsByLanguage(defaultLanguageId, market));

    // it is to show the prsmic news when enter to a market
    dispatch(getPrismicNews(defaultLanguageId));
  };
}

export function setSelectedLanguage(languageId) {
  return dispatch => {
    dispatch(beginAjaxCall());
    dispatch(loadLabelsByLanguage(languageId));
    dispatch(setSelectedLanguageSuccess(languageId));
  };
}

export function changeLanguage(languageId) {
  return (dispatch, getState) => {
    const [languageCode] = getLanguageAndAvailableLanguages(
      getState().configuration,
      languageId
    );
    i18n.changeLanguage(languageCode);
    dispatch(beginAjaxCall());
    dispatch(loadLabelsFromResources(languageCode));
    dispatch(setSelectedLanguageSuccess(languageId));

    // it is to show the prismic news when the user change selected language
    dispatch(getPrismicNews(languageId));
  };
}

export function getPrismicNews(languageId) {
  return (dispatch, getState) => {
    const prismicLanguageCode = getLanguageIsoCode(languageId);
    dispatch(
      getEventsAction(getState().configuration.market, prismicLanguageCode)
    );
  };
}

const isBrowserLanguageSupported = (languageId, TRANSLATIONS) =>
  TRANSLATIONS[languageId];
