import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function userInfo(state = initialState.userInfo, action) {
  switch (action.type) {
    case types.SET_USER_INFO:
      return action.info;
    case types.SET_CURRENT_ACCOUNT:
      return { ...state, ...action.account };
    case types.CLEAN_CURRENT_ACCOUNT:
      return {};
    default:
      return state;
  }
}
