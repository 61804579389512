import { ERRORS } from 'constants/constants';

export const SET_ERROR_REQUESTED = '[app] set error request';
export const SET_ERROR_GENERIC = '[app] unhandled error set as generic';
export const SET_ERROR_NOTAUTHORIZE = '[app] set not authorize error';
export const SET_ERROR_FORBIDDEN = '[app] error forbidden action';
export const SET_ERROR = '[app] set error';

export const setGenericError = () => ({
  type: SET_ERROR_GENERIC,
  payload: { code: ERRORS.EXCEPTION_GENERIC }
});

export const setCardValidationError = () => ({
    type: SET_ERROR_GENERIC,
    payload: { code: ERRORS.FAILED_CARDVALIDATION }
});

export const setNotAuthorizeError = () => ({
  type: SET_ERROR_NOTAUTHORIZE,
  payload: { code: ERRORS.EXCEPTION_NOTAUTHORIZE }
});

export const setForbiddenError = error => ({
  type: SET_ERROR_FORBIDDEN,
  payload: error
});

export const setError = error => ({
  type: SET_ERROR_REQUESTED,
  payload: error
});
