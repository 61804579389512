// @ts-check
import {
  failOnTypeAlreadyDefinedCreator,
  failOnTypeUndefinedCreator,
  failOnProductionCode
} from './action-provider-collaborators';
import {
  get,
  set,
  startImplementations,
  ACTION_IMPLEMENTATIONS,
  AUTHENTICATION_ACTIONS,
  CLAIM_ACTIONS,
  LABEL_ACTIONS,
  PRISMIC_ACTIONS,
  ACTIVE_PAGE_ACTIONS,
  ALERT_ACTIONS,
  isCheckOnSet
} from './action-provider-constants';

let failOnTypeAlreadyDefined;
let failOnTypeUndefined;
class ActionProvider {
  constructor() {
    this[startImplementations]();
  }

  [startImplementations]() {
    this[isCheckOnSet] = true;
    this[ACTION_IMPLEMENTATIONS] = {};
    failOnTypeAlreadyDefined = failOnTypeAlreadyDefinedCreator(
      this[ACTION_IMPLEMENTATIONS]
    );
    failOnTypeUndefined = failOnTypeUndefinedCreator(
      this[ACTION_IMPLEMENTATIONS]
    );
  }

  [get](type) {
    failOnTypeUndefined(type);

    return this[ACTION_IMPLEMENTATIONS][type];
  }

  [set](type, impl) {
    if (this[isCheckOnSet]) {
      failOnTypeAlreadyDefined(type);
    }

    this[ACTION_IMPLEMENTATIONS][type] = impl;
  }

  /**
   * ONLY FOR TEST PROPOSES **__YOU SHALL NEVER USE IT__** IN PRODUCTION CODE!
   */
  setTestModeOn() {
    failOnProductionCode('setTestModeOn');

    this[isCheckOnSet] = false;
  }

  /**
   * ONLY FOR TEST PROPOSES **__YOU SHALL NEVER USE IT__** IN PRODUCTION CODE!
   */
  clearImplementations() {
    failOnProductionCode('clearImplementations');
    this[startImplementations]();
  }

  /** @return {typeof import('../../actions/authenticationActions')} */
  getAuthenticationActions() {
    return this[get](AUTHENTICATION_ACTIONS);
  }

  /** @param {typeof import('../../actions/authenticationActions')} */
  setAuthenticationActions(impl) {
    return this[set](AUTHENTICATION_ACTIONS, impl);
  }

  /** @return {typeof import('../../actions/claimActions')} */
  getClaimActions() {
    return this[get](CLAIM_ACTIONS);
  }

  /** @param {typeof import('../../actions/claimActions')} */
  setClaimActions(impl) {
    return this[set](CLAIM_ACTIONS, impl);
  }

  /** @return {typeof import('../../actions/labelActions')} */
  getLabelActions() {
    return this[get](LABEL_ACTIONS);
  }

  /** @param {typeof import('../../actions/labelActions')} */
  setLabelActions(impl) {
    return this[set](LABEL_ACTIONS, impl);
  }

  /** @param {typeof import('../../actions/prismicActions')} */
  setPrismicActions(impl) {
    return this[set](PRISMIC_ACTIONS, impl);
  }

  /** @return {typeof import('../../actions/prismicActions')} */
  getPrismicActions() {
    return this[get](PRISMIC_ACTIONS);
  }

  /** @param {typeof import('../../actions/alertsActions')} */
  setAlertActions(impl) {
    return this[set](ALERT_ACTIONS, impl);
  }

  /** @return {typeof import('../../actions/alertsActions')} */
  getAlertActions() {
    return this[get](ALERT_ACTIONS);
  }

  /** @param {typeof import('../../actions/activePageActions')} */
  setActivePageActions(impl) {
    return this[set](ACTIVE_PAGE_ACTIONS, impl);
  }

  /** @return {typeof import('../../actions/activePageActions')} */
  getActivePageActions() {
    return this[get](ACTIVE_PAGE_ACTIONS);
  }
}

export default new ActionProvider();

/**
 * @name getState
 * @function
 * @returns {any}
 */

/** @typedef {{getState: getState, dispatch: function}} Store; */
