import React from 'react';
import PropTypes from 'prop-types';

const styles = {
  button: 'thank-you__link d-block',
  baseButton: 'btn btn-link'
};
const LinkButton = props => {
  const getClassName = `${styles.baseButton} ${
    props.className ? props.className : styles.button
  }`;
  return (
    <button
      type="button"
      className={getClassName}
      onClick={() => props.action()}
    >
      {props.text}
    </button>
  );
};

LinkButton.propTypes = {
  text: PropTypes.string.isRequired,
  action: PropTypes.func.isRequired,
  className: PropTypes.string
};
LinkButton.defaultProps = { className: null };
export default LinkButton;
