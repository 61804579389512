import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { Row, Col } from 'components/shared/Table';
import { DateField } from 'components/shared/Forms';

import { event } from 'utils/analytics';

import { DATE_FORMAT } from 'constants/constants';
import { GA_EVENTS, GA_ACTIONS } from 'constants/googleAnalytics';
import { appInsights } from 'ApplicationInsights';

const DateInputQuestion = props => {
  const [dateValue, setDateValue] = useState('');

  const { t } = useTranslation();

  useEffect(() => {
    setDateValue(props.answer);
  }, [props.answer]);

  const onInputChange = value => {
    const textfield = value;
    if (moment(textfield).isValid()) {
      setDateValue(textfield);
      if (textfield.length >= 3 || (textfield != undefined && textfield.length == undefined && textfield.toString().length >= 3)) {
        const userAnswer = moment(textfield).format(DATE_FORMAT);
        event(GA_EVENTS.QUESTION, GA_ACTIONS.ANSWER, {
          label: props.guideWireField
        });
        appInsights.trackEvent(
          {
            name:props.guideWireField
          },
          {
            EventCategory: GA_EVENTS.QUESTION,
            EventAction: GA_ACTIONS.ANSWER,
            EventLabel: props.guideWireField
          }
          )
        props.onUserAnswer(props.id, userAnswer, props.nextGroup);
      }
    }
  };

  return (
    <Row>
      <Col className="col-12 col-md-6">
        <DateField
          onValueChange={onInputChange}
          placeholder={t('commonFormDatePlaceholder')}
          value={dateValue}
          name={`id-${props.id}`}
        />
      </Col>
    </Row>
  );
};

DateInputQuestion.propTypes = {
  answer: PropTypes.string,
  onUserAnswer: PropTypes.func.isRequired,
  nextGroup: PropTypes.number.isRequired,
  id: PropTypes.number.isRequired,
  guideWireField: PropTypes.string
};
DateInputQuestion.defaultProps = {
  answer: '',
  guideWireField: 'dateInputQuestion'
};

export default DateInputQuestion;
