export const ATTESTATIONS_ENV = {
  UAT: 'uat',
  PROD: 'prod'
};

export const PROD_NOMATCH = 'NO_RESULT';

// In FR the Title are mapping: Mr -> M; Mrs -> Mme; Ms -> Mlle
export const ATTESTATIONS_TITLE = {
  amexUK: {
    dev: {
      Dr: 39294,
      Mr: 39374,
      Mrs: 39375,
      Ms: 39376
    },
    uat: {
      Dr: 39294,
      Mr: 39374,
      Mrs: 39375,
      Ms: 39376
    },
    prod: {
      Dr: 36664,
      Mr: 36744,
      Mrs: 36745,
      Ms: 36746
    }
  },
  amexFR: {
    dev: {
      Mr: 946334,
      Mrs: 946338,
      Ms: 946336
    },
    uat: {
      Mr: 946334,
      Mrs: 946338,
      Ms: 946336
    },
    prod: {
      Mr: 771532,
      Mrs: 771536,
      Ms: 771534
    }
  },
  amexDE: {
    dev: {
      Dr: 78906,
      Mr: 78986,
      Mrs: 78987,
      Ms: 78988
    },
    uat: {
      Dr: 78906,
      Mr: 78986,
      Mrs: 78987,
      Ms: 78988
    },
    prod: {
      Dr: 44930,
      Mr: 45010,
      Mrs: 45011,
      Ms: 45012
    }
  },
  amexAT: {
    dev: {
      Dr: 77943,
      Mr: 78023,
      Mrs: 78024,
      Ms: 78025
    },
    uat: {
      Dr: 77943,
      Mr: 78023,
      Mrs: 78024,
      Ms: 78025
    },
    prod: {
      Dr: 44075,
      Mr: 44155,
      Mrs: 44156,
      Ms: 44157
    }
  }
};

export const ATTESTATION_CARDS = {
  amexUK: {
    uat: {
      'American Express Platinum Business Card 823': 620110,
      'American Express Platinum Business Card 856': 620122,
      'American Express Preferred Rewards Gold Credit Card 302': 620065,
      'American Express RED 600': 620242,
      'American Express RED 601': 620245,
      'American Express Rewards Credit Card 301': 620062,
      'American Express Business Travel Account 706': 620152,
      'American Express Corporate Gold Card 704': 620146,
      'American Express Corporate Green Card 703': 620143,
      'American Express Corporate Platinum Card 705': 620149,
      'American Express Corporate Purchasing Card 710': 620161,
      'American Express Meeting Card 709': 620158,
      'Blue Credit Card 101': 620041,
      'Blue Sky Card 151': 620053,
      'British Airways American Express Accelerating Business Card 124': 620212,
      'British Airways American Express Credit Card 121': 620044,
      'British Airways American Express Premium Card 122': 620047,
      'British Airways American Express Premium Plus Card 123': 620050,
      'British Airways American Express Business Travel Account 707': 620155,
      'British Airways American Express Corporate Card 701': 620137,
      'British Airways Corporate Card Plus 702': 620140,
      'Gold Business Charge Card 822': 620107,
      'Gold Business Charge Card 855': 620119,
      'Gold Business Charge Card 882': 620215,
      'Gold Credit Card 321': 620071,
      'Green Business Charge Card 821': 620104,
      'Green Credit Card 201': 620056,
      'Green Credit Card 205': 620059,
      'Marriott Bonvoy American Express Card 607': 620086,
      'Platinum Cashback Credit Card 422': 620080,
      'Platinum Credit Card 401': 620074,
      'Platinum Credit Card 421': 620077,
      'The American Express Business International Currency Card 543821': 620185,
      'The American Express Business International Currency Card 543881': 620218,
      'The American Express Business International Currency Card 545821': 620185,
      'The American Express Business International Currency Card 545881': 620218,
      'The American Express Card 801': 620134,
      'The American Express Card 871': 620239,
      'The American Express International Currency Card 543801': 620170,
      'The American Express International Currency Card 543861': 620191,
      'The American Express International Currency Card 543871': 620200,
      'The American Express International Currency Card 543891': 620227,
      'The American Express International Currency Card 545801': 620170,
      'The American Express International Currency Card 545861': 620191,
      'The American Express International Currency Card 545871': 620200,
      'The American Express International Currency Card 545891': 620227,
      'The Centurion Card 804': 620101,
      'The Centurion Card 874': 620131,
      'The Centurion International Currency Card 543804': 620176,
      'The Centurion International Currency Card 543874': 620209,
      'The Centurion International Currency Card 543894': 620236,
      'The Centurion International Currency Card 545804': 620176,
      'The Centurion International Currency Card 545874': 620209,
      'The Centurion International Currency Card 545894': 620236,
      'The Express Rewards Credit Card 606': 620083,
      'The Gold Card 872': 620125,
      'The Gold International Currency Card 543802': 620173,
      'The Gold International Currency Card 543862': 620194,
      'The Gold International Currency Card 543872': 620203,
      'The Gold International Currency Card 543892': 620230,
      'The Gold International Currency Card 545802': 620173,
      'The Gold International Currency Card 545862': 620194,
      'The Gold International Currency Card 545872': 620203,
      'The Gold International Currency Card 545892': 620230,
      'The Gold Business International Currency Card 822': 620188,
      'The Gold Business International Currency Card 882': 620221,
      'The Nectar Credit Card 602': 620248,
      'The Platinum Card 803': 620098,
      'The Platinum Card 853': 620116,
      'The Platinum Card 873': 620128,
      'The Platinum International Currency Card 543803': 620179,
      'The Platinum International Currency Card 543863': 620197,
      'The Platinum International Currency Card 543873': 620206,
      'The Platinum International Currency Card 543893': 620233,
      'The Platinum International Currency Card 545803': 620179,
      'The Platinum International Currency Card 545863': 620197,
      'The Platinum International Currency Card 545873': 620206,
      'The Platinum International Currency Card 545893': 620233,
      'The Preferred Rewards Gold Card 802': 620095,
      'Vitality American Express Credit Card 303': 620068
    },
    prod: {
      'American Express Platinum Business Card 823': 505025,
      'American Express Platinum Business Card 856': 505037,
      'American Express Preferred Rewards Gold Credit Card 302': 504980,
      'American Express RED 600': 505157,
      'American Express RED 601': 505160,
      'American Express Rewards Credit Card 301': 504977,
      'American Express Business Travel Account 706': 505067,
      'American Express Corporate Gold Card 704': 505061,
      'American Express Corporate Green Card 703': 505058,
      'American Express Corporate Platinum Card 705': 505064,
      'American Express Corporate Purchasing Card 710': 505076,
      'American Express Meeting Card 709': 505073,
      'Blue Credit Card 101': 504956,
      'Blue Sky Card 151': 504968,
      'British Airways American Express Accelerating Business Card 124': 505127,
      'British Airways American Express Credit Card 121': 504959,
      'British Airways American Express Premium Card 122': 504962,
      'British Airways American Express Premium Plus Card 123': 504965,
      'British Airways American Express Business Travel Account 707': 505070,
      'British Airways American Express Corporate Card 701': 505052,
      'British Airways Corporate Card Plus 702': 505055,
      'Gold Business Charge Card 822': 505022,
      'Gold Business Charge Card 855': 505034,
      'Gold Business Charge Card 882': 505130,
      'Gold Credit Card 321': 504986,
      'Green Business Charge Card 821': 505019,
      'Green Credit Card 201': 504971,
      'Green Credit Card 205': 504974,
      'Marriott Bonvoy American Express Card 607': 505001,
      'Platinum Cashback Credit Card 422': 504995,
      'Platinum Credit Card 401': 504989,
      'Platinum Credit Card 421': 504992,
      'The American Express Business International Currency Card 543821': 505100,
      'The American Express Business International Currency Card 543881': 505133,
      'The American Express Business International Currency Card 545821': 505100,
      'The American Express Business International Currency Card 545881': 505133,
      'The American Express Card 801': 505049,
      'The American Express Card 871': 505154,
      'The American Express International Currency Card 543801': 505085,
      'The American Express International Currency Card 543861': 505106,
      'The American Express International Currency Card 543871': 505115,
      'The American Express International Currency Card 543891': 505142,
      'The American Express International Currency Card 545801': 505085,
      'The American Express International Currency Card 545861': 505106,
      'The American Express International Currency Card 545871': 505115,
      'The American Express International Currency Card 545891': 505142,
      'The Centurion Card 804': 505016,
      'The Centurion Card 874': 505046,
      'The Centurion International Currency Card 543804': 505091,
      'The Centurion International Currency Card 543874': 505124,
      'The Centurion International Currency Card 543894': 505151,
      'The Centurion International Currency Card 545804': 505091,
      'The Centurion International Currency Card 545874': 505124,
      'The Centurion International Currency Card 545894': 505151,
      'The Express Rewards Credit Card 606': 504998,
      'The Gold Card 872': 505040,
      'The Gold International Currency Card 543802': 505088,
      'The Gold International Currency Card 543862': 505109,
      'The Gold International Currency Card 543872': 505118,
      'The Gold International Currency Card 543892': 505145,
      'The Gold International Currency Card 545802': 505088,
      'The Gold International Currency Card 545862': 505109,
      'The Gold International Currency Card 545872': 505118,
      'The Gold International Currency Card 545892': 505145,
      'The Gold Business International Currency Card 822': 505103,
      'The Gold Business International Currency Card 882': 505136,
      'The Nectar Credit Card 602': 505163,
      'The Platinum Card 803': 505013,
      'The Platinum Card 853': 505031,
      'The Platinum Card 873': 505043,
      'The Platinum International Currency Card 543803': 505094,
      'The Platinum International Currency Card 543863': 505112,
      'The Platinum International Currency Card 543873': 505121,
      'The Platinum International Currency Card 543893': 505148,
      'The Platinum International Currency Card 545803': 505094,
      'The Platinum International Currency Card 545863': 505112,
      'The Platinum International Currency Card 545873': 505121,
      'The Platinum International Currency Card 545893': 505148,
      'The Preferred Rewards Gold Card 802': 505010,
      'Vitality American Express Credit Card 303': 504983
    }
  },
  amexFR: {
    uat: {
      'Blue d American Express 800': 946849,
      'Carte Air France KLM - American Express Gold 832': 946744,
      'Carte Air France KLM - American Express Gold 839': 946747,
      'Carte Air France KLM - American Express Platinum 833': 946750,
      'Carte Air France KLM - American Express Platinum 834': 946753,
      'Carte Air France KLM - American Express Silver 831': 946756,
      'Carte Business American Express 821': 946774,
      'Carte Business American Express 851': 946777,
      'Carte Business American Express 854': 946780,
      'Carte Business American Express 857': 946783,
      'Carte Business American Express 875': 946786,
      'Carte Business Gold American Express 822': 946762,
      'Carte Business Gold American Express 852': 946765,
      'Carte Business Gold American Express 855': 946768,
      'Carte Business Gold American Express 876': 946771,
      'Carte Business Platinum American Express 823': 946789,
      'Carte Business Platinum American Express 853': 946792,
      'Carte Business Platinum American Express 856': 946795,
      'Carte Business Platinum American Express 877': 946798,
      'Carte Corporate Air France - American Express 723': 946759,
      'Carte Corporate Gold American Express 706': 946816,
      'Carte Corporate Green American Express 705': 946840,
      'Carte Corporate Platinum American Express 704': 946819,
      'Carte Green American Express 801': 946828,
      'Carte Green American Express 871': 946831,
      'Carte Platinum American Express 803': 946834,
      'Carte Platinum American Express 873': 946837,
      'Carte Pro Air France KLM American Express Gold  836': 946801,
      'Carte Pro Air France KLM American Express Platinum 837': 946804,
      'Carte Pro Air France KLM American Express Silver 835': 946807,
      'Centurion d American Express 804': 946810,
      'Centurion d American Express 874': 946813,
      'Compte Carte Voyages d Affaires American Express 707': 946858,
      'Compte Carte Voyage d Affaires Air France American Express 740': 946870,
      'Corporate Meeting Card American Express 708': 946861,
      'Gold Card American Express 802': 946822,
      'Gold Card American Express 872': 946825
    },
    prod: {
      'Blue d American Express 800': 772047,
      'Carte Air France KLM - American Express Gold 832': 771942,
      'Carte Air France KLM - American Express Gold 839': 771945,
      'Carte Air France KLM - American Express Platinum 833': 771948,
      'Carte Air France KLM - American Express Platinum 834': 771951,
      'Carte Air France KLM - American Express Silver 831': 771954,
      'Carte Business American Express 821': 771972,
      'Carte Business American Express 851': 771975,
      'Carte Business American Express 854': 771978,
      'Carte Business American Express 857': 771981,
      'Carte Business American Express 875': 771984,
      'Carte Business Gold American Express 822': 771960,
      'Carte Business Gold American Express 852': 771963,
      'Carte Business Gold American Express 855': 771966,
      'Carte Business Gold American Express 876': 771969,
      'Carte Business Platinum American Express 823': 771987,
      'Carte Business Platinum American Express 853': 771990,
      'Carte Business Platinum American Express 856': 771993,
      'Carte Business Platinum American Express 877': 771996,
      'Carte Corporate Air France - American Express 723': 771957,
      'Carte Corporate Gold American Express 706': 772014,
      'Carte Corporate Green American Express 705': 772038,
      'Carte Corporate Platinum American Express 704': 772017,
      'Carte Green American Express 801': 772026,
      'Carte Green American Express 871': 772029,
      'Carte Platinum American Express 803': 772032,
      'Carte Platinum American Express 873': 772035,
      'Carte Pro Air France KLM American Express Gold  836': 771999,
      'Carte Pro Air France KLM American Express Platinum 837': 772002,
      'Carte Pro Air France KLM American Express Silver 835': 772005,
      'Centurion d American Express 804': 772008,
      'Centurion d American Express 874': 772011,
      'Compte Carte Voyages d Affaires American Express 707': 772056,
      'Compte Carte Voyage d Affaires Air France American Express 740': 772068,
      'Corporate Meeting Card American Express 708': 772059,
      'Gold Card American Express 802': 772020,
      'Gold Card American Express 872': 772023
    }
  },
  amexDE: {
    uat: {
      'American Express Aurum Card 404': 79180,
      'American Express Blue Card 101': 79150,
      'American Express Blue Card 401': 79159,
      'American Express Blue Card 402': 79162,
      'American Express Blue Card 602': 79171,
      'American Express Business Gold Card 422': 79208,
      'American Express Business Gold Card 425': 79211,
      'American Express Business Green Card 421': 79214,
      'American Express Business Green Card 424': 79217,
      'American Express Business Green Card 427': 79220,
      'American Express Business Green Card 428': 79223,
      'American Express Business Platinum Card 423': 79226,
      'American Express Business Platinum Card 426': 79229,
      'American Express Centurion Card 408': 79105,
      'American Express Centurion Card 453': 79108,
      'American Express Corporate Card 705': 79184,
      'American Express Corporate Gold Card 706': 79187,
      'American Express Corporate Platinum Card 704': 79190,
      'American Express Gold Card 405': 79117,
      'American Express Gold Card 701': 79120,
      'American Express Golf Card 472': 79165,
      'American Express Green Card 403': 79126,
      'American Express Green Card 481': 79129,
      'American Express Green Card 506': 79132,
      'American Express Green Card 508': 79135,
      'American Express Platinum Card 407': 79138,
      'American Express Platinum Card 434': 79141,
      'American Express Platinum Card 452': 79144,
      'American Express Platinum Card 601': 79147,
      'BMW Card 504': 79093,
      'BMW Premium Card Carbon 503': 79096,
      'BMW Premium Card Silber 501': 79099,
      'BMW Premium Card Gold 502': 79102,
      'Comdirect Gold Card 406': 79177,
      'Comdirect Gold Card 433': 79111,
      'Comdirect Gold Card 505': 79114,
      'Commerzbank Gold Card 451': 79123,
      'MINI Excitement Card 507': 79090,
      'PAYBACK American Express Karte 510': 79168,
      'SIXT American Express Card Gold 509': 79174,
      'The Corporate BIP Card 710': 79202,
      'The Corporate BTA Card 707': 79193,
      'The Corporate Meeting Card 708': 79196,
      'The Corporate Purchasing Card 709': 79199,
      'The Germany Gold Companion Credit Card 301': 79156,
      'The Germany Green Companion Credit Card 201': 79153
    },
    prod: {
      'American Express Aurum Card 404': 45204,
      'American Express Blue Card 101': 45174,
      'American Express Blue Card 401': 45183,
      'American Express Blue Card 402': 45186,
      'American Express Blue Card 602': 45195,
      'American Express Business Gold Card 422': 45232,
      'American Express Business Gold Card 425': 45235,
      'American Express Business Green Card 421': 45238,
      'American Express Business Green Card 424': 45241,
      'American Express Business Green Card 427': 45244,
      'American Express Business Green Card 428': 45247,
      'American Express Business Platinum Card 423': 45250,
      'American Express Business Platinum Card 426': 45253,
      'American Express Centurion Card 408': 45129,
      'American Express Centurion Card 453': 45132,
      'American Express Corporate Card 705': 45208,
      'American Express Corporate Gold Card 706': 45211,
      'American Express Corporate Platinum Card 704': 45214,
      'American Express Gold Card 405': 45141,
      'American Express Gold Card 701': 45144,
      'American Express Golf Card 472': 45189,
      'American Express Green Card 403': 45150,
      'American Express Green Card 481': 45153,
      'American Express Green Card 506': 45156,
      'American Express Green Card 508': 45159,
      'American Express Platinum Card 407': 45162,
      'American Express Platinum Card 434': 45165,
      'American Express Platinum Card 452': 45168,
      'American Express Platinum Card 601': 45171,
      'BMW Card 504': 45117,
      'BMW Premium Card Carbon 503': 45120,
      'BMW Premium Card Silber 501': 45123,
      'BMW Premium Card Gold 502': 45126,
      'Comdirect Gold Card 406': 45201,
      'Comdirect Gold Card 433': 45135,
      'Comdirect Gold Card 505': 45138,
      'Commerzbank Gold Card 451': 45147,
      'MINI Excitement Card 507': 45112,
      'PAYBACK American Express Karte 510': 45192,
      'SIXT American Express Card Gold 509': 45198,
      'The Corporate BIP Card 710': 45226,
      'The Corporate BTA Card 707': 45217,
      'The Corporate Meeting Card 708': 45220,
      'The Corporate Purchasing Card 709': 45223,
      'The Germany Gold Companion Credit Card 301': 45180,
      'The Germany Green Companion Credit Card 201': 45177
    }
  },
  amexAT: {
    uat: {
      'American Express Aurum Card 804': 78133,
      'American Express BMW Card 825': 78145,
      'American Express BMW Gold Card 826': 78148,
      'American Express Centurion Card 807': 78142,
      'American Express Gold Card 805': 78136,
      'American Express Gold Card 833': 78157,
      'American Express Green Card 803': 78130,
      'American Express Green Card 832': 78154,
      'American Express Platinum Card 806': 78139,
      'American Express Platinum Card 834': 78160,
      'American Express SBS Gold Card 822': 78168,
      'American Express SBS Green Card 821': 78165,
      'Payback American Express Karte 802': 78127,
      'Payback American Express Karte 831': 78151
    },
    prod: {
      'American Express Aurum Card 804': 44265,
      'American Express BMW Card 825': 44277,
      'American Express BMW Gold Card 826': 44280,
      'American Express Centurion Card 807': 44274,
      'American Express Gold Card 805': 44268,
      'American Express Gold Card 833': 44289,
      'American Express Green Card 803': 44262,
      'American Express Green Card 832': 44286,
      'American Express Platinum Card 806': 44271,
      'American Express Platinum Card 834': 44292,
      'American Express SBS Gold Card 822': 44300,
      'American Express SBS Green Card 821': 44297,
      'Payback American Express Karte 802': 44259,
      'Payback American Express Karte 831': 44283
    }
  }
};

export const MAP_ATTESTATIONS = {
  // UK - Mappeds
  401823: 'American Express Platinum Business Card 823',
  401856: 'American Express Platinum Business Card 856',
  401302: 'American Express Preferred Rewards Gold Credit Card 302',
  401600: 'American Express RED 600',
  401601: 'American Express RED 601',
  401301: 'American Express Rewards Credit Card 301',
  403706: 'American Express Business Travel Account 706',
  403704: 'American Express Corporate Gold Card 704',
  403703: 'American Express Corporate Green Card 703',
  403705: 'American Express Corporate Platinum Card 705',
  403710: 'American Express Corporate Purchasing Card 710',
  403709: 'American Express Meeting Card 709',
  401101: 'Blue Credit Card 101',
  401151: 'Blue Sky Card 151',
  401124: 'British Airways American Express Accelerating Business Card 124',
  401121: 'British Airways American Express Credit Card 121',
  401122: 'British Airways American Express Premium Card 122',
  401123: 'British Airways American Express Premium Plus Card 123',
  403707: 'British Airways American Express� Business Travel Account 707',
  403701: 'British Airways American Express� Corporate Card 701',
  403702: 'British Airways Corporate Card Plus 702',
  401822: 'Gold Business Charge Card 822',
  401855: 'Gold Business Charge Card 855',
  401882: 'Gold Business Charge Card 882',
  401321: 'Gold Credit Card 321',
  401821: 'Green Business Charge Card 821',
  401201: 'Green Credit Card 201',
  401205: 'Green Credit Card 205',
  401607: 'Marriott Bonvoy� American Express� Card 607',
  401422: 'Platinum Cashback Credit Card 422',
  401401: 'Platinum Credit Card 401',
  401421: 'Platinum Credit Card 421',
  545821: 'The American Express Business International Currency Card 545821',
  545881: 'The American Express Business International Currency Card 545881',
  543821: 'The American Express Business International Currency Card 543821',
  543881: 'The American Express Business International Currency Card 543881',
  401801: 'The American Express Card 801',
  401871: 'The American Express Card 871',
  545801: 'The American Express International Currency Card 543801',
  545861: 'The American Express International Currency Card 543861',
  545871: 'The American Express International Currency Card 543871',
  545891: 'The American Express International Currency Card 543891',
  543801: 'The American Express International Currency Card 545801',
  543861: 'The American Express International Currency Card 545861',
  543871: 'The American Express International Currency Card 545871',
  543891: 'The American Express International Currency Card 545891',
  401804: 'The Centurion Card 804',
  401874: 'The Centurion Card 874',
  545804: 'The Centurion International Currency Card 545804',
  545874: 'The Centurion International Currency Card 545874',
  545894: 'The Centurion International Currency Card 545894',
  543804: 'The Centurion International Currency Card 543804',
  543874: 'The Centurion International Currency Card 543874',
  543894: 'The Centurion International Currency Card 543894',
  401606: 'The Express Rewards Credit Card 606',
  401872: 'The Gold Card 872',
  545802: 'The Gold International Currency Card 545802',
  545862: 'The Gold International Currency Card 545862',
  545872: 'The Gold International Currency Card 545872',
  545892: 'The Gold International Currency Card 545892',
  543802: 'The Gold International Currency Card 543802',
  543862: 'The Gold International Currency Card 543862',
  543872: 'The Gold International Currency Card 543872',
  543892: 'The Gold International Currency Card 543892',
  545822: 'The Gold Business International Currency Card 822',
  545882: 'The Gold Business International Currency Card 882',
  401602: 'The Nectar Credit Card 602',
  401803: 'The Platinum Card 803',
  401853: 'The Platinum Card 853',
  401873: 'The Platinum Card 873',
  543803: 'The Platinum International Currency Card 543803',
  543863: 'The Platinum International Currency Card 543863',
  543873: 'The Platinum International Currency Card 543873',
  543893: 'The Platinum International Currency Card 543893',
  545803: 'The Platinum International Currency Card 545803',
  545863: 'The Platinum International Currency Card 545863',
  545873: 'The Platinum International Currency Card 545873',
  545893: 'The Platinum International Currency Card 545893',
  401802: 'The Preferred Rewards Gold Card 802',
  401303: 'Vitality American Express Credit Card 303',
  // -----------------------------------
  // FR - Mappeds
  441800: 'Blue d American Express 800',
  441832: 'Carte Air France KLM - American Express Gold 832',
  441839: 'Carte Air France KLM - American Express Gold 839',
  441833: 'Carte Air France KLM - American Express Platinum 833',
  441834: 'Carte Air France KLM - American Express Platinum 834',
  441831: 'Carte Air France KLM - American Express Silver 831',
  441821: 'Carte Business American Express 821',
  441851: 'Carte Business American Express 851',
  441854: 'Carte Business American Express 854',
  441857: 'Carte Business American Express 857',
  441875: 'Carte Business American Express 875',
  441822: 'Carte Business Gold American Express 822',
  441852: 'Carte Business Gold American Express 852',
  441855: 'Carte Business Gold American Express 855',
  441876: 'Carte Business Gold American Express 876',
  441823: 'Carte Business Platinum American Express 823',
  441853: 'Carte Business Platinum American Express 853',
  441856: 'Carte Business Platinum American Express 856',
  441877: 'Carte Business Platinum American Express 877',
  443723: 'Carte Corporate Air France - American Express 723',
  443706: 'Carte Corporate Gold American Express 706',
  443705: 'Carte Corporate Green American Express 705',
  443704: 'Carte Corporate Platinum American Express 704',
  441801: 'Carte Green American Express 801',
  441871: 'Carte Green American Express 871',
  441803: 'Carte Platinum American Express 803',
  441873: 'Carte Platinum American Express 873',
  441836: 'Carte Pro Air France KLM American Express Gold  836',
  441837: 'Carte Pro Air France KLM American Express Platinum 837',
  441835: 'Carte Pro Air France KLM American Express Silver 835',
  441804: 'Centurion d American Express 804',
  441874: 'Centurion d American Express 874',
  443707: 'Compte Carte Voyages d Affaires American Express 707',
  443740: 'Compte Carte Voyage d Affaires Air France American Express 740',
  443708: 'Corporate Meeting Card American Express 708',
  441802: 'Gold Card American Express 802',
  441872: 'Gold Card American Express 872',
  // -----------------------------------
  // DE - Mappeds
  421404: 'American Express Aurum Card 404',
  421101: 'American Express Blue Card 101',
  421401: 'American Express Blue Card 401',
  421402: 'American Express Blue Card 402',
  421602: 'American Express Blue Card 602',
  421422: 'American Express Business Gold Card 422',
  421425: 'American Express Business Gold Card 425',
  421421: 'American Express Business Green Card 421',
  421424: 'American Express Business Green Card 424',
  421427: 'American Express Business Green Card 427',
  421428: 'American Express Business Green Card 428',
  421423: 'American Express Business Platinum Card 423',
  421426: 'American Express Business Platinum Card 426',
  421408: 'American Express Centurion Card 408',
  421453: 'American Express Centurion Card 453',
  423705: 'American Express Corporate Card 705',
  423706: 'American Express Corporate Gold Card 706',
  423704: 'American Express Corporate Platinum Card 704',
  421405: 'American Express Gold Card 405',
  421701: 'American Express Gold Card 701',
  421472: 'American Express Golf Card 472',
  421403: 'American Express Green Card 403',
  421481: 'American Express Green Card 481',
  421506: 'American Express Green Card 506',
  421508: 'American Express Green Card 508',
  421407: 'American Express Platinum Card 407',
  421434: 'American Express Platinum Card 434',
  421452: 'American Express Platinum Card 452',
  421601: 'American Express Platinum Card 601',
  421504: 'BMW Card 504',
  421503: 'BMW Premium Card Carbon 503',
  421501: 'BMW Premium Card Silber 501',
  421502: 'BMW Premium Card Gold 502',
  421406: 'Comdirect Gold Card 406',
  421433: 'Comdirect Gold Card 433',
  421505: 'Comdirect Gold Card 505',
  421451: 'Commerzbank Gold Card 451',
  421507: 'MINI Excitement Card 507',
  421510: 'PAYBACK American Express Karte 510',
  421509: 'SIXT American Express Card Gold 509',
  423710: 'The Corporate BIP Card 710',
  423707: 'The Corporate BTA Card 707',
  423708: 'The Corporate Meeting Card 708',
  423709: 'The Corporate Purchasing Card 709',
  421301: 'The Germany Gold Companion Credit Card 301',
  421201: 'The Germany Green Companion Credit Card 201',
  // -----------------------------------
  // AT - Mappeds
  411804: 'American Express Aurum Card 804',
  411825: 'American Express BMW Card 825',
  411826: 'American Express BMW Gold Card 826',
  411807: 'American Express Centurion Card 807',
  411805: 'American Express Gold Card 805',
  411833: 'American Express Gold Card 833',
  411803: 'American Express Green Card 803',
  411832: 'American Express Green Card 832',
  411806: 'American Express Platinum Card 806',
  411834: 'American Express Platinum Card 834',
  411822: 'American Express SBS Gold Card 822',
  411821: 'American Express SBS Green Card 821',
  411802: 'Payback American Express Karte 802',
  411831: 'Payback American Express Karte 831'
};
