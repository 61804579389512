/* eslint-disable react/no-unescaped-entities */
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'components/shared/Table';
import { pageview } from 'utils/analytics';
import { getRoute } from 'utils/routeHelper';
import { isMarketLufthansa } from 'utils/urlHelpers';
import { appInsights } from 'ApplicationInsights';

export const CookiesPage = () => {
  const { t } = useTranslation();

  useEffect(() => {
    pageview({ title: 'Cookies', page: getRoute('cookies') });
    appInsights.trackPageView(
      {
        name:'Cookies',
        uri:getRoute('cookies')
      }
    )
  }, []);

  const titlePage = text => <h4>{t(text)}</h4>;
  const titleSection = text => <h6>{t(text)}</h6>;

  const listSection = listParagraph => {
    return listParagraph.map(paragraph => <li> {t(paragraph)} </li>);
  };
  const listLinksSection = listParagraph => {
    return listParagraph.map(paragraph => (
      <li>
        <a href={t(paragraph)}> {t(paragraph)} </a>
      </li>
    ));
  };
  const listWithAnchorLinks = listParagraph => {
    return listParagraph.map(paragraph => (
      <li>
        <span
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: t(paragraph) }}
        />
      </li>
    ));
  };
  const paragraphSection = text => <p className="content">{t(text)}</p>;

  const titleSectionWithLink = (text, link) => (
    <h6>
      {t(text)}
      <a href={t(link)}>{t(link)}</a>
    </h6>
  );

  const paragraphWithLink = (text, link) => (
    <p>
      {t(text)}
      <a href={t(link)}>{t(link)}</a>
    </p>
  );

  const listSection1 = [
    '[cookies_site] section1_category1',
    '[cookies_site] section1_category2',
    '[cookies_site] section1_category3',
    '[cookies_site] section1_category4',
    '[cookies_site] section1_category5',
    '[cookies_site] section1_category6',
    '[cookies_site] section1_category7'
  ];

  const listSection2 = [
    '[cookies_site] section2_text_2',
    '[cookies_site] section2_text_3'
  ];

  const listLinksSection3 = [
    '[cookies_site] section3_browser_1',
    '[cookies_site] section3_browser_2',
    '[cookies_site] section3_browser_3',
    '[cookies_site] section3_browser_4',
    '[cookies_site] section3_browser_5'
  ];

  return (
    <Row className="no-gutters cookies-page-box">
      <div className="cookies-page">
        <Col className="col">
          {titlePage('cookiesPageTitle')}
          {paragraphSection('cookiesPageSubTitle')}
          <br />
          <div>
            {titleSection('[cookies_site] section1_title')}
            {paragraphSection('[cookies_site] section1_text_1')}
            {paragraphSection('[cookies_site] section1_text_2')}
            {listSection(listSection1)}
            <br />
            {paragraphSection('[cookies_site] section1_text_3')}
            {paragraphSection('[cookies_site] section1_text_4')}
            {paragraphSection('[cookies_site] section1_text_5')}
          </div>
          <div>
            {titleSectionWithLink(
              '[cookies_site] section2_title',
              '[cookies_site] section2_title_url'
            )}
            {paragraphSection('[cookies_site] section2_text_1')}
            {listSection(listSection2)}
            <br />
            <div id="ot-sdk-cookie-policy" />
          </div>
          <div>
            {titleSection('[cookies_site] section3_title')}
            {paragraphSection('[cookies_site] section3_text_1')}
            {paragraphSection('[cookies_site] section3_text_2')}
            {isMarketLufthansa() == false ? listLinksSection(listLinksSection3) : listWithAnchorLinks(listLinksSection3)}
            <br />
          </div>
          <div>
            {titleSection('[cookies_site] section4_title')}
            {paragraphSection('[cookies_site] section4_text_1')}
          </div>
          <div>
            {titleSection('[cookies_site] section5_title')}
            {paragraphWithLink(
              '[cookies_site] section5_text_1',
              '[cookies_site] section5_url'
            )}
          </div>
        </Col>
      </div>
    </Row>
  );
};

export default CookiesPage;
