import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import { gotoMyClaims } from 'actions/activePageActions';
import { makeRecaptchaVisible } from 'utils/recaptcha/core';
import { obfuscateCard, isCardAcceptableNumber } from 'utils/CardHelper';
import { event } from 'utils/analytics';

import { MAX_CARDS } from 'constants/constants';
import { GA_EVENTS, GA_ACTIONS } from 'constants/googleAnalytics';

import { cleanCardInformation } from 'actions/feature/card';

import Select from 'components/shared/Select/CreatableSelectComponent';
import { Row, Col } from 'components/shared/Table';
import CardNumberCustomModal from './CardNumberCustomModal';
import './_CardNumberQuestion.scss';
import { appInsights } from 'ApplicationInsights';


export const CardNumberQuestion = props => {
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState('');
  const [isInputValid, setIsInputValid] = useState(true);
  const [selectedCard, setSelectedCard] = useState(null);
  const [options, setOptions] = useState(props.cardList);
  const [showCardComponentLoading, setShowCardComponentLoading] = useState(
    false
  );
  const [showNotFound, setShowNotFound] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  React.useEffect(() => {
    props.actions.cleanCardInformation();
    makeRecaptchaVisible(true);
    return () => makeRecaptchaVisible(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  React.useEffect(() => {
    if (props.validation && !props.validation.isValid) {
      if (props.validation.code === 404) {
        setShowNotFound(true);
      } else {
        setErrorMessage(props.validation.message);
      }
    } else {
      setErrorMessage('');
      setInputValue('');
    }
  }, [props.validation]);

  React.useEffect(() => {
    if (props.validatedCard && props.cardList.length === 0) {
      setOptions([...props.cardList, props.validatedCard]);
    } else {
      setOptions([...props.cardList]);
    }
  }, [props.cardList, props.validatedCard]);

  React.useEffect(() => {
    if (!selectedCard) {
      if (props.activeCardGencode) onSelectCard(false, props.activeCardGencode);
    } else {
      if (selectedCard.isCard && props.activeCardGencode) {
        setSelectedCard({ isCard: false, number: props.activeCardGencode });
      }
      if (
        !selectedCard.isCard &&
        props.activeCardGencode !== selectedCard.number
      ) {
        onSelectCard(false, props.activeCardGencode);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.activeCardGencode]);

  const openNotRecognisedCard = () => {
    setErrorMessage('cardNumberNotRecognised');
    setShowNotFound(false);
  };

  const openPhoneAXAModal = () => {
    setErrorMessage('cardPhoneAXA');
    setShowNotFound(false);
  };

  const onValidateInputCardNumber = value => {
    if (props.cardList.length >= MAX_CARDS) return false;

    return isCardAcceptableNumber(value);
  };
  
  const handleOnCardChange = (card, _action) => {
    if (card) {
      if (options.some(c => c.value === card.value)) {
        onSelectCard(false, card.value);
      } else {
        onSelectCard(true, card.value);
      }
      setIsInputValid(true);
      setShowCardComponentLoading(false);
    } else {
      onSelectCard(null);
    }
  };

  const handleOnInputChange = value => {
    if (!value) {
      setShowCardComponentLoading(false);
    } else {
      setShowCardComponentLoading(!onValidateInputCardNumber(value));
    }

    setIsInputValid(!value || onValidateInputCardNumber(value));
    setInputValue(value);

    return value;
  };

  const onSelectCard = (isCard, number) => {
    setSelectedCard({ isCard, number });

    if (isCard) {
      trackGA();
      props.onCardChange(number, number.slice(-4), true, props.id);
    } else {
      const card = options.find(c => c.value === number);
      if (card) {
        trackGA();
        props.onCardChange(card.value, card.label, false, props.id);
      }
    }
  };

  const trackGA = () => {
    event(GA_EVENTS.QUESTION, GA_ACTIONS.ANSWER, {
      label: props.guideWireField
    });
    appInsights.trackEvent(
      {
        name:props.guideWireField
      },
      {
        EventCategory: GA_EVENTS.QUESTION,
        EventAction: GA_ACTIONS.ANSWER,
        EventLabel: props.guideWireField
      }
      )
  };

  const renderLabelHelper = () => {
    let label = 'cardComponentHelpEmpty';

    if (props.cardList.length > 0 && props.cardList.length < MAX_CARDS) {
      label = 'cardComponentHelpWithCards';
    } else if (props.cardList.length >= MAX_CARDS) {
      label = 'cardComponentHelpFull';
    }

    return t(label);
  };

  const cardComponentLabelForLoadingAndNoOptions =
    props.cardList.length > 0
      ? t('cardComponentNoMatches')
      : t('cardComponentNoCards');

  const shouldShowErrorModal = showNotFound || errorMessage !== '';

  const closeModal = () => {
    setErrorMessage('');
    props.actions.gotoMyClaims();
  };

  return (
    <div id="card" className="credit-card">
      <Row className="my-2">
        <Col>
          <span className="card-help-label">{renderLabelHelper()}</span>
        </Col>
      </Row>

      <Select
        isLoading={showCardComponentLoading}
        loadingMessage={cardComponentLabelForLoadingAndNoOptions}
        noOptionsMessage={cardComponentLabelForLoadingAndNoOptions}
        className="product-number-card"
        onChange={handleOnCardChange}
        placeholder={t('cardComponentPlaceholder')}
        options={options}
        value={
          selectedCard && options.find(c => c.value === selectedCard.number)
        }
        isInputValueValid={isInputValid}
        inputValue={inputValue}
        name="card-comp"
        createNewOptionText={t('commonAddNew')}
        onValidateOption={onValidateInputCardNumber}
        onInputChange={handleOnInputChange}
        maxLenght={15}
      />

      {shouldShowErrorModal && (
        <CardNumberCustomModal
          modalErrorMessage={errorMessage}
          modalContentLabel=""
          closeModal={closeModal}
          notFound={showNotFound}
          openPhoneAXAModal={openPhoneAXAModal}
          openNotRecognisedCard={openNotRecognisedCard}
        />
      )}
    </div>
  );
};

CardNumberQuestion.propTypes = {
  id: PropTypes.number.isRequired,
  activeCardGencode: PropTypes.string,
  onCardChange: PropTypes.func.isRequired,
  cardList: PropTypes.arrayOf(PropTypes.shape({})),
  validation: PropTypes.shape({
    isValid: PropTypes.bool,
    message: PropTypes.string,
    code: PropTypes.number
  }),
  validatedCard: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string
  }),
  actions: PropTypes.shape({
    cleanCardInformation: PropTypes.func,
    gotoMyClaims: PropTypes.func
  }).isRequired,
  guideWireField: PropTypes.string
};
CardNumberQuestion.defaultProps = {
  cardList: [],
  activeCardGencode: null,
  validation: null,
  validatedCard: null,
  guideWireField: 'cardNumberQuestion'
};

const mapStateToProps = state => ({
  cardList: state.cardsList.map(c => ({
    label: obfuscateCard(c.obfuscated),
    value: c.number
  })),
  activeCardGencode: state.activeCard,
  validatedCard: !isEmpty(state.card.gencodeCard)
    ? {
      label: obfuscateCard(state.card.gencodeCard.obfuscated),
      value: state.card.gencodeCard.value
    }
    : null,
  validation: state.card.validation,
  currentStep: state.currentStep
});

const mapDispatchToProps = dispatch => ({
  actions: {
    ...bindActionCreators({ cleanCardInformation, gotoMyClaims }, dispatch)
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(CardNumberQuestion);
