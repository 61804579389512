import { REGEX } from 'constants/constants';

export function validateEmail(email) {
  return REGEX.VALIDATE_EMAIL.test(String(email).toLowerCase());
}

export function validatePersonalNumber(personalNumber) {
  return REGEX.SWEDISH_PERSONALNUMBER.test(String(personalNumber));
}

export function isValidPersonalNumberNorway(personalNumber) {
  const digit1 = parseInt(personalNumber.substr(0, 1), 10);
  const digit2 = parseInt(personalNumber.substr(1, 1), 10);
  const digit3 = parseInt(personalNumber.substr(2, 1), 10);
  const digit4 = parseInt(personalNumber.substr(3, 1), 10);
  const digit5 = parseInt(personalNumber.substr(4, 1), 10);
  const digit6 = parseInt(personalNumber.substr(5, 1), 10);
  const digit7 = parseInt(personalNumber.substr(6, 1), 10);
  const digit8 = parseInt(personalNumber.substr(7, 1), 10);
  const digit9 = parseInt(personalNumber.substr(8, 1), 10);
  const digit10 = parseInt(personalNumber.substr(9, 1), 10);
  const digit11 = parseInt(personalNumber.substr(10, 1), 10);

  let k1 =
    digit1 * 3 +
    digit2 * 7 +
    digit3 * 6 +
    digit4 * 1 +
    digit5 * 8 +
    digit6 * 9 +
    digit7 * 4 +
    digit8 * 5 +
    digit9 * 2;
  k1 %= 11;
  if (k1 !== 0) {
    k1 = 11 - k1;
  }
  if (k1 === 10) return false;

  let k2 =
    digit1 * 5 +
    digit2 * 4 +
    digit3 * 3 +
    digit4 * 2 +
    digit5 * 7 +
    digit6 * 6 +
    digit7 * 5 +
    digit8 * 4 +
    digit9 * 3 +
    k1 * 2;
  k2 %= 11;
  if (k2 !== 0) {
    k2 = 11 - k2;
  }
  if (k2 === 10) return false;
  if (k1 === digit10 && k2 === digit11) return true;
  return false;
}

export function validateCard(cardNumber) {
  const re = REGEX.AMEX_CARD;
  return re.test(String(cardNumber).toLowerCase());
}

export function validateOnlyDigits(value) {
  return REGEX.ONLY_DIGITS.test(String(value).toLowerCase());
}

export function validateIsAlphanumeric(value) {
  return REGEX.ALPHANUMERIC.test(String(value));
}

export function validateExistsAndIsNotEmpty(value) {
  return value ? validateIsAlphanumericNotEmpty(value) : false;
}

/**
 * Attention! If value is null the result will be true
 * @param {*} value
 */
export function validateIsAlphanumericNotEmpty(value) {
  const result = REGEX.ALPHANUMERIC_NOT_BLANK.test(String(value));
  return result;
}

export function validateExistsAndIsAlphanumericNotEmpty(value) {
  const result = value
    ? REGEX.ALPHANUMERIC_NOT_BLANK.test(String(value))
    : false;
  return result;
}

export function validateIsDangerousCharacters(value) {
  return REGEX.DANGEROUS_CHARACTERS.test(String(value));
}

export function sanitizeValue(value) {
  return value.replace(REGEX.DANGEROUS_CHARACTERS, '');
}

/**
 * This method only exists because in DEV and UAT i18Next returns the key
 * if there is no translation for that term.
 * In PROD, it returns an empty string
 * @param {string} key the PO Editor term Key
 * @param {string} value the PO Editor text after translated
 */
export function sanitizeTranslation(key, value) {
  if (!value) return value;
  return key === value ? '' : value;
}
