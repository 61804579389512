import React from 'react';

import { Consumer } from './configurationContext';
/**
 *
 * @param {import('react').Component} WrappedComponent
 * @returns {import('./withConfig').withConfigReturnFunction}
 *
 */
const withConfig = WrappedComponent => props => (
  <Consumer>
    {config => <WrappedComponent {...props} config={config} />}
  </Consumer>
);

export default withConfig;
