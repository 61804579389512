import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { CARD_TYPES } from 'constants/constants';

const Type = ({ value }) => {
  const { t } = useTranslation();

  return value && <p>{t(CARD_TYPES[value])}</p>;
};

Type.propTypes = {
  value: PropTypes.number
};

Type.defaultProps = {
  value: undefined
};

export default Type;
