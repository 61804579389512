import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Row, Col } from 'components/shared/Table';
import { TextField, DateField } from 'components/shared/Forms';
import PersonRelationship from 'components/ContactsQuestion/Shared/PersonRelationship';
import YesNo from 'components/ContactsQuestion/Shared/YesNo';
import DeleteIconButton from 'components/shared/DeleteButton/DeleteIconButton';
import { validateField, validateForm } from 'utils/formValidationHelper';
import { convertSwedishPersonalNumberToDate } from 'utils/convertHelper';

import {
  schemaValidation,
  maxLengthConstants
} from './AdditionalClaimantSwedenSchemaValidation';

function AdditionalClaimantSweden(props) {
  const person = {
    id: props.contact.id,
    isValid: props.contact.isValid,
    firstName: props.contact.firstName,
    surnames: props.contact.surnames,
    dob: props.contact.dob,
    personalNumber: props.contact.personalNumber,
    relationship: props.contact.relationship,
    relationshipDescription: props.contact.relationshipDescription,
    isSecondaryCardHolder: props.contact.isSecondaryCardHolder,
    isCoLiving: props.contact.isCoLiving
  };
  const [errorFields, setErrorFields] = useState({});
  const [formValues, setFormValues] = useState(person);
  const { t } = useTranslation();

  const validateAndUpdate = values => {
    const clone = { ...values, isValid: isFormValid(values) };
    setFormValues(clone);
    props.onUpdateContact(clone);
  };

  const onDateFieldChange = (name, value) => {
    const clone = {
      ...formValues,
      [name]: value
    };

    validateAndUpdate(clone);
  };

  const handleTextFieldChange = event => {
    setFormValues({
      ...formValues,
      [event.target.name]: event.target.value
    });
  };

  const onRelationshipChange = (name, key, value) => {
    const clone = {
      ...formValues,
      [name]: key,
      [`${name}Description`]: value
    };

    validateAndUpdate(clone);
  };

  const isFormValid = values => {
    const result = validateForm(schemaValidation, values);
    return result.isValid;
  };
  const handleFieldFormValueChange = (name, value) => {
    const clone = {
      ...formValues,
      [name]: value
    };
    validateAndUpdate(clone);
  };

  const handleValidation = (fieldName, value) => {
    const result = validateField(schemaValidation, fieldName, value);
    setErrorFields({
      ...errorFields,
      ...result.errorFields
    });
  };

  const handleValidationPNandDob = (_fieldName, value) => {
    let validation;
    const personaNumberValidation = validatePersonalNumber(value);
    validation = { ...personaNumberValidation.errorFields };
    if (value) {
      const dobValidation = validateDoB(
        convertSwedishPersonalNumberToDate(value)
      );
      validation = {
        ...personaNumberValidation.errorFields,
        ...dobValidation.errorFields
      };
    }

    setErrorFields({
      ...errorFields,
      ...validation
    });
  };

  const validateDoB = date => validateField(schemaValidation, 'dob', date);

  const validatePersonalNumber = number =>
    validateField(schemaValidation, 'personalNumber', number);

  const defineSwedishDateOfBirthByPersonalNumber = event => {
    if (event.target.value) {
      const personaNumberValidation = validatePersonalNumber(
        event.target.value
      );

      if (personaNumberValidation.isValid) {
        const result = convertSwedishPersonalNumberToDate(event.target.value);
        setFormValues({
          ...formValues,
          dob: result
        });
      }
    }
  };

  const isDoBDisabled =
    formValues.personalNumber && !errorFields.personalNumber?.hasError;

  return (
    <>
      {props.isRemovable && (
        <Row className="d-flex flex-row-reverse">
          <Col className="col-12 col-md-6">
            <DeleteIconButton onClick={props.onRemoveContact} />
          </Col>
        </Row>
      )}
      <Row>
        <Col className="col-12 col-md-6">
          <TextField
            label={t('commonFormUserNameLabel')}
            name="firstName"
            value={formValues.firstName}
            onValidate={handleValidation}
            onValueChange={handleTextFieldChange}
            errorFields={errorFields}
            ariaDescribedby={t('commonFormUserNameLabel')}
            required
          />
        </Col>
      </Row>
      <Row>
        <Col className="col-12 col-md-6">
          <TextField
            label={t('commonFormSurnameLabel')}
            name="surnames"
            value={formValues.surnames}
            onValidate={handleValidation}
            onValueChange={handleTextFieldChange}
            errorFields={errorFields}
            ariaDescribedby={t('commonFormSurnameLabel')}
            required
          />
        </Col>
      </Row>
      <Row>
        <Col className="col-12 col-md-6">
          <TextField
            value={formValues.personalNumber}
            name="personalNumber"
            onBlur={defineSwedishDateOfBirthByPersonalNumber}
            maxLength={maxLengthConstants.personalNumber}
            onValueChange={handleTextFieldChange}
            onValidate={handleValidationPNandDob}
            errorFields={errorFields}
            label={t('commonFormPersonalNumberLabel')}
            ariaDescribedby={t('commonFormPersonalNumberPlaceholder')}
          />
        </Col>
      </Row>
      <Row>
        <Col className="col-12 col-md-6">
          <DateField
            label={t('commonFormDateLabel')}
            name="dob"
            value={formValues.dob}
            placeholder={t('commonFormDatePlaceholder')}
            errorFields={errorFields}
            onValueChange={value => onDateFieldChange('dob', value)}
            onValidate={handleValidation}
            ariaDescribedby={t('commonFormDatePlaceholder')}
            required
            disabled={isDoBDisabled}
          />
        </Col>
      </Row>

      <div className="form-group form-check form-check-inline w-100">
        <PersonRelationship
          name="relationship"
          value={formValues.relationship}
          onChange={onRelationshipChange}
          isSelected={!!formValues.relationship}
          hasHelp
        />
      </div>
      <YesNo
        name="isSecondaryCardHolder"
        label={t('beneficiaryContactFormSecondaryCardHolderLabel')}
        errorFields={errorFields}
        value={formValues.isSecondaryCardHolder}
        booleanValues
        hasHelp
        onChange={handleFieldFormValueChange}
      />

      <YesNo
        name="isCoLiving"
        label={t('expenseBeneficiaryContactCoLiving')}
        errorFields={errorFields}
        value={formValues.isCoLiving}
        booleanValues
        hasHelp
        onChange={handleFieldFormValueChange}
      />
    </>
  );
}

AdditionalClaimantSweden.propTypes = {
  onUpdateContact: PropTypes.func.isRequired,
  onRemoveContact: PropTypes.func.isRequired,
  isRemovable: PropTypes.bool,
  contact: PropTypes.shape({
    id: PropTypes.number,
    isValid: PropTypes.bool,
    firstName: PropTypes.string,
    surnames: PropTypes.string,
    dob: PropTypes.string,
    personalNumber: PropTypes.string,
    relationship: PropTypes.number,
    relationshipDescription: PropTypes.string,
    isSecondaryCardHolder: PropTypes.string,
    isCoLiving: PropTypes.string
  }).isRequired
};

AdditionalClaimantSweden.defaultProps = {
  isRemovable: false
};

export default AdditionalClaimantSweden;
