import {
  UPLOAD_FILE_REQUESTED,
  UPLOAD_FILE_SUCCEEDED,
  UPLOAD_FILE_FAILED,
  updateFileResult,
  showFileSpinnerProgress,
  cleanFiles
} from 'actions/feature/file';

import * as apiRoutes from 'api/apiRoutes';

import { apiRequest } from 'actions/core/api';
import { setError } from 'actions/core/error';
import { EMPTY_CURRENT_CLAIM } from 'actions/actionTypes';

export const uploadFileFlow = ({ dispatch }) => next => action => {
  next(action);

  if (action.type === UPLOAD_FILE_REQUESTED) {
    const formData = new FormData();
    formData.append('files', action.payload.file);
    formData.append('ClaimID', action.payload.claimId);

    const route = apiRoutes.FILES;

    dispatch(showFileSpinnerProgress(action.payload.componentId, true));
    dispatch(
      apiRequest(
        'POST',
        route,
        'multipart/form-data',
        formData,
        {
          componentId: action.payload.componentId,
          name: action.payload.file.name,
          answerType: action.payload.answerType
        },
        UPLOAD_FILE_SUCCEEDED,
        UPLOAD_FILE_FAILED
      )
    );
  }
};

export const uploadFileSuccess = ({ dispatch }) => next => action => {
  next(action);

  if (action.type === UPLOAD_FILE_SUCCEEDED) {
    dispatch(
      updateFileResult(
        action.meta.componentId,
        true,
        action.payload,
        action.meta.name,
        action.meta.answerType
      )
    );
    dispatch(showFileSpinnerProgress(action.meta.componentId, false));
  }
};

export const uploadFileFail = ({ dispatch }) => next => action => {
  next(action);

  if (action.type === UPLOAD_FILE_FAILED) {
    dispatch(showFileSpinnerProgress(action.meta.componentId, false));
    dispatch(updateFileResult(action.meta.componentId, false));
    dispatch(setError(action.payload));
  }
};

export const clearFileUploadData = ({ dispatch }) => next => action => {
  next(action);
  if (action.type === EMPTY_CURRENT_CLAIM) {
    dispatch(cleanFiles());
  }
};

export default [
  uploadFileFlow,
  uploadFileSuccess,
  uploadFileFail,
  clearFileUploadData
];
