import React, { useState } from 'react';
import PropTypes from 'prop-types';

import YesNo from 'components/ContactsQuestion/Shared/YesNo';
import { validateForm } from 'utils/formValidationHelper';
import UploadDocument from 'components/ContactsQuestion/Shared/UploadDocument';
import { isFileIdValid } from 'components/ContactsQuestion/AffectedPerson/AffectedPeopleUtils';
import AffectedPerson from './AffectedPerson';
import schemaValidation from './AffectedPersonDocumentYesNoSchemaValidation';

const AffectedPersonDocumentHealth = props => {
  const person = {
    id: props.person.id,
    typeObjectId: props.person.typeObjectId,
    isValid: props.person.isValid,
    payload: {
      ...props.person.payload,
      answerYesNo: props.person.payload.answerYesNo,
      fileIdProvider: props.person.payload.fileIdProvider
    }
  };

  const [errorFields, setErrorFields] = useState({});

  const [formValues, setFormValues] = useState(person);

  const handleFieldFormValueChange = (name, value) => {
    const clone = {
      ...formValues,
      payload: { ...formValues.payload, [name]: value }
    };
    validateAndUpdate(clone);
  };

  const validateAndUpdate = values => {
    setFormValues(values);

    const clone = { ...values, isValid: values.isValid && isFormValid(values) };
    props.onUpdateContact(clone);
  };

  const isFormValid = values => {
    const result = validateForm(schemaValidation, values.payload);

    setErrorFields({
      ...errorFields,
      ...result.errorFields
    });
    return (
      values.isValid &&
      result.isValid &&
      isFileIdValid(values.payload.fileIdProvider)
    );
  };

  const handleOnUpdateContactBase = values => {
    const clone = {
      ...formValues,
      ...values,
      payload: { ...formValues.payload, ...values.payload }
    };
    validateAndUpdate(clone);
  };

  return (
    <>
      <AffectedPerson
        person={props.person}
        isRemovable={props.isRemovable}
        onRemoveContact={props.onRemoveContact}
        onUpdateContact={handleOnUpdateContactBase}
      />
      <YesNo
        name="answerYesNo"
        label={props.labelYesNo}
        errorFields={errorFields}
        value={props.person.payload.answerYesNo}
        onChange={handleFieldFormValueChange}
      />
      <UploadDocument
        id={props.person.id}
        label={props.label}
        isMedical={props.isMedical}
        showHelp={props.showHelp}
        value={props.person.payload.fileIdProvider}
        claimId={props.claimId}
        benefitId={props.benefitId}
        onChange={value => handleFieldFormValueChange('fileIdProvider', value)}
      />
    </>
  );
};

AffectedPersonDocumentHealth.propTypes = {
  person: PropTypes.shape({
    id: PropTypes.string,
    typeObjectId: PropTypes.number.isRequired,
    isValid: PropTypes.bool.isRequired,
    payload: PropTypes.shape({
      answerYesNo: PropTypes.string,
      fileIdProvider: PropTypes.string // rename this to fileId
    })
  }).isRequired,
  isRemovable: PropTypes.bool,
  onUpdateContact: PropTypes.func.isRequired,
  onRemoveContact: PropTypes.func.isRequired,
  claimId: PropTypes.string.isRequired,
  benefitId: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  labelYesNo: PropTypes.string.isRequired,
  isMedical: PropTypes.bool.isRequired,
  showHelp: PropTypes.bool.isRequired
};
AffectedPersonDocumentHealth.defaultProps = {
  isRemovable: false
};

export default AffectedPersonDocumentHealth;
