import history from './history';

const urlBase = '/OLC_V3';
const bodiamUrlBase = `${urlBase}/portal`;
const arundelUrlBase = `${urlBase}/welcome`;

function getUrl(url, marketUrl) {
  return `${url}/${marketUrl}`;
}

export function redirectToBodiam(marketUrl, lang) {
  history.push(getUrl(bodiamUrlBase, marketUrl, lang));
}

export function redirectToBodiamLogin(marketUrl) {
  history.push(`${getUrl(bodiamUrlBase, marketUrl)}/login`);
}

export function redirectToArundel(marketUrl) {
  history.push(getUrl(arundelUrlBase, marketUrl));
}

export function redirectToArundelWithReload(market) {
  window.location.href = getUrl(arundelUrlBase, market);
}

export function redirectToBodiamWithReload(market) {
  window.location.href = getUrl(bodiamUrlBase, market);
}
