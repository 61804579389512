import { getAnsweredTreeFromAnswers } from 'utils/AnswerHelper';
import {
  SAVE_CLAIM_REQUESTED,
  SAVE_CLAIM_SUCCEEDED,
  SAVE_CLAIM_FAILED
} from 'actions/feature/claim';
import { updateClaimData } from 'actions/claimActions';
import { CLAIMS } from 'api/apiRoutes';
import { apiRequest } from 'actions/core/api';
import { setError } from 'actions/core/error';
import { hideSpinner, showSpinner, notifyWarning } from 'actions/core/ui';
import { DATES_STEP, TREE_SECTION_BY_STEP_ID } from 'constants/claim';

const removeUnusedAttributesInBackend = currentClaim => {
  const { createDelayIncident, createCaseInput, ...values } = currentClaim;
  return values;
};

const getClaimUrlWithOrWithOutEmail = (currentClaim, language) => {
  if (currentClaim.sendEmail) {
    return `${CLAIMS}/${currentClaim.id}/email/${language}`;
  }
  return `${CLAIMS}/${currentClaim.id}/${language}`;
};

export const saveClaimFlow = ({ dispatch, getState }) => next => action => {
  next(action);

  const { currentStep } = getState();

  if (action.type === SAVE_CLAIM_REQUESTED) {
    // Dates are storing in current claim. If we update the values with the decision tree we will lose the dates.
    if (
      currentStep &&
      currentStep.step !== DATES_STEP &&
      currentStep.step in TREE_SECTION_BY_STEP_ID
    ) {
      const treeSection = TREE_SECTION_BY_STEP_ID[currentStep.step];
      const answeredTree = getAnsweredTreeFromAnswers(
        getState().currentClaim.trees[treeSection],
        getState().decisionTree.userAnswers
      );

      dispatch(
        updateClaimData({
          trees: {
            ...getState().currentClaim.trees,
            [treeSection]: answeredTree
          }
        })
      );
    }

    const { currentClaim } = getState();
    const claimToSave = removeUnusedAttributesInBackend(currentClaim);
    const url = getClaimUrlWithOrWithOutEmail(
      { ...currentClaim, sendEmail: action.payload.sendEmail },
      getState().marketLanguages.selectedLanguage
    );

    !action.meta.notShowSpinner && dispatch(showSpinner());
    dispatch(
      apiRequest(
        'PUT',
        url,
        {},
        claimToSave,
        {
          ...action.meta
        },
        action.meta.onSuccess || SAVE_CLAIM_SUCCEEDED,
        action.meta.onFail || SAVE_CLAIM_FAILED
      )
    );
  }
};

export const saveClaimSuccess = ({ dispatch }) => next => action => {
  next(action);

  if (action.type === SAVE_CLAIM_SUCCEEDED) {
    const { onSaveClaimSuccess } = action.meta;
    const successNotify =
      action.meta.successNotify ?? notifyWarning('alertSuccessSave');
    if (onSaveClaimSuccess) {
      dispatch({
        type: onSaveClaimSuccess,
        payload: action.payload,
        meta: action.meta
      });
    } else {
      dispatch(successNotify);
    }
    dispatch(hideSpinner());
  }
};

export const saveClaimFail = ({ dispatch }) => next => action => {
  next(action);

  if (action.type === SAVE_CLAIM_FAILED) {
    dispatch(hideSpinner());
    dispatch(setError(action.payload));
  }
};

export default [saveClaimFlow, saveClaimSuccess, saveClaimFail];
