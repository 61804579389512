import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function medicalProvider(
  state = initialState.medicalProvider,
  action
) {
  switch (action.type) {
    case types.MEDICAL_NEW_SEARCH:
      return {
        ...initialState.medicalProvider
      };
    case types.MEDICAL_PROVIDER_GET_CATEGORIES:
      return {
        ...state,
        categories: action.categoryList
      };
    case types.MEDICAL_PROVIDER_GET_SPECIALITIES:
      return {
        ...state,
        specialities: action.specialityList
      };
    case types.MEDICAL_PROVIDER_FACILITIES_REQUEST:
      return {
        ...state,
        query: action.query,
        result: initialState.medicalProvider.result,
        mapVisible: false
      };
    case types.MEDICAL_PROVIDER_FACILITIES_SUCCESS:
      return { ...state, result: action.resultData, mapVisible: true };
    case types.MEDICAL_PROVIDER_FACILITIES_FAILURE:
      return { ...state, result: [], mapVisible: false };
    case types.MEDICAL_PROVIDER_FACILITIES_START_LOAD_MAP:
      return { ...state, isLoading: true };
    case types.MEDICAL_PROVIDER_FACILITIES_STOP_LOAD_MAP:
      return { ...state, isLoading: false };
    default:
      return state;
  }
}
