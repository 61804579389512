export const VALIDATE_CARD_NUMBER_REQUESTED = '[card] validate card number';
export const VALIDATE_CARD_REQUESTED = '[card] validate';
export const VALIDATE_CARD_SUCCEEDED = '[card] validation success';
export const VALIDATE_CARD_FAILED = '[card] validation fail';

export const TRANSFORM_VALIDATE_ADD_CARD_REQUESTED =
  '[card] transform & validate & add to user';
export const ADD_CARD_REQUESTED = '[card] add';
export const ADD_CARD_SUCCEEDED = '[card] add success';
export const ADD_CARD_FAILED = '[card] add fail';

export const VALIDATE_AND_APPEND_CARD_TO_TREE_REQUESTED =
  '[card] validate & append to tree';
export const VALIDATE_AND_APPEND_CARD_NUMBER_TO_TREE_REQUESTED =
  '[card] validate card number & append to tree';
export const APPEND_CARD_TO_TREE_REQUESTED = '[card] append to tree';
export const APPEND_CARD_TO_TREE_ADD_CARD_SUCCESS =
  '[card] add card success & append to tree requested';
export const APPEND_CARD_TO_TREE_VALIDATION_SUCCEEDED =
  '[card] append to tree validation success';
export const APPEND_CARD_TO_TREE_SUCCEEDED = '[card] append to tree success';

export const VALIDATE_BENEFITS_CARD_REQUESTED =
  '[card] transform & validate & get benefits';
export const BENEFITS_CARD_REQUESTED = '[card] get benefits';
export const BENEFITS_CARD_SUCCEEDED = '[card] get benefits success';
export const BENEFITS_CARD_FAILED = '[card] get genefits fail';

export const SET_NEW_CARD_TO_LIST = '[card] set new card to list';
export const SET_CARD_INFORMATION = '[card] set card information';
export const CLEAN_CARD_INFORMATION = '[card] clean card information';
export const SET_CARD_BENEFITS = '[card] set card benefits';
export const SET_CARD_VALIDATION_RESULT = '[card] set validation result';
export const SET_ACTIVE_CARD = '[card] set card as active';
export const GENERATE_BENEFITS_FILE = '[benefits] generate cover benefits file';
export const GENERATE_BENEFITS_FILE_SUCCESS =
  '[benefits] generate cover benefits file successfully';
export const GENERATE_BENEFITS_FILE_FAIL =
  '[benefits] generate cover benefits file failed';

export const validateCard = (number, obfuscated, onSuccess, onFail, meta) => ({
  type: VALIDATE_CARD_REQUESTED,
  payload: { number, obfuscated },
  meta: {
    ...meta,
    onValidateCardSuccess: onSuccess,
    onValidateCardFail: onFail
  }
});

export const transformNumberAndValidate = (
  payload,
  onSuccess,
  onFail,
  meta
) => ({
  type: VALIDATE_CARD_NUMBER_REQUESTED,
  payload,
  meta: {
    ...meta,
    onValidateCardSuccess: onSuccess,
    onValidateCardFail: onFail
  }
});

export const addCardToUser = (number, redirectTo) =>
  addCardToUserEvent(number, null, null, redirectTo);

export const addCardToUserEvent = (number, onSuccess, onFail, redirectTo) => ({
  type: TRANSFORM_VALIDATE_ADD_CARD_REQUESTED,
  payload: { number, validateUnhandled: false },
  meta: { redirectTo, onAddCardSuccess: onSuccess, onAddCardFail: onFail }
});

export const addGencodeToUserEvent = (
  gencode,
  obfuscated,
  onSuccess,
  onFail,
  meta
) => ({
  type: ADD_CARD_REQUESTED,
  payload: { gencode, obfuscated },
  meta: {
    ...meta,
    onAddCardSuccess: onSuccess,
    onAddCardFail: onFail
  }
});

export const addCardAndAppendToTree = (
  gencode,
  obfuscated,
  productId,
  questionId
) =>
  addGencodeToUserEvent(
    gencode,
    obfuscated,
    APPEND_CARD_TO_TREE_ADD_CARD_SUCCESS,
    null,
    { productId, questionId }
  );

export const appendGencodeToTree = (number, obfuscated, questionId) => ({
  type: VALIDATE_AND_APPEND_CARD_TO_TREE_REQUESTED,
  payload: { number, obfuscated },
  meta: { questionId }
});

export const appendCardNumberToTree = (number, questionId) => ({
  type: VALIDATE_AND_APPEND_CARD_NUMBER_TO_TREE_REQUESTED,
  payload: { number },
  meta: { questionId }
});

export const validateAndGetBenefits = (number) => ({
  type: VALIDATE_BENEFITS_CARD_REQUESTED,
  payload: { number }
});

export const setCardValidationResult = (isValid, code, message) => ({
  type: SET_CARD_VALIDATION_RESULT,
  payload: { isValid, code, message }
});

export const setCardBeneftis = (benefits,productId=null) => ({
  type: SET_CARD_BENEFITS,
  payload: benefits,
  meta:{productId:productId}
});

export const setCardInformation = (productId, gencode, obfuscated) => ({
  type: SET_CARD_INFORMATION,
  payload: { productId, gencode, obfuscated }
});

export const setNewCardToList = card => ({
  type: SET_NEW_CARD_TO_LIST,
  payload: card
});

export const setActiveCard = gencode => ({
  type: SET_ACTIVE_CARD,
  payload: gencode
});

export const cleanCardInformation = () => ({
  type: CLEAN_CARD_INFORMATION
});

export const downloadBenefitsFile = (formValues, languageId, marketUrl) => ({
  type: GENERATE_BENEFITS_FILE,
  payload: { formValues, languageId, marketUrl }
});
