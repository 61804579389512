import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'components/shared/Table';
import { Button } from 'components/shared/Forms';

const CalendarPickerHeaderComponent = props => (
  <Row className="no-gutters calendar-picker-header">
    <Col className="col-1 text-right">
      <Button
        id="btn-calendarPickerHead-right"
        type="submit"
        className="btn btn-link btn-calendar"
        onClick={() => props.handleMonthUpdate('sub')}
      >
        <i className="material-icons md-24 align-middle ">navigate_before</i>
      </Button>
    </Col>
    <Col className="text-center calendar-title">{props.leftMonthTitle}</Col>
    <Col className="text-center calendar-title d-sm-none d-none d-md-none d-lg-none d-xl-block">
      {props.rightMonthTitle}
    </Col>
    <Col className="col-1 text-left">
      <Button
        id="btn-calendarPickerHead-left"
        type="submit"
        className="btn btn-link btn-calendar"
        onClick={() => props.handleMonthUpdate('add')}
      >
        <i className=" material-icons md-24 align-middle ">navigate_next</i>
      </Button>
    </Col>
  </Row>
);

CalendarPickerHeaderComponent.propTypes = {
  leftMonthTitle: PropTypes.string.isRequired,
  rightMonthTitle: PropTypes.string.isRequired,
  handleMonthUpdate: PropTypes.func.isRequired
};

export default CalendarPickerHeaderComponent;
