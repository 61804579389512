import {
  TRANSFORM_VALIDATE_ADD_CARD_REQUESTED,
  ADD_CARD_REQUESTED,
  ADD_CARD_SUCCEEDED,
  ADD_CARD_FAILED,
  transformNumberAndValidate,
  setNewCardToList,
  setCardValidationResult
} from 'actions/feature/card';

import { ADD_CARDS } from 'api/apiRoutes';
import { apiRequest } from 'actions/core/api';
import { goTo } from 'actions/core/app.navigation';
import { hideSpinner, showSpinner } from 'actions/core/ui';
import { setError } from 'actions/core/error';

export const transformAndvalidateAndAddCardFlow = ({
  dispatch
}) => next => action => {
  next(action);

  if (action.type === TRANSFORM_VALIDATE_ADD_CARD_REQUESTED) {
    dispatch(
      transformNumberAndValidate(
        { ...action.payload },
        ADD_CARD_REQUESTED,
        null,
        action.meta
      )
    );
  }
};

export const addCardFlow = ({ dispatch }) => next => action => {
  next(action);

  if (action.type === ADD_CARD_REQUESTED) {
    dispatch(showSpinner());
    dispatch(
      apiRequest(
        'POST',
        ADD_CARDS,
        {},
        {
          card: action.payload.gencode,
          obfuscated: action.payload.obfuscated
        },
        {
          ...action.meta
        },
        ADD_CARD_SUCCEEDED,
        ADD_CARD_FAILED
      )
    );
  }
};

export const addCardFlowSuccess = ({ dispatch }) => next => action => {
  next(action);

  if (action.type === ADD_CARD_SUCCEEDED) {
    const { redirectTo, onAddCardSuccess } = action.meta;
    dispatch(setNewCardToList(action.payload));
    if (redirectTo) {
      dispatch(goTo(redirectTo));
    }

    if (onAddCardSuccess) {
      dispatch({
        type: onAddCardSuccess,
        payload: action.payload,
        meta: action.meta
      });
    }
    dispatch(hideSpinner());
  }
};

export const addCardFlowFail = ({ dispatch }) => next => action => {
  next(action);

  if (action.type === ADD_CARD_FAILED) {
    const { status, data } = action.payload;
    if (status === 400 || status === 404) {
      dispatch(setCardValidationResult(false, status, data));
    } else {
      dispatch(setError(action.payload));
    }

    const { onAddCardFail } = action.meta;
    if (onAddCardFail) {
      dispatch({
        type: onAddCardFail,
        payload: action.payload,
        meta: action.meta
      });
    }

    dispatch(hideSpinner());
  }
};

export default [
  transformAndvalidateAndAddCardFlow,
  addCardFlow,
  addCardFlowSuccess,
  addCardFlowFail
];
