import { DEFAULT_EXPENSE_TYPES } from 'constants/expenseTypes';

export const groupByExpenseId = expenses => {
  const expensesList = [];
  const grouped = {};

  expenses.forEach(expense => {
    if (!expensesList.includes(expense.type)) {
      expensesList.push(expense.type);
    }
    if (!Object.prototype.hasOwnProperty.call(grouped, expense.type)) {
      grouped[expense.type] = [];
    }
    grouped[expense.type].push(expense);
  });

  return {
    groupedExpenses: grouped,
    expensesList
  };
};

export const getExpenseTypes = customExpensesTypeMarket => {
  return { ...DEFAULT_EXPENSE_TYPES, ...customExpensesTypeMarket };
};
