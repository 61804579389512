import { SET_POLICY_DETAIL, CLEAN_POLICY_DETAIL } from 'actions/feature/policy';
import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function claim(state = initialState.productDetail, action) {
  switch (action.type) {
    case types.SET_PRODUCT_DETAIL:
      return action.card;
    case SET_POLICY_DETAIL:
      return action.payload.policy;
    case CLEAN_POLICY_DETAIL:
      return initialState.productDetail;
    default:
      return state;
  }
}
