/* eslint-disable react/no-unescaped-entities */
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'components/shared/Table';
import { pageview } from 'utils/analytics';
import { getRoute } from 'utils/routeHelper';
import { appInsights } from 'ApplicationInsights';


export const LegalNoticePage = () => {
    const { t } = useTranslation();

    useEffect(() => {
      pageview({ title: 'Legal Notice', page: getRoute('Legal Notice') });
      appInsights.trackPageView(
        {
            name:'Legal Notice',
            uri: getRoute('Legal Notice') 
        }
      )
    }, []);
    const titlePage = text => <h4>{t(text)}</h4>;
    const titleSection = text => <h6>{t(text)}</h6>;
    const paragraphSection = text => <p className="content">{t(text)}</p>;
    return (
        <Row className="no-gutters legalnotice-page-box">
            <div className="legalnotice-page">
                <Col className="col">
             <div>
                {titlePage('[legalNoticePage] Title')}
                <br /><br />
                {titleSection('[legalNoticePage] SubTitle1')}
                <br />
                {paragraphSection('[legalNoticePage] SubTitle1 Paragraph1')}
                <br />
                {paragraphSection('[legalNoticePage] SubTitle1 Paragraph2_1')}
                {paragraphSection('[legalNoticePage] SubTitle1 Paragraph2_2')}
                {paragraphSection('[legalNoticePage] SubTitle1 Paragraph2_3')}
                <br />                    
                <span dangerouslySetInnerHTML={{ __html: t('[legalNoticePage] SubTitle1 Paragraph3') }}/>                                       
            </div>
            <div>
                <br />  <br />  
                {titleSection('[legalNoticePage] SubTitle2')}
                <br />
                {paragraphSection('[legalNoticePage] SubTitle2 Paragraph1')}
                <br />
                {paragraphSection('[legalNoticePage] SubTitle2 Paragraph2_1')}
                {paragraphSection('[legalNoticePage] SubTitle2 Paragraph2_2')}
                {paragraphSection('[legalNoticePage] SubTitle2 Paragraph2_3')}
                <br />                    
                <span dangerouslySetInnerHTML={{ __html: t('[legalNoticePage] SubTitle2 Paragraph3') }}/>              
                <br/> <br/>                              
                <span dangerouslySetInnerHTML={{ __html: t('[legalNoticePage] SubTitle2 Paragraph4') }}/>                 
                <br/> <br/> 
                <span dangerouslySetInnerHTML={{ __html: t('[legalNoticePage] SubTitle2 Paragraph5') }}/>   
            </div>
            <div>
                <br />  <br /> 
                {titleSection('[legalNoticePage] SubTitle3')}
                <br />
                {titleSection('[legalNoticePage] SubTitle3_1')}
                <br />
                {paragraphSection('[legalNoticePage] SubTitle3_1 Paragraph1')} 
                <br />
                <span dangerouslySetInnerHTML={{ __html: t('[legalNoticePage] SubTitle3_1 Paragraph2') }} />               
                <br />
                {paragraphSection('[legalNoticePage] SubTitle3_1 Paragraph3')}
                <br />
                {paragraphSection('[legalNoticePage] SubTitle3_1 Paragraph4')}
                <br />
                {paragraphSection('[legalNoticePage] SubTitle3_1 Paragraph5')}    
                <br />
                {titleSection('[legalNoticePage] SubTitle3_2')}
                <br />
                {paragraphSection('[legalNoticePage] SubTitle3_2 Paragraph1')}  
                <br />
                <span dangerouslySetInnerHTML={{ __html: t('[legalNoticePage] SubTitle3_2 Paragraph2') }} />  
                <br />
                <span dangerouslySetInnerHTML={{ __html: t('[legalNoticePage] SubTitle3_2 Paragraph3') }} /> 
                <br />
                {titleSection('[legalNoticePage] SubTitle3_3')}
                <br />
                <span dangerouslySetInnerHTML={{ __html: t('[legalNoticePage] SubTitle3_3 Paragraph1') }} />
                <br /> <br />
                {titleSection('[legalNoticePage] SubTitle3_4')}
                <br />
                {paragraphSection('[legalNoticePage] SubTitle3_4 Paragraph1')} 
                <br />
                {paragraphSection('[legalNoticePage] SubTitle3_4 Paragraph2')} 
                <br />
                <span dangerouslySetInnerHTML={{ __html: t('[legalNoticePage] SubTitle3_4 Paragraph3') }} />
                <br />
                <span dangerouslySetInnerHTML={{ __html: t('[legalNoticePage] SubTitle3_4 Paragraph4') }} />
                <br /> <br />
                <span dangerouslySetInnerHTML={{ __html: t('[legalNoticePage] SubTitle3_4 Paragraph5') }} />
                <br />  <br /> 
                {titleSection('[legalNoticePage] SubTitle4')}
                <br />
                {paragraphSection('[legalNoticePage] SubTitle4 Paragraph1')}
                <br />
                {paragraphSection('[legalNoticePage] SubTitle4 Paragraph2')}
                <br />
                {paragraphSection('[legalNoticePage] SubTitle4 Paragraph3')} 
            </div>
        </Col>
            </div>         
        </Row>
    );
}



export default LegalNoticePage;
