import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { event } from 'utils/analytics';
import CustomModal from 'shared/components/CustomModal';

import { GA_EVENTS, GA_ACTIONS } from 'constants/googleAnalytics';
import { appInsights } from 'ApplicationInsights';

const DeclineQuestion = props => {
  const { t } = useTranslation();
  const [isConfirmed, setConfirmation] = useState(false);
  const [isOpenModal, setOpenModal] = useState(true);

  const handleConfirmationYes = () => {
    event(GA_EVENTS.QUESTION, GA_ACTIONS.ANSWER, {
      label: `${props.guideWireField} - yes`
    });
    appInsights.trackEvent(
      {
        name: `${props.guideWireField} - yes`
      },
      {
        EventCategory: GA_EVENTS.QUESTION,
        EventAction: GA_ACTIONS.ANSWER,
        EventLabel:  `${props.guideWireField} - yes`
      }
      )
    setConfirmation(true);
  };

  const handleFinalConfirmationYes = () => {
    event(GA_EVENTS.QUESTION, GA_ACTIONS.EARLYEXIT, {
      label: `${props.guideWireField} - reason: ${props.properties.reasonId}`
    });
    appInsights.trackEvent(
      {
        name:`${props.guideWireField} - reason: ${props.properties.reasonId}`
      },
      {
        EventCategory: GA_EVENTS.QUESTION,
        EventAction: GA_ACTIONS.ANSWER,
        EventLabel: `${props.guideWireField} - reason: ${props.properties.reasonId}`
      }
      )
    setOpenModal(false);
    if (props.claimId) {
      props.onDeclineCurrentClaim(props.properties.reasonId, props.cancelType);
    } else {
      props.gotoLandingPage();
    }
  };

  let handleOk = handleFinalConfirmationYes;
  let handleCancel;
  let titleClassName = 'claim-denied-title';
  let modalTitle = '';
  let modalDescription = props.properties.description;
  let modalOk = t('commonOk');
  let modalCancel;
  if (!isConfirmed) {
    handleOk = handleConfirmationYes;
    handleCancel = props.onDeclineConfirmationNO;
    titleClassName = '';
    modalTitle = t('DeclineAreYouSure');
    modalDescription = t('DeclineAreYouSureDesp');
    modalOk = t('commonYes');
    modalCancel = t('commonNo');
  }

  return (
    <CustomModal
      isOpen={isOpenModal}
      contentLabel="Are you sure?"
      shouldCloseOnOverlayClick={false}
      titleClassName={titleClassName}
      overlayClassName="overlay"
      className="modal-frame"
      handleConfirmationOk={handleOk}
      handleConfirmationCancel={handleCancel}
      title={modalTitle}
      description={modalDescription}
      labelOk={modalOk}
      labelCancel={modalCancel}
    />
  );
};

DeclineQuestion.propTypes = {
  onDeclineCurrentClaim: PropTypes.func,
  onDeclineConfirmationNO: PropTypes.func.isRequired,
  gotoLandingPage: PropTypes.func.isRequired,
  properties: PropTypes.shape({
    reasonId: PropTypes.number,
    description: PropTypes.string
  }).isRequired,
  claimId: PropTypes.string,
  cancelType: PropTypes.number.isRequired,
  guideWireField: PropTypes.string
};

DeclineQuestion.defaultProps = {
  claimId: '',
  onDeclineCurrentClaim: () => false,
  guideWireField: 'declineQuestion'
};

export default DeclineQuestion;
