/* eslint-disable react/sort-comp */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { isEmpty } from 'lodash';

import ReactModal from 'react-modal';
import * as activePageActions from 'actions/activePageActions';
import * as prismicActions from 'actions/prismicActions';
import * as alertsActions from 'actions/alertsActions';

import history from 'utils/history';
import { getMarketFromUrl } from 'utils/urlHelpers';
import { resetHistory } from 'utils/sessionController';
import { BODIAM_YOUR_COVER, ARUNDEL_BASE_URL } from 'constants/constants';
import { getLanguageIsoCode } from 'utils/languageUtils';

import FaqLinks from 'Bodiam/components/FaqLinks';
import Loading from 'components/Loading/Loading';
import PageHeader from 'Bodiam/components/PageHeader';
import PolicySummary from 'Bodiam/components/PolicySummary';
import { getActiveCard } from 'store/selectors/activeCard';

class YourCoverPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      document: null,
      showModal: false
    };

    this.closeModalAndOpenLink = this.closeModalAndOpenLink.bind(this);
    this.showModal = this.showModal.bind(this);
    this.goToNewClaim = this.goToNewClaim.bind(this);
    this.showMessage = this.showMessage.bind(this);
    this.getProductPdfDocument = this.getProductPdfDocument.bind(this);
    this.getLanguageCode = this.getLanguageCode.bind(this);
  }

  componentDidMount() {
    if (!isEmpty(this.props.activeCard)) {
      this.getProductPdfDocument();
    }
  }

  componentDidUpdate(prevProps) {
    const diffCard = prevProps.activeCard !== this.props.activeCard;
    const diffLang =
      prevProps.marketLanguages.selectedLanguage !==
      this.props.marketLanguages.selectedLanguage;
    if (diffCard || diffLang) {
      this.getProductPdfDocument();
    }
  }

  getLanguageCode() {
    const { marketLanguages } = this.props;
    return getLanguageIsoCode(marketLanguages.selectedLanguage);
  }

  getProductPdfDocument() {
    this.props.actions
      .getProductPdfDocumentAction(
        this.props.activeCard.amexMarket,
        this.props.activeCard.amexCode,
        this.getLanguageCode()
      )
      .then(result => {
        if (result && result.results && result.results.length) {
          const document = result.results[0];
          this.setState({
            document
          });
        }
      });
  }

  buildPdfLink() {
    if (this.state.document) {
      return (
        <button
          type="button"
          className="btn-amex-policy-pdf d-flex start px-2"
          onClick={this.showMessage}
        >
          <i className="material-icons mr-2">archive</i>
          {this.props.labels.bodiamReadFullPolicyWording}
        </button>
      );
    }
    return null;
  }

  showModal() {
    this.setState({ showModal: true });
  }

  closeModalAndOpenLink() {
    this.setState({ showModal: false });
    window.open(this.state.document.data.link.url, '_blank');
  }

  showMessage() {
    if (this.props.activeCard.issuedBeforeDate) {
      this.showModal();
    } else {
      this.closeModalAndOpenLink();
    }
  }

  goToNewClaim() {
    const newClaimUrlWithMarket = `${ARUNDEL_BASE_URL}${getMarketFromUrl()}`;
    resetHistory(BODIAM_YOUR_COVER);
    this.props.actions.bodiamMakeClaimAction();
    history.push(newClaimUrlWithMarket);
    this.props.actions.gotoTriageWrapper();
  }

  getPolicyLinks() {
    return (
      <>
        <div className="col-12 align-middle px-0 mb-sm-1">
          {this.buildPdfLink()}
        </div>
        <div className="col-12 align-middle px-0 mb-sm-1">
          <button
            type="submit"
            className="btn-amex-call-to-action  d-flex start px-2"
            onClick={this.goToNewClaim}
          >
            <span className="mr-auto">
              {this.props.labels.bodiamMakeTravelInsuranceClaim}
            </span>
            <i className="material-icons">navigate_next</i>
          </button>
        </div>
      </>
    );
  }

  render() {
    const content = (
      <>
        <PageHeader
          title={this.props.labels.coverPageTitle}
          productImage={this.props.activeCard.imageURL}
        />
        {this.props.activeCard.product && (
          <div className="your-cover">
            <div className="section-container">
              <PolicySummary
                marketCode={this.props.activeCard.amexMarket}
                productCode={this.props.activeCard.amexCode}
                labels={this.props.labels}
                getCoverSummaryAction={this.props.actions.getCoverSummaryAction}
                loading={this.props.loading}
                language={this.getLanguageCode()}
                getBenefitListAction={this.props.actions.getBenefitListAction}
              />
              <hr />
              {this.getPolicyLinks()}
              <FaqLinks labels={this.props.labels} />
            </div>
          </div>
        )}
        <div>
          <Loading
            loading={this.props.loading}
            text={this.props.labels.loading}
          />
        </div>
        <ReactModal
          isOpen={this.state.showModal}
          contentLabel="contentLabel"
          onRequestClose={this.closeModal}
          className="modal-frame"
        >
          <div className="row">
            <div className="col text-center py-4">
              <span className="modal-description">
                {this.props.labels.bodiamIssuedBeforeDate}
              </span>
            </div>
          </div>
          <div className="row border-top pt-2 mt-3">
            <div className="col text-center">
              <button
                type="submit"
                className="btn btn-primary btn-lg mt-3 btn-amex"
                onClick={this.closeModalAndOpenLink}
              >
                {this.props.labels.commonOk}
              </button>
            </div>
          </div>
        </ReactModal>
      </>
    );

    return content;
  }
}

const mapStateToProps = state => ({
  labels: state.labels,
  activeCard: getActiveCard(state),
  prismicData: state.prismicData,
  loading: state.ajaxCallsInProgress > 0,
  marketLanguages: state.marketLanguages
});

const mapDispatchToProps = dispatch => ({
  actions: {
    ...bindActionCreators(activePageActions, dispatch),
    ...bindActionCreators(prismicActions, dispatch),
    ...bindActionCreators(alertsActions, dispatch)
  }
});

YourCoverPage.propTypes = {
  loading: PropTypes.bool.isRequired,
  labels: PropTypes.shape({
    bodiamReadFullPolicyWording: PropTypes.string.isRequired,
    bodiamMakeTravelInsuranceClaim: PropTypes.string.isRequired,
    coverPageTitle: PropTypes.string.isRequired,
    bodiamIssuedBeforeDate: PropTypes.string.isRequired,
    commonOk: PropTypes.string.isRequired,
    loading: PropTypes.string
  }).isRequired,
  activeCard: PropTypes.shape({
    id: PropTypes.number.isRequired,
    number: PropTypes.string.isRequired,
    amexCode: PropTypes.string.isRequired,
    amexMarket: PropTypes.string.isRequired,
    product: PropTypes.string.isRequired,
    typeOfCard: PropTypes.number.isRequired,
    imageURL: PropTypes.string.isRequired,
    issuedBeforeDate: PropTypes.bool.isRequired
  }).isRequired,
  prismicData: PropTypes.shape({
    faqPageData: PropTypes.shape({}),
    benefitList: PropTypes.shape({}),
    coverSummary: PropTypes.shape({}),
    productPdfDocuemnt: PropTypes.shape({})
  }).isRequired,
  actions: PropTypes.shape({
    gotoTriageWrapper: PropTypes.func.isRequired,
    getBenefitListAction: PropTypes.func.isRequired,
    getCoverSummaryAction: PropTypes.func.isRequired,
    getProductPdfDocumentAction: PropTypes.func.isRequired,
    bodiamMakeClaimAction: PropTypes.func.isRequired
  }).isRequired,
  marketLanguages: PropTypes.shape({
    languages: PropTypes.shape({}),
    selectedLanguage: PropTypes.string
  }).isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(YourCoverPage);
