import React from 'react';
import { useTranslation } from 'react-i18next';

import LanguageSelector from 'components/LanguageSelector';

const Header = () => {
  const { t } = useTranslation();
  return (
    <div className="row no-gutters">
      <div className="col landing-header">
        <div className="container">
          <img
            className="axa-logo mr-2"
            alt="axa logo"
            src="assets/img/u35.png"
          />
          {t('topBarText')}
          <LanguageSelector />
        </div>
      </div>
    </div>
  );
};

export default Header;
