import { SET_DOCUMENT_LINK } from 'actions/feature/howToClaim';
import initialState from './initialState';

export default function howToClaimLinks(
  state = initialState.howToClaimLinks,
  action
) {
  switch (action.type) {
    case SET_DOCUMENT_LINK:
      return { ...state, [action.payload.type]: action.payload.link };
    default:
      return state;
  }
}
