export const UPLOAD_FILE_REQUESTED = '[file] upload';
export const UPLOAD_FILE_SUCCEEDED = '[file] upload success';
export const UPLOAD_FILE_FAILED = '[file] upload fail';

export const DOWNLOAD_FILE_REQUESTED = '[file] download';
export const DOWNLOAD_FILE_SUCCEEDED = '[file] download success';
export const DOWNLOAD_FILE_FAILED = '[file] download fail';

export const RESET_FILES_STATUS = '[file] reset';

export const UPDATE_SHOW_FILE_SPINNER = '[file] toggle file spinner';
export const ADD_REASON_FOR_NO_DOC =
  '[file] Adding text answer to be submitted as file';
export const UPDATE_FILE_RESULT = '[file] upload file result';
export const CLEAN_FILE = '[file] clean';
export const GET_CLAIM_REASON = '[file] fetch reason for no file provided';
export const GET_CLAIM_REASON_SUCCEEDED =
  '[file] fetch reason for no file provided success';
export const GET_CLAIM_REASON_FAILED =
  '[file] fetch reason for no file provided failed';
export const CLEAN_FILE_STORE = '[file] clearing data in File Upload Store';
export const uploadFile = (componentId, claimId, file, answerType) => ({
  type: UPLOAD_FILE_REQUESTED,
  payload: { componentId, claimId, file, answerType }
});

export const showFileSpinnerProgress = (componentId, show, answerType) => ({
  type: UPDATE_SHOW_FILE_SPINNER,
  payload: { componentId, show, answerType }
});

export const addReasonForNoDoc = (componentId, show, answerType, reason,filename) => ({
  type: ADD_REASON_FOR_NO_DOC,
  payload: { componentId, show, answerType, reason,filename }
});

export const cleanFiles = (componentId, show, answerType, reason) => ({
  type: CLEAN_FILE_STORE
});

export const updateFileResult = (
  componentId,
  isSuccess,
  fileId = null,
  fileName = null,
  answerType
) => ({
  type: UPDATE_FILE_RESULT,
  payload: { componentId, isSuccess, fileId, fileName, answerType }
});

export const cleanFile = componentId => ({
  type: CLEAN_FILE,
  payload: { componentId }
});

export function getClaimReasonByFileId(claimId, fileId, questionId) {
  return { type: GET_CLAIM_REASON, payload: { claimId, fileId, questionId } };
}

export const downloadFile = (fileId, claimId) => ({
  type: DOWNLOAD_FILE_REQUESTED,
  payload: { fileId, claimId }
});

export const resetFileStatus = fileId => ({
  type: RESET_FILES_STATUS,
  payload: { fileId }
});
