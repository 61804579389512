import React, { Component } from 'react';
import { PropTypes, oneOfType } from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as activePageActions from 'actions/activePageActions';
import * as decisionTreeActions from 'actions/decisionTreeActions';
import * as claimActions from 'actions/claimActions';
import * as triageStatusActions from 'actions/triageStatusActions';
import * as authenticationActions from 'actions/authenticationActions';
import * as alertsActions from 'actions/alertsActions';
import { appendPolicyToTree, selectPolicy } from 'actions/feature/policy';
import {
  appendGencodeToTree,
  appendCardNumberToTree
} from 'actions/feature/card';
import { saveClaimAndDecline } from 'actions/feature/claim';

import { Button } from 'components/shared/Forms';
import GroupQuestion from 'components/GroupQuestion';

import { event } from 'utils/analytics';
import { GA_EVENTS, GA_ACTIONS } from 'constants/googleAnalytics';
import { appInsights } from 'ApplicationInsights';

export class Questionnaire extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showContinue: null
    };

    this.gotoLandingPage = this.gotoLandingPage.bind(this);
    this.handleGroupQuestionAnswer = this.handleGroupQuestionAnswer.bind(this);
    this.handleValidPolicyNumber = this.handleValidPolicyNumber.bind(this);
    this.handleDeleteLastUserAnswer = this.handleDeleteLastUserAnswer.bind(
      this
    );
    this.handleDeleteUserAnswersByQuestionId = this.handleDeleteUserAnswersByQuestionId.bind(
      this
    );
    this.updateContacts = this.updateContacts.bind(this);
    this.handleDeclineCurrentClaim = this.handleDeclineCurrentClaim.bind(this);
    this.handleValidCardNumber = this.handleValidCardNumber.bind(this);
  }

  handleValidCardNumber(number, obfuscated, isCardNumber, questionId) {
    if (isCardNumber) {
      this.props.actions.deleteAnswerByQuestionIdAndUpdateVisibleGroups(
        questionId
      );
      this.props.actions.appendCardNumberToTree(number, questionId);
    } else {
      this.props.actions.appendGencodeToTree(number, obfuscated, questionId);
    }
  }

  handleValidPolicyNumber(
    questionId,
    policyNumber,
    policyName,
    policyLastName,
    policyDate
  ) {
    this.props.actions.appendPolicyToTree(
      policyNumber,
      policyName,
      policyLastName,
      policyDate,
      questionId
    );
  }

  handleDeleteLastUserAnswer() {
    this.props.actions.deleteLastUserAnswerAndUpdateVisibleGroups();
  }

  handleDeleteUserAnswersByQuestionId(questionId) {
    this.props.actions.deleteAnswerByQuestionIdAndUpdateVisibleGroups(
      questionId
    );
  }

  handleDeclineCurrentClaim(reasonId, declinationType = null) {
    this.props.actions.saveClaimAndDecline(
      false,
      reasonId,
      this.props.languageId,
      declinationType
    );
  }

  handleGroupQuestionAnswer(newAnswers) {
    const showContinue =
      !this.props.disabledContinue &&
      ((newAnswers[0].nextGroupId === null && newAnswers[0].value !== false) ||
        newAnswers[0].nextGroupId !== null);

    this.setState({ showContinue });

    this.props.actions.updateUserAnswersAndVisibleGroups(newAnswers);
  }

  gotoLandingPage() {
    this.props.actions.gotoMyClaims();
  }

  handleOnContinue() {
    event(GA_EVENTS.QUESTIONNARIE, GA_ACTIONS.SUBMIT);
    appInsights.trackEvent(
      {
        name:'Questionnaire-Continue'
      },
      {
        EventCategory: GA_EVENTS.QUESTIONNARIE,
        EventAction: GA_ACTIONS.SUBMIT,
        EventLabel: 'Questionnaire-Continue'
      }
      )
    this.props.onContinue();
  }

  updateContacts(contacts) {
    const clone = { ...this.props.currentClaim };
    clone.contacts = contacts;
    this.props.actions.updateClaimData(clone);
  }

  updateCardsList(cards) {
    this.props.actions.setCardsList(cards);
  }

  render() {
    const answersLength = this.props.userAnswers.length;
    const answered =
      answersLength > 0 &&
      this.props.userAnswers[answersLength - 1] &&
      // All answers should have value assigned
      this.props.userAnswers.every(
        x => x.value !== null && x.value !== undefined
      ) &&
      this.props.userAnswers[answersLength - 1].nextGroupId === null;

    const displayVisibleGroups = visibleGroups =>
      visibleGroups.map(group => (
        <GroupQuestion
          key={group[0].id}
          groupQuestions={group}
          userAnswers={this.props.userAnswers}
          onGroupUserAnswer={this.handleGroupQuestionAnswer}
          onAddCard={this.updateCardsList}
          onValidCreditCard={this.handleValidCardNumber}
          onValidPolicyNumber={this.handleValidPolicyNumber}
          onDeclineCurrentClaim={this.handleDeclineCurrentClaim}
          onDeclineConfirmationNO={this.handleDeleteLastUserAnswer}
          onDeleteAnswersByQuestionId={this.handleDeleteUserAnswersByQuestionId}
          updateClaimData={this.props.actions.updateClaimData}
          gotoLandingPage={this.gotoLandingPage}
          labels={this.props.labels}
          updateContacts={this.updateContacts}
          contacts={this.props.currentClaim.contacts}
          cardsList={this.props.cardsList}
          policiesList={this.props.policiesList}
          activeCard={this.props.activeCard}
          claimId={this.props.currentClaim.id}
          benefitId={this.props.currentClaim.benefitId}
          onAddAlert={this.props.actions.addAlert}
          languageId={this.props.languageId}
          userInfo={this.props.userInfo}
          activePage={this.props.activePage}
          currentClaim={this.props.currentClaim}
        />
      ));
    return (
      <div className="row no-gutters">
        <div className="col">
          <div className="row no-gutters">
            <div className="col">
              {displayVisibleGroups(this.props.visibleGroups)}
            </div>
          </div>
          {(this.state.showContinue !== null
            ? this.state.showContinue && answered
            : answered) && (
            <div className="row mt-2">
              <div className="col">
                <Button
                  id="btn-questionnarie-continue"
                  type="button"
                  className="btn btn-primary btn-lg btn-block mt-3 btn-amex-continue"
                  onClick={() => this.handleOnContinue()}
                  disabled={this.props.disabledContinue}
                >
                  {this.props.labels.commonContinue}
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  visibleGroups: state.decisionTree.visibleGroups,
  userAnswers: state.decisionTree.userAnswers,
  currentClaim: state.currentClaim,
  inTriage: state.inTriage,
  labels: state.labels,
  cardsList: state.cardsList,
  policiesList: state.policiesList,
  contacts: state.currentClaim.contacts,
  activeCard: state.activeCard,
  activePage: state.activePage,
  languageId: state.marketLanguages.selectedLanguage,
  userInfo: state.userInfo
});

const mapDispatchToProps = dispatch => ({
  actions: {
    ...bindActionCreators(decisionTreeActions, dispatch),
    ...bindActionCreators(activePageActions, dispatch),
    ...bindActionCreators(claimActions, dispatch),
    ...bindActionCreators(authenticationActions, dispatch),
    ...bindActionCreators(triageStatusActions, dispatch),
    ...bindActionCreators(alertsActions, dispatch),
    ...bindActionCreators(
      {
        appendPolicyToTree,
        selectPolicy,
        appendGencodeToTree,
        appendCardNumberToTree,
        saveClaimAndDecline
      },
      dispatch
    )
  }
});
Questionnaire.propTypes = {
  visibleGroups: PropTypes.arrayOf(
    oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.object)])
  ).isRequired,
  userAnswers: PropTypes.arrayOf(PropTypes.object).isRequired,
  onContinue: PropTypes.func.isRequired,
  actions: PropTypes.shape({
    gotoMyClaims: PropTypes.func.isRequired,
    updateUserAnswersAndVisibleGroups: PropTypes.func.isRequired,
    appendCreditCardTree: PropTypes.func.isRequired,
    deleteLastUserAnswerAndUpdateVisibleGroups: PropTypes.func.isRequired,
    deleteAnswerByQuestionIdAndUpdateVisibleGroups: PropTypes.func.isRequired,
    setInTriage: PropTypes.func.isRequired,
    updateClaimData: PropTypes.func,
    setCardsList: PropTypes.func.isRequired,
    addUserCard: PropTypes.func.isRequired,
    resetUserAnswers: PropTypes.func.isRequired,
    addAlert: PropTypes.func.isRequired,
    selectPolicy: PropTypes.func.isRequired,
    appendPolicyToTree: PropTypes.func.isRequired,
    appendGencodeToTree: PropTypes.func.isRequired,
    appendCardNumberToTree: PropTypes.func.isRequired,
    saveClaimAndDecline: PropTypes.func.isRequired
  }).isRequired,
  labels: PropTypes.shape({
    commonContinue: PropTypes.string.isRequired
  }).isRequired,
  contacts: PropTypes.arrayOf(PropTypes.shape({})),
  cardsList: PropTypes.arrayOf(PropTypes.shape({})),
  policiesList: PropTypes.arrayOf(PropTypes.shape({})),
  activeCard: PropTypes.shape({}),
  activePage: PropTypes.string.isRequired,
  userInfo: PropTypes.shape({}),
  currentClaim: PropTypes.shape({
    id: PropTypes.string,
    contacts: PropTypes.arrayOf(PropTypes.shape({})),
    benefitId: PropTypes.number
  }).isRequired,
  disabledContinue: PropTypes.bool,
  languageId: PropTypes.number.isRequired
};

Questionnaire.defaultProps = {
  contacts: [],
  cardsList: [],
  policiesList: [],
  activeCard: {},
  userInfo: {},
  disabledContinue: false
};

export default connect(mapStateToProps, mapDispatchToProps)(Questionnaire);
