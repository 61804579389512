import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import * as activePageActions from '../../../actions/activePageActions';

import AutoLogout from '../../../shared/containers/AutoLogout';
import TopHeader from '../../../shared/containers/TopHeader';
import Footer from '../../../shared/components/Footer';
import Alerts from '../../../containers/Alerts';

const BodiamLayout = props => (
  <AutoLogout>
    <div className="container-fluid bodiam-layout">
      <div className="d-block">
        <TopHeader />
      </div>
      <Alerts />
      <div className="mt-4 mb-4">{props.children}</div>
      <div className="d-block">
        <Footer />
      </div>
    </div>
  </AutoLogout>
);

const mapStateToProps = state => ({
  activePage: state.activePage
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(activePageActions, dispatch)
});

BodiamLayout.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
  actions: PropTypes.shape({
    gotoSignIn: PropTypes.func.isRequired,
    gotoTriageWrapper: PropTypes.func.isRequired,
    gotoMyClaims: PropTypes.func.isRequired,
    gotoContactsPage: PropTypes.func.isRequired,
    gotoFAQPage: PropTypes.func.isRequired,
    gotoCookiesPage: PropTypes.func.isRequired
  }).isRequired
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(BodiamLayout)
);
