// Disabling reason: using callback in setState does not work as expected
/* eslint-disable react/no-access-state-in-setstate */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { withTranslation } from 'react-i18next';
import { Row, Col } from 'components/shared/Table';
import BackgroundLayout from 'components/Layout/BackgroundLayout';
import SignInForm from 'components/SignInForm';
import { formTypes, MY_CLAIMS_PAGE } from 'constants/constants';
import RecoverPasswordForm from 'components/RecoverPasswordForm';

import * as authenticationActions from 'actions/authenticationActions';
import * as activePageActions from 'actions/activePageActions';
import * as userActions from 'actions/feature/user';

import { pageview, event } from 'utils/analytics';
import { getRoute } from 'utils/routeHelper';

import { GA_EVENTS, GA_ACTIONS } from 'constants/googleAnalytics';
import { appInsights } from 'ApplicationInsights';

class SignInPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeForm: formTypes.SIGN_IN,
      registrationData: {
        email: '',
        password: ''
      }
    };

    this.handleRegistrationDataChange = this.handleRegistrationDataChange.bind(
      this
    );
    this.gotoLandingPage = this.gotoLandingPage.bind(this);
    this.displayContent = this.displayContent.bind(this);
    this.handleChangeDisplay = this.handleChangeDisplay.bind(this);
    this.handleSignAction = this.handleSignAction.bind(this);
    this.handleRecoverPasswordAction = this.handleRecoverPasswordAction.bind(
      this
    );
  }

  componentDidMount = () => {
    pageview({ title: 'User', page: getRoute('user', 'signIn') });
    appInsights.trackPageView(
      { name: 'User', 
      uri: getRoute('user', 'signIn') }
    )
  };

  handleDisplayForgot = () => {
    event(GA_EVENTS.USERACCOUNT, GA_ACTIONS.CLICK, {
      label: formTypes.FORGOT_PASSWORD
    });

    appInsights.trackEvent(
      {
        name:formTypes.FORGOT_PASSWORD
      },
      {
        EventCategory: GA_EVENTS.USERACCOUNT,
        EventAction: GA_ACTIONS.SUBMIT,
        EventLabel: formTypes.FORGOT_PASSWORD
      }
      )
    this.handleChangeDisplay(formTypes.FORGOT_PASSWORD);
  };

  handleRegistrationDataChange(e) {
    this.setState({
      ...this.state,
      registrationData: {
        ...this.state.registrationData,
        [e.target.name]: e.target.value
      }
    });
  }

  handleSignAction(email, password) {
    event(GA_EVENTS.USERACCOUNT, GA_ACTIONS.SUBMIT, {
      label: formTypes.SIGN_IN
    });
    appInsights.trackEvent(
      {
        name:formTypes.SIGN_IN
      },
      {
        EventCategory: GA_EVENTS.USERACCOUNT,
        EventAction: GA_ACTIONS.SUBMIT,
        EventLabel: formTypes.SIGN_IN
      }
      )
    this.props.actions.signInUser(email, password, MY_CLAIMS_PAGE);
  }

  handleRecoverPasswordAction() {
    event(GA_EVENTS.USERACCOUNT, GA_ACTIONS.SUBMIT, {
      label: formTypes.FORGOT_PASSWORD
    });

    appInsights.trackEvent(
      {
        name:formTypes.FORGOT_PASSWORD
      },
      {
        EventCategory: GA_EVENTS.USERACCOUNT,
        EventAction: GA_ACTIONS.SUBMIT,
        EventLabel: formTypes.FORGOT_PASSWORD
      }
      )
    this.props.actions.userRecoverPassword(this.state.registrationData.email);
    this.handleChangeDisplay(formTypes.FORGOT_PASSWORD_SENT);
  }

  handleChangeDisplay(type) {
    this.setState({ activeForm: type });
  }

  gotoLandingPage() {
    this.props.actions.gotoLandingPage();
  }

  displayContent() {
    switch (this.state.activeForm) {
      case formTypes.SIGN_IN:
        return (
          <SignInForm
            onForgotPassword={this.handleDisplayForgot}
            handleSignAction={this.handleSignAction}
          />
        );

      case formTypes.FORGOT_PASSWORD:
        return <RecoverPasswordForm />;

      case formTypes.FORGOT_PASSWORD_SENT:
        return (
          <Row>
            <Col className="mt-5 mb-5">
              <h5>{this.props.t('ForgotPasswardEmailSent')}</h5>
            </Col>
          </Row>
        );
      default:
        return '';
    }
  }

  render() {
    return (
      <BackgroundLayout
        title={this.props.t('SignInPageTitle')}
        onBackButtonClick={() => this.gotoLandingPage()}
        backButtonText={this.props.t('commonBack')}
      >
        {this.displayContent()}
      </BackgroundLayout>
    );
  }
}

SignInPage.propTypes = {
  t: PropTypes.func.isRequired,
  actions: PropTypes.shape({
    signInUser: PropTypes.func,
    userRecoverPassword: PropTypes.func,
    gotoLandingPage: PropTypes.func
  }).isRequired
};

const mapStateToProps = state => ({
  labels: state.labels,
  errorOnLogIn: state.authentication.errorMessageOnLogin
});

const mapDispatchToProps = dispatch => ({
  actions: {
    ...bindActionCreators(authenticationActions, dispatch),
    ...bindActionCreators(activePageActions, dispatch),
    ...bindActionCreators(userActions, dispatch)
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(SignInPage));
