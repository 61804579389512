import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';

import Slide from '@material-ui/core/Slide';

import { Col } from 'components/shared/Table';
import ExpensesCategories from './ExpensesCategories';
import ExpensesWhoPurchases from './ExpensesWhoPurchases';

const ExpensesSection = props => {
  const shouldShowWhoPurchases = !isEmpty(props.categories);
  return (
    <Slide in direction="up" mountOnEnter unmountOnExit>
      <Col className="mt-4 mb-4 mr-3 ml-3">
        <ExpensesCategories
          typeOfExpenses={props.typeOfExpenses}
          selectedCat={props.categories}
          onCategoryClick={props.handleClick}
        />
        {shouldShowWhoPurchases && (
          <ExpensesWhoPurchases
            contacts={props.contacts}
            updateContacts={props.updateContacts}
            handleDisplayPurchases={props.handleDisplayPurchases}
            languageId={props.languageId}
          />
        )}
      </Col>
    </Slide>
  );
};

ExpensesSection.propTypes = {
  typeOfExpenses: PropTypes.arrayOf(PropTypes.number).isRequired,
  categories: PropTypes.shape({}).isRequired,
  contacts: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired,
  updateContacts: PropTypes.func.isRequired,
  handleDisplayPurchases: PropTypes.func.isRequired,
  handleClick: PropTypes.func.isRequired,
  languageId: PropTypes.number.isRequired
};

export default ExpensesSection;
