import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ContentLoader from 'react-content-loader';

import {
  bodiamRemoveCardAction,
  emptyBodiamAction,
  bodiamAddCardAction,
  gotoMyClaims
} from 'actions/activePageActions';
import { setActiveCardCookies } from 'actions/activeCardActions';
import { getCardDetails, getUserCards } from 'actions/authenticationActions';

import CarouselComponent from 'components/shared/CarouselComponent';

import history from 'utils/history';
import { getMarketFromUrl } from 'utils/urlHelpers';
import { resetHistory } from 'utils/sessionController';

import {
  BODIAM_ACTIONS,
  DEFAULT_CARD_IMAGE,
  BODIAM_DASHBOARD,
  CAROUSEL_CLASSES,
  MAX_CARDS,
  ARUNDEL_BASE_URL
} from 'constants/constants';
import { getActiveCard } from 'store/selectors/activeCard';

export class CardsCarousel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      slides: null
    };

    this.onChangeCurrentSlide = this.onChangeCurrentSlide.bind(this);
    this.onAddCard = this.onAddCard.bind(this);
  }

  async componentDidMount() {
    if (this.props.cardsList && this.props.cardsList.length) {
      this.buildSlidesForCarousel();
    } else {
      this.props.actions.getUserCards();
    }
  }

  componentDidUpdate(prevProps) {
    if (
      ((!prevProps.cardsList ||
        prevProps.cardsList.length !== this.props.cardsList.length) &&
        this.props.labels.bodiamCardProduct &&
        this.props.cardsList) ||
      (!prevProps.labels.bodiamCardProduct &&
        this.props.labels.bodiamCardProduct &&
        this.props.cardsList)
    ) {
      this.buildSlidesForCarousel();
    }

    // IF A CARD IS REMOVED
    if (
      prevProps.bodiamAction === BODIAM_ACTIONS.REMOVE_CARD &&
      this.props.bodiamAction === BODIAM_ACTIONS.REMOVED_CARD
    ) {
      if (this.props.cardsList && this.props.cardsList.length) {
        this.props.actions.setActiveCardCookies(this.props.cardsList[0], 0);
        this.buildSlidesForCarousel();
      }
      this.props.actions.emptyBodiamAction();
    }
  }

  buildSlidesForCarousel = () => {
    const {
      props: { cardsList }
    } = this;
    const slides = cardsList.map(card => this.buildSlide(card));
    this.setState({ slides });
  };

  /**
   * Creates a property title and sets a default imageURL property value in case it's not defined in the original object
   */
  buildSlide = card => {
    const clone = { ...card };
    const imgURL = card.imageURL || DEFAULT_CARD_IMAGE;
    clone.imageURL = imgURL;
    clone.title = `${this.props.labels && this.props.labels.bodiamCardProduct}`;
    clone.cardName = card.realName;
    return clone;
  };

  onChangeCurrentSlide = currentSlide => {
    this.props.actions.setActiveCardCookies(
      this.props.cardsList[currentSlide],
      currentSlide
    );
  };

  onAddCard = () => {
    const arundelUrl = `${ARUNDEL_BASE_URL}${getMarketFromUrl()}`;
    resetHistory(BODIAM_DASHBOARD);
    this.props.actions.bodiamAddCardAction();
    this.props.actions.gotoMyClaims();
    history.push(arundelUrl);
  };

  onRemoveCard = () => {
    const arundelUrl = `${ARUNDEL_BASE_URL}${getMarketFromUrl()}`;
    resetHistory(BODIAM_DASHBOARD);
    this.props.actions.bodiamRemoveCardAction();
    this.props.actions.getCardDetails(this.props.activeCard.id, () => {
      this.props.actions.gotoMyClaims();
      history.push(arundelUrl);
    });
  };

  render() {
    const {
      state: { slides }
    } = this;
    return (
      <div className="mb-3">
        {this.props.title && <h1>{this.props.title}</h1>}
        <div>
          {Array.isArray(slides) && slides.length ? (
            <CarouselComponent
              extraClassName={CAROUSEL_CLASSES.CARDS}
              showAddSlide={slides.length < MAX_CARDS}
              showRemoveSlide={slides.length > 1}
              slides={slides}
              onSlideChange={this.onChangeCurrentSlide}
              onAddSlide={this.onAddCard}
              onRemoveSlide={this.onRemoveCard}
              currentSlide={this.props.cardsList.indexOf(
                x => x.number === this.props.activeCard.number
              )}
              showIndicators={slides.length <= MAX_CARDS}
            />
          ) : (
            <CardLoader />
          )}
        </div>
      </div>
    );
  }
}

const CardLoader = props => (
  <ContentLoader
    speed={2}
    width={600}
    height={360}
    viewBox="0 0 600 360"
    backgroundColor="#f3f3f3"
    foregroundColor="#e8e8e8"
    {...props}
  >
    <rect x="115" y="44" rx="3" ry="3" width="380" height="19" />
    <rect x="214" y="23" rx="3" ry="3" width="178" height="15" />
    <rect x="208" y="75" rx="3" ry="3" width="178" height="15" />
    <rect x="102" y="101" rx="0" ry="0" width="398" height="252" />
    <circle cx="50" cy="202" r="14" />
    <circle cx="551" cy="200" r="14" />
  </ContentLoader>
);

const mapStateToProps = state => ({
  labels: state.labels,
  cardsList: state.cardsList,
  activeCard: getActiveCard(state),
  bodiamAction: state.bodiamAction
});

const mapDispatchToProps = dispatch => ({
  actions: {
    ...bindActionCreators(
      {
        bodiamRemoveCardAction,
        emptyBodiamAction,
        bodiamAddCardAction,
        gotoMyClaims,
        setActiveCardCookies,
        getCardDetails,
        getUserCards
      },
      dispatch
    )
  }
});

CardsCarousel.propTypes = {
  title: PropTypes.string,
  labels: PropTypes.shape({
    bodiamCardProduct: PropTypes.string
  }).isRequired,
  actions: PropTypes.shape({
    bodiamRemoveCardAction: PropTypes.func.isRequired,
    emptyBodiamAction: PropTypes.func.isRequired,
    bodiamAddCardAction: PropTypes.func.isRequired,
    gotoMyClaims: PropTypes.func.isRequired,
    setActiveCardCookies: PropTypes.func.isRequired,
    getCardDetails: PropTypes.func.isRequired,
    getUserCards: PropTypes.func.isRequired
  }).isRequired,
  activeCard: PropTypes.shape({
    id: PropTypes.number,
    number: PropTypes.string,
    amexCode: PropTypes.string,
    amexMarket: PropTypes.string,
    imageURL: PropTypes.string
  }).isRequired,
  bodiamAction: PropTypes.string,
  cardsList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    })
  ).isRequired
};

CardsCarousel.defaultProps = {
  title: '',
  bodiamAction: ''
};

export default connect(mapStateToProps, mapDispatchToProps)(CardsCarousel);
