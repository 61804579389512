import { object, string, number, date, bool } from 'yup';
import moment from 'moment';
import { ERRORS } from 'constants/constants';
import { maxLength } from 'constants/inputsLengths';

// eslint-disable-next-line import/prefer-default-export
export const schemaValidation = object({
  title: string().required(),
  firstName: string()
    .max(maxLength.firstName)
    .required(ERRORS.INVALID_TEXT)
    .typeError(ERRORS.INVALID_TEXT),
  surnames: string()
    .max(maxLength.surnames)
    .required(ERRORS.INVALID_TEXT)
    .typeError(ERRORS.INVALID_TEXT),
  dob: date()
    .transform(rawValue => moment(rawValue).toDate())
    .required(ERRORS.INVALID_TEXT)
    .max(moment(), ERRORS.DOB_INCORRECT)
    .typeError(ERRORS.DOB_INCORRECT),
  relationship: number()
    .required(ERRORS.INVALID_TEXT)
    .typeError(ERRORS.INVALID_TEXT),
  isSecondaryCardHolder: bool().required(),
  isCoLiving: bool().required()
});
