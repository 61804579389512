import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Row, Col } from 'components/shared/Table';
import { Button } from 'components/shared/Forms';
import ButtonBack from 'components/shared/Custom/ButtonBack';

import AddCardComponent from 'components/AddCardComponent';
import AddPolicyComponent from 'components/AddPolicyComponent';

const AddProductElementContainer = props => {
  const { t } = useTranslation();
  return (
    <Row>
      <Col>
        <Row>
          <Col>
            <ButtonBack
              onClick={() => {
                props.toggleMethod();
              }}
              labelText={t('buttonBackAddCard')}
            />
          </Col>
        </Row>
        {props.children}
        <Row>
          <Col className="text-center">
            <Button
              type="button"
              className="btn btn-link"
              onClick={() => props.toggleMethod()}
              text={t('commonCancel')}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

AddProductElementContainer.propTypes = {
  userInfo: PropTypes.shape({
    name: PropTypes.string,
    surname: PropTypes.string,
    dateOfBirth: PropTypes.string
  }).isRequired,
  toggleMethod: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([AddCardComponent, AddPolicyComponent])
};

AddProductElementContainer.defaultProps = {
  children: undefined
};

export default AddProductElementContainer;
