import React from 'react';
import PropTypes from 'prop-types';

const CalendarHeaderComponent = props => (
  <div className="row no-gutters calendar-header">
    <div className="col text-center">
      {props.displayDate.startOf('isoWeek').format('dd')}
    </div>
    <div className="col text-center">
      {props.displayDate.add(1, 'days').format('dd')}
    </div>
    <div className="col text-center">
      {props.displayDate.add(1, 'days').format('dd')}
    </div>
    <div className="col text-center">
      {props.displayDate.add(1, 'days').format('dd')}
    </div>
    <div className="col text-center">
      {props.displayDate.add(1, 'days').format('dd')}
    </div>
    <div className="col text-center">
      {props.displayDate.add(1, 'days').format('dd')}
    </div>
    <div className="col text-center">
      {props.displayDate.add(1, 'days').format('dd')}
    </div>
  </div>
);

CalendarHeaderComponent.propTypes = {
  displayDate: PropTypes.shape({
    add: PropTypes.func.isRequired,
    startOf: PropTypes.func.isRequired
  }).isRequired
};

export default CalendarHeaderComponent;
