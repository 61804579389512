import React from 'react';
import PropTypes from 'prop-types';
// import { pageview } from 'utils/analytics';
import { BODIAM_BASE_URL } from 'constants/constants';
import history from 'utils/history';
import { getMarketFromUrl } from 'utils/urlHelpers';
import forms from './forms.svg';
import lens from './lens.svg';
import pen from './pen.svg';
import file from './file.svg';

const MainMenu = props => {
  const menuItems = [
    {
      label: 'bodiamReviewYourClaims',
      muiIcon: forms,
      onClick: () => {
        props.goToMyAccount();
      }
    },
    {
      muiIcon: pen,
      label: 'bodiamMakeClaim',
      onClick: () => {
        props.newClaim();
      }
    },
    {
      label: 'bodiamFindMedicalProviders',
      muiIcon: lens,
      onClick: () => {
        history.push(`${BODIAM_BASE_URL}${getMarketFromUrl()}/providers`);
      }
    },
    {
      label: 'bodiamGetInsuranceCertificate',
      muiIcon: file,
      onClick: () => {
        history.push(`${BODIAM_BASE_URL}${getMarketFromUrl()}/certificate`);
      }
    }
  ];

  const buildItem = (item, key) => (
    <div
      onClick={item.onClick}
      role="button"
      tabIndex={key}
      onKeyDown={item.onClick}
      className="col-3"
      key={key}
    >
      <div className="box">
        <img
          className="img"
          alt={props.labels[item.label]}
          src={item.muiIcon}
        />
        {props.labels[item.label] ? (
          <span className="text">{props.labels[item.label]}</span>
        ) : (
          <span className="placeholder" />
        )}
      </div>
    </div>
  );

  const items = menuItems.map((item, key) => buildItem(item, key));

  return (
    <div className="container bodiam-menu">
      <div className="row">{items}</div>
    </div>
  );
};
MainMenu.propTypes = {
  goToMyAccount: PropTypes.func.isRequired,
  labels: PropTypes.shape({
    bodiamGetInsuranceCertificate: PropTypes.string,
    bodiamFindMedicalProviders: PropTypes.string,
    bodiamMakeClaim: PropTypes.string
  }).isRequired,
  newClaim: PropTypes.func.isRequired
};

export default MainMenu;
