export default {
  labels: {},
  marketLanguages: {},
  decisionTree: {
    questionnaireTreeByGroupId: {},
    visibleGroups: [],
    userAnswers: [],
    currentTree: 'Triage'
  },
  ajaxCallsInProgress: 0,
  authentication: {
    errorMessageOnLogin: null,
    token: null
  },
  userInfo: {},
  activePage: 'LANDING_PAGE',
  alerts: [],
  currentClaim: {
    affectedPersons: []
  },
  currentStep: {
    step: 0,
    lastStep: 0
  },
  inTriage: false,
  claimsList: [],
  claimDocumentsList: {},
  cardsList: [],
  policiesList: [],
  productDetail: null,
  activeCard: null,
  activePolicy: null,
  bodiamAction: null,
  prismicEvents: [],
  configuration: {},
  card: {
    gencodeCard: null,
    benefits: [],
    validation: null
  },
  policy: {
    number: null,
    name: null,
    lastName: null,
    date: null
  },
  policyValidation: null,
  medicalProvider: {
    categories: [],
    specialities: [],
    result: [],
    query: {
      location: {
        latitude: '',
        longitude: '',
        title: ''
      },
      category: '',
      speciality: ''
    },
    mapVisible: false,
    isLoading: false
  },
  error: {
    label: undefined,
    message: undefined
  },
  showPopupV2: true,
  howToClaimLinks: {},
  fileUpload: {},
  cookiesConsent: false,
  datesValidation: {
    result: null,
    errorMessage: undefined
  },
  countries: []
};
