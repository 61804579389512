import React from 'react';
import propTypes from 'prop-types';
import { RichText } from 'prismic-reactjs';
import { LinkResolver } from '../../../utils/PrismicHelper';

const PolicySummaryDescriptionWithIcon = props => (
  <>
    {props.icon.url && (
      <img src={props.icon.url} alt={props.icon.alt} width={60} heigth={60} />
    )}
    {props.description && RichText.render(props.description, LinkResolver)}
  </>
);

PolicySummaryDescriptionWithIcon.propTypes = {
  description: propTypes.string.isRequired,
  icon: propTypes.shape({
    url: propTypes.string.isRequired,
    alt: propTypes.string.isRequired
  }).isRequired
};

export default PolicySummaryDescriptionWithIcon;
