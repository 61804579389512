import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useTranslation } from 'react-i18next';

import { Row, Col } from 'components/shared/Table';
import { Button } from 'components/shared/Forms';
import ClaimsListBoxComponent from 'components/ClaimsListBoxComponent';
import ProductNumberDetailsList from 'components/ProductNumberDetailsList';
import UserInfoCard from 'components/UserInfoCard';

import * as activePageActions from 'actions/activePageActions';
import * as authenticationActions from 'actions/authenticationActions';
import { selectClaimDocumentsList, getDocuments } from 'actions/feature/claim';
import * as claimActions from 'actions/claimActions';
import { goTo } from 'actions/core/app.navigation';
import actionProvider from 'utils/action-provider/action-provider';

import { withConfig } from 'ConfigurationProvider';

import { UPLOAD_CLAIM_DOCUMENTS } from 'constants/constants';
import { GA_EVENTS, GA_ACTIONS } from 'constants/googleAnalytics';

import { pageview, event } from 'utils/analytics';
import { getRoute } from 'utils/routeHelper';
import { appInsights } from 'ApplicationInsights';

export const MyClaimsPage = props => {
  useEffect(() => {
    pageview({ title: 'User', page: getRoute('user', 'profile') });
    appInsights.trackPageView(
      {
        name: 'User', uri: getRoute('user', 'profile')
      }
    )
    props.actions.emptyCurrentClaim();
  }, [props.actions]);
  const { t } = useTranslation();
  const goToEditProfilePage = () => {
    props.toggleEditProfile();
  };
  const userNameValue = `${props.userInfo &&
    props.userInfo.name} ${props.userInfo && props.userInfo.surname}`;
  const showTitle = () => {
    return props.config.SHOW_WELCOME.title && props.userInfo.title
      ? t(props.userInfo.title)
      : '';
  };
  const showName = () => {
    return props.config.SHOW_WELCOME.name && props.userInfo.name
      ? props.userInfo.name
      : '';
  };
  const showSurname = () => {
    return props.config.SHOW_WELCOME.surname && props.userInfo.surname
      ? props.userInfo.surname
      : '';
  };
  const gotoUploadPageHandler = claim => {
    props.actions.selectClaimDocumentsList(claim);
    props.actions.goTo(UPLOAD_CLAIM_DOCUMENTS);
  };
  const handleGoToClaim = claimId => {
    event(GA_EVENTS.PROFILE, GA_ACTIONS.CLICK, {
      label: 'openClaim'
    });
    appInsights.trackEvent(
      {
        name:'openClaim'
      },
      {
        EventCategory: GA_EVENTS.PROFILE,
        EventAction: GA_ACTIONS.CLICK,
        EventLabel: 'openClaim'
      }
      )
    props.actions.gotoClaim(claimId);
  };
  const handleGotoTriageWrapper = () => {
    event(GA_EVENTS.PROFILE, GA_ACTIONS.CLICK, {
      label: 'newClaim'
    });
    appInsights.trackEvent(
      {
        name:'newClaim'
      },
      {
        EventCategory: GA_EVENTS.PROFILE,
        EventAction: GA_ACTIONS.CLICK,
        EventLabel: 'newClaim'
      }
      )
    props.actions.gotoTriageWrapper();
  };
  const handleAddPolicy = () => {
    event(GA_EVENTS.PROFILE, GA_ACTIONS.CLICK, {
      label: 'addNewPolicy'
    });
    appInsights.trackEvent(
      {
        name:'addNewPolicy'
      },
      {
        EventCategory: GA_EVENTS.PROFILE,
        EventAction: GA_ACTIONS.CLICK,
        EventLabel: 'addNewPolicy'
      }
      )
	  
    props.toggleAddPolicy();
  };
  const handleAddCard = () => {
    event(GA_EVENTS.PROFILE, GA_ACTIONS.CLICK, {
      label: 'addNewCard'
    });
    appInsights.trackEvent(
      {
        name:'addNewCard'
      },
      {
        EventCategory: GA_EVENTS.PROFILE,
        EventAction: GA_ACTIONS.CLICK,
        EventLabel: 'addNewCard'
      }
      )
    props.toggleAddCard();
  };
  return (
    <Row>
      <Col>
        <Row>
          <Col>
            <h2>
              {`${t(
                'commonWelcome'
              )} ${showTitle()} ${showName()} ${showSurname()}`}
            </h2>
          </Col>
        </Row>
        <Row>
          <Col className="col-12 col-sm-12 col-md-5">
            <Row className="filler">
              <Col />
            </Row>
            <Row>
              <Col>
                <UserInfoCard
                  userInfo={props.userInfo}
                  userName={`${props.userInfo.name} ${props.userInfo.surname}`}
                  toggleEditProfile={goToEditProfilePage}
                />
              </Col>
            </Row>
            {props.config.CREDITCARD.enabled && (
              <>
                <Row className="pt-2 pt-md-0 mb-3">
                  <Col>
                    <ProductNumberDetailsList
                      list={props.cardsList}
                      listTitle={t('commonMyCards')}
                      userName={userNameValue}
                      getItemInfo={props.actions.getCardDetails}
                      toggleItemProfile={props.toggleProductItemProfile}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col />
                  <Col className="col-8 text-center mb-4">
                    {props.cardsList.length < 5 && (
                      <Button
                        type="button"
                        className="btn btn-add-card text-center"
                        onClick={handleAddCard}
                      >
                        <i className="material-icons rounded-circle add-card-icon">
                          control_point
                        </i>
                        <p>{t('commonAddCard')}</p>
                      </Button>
                    )}
                  </Col>
                  <Col />
                </Row>
              </>
            )}
            {props.config.RETAIL.enabled && (
              <>
                <Row className="pt-2 pt-md-0">
                  <Col>
                    <ProductNumberDetailsList
                      list={props.policiesList}
                      listTitle={t('commonMyPolicies')}
                      userName={userNameValue}
                      getItemInfo={props.actions.getPolicyDetails}
                      toggleItemProfile={props.togglePolicyItemProfile}
                      isCardsList={false}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col />
                  <Col className="col-8 text-center mb-4">
                    <Button
                      type="button"
                      className="btn btn-add-card text-center"
                      onClick={handleAddPolicy}
                    >
                      <i className="material-icons rounded-circle add-card-icon">
                        control_point
                      </i>
                      <p>{t('commonAddPolicy')}</p>
                    </Button>
                  </Col>
                  <Col />
                </Row>
              </>
            )}
          </Col>
          <Col className="col-12 col-sm-12 col-md-7">
            <ClaimsListBoxComponent
              myClaims={props.claimsList}
              userName={`${props.userInfo.name} ${props.userInfo.surname}`}
              gotoTriageWrapper={handleGotoTriageWrapper}
              gotoClaim={handleGoToClaim}
              gotoUploadClaimDocuments={gotoUploadPageHandler}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

const mapStateToProps = state => ({
  userInfo: state.userInfo,
  claimsList: state.claimsList,
  cardsList: state.cardsList,
  policiesList: state.policiesList
});

const mapDispatchToProps = dispatch => ({
  actions: {
    ...bindActionCreators(authenticationActions, dispatch),
    ...bindActionCreators(activePageActions, dispatch),
    ...bindActionCreators(claimActions, dispatch),
    ...bindActionCreators({ goTo }, dispatch),
    ...bindActionCreators(
      {
        getDocuments,
        selectClaimDocumentsList,
        emptyCurrentClaim: actionProvider.getClaimActions().emptyCurrentClaim
      },
      dispatch
    )
  }
});

MyClaimsPage.propTypes = {
  actions: PropTypes.shape({
    getCardDetails: PropTypes.func.isRequired,
    getPolicyDetails: PropTypes.func.isRequired,
    gotoClaim: PropTypes.func.isRequired,
    gotoTriageWrapper: PropTypes.func.isRequired,
    goTo: PropTypes.func.isRequired,
    selectClaimDocumentsList: PropTypes.func.isRequired,
    emptyCurrentClaim: PropTypes.func.isRequired
  }).isRequired,
  config: PropTypes.shape({
    RETAIL: PropTypes.shape({
      enabled: PropTypes.bool.isRequired
    }).isRequired,
    CREDITCARD: PropTypes.shape({
      enabled: PropTypes.bool.isRequired
    }).isRequired,
    SHOW_WELCOME: PropTypes.shape({
      title: PropTypes.bool.isRequired,
      name: PropTypes.bool.isRequired,
      surname: PropTypes.bool.isRequired
    })
  }).isRequired,
  userInfo: PropTypes.shape({
    name: PropTypes.string,
    surname: PropTypes.string,
    title: PropTypes.string
  }).isRequired,
  claimsList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  cardsList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  policiesList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  toggleAddCard: PropTypes.func.isRequired,
  toggleAddPolicy: PropTypes.func.isRequired,
  toggleEditProfile: PropTypes.func.isRequired,
  toggleProductItemProfile: PropTypes.func.isRequired,
  togglePolicyItemProfile: PropTypes.func.isRequired
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withConfig(MyClaimsPage));
