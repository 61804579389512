import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { validateField, validateForm } from 'utils/formValidationHelper';
import { TextField, DateField } from 'components/shared/Forms';
import DeleteIconButton from 'components/shared/DeleteButton/DeleteIconButton';
import { Row, Col } from 'components/shared/Table';
import PersonRelationship from 'components/ContactsQuestion/Shared/PersonRelationship';
import YesNo from 'components/ContactsQuestion/Shared/YesNo';
import TitleSelectionGroup from 'components/TitleSelectionGroup/TitleSelectionGroup';
import { maxLength } from 'constants/inputsLengths';
import { schemaValidation } from './AdditionalClaimantSchemaValidation';

const AdditionalClaimantBasic = props => {
  const contact = {
    id: props.contact.id,
    isValid: props.contact.isValid,
    title: props.contact.title,
    firstName: props.contact.firstName,
    surnames: props.contact.surnames,
    dob: props.contact.dob,
    relationship: props.contact.relationship,
    relationshipDescription: props.contact.relationshipDescription,
    isSecondaryCardHolder: props.contact.isSecondaryCardHolder,
    isCoLiving: props.contact.isCoLiving
  };
  const [errorFields, setErrorFields] = useState({});
  const [formValues, setFormValues] = useState(contact);
  const { t } = useTranslation();

  /**
   * @param {string} selected
   */
  const onChangeTitle = selected => {
    const clone = { ...contact, title: selected };
    setFormValues(clone);
    props.onUpdateContact(clone);
  };

  const onInputChange = event => {
    setNewValues(event.target.name, event.target.value);
  };

  const setNewValues = (name, value) => {
    const newFormValues = {
      ...contact,
      [name]: value
    };
    validateAndUpdate(newFormValues);
  };

  const onRelationshipChange = (name, key, value) => {
    const clone = {
      ...contact,
      [name]: key,
      [`${name}Description`]: value
    };

    validateAndUpdate(clone);
  };

  const onYesNoQuestionChange = (name, value) => {
    const clone = {
      ...contact,
      [name]: value
    };
    validateAndUpdate(clone);
  };

  const isFormValid = values => {
    const result = validateForm(schemaValidation, values);
    return result.isValid;
  };

  const validateAndUpdate = values => {
    const clone = { ...values, isValid: isFormValid(values) };
    setFormValues(clone);
    props.onUpdateContact(clone);
  };

  const handleValidation = (fieldName, value) => {
    const result = validateField(schemaValidation, fieldName, value);
    setErrorFields({
      ...errorFields,
      ...result.errorFields
    });
  };

  return (
    <>
      {props.isRemovable && (
        <Row className="d-flex flex-row-reverse">
          <Col className="col-12 col-md-6">
            <DeleteIconButton onClick={props.onRemoveContact} />
          </Col>
        </Row>
      )}
      <div className="form-group form-check form-check-inline">
        <TitleSelectionGroup
          availableTitles={props.availableTitles}
          handleTitleSelection={onChangeTitle}
          selectedTitle={formValues.title}
          hasHelp
          isSelectedTitle={!!formValues.title}
          classNames={{
            labelWrapper: 'label-input col-12 px-0'
          }}
        />
      </div>
      <Row>
        <Col className="col-12 col-md-6">
          <TextField
            id="input-additionalClaimant-firstName"
            label={t('commonFormUserNameLabel')}
            name="firstName"
            value={formValues.firstName}
            onValueChange={onInputChange}
            onValidate={handleValidation}
            ariaDescribedby={t('commonFormUserNameLabel')}
            required
            errorFields={errorFields}
            maxLength={maxLength.firstName}
          />
        </Col>
      </Row>
      <Row>
        <Col className="col-12 col-md-6">
          <TextField
            id="input-additionalClaimant-surnames"
            label={t('commonFormSurnameLabel')}
            name="surnames"
            value={formValues.surnames}
            onValueChange={onInputChange}
            onValidate={handleValidation}
            ariaDescribedby={t('commonFormSurnamePlaceholder')}
            required
            errorFields={errorFields}
            maxLength={maxLength.surnames}
          />
        </Col>
      </Row>
      <Row>
        <Col className="col-12 col-md-6">
          <DateField
            id="input-additionalClaimant-date"
            label={t('commonFormDateLabel')}
            name="dob"
            value={formValues.dob}
            onValueChange={value => setNewValues('dob', value)}
            onValidate={handleValidation}
            ariaDescribedby={t('commonDateOfBirth')}
            placeholder={t('commonFormDatePlaceholder')}
            required
            errorFields={errorFields}
          />
        </Col>
      </Row>
      <div className="form-group form-check form-check-inline w-100">
        <PersonRelationship
          name="relationship"
          value={formValues.relationship}
          onChange={onRelationshipChange}
          isSelected={!!formValues.relationship}
          hasHelp
        />
      </div>
      <Row>
        <Col className="col-12 col-md-6">
          <YesNo
            name="isSecondaryCardHolder"
            label={t('beneficiaryContactFormSecondaryCardHolderLabel')}
            errorFields={errorFields}
            value={formValues.isSecondaryCardHolder}
            booleanValues
            hasHelp
            onChange={onYesNoQuestionChange}
          />
        </Col>
      </Row>
      <Row>
        <Col className="col-12 col-md-6">
          <YesNo
            name="isCoLiving"
            label={t('expenseBeneficiaryContactCoLiving')}
            errorFields={errorFields}
            value={formValues.isCoLiving}
            booleanValues
            hasHelp
            onChange={onYesNoQuestionChange}
          />
        </Col>
      </Row>
    </>
  );
};

AdditionalClaimantBasic.propTypes = {
  contact: PropTypes.shape({
    id: PropTypes.number,
    isValid: PropTypes.bool,
    title: PropTypes.string,
    firstName: PropTypes.string,
    surnames: PropTypes.string,
    dob: PropTypes.string,
    relationship: PropTypes.number,
    relationshipDescription: PropTypes.string,
    isSecondaryCardHolder: PropTypes.bool,
    isCoLiving: PropTypes.bool
  }).isRequired,
  availableTitles: PropTypes.shape({}).isRequired,
  onUpdateContact: PropTypes.func.isRequired,
  onRemoveContact: PropTypes.func.isRequired,
  isRemovable: PropTypes.bool
};

AdditionalClaimantBasic.defaultProps = {
  isRemovable: false
};

export default AdditionalClaimantBasic;
