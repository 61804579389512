/* eslint-disable react/no-unescaped-entities */
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'components/shared/Table';
import { pageview } from 'utils/analytics';
import { getRoute } from 'utils/routeHelper';


export const DataProtectionPage = () => {

    const { t } = useTranslation();
    const titlePage = text => <h4>{t(text)}</h4>;
    const titleSection = text => <h6>{t(text)}</h6>;
    const paragraphSection = text => <p className="content">{t(text)}</p>;
    
    return (
        <Row className="no-gutters dataprotection-page-box">
            <div className="dataprotection-page">
                <Col className="col">
                    {titlePage('[dataProtectionPage] Title')}
                    <br />
                    {paragraphSection('[dataProtectionPage] Paragraph1')}
                    <br />
                    {paragraphSection('[dataProtectionPage] Paragraph2')}
                    <br />
                    {paragraphSection('[dataProtectionPage] Paragraph3')}
                    <br />
                    {paragraphSection('[dataProtectionPage] Paragraph4')}
                    <br />                    
                    <span dangerouslySetInnerHTML={{ __html: t('[dataProtectionPage] Paragraph5') }}/>
                    <br /> <br />
                    {titleSection('[dataProtectionPage] SubTitle1')}                   
                    <br />                    
                    <span dangerouslySetInnerHTML={{ __html: t('[dataProtectionPage] SubTitle1 Paragraph') }}/>
                    <br /> <br />
                    {titleSection('[dataProtectionPage] SubTitle2')}                   
                    <br />                    
                    <span dangerouslySetInnerHTML={{ __html: t('[dataProtectionPage] SubTitle2 Paragraph') }}/>
                    <br /> <br />
                    {titleSection('[dataProtectionPage] SubTitle3')}                   
                    <br />                    
                    <span dangerouslySetInnerHTML={{ __html: t('[dataProtectionPage] SubTitle3 Paragraph') }}/>
                    <br /><br />
                    {titleSection('[dataProtectionPage] SubTitle3_1')} 
                    <br />
                    {paragraphSection('[dataProtectionPage] SubTitle3_1 Paragraph')}
                    <br />
                    {titleSection('[dataProtectionPage] SubTitle3_1_2')}
                    <br />
                    {paragraphSection('[dataProtectionPage] SubTitle3_1_2 Paragraph')}
                    <br />
                    {titleSection('[dataProtectionPage] SubTitle3_1_3')}
                    <br />
                    <span dangerouslySetInnerHTML={{ __html: t('[dataProtectionPage] SubTitle3_1_3 Paragraph') }}/>
                    <br /><br />
                    {titleSection('[dataProtectionPage] SubTitle3_1_4')}
                    <br />
                    {paragraphSection('[dataProtectionPage] SubTitle3_1_4 Paragraph')}
                    <br />
                    {titleSection('[dataProtectionPage] SubTitle3_1_5')}
                    <br />
                    <span dangerouslySetInnerHTML={{ __html: t('[dataProtectionPage] SubTitle3_1_5 Paragraph') }}/>
                    <br /><br />
                    {titleSection('[dataProtectionPage] SubTitle3_2')}
                    <br />
                    <span dangerouslySetInnerHTML={{ __html: t('[dataProtectionPage] SubTitle3_2_1 Paragraph') }}/>                    
                    <br /><br />
                    {titleSection('[dataProtectionPage] SubTitle4')}
                    <br />
                    {titleSection('[dataProtectionPage] SubTitle4_1')}
                    <br />
                    {paragraphSection('[dataProtectionPage] SubTitle4_1 Paragraph')}
                    <br />
                    {titleSection('[dataProtectionPage] SubTitle4_2')}
                    <br />
                    {paragraphSection('[dataProtectionPage] SubTitle4_2 Paragraph')}
                    <br />
                    {titleSection('[dataProtectionPage] SubTitle4_3')}
                    <br />
                    {paragraphSection('[dataProtectionPage] SubTitle4_3 Paragraph')}
                    <br />
                    {titleSection('[dataProtectionPage] SubTitle4_4')}
                    <br />
                    {paragraphSection('[dataProtectionPage] SubTitle4_4 Paragraph')}
                    <br />
                    {titleSection('[dataProtectionPage] SubTitle4_5')}
                    <br />
                    {paragraphSection('[dataProtectionPage] SubTitle4_5 Paragraph')}                    
                    <br /> <br />
                    {titleSection('[dataProtectionPage] SubTitle5')} 
                    <br />
                    <span dangerouslySetInnerHTML={{ __html: t('[dataProtectionPage] SubTitle5 Paragraph') }}/>
                    <br /> <br />
                    {titleSection('[dataProtectionPage] SubTitle6')} 
                    <br />
                    <span dangerouslySetInnerHTML={{ __html: t('[dataProtectionPage] SubTitle6 Paragraph') }}/> 
                    <br /> <br />
                    {titleSection('[dataProtectionPage] SubTitle7')} 
                    <br />
                    <span dangerouslySetInnerHTML={{ __html: t('[dataProtectionPage] SubTitle7 Paragraph1') }}/>                   
                    <br />
                    <span dangerouslySetInnerHTML={{ __html: t('[dataProtectionPage] SubTitle7 Paragraph2') }}/>
                    <br />
                    <span dangerouslySetInnerHTML={{ __html: t('[dataProtectionPage] SubTitle7 Paragraph3') }}/>
                    <br /> <br />
                    {titleSection('[dataProtectionPage] SubTitle8')} 
                    <br />
                    <span dangerouslySetInnerHTML={{ __html: t('[dataProtectionPage] SubTitle8 Paragraph1') }}/>
                    
                </Col>
            </div>            
        </Row>
    );

}




export default DataProtectionPage;
