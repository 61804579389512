import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Label from 'components/shared/Forms/Label';
import LabelFor from 'components/shared/Forms/LabelFor';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import {
  validateIsDangerousCharacters,
  sanitizeValue
} from 'utils/validationHelper';
import HelpText from './HelpText';
import ToolTipButton from './ToolTipButton';

const stylePopOver = {
  padding: '10px'
};

const TextField = props => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  React.useEffect(() => {
    if (anchorEl) {
      window.setTimeout(() => {
        setAnchorEl(null);
      }, 3000);
    }
  }, [anchorEl]);

  /**
   *
   * @param {Array} errorMessages Renders validation messages under the field.
   * Supports multiple fields
   * TODO: stop supporting pure object, it should only receive array of error object for code simplicity
   */
  const renderErrorMessages = (errorMessages, showAllErrors) => {
    if (typeof errorMessages == 'string')
      return <div className="invalid-feedback">{t(errorMessages)}</div>;
    if (showAllErrors) {
      return errorMessages.map(errMsg => (
        <div key={errMsg} className="invalid-feedback">
          {t(errMsg)}
        </div>
      ));
    }
    return <div className="invalid-feedback">{t(errorMessages[0])}</div>;
  };

  /**
   * this expression defines if the field must paint the board red
   */
  const isInvalid =
    props.errorFields[props.name] && props.errorFields[props.name].hasError;

  /**
   * Determines red colorful boards when field is not valid
   * @param {string} base base CSS to expand from
   */
  const getClass = base =>
    // eslint-disable-next-line no-nested-ternary
    `${base}  ${isInvalid ? 'is-invalid' : props.value ? 'is-valid' : ''}`;

  const handleOnBlur = e => {
    if (props.onBlur) props.onBlur(e);
    props.onValidate(e.target.name, e.target.value);
  };

  const handleDangerousChart = event => {
    const { name, value } = event.target;
    if (props.sanitize && validateIsDangerousCharacters(value)) {
      setAnchorEl(event.currentTarget);
      return { target: { name, value: sanitizeValue(value) } };
    }
    return event;
  };

  const handleChange = event => {
    const eventChecked = handleDangerousChart(event);
    props.onValueChange(eventChecked);
  };

  return (
    <div className={getClass('form-group')}>
      <LabelFor
        labelRef={props.inputRef}
        name={props.name}
        className="col col-md-12 col-lg-12 px-0"
      >
        <Label text={props.label} isRequired={props.required} />
        <ToolTipButton text={props.toolTipText} />
        {props.helpText && <HelpText name={props.name} text={props.helpText} />}

        <input
          data-testid={`${props.id}-textInput`}
          type={props.type}
          className={getClass('form-control')}
          onChange={handleChange}
          name={props.name}
          placeholder={props.placeholder}
          disabled={props.disabled}
          aria-describedby={props.ariaDescribedby}
          maxLength={props.maxLength}
          value={props.value}
          onBlur={handleOnBlur}
          onPaste={props.onPaste}
          autoComplete={props.autoComplete ? 'on' : 'off'}
        />
        <Popover
          id="popover-advise"
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'left'
          }}
        >
          <Typography color="error" style={stylePopOver}>
            {t('popOverRestrictedCharacters')}
          </Typography>
        </Popover>

        {isInvalid &&
          renderErrorMessages(
            props.errorFields[props.name].message,
            props.showAllErrors
          )}
      </LabelFor>
    </div>
  );
};

TextField.propTypes = {
  id: PropTypes.string,
  inputRef: PropTypes.shape({}),
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  value: PropTypes.string,
  toolTipText: PropTypes.string,
  onValueChange: PropTypes.func,
  maxLength: PropTypes.number,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  helpText: PropTypes.string,
  disabled: PropTypes.bool,
  showAllErrors: PropTypes.bool,
  required: PropTypes.bool,
  errorFields: PropTypes.shape({
    hasError: PropTypes.bool,
    message: PropTypes.string
  }),
  ariaDescribedby: PropTypes.string,
  onValidate: PropTypes.func,
  onPaste: PropTypes.func,
  onBlur: PropTypes.func,
  autoComplete: PropTypes.bool,
  sanitize: PropTypes.bool
};

TextField.defaultProps = {
  id: 'input-textField',
  inputRef: null,
  label: '',
  value: undefined,
  maxLength: 100,
  placeholder: '',
  type: 'text',
  helpText: '',
  disabled: false,
  showAllErrors: false,
  required: false,
  ariaDescribedby: '',
  errorFields: {},
  onValidate: () => {},
  onPaste: () => {},
  onValueChange: () => {},
  autoComplete: false,
  toolTipText: '',
  onBlur: undefined,
  sanitize: true
};

export default TextField;
