import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import { SidebarDropdownToggle } from './SidebarDropdownToggle';

class MenuButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: true
    };

    this.toggleNavbar = this.toggleNavbar.bind(this);
  }

  toggleNavbar() {
    this.setState(prevState => ({
      collapsed: !prevState.collapsed
    }));
    this.props.onCollapseMenu();
  }

  renderSidebarContent() {
    const {
      props: { actions, isLoggedIn, makeAClaim },
      toggleNavbar
    } = this;
    return (
      <SidebarDropdownToggle
        actions={actions}
        makeAClaim={makeAClaim}
        onRedirect={toggleNavbar}
        isLoggedIn={isLoggedIn}
      />
    );
  }

  render() {
    const {
      state: { collapsed },
      toggleNavbar
    } = this;

    const toggleBtnClass = collapsed
      ? 'navbar-toggler navbar-toggler-right collapsed'
      : 'navbar-toggler navbar-toggler-right nav-bar-toggler';

    const toggleContentClass = collapsed
      ? 'collapse navbar-collapse sidenav'
      : 'collapse navbar-collapse show sidenav sidenav-visible';

    const menu = (
      <div className="toggle-btn ">
        <button
          onClick={toggleNavbar}
          className={toggleBtnClass}
          type="button"
          data-toggle="collapse"
          data-target="#navbarResponsive"
          aria-controls="navbarResponsive"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          {collapsed ? (
            <i className="material-icons">menu</i>
          ) : (
            <i className="material-icons">close</i>
          )}
          <span className="d-none d-md-inline toggler-label">
            {this.props.t('genericMenu')}
          </span>
        </button>
      </div>
    );

    return (
      <nav className="navbar navbar-light toggle-logo-box ">
        <div>{menu}</div>

        <div className={`${toggleContentClass}`} id="navbarResponsive">
          <ul className="navbar-nav p-3">{this.renderSidebarContent()}</ul>
        </div>
      </nav>
    );
  }
}

MenuButton.propTypes = {
  actions: PropTypes.shape({}).isRequired,
  isLoggedIn: PropTypes.bool,
  onCollapseMenu: PropTypes.func,
  makeAClaim: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired
};

MenuButton.defaultProps = {
  isLoggedIn: false,
  onCollapseMenu: null
};

export default withTranslation()(MenuButton);
