import React, { useState } from 'react';
import PropTypes from 'prop-types';

import AffectedPerson from 'components/ContactsQuestion/AffectedPerson/Types/AffectedPerson';
import YesNo from 'components/ContactsQuestion/Shared/YesNo';
import { validateForm } from 'utils/formValidationHelper';
import UploadDocument from 'components/ContactsQuestion/Shared/UploadDocument';
import { isFileIdValid } from 'components/ContactsQuestion/AffectedPerson/AffectedPeopleUtils';
import schemaValidation from './AffectedPersonMedicalDeathHealthSchemaValidation';

const AffectedPersonMedicalDeathHealth = props => {
  const person = {
    id: props.person.id,
    typeObjectId: props.person.typeObjectId,
    isValid: props.person.isValid,
    payload: {
      ...props.person.payload,
      wasStable: props.person.payload.wasStable,
      medicalCertificate: props.person.payload.medicalCertificate,
      deathCertificate: props.person.payload.deathCertificate
    }
  };

  const [errorFields, setErrorFields] = useState({});

  const [formValues, setFormValues] = useState(person);

  const handleFieldFormValueChange = (name, value) => {
    const clone = {
      ...formValues,
      payload: { ...formValues.payload, [name]: value }
    };
    validateAndUpdate(clone);
  };

  const validateAndUpdate = values => {
    setFormValues(values);

    const clone = { ...values, isValid: values.isValid && isFormValid(values) };
    props.onUpdateContact(clone);
  };

  const isFormValid = values => {
    const result = validateForm(schemaValidation, values.payload);
    setErrorFields({
      ...errorFields,
      ...result.errorFields
    });
    return (
      values.isValid &&
      result.isValid &&
      isFileIdValid(props.person.payload.medicalCertificate) &&
      isFileIdValid(props.person.payload.deathCertificate)
    );
  };

  const handleOnUpdateContactBase = values => {
    const clone = {
      ...formValues,
      ...values,
      payload: { ...formValues.payload, ...values.payload }
    };
    validateAndUpdate(clone);
  };

  return (
    <>
      <AffectedPerson
        person={props.person}
        isRemovable={props.isRemovable}
        onRemoveContact={props.onRemoveContact}
        onUpdateContact={handleOnUpdateContactBase}
      />
      <YesNo
        name="wasStable"
        label="contactsWasStable"
        errorFields={errorFields}
        value={props.person.payload.wasStable}
        onChange={handleFieldFormValueChange}
      />
      <UploadDocument
        id={`medical${props.person.id}`}
        label="contactsHasMedical"
        isMedical
        showHelp
        value={props.person.payload.medicalCertificate}
        claimId={props.claimId}
        benefitId={props.benefitId}
        onChange={value =>
          handleFieldFormValueChange('medicalCertificate', value)
        }
      />
      <UploadDocument
        id={`death${props.person.id}`}
        label="contactsHasDeath"
        isMedical={false}
        showHelp={false}
        value={props.person.payload.deathCertificate}
        claimId={props.claimId}
        benefitId={props.benefitId}
        onChange={value =>
          handleFieldFormValueChange('deathCertificate', value)
        }
      />
    </>
  );
};

AffectedPersonMedicalDeathHealth.propTypes = {
  person: PropTypes.shape({
    id: PropTypes.string,
    typeObjectId: PropTypes.number.isRequired,
    isValid: PropTypes.bool.isRequired,
    payload: PropTypes.shape({
      wasStable: PropTypes.string,
      deathCertificate: PropTypes.string,
      medicalCertificate: PropTypes.string
    })
  }).isRequired,
  isRemovable: PropTypes.bool,
  onUpdateContact: PropTypes.func.isRequired,
  onRemoveContact: PropTypes.func.isRequired,
  claimId: PropTypes.string.isRequired,
  benefitId: PropTypes.number.isRequired
};
AffectedPersonMedicalDeathHealth.defaultProps = {
  isRemovable: false
};

export default AffectedPersonMedicalDeathHealth;
