// TODO: technical debts
/* eslint-disable react/no-access-state-in-setstate */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import PasswordValidator from 'password-validator';

class RegistrationPasswordComponent extends Component {
  static validatePasswordHasUppercase(password) {
    const schema = new PasswordValidator();
    schema.has().uppercase(); // Must have uppercase letters
    return schema.validate(password);
  }

  static validatePasswordHasLowercase(password) {
    const schema = new PasswordValidator();
    schema.has().lowercase(); // Must have lowercase letters
    return schema.validate(password);
  }

  static validatePasswordHasDigit(password) {
    const schema = new PasswordValidator();
    schema.has().digits(); // Must have digits
    return schema.validate(password);
  }

  static validatePasswordMinLength(password) {
    const schema = new PasswordValidator();
    schema
      .is()
      .min(8) // Minimum length 8
      .is()
      .max(100); // Maximum length 100
    return schema.validate(password);
  }

  static validatePasswordHasNotSpaces(password) {
    const schema = new PasswordValidator();
    schema
      .has()
      .not()
      .spaces(); // Should not have spaces
    return schema.validate(password);
  }

  static validatePasswordHasSymbols(password) {
    const schema = new PasswordValidator();
    schema.has().symbols(); // Must have symbols
    return schema.validate(password);
  }

  static isValidPassword(password = '') {
    const hasUpper = RegistrationPasswordComponent.validatePasswordHasUppercase(
      password
    );
    const hasLower = RegistrationPasswordComponent.validatePasswordHasLowercase(
      password
    );
    const hasDigit = RegistrationPasswordComponent.validatePasswordHasDigit(
      password
    );
    const hasMinLength = RegistrationPasswordComponent.validatePasswordMinLength(
      password
    );
    const hasNoSpaces = RegistrationPasswordComponent.validatePasswordHasNotSpaces(
      password
    );
    const hasSymbol = RegistrationPasswordComponent.validatePasswordHasSymbols(
      password
    );
    return (
      hasUpper &&
      hasLower &&
      hasDigit &&
      hasMinLength &&
      hasNoSpaces &&
      hasSymbol
    );
  }

  constructor(props) {
    super(props);
    this.state = {
      compare: '',
      isMatching: true,
      hasUppercase: false,
      hasLowercase: false,
      hasDigit: false,
      hasMinLength: false,
      hasNoSpaces: false,
      hasSymbols: false
    };
    this.handleConfirmChange = this.handleConfirmChange.bind(this);
    this.comparePasswords = this.comparePasswords.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.validatePassword = this.validatePassword.bind(this);
  }

  // eslint-disable-next-line
  validatePassword(password) {
    this.setState({
      hasUppercase: RegistrationPasswordComponent.validatePasswordHasUppercase(
        password
      ),
      hasLowercase: RegistrationPasswordComponent.validatePasswordHasLowercase(
        password
      ),
      hasDigit: RegistrationPasswordComponent.validatePasswordHasDigit(
        password
      ),
      hasMinLength: RegistrationPasswordComponent.validatePasswordMinLength(
        password
      ),
      hasNoSpaces: RegistrationPasswordComponent.validatePasswordHasNotSpaces(
        password
      ),
      hasSymbols: RegistrationPasswordComponent.validatePasswordHasSymbols(
        password
      )
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.value !== this.props.value ||
      prevState.compare !== this.state.compare
    ) {
      this.comparePasswords();
      if (prevProps.value !== this.props.value) {
        this.validatePassword(this.props.value);
      }
    }
  }

  handleConfirmChange(e) {
    const pass = e.target.value;
    const isMatching = pass === this.props.value;
    this.setState(
      {
        compare: pass,
        isMatching
      },
      () => {
        if (this.props.onValidationExit) {
          const validation = RegistrationPasswordComponent.isValidPassword(
            pass
          );
          this.props.onValidationExit(validation && isMatching);
        }
      }
    );
  }

  comparePasswords() {
    const isMatching = this.props.value === this.state.compare;
    this.setState(
      {
        isMatching
      },
      () => {
        if (this.props.onValidationExit) {
          const validation = RegistrationPasswordComponent.isValidPassword(
            this.props.value
          );
          this.props.onValidationExit(validation && isMatching);
        }
      }
    );
  }

  handleChange(e) {
    this.props.onInputChange(e);
  }

  // eslint-disable-next-line class-methods-use-this
  handlePasteConfirmField(event) {
    event.preventDefault();
  }

  getValidationResultError() {
    const errorMessages = [];
    const validation = RegistrationPasswordComponent.isValidPassword(
      this.props.value
    );
    if (this.props.value && this.props.value !== '' && !validation) {
      if (!this.state.hasUppercase) {
        errorMessages.push('passwordErrorNoUppercase');
      }
      if (!this.state.hasLowercase) {
        errorMessages.push('passwordErrorNoLowercase');
      }
      if (!this.state.hasDigit) {
        errorMessages.push('passwordErrorNoDigit');
      }
      if (!this.state.hasMinLength) {
        errorMessages.push('passwordErrorNoMinLength');
      }
      if (!this.state.hasNoSpaces) {
        errorMessages.push('passwordErrorHasSpaces');
      }
      if (!this.state.hasSymbols) {
        errorMessages.push('passwordErrorNoSymbol');
      }
    }
    if (errorMessages.length > 0) {
      const result = errorMessages.map(error => (
        <div key={error} className="invalid-feedback d-block">
          {this.props.labels[error]}
        </div>
      ));
      return result;
    }
    return null;
  }

  getPasswordCompareResult() {
    const validation = RegistrationPasswordComponent.isValidPassword(
      this.props.value
    );
    return validation && this.state.compare && !this.state.isMatching ? (
      <div className="invalid-feedback d-block">
        {this.props.labels.registrationPasswordsNotMatch}
      </div>
    ) : null;
  }

  render() {
    const passwordValidationResult = this.getValidationResultError();
    const passwordCompareResult = this.getPasswordCompareResult();

    const isValid = RegistrationPasswordComponent.isValidPassword(
      this.props.value
    );

    let isValidClassname = '';
    if (this.props.value) {
      isValidClassname = isValid ? 'is-valid' : 'is-invalid';
    }
    let isMatchingClassName = '';
    if (isValid && this.state.compare) {
      isMatchingClassName = this.state.isMatching ? 'is-valid' : 'is-invalid';
    }

    return (
      <div className="form-group">
        <label
          htmlFor={`${this.props.name}`}
          className="label-input col-12 col-md-8 col-lg-6 mb-4"
        >
          <strong>{`${this.props.label}`}</strong>
          <small id={`${this.props.name}Help`} className="form-text text-muted">
            {`${this.props.helpText}`}
          </small>
          <input
            type="password"
            className={`form-control ${isValidClassname}`}
            id={`${this.props.name}`}
            name={`${this.props.name}`}
            value={this.props.value}
            onChange={this.handleChange}
            aria-describedby={this.props.ariaDescribedby}
            placeholder={this.props.placeholder}
            required
            autoComplete="off"
            maxLength="100"
          />
          {passwordValidationResult}
        </label>
        <div className="d-none d-lg-block w-100" />

        <label
          htmlFor="password2"
          className="label-input col-12 col-md-8 col-lg-6"
        >
          <strong>{`${this.props.labels.commonFromPasswordConfrimLabel} *`}</strong>
          <input
            type="password"
            className={`form-control ${isMatchingClassName}`}
            id="password2"
            name="password2"
            value={this.state.compare}
            onChange={this.handleConfirmChange}
            aria-describedby="password"
            required
            onPaste={this.handlePasteConfirmField}
            autoComplete="off"
            maxLength="100"
          />
          {passwordCompareResult}
        </label>
      </div>
    );
  }
}

RegistrationPasswordComponent.propTypes = {
  onInputChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  ariaDescribedby: PropTypes.string,
  helpText: PropTypes.string,
  onValidationExit: PropTypes.func,
  labels: PropTypes.shape({
    passwordErrorNoUppercase: PropTypes.string.isRequired,
    passwordErrorNoLowercase: PropTypes.string.isRequired,
    passwordErrorNoDigit: PropTypes.string.isRequired,
    passwordErrorNoMinLength: PropTypes.string.isRequired,
    passwordErrorHasSpaces: PropTypes.string.isRequired,
    registrationPasswordsNotMatch: PropTypes.string.isRequired,
    commonFromPasswordConfrimLabel: PropTypes.string.isRequired
  }).isRequired
};
RegistrationPasswordComponent.defaultProps = {
  helpText: '',
  ariaDescribedby: '',
  placeholder: '',
  onValidationExit: () => {}
};

export default RegistrationPasswordComponent;
