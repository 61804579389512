import React from 'react';
import PropTypes from 'prop-types';

const amexFRAttenstationURL = process.env.REACT_APP_ATTESTATIONS_FR;


const CheckBenefitsComponent = props => (
  <div className="row no-gutters">
    <div className="col landing-card">
      <div className="row no-gutters align-items-center">
        <div className="col-12 col-md-6 pr-3">
          <h4>{props.checkQuestionTitle}</h4>
          <span
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: props.checkDescription }}
          />
        </div>
        <div className="col-12 col-md-6 align-middle">
          {!props.displaylink && (
            <>
              <button
                type="button"
                className="call-to-action-button call-to-action-button_smaller d-flex start"
                onClick={props.onOpenCheckModal}
              >
                <span className="mr-auto mt-auto mb-auto">
                  {props.checkBtnLabel}
                </span>
                <i className="material-icons rounded-circle call-to-action-button-chevron mr-4 ml-2 text-center">
                  navigate_next
            </i>
              </button>
            </>
          )}
          {props.displaylink && (
            <>
              <a
                href={amexFRAttenstationURL}
                target="_blank"
                className="anchor-no-underline call-to-action-button call-to-action-button_smaller d-flex start" rel="noopener noreferrer"
              >
                <span className="mr-auto mt-auto mb-auto">
                  {props.checkBtnLabel}
                </span>
                <i className="material-icons rounded-circle call-to-action-button-chevron mr-4 ml-2 text-center">
                  navigate_next
            </i>
              </a>
            </>
          )}
        </div>
      </div>
    </div>
  </div>
);

CheckBenefitsComponent.propTypes = {
  checkQuestionTitle: PropTypes.string,
  checkDescription: PropTypes.string,
  onOpenCheckModal: PropTypes.func.isRequired,
  checkBtnLabel: PropTypes.string,
  displaylink: PropTypes.bool
};

CheckBenefitsComponent.defaultProps = {
  checkQuestionTitle: '',
  checkDescription: '',
  checkBtnLabel: '',
  displaylink: false
};

export default CheckBenefitsComponent;
