import React from 'react';
import PropTypes from 'prop-types';

const SidebarDropdownToggleItem = props => (
  <li className="nav-item dropdown row no-gutters">
    <div
      className="nav-link dropdown-toggle col-11 offset-1"
      role="button"
      data-toggle="dropdown"
      onClick={props.onClick}
      tabIndex="0"
      onKeyPress={props.onClick}
    >
      <span className="d-xs-inline d-md-block d-xl-inline">{props.label}</span>
    </div>
  </li>
);

SidebarDropdownToggleItem.propTypes = {
  label: PropTypes.string,
  onClick: PropTypes.func.isRequired
};

SidebarDropdownToggleItem.defaultProps = {
  label: ''
};

export default SidebarDropdownToggleItem;
