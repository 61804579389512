import ReactGA from 'react-ga';
import types from './providerType';

const initGA = (trackingId, testMode) => {
  ReactGA.initialize(trackingId, {
    testMode
  });
  return {
    type: types.GOOGLE_ANALYTICS,
    trackingId,
    enabled: () => true,
    event: ({ category, action, rest }) =>
      ReactGA.event({
        category,
        action,
        label: rest?.label,
        value: rest?.value,
        nonInteraction: rest?.nonInteraction
      }),
    pageview: args => {
      // Before send the hit for events or page view we need to set the page
      ReactGA.ga('set', 'page', args.page);
      ReactGA.ga('send', { hitType: 'pageview' });
    }
  };
};

export default initGA;
