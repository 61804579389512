import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { event } from 'utils/analytics';

import { Row, Col } from 'components/shared/Table';

import { FAKE_UPLOAD_ID } from 'constants/constants';
import { GA_EVENTS, GA_ACTIONS } from 'constants/googleAnalytics';
import { appInsights } from 'ApplicationInsights';

const CountryQuestion = props => {
  const { t } = useTranslation();
  const [selectedValue, setSelectedValue] = useState(props.answer);

  const dispatchGAEvent = selectedItem => {
    event(GA_EVENTS.QUESTION, GA_ACTIONS.ANSWER, {
      label: `${props.guideWireField} - ${selectedItem}`
    });
    appInsights.trackEvent(
      {
        name:`${props.guideWireField} - ${selectedItem}`
      },
      {
        EventCategory: GA_EVENTS.QUESTION,
        EventAction: GA_ACTIONS.ANSWER,
        EventLabel: `${props.guideWireField} - ${selectedItem}`
      }
      )
  };

  const handleOnChange = eventHandler => {
    const { value } = eventHandler.target;

    const selectedComboItem =
      value && props.properties.items.find(item => item.value === value);

    setSelectedValue(selectedComboItem.value);
    dispatchGAEvent(value);

    props.onUserAnswer(
      props.id,
      value,
      selectedComboItem.nextGroupId || props.nextGroup
    );
  };

  const grouped = props.properties.items.filter(item => item.isGrouped);

  const blankOption = (
    <option disabled value="" key="placeholder">
      {t('countrySelectPlaceholder')}
    </option>
  );

  const newOptions = grouped.map(item => (
    <option key={item.value} value={item.value}>
      {item.label}
    </option>
  ));

  return (
    <Row className="d-flex flex-wrap combo">
      <Col className="form-group combo-item mr-4">
        <select
          id="select-countryQuestion"
          className="input-group-text mr-2 simple-select mt-2"
          value={selectedValue}
          onChange={handleOnChange}
        >
          {[blankOption, ...newOptions.slice()]}
        </select>
      </Col>
    </Row>
  );
};

CountryQuestion.propTypes = {
  answer: PropTypes.string,
  properties: PropTypes.shape({
    type: PropTypes.number,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string,
        label: PropTypes.string,
        isGrouped: PropTypes.bool
      })
    )
  }).isRequired,
  onUserAnswer: PropTypes.func.isRequired,
  id: PropTypes.number,
  nextGroup: PropTypes.number,
  guideWireField: PropTypes.string
};

CountryQuestion.defaultProps = {
  id: FAKE_UPLOAD_ID,
  answer: '',
  nextGroup: null,
  guideWireField: 'countryQuestion'
};

export default CountryQuestion;
