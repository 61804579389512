import React from 'react';
import PropTypes from 'prop-types';
import InfoIcon from './InfoIcon';
import UploadInfoText from './UploadInfoText';

const UnavailableAdditionalText = ({ text }) => (
  <span className="d-block mt-2">
    <InfoIcon />
    <UploadInfoText text={text} />
  </span>
);

UnavailableAdditionalText.propTypes = {
  text: PropTypes.string.isRequired
};

export default UnavailableAdditionalText;
