import {
  VAIDATE_APPEND_POLICY_TREE_REQUESTED,
  APPEND_POLICY_TO_TREE_ADD_POLICY_SUCCESS,
  APPEND_POLICY_TO_TREE_VALIDATION_SUCCEEDED,
  validatePolicy,
  selectPolicy,
  addPolicyAndAppendToTree,
  APPEND_POLICY_TREE_SUCCEEDED
} from 'actions/feature/policy';
import { appendCreditCardTree } from 'actions/decisionTreeActions';
import { setInTriage } from 'actions/triageStatusActions';
import { isUserLoggedIn } from 'utils/sessionController';

export const validateAndAppendPolicyFlow = ({ dispatch }) => next => action => {
  next(action);

  if (action.type === VAIDATE_APPEND_POLICY_TREE_REQUESTED) {
    dispatch(
      validatePolicy(
        action.payload.number,
        action.payload.name,
        action.payload.surname,
        action.payload.date,
        APPEND_POLICY_TO_TREE_VALIDATION_SUCCEEDED,
        null,
        {
          ...action.meta
        }
      )
    );
  }
};

const isPolicyNew = (policyList, gencode) => {
  return !policyList.filter(c => c.number === gencode).length;
};

export const validationSucceededAndAddPolicyAndAppendPolicyToTreeFlowRequested = ({
  dispatch,
  getState
}) => next => action => {
  next(action);

  if (action.type === APPEND_POLICY_TO_TREE_VALIDATION_SUCCEEDED) {
    // We need to check if the user is looged in because the user can start with a claim and doesn't have an account
    if (
      isUserLoggedIn() &&
      isPolicyNew(getState().policiesList, action.payload.number)
    ) {
      dispatch(
        addPolicyAndAppendToTree(
          action.payload.number,
          action.payload.name,
          action.payload.surname,
          action.payload.date,
          action.payload.productId,
          action.meta.questionId
        )
      );
    } else {
      dispatch({
        type: APPEND_POLICY_TO_TREE_ADD_POLICY_SUCCESS,
        payload: {
          number: action.payload.number,
          name: action.payload.name,
          surname: action.payload.surname,
          date: action.payload.date
        },
        meta: { ...action.meta, productId: action.payload.productId }
      });
    }
  }
};

export const appendPolicyToTreeFlow = ({ dispatch }) => next => action => {
  next(action);

  if (action.type === APPEND_POLICY_TO_TREE_ADD_POLICY_SUCCESS) {
    dispatch(
      selectPolicy(
        action.payload.number,
        action.meta.name,
        action.meta.surname,
        action.meta.date
      )
    );
    dispatch(
      appendCreditCardTree(
        action.meta.productId,
        action.meta.questionId,
        action.payload.number
      )
    );
    dispatch(setInTriage());
    dispatch({ type: APPEND_POLICY_TREE_SUCCEEDED });
  }
};

export default [
  validateAndAppendPolicyFlow,
  validationSucceededAndAddPolicyAndAppendPolicyToTreeFlowRequested,
  appendPolicyToTreeFlow
];
