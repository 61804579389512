import Cookies from 'universal-cookie';

import { TOKEN, TOKEN_MAX_AGE, ACTIVE_CARD_INDEX } from 'constants/constants';
import { init } from 'utils/analytics';

const cookies = new Cookies();

export function saveToken(data) {
  if (data && data.errorCode == null && data.token !== null) {
    cookies.set(TOKEN, data, {
      path: '/',
      maxAge: TOKEN_MAX_AGE
    });
    return true;
  }
  return false;
}
export function getToken() {
  return cookies.get(TOKEN);
}

export function isUserLoggedIn() {
  return getToken() !== undefined;
}

export function removeToken() {
  cookies.remove(TOKEN, { path: '/' });
  return true;
}

export function saveActiveCardIndex(index) {
  cookies.set(ACTIVE_CARD_INDEX, index, { path: '/' });
}
export function getActiveCardIndex() {
  return cookies.get(ACTIVE_CARD_INDEX, { path: '/' });
}

export function initGACookies() {
  init({
    trackingId: process.env.REACT_APP_ANALYTICS_ID,
    testMode: process.env.NODE_ENV === 'test'
  });
}

export function resetHistory(page) {
  window.history.pushState(null, null, window.href);
  localStorage.removeItem('history');
  localStorage.setItem('history', JSON.stringify([page, page]));
}

export function setActivityMetadata() {
  cookies.set('ACTIVITY', Date.now(), {
    path: '/',
    maxAge: TOKEN_MAX_AGE
  });
}
export function getActivityMetadata() {
  return cookies.get('ACTIVITY');
}

export function setMarketInLocalStorage(market) {
  localStorage.setItem('market', market);
}

export function getMarketFromLocalStorage() {
  return localStorage.getItem('market');
}

export const isTestEnvironment = () =>
  process.env.REACT_APP_ENVIRONMENT !== 'prod';
