import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Label, LabelFor } from 'components/shared/Forms';
import { Col } from 'components/shared/Table';
import { CARD_HOLDER_OPTIONS, COMBO_TYPES } from 'constants/constants';

const HolderCardOptions = props => {
  const { t } = useTranslation();

  const optionsNotPrimaryCard = {
    type: COMBO_TYPES.RADIO,
    items: [
      {
        label: t('cardHolderSuplementary'),
        key: CARD_HOLDER_OPTIONS.SUPPLEMENTARY,
        value: CARD_HOLDER_OPTIONS.SUPPLEMENTARY
      },
      {
        label: t('cardHolderNoCard'),
        key: CARD_HOLDER_OPTIONS.NOCARD,
        value: CARD_HOLDER_OPTIONS.NOCARD
      }
    ]
  };

  const optionsPrimaryCard = {
    ...optionsNotPrimaryCard,
    items: [
      {
        label: t('cardHolderPrimary'),
        key: CARD_HOLDER_OPTIONS.PRIMARY,
        value: CARD_HOLDER_OPTIONS.PRIMARY
      },
      ...optionsNotPrimaryCard.items
    ]
  };

  const handleRadioClick = item => props.onChange(item.value);

  const renderOptions = props.isPrimaryCard
    ? optionsPrimaryCard
    : optionsNotPrimaryCard;

  const isChecked = value => value === props.value;

  return (
    <div className="mb-2">
      <LabelFor
        name="cardHolderOptions"
        htmlFor="cardHolderOptions"
        className="label-input col-12 px-0"
      >
        <Label text={t('cardHolderRadioLabel')} isRequired />
      </LabelFor>
      <Col>
        {renderOptions.items.map(item => (
          <label className="radio-container" key={item.value}>
            {item.label}
            <input
              type="radio"
              name="radio"
              checked={isChecked(item.value)}
              onClick={() => handleRadioClick(item)}
            />
            <span className="checkmark" />
          </label>
        ))}
      </Col>
    </div>
  );
};

HolderCardOptions.propTypes = {
  isPrimaryCard: PropTypes.bool.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired
};

HolderCardOptions.defaultProps = {
  value: ''
};

export default HolderCardOptions;
