import React from 'react';
import PropTypes from 'prop-types';

const CardHowToClaimComponent = props => (
  <div className="left-card px-xs-1 px-md-4 p-md-4 my-4 mb-md-4">
    {props.children}
  </div>
);

CardHowToClaimComponent.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};

export default CardHowToClaimComponent;
