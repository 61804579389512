import * as types from '../actions/actionTypes';
import {
  SET_SUCCESS_ALERT,
  SET_ERROR_ALERT,
  SET_WARNING_ALERT
} from '../actions/core/ui';
import initialState from './initialState';

export default function(state = initialState.alerts, action) {
  switch (action.type) {
    case SET_SUCCESS_ALERT:
    case SET_ERROR_ALERT:
    case SET_WARNING_ALERT:
      return [...state, action.payload];
    case types.ADD_ALERT:
      return [...state, action.alert];
    case types.HANDLE_API_ERROR:
      return [...state, action.alert];
    case types.DELETE_ALERT:
      return state.filter(element => element !== action.alert);
    case types.DELETE_ALL_ALERT:
      return [];
    default:
      return state;
  }
}
