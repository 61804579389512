import Remarkable from 'remarkable';
import RemarkableReactRenderer from 'remarkable-react';

const md = new Remarkable();
md.renderer = new RemarkableReactRenderer();

export function markDownParagraph(text) {
  if (Array.isArray(text)) {
    const paragraphList = text.map(paragraph => md.render(paragraph));
    return paragraphList;
  }
  return md.render(text);
}

export default md;
