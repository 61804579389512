import userMdl from './feature/user';
import claimMdl from './feature/claim';
import policyMdl from './feature/policy';
import cardMdl from './feature/card';
import howToClaimMdl from './feature/howToClaim';
import fileMdl from './feature/file';
import coreMdl from './core';
import dateValidationMdl from './feature/dates';
import languageMdl from './feature/language';
import countryMdl from './feature/country';

export default [
  ...userMdl,
  ...claimMdl,
  ...dateValidationMdl,
  ...howToClaimMdl,
  ...policyMdl,
  ...cardMdl,
  ...fileMdl,
  ...coreMdl,
  ...languageMdl,
  ...countryMdl
];
