import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { isEmpty } from 'lodash';

import PageHeader from 'Bodiam/components/PageHeader';
import { submitFaqEvaluation } from 'actions/faqActions';
import { getFaqPageDataAction } from 'actions/prismicActions';
import { getFaqIdFromUrl } from 'utils/urlHelpers';
import { getFaqTitleById } from 'utils/PrismicHelper';
import { getLanguageIsoCode } from 'utils/languageUtils';
import Loading from 'components/Loading/Loading';
import { getActiveCard } from 'store/selectors/activeCard';
import { withTranslation } from 'react-i18next';
import FAQDetail from './FAQDetail';

export class FAQPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      idFaq: getFaqIdFromUrl(), // from 1 to 5, corresponding to the FAQS pismic IDs
      faqData: null
    };
    this.handleSubmitEvaluation = this.handleSubmitEvaluation.bind(this);
  }

  componentDidMount() {
    this.retrieveFaq();
  }

  componentDidUpdate(prevProps) {
    const diffCard = prevProps.activeCard !== this.props.activeCard;
    const diffLang =
      prevProps.marketLanguages.selectedLanguage !==
      this.props.marketLanguages.selectedLanguage;
    if (diffCard || diffLang) {
      this.retrieveFaq();
    }
  }

  getFaqContent() {
    const results = this.state.faqData.map(faq => (
      <FAQDetail
        key={faq.id}
        data={faq.data}
        prismicId={faq.id}
        version={faq.last_publication_date}
        onSubmitEvaluation={this.handleSubmitEvaluation}
      />
    ));
    return results;
  }

  handleSubmitEvaluation(evaluation) {
    this.props.actions.submitFaqEvaluation(evaluation);
  }

  retrieveFaq() {
    const language = getLanguageIsoCode(
      this.props.marketLanguages.selectedLanguage
    );
    if (this.state.idFaq && !isEmpty(this.props.activeCard)) {
      this.props.actions
        .getFaqPageDataAction(
          this.props.activeCard.amexMarket,
          this.props.activeCard.amexCode,
          this.state.idFaq,
          language
        )
        .then(data => {
          if (data.results.length) {
            const faqData = data.results.sort(
              (a, b) => a.data.order - b.data.order
            );
            this.setState({ faqData });
          } else {
            this.setState({ errorMessage: this.props.t('noCoverFaqFound') });
          }
        });
    }
  }

  render() {
    const { faqData, errorMessage } = this.state;
    const faqContent = faqData ? (
      <div className="text-center faq-page">{this.getFaqContent()}</div>
    ) : null;

    let content = (
      <>
        <PageHeader
          title={this.props.t('coverPageTitle')}
          productImage={this.props.activeCard.imageURL}
        />
        {this.state.idFaq && (
          <h2 className="text-center pb-4">
            {this.props.t(getFaqTitleById(this.state.idFaq))}
          </h2>
        )}
        {faqContent}
        <div>
          <Loading
            loading={this.props.loading}
            text={this.props.t('loading')}
          />
        </div>
      </>
    );
    if (errorMessage) {
      content = <p className="container">{errorMessage}</p>;
    }
    return content;
  }
}

const mapStateToProps = state => ({
  activeCard: getActiveCard(state),
  loading: state.ajaxCallsInProgress > 0,
  marketLanguages: state.marketLanguages
});

const mapDispatchToProps = dispatch => ({
  actions: {
    ...bindActionCreators(
      {
        submitFaqEvaluation,
        getFaqPageDataAction
      },
      dispatch
    )
  }
});

FAQPage.propTypes = {
  t: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  activeCard: PropTypes.shape({
    id: PropTypes.number.isRequired,
    number: PropTypes.string.isRequired,
    product: PropTypes.string.isRequired,
    typeOfCard: PropTypes.number.isRequired,
    imageURL: PropTypes.string.isRequired,
    amexMarket: PropTypes.string.isRequired,
    amexCode: PropTypes.string.isRequired,
    hasMedExpense: PropTypes.bool.isRequired
  }).isRequired,
  actions: PropTypes.shape({
    submitFaqEvaluation: PropTypes.func.isRequired,
    getFaqPageDataAction: PropTypes.func.isRequired
  }).isRequired,
  marketLanguages: PropTypes.shape({
    selectedLanguage: PropTypes.number.isRequired
  }).isRequired
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(FAQPage));
