/* eslint-disable react/no-danger */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Grow from '@material-ui/core/Grow';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

import { isMarketLufthansa } from 'utils/urlHelpers';
import { scrollToY } from 'utils/pageUtils';
import {
  CARD_NUMBER,
  CHECKBOX,
  HOW_TO_CLAIM,
  YES_NO,
  COMBO,
  COUNTRY,
  DECLINED,
  FILE,
  CONTACTS_MEDICAL,
  PUSH_AWAY,
  CONTACTS_MEDICAL_REPORT,
  CONTACTS_DOB_CARD,
  NUMBER,
  FILE_MEDICAL,
  DATE,
  TEXT,
  TEXT_AREA,
  CURRENCY,
  CONTACTS,
  CONTACTS_HEALTH,
  CONTACTS_DEATH_HEALTH,
  TEXT_AREA_WIDE,
  CONTACTS_DEATH,
  CONTACTS_MEDICAL_DEATH_DOB_PRIMARYCARD,
  WARNING,
  POLICY_NUMBER,
  CONTACTS_MEDICAL_HEALTH_CERTIFICATE,
  CONTACTS_MEDICAL_DEATH,
  CONTACTS_MEDICAL_HEALTH,
  CONTACTS_MEDICAL_HEALTH_REPORT,
  CONTACTS_MEDICAL_CERTIFICATE,
  CONTACTS_MEDICAL_DEATH_HEALTH,
  CONTACTS_MEDICAL_DISABILITY_REPORT
} from 'constants/constants';

import PolicyNumberQuestion from 'components/PolicyNumberQuestion';
import CardNumberQuestion from 'components/CardNumberQuestion';
import CheckQuestion from 'components/CheckQuestion';
import ComboQuestion from 'components/ComboQuestion';
import CountryQuestion from 'components/CountryQuestion';
import CurrencyQuestion from 'components/CurrencyQuestion';
import DateInputQuestion from 'components/DateInputQuestion';
import DeclineQuestion from 'components/DeclineQuestion';
import HowToClaim from 'components/HowToClaim';
import NumberQuestion from 'components/NumberQuestion';
import TextQuestion from 'components/TextQuestion';
import UploadQuestion from 'components/UploadQuestion';
import YesNoQuestion from 'components/YesNoQuestion';
import WarningQuestion from 'components/WarningQuestion';
import TextAreaQuestion from 'components/TextAreaQuestion';
import AffectedPeople from 'components/ContactsQuestion/AffectedPerson';

export class QuestionItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
      showHowToClaimTitle: true
    };
    this.handleChange = this.handleChange.bind(this);
    this.showLabel = this.showLabel.bind(this);
    this.displayLabelDescription = this.displayLabelDescription.bind(this);
    this.toggleHowToClaimTitle = this.toggleHowToClaimTitle.bind(this);

    this.node = React.createRef();
  }

  componentDidMount() {
    scrollToY(this.node.current ? this.node.current.offsetTop : 0);
  }

  handleChange(value) {
    this.setState({ expanded: value });
  }

  displayLabelDescription() {
    const hasDescription = (
      <button
        type="button"
        className="btn btn-link"
        onClick={() => this.handleChange(!this.state.expanded)}
      >
        <HelpOutlineIcon />
      </button>
    );

    const isDescription = (
      <Grow
        in={this.state.expanded}
        style={{ transformOrigin: '0 0 0' }}
        {...(this.state.expanded ? { timeout: 1000 } : {})}
      >
        <div className="row">
          <div className="col toolTipText">{this.props.description}</div>
        </div>
      </Grow>
    );

    return (
      <div className="row">
        <div className="col">
          <div className="row">
            <div className="col label">
              <span dangerouslySetInnerHTML={{ __html: this.props.label }} />{' '}
              {this.props.description && hasDescription}
            </div>
          </div>
          {this.state.expanded && isDescription}
        </div>
      </div>
    );
  }

  toggleHowToClaimTitle() {
    this.setState(prevState => ({
      showHowToClaimTitle: !prevState.showHowToClaimTitle
    }));
  }

  showLabel(typeOfQuestion) {
    switch (typeOfQuestion) {
      case CHECKBOX:
        return <div />;
      case HOW_TO_CLAIM:
        if (this.state.showHowToClaimTitle)
          return this.displayLabelDescription();
        return <div />;
      default:
        return this.displayLabelDescription();
    }
  }

  render() {
    const displayQuestion = typeOfQuestion => {
      switch (typeOfQuestion) {
        case YES_NO:
          return <YesNoQuestion {...this.props} />;
        case COMBO:
          return (
            <ComboQuestion
              {...this.props}
              updateClaimData={this.props.updateClaimData}
              activePage={this.props.activePage}
              currentClaim={this.props.currentClaim}
            />
          );
        case COUNTRY:
          return (
            <CountryQuestion
              id={this.props.id}
              answer={this.props.answer}
              userAnswer={this.props.userAnswer}
              nextGroup={this.props.nextGroup}
              properties={this.props.properties}
              onUserAnswer={this.props.onUserAnswer}
              guideWireField={this.props.guideWireField}
            />
          );
        case CARD_NUMBER:
          return (
            <CardNumberQuestion
              id={this.props.id}
              onCardChange={this.props.onValidCreditCard}
              guideWireField={this.props.guideWireField}
            />
          );
        case DECLINED:
        case PUSH_AWAY:
          return (
            <DeclineQuestion
              id={this.props.id}
              onDeclineCurrentClaim={this.props.onDeclineCurrentClaim}
              onDeclineConfirmationNO={this.props.onDeclineConfirmationNO}
              gotoLandingPage={this.props.gotoLandingPage}
              properties={this.props.properties}
              claimId={this.props.claimId}
              cancelType={this.props.type}
              guideWireField={this.props.guideWireField}
            />
          );
        case NUMBER:
          return (
            <NumberQuestion {...this.props} mandatory={this.props.mandatory} />
          );
        case HOW_TO_CLAIM:
          return (
            <HowToClaim
              {...this.props}
              hideTitle={this.toggleHowToClaimTitle}
              inQuestionnaire
              productId={this.props.currentClaim.productId}
              gotoLanding={this.props.gotoLandingPage}
            />
          );
        case CURRENCY:
          return (
            <CurrencyQuestion {...this.props} labels={this.props.labels} />
          );
        case FILE:
        case FILE_MEDICAL:
          return (
            <UploadQuestion
              id={this.props.id}
              answer={this.props.userAnswer && this.props.userAnswer.value}
              claimId={this.props.claimId}
              benefitId={this.props.benefitId}
              isMedical={typeOfQuestion === FILE_MEDICAL}
              nextGroup={this.props.nextGroup}
              onUserAnswer={this.props.onUserAnswer}
              isFromQuestionItem
              shouldProvideReason
              guideWireField={this.props.guideWireField}
            />
          );
        case DATE:
          return <DateInputQuestion {...this.props} />;
        case TEXT:
          return <TextQuestion {...this.props} />;
        case TEXT_AREA:
          return <TextAreaQuestion {...this.props} />;
        case TEXT_AREA_WIDE:
          return <TextAreaQuestion {...this.props} isWide />;
        case CONTACTS:
        case CONTACTS_DEATH:
        case CONTACTS_MEDICAL_DEATH:
        case CONTACTS_HEALTH:
        case CONTACTS_DEATH_HEALTH:
        case CONTACTS_MEDICAL:
        case CONTACTS_MEDICAL_CERTIFICATE:
        case CONTACTS_MEDICAL_REPORT:
        case CONTACTS_MEDICAL_HEALTH:
        case CONTACTS_MEDICAL_HEALTH_REPORT:
        case CONTACTS_MEDICAL_HEALTH_CERTIFICATE:
        case CONTACTS_MEDICAL_DEATH_HEALTH:
        case CONTACTS_DOB_CARD:
        case CONTACTS_MEDICAL_DEATH_DOB_PRIMARYCARD:
        case CONTACTS_MEDICAL_DISABILITY_REPORT:
          return (
            <AffectedPeople
              id={this.props.id}
              typeObjectId={typeOfQuestion}
              onUserAnswer={this.props.onUserAnswer}
              nextGroup={this.props.nextGroup}
              guideWireField={this.props.guideWireField}
            />
          );
        case CHECKBOX:
          return (
            <CheckQuestion
              id={this.props.id}
              userAnswer={this.props.userAnswer}
              onUserAnswer={this.props.onUserAnswer}
              description={this.props.description}
              nextGroup={this.props.nextGroup}
              guideWireField={this.props.guideWireField}
            />
          );
        case WARNING:
          return <WarningQuestion {...this.props} labels={this.props.labels} />;
        case POLICY_NUMBER:
          return (
            <PolicyNumberQuestion
              id={this.props.id}
              isLufthansa={isMarketLufthansa()}
              userAnswer={this.props.userAnswer}
              onValidPolicyNumber={this.props.onValidPolicyNumber}
              onPolicyInformationChange={() =>
                this.props.onDeleteAnswersByQuestionId(this.props.id)
              }
              guideWireField={this.props.guideWireField}
            />
          );
        default:
          return <div>{this.props.labels.GenericErrorMessage}</div>;
      }
    };

    const questionMargin =
      this.props.type === WARNING ? '' : 'question-item mb-5';

    return (
      <div className="animation">
        <div className={`row ${questionMargin}`} ref={this.node}>
          <div className="col">
            <div className="row">
              <div className="col">{this.showLabel(this.props.type)}</div>
            </div>
            <div className="row">
              <div className="col mt-2">{displayQuestion(this.props.type)}</div>
              <div ref={this.node} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

QuestionItem.propTypes = {
  id: PropTypes.number.isRequired,
  type: PropTypes.number.isRequired,
  label: PropTypes.string,
  description: PropTypes.string,
  labels: PropTypes.shape({
    GenericErrorMessage: PropTypes.string.isRequired
  }).isRequired,
  contacts: PropTypes.arrayOf(PropTypes.shape({})),
  cardsList: PropTypes.arrayOf(PropTypes.shape({})),
  policiesList: PropTypes.arrayOf(PropTypes.shape({})),
  updateContacts: PropTypes.func.isRequired,
  activeCard: PropTypes.shape({}),
  activePolicy: PropTypes.shape({}),
  claimId: PropTypes.string.isRequired,
  benefitId: PropTypes.number,
  onAddAlert: PropTypes.func.isRequired,
  languageId: PropTypes.number.isRequired,
  onValidPolicyNumber: PropTypes.func.isRequired,
  onValidCreditCard: PropTypes.func.isRequired,
  gotoLandingPage: PropTypes.func.isRequired,
  onDeclineCurrentClaim: PropTypes.func.isRequired,
  onDeclineConfirmationNO: PropTypes.func.isRequired,
  onDeleteAnswersByQuestionId: PropTypes.func.isRequired,
  userInfo: PropTypes.shape({}),
  updateClaimData: PropTypes.func.isRequired,
  activePage: PropTypes.string.isRequired,
  currentClaim: PropTypes.shape({
    productId: PropTypes.number
  }).isRequired,
  userAnswer: PropTypes.shape({
    questionId: PropTypes.number,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    nextQuestionId: PropTypes.number
  }),
  mandatory: PropTypes.bool,
  nextGroup: PropTypes.number.isRequired,
  properties: PropTypes.shape({}).isRequired,
  onUserAnswer: PropTypes.func.isRequired,
  answer: PropTypes.string,
  guideWireField: PropTypes.string
};

QuestionItem.defaultProps = {
  label: '',
  description: '',
  answer: undefined,
  contacts: [],
  cardsList: [],
  activeCard: {},
  policiesList: [],
  activePolicy: {},
  userInfo: {},
  benefitId: 0,
  userAnswer: undefined,
  mandatory: false,
  guideWireField: undefined
};

export default QuestionItem;
