/* eslint-disable react/no-access-state-in-setstate */
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import Slide from '@material-ui/core/Slide';
import Currency from 'components/CurrencyComponent/CurrencyComponent';
import CurrencyType from 'components/CurrencyType';
import { FILE_ANSWER } from 'constants/constants';
import { CDW, BENEFIT_BY_ID } from 'constants/claim';

import { Row, Col } from 'components/shared/Table';
import {
  TextField,
  DateField,
  Label,
  LabelFor,
  Button
} from 'components/shared/Forms';
import UploadQuestion from 'components/UploadQuestion';
import PurchaseFormPaymentMethod from './PurchaseFormPaymentMethod';
import PurchaseFormPaymentMethodDescription from './PurchaseFormPaymentMethodDescription';
import ToolTipButton from '../../shared/Forms/ToolTipButton';
import { getMarketFromUrl,isMarketAmexFR } from 'utils/urlHelpers';

const PurchasesForm = props => {
  const { t } = useTranslation();
  const [formValues, setFormValues] = useState({});

  useEffect(() => {
    setFormValues(props.expense);
  }, [props.expense]);

  const isValidForm = (values, showHowPaid) => {
    const {
      description,
      date,
      currencyCode,
      amount,
      sendType,
      type,
      paymentMethod
    } = values;
    return (
      description &&
      description.length > 0 &&
      date &&
      currencyCode &&
      currencyCode.length > 0 &&
      amount > 0 &&
      type > 0 &&
      sendType > 0 &&
      (!showHowPaid || paymentMethod > 0)
    );
  };

  const onCurrencyValueChange = event => {
    const clone = { ...formValues };

    if (event.target) {
      clone[event.target.name] = event.target.value.trim();
    } else {
      clone.amount = event.value;
    }
    setFormValues(clone);
  };

  const onInputChange = event => {
    const clone = { ...formValues };

    if (event.target) {
      clone[event.target.name] = event.target.value;
    }

    setFormValues(clone);
  };

  const getPlaceholderByCategory = category => {
    switch (category) {
      case 1:
        return 'ExpensesPurchasesFormDescriptionAccommodationPlaceHolder';
      case 2:
        return 'ExpensesPurchasesFormDescriptionEntertainmentPlaceHolder';
      case 3:
        return 'ExpensesPurchasesFormDescriptionPackageTripPlaceHolder';
      case 4:
        return 'ExpensesPurchasesFormDescriptionPersonalItemsPlaceHolder';
      case 5:
        return 'ExpensesPurchasesFormDescriptionMedicalPlaceHolder';
      case 6:
        return 'ExpensesPurchasesFormDescriptionTravelPlaceHolder';
      case 7:
        return 'ExpensesPurchasesFormDescriptionTransportPlaceHolder';
      case 8:
        return 'ExpensesPurchasesFormDescriptionRefreshmentPlaceHolder';
      case 9:
        return 'ExpensesPurchasesFormDescriptionCarRentalPlaceHolder';
      default:
        return '';
    }
  };

  const handleNumberFieldSet = value => {
    setFormValues({ ...formValues, amount: value });
  };

  const handleSaveExpense = () => {
    props.onSubmitClick(props.category, formValues);
  };

  const handleCancelClcik = () => {
    props.onCancelClick(props.category);
  };

  const handleOnChangePaymentMethod = (selected, field = 'paymentMethod') => {
    setFormValues({
      ...formValues,
      [field]: selected.value || selected,
      paymentMethodDescription: undefined
    });
  };

  const handleSelectType = (type, fileId = null) => {
    const clone = { ...formValues };
    if (type !== FILE_ANSWER.FILE) {
      clone.sendType = type;
      clone.file = null;
    } else if (type === FILE_ANSWER.FILE && fileId) {
      clone.sendType = type;
      clone.file = fileId;
    } else {
      clone.sendType = null;
      clone.file = null;
    }
    setFormValues(clone);
  };

  const handleOnChangeCoinType = e => {
    setFormValues({ ...formValues, currencyCode: e.target.value });
  };

  const handleValueChange = event => {
    setNewValues(event.target.name, event.target.value);
  };

  const setNewValues = (name, value) => {
    const newFormValues = {
      ...formValues,
      [name]: value
    };
    setFormValues(newFormValues);
  };

  const shouldShowPurchaseFormPaymentMethodDescription =
    formValues.paymentMethod > 1;

  const expenseAnswer =
    formValues.file || (formValues.sendType && formValues.sendType.toString());

  const isCdwBenefit = () => BENEFIT_BY_ID[props.benefitId] === CDW;

  return (
    <Slide
      direction="down"
      in={props.showForm}
      mountOnEnter
      unmountOnExit
      style={{ transformOrigin: '0 0 0' }}
      timeout={1000}
    >
      <Row className="justify-content-center">
        <Col>
          <form className="text-left needs-validation mb-2 ">
            <Row>
              <Col className="col-12 col-md-6">
                <TextField
                  id="input-purchaseForm-description"
                  label={t('ExpensesPurchasesFormDiscriptionLabel')}
                  name="description"
                  value={formValues.description}
                  onValueChange={handleValueChange}
                  ariaDescribedby={t('ExpensesPurchasesFormDiscriptionLabel')}
                  placeholder={t(getPlaceholderByCategory(props.category))}
                  required
                  maxLength={50}
                />
              </Col>
            </Row>
            <Row>
              <Col className="col-12 col-md-6">
                <DateField
                  id="input-purchaseForm-date"
                  label={t('ExpensesPurchasesFormDate')}
                  name="date"
                  value={formValues.date}
                  onValueChange={value => setNewValues('date', value)}
                  ariaDescribedby={t('ExpensesPurchasesFormDate')}
                  placeholder={t('ExpensesPurchasesFormDate')}
                  required
                />
              </Col>
            </Row>
            <div className="form-group">
              <LabelFor name="currencyCode" className="label-input">
                <Label text={t('ExpensesPurchasesFormCost')} isRequired />
                {isCdwBenefit() && (
                  <ToolTipButton text={t('ExpensesPurchasesFormCostTooltip')} showToolTip={isMarketAmexFR()} />
                )}
                <div className="input-group">
                  <div className="input-group-prepend mr-sm-2">
                    <CurrencyType
                      className="select-currency-input"
                      onValueChange={handleOnChangeCoinType}
                      value={formValues.currencyCode}
                    />
                  </div>
                  <Currency
                    id="input-purchaseForm-amount"
                    prefix={`${formValues.currencyCode} `}
                    className="form-control col-md-6 col-sm-12 currency-question"
                    amount={formValues.amount}
                    max={10000000}
                    min={0}
                    ariaLabel="Amount "
                    disabled={formValues.currencyCode === ''}
                    mask="_"
                    onValueChange={onCurrencyValueChange}
                    numberField={formValues.amount}
                    onNumberFieldSet={handleNumberFieldSet}
                  />
                </div>
              </LabelFor>
            </div>
            {props.showHowPaid && (
              <div className="form-group form-check form-check-inline w-100">
                <LabelFor name="title" className="label-input col-12 px-0">
                  <Label text={t('PurchasesFormPaidMethodsLabel')} isRequired />
                  <div
                    className="btn-toolbar"
                    role="toolbar"
                    aria-label="Toolbar with button groups"
                  >
                    <PurchaseFormPaymentMethod
                      handleOnChangePaymentMethod={handleOnChangePaymentMethod}
                      formValues={formValues}
                    />
                  </div>
                </LabelFor>
              </div>
            )}
            {shouldShowPurchaseFormPaymentMethodDescription && (
              <PurchaseFormPaymentMethodDescription
                onPaymentMethodTextChange={onInputChange}
                paymentMethodTextValue={formValues.paymentMethodDescription}
              />
            )}
            <Row>
                          <Col className="label mb-3">
                              <Label text={t('purchaseProvideProofLabel')} /> 
              </Col>
            </Row>
            <UploadQuestion
              id={props.expense.id}
              answer={expenseAnswer}
              onUserAnswer={handleSelectType}
              claimId={props.claimId}
              benefitId={props.benefitId}
            />
            <Row className="mb-2 mt-3">
              <Col>
                <Button
                  type="button"
                  className="btn btn-default btn-block btn-amex cancel"
                  onClick={handleCancelClcik}
                  text={t('commonCancel')}
                />
              </Col>
              <Col>
                <Button
                  id="btn-purchasesForm-saveExpense"
                  type="button"
                  className="btn btn-primary btn-block btn-amex save"
                  onClick={handleSaveExpense}
                  disabled={!isValidForm(formValues, props.showHowPaid)}
                  text={t('purchasesFormSaveButton')}
                />
              </Col>
            </Row>
          </form>
        </Col>
      </Row>
    </Slide>
  );
};

PurchasesForm.propTypes = {
  showForm: PropTypes.bool.isRequired,
  onSubmitClick: PropTypes.func.isRequired,
  onCancelClick: PropTypes.func.isRequired,
  category: PropTypes.number.isRequired,
  showHowPaid: PropTypes.bool.isRequired,
  expense: PropTypes.shape({
    id: PropTypes.number,
    currencyCode: PropTypes.string
  }),
  claimId: PropTypes.string,
  benefitId: PropTypes.number
};

PurchasesForm.defaultProps = {
  claimId: undefined,
  benefitId: undefined,
  expense: {
    id: null
  }
};

export default PurchasesForm;
