import {
  SET_ERROR_REQUESTED,
  SET_ERROR_GENERIC,
  SET_ERROR_NOTAUTHORIZE,
  SET_ERROR_FORBIDDEN,
  setGenericError,
  setNotAuthorizeError,
    setForbiddenError,
    setCardValidationError
} from 'actions/core/error';
import { notifyError } from 'actions/core/ui';
import { LANDING_PAGE, MY_CLAIMS_PAGE } from 'constants/constants';
import { goTo } from 'actions/core/app.navigation';

export const setErrorFlow = ({ dispatch }) => next => action => {
  next(action);

    if (action.payload == "FailedCardValidation") { dispatch(setCardValidationError()); }
    else {
        if (action.type === SET_ERROR_REQUESTED) {            
            const { status, data } = action.payload;
            switch (status) {
                case 400:
                    dispatch(notifyError(data));
                    break;
                case 401:
                    dispatch(setNotAuthorizeError());
                    break;
                case 403:
                    dispatch(setForbiddenError(data));
                    break;
                default:
                    dispatch(setGenericError());
                    break;
            }
        }
    }
};

export const setGenericErrorFlow = ({ dispatch }) => next => action => {
  next(action);

  if (action.type === SET_ERROR_GENERIC) {
    dispatch(goTo(LANDING_PAGE));
    dispatch(notifyError(action.payload.code));
  }
};

export const setNotAuthorizeErrorFlow = ({ dispatch }) => next => action => {
  next(action);

  if (action.type === SET_ERROR_NOTAUTHORIZE) {
    dispatch(goTo(LANDING_PAGE));
    dispatch(notifyError(action.payload.code));
  }
};

export const setForbiddenErrorFlow = ({ dispatch }) => next => action => {
  next(action);

  if (action.type === SET_ERROR_FORBIDDEN) {
    dispatch(goTo(MY_CLAIMS_PAGE));
    dispatch(notifyError(action.payload));
  }
};

export default [
  setErrorFlow,
  setGenericErrorFlow,
  setNotAuthorizeErrorFlow,
  setForbiddenErrorFlow
];
