import initGA from './googleAnalytics';
import types from './providerType';

const providers = [];

const addIfNotExist = (type, trackingId, testMode) => {
  if (!providers.some(x => x.type === type && x.trackingId === trackingId))
    providers.push(initGA(trackingId, testMode));
};

export function init({ trackingId, testMode }) {
  if (trackingId) {
    addIfNotExist(types.GOOGLE_ANALYTICS, trackingId, testMode);
  }
}

export function remove({ type, trackingId }) {
  providers.splice(
    providers.findIndex(x => x.type !== type && x.trackingId !== trackingId),
    1
  );
}

export function event(category, action, rest = {}) {
  providers.forEach(
    provider => provider.enabled() && provider.event({ action, category, rest })
  );
}

export function pageview(args) {
  providers.forEach(provider => provider.enabled() && provider.pageview(args));
}
