import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { pageview } from 'utils/analytics';
import { getRouteForClaim } from 'utils/routeHelper';
import { TASKLIST_STEP } from 'constants/claim';

import HowToClaim from 'components/HowToClaim/index';
import InfoCard from 'components/TaskList/InfoCard';
import StepList from 'components/TaskList/StepList';
import { appInsights } from 'ApplicationInsights';
const TaskList = props => {
  useEffect(() => {
    pageview({
      title: 'Claim-Wizard',
      page: getRouteForClaim(props.language, props.benefit, TASKLIST_STEP)
    });
    appInsights.trackPageView(
      { 
        name: 'Claim-Wizard', 
        uri: getRouteForClaim(props.language, props.benefit, TASKLIST_STEP)
     }
    )
  }, [props.benefit, props.currentStep, props.language]);
  const properties = {
    benefit: props.benefit
  };
  return (
    <>
      <div className="we-ask-doc-provide-section">
        <InfoCard labels={props.labels} />
        <HowToClaim
          properties={properties}
          claimStep={props.currentStep}
          language={props.language}
          productId={props.productId}
          labels={props.labels}
          gotoLanding={props.gotoLandingPage}
        />
      </div>
      <StepList
        claimStep={props.currentStep}
        setActivePage={props.setActivePage}
      />
    </>
  );
};

TaskList.propTypes = {
  setActivePage: PropTypes.func.isRequired,
  currentStep: PropTypes.number.isRequired,
  labels: PropTypes.shape({}).isRequired,
  benefit: PropTypes.number.isRequired,
  productId: PropTypes.number.isRequired,
  language: PropTypes.string.isRequired,
  gotoLandingPage: PropTypes.func.isRequired
};

export default TaskList;
