import { endPointCallGet } from './endPointCallerApi';
import * as apiRoutes from './apiRoutes';

class medicalFacilitiesApi {
  static getMedicalFacilities(query) {
    const params = query.categorySpecialty
      .filter(y => !!y)
      .map(x => `categorySpecialty=${x}`);
    params.push(`latitude=${query.latitude}`);
    params.push(`longitude=${query.longitude}`);
    const url = `${apiRoutes.FACILITIES}?${params.join('&')}`;
    return endPointCallGet(url);
  }
}

export default medicalFacilitiesApi;
