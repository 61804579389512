import contentDisposition from 'content-disposition';
import {
  DOWNLOAD_FILE_REQUESTED,
  DOWNLOAD_FILE_SUCCEEDED,
  DOWNLOAD_FILE_FAILED
} from 'actions/feature/file';
import { apiRequest } from 'actions/core/api';
import * as apiRoutes from 'api/apiRoutes';
import { hideSpinner } from 'actions/core/ui';

export const downloadFileFlow = ({ dispatch }) => next => action => {
  next(action);

  if (action.type !== DOWNLOAD_FILE_REQUESTED) return;
  const url = `${apiRoutes.FILES}/${action.payload.fileId}/${action.payload.claimId}`;
  dispatch(
    apiRequest(
      'GET',
      url,
      null,
      null,
      {
        responseType: 'blob',
        fileId: action.payload.fileId
      },
      DOWNLOAD_FILE_SUCCEEDED,
      DOWNLOAD_FILE_FAILED
    )
  );
};

export const downloadFileSuccessFlow = ({ dispatch }) => next => action => {
  next(action);

  if (action.type === DOWNLOAD_FILE_SUCCEEDED) {
    dispatch(hideSpinner());
    const filenameHeader = contentDisposition.parse(
      action.meta.response.headers['content-disposition']
    );

    const filename = filenameHeader?.parameters?.filename || 'Unknown';

    const url = URL.createObjectURL(action.payload);
    const link = document.createElement('a');
    link.href = url;
    link.download = filename;
    document.body.appendChild(link); // we need to append the element to the dom -> otherwise it will not work in firefox
    link.click();
    link.remove();
  }
};

export default [downloadFileFlow, downloadFileSuccessFlow];
