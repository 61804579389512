import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Slide from '@material-ui/core/Slide';
import NewsCarousel from 'Bodiam/containers/NewsCarousel/NewsCarousel';
import * as activePageActions from 'actions/activePageActions';
import * as authenticationActions from 'actions/authenticationActions';

import {
  addPolicyToUser,
  cleanPolicyValidationResult
} from 'actions/feature/policy';
import { addCardToUser, cleanCardInformation } from 'actions/feature/card';

import Header from 'components/LandingPage/components/header';
import TopHeader from 'shared/containers/TopHeader';
import Footer from 'shared/components/Footer';

import Alerts from 'containers/Alerts/index';
import MyClaimsPage from 'containers/MyClaimsPage/index';
import EditProfilePage from 'containers/EditProfilePage';
import ProductItemProfileDetailPage from 'containers/ProductItemProfileDetailPage';
import AddProductElementContainer from 'containers/AddProductElementContainer';
import AddPolicyComponent from 'components/AddPolicyComponent';
import AddCardComponent from 'components/AddCardComponent';

import {
  BODIAM_ACTIONS,
  BODIAM_BASE_URL,
  MY_CLAIMS_PAGE
} from 'constants/constants';
import history from 'utils/history';
import { scrollTop } from 'utils/pageUtils';
import { getMarketFromUrl, isMarketLufthansa } from 'utils/urlHelpers';

import EditEmailPage from '../EditEmailPage';

export class ProfileDashBoardPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      claimsPage:
        !props.bodiamAction ||
        props.bodiamAction === BODIAM_ACTIONS.VIEW_PROFILE,
      productItemProfilePage: props.bodiamAction === BODIAM_ACTIONS.REMOVE_CARD,
      editProfilePage: props.bodiamAction === BODIAM_ACTIONS.EDIT_PROFILE,
      editEmailPage: false,
      addCardPage: props.bodiamAction === BODIAM_ACTIONS.ADD_CARD,
      addPolicyPage: false,
      policyItemProfilePage: false
    };
    this.toggleAddCard = this.toggleAddCard.bind(this);
    this.toggleAddPolicy = this.toggleAddPolicy.bind(this);
    this.toggleProductItemProfile = this.toggleProductItemProfile.bind(this);
    this.togglePolicyItemProfile = this.togglePolicyItemProfile.bind(this);
    this.toggleEditProfile = this.toggleEditProfile.bind(this);
    this.toggleEditEmail = this.toggleEditEmail.bind(this);
    this.handleAddUserCard = this.handleAddUserCard.bind(this);
    this.handleAddUserPolicy = this.handleAddUserPolicy.bind(this);
  }

  toggleAddCard() {
    if (this.props.bodiamAction === BODIAM_ACTIONS.ADD_CARD) {
      history.push(`${BODIAM_BASE_URL}${getMarketFromUrl()}`);
      this.props.actions.emptyBodiamAction();
    } else {
      this.setState(prevState => ({
        addCardPage: !prevState.addCardPage,
        claimsPage: !prevState.claimsPage
      }));
      scrollTop();
    }
  }

  toggleAddPolicy() {
    // It's a hack to go to My claim page, because it's handling all by state
    this.props.actions.cleanPolicyValidationResult();
    this.setState(prevState => ({
      addPolicyPage: !prevState.addPolicyPage,
      claimsPage: !prevState.claimsPage
    }));
    scrollTop();
  }

  toggleProductItemProfile() {
    if (this.props.bodiamAction === BODIAM_ACTIONS.REMOVE_CARD) {
      history.push(`${BODIAM_BASE_URL}${getMarketFromUrl()}`);
      this.props.actions.bodiamRemovedCardAction();
    } else {
      this.setState(prevState => ({
        productItemProfilePage: !prevState.productItemProfilePage,
        claimsPage: !prevState.claimsPage
      }));
      scrollTop();
    }
  }

  togglePolicyItemProfile() {
    this.setState(prevState => ({
      policyItemProfilePage: !prevState.policyItemProfilePage,
      claimsPage: !prevState.claimsPage
    }));
    scrollTop();
  }

  toggleEditProfile() {
    this.setState(prevState => ({
      editProfilePage: !prevState.editProfilePage,
      claimsPage: !prevState.claimsPage
    }));
    scrollTop();
  }

  toggleEditEmail() {
    this.setState(prevState => ({
      editEmailPage: !prevState.editEmailPage,
      editProfilePage: !prevState.editProfilePage
    }));
    scrollTop();
  }

  handleAddUserCard(cardNumberValue, obfuscatedValue) {
    this.props.actions.addCardToUser(
      cardNumberValue,
      obfuscatedValue,
      MY_CLAIMS_PAGE
    );
  }

  handleAddUserPolicy(name, lastName, number, dateOfBirth) {
    this.props.actions.addPolicyToUser(
      number,
      name,
      lastName,
      dateOfBirth,
      MY_CLAIMS_PAGE
    );
  }

  render() {
    return (
      <div className="container-fluid">
        <div className="row no-gutters">
          <div className="col">
            <TopHeader onLogOut={this.props.onLogOut} />
          </div>
        </div>

        <div className="row no-gutters landing-page mb-4">
          <div className="col">
            <Header topBarText={this.props.labels.topBarText} />
          </div>
        </div>

        <div className="row no-gutters">
          <div className="col">
            <div className="container">
              <div className="row no-gutters">
                <div className="col layout-alerts">
                  <Alerts />
                </div>
              </div>
              {
                <Slide
                  direction="left"
                  in={this.state.productItemProfilePage}
                  mountOnEnter
                  unmountOnExit
                >
                  <ProductItemProfileDetailPage
                    toggleProductItemProfile={this.toggleProductItemProfile}
                  />
                </Slide>
              }
              {
                <Slide
                  direction="left"
                  in={this.state.policyItemProfilePage}
                  mountOnEnter
                  unmountOnExit
                >
                  <ProductItemProfileDetailPage
                    toggleProductItemProfile={this.togglePolicyItemProfile}
                  />
                </Slide>
              }
              {
                <Slide
                  direction="left"
                  in={this.state.editProfilePage}
                  mountOnEnter
                  unmountOnExit
                >
                  <EditProfilePage
                    toggleEditProfile={this.toggleEditProfile}
                    toggleEditEmail={this.toggleEditEmail}
                  />
                </Slide>
              }
              {
                <Slide
                  direction="left"
                  in={this.state.editEmailPage}
                  mountOnEnter
                  unmountOnExit
                >
                  <EditEmailPage toggleEditEmail={this.toggleEditEmail} />
                </Slide>
              }
              {
                <Slide
                  direction="left"
                  in={this.state.addCardPage}
                  mountOnEnter
                  unmountOnExit
                >
                  <AddProductElementContainer
                    toggleMethod={this.toggleAddCard}
                    userInfo={this.props.userInfo}
                  >
                    <AddCardComponent
                      addUserCard={this.handleAddUserCard}
                      toggleMethod={this.toggleAddCard}
                      cardValidation={this.props.cardValidation}
                      onCleanCardInformation={() =>
                        this.props.actions.cleanCardInformation()
                      }
                    />
                  </AddProductElementContainer>
                </Slide>
              }
              {
                <Slide
                  direction="left"
                  in={this.state.addPolicyPage}
                  mountOnEnter
                  unmountOnExit
                >
                  <AddProductElementContainer
                    toggleMethod={this.toggleAddPolicy}
                    userInfo={this.props.userInfo}
                  >
                    <AddPolicyComponent
                      labels={this.props.labels}
                      toggleMethod={this.toggleAddPolicy}
                      addUserPolicy={this.handleAddUserPolicy}
                      isLufthansa={isMarketLufthansa()}
                      userInfo={this.props.userInfo}
                      policyValidation={this.props.policyValidation}
                      onCleanValidation={() =>
                        this.props.actions.cleanPolicyValidationResult()
                      }
                    />
                  </AddProductElementContainer>
                </Slide>
              }
              {
                <Slide
                  style={{ transformOrigin: '0 0 0' }}
                  {...(this.state.claimsPage ? { timeout: 1000 } : {})}
                  direction="right"
                  in={this.state.claimsPage}
                  mountOnEnter
                  unmountOnExit
                >
                  <MyClaimsPage
                    toggleEditProfile={this.toggleEditProfile}
                    toggleAddCard={this.toggleAddCard}
                    toggleAddPolicy={this.toggleAddPolicy}
                    toggleProductItemProfile={this.toggleProductItemProfile}
                    togglePolicyItemProfile={this.togglePolicyItemProfile}
                  />
                </Slide>
              }
            </div>
          </div>
        </div>
        <div className="row no-gutters mt-4">
          <div className="col">
            <NewsCarousel />
            <Footer />
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  labels: state.labels,
  bodiamAction: state.bodiamAction,
  userInfo: state.userInfo,
  languageId: state.marketLanguages.selectedLanguage,
  policyValidation: state.policyValidation,
  cardValidation: state.card.validation
});

const mapDispatchToProps = dispatch => ({
  actions: {
    ...bindActionCreators(authenticationActions, dispatch),
    ...bindActionCreators(activePageActions, dispatch),
    ...bindActionCreators(
      {
        addPolicyToUser,
        addCardToUser,
        cleanCardInformation,
        cleanPolicyValidationResult
      },
      dispatch
    )
  }
});

ProfileDashBoardPage.propTypes = {
  bodiamAction: PropTypes.string,
  labels: PropTypes.shape({
    topBarText: PropTypes.string.isRequired
  }).isRequired,
  actions: PropTypes.shape({
    gotoSignIn: PropTypes.func.isRequired,
    gotoContactsPage: PropTypes.func.isRequired,
    gotoFAQPage: PropTypes.func.isRequired,
    gotoCookiesPage: PropTypes.func.isRequired,
    emptyBodiamAction: PropTypes.func.isRequired,
    addCardToUser: PropTypes.func.isRequired,
    addPolicyToUser: PropTypes.func.isRequired,
    cleanPolicyValidationResult: PropTypes.func.isRequired,
    cleanCardInformation: PropTypes.func.isRequired,
    bodiamRemovedCardAction: PropTypes.func.isRequired
  }).isRequired,
  userInfo: PropTypes.shape({
    name: PropTypes.string,
    surname: PropTypes.string,
    dateOfBirth: PropTypes.string
  }).isRequired,
  policyValidation: PropTypes.shape({
    isValid: PropTypes.bool,
    message: PropTypes.string
  }),
  cardValidation: PropTypes.shape({
    isValid: PropTypes.bool,
    message: PropTypes.string
  }),
  languageId: PropTypes.number.isRequired,
  onLogOut: PropTypes.func.isRequired
};

ProfileDashBoardPage.defaultProps = {
  bodiamAction: '',
  policyValidation: null,
  cardValidation: null
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfileDashBoardPage);
