import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'components/shared/Table';
import { Button } from 'components/shared/Forms';
import NavigateBefore from '@material-ui/icons/NavigateBefore';
import { PHONE_WIDTH } from 'constants/constants';
import './ButtonBack.scss';

const ButtonBack = props => {
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
  }, []);
  const handleWindowSizeChange = () => setWidth(window.innerWidth);
  const isDesktop = width > PHONE_WIDTH;
  return (
    <Row>
      <Col className="col-12 md-6">
        <Button
          type="button"
          className="btn btn-go-back mb-3"
          onClick={props.onClick}
        >
          <NavigateBefore className="navigation-icon-before" />
          {isDesktop && props.labelText}
        </Button>
      </Col>
    </Row>
  );
};

ButtonBack.propTypes = {
  labelText: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired
};

export default ButtonBack;
