import {
  VALIDATE_ADD_POLICY_REQUESTED,
  ADD_POLICY_REQUESTED,
  ADD_POLICY_SUCCEEDED,
  ADD_POLICY_FAILED,
  validatePolicy,
  setPolicyValidationResult
} from 'actions/feature/policy';
import * as apiRoutes from 'api/apiRoutes';

import { apiRequest } from 'actions/core/api';
import { setError } from 'actions/core/error';
import { goTo } from 'actions/core/app.navigation';
import { hideSpinner, showSpinner } from 'actions/core/ui';

export const validateAndAddPolicyFlow = ({ dispatch }) => next => action => {
  next(action);

  if (action.type === VALIDATE_ADD_POLICY_REQUESTED) {
    dispatch(
      validatePolicy(
        action.payload.number,
        action.payload.name,
        action.payload.surname,
        action.payload.date,
        ADD_POLICY_REQUESTED,
        null,
        action.meta
      )
    );
  }
};

export const addPolicyFlow = ({ dispatch }) => next => action => {
  next(action);

  if (action.type === ADD_POLICY_REQUESTED) {
    dispatch(showSpinner());
    dispatch(
      apiRequest(
        'POST',
        apiRoutes.GET_POLICIES,
        {},
        {
          name: action.payload.name,
          surname: action.payload.surname,
          number: action.payload.number,
          dateOfBirth: action.payload.date
        },
        { ...action.meta },
        ADD_POLICY_SUCCEEDED,
        ADD_POLICY_FAILED
      )
    );
  }
};

export const addPolicySuccess = ({ dispatch }) => next => action => {
  next(action);

  if (action.type === ADD_POLICY_SUCCEEDED) {
    const { redirectTo, onAddPolicySuccess } = action.meta;
    if (redirectTo) {
      dispatch(goTo(redirectTo));
    }
    if (onAddPolicySuccess) {
      dispatch({
        type: onAddPolicySuccess,
        payload: action.payload,
        meta: action.meta
      });
    }
    dispatch(hideSpinner());
  }
};

export const addPolicyFail = ({ dispatch }) => next => action => {
  next(action);

  if (action.type === ADD_POLICY_FAILED) {
    const { status, data } = action.payload;
    if (status === 400) {
      dispatch(setPolicyValidationResult(false, data));
    } else {
      dispatch(setError(action.payload));
    }

    const { onAddPolicyFail } = action.meta;
    if (onAddPolicyFail) {
      dispatch({
        type: onAddPolicyFail,
        payload: action.payload,
        meta: action.meta
      });
    }

    dispatch(hideSpinner());
  }
};

export default [
  validateAndAddPolicyFlow,
  addPolicyFlow,
  addPolicySuccess,
  addPolicyFail
];
