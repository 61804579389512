import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeRecaptchaVisible } from 'utils/recaptcha/core';
import { Label, LabelFor } from 'components/shared/Forms';
import { Row, Col } from 'components/shared/Table';
import PolicyNumberSelect from './PolicyNumberSelect';
import PolicyNumberInput from './PolicyNumberInput';

const PolicyNumberField = props => {
  const [policyNumber, setPolicyNumber] = useState('');

  useEffect(() => {
    setPolicyNumber(props.value);
  }, [props.value]);

  useEffect(() => {
    makeRecaptchaVisible(true);
    return () => makeRecaptchaVisible(false);
  }, []);

  const handleOnInputValueChange = ev => {
    setPolicyNumber(ev.value);
    props.onValueChange(ev.value);
  };

  return (
    <Row>
      <LabelFor name="number" className="col">
        {props.text && <Label isRequired text={props.text} />}
        {props.helpText && (
          <Row className="my-2">
            <Col>
              <span>{props.helpText}</span>
            </Col>
          </Row>
        )}
        {props.isAdding ? (
          <PolicyNumberInput
            onChange={e => handleOnInputValueChange(e)}
            regexValidation={props.regexValidation}
            maxLength={props.maxLength}
          />
        ) : (
          <PolicyNumberSelect
            onChange={e => handleOnInputValueChange(e)}
            value={policyNumber}
            list={props.list}
            regexValidation={props.regexValidation}
            maxLength={props.maxLength}
          />
        )}
        <Row className="my-2">
          <Col>{props.warningText && <span>{props.warningText}</span>}</Col>
        </Row>
      </LabelFor>
    </Row>
  );
};

PolicyNumberField.propTypes = {
  text: PropTypes.string,
  warningText: PropTypes.string,
  helpText: PropTypes.string,
  value: PropTypes.string,
  list: PropTypes.arrayOf(PropTypes.shape({})),
  onValueChange: PropTypes.func.isRequired,
  regexValidation: PropTypes.instanceOf(RegExp).isRequired,
  maxLength: PropTypes.number.isRequired,
  isAdding: PropTypes.bool
};
PolicyNumberField.defaultProps = {
  text: '',
  warningText: '',
  helpText: '',
  value: '',
  list: [],
  isAdding: false
};

export default PolicyNumberField;
