import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import MedicalProvidersSearchForm from './MedicalProvidersSearchForm';
import PageHeader from '../PageHeader';
import CustomModal from '../../../shared/components/CustomModal';

function MedicalProvidersSearch(props) {
  const [warning, setWarning] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    setWarning(true);
    return () => {
      setWarning(false);
    };
  }, []);

  const handleSearchFormSubmit = (category, location = null, speciality = '') =>
    props.onSubmit(location, category, speciality);

  const setError = message => props.onAddAlert('error', message);

  const setInfo = message => props.onAddAlert('info', message);

  const getModalCLssClass = isPremium =>
    `bodiam-modal-frame medical-providers-search ${
      isPremium ? 'warning-premium' : 'warning'
    }`;

  const handleLocationErrors = error => {
    switch (error.code) {
      case error.PERMISSION_DENIED:
        setInfo(t('geolocationPermissionDenied'));
        break;
      case error.POSITION_UNAVAILABLE:
        setError(t('geolocationInfoUnavailable'));
        break;
      case error.TIMEOUT:
        setError(t('geolocationTimeOut'));
        break;
      default:
        setError(t('GenericErrorMessage'));
        break;
    }
  };

  const handleModalClick = () => {
    setWarning(!warning);
  };

  const isPremium = props.activeCard.hasMedExpense;
  const description = isPremium
    ? t('medicalFacilitiesWarningPremium')
    : t('medicalFacilitiesWarning');

  return (
    <>
      <PageHeader title={t('medicalFacilitiesTitle')} />
      <MedicalProvidersSearchForm
        onSubmit={handleSearchFormSubmit}
        onAddAlert={props.onAddAlert}
        handleLocationErrors={handleLocationErrors}
        query={props.query}
        categoryList={props.categoryList}
        specialityList={props.specialityList}
      />
      <CustomModal
        isOpen={warning}
        contentLabel="Facilities Warning"
        shouldCloseOnOverlayClick
        overlayClassName="overlay"
        className={getModalCLssClass(isPremium)}
        handleConfirmationOk={handleModalClick}
        description={description}
        labelOk={t('commonOk')}
        inBodiam
      />
    </>
  );
}

MedicalProvidersSearch.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onAddAlert: PropTypes.func.isRequired,
  activeCard: PropTypes.shape({
    hasMedExpense: PropTypes.bool
  }).isRequired,
  categoryList: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      hasSpecialities: PropTypes.bool
    })
  ).isRequired,
  specialityList: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string
    })
  ).isRequired,
  query: PropTypes.shape({
    location: PropTypes.shape({
      latitude: PropTypes.string,
      longitude: PropTypes.string,
      title: PropTypes.string
    }),
    category: PropTypes.string,
    speciality: PropTypes.string
  })
};

MedicalProvidersSearch.defaultProps = {
  query: null
};

export default MedicalProvidersSearch;
