import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Label from 'components/shared/Forms/Label';
import LabelFor from 'components/shared/Forms/LabelFor';
import { withTranslation } from 'react-i18next';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import Grow from '@material-ui/core/Grow';
import Suggestions from './Suggestions/Suggestions';

class AutoCompleteInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchTerm: '',
      open: false,
      suggestedItems: [],
      currentOption: 0,
      isInvalid: false,
      showTip: false
    };
    this.getClass = this.getClass.bind(this);
    this.getBaseCSS = this.getBaseCSS.bind(this);
    this.handleOnBlur = this.handleOnBlur.bind(this);
    this.updateParentState = this.updateParentState.bind(this);
    this.updateLocalState = this.updateLocalState.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.setWrapperRef = this.setWrapperRef.bind(this);
  }

  componentDidMount() {
    // When component mount listen click event when click on document
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    // Remove click event handler on document when component unmounts
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  getBaseCSS() {
    return `input-search form-control ${
      this.state.open && this.state.searchTerm.length > 0
        ? '.input-search--open'
        : ''
    } ${this.props.className}`;
  }

  getClass = base => {
    const isInvalid =
      this.props.errorFields[this.props.name] &&
      this.props.errorFields[this.props.name].hasError;
    return `${base}  ${
      // eslint-disable-next-line no-nested-ternary
      isInvalid ? 'is-invalid' : this.props.value ? 'is-valid' : ''
    }`;
  };
  // eslint-disable-next-line no-nested-ternary

  setWrapperRef = node => {
    this.wrapperRef = node;
  };

  handleOnBlur = e => {
    if (this.props.onBlur) this.props.onBlur(e);
    this.props.onValidate(e.target.name, this.props.value);
  };

  toggleToolTip = () => {
    const toggleToolTipState = this.state.showTip;
    this.setState({ showTip: !toggleToolTipState });
  };

  // This will update parent state which control this component
  updateParentState(searchTerm) {
    this.props.parentUpdateState(searchTerm);
  }

  // control the entire state control of this component
  updateLocalState(state) {
    this.setState(state);
  }

  handleClickOutside(event) {
    // Control when user clicks outside the input components
    // if the suggestions box is open when click uutside will close the
    // box
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({ open: false });
    }
  }

  /**
   * @param {Array} errorMessages Renders validation messages under the field.
   * @param {Array} showAllErrors shows all errors, if false it shows only the first one.
   * Supports multiple fields
   * TODO: stop supporting pure object, it should only receive array of error object for code simplicity
   */
  renderErrorMessages = (errorMessages, showAllErrors) => {
    if (typeof errorMessages === 'string')
      return (
        <div className="invalid-feedback">{this.props.t(errorMessages)}</div>
      );
    if (showAllErrors) {
      return errorMessages.map(errMsg => (
        <div key={errMsg} className="invalid-feedback">
          {this.props.t(errMsg)}
        </div>
      ));
    }
    return (
      <div className="invalid-feedback">{this.props.t(errorMessages[0])}</div>
    );
  };

  /**
   * Show standard tooltip icon button when text is provided
   */
  renderToolTipButton = () => {
    return this.props.toolTipText ? (
      <button
        type="button"
        className="btn btn-link"
        onClick={this.toggleToolTip}
      >
        <HelpOutlineIcon />
      </button>
    ) : null;
  };

  /**
   * This creates a accordion text field to show more info about a given field
   */
  renderToolTip = () => {
    return this.state.showTip ? (
      <Grow
        in={this.state.showTip}
        style={{ transformOrigin: '0 0 0' }}
        {...(this.state.showTip ? { timeout: 1000 } : {})}
      >
        <div className="row">
          <div className="col toolTipText">{this.props.toolTipText}</div>
        </div>
      </Grow>
    ) : null;
  };

  render() {
    const { searchTerm, open } = this.state;
    const cssClass = this.getClass(this.getBaseCSS());
    const isInvalid = cssClass.indexOf('is-invalid') > -1;
    return (
      <div className="form-group">
        <LabelFor className="col col-md-12 col-lg-12 px-0">
          <Label text={this.props.label} isRequired={this.props.required} />
          {this.renderToolTipButton()}
          {this.renderToolTip()}
          <input
            data-testid={`input-autoComplete-${this.props.name}`}
            autoComplete="off"
            type="text"
            onChange={AutoCompleteInput.handleTermChange(
              this.updateLocalState,
              this.updateParentState,
              this.props.autoCompleteItems,
              this.props.maxSuggests,
              this.props.includeSearchTerm
            )}
            onFocus={AutoCompleteInput.handleFocusInput(
              this.updateLocalState,
              searchTerm,
              this.updateParentState
            )}
            onBlur={this.handleOnBlur}
            onKeyDown={AutoCompleteInput.handleCloseAutoComplete(
              this.updateLocalState,
              this.state,
              this.updateParentState
            )}
            value={this.props.value}
            placeholder={this.props.placeholder}
            name={this.props.name}
            open={open && searchTerm.length > 0 ? open : false}
            className={cssClass}
          />
          {isInvalid &&
            this.renderErrorMessages(
              this.props.errorFields[this.props.name].message,
              this.props.showAllErrors
            )}
          <Suggestions
            open={open}
            updateLocalState={this.updateLocalState}
            updateParentState={this.updateParentState}
            {...this.state}
            {...this.props}
          />
        </LabelFor>
      </div>
    );
  }
}

// this approach on declaring events handlers improves
// Readability and maintainance also improve time loading
// since the class and components will be read first by
// the browser engine - also it's the unique reference for all
// others components which would need this

// Handling onChange event
// it will receive data to update the parent state
AutoCompleteInput.handleTermChange = (
  updateLocalState,
  updateParentState,
  autoCompleteItems,
  maxSuggests,
  includeSearchTerm
) => event => {
  // Differences between e.target and e.currentTarget
  // target = element that triggered event. or in other words
  // it could be whatever that's actualy clicked on. It can vary, as this
  // can be within an element that the event was bound to
  // currentTarget = element that listens to event.
  // currentTarget is the element you actually bound the event to.
  // This will never change.

  // Since i accessed to the DOM element that's associated with the event handler
  // I defined, i used currentTarget.

  // Every time the user type a new letter the filter method
  // Will detect matches with the names of every gnome in the array

  // codesmell: we should revisit this and define if this will be a function or an array
  const AutoCompleteList = (typeof autoCompleteItems === 'function'
    ? autoCompleteItems.apply()
    : autoCompleteItems
  )
    .filter(autoCompleteItem => {
      // If the search item is include within the array item this will show
      // all the options which includes the search items
      if (includeSearchTerm) {
        return (
          autoCompleteItem
            .toLowerCase()
            .indexOf(event.target.value.toLowerCase()) >= 0
        );
      }
      // By default the autocomplete input will show only the options which
      // search term starts and includes with the search term
      return (
        autoCompleteItem
          .toLowerCase()
          .search(event.target.value.toLowerCase()) === 0
      );
    })
    .splice(0, maxSuggests);
  // Control how many suggestions will be show

  updateLocalState({
    searchTerm: event.currentTarget.value, // update the controlled component
    open: AutoCompleteList.length !== 0, // If there is not a match close the suggestion box
    suggestedItems: AutoCompleteList, // return an array of all suggestions
    currentOption: -1 // disable suggest the first item, to enable switch it to 0
  });
  // update global state of the parent search term
  updateParentState(event.currentTarget.value);
};

// handle on Focus
AutoCompleteInput.handleFocusInput = (
  updateLocalState,
  searchTerm,
  updateParentState
) => event => {
  event.preventDefault();
  // if there are at least one letter in the input and if it is focused
  // open the box with suggestions
  const open = searchTerm.length > 0;

  updateLocalState({
    open
  });
};

// handle on keydown
AutoCompleteInput.handleCloseAutoComplete = (
  updateLocalState,
  state,
  updateParentState
) => event => {
  // When user press ESC key
  // Close the suggestion box and reset all suggestions
  if (event.which === 27) {
    updateLocalState({
      open: false,
      suggestedItems: []
    });
  } else if (event.keyCode === 13) {
    // When Enter
    // if there is a suggested option pass it to the state if not check if -1 and just send the current search term
    // reset the suggestions and close the box and algo depending of the position
    // of the current option it will return the suggested option or tbe search Term
    // only if the current option is bigger or equal to 0 it will return the suggestion
    const processSearch =
      state.currentOption === -1
        ? state.searchTerm
        : state.suggestedItems[state.currentOption];
    updateLocalState({
      open: false,
      searchTerm: processSearch,
      suggestedItems: [],
      currentOption: -1
    });

    updateParentState(processSearch);
  } else if (event.keyCode === 40) {
    // When arrow up
    // select and option controlled by keyboard
    // everytime the user clicks arrow up button it will decrease
    // the suggestion options
    // suggestion options cant be less than -1 so in this case
    // when click up always returns -1
    updateLocalState(prevState => {
      const arrayItem = prevState.suggestedItems.length - 1;
      const maxOption =
        prevState.currentOption < arrayItem
          ? prevState.currentOption + 1
          : arrayItem;
      return {
        currentOption: maxOption
      };
    });
  } else if (event.keyCode === 38) {
    // When arrow down
    // select and option controlled by keyboard
    // everytime the user clicks arrow down button it will increase
    // the suggestion options

    updateLocalState(prevState => {
      // If not selected option return -1
      const minOption =
        prevState.currentOption < 1 ? -1 : prevState.currentOption - 1;
      return {
        currentOption: minOption
      };
    });
  } else if (event.keyCode === 9) {
    updateLocalState({
      currentOption: 0,
      suggestedItems: [],
      open: false
    });
  }
};

AutoCompleteInput.propTypes = {
  name: PropTypes.string.isRequired,
  onValidate: PropTypes.func,
  t: PropTypes.func.isRequired,
  parentUpdateState: PropTypes.func.isRequired,
  value: PropTypes.string,
  toolTipText: PropTypes.string,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  maxSuggests: PropTypes.number,
  includeSearchTerm: PropTypes.bool,
  labelName: PropTypes.string,
  errorFields: PropTypes.shape({
    hasError: PropTypes.bool,
    message: PropTypes.string
  }),
  className: PropTypes.string,
  onBlur: PropTypes.func,
  required: PropTypes.bool,
  showAllErrors: PropTypes.bool,
  autoCompleteItems: PropTypes.func
};

AutoCompleteInput.defaultProps = {
  onValidate: () => {},
  value: '',
  placeholder: '',
  maxSuggests: 0,
  includeSearchTerm: false,
  labelName: '',
  autoCompleteItems: () => {},
  className: '',
  onBlur: () => {},
  required: false,
  showAllErrors: false,
  errorFields: [],
  toolTipText: ''
};

export default withTranslation()(AutoCompleteInput);
