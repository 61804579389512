import {
  GET_DOCUMENT_LINK_REQUESTED,
  GET_DOCUMENT_LINK_SUCCEEDED,
  GET_DOCUMENT_LINK_FAILED,
  setDocumentLink
} from 'actions/feature/howToClaim';
import * as apiRoutes from '../../../api/apiRoutes';

import { apiRequest } from '../../../actions/core/api';
import { hideSpinner, showSpinner } from '../../../actions/core/ui';

export const getDocumentLinkFlow = ({
  dispatch,
  getState
}) => next => action => {
  next(action);

  if (action.type === GET_DOCUMENT_LINK_REQUESTED) {
    const languageId = getState().marketLanguages.selectedLanguage;
    dispatch(showSpinner());
    dispatch(
      apiRequest(
        'GET',
        `${apiRoutes.HOW_TO_CLAIM}/doctype/${action.payload.type}/${languageId}`,
        {},
        {},
        {
          ...action.meta,
          type: action.payload.type
        },
        GET_DOCUMENT_LINK_SUCCEEDED,
        GET_DOCUMENT_LINK_FAILED
      )
    );
  }
};

export const getDocumentLinkSuccess = ({ dispatch }) => next => action => {
  next(action);

  if (action.type === GET_DOCUMENT_LINK_SUCCEEDED) {
    dispatch(setDocumentLink(action.payload, action.meta.type));
    dispatch(hideSpinner());
  }
};

export const getDocumentLinkFail = ({ dispatch }) => next => action => {
  next(action);

  if (action.type === GET_DOCUMENT_LINK_FAILED) {
    dispatch(hideSpinner());
    dispatch(setDocumentLink());
  }
};

export default [
  getDocumentLinkFlow,
  getDocumentLinkSuccess,
  getDocumentLinkFail
];
