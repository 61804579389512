import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useTranslation } from 'react-i18next';

import { pageview } from 'utils/analytics';
import { getRouteForClaim } from 'utils/routeHelper';
import { DATES_STEP } from 'constants/claim';

import CalendarPickerComponent from 'components/CalendarPickerComponent';
import CustomModal from 'shared/components/CustomModal';
import { saveClaimAndCheckDates } from 'actions/feature/claim';
import { updateClaimData } from 'actions/claimActions';
import { addAlert } from 'actions/alertsActions';
import { DATES_VALIDATION_RESULT, ALERT_TYPES } from 'constants/constants';
import { resetDatesValidation } from 'actions/feature/dates';
import { gotoMyClaims } from 'actions/activePageActions';
import { appInsights } from 'ApplicationInsights';

const DatesAndTimesPage = props => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const { t } = useTranslation();

  React.useEffect(() => {
    pageview({
      title: 'Claim-Wizard',
      page: getRouteForClaim(
        props.language,
        props.currentClaim.benefitId,
        DATES_STEP
      )
    });
    appInsights.trackPageView(
      {
        name: 'Claim-Wizard',
        uri: getRouteForClaim(
        props.language,
        props.currentClaim.benefitId,
        DATES_STEP
      )
      }
    )
  }, [props.currentClaim.benefitId, props.language]);

  React.useEffect(() => {
    if (
      props.datesValidation &&
      props.datesValidation.result === DATES_VALIDATION_RESULT.DECLINE
    ) {
      setErrorMessage(t(props.datesValidation.errorMessage));
      setModalOpen(true);
    }
  }, [t, props.datesValidation]);

  const handleUpdateDates = () => {
    props.actions.saveClaimAndCheckDates(false);
  };

  const handleOk = () => {
    setModalOpen(false);
    props.actions.resetDatesValidation();
    props.actions.addAlert(ALERT_TYPES.ERROR, t('alertSuccessDecline'));
    props.actions.gotoMyClaims();
  };

  const handleSelectedDate = dates => {
    props.actions.updateClaimData({
      ...props.currentClaim,
      trees: {
        ...props.currentClaim.trees,
        dates: {
          questions: dates
        }
      }
    });
  };

  return (
    <>
      <CalendarPickerComponent
        title={t('calendarPickerComponentSubTitle')}
        questions={props.questions}
        lang={props.language}
        handelUpdateDates={handleUpdateDates}
        handleSelectedDate={handleSelectedDate}
      />
      <CustomModal
        isOpen={isModalOpen}
        shouldCloseOnOverlayClick={false}
        overlayClassName="overlay"
        className="modal-frame"
        handleConfirmationOk={handleOk}
        description={errorMessage}
        labelOk={t('commonOk')}
        contentLabel=""
      />
    </>
  );
};

const mapStateToProps = state => ({
  currentClaim: state.currentClaim,
  datesValidation: state.datesValidation
});

const mapDispatchToProps = dispatch => ({
  actions: {
    ...bindActionCreators(
      {
        updateClaimData,
        addAlert,
        saveClaimAndCheckDates,
        gotoMyClaims,
        resetDatesValidation
      },
      dispatch
    )
  }
});

DatesAndTimesPage.propTypes = {
  questions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  actions: PropTypes.shape({
    updateClaimData: PropTypes.func,
    gotoMyClaims: PropTypes.func.isRequired,
    addAlert: PropTypes.func.isRequired,
    saveClaimAndCheckDates: PropTypes.func.isRequired,
    resetDatesValidation: PropTypes.func.isRequired
  }).isRequired,
  currentClaim: PropTypes.shape({
    id: PropTypes.string.isRequired,
    trees: PropTypes.shape({}),
    benefitId: PropTypes.number,
    step: PropTypes.number
  }).isRequired,
  datesValidation: PropTypes.shape({
    result: PropTypes.number,
    errorMessage: PropTypes.string
  }).isRequired,
  language: PropTypes.string.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(DatesAndTimesPage);
