import React from 'react';
import { useDispatch } from 'react-redux';
import { ARUNDEL_BASE_URL } from 'constants/constants';
import history from 'utils/history';
import { gotoCookiesPage } from 'actions/activePageActions';
import { getMarketFromLocalStorage } from 'utils/sessionController';

const CookiesManager = () => {
  const dispatch = useDispatch();
  const market = getMarketFromLocalStorage();
  const urlByMarket = `${ARUNDEL_BASE_URL}${market}`;
  dispatch(gotoCookiesPage());
  history.push(urlByMarket);
  return null;
};

export default CookiesManager;
