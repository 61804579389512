import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { bindActionCreators } from 'redux';
import { getMarketFromUrl } from 'utils/urlHelpers';
import history from 'utils/history';
import Loading from 'components/Loading/Loading';
import * as alertsActions from 'actions/alertsActions';
import * as medicalProviderActions from 'actions/medicalProviderActions';
import { scrollTop } from 'utils/pageUtils';
import MedicalProvidersMap from 'Bodiam/components/MedicalFacilities/MedicalProvidersMap';
import MedicalProvidersSearch from 'Bodiam/components/MedicalFacilities/MedicalProvidersSearch';
import { BODIAM_BASE_URL } from 'constants/constants';
import { getActiveCard } from 'store/selectors/activeCard';

function MedicalProvidersPage(props) {
  const { t } = useTranslation();

  useEffect(() => {
    props.actions.newSearch();

    if (!(props.activeCard && props.activeCard.id)) {
      history.push(`${BODIAM_BASE_URL}${getMarketFromUrl()}`);
    }
  }, [props.actions, props.activeCard]);

  const onMedicalProviderSearchSumbmit = (
    location,
    category,
    speciality = null
  ) => {
    props.actions.getMedicalProviderFacilities(location, category, speciality);
    scrollTop();
  };

  const handlOnAddAlert = (type, message) => {
    props.actions.addAlert(type, message);
  };

  const toggleMapVisible = () => {
    props.actions.newSearch();
    scrollTop();
  };

  const handleMapLoading = isLoading => {
    if (isLoading) {
      props.actions.startLoadingMap();
    } else {
      props.actions.stopLoadingMap();
    }
  };

  return (
    <>
      <Loading loading={props.loading} text={t('loading')} />
      {!props.mapVisible && (
        <MedicalProvidersSearch
          onSubmit={onMedicalProviderSearchSumbmit}
          onAddAlert={handlOnAddAlert}
          activeCard={props.activeCard}
          categoryList={props.categories}
          specialityList={props.specialities}
          query={props.searchQuery}
        />
      )}
      {!props.loading && props.mapVisible && (
        <MedicalProvidersMap
          onLoading={handleMapLoading}
          results={props.searchResult}
          query={props.searchQuery}
          onFilter={toggleMapVisible}
        />
      )}
    </>
  );
}

const mapStateToProps = state => ({
  activeCard: getActiveCard(state),
  mapVisible: state.medicalProvider.mapVisible,
  searchResult: state.medicalProvider.result,
  searchQuery: state.medicalProvider.query,
  categories: state.medicalProvider.categories,
  specialities: state.medicalProvider.specialities,
  loading: state.medicalProvider.isLoading
});

const mapDispatchToProps = dispatch => ({
  actions: {
    ...bindActionCreators(alertsActions, dispatch),
    ...bindActionCreators(medicalProviderActions, dispatch)
  }
});

MedicalProvidersPage.propTypes = {
  mapVisible: PropTypes.bool.isRequired,
  searchResult: PropTypes.arrayOf(PropTypes.shape()),
  searchQuery: PropTypes.shape({
    location: PropTypes.shape({
      latitude: PropTypes.string,
      longitude: PropTypes.string,
      title: PropTypes.string
    }),
    category: PropTypes.string,
    speciality: PropTypes.string
  }),
  activeCard: PropTypes.shape({
    id: PropTypes.number
  }).isRequired,
  categories: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  specialities: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  loading: PropTypes.bool.isRequired,
  actions: PropTypes.shape({
    newSearch: PropTypes.func.isRequired,
    addAlert: PropTypes.func.isRequired,
    getMedicalProviderFacilities: PropTypes.func.isRequired,
    startLoadingMap: PropTypes.func.isRequired,
    stopLoadingMap: PropTypes.func.isRequired
  }).isRequired
};

MedicalProvidersPage.defaultProps = {
  searchResult: [],
  searchQuery: null
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MedicalProvidersPage);
