import { object, string } from 'yup';
import { REGEX } from 'constants/constants';
import IBAN from 'iban';

const validateIban = value => IBAN.isValid(value);
export const maxLengthConstants = {
  holder: 50,
  iban: 34,
  swiftCode: 11,
  account: 8,
  sortCode: 6
};

const generalBankDataValidations = {
  iban: string()
    .required('isInValidText')
    .test('form-iban', 'bankFormIbanCodeErrorMsg', validateIban),
  swiftCode: string()
    .required('isInValidText')
    .matches(REGEX.SWIFT_CODE, 'bankFormSwiftCodeErrorMsg')
};

const UKBankValidations = {
  name: string().required('isInValidText'),
  accountType: string().required('isInValidText'),
  account: string()
    .required('isInValidText')
    .matches(REGEX.UK_ACCOUNT_NUMBER, 'accountNumberMinLengthError'),
  sortCode: string()
    .required('isInValidText')
    .matches(REGEX.UK_SORT_CODE, 'sortCodeMinLengthError')
};

const getBankDataSchemaValidation = isUKMarket =>
  object({
    holder: string().required('isInValidText'),
    ...(isUKMarket ? UKBankValidations : generalBankDataValidations)
  });
export default getBankDataSchemaValidation;
