import * as types from './actionTypes';

import faqApi from '../api/faqApi';

import { beginAjaxCall, ajaxCallError } from './ajaxStatusActions';
import { handleApiError, addAlert } from './alertsActions';

export function addFaqEvaluation(evaluation) {
  return { type: types.ADD_FAQ_EVALUATION, evaluation };
}

export function addFaqEvaluationSuccess() {
  return { type: types.ADD_FAQ_EVALUATION_SUCCESS };
}

export function submitFaqEvaluation(evaluation) {
  return (dispatch, getState) => {
    dispatch(beginAjaxCall());

    return faqApi
      .addEvaluation(evaluation)
      .then(result => {
        dispatch(addFaqEvaluationSuccess());
        if (result && result.errorCode) {
          dispatch(addAlert('warning', getState().labels[result.errorCode]));
        } else {
          dispatch(addAlert('success', getState().labels.faqEvaluationSuccess));
        }
      })
      .catch(error => {
        dispatch(handleApiError(error));
        dispatch(ajaxCallError());
      });
  };
}
