import React from 'react';
import PropTypes from 'prop-types';

const HelpText = props => (
  <small id={`${props.name}Help`} className={props.className}>
    {`${props.text}`}
  </small>
);

HelpText.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired
};

HelpText.defaultProps = {
  className: 'form-text text-muted'
};

export default HelpText;
