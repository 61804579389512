export default {
  alignMiddle: 'align-middle',
  btn: 'btn',
  btnAmexContinue: 'btn-amex-continue',
  btnLg: 'btn-lg',
  btnPrimary: 'btn-primary',
  col: 'col',
  inlineBlock: 'd-inline-block',
  materialIcons: 'material-icons',
  mr2: 'mr-2',
  noGutters: 'no-gutters',
  row: 'row',
  textCenter: 'text-center',
  uploadDropText: 'upload__drop__text'
};
