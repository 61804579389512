import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const HowToClaimWeAsk = props => {
  const { t } = useTranslation();
  const listItem = props.weAsk.list.map(item => {
    const itemTranslation = t(item);
    return (
      itemTranslation && (
        <li key={item}>
          <div className="row">
            <i className="material-icons md-36">check_circle</i>
            <div className="col pt-1 pt-md-2">{itemTranslation}</div>
          </div>
        </li>
      )
    );
  });

  return (
    <div className="row how-to-claim-section">
      <div className="col">
        <h6>{t(props.weAsk.title)}</h6>
        <ul className="list-we-ask-you">{listItem}</ul>
      </div>
    </div>
  );
};

HowToClaimWeAsk.propTypes = {
  weAsk: PropTypes.shape({
    title: PropTypes.string.isRequired,
    list: PropTypes.arrayOf(PropTypes.string).isRequired
  }).isRequired
};

export default HowToClaimWeAsk;
