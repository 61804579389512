import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useTranslation } from 'react-i18next';

import { addAlert } from 'actions/alertsActions';
import { uploadFile, cleanFile } from 'actions/feature/file';
import { ALERT_TYPES, FILE_ANSWER } from 'constants/constants';
import HowToClaimApi from 'api/HowToClaimApi';
import howToClaimLabels from '../HowToClaim/howToClaimLabels';
import LearnMore from './LearnMore';
import UploadDropzone from './UploadDropzone';
import UploadModal from './UploadModal/UploadModal';

const UploadComponent = props => {
   const { t } = useTranslation();
   const [showModal, setShowModal] = useState(false);
   const [howToClaim, setHowToClaim] = useState({});

    useEffect(() => {
        return () => {
            props.actions.cleanFile(props.id);
        };
    }, [props.actions, props.id]);

    useEffect(() => {
        if (props.benefitLabel) {
            setHowToClaim(howToClaimLabels[props.benefitLabel]);
        }
        if (!props.benefitLabel && props.benefitId) {
            // move to middleware
            HowToClaimApi.getHowToClaim(props.benefitId)
                .then(benefitValidated =>
                    setHowToClaim(howToClaimLabels[benefitValidated])
                )
                .catch(error =>
                    props.actions.addAlert(ALERT_TYPES.ERROR, error.response)
                );
        }
    }, [props.actions, props.benefitId, props.benefitLabel]);

    useEffect(() => {
        if (props.fileUpload[props.id] && props.fileUpload[props.id].result) {
            if (props.fileUpload[props.id].result.isSuccess) {
                props.onFileUploaded(props.fileUpload[props.id].result.fileId);
            } else {
                // Clean answer
                props.onFileUploaded(null);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.fileUpload[props.id]]);

    const onDrop = (acceptedFiles, rejectedFiles) => {
        if (rejectedFiles.length) {
            props.actions.addAlert(ALERT_TYPES.ERROR, t('fileUploadNotAdmitted'));
        } else if (acceptedFiles.length) {
            props.actions.cleanFile(props.id);
            props.actions.uploadFile(
                props.id,
                props.claimId,
                acceptedFiles[0],
                props.answerType
            );
        }
    };

    const shouldShowSpinner =
        !!props.fileUpload[props.id] && !!props.fileUpload[props.id].showSpinner;
    /**
     * If component is being used in what happened:
     * Should return TRUE when there is a fileId and the reason field is empty
     * Should return FALSE when there is a fileId and the reason field is NOT empty
     *
     * If component is being used in Expenses screen
     * Should return true if there is a fileId associated with the answer
     * Should return false if no fileId is associated.
     * @param {number} fileId id of the last file submitted associated to this answer type
     *
     */
    const isUploaded = fileId => {
        const uploadData = props.fileUpload[props.id];
        if (!uploadData) return !!fileId;
        let isReason = false;
        // eslint-disable-next-line eqeqeq
        if (uploadData) isReason = uploadData.answerType == FILE_ANSWER.FILE;
        return !!fileId && isReason;
    };

    return (
        props.shouldShow && (
            <>
                <UploadDropzone
                    onDrop={onDrop}
                    uploaded={isUploaded(props.fileId)}
                    fileName={props.fileName != null ? props.fileName : props.fileUpload != undefined && props.id != undefined && props.fileUpload[props.id] ? props.fileUpload[props.id].result?.fileName : ""}
                    uploading={shouldShowSpinner}
                    isHideFilename={props.learnMoreHide }
                />
                <br />
                {props.learnMoreHide ? null :
                    <LearnMore handleShowCards={() => setShowModal(!showModal)} />}
                <UploadModal
                    closeModal={() => setShowModal(false)}
                    learnMore={howToClaim.learnMore}
                    showModal={showModal}
                />
            </>
        )
    );
};

const mapStateToProps = state => ({
    fileUpload: state.fileUpload
});

const mapDispatchToProps = dispatch => ({
    actions: {
        ...bindActionCreators({ uploadFile, cleanFile, addAlert }, dispatch)
    }
});

UploadComponent.propTypes = {
    id: PropTypes.number,
    claimId: PropTypes.string.isRequired,
    onFileUploaded: PropTypes.func.isRequired,
    benefitLabel: PropTypes.string,
    answerType: PropTypes.string.isRequired,
    benefitId: PropTypes.number,
    fileId: PropTypes.string,
    fileName: PropTypes.string,
    shouldShow: PropTypes.bool,
    learnMoreHide: PropTypes.bool,
    fileUpload: PropTypes.shape({
        showSpinner: PropTypes.bool,
        result: PropTypes.shape({
            fileId: PropTypes.number,
            fileName: PropTypes.string
        })
    }),
    actions: PropTypes.shape({
        uploadFile: PropTypes.func.isRequired,
        cleanFile: PropTypes.func.isRequired,
        addAlert: PropTypes.func.isRequired
    }).isRequired
};

UploadComponent.defaultProps = {
    id: Math.random(),
    benefitLabel: null,
    benefitId: null,
    fileId: null,
    fileName: null,
    shouldShow: false,
    fileUpload: {}
};

export default connect(mapStateToProps, mapDispatchToProps)(UploadComponent);
