import React from 'react';
import PropTypes from 'prop-types';

const ThankYouNextSteps = props => {
  return (
    <div className="text-center">
      <div className="main-card">
        <h3 className="mb-3">{props.thanksNextSteps}</h3>
        <div className="mb-4">
          <p>{props.thanksClaimAssessed}</p>
          <p>{props.thanksProcessClaim}</p>
        </div>
      </div>
    </div>
  );
};

ThankYouNextSteps.propTypes = {
  thanksNextSteps: PropTypes.string.isRequired,
  thanksClaimAssessed: PropTypes.string.isRequired,
  thanksProcessClaim: PropTypes.string.isRequired
};
export default ThankYouNextSteps;
