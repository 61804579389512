import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'components/shared/Forms';

const StepListItem = props => {
  const button = props.button ? (
    <div className="col-5 col-md-6">
      <Button
        id="btn-stepListItem"
        type="submit"
        className="btn-amex-call-to-action p-md-4"
        onClick={props.onStepClick}
      >
        {props.btnLabel}
      </Button>
    </div>
  ) : (
    ''
  );
  return (
    <div className="step-list-item left-card row align-items-center p-2 mb-2">
      <div className="col-7 col-md-6 p-2">
        <h4>{props.title}</h4>
        <span className="step-list-item__descr">{props.description}</span>
      </div>
      {button}
    </div>
  );
};

StepListItem.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  button: PropTypes.bool.isRequired,
  onStepClick: PropTypes.func,
  btnLabel: PropTypes.string
};

StepListItem.defaultProps = {
  onStepClick: () => {},
  btnLabel: ''
};

export default StepListItem;
