import { MARKETS } from 'constants/constants';

export function convertPersonalNumberToDateByMarket(personalNumber, market) {
  switch (market) {
    case MARKETS.AMEX_FI:
      return convertFinnishPersonalNumberToDate(personalNumber);
    case MARKETS.AMEX_SE:
      return convertSwedishPersonalNumberToDate(personalNumber);
    case MARKETS.AMEX_NO:
      return convertNorwayPersonalNumberToDate(personalNumber);
    default:
      return '';
  }
}

export function convertNorwayPersonalNumberToDate(personalNumber) {
  const digit7 = parseInt(personalNumber.substr(6, 1), 10);
  const digit8 = parseInt(personalNumber.substr(7, 1), 10);
  const digit9 = parseInt(personalNumber.substr(8, 1), 10);

  const individualNumber = digit7 * 100 + digit8 * 10 + digit9;

  const day = parseInt(personalNumber.substr(0, 2), 10);
  const month = parseInt(personalNumber.substr(2, 2), 10) - 1;
  let year = parseInt(personalNumber.substr(4, 2), 10);

  if (individualNumber >= 0 && individualNumber <= 499) year += 1900;
  else if (
    individualNumber >= 500 &&
    individualNumber <= 749 &&
    year >= 54 &&
    year <= 99
  )
    year += 1800;
  else if (
    individualNumber >= 900 &&
    individualNumber <= 999 &&
    year >= 40 &&
    year <= 99
  )
    year += 1900;
  else if (
    individualNumber >= 500 &&
    individualNumber <= 999 &&
    year >= 0 &&
    year <= 39
  )
    year += 2000;
  else year = 1700;

  return year && validateMonth(month) && day
    ? new Date(year, month, day, 12, 0, 0)
    : null;
}

export function convertSwedishPersonalNumberToDate(personalNumber) {
  const isOlder100 = personalNumber.substr(6, 1) === '+';
  const currentYear = String(new Date().getUTCFullYear()).substr(2, 2);
  const yearAux = parseInt(personalNumber.substr(0, 2), 10);
  const year =
    isOlder100 || yearAux > currentYear
      ? parseInt('1900', 10) + yearAux
      : parseInt('2000', 10) + yearAux;

  const month = parseInt(personalNumber.substr(2, 2), 10) - 1;
  const day = parseInt(personalNumber.substr(4, 2), 10);
  return year && validateMonth(month) && day
    ? new Date(year, month, day, 12, 0, 0)
    : null;
}

export function convertFinnishPersonalNumberToDate(personalNumber) {
  let centuryOfBirth = '';
  const centuryModifier = personalNumber.substr(6, 1);
  const yearAux = parseInt(personalNumber.substr(4, 2), 10);
  switch (centuryModifier) {
    case '+':
      centuryOfBirth = '1800';
      break;
    case '-':
      centuryOfBirth = '1900';
      break;
    case 'A':
      centuryOfBirth = '2000';
      break;
    default:
      return '';
  }
  const year = parseInt(centuryOfBirth, 10) + parseInt(yearAux, 10);
  const month = parseInt(personalNumber.substr(2, 2), 10) - 1;
  const day = parseInt(personalNumber.substr(0, 2), 10);
  return year && validateMonth(month) && day
    ? new Date(year, month, day, 12, 0, 0)
    : null;
}

const validateMonth = month => month || month === 0;
