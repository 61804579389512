import React from 'react';
import ReactModal from 'react-modal';
import { useTranslation } from 'react-i18next';
import { PropTypes } from 'prop-types';
import Button from 'components/shared/Forms/Button';
import Row from 'components/shared/Table/Row';
import Col from 'components/shared/Table/Col';

function NotificationModal(props) {
  const { t } = useTranslation();

  return (
    <ReactModal
      isOpen={props.isOpen}
      onRequestClose={props.onRequestClose}
      contentLabel="contentLabel"
      className="modal-frame"
    >
      <Row>
        <Col className="text-center py-4">
          <span className="modal-description">{t(props.text)}</span>
        </Col>
      </Row>
      <Row className="border-top pt-2 mt-3">
        <Col className="text-center">
          <Button
            type="submit"
            className="btn btn-primary btn-lg mt-3 btn-amex"
            onClick={props.onClick}
            text={t('commonOk')}
          />
        </Col>
      </Row>
    </ReactModal>
  );
}
NotificationModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired
};
export default NotificationModal;
