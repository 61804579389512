export const GA_EVENTS = {
  FOOTER: 'Footer',
  SIDEBAR: 'SideBar',
  COOKIES: 'Cookies',
  QUESTION: 'TreeQuestion',
  QUESTIONDATES: 'TreeQuestionDatesStep',
  POLICY: 'PolicyComponent',
  AFFECTEDPERSON: 'AffectedPeople',
  QUESTIONNARIE: 'Questionarie',
  CLAIMWIZARD: 'ClaimWizard',
  EXPENSESCATEGORIES: 'ExpensesCategory',
  EXPENSESADDITIONALCLAIMANTS: 'ExpensesAdditionalClaimants',
  PURCHASEITEM: 'PurchaseItem',
  REVIEW: 'ReviewClaim',
  PROFILE: 'Profile',
  HOME: 'Home',
  NEWSMODAL: 'NewsModal',
  HEADER: 'Header',
  LANGUAGESELECTOR: 'LanguageSelector',
  USERACCOUNT: 'UserAccount',
  CERTIFICATE: 'DownloadCertificate'
};

export const GA_ACTIONS = {
  CLICK: 'click',
  ANSWER: 'answered',
  SUBMIT: 'submit',
  DECLINE: 'declineClaim',
  EARLYEXIT: 'earlyExitClaim',
  SELECT: 'select',
  REMOVE: 'remove',
  ADD: 'add',
  SAVE: 'save',
  EDIT: 'edit',
  CANCEL: 'cancel'
};
