import {
  VALIDATE_DATES_REQUEST,
  VALIDATE_DATES_SUCCEEDED,
  VALIDATE_DATES_FAILED,
  setDatesValidation
} from 'actions/feature/dates';
import { saveClaim } from 'actions/feature/claim';
import { CLAIMS, VALIDATE_DATES } from 'api/apiRoutes';
import { apiRequest } from 'actions/core/api';
import { updateClaimStep, updateCurrentStep } from 'actions/claimActions';
import { setError } from 'actions/core/error';
import { updateActivePage } from 'actions/activePageActions';
import { hideSpinner, showSpinner } from 'actions/core/ui';

import { DATES_VALIDATION_RESULT, EXPENSES, REVIEW } from 'constants/constants';
import { EXPENSES_STEP } from 'constants/claim';
import { findCurrentStep } from 'containers/ClaimWrapper/ClaimWrapper';

export const validateDates = ({ dispatch, getState }) => next => action => {
  next(action);

  if (action.type === VALIDATE_DATES_REQUEST) {
    const claimId = action.payload.id;
    const language = getState().marketLanguages.selectedLanguage;
    dispatch(showSpinner());
    dispatch(
      apiRequest(
        'POST',
        `${CLAIMS}/${claimId}${VALIDATE_DATES}/${language}`,
        {},
        action.payload.trees.dates.questions,
        {},
        VALIDATE_DATES_SUCCEEDED,
        VALIDATE_DATES_FAILED
      )
    );
  }
};

export const validateDatesSuccess = ({
  dispatch,
  getState
}) => next => action => {
  next(action);

  if (action.type === VALIDATE_DATES_SUCCEEDED) {
    if (action.payload.result === DATES_VALIDATION_RESULT.DECLINE) {
      dispatch(setDatesValidation(action.payload));
      dispatch(hideSpinner());
    } else {
      const currentStep = findCurrentStep(
        getState().currentStep,
        EXPENSES_STEP
      );
      const activePage =
        currentStep.lastStep === EXPENSES_STEP ? EXPENSES : REVIEW;
      dispatch(updateClaimStep(EXPENSES_STEP));
      dispatch(updateCurrentStep(currentStep));
      dispatch(updateActivePage(activePage));
      dispatch(saveClaim(false));
      dispatch(hideSpinner());
    }
  }
};

export const validateDatesFail = ({ dispatch }) => next => action => {
  next(action);

  if (action.type === VALIDATE_DATES_FAILED) {
    dispatch(hideSpinner());
    dispatch(setError(action.payload));
  }
};

export default [validateDates, validateDatesSuccess, validateDatesFail];
