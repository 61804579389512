// TODO: technical debts
/* eslint-disable no-undef */
import React from 'react';
import PropTypes from 'prop-types';
/*
  If open exist and there is at least one character in search term
  open the suggestion box
*/
const Suggestions = ({
  searchTerm,
  updateLocalState,
  updateParentState,
  open,
  suggestedItems,
  currentOption
}) => (
  <div
    className="suggestions"
    style={
      open && searchTerm.length > 0 ? { display: 'block' } : { display: 'none' }
    }
  >
    <ul className="suggestions-list">
      {suggestedItems.map(autoCompleteItem => (
        <li
          key={autoCompleteItem}
          className={`suggestion ${suggestedItems[currentOption] ===
            autoCompleteItem && 'suggestion--active'}`}
          onClick={Suggestions.selectCompletion(
            updateLocalState,
            updateParentState
          )}
          onMouseOver={Suggestions.mouseOverSuggestion(
            updateLocalState,
            suggestedItems.indexOf(autoCompleteItem)
          )}
          onMouseLeave={Suggestions.mouseLeaveSuggestion(updateLocalState)}
          onFocus={Suggestions.hoverFocus}
          role="presentation"
        >
          {autoCompleteItem}
        </li>
      ))}
    </ul>
  </div>
);

// Select suggestion on Mouse Click
Suggestions.selectCompletion = (
  updateLocalState,
  updateParentState
) => event => {
  event.preventDefault();
  // When option selected return this to searchTerm and update the state
  // to filter the items
  // Also close the suggestion box, resetting the suggestion items and current options go to -1
  updateLocalState({
    searchTerm: event.currentTarget.textContent,
    open: false,
    suggestedItems: [],
    currentOption: -1
  });
  updateParentState(event.target.textContent);
};

// Suggestion when mouse is over and option
Suggestions.mouseOverSuggestion = (updateLocalState, indexNumber) => () => {
  // get the selected option and update the current option
  updateLocalState({
    currentOption: indexNumber
  });
};

// When mouse leaves the suggestion reset the current option to -1
Suggestions.mouseLeaveSuggestion = updateLocalState => () => {
  updateLocalState({
    currentOption: -1
  });
};
// used together with mouse leave and enter for accesibility.
Suggestions.hoverFocus = event => {
  event.focus();
};

Suggestions.propTypes = {
  searchTerm: PropTypes.string.isRequired,
  updateLocalState: PropTypes.func.isRequired,
  updateParentState: PropTypes.func.isRequired,
  open: PropTypes.bool,
  suggestedItems: PropTypes.arrayOf({}),
  currentOption: PropTypes.number.isRequired
};

Suggestions.defaultProps = {
  open: false,
  suggestedItems: []
};

export default Suggestions;
