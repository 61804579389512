export const failOnTypeAlreadyDefinedCreator = apiImplementations => type => {
  if (apiImplementations[type]) {
    throw new Error(`Api '${type}' already defined.`);
  }
};

export const failOnTypeUndefinedCreator = apiImplementations => type => {
  if (!apiImplementations[type]) {
    throw new Error(`Api '${type}' not defined.`);
  }
};

export const failOnProductionCode = methodName => {
  if (!('describe' in global)) {
    throw new Error(`${methodName} must never be used in production code`);
  }
};
