import React, { useState, useEffect } from 'react';
import PropType from 'prop-types';
import l10ns from 'flatpickr/dist/l10n';
import Flatpickr from 'react-flatpickr';
import Moment from 'moment';
import { LANGUAGE_MAPS, DATE_FORMAT } from 'constants/constants';

const FlatpickrWithBlur = props => {
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    if (props.value) {
      const valueDate = Moment(props.value);
      setInputValue(valueDate.format('DD/MM/YYYY'));
    } else {
      setInputValue('');
    }
  }, [props.value]);

  /** @param {import('flatpickr/dist/types/locale').key} languageCode */
  const getTranslationByLanguageCode = languageCode => l10ns[languageCode];

  const handleOnCloseAsBlur = e => {
    const { name, onBlur } = props;
    const auxValue = (e && e[0]) || inputValue;

    onBlur({
      target: {
        name,
        value: getValueDateFormated(auxValue)
      }
    });
  };

  const handleInputValueChange = e => {
    setInputValue(e.target.value);
  };

  const getValueDateFormated = value => {
    if (!value) return '';

    const dateValue = Moment(value, 'DD/MM/YYYY');
    return dateValue && dateValue.isValid()
      ? dateValue.format(DATE_FORMAT)
      : '';
  };

  const langCode = LANGUAGE_MAPS[props.language];

  const options = {
    ...props.options,
    locale: getTranslationByLanguageCode(langCode)
  };

  const validationClassName = props.className.includes('is-invalid')
    ? 'is-invalid'
    : '';

  return (
    <Flatpickr
      id={props.id}
      disabled={props.disabled}
      aria-describedby={props.ariaDescribedby}
      onClose={handleOnCloseAsBlur}
      autoComplete={props.autoComplete}
      options={options}
      placeholder={props.placeholder}
      className={props.className}
      maxLength={props.maxLength}
      value={getValueDateFormated(props.value)}
      render={(inputProps, ref) => (
        <div className={`flatpickr ${validationClassName}`}>
          <input
            {...inputProps}
            ref={ref}
            value={inputValue}
            onChange={handleInputValueChange}
            onBlur={handleOnCloseAsBlur}
          />
        </div>
      )}
    />
  );
};

FlatpickrWithBlur.propTypes = {
  id: PropType.string.isRequired,
  disabled: PropType.bool,
  ariaDescribedby: PropType.string,
  autoComplete: PropType.string,
  maxLength: PropType.number,
  value: PropType.string,
  options: PropType.shape({}),
  placeholder: PropType.string,
  className: PropType.string,
  name: PropType.string.isRequired,
  onBlur: PropType.func.isRequired,
  onChange: PropType.func.isRequired,
  language: PropType.string.isRequired
};

FlatpickrWithBlur.defaultProps = {
  disabled: false,
  options: {},
  placeholder: '',
  className: '',
  autoComplete: 'off',
  ariaDescribedby: '',
  maxLength: 100,
  value: undefined
};

export default FlatpickrWithBlur;
