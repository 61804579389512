/* eslint-disable react/jsx-indent */
import React from 'react';
import PropTypes from 'prop-types';

import BenefitCarousel from './BenefitCarousel';
import BenefitDetails from './BenefitDetails';

class ExploreCover extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentBenefit: 0,
      doc: null,
      errorMessage: null
    };

    this.handleBenefitChange = this.handleBenefitChange.bind(this);
    this.getBenefitList = this.getBenefitList.bind(this);
  }

  componentDidMount() {
    this.getBenefitList();
  }

  componentDidUpdate(prevProps) {
    if (this.props.language !== prevProps.language) {
      this.getBenefitList();
    }
    // eslint-disable-next-line react/no-did-update-set-state
  }

  getBenefitList() {
    this.props
      .getBenefitListAction(
        this.props.marketCode,
        this.props.productCode,
        this.props.language
      )
      .then(data => {
        if (data.results.length) {
          this.setState({ doc: data.results[0].data, errorMessage: null });
        } else {
          this.setState({ errorMessage: this.props.labels.noBenefitsFound });
        }
      });
  }

  handleBenefitChange(index) {
    this.setState({ currentBenefit: index });
  }

  render() {
    let content = this.state.doc ? (
      <>
        <BenefitCarousel
          benefits={this.state.doc.benefit}
          selectedBenefit={this.state.currentBenefit}
          onChangeSelected={this.handleBenefitChange}
          labels={this.props.labels}
        />
        <hr />
        <BenefitDetails
          benefit={this.state.doc.benefit[this.state.currentBenefit]}
          selectedBenefit={this.state.currentBenefit}
        />
      </>
    ) : null;
    if (this.state.errorMessage) {
      content = <p className="mt-3">{this.state.errorMessage}</p>;
    }
    return content;
  }
}

ExploreCover.propTypes = {
  marketCode: PropTypes.string.isRequired,
  productCode: PropTypes.string.isRequired,

  labels: PropTypes.shape({
    noBenefitsFound: PropTypes.string.isRequired
  }).isRequired,
  getBenefitListAction: PropTypes.func.isRequired,
  language: PropTypes.string.isRequired
};

export default ExploreCover;
