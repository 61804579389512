import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Card from '@material-ui/core/Card';

const styles = theme => ({
  root: {
    overflow: 'visible',
    [theme.breakpoints.down('xs')]: {
      paddingRight: 0,
      paddingLeft: 0
    }
  }
});

function CardOverride(props) {
  const { classes, children } = props;
  return (
    <Card {...props} className={classes.root}>
      {children}
    </Card>
  );
}

CardOverride.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.shape({})
  }).isRequired,
  children: PropTypes.shape({}).isRequired
};

export default withStyles(styles)(CardOverride);
