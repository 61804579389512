import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button } from 'components/shared/Forms';
import './_CheckCoverPage.scss';
import { COVER_PAGE_FORM, CHATBOT_URL } from 'constants/constants';

const BenefitsList = ({ modalErrorMessage, benefits, goTo }) => {
  const { t } = useTranslation();
  const showChatbotButton = !!+process.env.REACT_APP_SHOW_CHATBOT;
  const getBenefits = () =>
    benefits.map(benefit => (
      <li className="pb-1" key={benefits.indexOf(benefit.benefitLabelCode)}>
        <span>{t(benefit.benefitLabelCode)}</span>
      </li>
    ));

  const goToCoverDetails = () => goTo(COVER_PAGE_FORM);

  return (
    !modalErrorMessage &&
    benefits &&
    benefits.length > 0 && (
      <div className="benefit-list-container">
        <div className="row pt-4 mb-4">
          <div className="col">
            <div className="row mb-2">
              <div className="col label">
                {`${t('commonProductBenefitsLabel')}:`}
              </div>
            </div>
            <div className="row">
              <div className="col">
                <ul className="pl-0 label">{getBenefits()}</ul>
              </div>
            </div>
          </div>
        </div>
        <div className="benefit-list-buttons-container">
          <Button
            type="submit"
            className="btn btn-default btn-amex btn-amex-selected generate-certificate-button"
            text={t('generateCertificate')}
            onClick={goToCoverDetails}
          />
          {showChatbotButton && (
            <a
              href={CHATBOT_URL}
              target="_blank"
              className="btn btn-default btn-amex btn-amex-selected generate-certificate-external-link"
              rel="noopener noreferrer"
            >
              {t('goToChatbot')}
            </a>
          )}
        </div>
      </div>
    )
  );
};

BenefitsList.propTypes = {
  modalErrorMessage: PropTypes.string.isRequired,
  benefits: PropTypes.arrayOf(PropTypes.string).isRequired,
  goTo: PropTypes.func.isRequired
};

export default BenefitsList;
