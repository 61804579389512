import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';
import { groupByExpenseId, getExpenseTypes } from 'utils/expensesHelper';
import { getAllCurrencies } from 'utils/currencyHelper';
import { withConfig } from '../../ConfigurationProvider';
import { Icon } from '@material-ui/core';
import { green } from '@material-ui/core/colors';

import { Col } from 'components/shared/Table';

export const CheckedContentExpenses = props => {    
  const { t } = useTranslation();
  const currenciesObj = getAllCurrencies();
  const expenseTypes = getExpenseTypes(props.config.EXPENSE_TYPES);
  const getGroupedExpensesFormatted = grouped =>
    grouped.map(expense => {
      const currency = currenciesObj[expense.currencyCode];
      return (
          <div className="container mb-2 formatted-question" key={expense.description}>
              <div className="row">
              <div className="col-md-4 col-sm-12">
                      <span className="font-weight-bold">{expense.description}</span>                     
                  </div>                  
              {expense.sendType == 2 ? (
                      <div className="col-md-4 col-sm-8 formatted-answer">
                          <div className="row">
                      <i style={{ color: "red" }} class="material-icons">error</i>
                            &nbsp;&nbsp;  {t('ActionRequired\n')}
                          </div></div>
              ) : (
                          <div className="col-md-4 col-sm-8 formatted-answer">
                              <div className="row">
                              {expense.fileName != null && expense.fileName != "" ? <Icon style={{ color: green[500] }} fontSize="small">
                                  check_circle
                              </Icon> : ""}
                                 &nbsp;&nbsp;  {expense.fileName}    {props.answer}
                              </div></div>
                  )}
                  <Col className="col-6 col-sm-4 col-md-4 text-left pt-3">
              <NumberFormat
                  className="font-italic float-right"
                  thousandSeparator={
                      props.config.REGIONAL_SETTINGS.thousandSeparator
                  }
                  decimalSeparator={props.config.REGIONAL_SETTINGS.decimalSeparator}
                  fixedDecimalScale
                  decimalScale={2}
                  displayType="text"
                  value={expense.amount}
                  isNumericString
                  prefix={`${currency.symbol} `}
                      /></Col>
          </div>
        </div>
      );
    });

  const getExpensesFormatted = () => {
    const { groupedExpenses, expensesList } = groupByExpenseId(props.expenses);
    return expensesList.map((expense, key) => (
      <div className="card" key={expense}>
        <div className="card-header" id={`heading${key}`}>
                <span className="font-weight-bold">                   
            <i className="material-icons align-middle mr-2 pb-1">
              {expenseTypes[expense].icon}
            </i>
            {t([expenseTypes[expense].label])}
          </span>
        </div>
        <div className="collapse show">
                <div className="card-body">                    
            {getGroupedExpensesFormatted(groupedExpenses[expense])}
          </div>
        </div>
      </div>
    ));
  };

  return <div className="expenses-card">{getExpensesFormatted()}</div>;
};

CheckedContentExpenses.propTypes = {
  expenses: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  config: PropTypes.shape({
    REGIONAL_SETTINGS: PropTypes.shape({
      thousandSeparator: PropTypes.string.isRequired,
      decimalSeparator: PropTypes.string.isRequired
    }).isRequired,
    EXPENSE_TYPES: PropTypes.shape({
      label: PropTypes.string,
      icon: PropTypes.arrayOf(PropTypes.string)
    })
  }).isRequired
};

export default withConfig(CheckedContentExpenses);
