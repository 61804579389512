// Disabling Reason: errors regarding fetching data are printed out
/* eslint-disable no-console */
import React from 'react';
import Prismic from 'prismic-javascript';
import {
  PrismicConf,
  POLICY_FAQ_CODES,
  POLICY_FAQ_TITLES
} from 'constants/constants';

const PRISMIC_KEY = process.env.REACT_APP_PRISMIC_KEY;

export const LinkResolver = () => '/';

export const getContext = () =>
  new Promise((resolve, reject) => {
    // Might need access token. In that case, token must be added after endpoint
    Prismic.api(PrismicConf.apiEndpoint, { PRISMIC_KEY })
      .then((api, err) => {
        if (err) reject(err);
        resolve(api);
      })
      .catch(err => console.warn(err));
  });

/**
 * @param {*} document
 * @param {*} label
 *
 * @return {Object} Object containing the link to a web document.
 */
export const buildLinkToPdf = (document, label) => {
  if (document) {
    return (
      <a
        href={document.data.link.url}
        target="_blank"
        rel="noopener noreferrer"
      >
        {label}
      </a>
    );
  }
  return null;
};

export const buildMarketProductTag = (marketCode, productCode) =>
  `${marketCode} - ${productCode}`;

export const getFaqIdByName = faqName => {
  let result =
    faqName &&
    faqName.length &&
    POLICY_FAQ_CODES.indexOf(faqName.toUpperCase());
  if (result >= 0) result += 1;
  return result;
};

export const getFaqTitleById = idFaq => POLICY_FAQ_TITLES[idFaq - 1];
