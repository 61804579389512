import React from 'react';
import PropTypes from 'prop-types';

const PageHeader = props => (
  <div className="page-header mb-4 align-middle text-center">
    {props.productImage && (
      <img
        src={props.productImage}
        alt="product"
        height="35"
        className="float-left"
      />
    )}
    <h4 className="align-middle">{props.title}</h4>
  </div>
);

PageHeader.propTypes = {
  title: PropTypes.string,
  productImage: PropTypes.string
};

PageHeader.defaultProps = {
  productImage: '',
  title: ''
};

export default PageHeader;
