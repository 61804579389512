import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { every, isEmpty, min } from 'lodash';
import { validateField, validateForm } from 'utils/formValidationHelper';
import { Row, Col } from 'components/shared/Table';
import TitleSelectionGroup from 'components/TitleSelectionGroup';
import {
  Form,
  TextField,
  DateField,
  PhoneField,
  Button
} from 'components/shared/Forms';
import { GENERIC_FORM_INPUT_MAX_LENGTH } from 'constants/constants';
import { length, maxLength } from 'constants/inputsLengths';
import {
  getSchemaValidation,
  emailSchemaValidations,
  passwordSchemaValidations
} from 'components/RegistrationForm/RegistrationFormSchemaValidation';
import { convertPersonalNumberToDateByMarket } from 'utils/convertHelper';
import { getMarketFromUrl,isMarketLufthansa } from 'utils/urlHelpers';

const defaultFromValues = {
  title: '',
  firstName: '',
  surname: '',
  personalNumber: '',
  dateOfBirth: '',
  phone: '',
  address: '',
  addressNumber: '',
  addressCity: '',
  addressPC: '',
  email: '',
  emailConfirmation: '',
  password: '',
  passwordConfirmation: '',
  cardNumber: ''
};
// we are unsing that array to index field and field reference
const formValuesIndex = Object.keys(defaultFromValues);
const RegistrationForm = props => {
  const inputFieldRefs = useRef(
    Object.keys(defaultFromValues).map(() => React.createRef())
  );
  const [errorFields, setErrorFields] = useState({});
  const [formValues, setFormValues] = useState({ ...defaultFromValues });
  const { t } = useTranslation();
  const market = getMarketFromUrl();
  const schemaValidation = getSchemaValidation(props.inBodiam, market);    
  const isPolicyEmpty = every(props.policy, isEmpty);

  React.useEffect(() => {
    if (!isPolicyEmpty) {
      setFormValues({
        ...formValues,
        firstName: props.policy.name,
        surname: props.policy.lastName,
        dateOfBirth: props.policy.date
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.policy]);

  const getIndexByKey = key => formValuesIndex.indexOf(key);

  const defineDateOfBirthByPersonalNumber = event => {
    const result = convertPersonalNumberToDateByMarket(
      event.target.value,
      market
    );
    setFormValues({
      ...formValues,
      dateOfBirth: result
    });
  };

  const handleValueChange = event => {
    setNewValues(event.target.name, event.target.value);
  };

  const setNewValues = (name, value) => {
    setFormValues({
      ...formValues,
      [name]: value
    });
  };

  const handlePasswordValueChange = event => {
    handleValueChange(event);
    handleValidation(event.target.name, event.target.value);
  };

  const handlePasteConfirmField = event => {
    event.preventDefault();
  };

  const moveScrollToError = errors => {
    const minErrorIndex = min(
      Object.keys(errors).map(e =>
        errors[e].hasError ? getIndexByKey(e) : undefined
      )
    );
    inputFieldRefs.current[minErrorIndex].current.scrollIntoView();
  };

  const handleSubmit = () => {
    const result = validateForm(schemaValidation, formValues);
    const newErrors = {
      ...result.errorFields
    };
    setErrorFields(newErrors);

    if (!result.isValid) {
      moveScrollToError(newErrors);
    } else {
      props.onSubmitForm(formValues);
    }
  };

  /**
   * @param {string} selected
   */
  const handleOnChangeTitle = selected => {
    setFormValues({ ...formValues, title: selected });
  };

  const handleValidation = (fieldName, value) => {
    const result = validateField(schemaValidation, fieldName, value);
    setErrorFields({
      ...errorFields,
      ...result.errorFields
    });
  };

  const handleEmailConfirmation = (fieldName, value) => {
    const result = validateForm(emailSchemaValidations, {
      email: formValues.email,
      [fieldName]: value
    });

    setErrorFields({
      ...errorFields,
      ...result.errorFields
    });
  };

  const handlePasswordConfirmation = (fieldName, value) => {
    const result = validateForm(passwordSchemaValidations, {
      password: formValues.password,
      [fieldName]: value
    });
    setErrorFields({
      ...errorFields,
      ...result.errorFields
    });
  };

  const setReferenceByName = name =>
        inputFieldRefs.current[getIndexByKey(name)];

    const ilegalyChangable = !props.inBodiam && !isPolicyEmpty;       
  return (
    <Row>
          <Col>         
              <Form className={isMarketLufthansa() == false ? "col-12 col-md-6 mt-4" : "col-12 col-md-8 mt-4"}>
          <h4 className="mb-3">
            {props.inBodiam
              ? t('registrationBodiamNeedsTitle')
              : t('registrationAxaNeedsTitle')}
          </h4>
          <Row>
            <Col>
              <TitleSelectionGroup
                titleRef={setReferenceByName('title')}
                availableTitles={props.availableTitles}
                onValueChange={handleValueChange}
                hasHelp
                isSelectedTitle={!!formValues.title}
                handleTitleSelection={handleOnChangeTitle}
                selectedTitle={formValues.title}
                classNames={{
                  labelWrapper: 'label-input col-12'
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <TextField
                inputRef={setReferenceByName('firstName')}
                label={t('commonFormUserNameLabel')}
                name="firstName"
                value={formValues.firstName}
                onValidate={handleValidation}
                onValueChange={handleValueChange}
                errorFields={errorFields}
                ariaDescribedby={t('commonFormUserNameLabel')}
                disabled={ilegalyChangable}
                required
                toolTipText={
                  ilegalyChangable &&
                  t('toolTip_PolicyDataRestrictionFirstName')
                }
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <TextField
                inputRef={setReferenceByName('surname')}
                label={t('commonFormSurnameLabel')}
                name="surname"
                value={formValues.surname}
                onValidate={handleValidation}
                onValueChange={handleValueChange}
                errorFields={errorFields}
                ariaDescribedby={t('commonFormSurnameLabel')}
                disabled={ilegalyChangable}
                toolTipText={
                  ilegalyChangable && t('toolTip_PolicyDataRestrictionLastName')
                }
                required
              />
            </Col>
          </Row>
          {props.showPersonalNumber && (
            <Row>
              <Col>
                <TextField
                  inputRef={setReferenceByName('personalNumber')}
                  value={formValues.personalNumber}
                  name="personalNumber"
                  placeholder={t('commonFormPersonalNumberPlaceholder')}
                  onBlur={defineDateOfBirthByPersonalNumber}
                  maxLength={length.personalNumber}
                  onValueChange={handleValueChange}
                  onValidate={handleValidation}
                  errorFields={errorFields}
                  label={t('commonFormPersonalNumberLabel')}
                  ariaDescribedby={t('commonFormPersonalNumberPlaceholder')}
                  required
                  disabled={ilegalyChangable}
                />
              </Col>
            </Row>
          )}
          <Row>
            <Col>
              <DateField
                dateRef={setReferenceByName('dateOfBirth')}
                label={t('commonFormDateLabel')}
                name="dateOfBirth"
                value={formValues.dateOfBirth}
                placeholder={t('commonFormDatePlaceholder')}
                errorFields={errorFields}
                onValueChange={value => setNewValues('dateOfBirth', value)}
                onValidate={handleValidation}
                ariaDescribedby={t('commonFormDatePlaceholder')}
                required
                disabled={props.showPersonalNumber || ilegalyChangable}
                toolTipText={
                  ilegalyChangable &&
                  t('toolTip_PolicyDataRestrictionDateOfBirth')
                }
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <PhoneField
                inputRef={setReferenceByName('phone')}
                name="phone"
                label={t('commonFormPhoneLabel')}
                helpText={t('commonFormPhoneDescription')}
                value={formValues.phone}
                onValueChange={handleValueChange}
                onValidate={handleValidation}
                errorFields={errorFields}
                required
              />
            </Col>
          </Row>

          <Row>
            <Col>
              <TextField
                inputRef={setReferenceByName('address')}
                label={t('commonFormAddressLabel')}
                name="address"
                value={formValues.address}
                onValueChange={handleValueChange}
                errorFields={errorFields}
                ariaDescribedby={t('commonFormAddressPlaceholder')}
                required
                onValidate={handleValidation}
                maxLength={maxLength.address}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <TextField
                inputRef={setReferenceByName('addressNumber')}
                label={t('commonAddressNumber')}
                name="addressNumber"
                value={formValues.addressNumber}
                onValueChange={handleValueChange}
                errorFields={errorFields}
                ariaDescribedby={t('commonFormAddressNumberPlaceholder')}
                onValidate={handleValidation}
                maxLength={maxLength.addressNumber}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <TextField
                inputRef={setReferenceByName('addressCity')}
                label={t('commonFromCityLabel')}
                name="addressCity"
                value={formValues.addressCity}
                onValueChange={handleValueChange}
                errorFields={errorFields}
                ariaDescribedby={t('commonFromCityPlaceholder')}
                required
                onValidate={handleValidation}
                maxLength={maxLength.addressCity}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <TextField
                inputRef={setReferenceByName('addressPC')}
                label={t('commonFromPostCodeLabel')}
                name="addressPC"
                value={formValues.addressPC}
                onValueChange={handleValueChange}
                errorFields={errorFields}
                ariaDescribedby={t('commonFromPostCodePlaceholder')}
                required
                onValidate={handleValidation}
                maxLength={maxLength.addressPC}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <TextField
                inputRef={setReferenceByName('email')}
                label={t('commonFromEmailLabel')}
                name="email"
                value={formValues.email}
                onValueChange={handleValueChange}
                onValidate={handleValidation}
                maxLength={GENERIC_FORM_INPUT_MAX_LENGTH}
                helpText={t('commonFormEmailRegistrationDiscription')}
                errorFields={errorFields}
                ariaDescribedby={t('commonFromEmailPlaceholder')}
                required
                sanitize={false}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <TextField
                inputRef={setReferenceByName('emailConfirmation')}
                label={t('registrationConfirmEmail')}
                name="emailConfirmation"
                value={formValues.emailConfirmation}
                maxLength={GENERIC_FORM_INPUT_MAX_LENGTH}
                onValueChange={handleValueChange}
                onPaste={handlePasteConfirmField}
                onValidate={handleEmailConfirmation}
                errorFields={errorFields}
                ariaDescribedby={t('commonFromEmailPlaceholder')}
                required
                sanitize={false}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <TextField
                inputRef={setReferenceByName('password')}
                label={t('commonFromPasswordCreateLabel')}
                name="password"
                value={formValues.password}
                type="password"
                showAllErrors
                onValueChange={handlePasswordValueChange}
                helpText={t('commonFromPasswordDiscription')}
                onValidate={handleValidation}
                errorFields={errorFields}
                ariaDescribedby={t('commonFromPasswordPlaceholder')}
                required
                sanitize={false}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <TextField
                inputRef={setReferenceByName('passwordConfirmation')}
                label={t('commonFromPasswordConfrimLabel')}
                name="passwordConfirmation"
                value={formValues.passwordConfirmation}
                type="password"
                onValueChange={handleValueChange}
                onValidate={handlePasswordConfirmation}
                errorFields={errorFields}
                ariaDescribedby={t('commonFromPasswordConfirmationPlaceholder')}
                required
                sanitize={false}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              {props.inBodiam && (
                <TextField
                  inputRef={setReferenceByName('cardNumber')}
                  label={t('commonCardNumberLabel')}
                  name="cardNumber"
                  value={
                    formValues.cardNumber &&
                    formValues.cardNumber.replace(/[^\d]/g, '')
                  }
                  className="form-control mb-2 mr-sm-2"
                  placeholder="***********0001"
                  onValueChange={handleValueChange}
                  onValidate={handleValidation}
                  errorFields={errorFields}
                  ariaDescribedby={t(
                    'commonFromPasswordConfirmationPlaceholder'
                  )}
                  required
                  maxLength={15}
                />
              )}
              <Row>
                <Col>
                  <Button
                    type="button"
                    onClick={handleSubmit}
                    className={
                      props.inBodiam
                        ? 'btn btn-primary btn-signup btn-amex'
                        : 'btn btn-primary btn-lg btn-block btn-amex'
                    }
                    text={
                      props.inBodiam
                        ? t('registrationBodiamCreateAndContinue')
                        : t('registrationCreateAndContinue')
                    }
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </Col>
    </Row>
  );
};

RegistrationForm.propTypes = {
  availableTitles: PropTypes.shape({
    enabled: PropTypes.bool.isRequired,
    values: PropTypes.arrayOf(PropTypes.string)
  }).isRequired,
  policy: PropTypes.shape({
    name: PropTypes.string,
    lastName: PropTypes.string,
    date: PropTypes.string
  }),
  onSubmitForm: PropTypes.func.isRequired,
  inBodiam: PropTypes.bool.isRequired,
  showPersonalNumber: PropTypes.bool.isRequired
};

RegistrationForm.defaultProps = { policy: {} };

export default RegistrationForm;
