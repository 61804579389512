import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { LabelFor, Label, ValidatorText } from '../shared/Forms';
import TitleSelectionButton from './TitleSelectionButton';

/**
 * Renders the form title buttons.
 * @param {{
 *  availableTitles: { enabled: boolean, values: string[] } ,
 *  classNames: {
 *    labelWrapper: string
 *  }
 *  handleTitleSelection: (titleLabelCode: string) => void,
 *  selectedTitle: string,
 * }} param0
 */
const TitleSelectionGroup = ({
  titleRef,
  availableTitles,
  classNames: { labelWrapper },
  handleTitleSelection,
  hasHelp,
  isSelectedTitle,
  name,
  selectedTitle
}) => {
  const { t } = useTranslation();
  return (
    availableTitles.enabled && (
      <LabelFor
        labelRef={titleRef}
        htmlFor="title"
        name="title"
        className={labelWrapper}
      >
        <Label text={t('commonFormUserTitleLabel')} isRequired />
        {hasHelp && (
          <ValidatorText
            name={name}
            text={t('commonSelectTitle')}
            isSelected={isSelectedTitle}
          />
        )}
        <div
          className="btn-toolbar"
          role="toolbar"
          aria-label="Toolbar with button groups"
        >
          {availableTitles.values.map(noGrouped => {
            const titleLabelCode = `BeneficiaryContactFormTitles${noGrouped}`;
            return (
              <TitleSelectionButton
                handleTitleSelection={() =>
                  handleTitleSelection(titleLabelCode)
                }
                key={titleLabelCode}
                selectedTitle={selectedTitle}
                titleLabel={t(titleLabelCode)}
                titleLabelCode={titleLabelCode}
              />
            );
          })}
        </div>
      </LabelFor>
    )
  );
};
TitleSelectionGroup.propTypes = {
  titleRef: PropTypes.shape({}),
  availableTitles: PropTypes.shape({
    enabled: PropTypes.bool.isRequired,
    values: PropTypes.arrayOf(PropTypes.string).isRequired
  }).isRequired,
  classNames: PropTypes.shape({
    labelWrapper: PropTypes.string.isRequired
  }).isRequired,
  handleTitleSelection: PropTypes.func.isRequired,
  hasHelp: PropTypes.bool,
  isSelectedTitle: PropTypes.bool,
  name: PropTypes.string,
  selectedTitle: PropTypes.string
};

TitleSelectionGroup.defaultProps = {
  titleRef: null,
  hasHelp: false,
  isSelectedTitle: undefined,
  name: '',
  selectedTitle: ''
};

export default TitleSelectionGroup;
