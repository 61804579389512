/* eslint-disable prefer-destructuring */
import React from 'react';
import Moment from 'moment';
import {
  COMBO,
  YES_NO,
  TEXT,
  TEXTBOX_WIDE,
  TEXT_AREA,
  TEXT_AREA_WIDE,
  NUMBER,
  INTEGER,
  TIME,
  CURRENCY,
  FILE,
  DATE,
  DATE_TIME,
  DATE_TIME_ROUGHLY,
  DATE_TIME_SCHEDULED,
  DATE_TIME_ACTUAL,
  FILE_ANSWER
} from 'constants/constants';
import DownloadButton from 'components/shared/DownloadButton';

/**
 * This method ensures that the triage answers are transfered into an structure
 * compliant with the expectations of the backend
 *
 * @param {Object}   answers           Object containing all questions answered
 *
 * @return {Object} Object containing the answers object and the proper attribute naming.
 */
export const transferAnswers = answers => {
  const newAnswers = [];
  Object.keys(answers).forEach(key => {
    newAnswers.push({
      Id: answers[key].questionId,
      Answer: answers[key].value,
      NextGroup: answers[key].nextGroupId
    });
  });
  return newAnswers;
};

/**
 * This method ensures that the answer in each step are properly transferred to
 * the current claim in the global state. This is done by creating a copy of the
 * current tree (param1) and setting to each object the answer with the same question Id
 *
 * @param {Array}   answers           Object containing all questions answered
 * @param {Object}   tree              Tree inside the claim in global state
 *
 * @return {Object} Copy of the selected tree in the claim with the new answers.
 */
export const getAnsweredTreeFromAnswers = (tree, answers) => {
  const newTree = { ...tree };
  tree.questions.forEach((question, index) => {
    const answer = answers.filter(ans => ans.questionId === question.id)[0];
    const notAnswered = !answer || !answer.value;
    newTree.questions[index].answer = notAnswered ? '' : answer.value;
  });
  return newTree;
};

/**
 * returns a userAnswers object given a treeOrderedByGroupId and the initialQuestionId
 *
 * @param {Object}   treeGroupedById
 * @param {Object}   initialQuestionId
 *
 * @return {Array} Object containing all questions answered
 */
export const getUserAnswersFromTree = (treeGroupedById, initialQuestionId) => {
  const getNextGroupId = question => {
    const isComboQuestion = quest => quest.type === COMBO;
    const isYesNoQuestion = quest => quest.type === YES_NO;

    const getNextGroupIdForCombo = comboQuestion => {
      const comboItems = comboQuestion.properties.items;
      const { nextGroupId } = comboItems.find(
        item => item.value === comboQuestion.answer
      );
      return nextGroupId;
    };
    const getNextGroupIdForYesNo = yesNoQuestion => {
      const isYes = yesNoQuestion.properties.valueYes === yesNoQuestion.answer;
      return isYes
        ? yesNoQuestion.properties.nextGroupIdYes
        : yesNoQuestion.properties.nextGroupIdNo;
    };

    let nextGroupId = question.nextGroup;
    if (isComboQuestion(question)) {
      nextGroupId = getNextGroupIdForCombo(question);
    }
    if (isYesNoQuestion(question)) {
      nextGroupId = getNextGroupIdForYesNo(question);
    }
    return nextGroupId;
  };

  const getUserAnswerFromQuestion = question => ({
    questionId: question.id,
    value: question.answer,
    nextGroupId: getNextGroupId(question)
  });

  const buildUserAnswers = (treeByGroupId, questionId, userAnswers) => {
    const group = treeByGroupId[questionId];

    let provisionalUserAnswers = userAnswers;
    let nextGroupId;
    let temp;
    if (group) {
      temp = group
        .map(question => {
          if (question.answer) {
            const answer = getUserAnswerFromQuestion(question);
            nextGroupId = answer.nextGroupId;
            return answer;
          }

          return null;
        })
        .filter(question => question !== undefined && question !== null);
    }
    if (temp && temp.length)
      provisionalUserAnswers = provisionalUserAnswers.concat(temp);
    if (nextGroupId)
      return buildUserAnswers(
        treeByGroupId,
        nextGroupId,
        provisionalUserAnswers
      );

    return provisionalUserAnswers;
  };

  return buildUserAnswers(treeGroupedById, initialQuestionId, []);
};

export const formatAnswer = (
  question,
  contacts,
  labels,
  config,
  userInfo,
  claim = {}
) => {
  const defaultAnswer = 'N/A';
  if (question.answer === '' || !question.answer) {
    return defaultAnswer;
  }
  switch (question.type) {
    case TEXT:
    case TEXTBOX_WIDE:
    case TEXT_AREA:
    case TEXT_AREA_WIDE:
    case NUMBER:
    case INTEGER:
    case TIME:
    case CURRENCY:
      return question.answer;
    case COMBO:
      return question.properties.items.filter(
        item => item.value === question.answer
      )[0].label;
    case FILE:
          // Check if ID of file is received
          if (question.answer.toString().length > 1) {              
            return <DownloadButton fileId={question.answer} claimId={claim.id} />;
      }
      if (question.answer === FILE_ANSWER.EMAIL) {
        return labels.uploadComponentEmail;
      }
      if (question.answer === FILE_ANSWER.UNAVAILABLE) {
        return labels.uploadComponentUnavailable;
      }
      return defaultAnswer;
    case YES_NO:
      return question.answer === 'answer-yes'
        ? labels.commonYes
        : labels.commonNo;
    case DATE:
      return Moment(question.answer).format(config.DATE_FORMAT);
    case DATE_TIME:
    case DATE_TIME_ROUGHLY:
    case DATE_TIME_SCHEDULED:
    case DATE_TIME_ACTUAL:
      return Moment(question.answer).format(
        `${config.DATE_FORMAT}, ${config.TIME_FORMAT}`
      );

    default:
      return defaultAnswer;
  }
};

/**
 * Returns the index of the first object in an array that matches the value of an specific property
 *
 * @param {Array}   array List where the index needs to be found
 * @param {String}  attr Specific attribute whose value will be compared
 * @param {Integer} value Value to be matched
 * @param {Boolean} isGroups Searching in array of arrays or just array of objects (visible groups or user answers)
 *
 * @return {Integer} Index of the first object matching with the input value
 */
export function getIndexOfObjectByProp(array, attr, value, isGroups = true) {
  for (let i = 0; i < array.length; i += 1) {
    const item = isGroups ? array[i][0] : array[i];
    if (item[attr] === value) {
      return i;
    }
  }
  return -1;
}
