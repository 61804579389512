import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import CardContent from '@material-ui/core/CardContent';

const styles = theme => ({
  root: {
    [theme.breakpoints.down('xs')]: {
      paddingLeft: '0px',
      paddingRight: '0px'
    }
  }
});

function CardContentOverride(props) {
  const { classes, children } = props;
  return (
    <CardContent {...props} className={classes.root}>
      {children}
    </CardContent>
  );
}

CardContentOverride.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.shape({})
  }).isRequired,
  children: PropTypes.shape({}).isRequired
};

export default withStyles(styles)(CardContentOverride);
