/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import history from 'utils/history';
import { getMarketFromUrl } from 'utils/urlHelpers';
import { withTranslation } from 'react-i18next';
import { BODIAM_BASE_URL, YOUR_COVER } from 'constants/constants';

class SlideDropdownToggleYourCover extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: true
    };

    this.getSubmenu = this.getSubmenu.bind(this);
    this.generalOnClick = this.generalOnClick.bind(this);
  }

  static getSubmenuItems() {
    return [
      {
        label: 'navbarCoverPolicyDetails',
        link: `${BODIAM_BASE_URL}${getMarketFromUrl()}${YOUR_COVER}`,
        ga: 'cover'
      },
      {
        label: 'navbarCoverWho',
        link: `${BODIAM_BASE_URL}${getMarketFromUrl()}${YOUR_COVER}who`,
        ga: 'cover_who'
      },
      {
        label: 'navbarCoverWhat',
        link: `${BODIAM_BASE_URL}${getMarketFromUrl()}${YOUR_COVER}what`,
        ga: 'cover_what'
      },
      {
        label: 'navbarCoverProcess',
        link: `${BODIAM_BASE_URL}${getMarketFromUrl()}${YOUR_COVER}claim-process`,
        ga: 'cover_claim-process'
      },
      {
        label: 'navbarCoverPrivacy',
        link: `${BODIAM_BASE_URL}${getMarketFromUrl()}${YOUR_COVER}privacy`,
        ga: 'cover_privacy'
      },
      {
        label: 'navbarCoverComplaint',
        link: `${BODIAM_BASE_URL}${getMarketFromUrl()}${YOUR_COVER}complain`,
        ga: 'cover_complain'
      }
    ];
  }

  getSubmenu() {
    return (
      <ul className="container your-cover-sub">
        {SlideDropdownToggleYourCover.getSubmenuItems().map((item, key) => (
          <li
            className="nav-item dropdown py-2"
            onClick={() => {
              this.generalOnClick(item.link);
            }}
            // eslint-disable-next-line react/no-array-index-key
            key={`${key}${item.label}`}
          >
            <span className="d-xs-inline d-md-block d-xl-inline">
              {this.props.t(item.label)}
            </span>
          </li>
        ))}
      </ul>
    );
  }

  toggleCollapsed = () =>
    this.setState(prevState => ({
      collapsed: !prevState.collapsed
    }));

  generalOnClick(link) {
    history.push(link);
    this.props.onRedirect();
  }

  render() {
    const {
      state: { collapsed },
      toggleCollapsed
    } = this;
    return (
      <>
        <li className="nav-item dropdown your-cover">
          <div
            className="nav-link dropdown-toggle row no-gutters"
            role="button"
            data-toggle="dropdown"
            onClick={toggleCollapsed}
            tabIndex="0"
            onKeyPress={toggleCollapsed}
          >
            <div className="col-1">
              <i className="material-icons align-middle">
                {collapsed ? 'chevron_right' : 'expand_more'}
              </i>
            </div>
            <div className="col-11">
              <span className="d-xs-inline d-md-block d-xl-inline align-middle">
                {this.props.t('landingCardQuestionH3')}
              </span>
            </div>
          </div>
        </li>
        {!collapsed && this.getSubmenu()}
      </>
    );
  }
}

SlideDropdownToggleYourCover.propTypes = {
  onRedirect: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired
};

export default withTranslation()(SlideDropdownToggleYourCover);
