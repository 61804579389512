import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { pageview } from 'utils/analytics';
import { getRouteForClaim } from 'utils/routeHelper';

import Questionnaire from 'containers/Questionnaire';

import {
  resetUserAnswers,
  resetVisibleGroups,
  loadDecisionTree
} from 'actions/decisionTreeActions';
import { updateClaimStep } from 'actions/claimActions';
import { saveClaimAndContinue } from 'actions/feature/claim';

import { WHAT_HAPPENED_LABEL } from 'constants/constants';
import { WHAT_HAPPENED_STEP } from 'constants/claim';
import { scrollTop } from 'utils/pageUtils';
import { appInsights } from 'ApplicationInsights';


export class WhatHappened extends React.Component {
  constructor(props) {
    super(props);

    this.handleOnContinue = this.handleOnContinue.bind(this);
  }

  componentDidMount() {
    pageview({
      title: 'Claim-Wizard',
      page: getRouteForClaim(
        this.props.language,
        this.props.currentClaim.benefitId,
        WHAT_HAPPENED_STEP
      )
    });
    appInsights.trackPageView(
      {
        name: 'Claim-Wizard',
        uri: getRouteForClaim(
        this.props.language,
        this.props.currentClaim.benefitId,
        WHAT_HAPPENED_STEP
      )
      }
    )
    scrollTop();
    this.props.actions.resetUserAnswers();
    this.props.actions.resetVisibleGroups();
    this.props.actions.updateClaimStep(WHAT_HAPPENED_STEP);
    this.props.actions.loadDecisionTree(WHAT_HAPPENED_LABEL);
  }

  handleOnContinue() {
    this.props.actions.saveClaimAndContinue();
  }

  render() {
    return (
      <div>
        <Questionnaire onContinue={this.handleOnContinue} />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  visibleGroups: state.decisionTree.visibleGroups,
  userAnswers: state.decisionTree.userAnswers,
  currentClaim: state.currentClaim,
  language: state.marketLanguages.selectedLanguage,
  inTriage: state.inTriage
});

const mapDispatchToProps = dispatch => ({
  actions: {
    ...bindActionCreators(
      {
        resetUserAnswers,
        resetVisibleGroups,
        loadDecisionTree,
        updateClaimStep,
        saveClaimAndContinue
      },
      dispatch
    )
  }
});

WhatHappened.propTypes = {
  currentClaim: PropTypes.shape({
    id: PropTypes.string,
    contacts: PropTypes.arrayOf(PropTypes.shape({})),
    benefitId: PropTypes.number,
    step: PropTypes.number
  }).isRequired,
  language: PropTypes.number.isRequired,
  actions: PropTypes.shape({
    resetUserAnswers: PropTypes.func.isRequired,
    resetVisibleGroups: PropTypes.func.isRequired,
    loadDecisionTree: PropTypes.func.isRequired,
    updateClaimStep: PropTypes.func.isRequired,
    saveClaimAndContinue: PropTypes.func.isRequired
  }).isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(WhatHappened);
