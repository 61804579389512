import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { DOCUMENT_TYPE } from 'constants/constants';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getDocumentLink } from 'actions/feature/howToClaim';

export const DocumentLinkComponent = props => {
  const shouldShowButton = button => !isEmpty(button);
    const buttonClass = 'btn btn-primary btn-amex-doc';    

  const { t } = useTranslation();
  React.useEffect(() => {
    props.actions.getDocumentLink(DOCUMENT_TYPE.MEDICAL_REPORT);
    props.actions.getDocumentLink(DOCUMENT_TYPE.GDPR_REPORT);
  }, [props.actions]);

  return (
    <>
      {props.config.PROVIDE_MEDICALCERTIFICATE &&
              shouldShowButton(props.buttonMedicalReport) && (
              <div class="row justify-content-center">
            <a
              className={buttonClass}
              href={props.documentLinkMedicalReport}
              target="_blank"
              rel="noopener noreferrer"
            >
                      {t(props.buttonMedicalReport.title)}
                  </a>       </div>
        )}
      {props.config.PROVIDE_GDPR && shouldShowButton(props.buttonGDPR) && (
         <div class="row justify-content-center mt-2">
          <a
            className={buttonClass}
            href={props.documentLinkGDPR}
            target="_blank"
            rel="noopener noreferrer"
          >
            {t(props.buttonGDPR.title)}
                  </a>
              </div>
      )}
    </>
  );
};

DocumentLinkComponent.propTypes = {
  documentLinkMedicalReport: PropTypes.string,
  documentLinkGDPR: PropTypes.string,
  buttonMedicalReport: PropTypes.shape({
    title: PropTypes.string
  }),
  buttonGDPR: PropTypes.shape({
    title: PropTypes.string
  }),
  config: PropTypes.shape({
    PROVIDE_MEDICALCERTIFICATE: PropTypes.bool.isRequired,
    PROVIDE_GDPR: PropTypes.bool.isRequired
  }).isRequired,
  actions: PropTypes.shape().isRequired
};

DocumentLinkComponent.defaultProps = {
  buttonMedicalReport: null,
  buttonGDPR: null,
  documentLinkMedicalReport: '',
  documentLinkGDPR: ''
};
const mapStateToProps = state => ({
  documentLinkMedicalReport:
    state.howToClaimLinks[DOCUMENT_TYPE.MEDICAL_REPORT],
  documentLinkGDPR: state.howToClaimLinks[DOCUMENT_TYPE.GDPR_REPORT],
  config: state.configuration
});

const mapDispatchToProps = dispatch => ({
  actions: {
    ...bindActionCreators({ getDocumentLink }, dispatch)
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DocumentLinkComponent);
