import React, { useState } from 'react';
import PropTypes from 'prop-types';

import UploadDocument from 'components/ContactsQuestion/Shared/UploadDocument';
import { isFileIdValid } from 'components/ContactsQuestion/AffectedPerson/AffectedPeopleUtils';
import AffectedPerson from './AffectedPerson';

const AffectedPersonWithDocument = props => {
  const person = {
    id: props.person.id,
    typeObjectId: props.person.typeObjectId,
    isValid: props.person.isValid,
    payload: {
      ...props.person.payload,
      fileIdProvider: props.person.payload.fileIdProvider
    }
  };

  const [formValues, setFormValues] = useState(person);

  const handleFieldFormValueChange = value => {
    const clone = {
      ...formValues,
      payload: { ...formValues.payload, fileIdProvider: value }
    };
    validateAndUpdate(clone);
  };

  const validateAndUpdate = values => {
    setFormValues(values);

    const clone = { ...values, isValid: values.isValid && isFormValid(values) };
    props.onUpdateContact(clone);
  };

  const isFormValid = values =>
    values.isValid && isFileIdValid(values.payload.fileIdProvider);

  const handleOnUpdateContactBase = values => {
    const clone = {
      ...formValues,
      ...values,
      payload: { ...formValues.payload, ...values.payload }
    };
    validateAndUpdate(clone);
  };

  return (
    <>
      <AffectedPerson
        person={props.person}
        isRemovable={props.isRemovable}
        onRemoveContact={props.onRemoveContact}
        onUpdateContact={handleOnUpdateContactBase}
      />
      <UploadDocument
        id={props.person.id}
        label={props.label}
        isMedical={props.isMedical}
        showHelp={props.showHelp}
        value={props.person.payload.fileIdProvider}
        claimId={props.claimId}
        benefitId={props.benefitId}
        onChange={handleFieldFormValueChange}
      />
    </>
  );
};

AffectedPersonWithDocument.propTypes = {
  person: PropTypes.shape({
    id: PropTypes.string,
    typeObjectId: PropTypes.number.isRequired,
    isValid: PropTypes.bool.isRequired,
    payload: PropTypes.shape({
      fileIdProvider: PropTypes.string // rename this to fileId
    })
  }).isRequired,
  isRemovable: PropTypes.bool,
  onUpdateContact: PropTypes.func.isRequired,
  onRemoveContact: PropTypes.func.isRequired,
  claimId: PropTypes.string.isRequired,
  benefitId: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  isMedical: PropTypes.bool.isRequired,
  showHelp: PropTypes.bool.isRequired
};
AffectedPersonWithDocument.defaultProps = {
  isRemovable: false
};

export default AffectedPersonWithDocument;
