import {
  endPointCallGet,
  endPointCallPost,
  endPointCallPut
} from './endPointCallerApi';
import * as apiRoutes from './apiRoutes';

class authenticationApi {
  static signIn(username, password) {
    const url = `${apiRoutes.SIGN_IN}`;
    return endPointCallPost(url, {
      username,
      password
    });
  }

  static signUp(regData) {
    const url = `${apiRoutes.SIGN_UP}`;
    return endPointCallPost(url, regData, null, {
      recaptcha: regData.captchaToken
    });
  }

  static userUpdateProfile(userInfo) {
    const url = `${apiRoutes.PUT_ACCOUNT}`;
    return endPointCallPut(url, userInfo);
  }

  static getCard(id) {
    const url = `${apiRoutes.GET_CARDS}/${id}`;
    return endPointCallGet(url);
  }

  static getPolicy(id) {
    const url = `${apiRoutes.GET_POLICIES}/${id}`;
    return endPointCallGet(url);
  }

  static recoverPassword(email, lang) {
    const url = `${apiRoutes.RECOVER_PASSWORD}/${email}/${lang}`;
    return endPointCallGet(url);
  }

  static resetPassword(password, token) {
    const url = `${apiRoutes.RESET_PASSWORD}/${token}`;
    const body = {
      stringRequest: password
    };
    return endPointCallPost(url, body);
  }

  static getUserInfo() {
    const url = `${apiRoutes.GET_ACCOUNT}`;
    return endPointCallGet(url);
  }

  static getMaamToken() {
    const url = `${apiRoutes.GET_ACCOUNT}/maamToken`;
    return endPointCallGet(url);
  }

  static getUserPolicies() {
    const url = `${apiRoutes.GET_POLICIES}`;
    return endPointCallGet(url);
  }

  static addUserPolicy(name, surname, number, dateOfBirth) {
    const url = `${apiRoutes.GET_POLICIES}`;
    const body = {
      name,
      surname,
      number,
      dateOfBirth
    };
    return endPointCallPost(url, body);
  }
}

export default authenticationApi;
