import {
  DOWNLOAD_FILE_REQUESTED,
  DOWNLOAD_FILE_SUCCEEDED,
  DOWNLOAD_FILE_FAILED,
  RESET_FILES_STATUS
} from 'actions/feature/file';

const fileReducer = (state = {}, action) => {
  switch (action.type) {
    case DOWNLOAD_FILE_REQUESTED:
      return {
        ...state,
        [action.payload.fileId]: {
          loading: true,
          downloaded: false
        }
      };
    case DOWNLOAD_FILE_SUCCEEDED:
      return {
        ...state,
        [action.meta.fileId]: {
          loading: false,
          downloaded: true
        }
      };
    case DOWNLOAD_FILE_FAILED:
      return {
        ...state,
        [action.meta.fileId]: {
          loading: false,
          downloaded: false
        }
      };
    case RESET_FILES_STATUS:
      return {
        ...state,
        [action.payload.fileId]: {
          loading: false,
          downloaded: false
        }
      };
    default:
      return state;
  }
};

export default fileReducer;
