export const maxLength = {
  firstName: 50,
  surnames: 50,
  phone: 100,
  address: 100,
  addressNumber: 50,
  addressPC: 20,
  addressCity: 100,
  email: 50
};

export const length = {
  cardNumber: 15,
  personalNumber: 11
};
