import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import List from '@material-ui/core/List';
import IconButton from '@material-ui/core/IconButton';
import moment from 'moment';
import { CLAIM_STATUS_CODES } from 'constants/constants';
import { withConfig } from 'ConfigurationProvider';
import { ReactComponent as InProgressIcon } from 'assets/img/inprogress.svg';
import { ReactComponent as DeclinedIcon } from 'assets/img/declined.svg';
import { ReactComponent as WithdrawnIcon } from 'assets/img/withdrawn.svg';
import { ReactComponent as SubmittedIcon } from 'assets/img/submitted.svg';
import { ReactComponent as PaidIcon } from 'assets/img/paid.svg';
import UnvailableIcon from '@material-ui/icons/ErrorOutline';
import { getStatusDescription, getStatusToolTip } from './ClaimListHelpers';
import ClaimCard from './ClaimCard';

const styles = {
  iconButton: { height: '30px' },
  iconButtonOrange: { height: '35px', width: '35px', color: 'orange' }
};
export const ClaimListComponent = props => {
  const { t } = useTranslation();
  const isClickable = claim => claim.status === CLAIM_STATUS_CODES.IN_PROGRESS;
  const isClickableToUpload = claim =>
    claim.status === CLAIM_STATUS_CODES.IN_PROGRESS ||
    claim.status === CLAIM_STATUS_CODES.SUBMITTED;

  const isLibertyExpiredClaim = status => status > CLAIM_STATUS_CODES.SETTLED;

  const listitems = props.list.map(item => {
    const clickable = isClickable(item);
    const onClick = clickable ? () => props.gotoClaim(item.id) : null;
    const onClickAddDocument = isClickableToUpload
      ? () => props.gotoUploadClaimDocuments(item)
      : () => {};

    const getClaimActionButton = status => {
      let result;

      switch (status) {
        case CLAIM_STATUS_CODES.IN_PROGRESS:
          result = (
            <IconButton>
              <InProgressIcon style={styles.iconButton} />
            </IconButton>
          );
          break;
        case CLAIM_STATUS_CODES.DECLINED:
          result = (
            <IconButton>
              <DeclinedIcon style={styles.iconButton} />
            </IconButton>
          );
          break;
        case CLAIM_STATUS_CODES.WITHDRAWN:
          result = (
            <IconButton>
              <WithdrawnIcon style={styles.iconButton} />
            </IconButton>
          );
          break;
        case CLAIM_STATUS_CODES.SUBMITTED:
        case CLAIM_STATUS_CODES.APPROVED:
          result = (
            <IconButton>
              <SubmittedIcon style={styles.iconButton} />
            </IconButton>
          );
          break;
        case CLAIM_STATUS_CODES.SETTLED:
          result = (
            <IconButton>
              <PaidIcon style={styles.iconButton} />
            </IconButton>
          );
          break;
        case CLAIM_STATUS_CODES.UNAVAILABLE:
          result = (
              <IconButton style={{ display: "None" }}>
              <UnvailableIcon />
            </IconButton>
          );
          break;
        default:
          result = (
            <IconButton style={styles.iconButton}>
              <i className="material-icons">navigate_next</i>
            </IconButton>
          );
          break;
      }
      return result;
    };

    const claimActionButton = getClaimActionButton(item.status);

    const isNotEditable =
      isLibertyExpiredClaim(item.status) &&
      item.status !== CLAIM_STATUS_CODES.UNAVAILABLE;

    moment.locale(props.lang);

    const decisionDate = item.decisiondue
      ? t('commonClaimDecisionDue') +
        moment(item.decisiondue).format(props.config.DATE_FORMAT)
      : t(getStatusDescription(item.status));

    const title = isNotEditable ? (
      <div style={{ maxWidth: 300, whiteSpace: 'normal', textAlign: 'left' }}>
        {`${t(getStatusDescription(item.status))}. ${t('libertyExpiredClaim')}`}
      </div>
    ) : (
      t(getStatusDescription(item.status))
    );

    return (
      <ClaimCard
        key={item.id}
        id={item.id}
        benefit={t(item.benefit)}
        showUploadButton={
          item.status === CLAIM_STATUS_CODES.IN_PROGRESS ||
          item.status === CLAIM_STATUS_CODES.SUBMITTED
        }
        uploadButtonText={t('commonAddNewDocument')}
        decisionDate={decisionDate}
        actionTitle={title}
        title={t(getStatusToolTip(item.status))}
        claimActionButton={claimActionButton}
        uploadButtonAction={onClickAddDocument}
        action={onClick}
      />
    );
  });
  return <List>{listitems}</List>;
};

ClaimListComponent.propTypes = {
  lang: PropTypes.string,
  gotoClaim: PropTypes.func.isRequired,
  gotoUploadClaimDocuments: PropTypes.func,
  config: PropTypes.shape({
    DATE_FORMAT: PropTypes.string.isRequired
  }).isRequired,
  list: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired
};

ClaimListComponent.defaultProps = {
  lang: '',
  gotoUploadClaimDocuments: () => {}
};

export default withConfig(ClaimListComponent);
