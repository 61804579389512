import { endPointCallDelete } from './endPointCallerApi';
import * as apiRoutes from './apiRoutes';

class PolicyApi {
  static deletePolicyApi(cardId) {
    const url = `${apiRoutes.GET_POLICIES}/${cardId}`;
    return endPointCallDelete(url);
  }
}

export default PolicyApi;
