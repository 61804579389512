import { MEDICAL_FACILITIES } from 'constants/constants';
import medicalFacilitiesApi from 'api/medicalFacilitiesApi';
import * as types from 'actions/actionTypes';
import { handleApiError } from 'actions/alertsActions';

function getCategories() {
  return dispatch => {
    const categoryList = MEDICAL_FACILITIES.CATEGORIES.map(category => ({
      label: category.label,
      hasSpecialities: category.hasSpecialities
    }));

    dispatch({
      type: types.MEDICAL_PROVIDER_GET_CATEGORIES,
      categoryList
    });
  };
}

function getSpecialities() {
  return dispatch => {
    const specialityList = MEDICAL_FACILITIES.SPECIALITIES.map(speciality => ({
      label: speciality
    }));

    dispatch({
      type: types.MEDICAL_PROVIDER_GET_SPECIALITIES,
      specialityList
    });
  };
}

export function newSearch() {
  return dispatch => {
    dispatch({
      type: types.MEDICAL_NEW_SEARCH
    });
    dispatch(getCategories());
    dispatch(getSpecialities());
  };
}

function medicalProviderRequest(location, category, speciality) {
  return {
    type: types.MEDICAL_PROVIDER_FACILITIES_REQUEST,
    query: { location, category, speciality }
  };
}

export function getMedicalProviderFacilities(location, category, speciality) {
  return async dispatch => {
    try {
      dispatch(medicalProviderRequest(location, category, speciality));
      dispatch(startLoadingMap());
      const query = {
        categorySpecialty: [category, speciality],
        latitude: location.latitude,
        longitude: location.longitude
      };
      const facilities = await medicalFacilitiesApi.getMedicalFacilities(query);
      dispatch(medicalProviderFacilitiesSuccess(facilities));
      dispatch(stopLoadingMap());
    } catch (error) {
      dispatch(medicalProviderFacilitiesFailure());
      dispatch(handleApiError(error));
      dispatch(stopLoadingMap());
    }
  };
}

export function medicalProviderFacilitiesSuccess(resultData) {
  return { type: types.MEDICAL_PROVIDER_FACILITIES_SUCCESS, resultData };
}

export function medicalProviderFacilitiesFailure() {
  return { type: types.MEDICAL_PROVIDER_FACILITIES_FAILURE };
}

export function startLoadingMap() {
  return { type: types.MEDICAL_PROVIDER_FACILITIES_START_LOAD_MAP };
}

export function stopLoadingMap() {
  return { type: types.MEDICAL_PROVIDER_FACILITIES_STOP_LOAD_MAP };
}
