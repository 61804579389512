import React from 'react';
import PropTypes from 'prop-types';
import { REPORT } from 'constants/constants';
import { CLAIMS } from 'api/apiRoutes';

const ThankYouSummary = props => (
  <div className="text-center">
    <div className="main-card">
      <i className="material-icons check">check_circle_outline</i>
      <h3>{props.labels.thanksSummaryTitle}</h3>
      <p className="mb-3">
        {`${props.labels.commonClaimNumber} `}
        <strong>{props.claimId}</strong>
      </p>
      <p className="mb-4">
        {props.labels.thanksWillReceiveEmail}
        <span className="d-block font-weight-bold">{props.userEmail}</span>
      </p>
      <a
        href={`${CLAIMS}/${props.claimId}${REPORT}${props.userEmail}/${props.language}`}
        target="blank"
      >
        {props.labels.thanksDownloadClaimPDF}
      </a>
    </div>
  </div>
);

ThankYouSummary.propTypes = {
  labels: PropTypes.shape({
    thanksClaimAssessed: PropTypes.string.isRequired,
    thanksProcessClaim: PropTypes.string.isRequired,
    commonClaimNumber: PropTypes.string.isRequired,
    thanksDownloadClaimPDF: PropTypes.string.isRequired,
    thanksWillReceiveEmail: PropTypes.string.isRequired,
    thanksSummaryTitle: PropTypes.string.isRequired,
    thanksNextSteps: PropTypes.string.isRequired
  }).isRequired,
  claimId: PropTypes.string.isRequired,
  userEmail: PropTypes.string.isRequired,
  language: PropTypes.number.isRequired
};

export default ThankYouSummary;
