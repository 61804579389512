const howToClaimLabels = {
  CANCELLATION: {
    title: 'HowToClaim_CAN_Title',
    description: 'HowToClaim_CAN_Description',
    footer: 'HowToClaim_CAN_Footer',
    continueButton: 'HowToClaim_CAN_ContinueButon',
    buttonTitle: 'HowToClaim_CAN_LearnMore_Button',
    weAsk: {
      title: 'HowToClaim_CAN_WeAsk_Title',
      list: [
        'HowToClaim_CAN_WeAsk_ListItem1',
        'HowToClaim_CAN_WeAsk_ListItem2',
        'HowToClaim_CAN_WeAsk_ListItem3',
        'HowToClaim_CAN_WeAsk_ListItem4'
      ]
    },
    learnMore: {
      title: 'HowToClaim_CAN_LearnMoreTitle',
      cardContainer: {
        cards: [
          {
            title: 'HowToClaim_CAN_LearnMore_Card1_Title',
            items: [
              'HowToClaim_CAN_LearnMore_Card1_Item1',
              'HowToClaim_CAN_LearnMore_Card1_Item2',
              'HowToClaim_CAN_LearnMore_Card1_Item3',
              'HowToClaim_CAN_LearnMore_Card1_Item4'
            ]
          },
          {
            title: 'HowToClaim_CAN_LearnMore_Card2_Title',
            items: [
              'HowToClaim_CAN_LearnMore_Card2_Item1',
              'HowToClaim_CAN_LearnMore_Card2_Item2',
              'HowToClaim_CAN_LearnMore_Card2_Item3',
              'HowToClaim_CAN_LearnMore_Card2_Item4'
            ]
          },
          {
            title: 'HowToClaim_CAN_LearnMore_Card3_Title',
            items: [
              'HowToClaim_CAN_LearnMore_Card3_Item1',
              'HowToClaim_CAN_LearnMore_Card3_Item2'
            ],
            buttonMedicalReport: {
              title: 'HowToClaim_CAN_LearnMore_Card3_ButtonMedicalReport'
            },
            buttonGDPR: {
              title: 'HowToClaim_CAN_LearnMore_Card3_ButtonGDPR'
            }
          }
        ]
      },
      modalButton: {
        title: 'HowToClaim_CAN_LearnMore_Modal_Button'
      },
      modalFooter: 'HowToClaim_CAN_LearnMore_Modal_Footer'
    },
    youProvide: {
      title: 'HowToClaim_CAN_YouProvide_Title',
      list: [
        {
          title: 'HowToClaim_CAN_YouProvide_ListItem1_Title',
          subtitle: 'HowToClaim_CAN_YouProvide_ListItem1_Subtitle'
        },
        {
          title: 'HowToClaim_CAN_YouProvide_ListItem2_Title',
          subtitle: 'HowToClaim_CAN_YouProvide_ListItem2_Subtitle'
        },
        {
          title: 'HowToClaim_CAN_YouProvide_ListItem3_Title',
          subtitle: 'HowToClaim_CAN_YouProvide_ListItem3_Subtitle'
        }
      ]
    }
  },
  'TRAVEL DELAY': {
    title: 'HowToClaim_TD_Title',
    description: 'HowToClaim_TD_Description',
    footer: 'HowToClaim_TD_Footer',
    continueButton: 'HowToClaim_TD_ContinueButon',
    buttonTitle: 'HowToClaim_TD_LearnMore_Button',
    weAsk: {
      title: 'HowToClaim_TD_WeAsk_Title',
      list: [
        'HowToClaim_TD_WeAsk_ListItem1',
        'HowToClaim_TD_WeAsk_ListItem2',
        'HowToClaim_TD_WeAsk_ListItem3',
        'HowToClaim_TD_WeAsk_ListItem4'
      ]
    },
    learnMore: {
      title: 'HowToClaim_TD_LearnMoreTitle',
      cardContainer: {
        cards: [
          {
            title: 'HowToClaim_TD_LearnMore_Card1_Title',
            items: [
              'HowToClaim_TD_LearnMore_Card1_Item1',
              'HowToClaim_TD_LearnMore_Card1_Item2',
              'HowToClaim_TD_LearnMore_Card1_Item3',
              'HowToClaim_TD_LearnMore_Card1_Item4'
            ]
          },
          {
            title: 'HowToClaim_TD_LearnMore_Card2_Title',
            items: [
              'HowToClaim_TD_LearnMore_Card2_Item1',
              'HowToClaim_TD_LearnMore_Card2_Item2',
              'HowToClaim_TD_LearnMore_Card2_Item3',
              'HowToClaim_TD_LearnMore_Card2_Item4'
            ]
          },
          {
            title: 'HowToClaim_TD_LearnMore_Card3_Title',
            items: [
              'HowToClaim_TD_LearnMore_Card3_Item1',
              'HowToClaim_TD_LearnMore_Card3_Item2'
            ],
            buttonMedicalReport: {
              title: 'HowToClaim_TD_LearnMore_Card3_ButtonMedicalReport'
            },
            buttonGDPR: {
              title: 'HowToClaim_TD_LearnMore_Card3_ButtonGDPR'
            }
          }
        ]
      },
      modalButton: {
        title: 'HowToClaim_TD_LearnMore_Modal_Button'
      },
      modalFooter: 'HowToClaim_TD_LearnMore_Modal_Footer'
    },
    youProvide: {
      title: 'HowToClaim_TD_YouProvide_Title',
      list: [
        {
          title: 'HowToClaim_TD_YouProvide_ListItem1_Title',
          subtitle: 'HowToClaim_TD_YouProvide_ListItem1_Subtitle'
        },
        {
          title: 'HowToClaim_TD_YouProvide_ListItem2_Title',
          subtitle: 'HowToClaim_TD_YouProvide_ListItem2_Subtitle'
        },
        {
          title: 'HowToClaim_TD_YouProvide_ListItem3_Title',
          subtitle: 'HowToClaim_TD_YouProvide_ListItem3_Subtitle'
        }
      ]
    }
  },
  'MISSED DEPARTURE': {
    title: 'HowToClaim_MD_Title',
    description: 'HowToClaim_MD_Description',
    footer: 'HowToClaim_MD_Footer',
    continueButton: 'HowToClaim_MD_ContinueButon',
    buttonTitle: 'HowToClaim_MD_LearnMore_Button',
    weAsk: {
      title: 'HowToClaim_MD_WeAsk_Title',
      list: [
        'HowToClaim_MD_WeAsk_ListItem1',
        'HowToClaim_MD_WeAsk_ListItem2',
        'HowToClaim_MD_WeAsk_ListItem3',
        'HowToClaim_MD_WeAsk_ListItem4'
      ]
    },
    learnMore: {
      title: 'HowToClaim_MD_LearnMoreTitle',
      cardContainer: {
        cards: [
          {
            title: 'HowToClaim_MD_LearnMore_Card1_Title',
            items: [
              'HowToClaim_MD_LearnMore_Card1_Item1',
              'HowToClaim_MD_LearnMore_Card1_Item2',
              'HowToClaim_MD_LearnMore_Card1_Item3',
              'HowToClaim_MD_LearnMore_Card1_Item4'
            ]
          },
          {
            title: 'HowToClaim_MD_LearnMore_Card2_Title',
            items: [
              'HowToClaim_MD_LearnMore_Card2_Item1',
              'HowToClaim_MD_LearnMore_Card2_Item2',
              'HowToClaim_MD_LearnMore_Card2_Item3',
              'HowToClaim_MD_LearnMore_Card2_Item4'
            ]
          },
          {
            title: 'HowToClaim_MD_LearnMore_Card3_Title',
            items: [
              'HowToClaim_MD_LearnMore_Card3_Item1',
              'HowToClaim_MD_LearnMore_Card3_Item2'
            ],
            buttonMedicalReport: {
              title: 'HowToClaim_MD_LearnMore_Card3_ButtonMedicalReport'
            },
            buttonGDPR: {
              title: 'HowToClaim_MD_LearnMore_Card3_ButtonGDPR'
            }
          }
        ]
      },
      modalButton: {
        title: 'HowToClaim_MD_LearnMore_Modal_Button'
      },
      modalFooter: 'HowToClaim_MD_LearnMore_Modal_Footer'
    },
    youProvide: {
      title: 'HowToClaim_MD_YouProvide_Title',
      list: [
        {
          title: 'HowToClaim_MD_YouProvide_ListItem1_Title',
          subtitle: 'HowToClaim_MD_YouProvide_ListItem1_Subtitle'
        },
        {
          title: 'HowToClaim_MD_YouProvide_ListItem2_Title',
          subtitle: 'HowToClaim_MD_YouProvide_ListItem2_Subtitle'
        },
        {
          title: 'HowToClaim_MD_YouProvide_ListItem3_Title',
          subtitle: 'HowToClaim_MD_YouProvide_ListItem3_Subtitle'
        }
      ]
    }
  },
  'MISSED CONNECTION': {
    title: 'HowToClaim_MC_Title',
    description: 'HowToClaim_MC_Description',
    footer: 'HowToClaim_MC_Footer',
    continueButton: 'HowToClaim_MC_ContinueButon',
    buttonTitle: 'HowToClaim_MC_LearnMore_Button',
    weAsk: {
      title: 'HowToClaim_MC_WeAsk_Title',
      list: [
        'HowToClaim_MC_WeAsk_ListItem1',
        'HowToClaim_MC_WeAsk_ListItem2',
        'HowToClaim_MC_WeAsk_ListItem3',
        'HowToClaim_MC_WeAsk_ListItem4'
      ]
    },
    learnMore: {
      title: 'HowToClaim_MC_LearnMoreTitle',
      cardContainer: {
        cards: [
          {
            title: 'HowToClaim_MC_LearnMore_Card1_Title',
            items: [
              'HowToClaim_MC_LearnMore_Card1_Item1',
              'HowToClaim_MC_LearnMore_Card1_Item2',
              'HowToClaim_MC_LearnMore_Card1_Item3',
              'HowToClaim_MC_LearnMore_Card1_Item4'
            ]
          },
          {
            title: 'HowToClaim_MC_LearnMore_Card2_Title',
            items: [
              'HowToClaim_MC_LearnMore_Card2_Item1',
              'HowToClaim_MC_LearnMore_Card2_Item2',
              'HowToClaim_MC_LearnMore_Card2_Item3',
              'HowToClaim_MC_LearnMore_Card2_Item4'
            ]
          },
          {
            title: 'HowToClaim_MC_LearnMore_Card3_Title',
            items: [
              'HowToClaim_MC_LearnMore_Card3_Item1',
              'HowToClaim_MC_LearnMore_Card3_Item2'
            ],
            buttonMedicalReport: {
              title: 'HowToClaim_MC_LearnMore_Card3_ButtonMedicalReport'
            },
            buttonGDPR: {
              title: 'HowToClaim_MC_LearnMore_Card3_ButtonGDPR'
            }
          }
        ]
      },
      modalButton: {
        title: 'HowToClaim_MC_LearnMore_Modal_Button'
      },
      modalFooter: 'HowToClaim_MC_LearnMore_Modal_Footer'
    },
    youProvide: {
      title: 'HowToClaim_MC_YouProvide_Title',
      list: [
        {
          title: 'HowToClaim_MC_YouProvide_ListItem1_Title',
          subtitle: 'HowToClaim_MC_YouProvide_ListItem1_Subtitle'
        },
        {
          title: 'HowToClaim_MC_YouProvide_ListItem2_Title',
          subtitle: 'HowToClaim_MC_YouProvide_ListItem2_Subtitle'
        },
        {
          title: 'HowToClaim_MC_YouProvide_ListItem3_Title',
          subtitle: 'HowToClaim_MC_YouProvide_ListItem3_Subtitle'
        }
      ]
    }
  },
  CURTAILMENT: {
    title: 'HowToClaim_CUR_Title',
    description: 'HowToClaim_CUR_Description',
    footer: 'HowToClaim_CUR_Footer',
    continueButton: 'HowToClaim_CUR_ContinueButon',
    buttonTitle: 'HowToClaim_CUR_LearnMore_Button',
    weAsk: {
      title: 'HowToClaim_CUR_WeAsk_Title',
      list: [
        'HowToClaim_CUR_WeAsk_ListItem1',
        'HowToClaim_CUR_WeAsk_ListItem2',
        'HowToClaim_CUR_WeAsk_ListItem3',
        'HowToClaim_CUR_WeAsk_ListItem4'
      ]
    },
    learnMore: {
      title: 'HowToClaim_CUR_LearnMoreTitle',
      cardContainer: {
        cards: [
          {
            title: 'HowToClaim_CUR_LearnMore_Card1_Title',
            items: [
              'HowToClaim_CUR_LearnMore_Card1_Item1',
              'HowToClaim_CUR_LearnMore_Card1_Item2',
              'HowToClaim_CUR_LearnMore_Card1_Item3',
              'HowToClaim_CUR_LearnMore_Card1_Item4'
            ]
          },
          {
            title: 'HowToClaim_CUR_LearnMore_Card2_Title',
            items: [
              'HowToClaim_CUR_LearnMore_Card2_Item1',
              'HowToClaim_CUR_LearnMore_Card2_Item2',
              'HowToClaim_CUR_LearnMore_Card2_Item3',
              'HowToClaim_CUR_LearnMore_Card2_Item4'
            ]
          },
          {
            title: 'HowToClaim_CUR_LearnMore_Card3_Title',
            items: [
              'HowToClaim_CUR_LearnMore_Card3_Item1',
              'HowToClaim_CUR_LearnMore_Card3_Item2'
            ],
            buttonMedicalReport: {
              title: 'HowToClaim_CUR_LearnMore_Card3_ButtonMedicalReport'
            },
            buttonGDPR: {
              title: 'HowToClaim_CUR_LearnMore_Card3_ButtonGDPR'
            }
          }
        ]
      },
      modalButton: {
        title: 'HowToClaim_CUR_LearnMore_Modal_Button'
      },
      modalFooter: 'HowToClaim_CUR_LearnMore_Modal_Footer'
    },
    youProvide: {
      title: 'HowToClaim_CUR_YouProvide_Title',
      list: [
        {
          title: 'HowToClaim_CUR_YouProvide_ListItem1_Title',
          subtitle: 'HowToClaim_CUR_YouProvide_ListItem1_Subtitle'
        },
        {
          title: 'HowToClaim_CUR_YouProvide_ListItem2_Title',
          subtitle: 'HowToClaim_CUR_YouProvide_ListItem2_Subtitle'
        },
        {
          title: 'HowToClaim_CUR_YouProvide_ListItem3_Title',
          subtitle: 'HowToClaim_CUR_YouProvide_ListItem3_Subtitle'
        },
        {
          title: 'HowToClaim_CUR_YouProvide_ListItem4_Title',
          subtitle: 'HowToClaim_CUR_YouProvide_ListItem4_Subtitle'
        }
      ]
    }
  },
  'BAGGAGE DELAY': {
    title: 'HowToClaim_BD_Title',
    description: 'HowToClaim_BD_Description',
    footer: 'HowToClaim_BD_Footer',
    continueButton: 'HowToClaim_BD_ContinueButon',
    buttonTitle: 'HowToClaim_BD_LearnMore_Button',
    weAsk: {
      title: 'HowToClaim_BD_WeAsk_Title',
      list: [
        'HowToClaim_BD_WeAsk_ListItem1',
        'HowToClaim_BD_WeAsk_ListItem2',
        'HowToClaim_BD_WeAsk_ListItem3',
        'HowToClaim_BD_WeAsk_ListItem4'
      ]
    },
    learnMore: {
      title: 'HowToClaim_BD_LearnMoreTitle',
      cardContainer: {
        cards: [
          {
            title: 'HowToClaim_BD_LearnMore_Card1_Title',
            items: [
              'HowToClaim_BD_LearnMore_Card1_Item1',
              'HowToClaim_BD_LearnMore_Card1_Item2',
              'HowToClaim_BD_LearnMore_Card1_Item3',
              'HowToClaim_BD_LearnMore_Card1_Item4'
            ]
          },
          {
            title: 'HowToClaim_BD_LearnMore_Card2_Title',
            items: [
              'HowToClaim_BD_LearnMore_Card2_Item1',
              'HowToClaim_BD_LearnMore_Card2_Item2',
              'HowToClaim_BD_LearnMore_Card2_Item3',
              'HowToClaim_BD_LearnMore_Card2_Item4'
            ]
          },
          {
            title: 'HowToClaim_BD_LearnMore_Card3_Title',
            items: [
              'HowToClaim_BD_LearnMore_Card3_Item1',
              'HowToClaim_BD_LearnMore_Card3_Item2'
            ],
            buttonMedicalReport: {
              title: 'HowToClaim_BD_LearnMore_Card3_ButtonMedicalReport'
            },
            buttonGDPR: {
              title: 'HowToClaim_BD_LearnMore_Card3_ButtonGDPR'
            }
          }
        ]
      },
      modalButton: {
        title: 'HowToClaim_BD_LearnMore_Modal_Button'
      },
      modalFooter: 'HowToClaim_BD_LearnMore_Modal_Footer'
    },
    youProvide: {
      title: 'HowToClaim_BD_YouProvide_Title',
      list: [
        {
          title: 'HowToClaim_BD_YouProvide_ListItem1_Title',
          subtitle: 'HowToClaim_BD_YouProvide_ListItem1_Subtitle'
        },
        {
          title: 'HowToClaim_BD_YouProvide_ListItem2_Title',
          subtitle: 'HowToClaim_BD_YouProvide_ListItem2_Subtitle'
        },
        {
          title: 'HowToClaim_BD_YouProvide_ListItem3_Title',
          subtitle: 'HowToClaim_BD_YouProvide_ListItem3_Subtitle'
        },
        {
          title: 'HowToClaim_BD_YouProvide_ListItem4_Title',
          subtitle: 'HowToClaim_BD_YouProvide_ListItem4_Subtitle'
        },
        {
          title: 'HowToClaim_BD_YouProvide_ListItem5_Title',
          subtitle: 'HowToClaim_BD_YouProvide_ListItem5_Subtitle'
        }
      ]
    }
  },
  'BAGGAGE DELAY EXTENDED': {
    title: 'HowToClaim_BD_Title',
    description: 'HowToClaim_BD_Description',
    footer: 'HowToClaim_BD_Footer',
    continueButton: 'HowToClaim_BD_ContinueButon',
    buttonTitle: 'HowToClaim_BD_LearnMore_Button',
    weAsk: {
      title: 'HowToClaim_BD_WeAsk_Title',
      list: [
        'HowToClaim_BD_WeAsk_ListItem1',
        'HowToClaim_BD_WeAsk_ListItem2',
        'HowToClaim_BD_WeAsk_ListItem3',
        'HowToClaim_BD_WeAsk_ListItem4'
      ]
    },
    learnMore: {
      title: 'HowToClaim_BD_LearnMoreTitle',
      cardContainer: {
        cards: [
          {
            title: 'HowToClaim_BD_LearnMore_Card1_Title',
            items: [
              'HowToClaim_BD_LearnMore_Card1_Item1',
              'HowToClaim_BD_LearnMore_Card1_Item2',
              'HowToClaim_BD_LearnMore_Card1_Item3',
              'HowToClaim_BD_LearnMore_Card1_Item4'
            ]
          },
          {
            title: 'HowToClaim_BD_LearnMore_Card2_Title',
            items: [
              'HowToClaim_BD_LearnMore_Card2_Item1',
              'HowToClaim_BD_LearnMore_Card2_Item2',
              'HowToClaim_BD_LearnMore_Card2_Item3',
              'HowToClaim_BD_LearnMore_Card2_Item4'
            ]
          },
          {
            title: 'HowToClaim_BD_LearnMore_Card3_Title',
            items: [
              'HowToClaim_BD_LearnMore_Card3_Item1',
              'HowToClaim_BD_LearnMore_Card3_Item2'
            ],
            buttonMedicalReport: {
              title: 'HowToClaim_BD_LearnMore_Card3_ButtonMedicalReport'
            },
            buttonGDPR: {
              title: 'HowToClaim_BD_LearnMore_Card3_ButtonGDPR'
            }
          }
        ]
      },
      modalButton: {
        title: 'HowToClaim_BD_LearnMore_Modal_Button'
      },
      modalFooter: 'HowToClaim_BD_LearnMore_Modal_Footer'
    },
    youProvide: {
      title: 'HowToClaim_BD_YouProvide_Title',
      list: [
        {
          title: 'HowToClaim_BD_YouProvide_ListItem1_Title',
          subtitle: 'HowToClaim_BD_YouProvide_ListItem1_Subtitle'
        },
        {
          title: 'HowToClaim_BD_YouProvide_ListItem2_Title',
          subtitle: 'HowToClaim_BD_YouProvide_ListItem2_Subtitle'
        },
        {
          title: 'HowToClaim_BD_YouProvide_ListItem3_Title',
          subtitle: 'HowToClaim_BD_YouProvide_ListItem3_Subtitle'
        },
        {
          title: 'HowToClaim_BD_YouProvide_ListItem4_Title',
          subtitle: 'HowToClaim_BD_YouProvide_ListItem4_Subtitle'
        },
        {
          title: 'HowToClaim_BD_YouProvide_ListItem5_Title',
          subtitle: 'HowToClaim_BD_YouProvide_ListItem5_Subtitle'
        }
      ]
    }
  },
  'MEDICAL EXPENSES': {
    title: 'HowToClaim_ME_Title',
    description: 'HowToClaim_ME_Description',
    footer: 'HowToClaim_ME_Footer',
    continueButton: 'HowToClaim_ME_ContinueButon',
    buttonTitle: 'HowToClaim_ME_LearnMore_Button',
    weAsk: {
      title: 'HowToClaim_ME_WeAsk_Title',
      list: [
        'HowToClaim_ME_WeAsk_ListItem1',
        'HowToClaim_ME_WeAsk_ListItem2',
        'HowToClaim_ME_WeAsk_ListItem3',
        'HowToClaim_ME_WeAsk_ListItem4'
      ]
    },
    learnMore: {
      title: 'HowToClaim_ME_LearnMoreTitle',
      cardContainer: {
        cards: [
          {
            title: 'HowToClaim_ME_LearnMore_Card1_Title',
            items: [
              'HowToClaim_ME_LearnMore_Card1_Item1',
              'HowToClaim_ME_LearnMore_Card1_Item2',
              'HowToClaim_ME_LearnMore_Card1_Item3',
              'HowToClaim_ME_LearnMore_Card1_Item4'
            ]
          },
          {
            title: 'HowToClaim_ME_LearnMore_Card2_Title',
            items: [
              'HowToClaim_ME_LearnMore_Card2_Item1',
              'HowToClaim_ME_LearnMore_Card2_Item2',
              'HowToClaim_ME_LearnMore_Card2_Item3',
              'HowToClaim_ME_LearnMore_Card2_Item4'
            ]
          },
          {
            title: 'HowToClaim_ME_LearnMore_Card3_Title',
            items: [
              'HowToClaim_ME_LearnMore_Card3_Item1',
              'HowToClaim_ME_LearnMore_Card3_Item2'
            ],
            buttonMedicalReport: {
              title: 'HowToClaim_ME_LearnMore_Card3_ButtonMedicalReport'
            },
            buttonGDPR: {
              title: 'HowToClaim_ME_LearnMore_Card3_ButtonGDPR'
            }
          }
        ]
      },
      modalButton: {
        title: 'HowToClaim_ME_LearnMore_Modal_Button'
      },
      modalFooter: 'HowToClaim_ME_LearnMore_Modal_Footer'
    },
    youProvide: {
      title: 'HowToClaim_ME_YouProvide_Title',
      list: [
        {
          title: 'HowToClaim_ME_YouProvide_ListItem1_Title',
          subtitle: 'HowToClaim_ME_YouProvide_ListItem1_Subtitle'
        },
        {
          title: 'HowToClaim_ME_YouProvide_ListItem2_Title',
          subtitle: 'HowToClaim_ME_YouProvide_ListItem2_Subtitle'
        },
        {
          title: 'HowToClaim_ME_YouProvide_ListItem3_Title',
          subtitle: 'HowToClaim_ME_YouProvide_ListItem3_Subtitle'
        },
        {
          title: 'HowToClaim_ME_YouProvide_ListItem4_Title',
          subtitle: 'HowToClaim_ME_YouProvide_ListItem4_Subtitle'
        },
        {
          title: 'HowToClaim_ME_YouProvide_ListItem5_Title',
          subtitle: 'HowToClaim_ME_YouProvide_ListItem5_Subtitle'
        }
      ]
    }
  },
  'BAGGAGE-MONEY & TRAVEL DOCS': {
    title: 'HowToClaim_BMTD_Title',
    description: 'HowToClaim_BMTD_Description',
    footer: 'HowToClaim_BMTD_Footer',
    continueButton: 'HowToClaim_BMTD_ContinueButon',
    buttonTitle: 'HowToClaim_BMTD_LearnMore_Button',
    weAsk: {
      title: 'HowToClaim_BMTD_WeAsk_Title',
      list: [
        'HowToClaim_BMTD_WeAsk_ListItem1',
        'HowToClaim_BMTD_WeAsk_ListItem2',
        'HowToClaim_BMTD_WeAsk_ListItem3',
        'HowToClaim_BMTD_WeAsk_ListItem4'
      ]
    },
    learnMore: {
      title: 'HowToClaim_BMTD_LearnMoreTitle',
      cardContainer: {
        cards: [
          {
            title: 'HowToClaim_BMTD_LearnMore_Card1_Title',
            items: [
              'HowToClaim_BMTD_LearnMore_Card1_Item1',
              'HowToClaim_BMTD_LearnMore_Card1_Item2',
              'HowToClaim_BMTD_LearnMore_Card1_Item3',
              'HowToClaim_BMTD_LearnMore_Card1_Item4'
            ]
          },
          {
            title: 'HowToClaim_BMTD_LearnMore_Card2_Title',
            items: [
              'HowToClaim_BMTD_LearnMore_Card2_Item1',
              'HowToClaim_BMTD_LearnMore_Card2_Item2',
              'HowToClaim_BMTD_LearnMore_Card2_Item3',
              'HowToClaim_BMTD_LearnMore_Card2_Item4'
            ]
          },
          {
            title: 'HowToClaim_BMTD_LearnMore_Card3_Title',
            items: [
              'HowToClaim_BMTD_LearnMore_Card3_Item1',
              'HowToClaim_BMTD_LearnMore_Card3_Item2'
            ],
            buttonMedicalReport: {
              title: 'HowToClaim_BMTD_LearnMore_Card3_ButtonMedicalReport'
            },
            buttonGDPR: {
              title: 'HowToClaim_BMTD_LearnMore_Card3_ButtonGDPR'
            }
          }
        ]
      },
      modalButton: {
        title: 'HowToClaim_BMTD_LearnMore_Modal_Button'
      },
      modalFooter: 'HowToClaim_BMTD_LearnMore_Modal_Footer'
    },
    youProvide: {
      title: 'HowToClaim_BMTD_YouProvide_Title',
      list: [
        {
          title: 'HowToClaim_BMTD_YouProvide_ListItem1_Title',
          subtitle: 'HowToClaim_BMTD_YouProvide_ListItem1_Subtitle'
        },
        {
          title: 'HowToClaim_BMTD_YouProvide_ListItem2_Title',
          subtitle: 'HowToClaim_BMTD_YouProvide_ListItem2_Subtitle'
        },
        {
          title: 'HowToClaim_BMTD_YouProvide_ListItem3_Title',
          subtitle: 'HowToClaim_BMTD_YouProvide_ListItem3_Subtitle'
        },
        {
          title: 'HowToClaim_BMTD_YouProvide_ListItem4_Title',
          subtitle: 'HowToClaim_BMTD_YouProvide_ListItem4_Subtitle'
        }
      ]
    }
  },
  CDW: {
    title: 'HowToClaim_CDW_Title',
    description: 'HowToClaim_CDW_Description',
    footer: 'HowToClaim_CDW_Footer',
    continueButton: 'HowToClaim_CDW_ContinueButon',
    buttonTitle: 'HowToClaim_CDW_LearnMore_Button',
    weAsk: {
      title: 'HowToClaim_CDW_WeAsk_Title',
      list: [
        'HowToClaim_CDW_WeAsk_ListItem1',
        'HowToClaim_CDW_WeAsk_ListItem2',
        'HowToClaim_CDW_WeAsk_ListItem3',
        'HowToClaim_CDW_WeAsk_ListItem4'
      ]
    },
    learnMore: {
      title: 'HowToClaim_CDW_LearnMoreTitle',
      cardContainer: {
        cards: [
          {
            title: 'HowToClaim_CDW_LearnMore_Card1_Title',
            items: [
              'HowToClaim_CDW_LearnMore_Card1_Item1',
              'HowToClaim_CDW_LearnMore_Card1_Item2',
              'HowToClaim_CDW_LearnMore_Card1_Item3',
              'HowToClaim_CDW_LearnMore_Card1_Item4'
            ]
          },
          {
            title: 'HowToClaim_CDW_LearnMore_Card2_Title',
            items: [
              'HowToClaim_CDW_LearnMore_Card2_Item1',
              'HowToClaim_CDW_LearnMore_Card2_Item2',
              'HowToClaim_CDW_LearnMore_Card2_Item3',
              'HowToClaim_CDW_LearnMore_Card2_Item4'
            ]
          },
          {
            title: 'HowToClaim_CDW_LearnMore_Card3_Title',
            items: [
              'HowToClaim_CDW_LearnMore_Card3_Item1',
              'HowToClaim_CDW_LearnMore_Card3_Item2'
            ],
            buttonMedicalReport: {
              title: 'HowToClaim_CDW_LearnMore_Card3_ButtonMedicalReport'
            },
            buttonGDPR: {
              title: 'HowToClaim_CDW_LearnMore_Card3_ButtonGDPR'
            }
          }
        ]
      },
      modalButton: {
        title: 'HowToClaim_CDW_LearnMore_Modal_Button'
      },
      modalFooter: 'HowToClaim_CDW_LearnMore_Modal_Footer'
    },
    youProvide: {
      title: 'HowToClaim_CDW_YouProvide_Title',
      list: [
        {
          title: 'HowToClaim_CDW_YouProvide_ListItem1_Title',
          subtitle: 'HowToClaim_CDW_YouProvide_ListItem1_Subtitle'
        },
        {
          title: 'HowToClaim_CDW_YouProvide_ListItem2_Title',
          subtitle: 'HowToClaim_CDW_YouProvide_ListItem2_Subtitle'
        },
        {
          title: 'HowToClaim_CDW_YouProvide_ListItem3_Title',
          subtitle: 'HowToClaim_CDW_YouProvide_ListItem3_Subtitle'
        },
        {
          title: 'HowToClaim_CDW_YouProvide_ListItem4_Title',
          subtitle: 'HowToClaim_CDW_YouProvide_ListItem4_Subtitle'
        },
        {
          title: 'HowToClaim_CDW_YouProvide_ListItem5_Title',
          subtitle: 'HowToClaim_CDW_YouProvide_ListItem5_Subtitle'
        }
      ]
    }
  },
  'TRAVEL ACCIDENT': {
    title: 'HowToClaim_TA_Title',
    description: 'HowToClaim_TA_Description',
    footer: 'HowToClaim_TA_Footer',
    continueButton: 'HowToClaim_TA_ContinueButon',
    buttonTitle: 'HowToClaim_TA_LearnMore_Button',
    weAsk: {
      title: 'HowToClaim_TA_WeAsk_Title',
      list: [
        'HowToClaim_TA_WeAsk_ListItem1',
        'HowToClaim_TA_WeAsk_ListItem2',
        'HowToClaim_TA_WeAsk_ListItem3',
        'HowToClaim_TA_WeAsk_ListItem4'
      ]
    },
    learnMore: {
      title: 'HowToClaim_TA_LearnMoreTitle',
      cardContainer: {
        cards: [
          {
            title: 'HowToClaim_TA_LearnMore_Card1_Title',
            items: [
              'HowToClaim_TA_LearnMore_Card1_Item1',
              'HowToClaim_TA_LearnMore_Card1_Item2',
              'HowToClaim_TA_LearnMore_Card1_Item3',
              'HowToClaim_TA_LearnMore_Card1_Item4'
            ]
          },
          {
            title: 'HowToClaim_TA_LearnMore_Card2_Title',
            items: [
              'HowToClaim_TA_LearnMore_Card2_Item1',
              'HowToClaim_TA_LearnMore_Card2_Item2',
              'HowToClaim_TA_LearnMore_Card2_Item3',
              'HowToClaim_TA_LearnMore_Card2_Item4'
            ]
          },
          {
            title: 'HowToClaim_TA_LearnMore_Card3_Title',
            items: [
              'HowToClaim_TA_LearnMore_Card3_Item1',
              'HowToClaim_TA_LearnMore_Card3_Item2'
            ],
            buttonMedicalReport: {
              title: 'HowToClaim_TA_LearnMore_Card3_ButtonMedicalReport'
            },
            buttonGDPR: {
              title: 'HowToClaim_TA_LearnMore_Card3_ButtonGDPR'
            }
          }
        ]
      },
      modalButton: {
        title: 'HowToClaim_TA_LearnMore_Modal_Button'
      },
      modalFooter: 'HowToClaim_TA_LearnMore_Modal_Footer'
    },
    youProvide: {
      title: 'HowToClaim_TA_YouProvide_Title',
      list: [
        {
          title: 'HowToClaim_TA_YouProvide_ListItem1_Title',
          subtitle: 'HowToClaim_TA_YouProvide_ListItem1_Subtitle'
        },
        {
          title: 'HowToClaim_TA_YouProvide_ListItem2_Title',
          subtitle: 'HowToClaim_TA_YouProvide_ListItem2_Subtitle'
        },
        {
          title: 'HowToClaim_TA_YouProvide_ListItem3_Title',
          subtitle: 'HowToClaim_TA_YouProvide_ListItem3_Subtitle'
        }
      ]
    }
  },
  'LOSS/DAMAGE/THEFT OF BAGGAGE': {
    title: 'HowToClaim_LDTB_Title',
    description: 'HowToClaim_LDTB_Description',
    footer: 'HowToClaim_LDTB_Footer',
    continueButton: 'HowToClaim_LDTB_ContinueButon',
    buttonTitle: 'HowToClaim_LDTB_LearnMore_Button',
    weAsk: {
      title: 'HowToClaim_LDTB_WeAsk_Title',
      list: [
        'HowToClaim_LDTB_WeAsk_ListItem1',
        'HowToClaim_LDTB_WeAsk_ListItem2',
        'HowToClaim_LDTB_WeAsk_ListItem3',
        'HowToClaim_LDTB_WeAsk_ListItem4'
      ]
    },
    learnMore: {
      title: 'HowToClaim_LDTB_LearnMoreTitle',
      cardContainer: {
        cards: [
          {
            title: 'HowToClaim_LDTB_LearnMore_Card1_Title',
            items: [
              'HowToClaim_LDTB_LearnMore_Card1_Item1',
              'HowToClaim_LDTB_LearnMore_Card1_Item2',
              'HowToClaim_LDTB_LearnMore_Card1_Item3',
              'HowToClaim_LDTB_LearnMore_Card1_Item4'
            ]
          },
          {
            title: 'HowToClaim_LDTB_LearnMore_Card2_Title',
            items: [
              'HowToClaim_LDTB_LearnMore_Card2_Item1',
              'HowToClaim_LDTB_LearnMore_Card2_Item2',
              'HowToClaim_LDTB_LearnMore_Card2_Item3',
              'HowToClaim_LDTB_LearnMore_Card2_Item4'
            ]
          },
          {
            title: 'HowToClaim_LDTB_LearnMore_Card3_Title',
            items: [
              'HowToClaim_LDTB_LearnMore_Card3_Item1',
              'HowToClaim_LDTB_LearnMore_Card3_Item2'
            ],
            buttonMedicalReport: {
              title: 'HowToClaim_LDTB_LearnMore_Card3_ButtonMedicalReport'
            },
            buttonGDPR: {
              title: 'HowToClaim_LDTB_LearnMore_Card3_ButtonGDPR'
            }
          }
        ]
      },
      modalButton: {
        title: 'HowToClaim_LDTB_LearnMore_Modal_Button'
      },
      modalFooter: 'HowToClaim_LDTB_LearnMore_Modal_Footer'
    },
    youProvide: {
      title: 'HowToClaim_LDTB_YouProvide_Title',
      list: [
        {
          title: 'HowToClaim_LDTB_YouProvide_ListItem1_Title',
          subtitle: 'HowToClaim_LDTB_YouProvide_ListItem1_Subtitle'
        },
        {
          title: 'HowToClaim_LDTB_YouProvide_ListItem2_Title',
          subtitle: 'HowToClaim_LDTB_YouProvide_ListItem2_Subtitle'
        },
        {
          title: 'HowToClaim_LDTB_YouProvide_ListItem3_Title',
          subtitle: 'HowToClaim_LDTB_YouProvide_ListItem3_Subtitle'
        },
        {
          title: 'HowToClaim_LDTB_YouProvide_ListItem4_Title',
          subtitle: 'HowToClaim_LDTB_YouProvide_ListItem4_Subtitle'
        }
      ]
    }
  }
};

export default howToClaimLabels;
