export const IBAN = 'iban';
export const SWIFT_CODE = 'swiftCode';
export const ACCOUNT = 'account';
export const SORT_CODE = 'sortCode';

export const BANK_LIST = [
  'Abbey National Treasury Services',
  'ABC International Bank',
  'Access Bank UK',
  'Adam & Company',
  'ADIB',
  'Ahli United Bank',
  'AIB Group',
  'Al Rayan Bank',
  'Aldermore Bank',
  'Alliance Trust Savings',
  'Alpha Bank London',
  'ANZ Bank',
  'Arbuthnot Latham & Co',
  'Atom Bank',
  'Axis Bank UK',
  'Bank and Clients',
  'Bank Leumi',
  'Bank Mandiri',
  'Bank of America Merrill Lynch International',
  'Bank of Baroda',
  'Bank of Beirut',
  'Bank of Ceylon',
  'Bank of China',
  'Bank of Communications',
  'Bank of Cyprus UK',
  'Bank of India',
  'Bank of Ireland',
  'Bank of London and The Middle East',
  'Bank of New York Mellon',
  'Bank of Scotland',
  'Bank of the Philippine Islands',
  'Bank Saderat',
  'Bank Sepah International',
  'Barclays Bank',
  'Bath Building Society',
  'Beverley Building Society',
  'BFC Bank',
  'BIRA Bank',
  'BMCE Bank International',
  'British Arab Commercial Bank',
  'Brown Shipley & Co',
  'Buckinghamshire Building Society',
  'C Hoare & Co',
  'CAF Bank',
  'Cambridge & Counties Bank',
  'Cambridge Building Society',
  'Cater Allen',
  'Charity Bank',
  'Charter Court Financial Services',
  'China Construction Bank',
  'Chorley & District Building Society',
  'CIBC World Markets',
  'Citibank',
  'ClearBank',
  'Close Brothers',
  'Clydesdale Bank',
  'Co-operative Bank',
  'Coutts',
  'Coutts & Company',
  'Coventry Building Society',
  'Credit Suisse',
  'Credit Suisse International',
  'Crown Agents Bank',
  'Cumberland Building Society',
  'Darlington Building Society',
  'DB UK Bank',
  'Diamond Bank',
  'Dudley Building Society',
  'Duncan Lawrie',
  'Earl Shilton Building Society',
  'Ecology Building Society',
  'EFG Private Bank',
  'Europe Arab Bank',
  'FBN Bank',
  'FCE Bank',
  'FCMB Bank',
  'First Direct',
  'Furness Building Society',
  'Gatehouse Bank',
  'GE Capital Bank',
  'Ghana International Bank',
  'Goldman Sachs International Bank',
  'Guaranty Trust Bank',
  'Gulf International Bank',
  'Habib Bank Zurich',
  'Habibsons Bank',
  'Halifax',
  'Hampden & Co',
  'Hampshire Trust Bank',
  'Hanley Economic Building Society',
  'Harpenden Building Society',
  'Havin Bank',
  'Hinckley & Rugby Building Society',
  'Holmesdale Building Society',
  'HSBC',
  'ICBC',
  'ICBC Standard Bank',
  'ICICI Bank UK',
  'Investec Bank',
  'Ipswich Building Society',
  'Isle of Man',
  'Itau BBA International',
  'J.P. Morgan Europe',
  'J.P. Morgan International Bank',
  'J.P. Morgan Securities',
  'John Lewis',
  'Jordan International Bank',
  'Julian Hodge Bank',
  'Kexim Bank',
  'Kingdom Bank',
  'Kookmin Bank International',
  'Leeds Building Society',
  'Leek United Building Society',
  'Lloyds Bank',
  'Lloyds Bank Private Banking',
  'Loughborough Building Society',
  'Macquarie Bank International',
  'Manchester Building Society',
  'Mansfield Building Society',
  'Market Harborough Building Society',
  'Marks & Spencer',
  'Marsden Building Society',
  'Masthaven Bank',
  'Melli Bank',
  'Methodist Chapel Aid',
  'Metro Bank',
  'Mizuho International',
  'Monmouthshire Building Society',
  'Monzo Bank',
  'Morgan Stanley Bank International',
  'National Bank of Egypt',
  'National Bank of Kuwait',
  'National Counties Building Society',
  'National Westminster Bank',
  'Nationwide Building Society',
  'Newbury Building Society',
  'Newcastle Building Society',
  'Nomura Bank International',
  'Northern Bank',
  'Northern Trust Global Services',
  'Nottingham Building Society',
  'OakNorth Bank',
  'OneSavings Bank',
  'Paragon Bank',
  'PCF Group',
  'Penrith Building Society',
  'Persia International Bank',
  'Philippine National Bank',
  'Principality Building Society',
  'Progressive Building Society',
  'Punjab National Bank',
  'QIB',
  'R. Raphael & Sons',
  'Rathbone Investment Management',
  'RBC Europe',
  'RBS International',
  'Reliance Bank',
  'Revolut',
  'Royal Bank of Scotland',
  'Saffron Building Society',
  'Sainsburys',
  'Santander UK',
  'Schroder & Co',
  'Scotiabank Europe',
  'Scottish Building Society',
  'Scottish Widows Bank',
  'Secure Trust Bank',
  'SG Kleinwort Hambros Bank',
  'Shawbrook Bank',
  'Skipton Building Society',
  'Smith & Williamson Investment Services',
  'Sonali Bank',
  'Stafford Railway Building Society',
  'Standard Chartered Bank',
  'Starling Bank',
  'State Bank of India',
  'Sumitomo Mitsui Banking Corporation Europe',
  'Swansea Building Society',
  'Tandem Bank',
  'TD Bank Europe',
  'Teachers Building Society',
  'Tesco Bank',
  'The Melton Building Society',
  'Tipton & Coseley Building Society',
  'TSB',
  'Turkish Bank',
  'UBS',
  'Ulster Bank',
  'Union Bank of India',
  'Union Bank UK',
  'United National Bank',
  'United Trust Bank',
  'Unity Trust Bank',
  'Vanquis Bank',
  'Vernon Building Society',
  'Virgin Money',
  'VTB Capital',
  'Weatherbys Bank',
  'Wesleyan Bank',
  'West Bromwich Building Society',
  'Westpac Europe',
  'Wyelands Bank',
  'Yorkshire Building Society',
  'Zenith Bank'
];

export const BANK_TYPE_LIST = {
  'Adam & Company': ['Black card'],
  'Bank of Scotland': [
    'Classic',
    'Gold',
    'Silver',
    'Premier',
    'Platinum',
    'Private Banking',
    'Ultimate Reward'
  ],
  'Barclays Bank': [
    'Additions Plus',
    'Additions Active',
    'Business Current',
    'Finer',
    'Travel Pack',
    'Travel Plus Pack',
    'Premier Current ',
    'Premier Travel Plus Pack',
    'Premier Life',
    'Premier Charge',
    'Bank Account',
    ' Infinite'
  ],
  Citibank: ['Citi Plus', 'Citi Gold'],
  'Co-operative Bank': ['Smilemore', 'Premier', 'Privilege'],
  Coutts: ['Silk', 'Black Card'],
  'First Direct': ['Retail', 'Account'],
  Halifax: ['Ultimate Reward'],
  HSBC: [
    'Retail',
    'Premier',
    'Aspects',
    'Platinum Visa',
    'Gold MasterCard',
    'Jade',
    'Insurance Aspects',
    'Private Bank',
    'First Directory',
    'Advance Account',
    'Student Account',
    'Bank Account'
  ],
  'Isle of Man': ['Gold '],
  'John Lewis': ['Essential ', 'Plus', 'Premier'],
  'Lloyds Bank': [
    'Silver',
    'Platinum',
    'Private',
    'Mayfair',
    'Island Gold',
    'Island Premier',
    'Gold',
    'Premier',
    'Club Lloyds',
    'Classic Account'
  ],
  'Marks & Spencer': ['Premium Club', 'Premium Current', 'Premium Account'],
  'National Westminster Bank': [
    'Advantage Gold ',
    'Select Platinum',
    'Black Card',
    'Gold Plus',
    'Select Silver',
    'Reward Account',
    'Reward Silver',
    'Reward Platinum',
    'Reward Black'
  ],
  'Nationwide Building Society': ['Flex', 'Flex plus'],
  'RBS International': ['Royalties'],
  'Royal Bank of Scotland': [
    'Royalties Gold ',
    'Select Platinum',
    'Black Card',
    'Royalties Premier',
    'Select Silver',
    'Reward Account',
    'Reward Silver',
    'Reward Platinum',
    'Reward Black'
  ],
  Sainsburys: ['Gold card'],
  'Tesco Bank': ['Value', 'Standard', 'Finer'],
  TSB: ['Silver', 'Gold', 'Platinum', 'Classic ', 'Plus ', 'Premier'],
  'Ulster Bank': ['Ufirst Gold', 'Ufirst Private'],
  'Yorkshire Building Society': ['Signature']
};
