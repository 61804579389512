import React from 'react';
import PropTypes from 'prop-types';

import Label from 'components/shared/Forms/Label';
import LabelFor from 'components/shared/Forms/LabelFor';
import Select from './SelectComponent';

const SelectField = props => {
  const getClass = base => `${base}  ${props.value ? 'is-valid' : ''}`;
  return (
    <div className={getClass(props.groupClassName)}>
      <LabelFor name={props.name} className="col col-md-12 col-lg-12 px-0">
        <Label text={props.label} isRequired={props.isRequired} />
        <Select
          isClearable={props.isClearable}
          disabled={props.disabled}
          onChange={props.onChange}
          options={props.options}
          value={props.value}
          className={props.className}
          name={props.name}
          placeholder={props.placeholder}
        />
      </LabelFor>
    </div>
  );
};

SelectField.propTypes = {
  label: PropTypes.string,
  isClearable: PropTypes.bool,
  isRequired: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string
    })
  ),
  value: PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string
  }),
  className: PropTypes.string,
  groupClassName: PropTypes.string,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string
};

SelectField.defaultProps = {
  label: '',
  isClearable: false,
  isRequired: false,
  disabled: false,
  className: '',
  options: [],
  groupClassName: 'form-group',
  value: null,
  placeholder: ''
};

export default SelectField;
