import React from 'react';
import PropTypes from 'prop-types';

const WizardSteps = ({ currentStep, lastStep, labels }) => {
  function setupStatus(step) {
    if (currentStep === step) {
      return {
        class: 'wizard__items--active',
        labelClass: 'd-md-block',
        tick: false
      };
    }
    if (lastStep >= step) {
      return {
        class: 'wizard__items--success',
        labelClass: 'd-none d-md-block',
        tick: true
      };
    }
    return {
      class: '',
      labelClass: 'd-none d-md-block',
      tick: false
    };
  }

  return (
    <div className="wizard">
      {labels.map((label, index) => (
        <React.Fragment key={label}>
          <div className={`wizard__items ${setupStatus(index + 1).class}`}>
            <div>
              {setupStatus(index + 1).tick ? (
                <i className="material-icons">check</i>
              ) : (
                index + 1
              )}
            </div>
            <h4 className={`caption ${setupStatus(index + 1).labelClass}`}>
              {label}
            </h4>
          </div>
          {index < labels.length - 1 && (
            <i className="material-icons wizard__lg-decoration">
              chevron_right
            </i>
          )}
        </React.Fragment>
      ))}
    </div>
  );
};

WizardSteps.propTypes = {
  currentStep: PropTypes.number,
  lastStep: PropTypes.number,
  labels: PropTypes.array
};

WizardSteps.defaultProps = {
  currentStep: 2,
  lastStep: 1,
  labels: [
    'What Happened',
    'The Dates',
    'Your Purchases',
    'Insuarance Policies',
    'Review'
  ]
};

export default WizardSteps;
