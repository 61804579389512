import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { withConfig } from 'ConfigurationProvider';
import Grid from '@material-ui/core/Grid';
import Grow from '@material-ui/core/Grow';
import { Button } from 'components/shared/Forms';

import { getExpenseTypes } from 'utils/expensesHelper';

export const ExpensesCategories = props => {
  const { t } = useTranslation();
  const expenseTypes = getExpenseTypes(props.config.EXPENSE_TYPES);

  const getExpenseIcon = category => {
    if (expenseTypes[category]) {
      if (typeof expenseTypes[category].icon === 'string')
        return <i className="material-icons">{expenseTypes[category].icon}</i>;
      return expenseTypes[category].icon.map((item, key) => (
        // Disabled Reason: not sure what is behind item. Possible technical debt.
        // eslint-disable-next-line react/no-array-index-key
        <i className="material-icons" key={key}>
          {item}
        </i>
      ));
    }
    return 'credit_card';
  };

  const getExpenseLabel = category => expenseTypes[category].label;

  const isSelected = title => {
    if (props.selectedCat[title] && props.selectedCat[title] === title) {
      return 'btn-primary btn-amex-selected';
    }
    return 'btn-outline-primary btn-amex';
  };

  return (
    <div className="your-purchases-categories">
      <Grid container spacing={8}>
        {props.typeOfExpenses.map(expense => (
          <Grid item xs={12} sm={6} md={4} lg={4}>
            <Grow in style={{ transformOrigin: '0 0 0' }} timeout={1000}>
              <Button
                id={`btn-expensesCategories-${expense}`}
                type="button"
                className={`btn ${isSelected(
                  expense
                )} btn-lg btn-block btn-expense`}
                onClick={() => props.onCategoryClick(expense)}
              >
                {getExpenseIcon(expense)}
                <br />
                <span className="expense-button__text">
                  {t(getExpenseLabel(expense))}
                </span>
              </Button>
            </Grow>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

ExpensesCategories.propTypes = {
  onCategoryClick: PropTypes.func.isRequired,
  typeOfExpenses: PropTypes.arrayOf(PropTypes.number.isRequired).isRequired,
  selectedCat: PropTypes.shape({}).isRequired,
  config: PropTypes.shape({
    EXPENSE_TYPES: PropTypes.shape({
      label: PropTypes.string,
      icon: PropTypes.arrayOf(PropTypes.string)
    })
  })
};

ExpensesCategories.defaultProps = {
  config: {
    EXPENSE_TYPES: null
  }
};

export default withConfig(ExpensesCategories);
