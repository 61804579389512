import { PAGE_BY_STEP_ID } from 'constants/claim';
import { getLanguageIsoCode } from 'utils/languageUtils';
import { getMarketFromUrl } from './urlHelpers';

const claimRoute = 'claim';
const BENEFITS = {
  1: 'BAGGAGE DELAY',
  2: 'BAGGAGE DELAY EXTENDED',
  3: 'BAGGAGE-MONEY & TRAVEL DOCS',
  4: 'CANCELLATION',
  5: 'CDW',
  6: 'CURTAILMENT',
  7: 'MEDICAL ASSISTANCE',
  8: 'MEDICAL EXPENSES',
  9: 'MISSED CONNECTION',
  10: 'MISSED DEPARTURE',
  11: 'SLI',
  12: 'TRANSPORTATION ASSIST',
  13: 'TRAVEL DELAY',
  14: 'LEGAL COSTS IN PURSUIT OF COMP',
  15: 'LEGAL DEFENSE COSTS',
  16: 'PERSONAL ACCIDENT',
  17: 'PERSONAL LIABILITY - PURSUIT O',
  18: 'PERSONAL LIABILITY LEGAL COSTS',
  19: 'PURCHASE PROTECTION',
  20: 'RETURN PROTECTION',
  21: 'TRAVEL ACCIDENT',
  22: 'HOME ASSISTANCE',
  23: 'Lock and Keys',
  24: 'Home Assistance/ Lock & Key',
  27: 'Refund Protection',
  28: 'Legal Assistance & Compensation - (In Defence)',
  29: 'Legal Assistance & Compensation - (In Pursuit)',
  30: 'LOSS/DAMAGE/THEFT OF BAGGAGE',
  31: 'Personal Belonging  /Baggage (Lloyds)',
  32: 'Business Travel Accident',
  33: 'Global Assistance  (AXA -service, not claim)'
};

export const getRoute = (currentPage, ...opts) =>
  buildRoute([currentPage, ...opts]);

export const getRouteForClaim = (language, benefit, claimStep, ...opts) => {
  const languageDesc = getLanguageIsoCode(language);

  const benefitDesc =
    benefit && Object.keys(BENEFITS).includes(benefit.toString())
      ? BENEFITS[benefit]
      : benefit;

  const claimStepDesc =
    claimStep && Object.keys(PAGE_BY_STEP_ID).includes(claimStep.toString())
      ? PAGE_BY_STEP_ID[claimStep].stepName
      : claimStep;

  return getRoute(
    claimRoute,
    languageDesc,
    benefitDesc,
    claimStepDesc,
    ...opts
  );
};

const buildRoute = routes =>
  [getMarketFromUrl(), ...routes].filter(x => !!x).join('/');

export default { getRoute };
