import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { pageview } from 'utils/analytics';
import { getRouteForClaim } from 'utils/routeHelper';
import { BANK_STEP } from 'constants/claim';

import {
  resetUserAnswers,
  resetVisibleGroups,
  loadDecisionTree
} from 'actions/decisionTreeActions';

import { saveClaimAndContinue } from 'actions/feature/claim';
import { updateClaimData } from 'actions/claimActions';

import { BANK_LABEL } from 'constants/constants';
import BankDataForm from 'containers/BankData/BankDataForm';
import Questionnaire from 'containers/Questionnaire';
import { appInsights } from 'ApplicationInsights';

export const BankData = props => {
  const [isFormValid, setIsFormValid] = useState(false);
  const [questionnaireAnswer, setQuestionnaireAnswer] = useState(false);

  React.useEffect(() => {
    pageview({
      title: 'Claim-Wizard',
      page: getRouteForClaim(
        props.language,
        props.currentClaim.benefitId,
        BANK_STEP
      )
    });
    appInsights.trackPageView(
      { 
        name: 'Claim-Wizard', 
        uri: getRouteForClaim(
          props.language,
          props.currentClaim.benefitId,
          BANK_STEP
        )
     }
    )
  }, [props.currentClaim.benefitId, props.language]);

  React.useEffect(() => {
    props.actions.resetUserAnswers();
    props.actions.resetVisibleGroups();
    props.actions.loadDecisionTree(BANK_LABEL);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (props.userAnswers && props.userAnswers[0]) {
      setQuestionnaireAnswer(props.userAnswers[0].value);
    }
  }, [props.userAnswers]);

  const handleStaticFormSubmit = info => {
    props.actions.updateClaimData({ bankData: info });
  };

  const handleOnContinue = async () => {
    props.actions.saveClaimAndContinue();
  };

  return (
    <div className="bank-data">
      <BankDataForm
        bankData={props.currentClaim.bankData}
        saveFormInfo={handleStaticFormSubmit}
        toggleFormValid={setIsFormValid}
      />
      <div
        id="bank-tree-question"
        className={isFormValid ? 'visible' : 'invisible'}
      >
        <Questionnaire
          onContinue={handleOnContinue}
          disabledContinue={!isFormValid || questionnaireAnswer === 'false'}
        />
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  visibleGroups: state.decisionTree.visibleGroups,
  userAnswers: state.decisionTree.userAnswers,
  currentClaim: state.currentClaim,
  language: state.marketLanguages.selectedLanguage,
  inTriage: state.inTriage
});

const mapDispatchToProps = dispatch => ({
  actions: {
    ...bindActionCreators(
      {
        resetUserAnswers,
        resetVisibleGroups,
        loadDecisionTree,
        updateClaimData,
        saveClaimAndContinue
      },
      dispatch
    )
  }
});

BankData.propTypes = {
  userAnswers: PropTypes.arrayOf(
    PropTypes.shape({
      questionId: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      nextGroupId: PropTypes.string.isRequired
    })
  ),
  actions: PropTypes.shape({
    resetVisibleGroups: PropTypes.func.isRequired,
    resetUserAnswers: PropTypes.func.isRequired,
    loadDecisionTree: PropTypes.func.isRequired,
    updateClaimData: PropTypes.func.isRequired,
    saveClaimAndContinue: PropTypes.func.isRequired
  }).isRequired,
  currentClaim: PropTypes.shape({
    benefitId: PropTypes.number.isRequired,
    step: PropTypes.number.isRequired,
    bankData: PropTypes.shape({
      name: PropTypes.string.isRequired,
      holder: PropTypes.string.isRequired,
      account: PropTypes.string.isRequired,
      accountType: PropTypes.string,
      sortCode: PropTypes.string,
      iban: PropTypes.string,
      swiftCode: PropTypes.string
    }).isRequired
  }).isRequired,
  language: PropTypes.number.isRequired
};

BankData.defaultProps = {
  userAnswers: []
};

export default connect(mapStateToProps, mapDispatchToProps)(BankData);
