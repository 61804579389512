import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'components/shared/Table';
import { Button } from 'components/shared/Forms';
import { MAX_STEP } from 'constants/claim';
import StickySaveButton from './StickySaveButton';
import useWidthDevice from '../../shared/customHooks/use-device-width';

const ENTER_KEY_CODE = 13;

const SaveLayoutCard = props => {
  const { isMobileDevice } = useWidthDevice();
  return (
    <Row className="no-gutters mb-4">
      <Col className="right-card">
        {((isMobileDevice && props.currentStep !== MAX_STEP) ||
          !isMobileDevice) && (
          <StickySaveButton submitDisabled={props.submitDisabled} />
        )}
        <Row className="no-gutters">
          <Col className="save-card-cel">
            <Button
              className="btn btn-outline-primary btn-block btn-amex"
              onClick={() => props.onSave()}
              type="button"
              text={props.labels.claimSaverSave}
              disabled={props.saveDisabled}
            />
          </Col>
        </Row>

        <Col className="save-card-cel-slim ">
          <span className="recommendation-text">
            {props.labels.ableToEditOnReview}
          </span>
        </Col>

        <Row className="no-gutters">
          <Col className="save-card-cel">
            <Row className="no-gutters ">
              <Col className="save-label text-left">
                {props.labels.claimSaverAXAClaim}
              </Col>
              <Col className="save-item text-right">{props.claimId}</Col>
            </Row>
          </Col>
        </Row>

        <Row className="no-gutters">
          <Col className="save-card-cel-super-slim">
            <Row className="no-gutters">
              <Col className="save-label save-time text-left">
                {props.labels.claimSaverContant}
              </Col>
              <Col className="save-item save-time text-right">
                {props.labels.claimSaverContantPhone}
              </Col>
            </Row>
          </Col>
        </Row>

        <Row className="no-gutters">
          <Col className="save-card-cel-slim save-time text-left">
            {props.labels.claimSaverOpenDays}
          </Col>
        </Row>

        <Row className="no-gutters">
          <Col className="save-card-cel-bottom">
            <Button
              className="btn btn-outline-primary btn-block btn-amex"
              onClick={() => props.onCancel()}
              onKeyDown={ev => {
                if (ev.keyCode === ENTER_KEY_CODE) {
                  props.onCancel();
                }
              }}
              type="button"
              text={props.labels.claimSaverDontWannaClaim}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

SaveLayoutCard.propTypes = {
  claimId: PropTypes.string.isRequired,
  onSave: PropTypes.func.isRequired,
  saveDisabled: PropTypes.bool,
  submitDisabled: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  labels: PropTypes.shape({
    claimSaverSubmit: PropTypes.string.isRequired,
    claimSaverSubmitCaption: PropTypes.string.isRequired,
    claimSaverSubmitReminder: PropTypes.string.isRequired,
    claimSaverSubmitTooltip: PropTypes.string.isRequired,
    claimSaverSave: PropTypes.string.isRequired,
    claimSaverDontWannaClaim: PropTypes.string.isRequired,
    claimSaverAXAClaim: PropTypes.string.isRequired,
    claimSaverContant: PropTypes.string.isRequired,
    claimSaverOpenDays: PropTypes.string.isRequired,
    claimSaverContantPhone: PropTypes.string.isRequired,
    ableToEditOnReview: PropTypes.string.isRequired
  }).isRequired,
  currentStep: PropTypes.number.isRequired
};

SaveLayoutCard.defaultProps = {
  saveDisabled: false,
  currentStep: 1
};

export default SaveLayoutCard;
