import React from 'react';
import PropTypes from 'prop-types';
import ReactCountryFlag from 'react-country-flag';

import { getLanguageSelectorCountryCode } from 'utils/languageUtils';
import { inBodiam } from '../../utils/urlHelpers';

const style = {
  cursor: 'pointer'
};

const Flag = props => {
  const selectedClassName = props.selected ? 'selected flags' : 'flags';
  const smallFlags = inBodiam() ? 'bodiam-flags' : '';
  return (
    <div
      id={`flag-${props.langId}`}
      className={`d-inline ${selectedClassName} ${smallFlags}`}
      onClick={props.selected ? () => {} : () => props.onClick(props.langId)}
      onKeyDown={props.selected ? () => {} : () => props.onClick(props.langId)}
    >
      <ReactCountryFlag
        code={getLanguageSelectorCountryCode(props.langId)}
        svg
        styleProps={style}
      />
    </div>
  );
};

Flag.propTypes = {
  langId: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
  selected: PropTypes.bool.isRequired
};

export default Flag;
