import {
  VALIDATE_CARD_REQUESTED,
  VALIDATE_CARD_SUCCEEDED,
  VALIDATE_CARD_FAILED,
  VALIDATE_CARD_NUMBER_REQUESTED,
  setCardValidationResult
} from 'actions/feature/card';
import { VALIDATE_CREDIT_CARD } from 'api/apiRoutes';
import { apiRequest } from 'actions/core/api';
import { setError } from 'actions/core/error';
import { hideSpinner, showSpinner ,notifyError,notifyWarning} from 'actions/core/ui';
import { getRecaptchaToken } from 'utils/recaptcha';
import Cookies from 'universal-cookie';

const cookies = new Cookies();
const failedCountCookieName = "NumberFailures";

export const validateCardNumberFlow = ({
  dispatch
}) => next => async action => {
  next(action);    
  if (action.type === VALIDATE_CARD_NUMBER_REQUESTED) {
    const { validateUnhandled } = action.payload;
    dispatch(showSpinner());
    const recaptcha = await getRecaptchaToken();
    dispatch(
        apiRequest(
          'POST',
        `${VALIDATE_CREDIT_CARD}/number${
          validateUnhandled === undefined
            ? ''
            : `?validateUnhandled=${validateUnhandled}`
        }`,
        { recaptcha },
        {
          number: action.payload.number
        },
        {
          ...action.meta
        },
        VALIDATE_CARD_SUCCEEDED,
        VALIDATE_CARD_FAILED
      )
    );
  }
};

export const validateCardFlow = ({ dispatch }) => next => async action => {
  next(action);

  if (action.type === VALIDATE_CARD_REQUESTED) {
    dispatch(showSpinner());
    const recaptcha = await getRecaptchaToken();
    dispatch(
      apiRequest(
        'POST',
        `${VALIDATE_CREDIT_CARD}/gencode`,
        { recaptcha },
        {
          number: action.payload.number,
          obfuscated: action.payload.obfuscated
        },
        {
          ...action.meta
        },
        VALIDATE_CARD_SUCCEEDED,
        VALIDATE_CARD_FAILED
      )
    );
  }
};


export const validateCardSuccess = ({ dispatch }) => next => action => {
    next(action);    
  if (action.type === VALIDATE_CARD_SUCCEEDED) {
    dispatch(setCardValidationResult(true));      
    const { onValidateCardSuccess } = action.meta;
      if (onValidateCardSuccess) {         
          if (action.payload.error) {             
              let numberOfFailures = cookies.get(failedCountCookieName)
              if (numberOfFailures == undefined) {
                  numberOfFailures = 0;                  
              }
              numberOfFailures = Number(numberOfFailures) + 1;
              if (numberOfFailures == 6 ) {                  
                  numberOfFailures = Number(numberOfFailures)-1;
                  cookies.set(failedCountCookieName, numberOfFailures,{ maxAge: 1800 },);    
                  dispatch(setError(action.payload.error));
              } else {                 
                  dispatch(notifyWarning('CardValidation'))
                  cookies.set(failedCountCookieName, numberOfFailures);
              }              
          }
          else {
              cookies.set(failedCountCookieName, 0, { maxAge: 0 },);
              dispatch({
                  type: onValidateCardSuccess,
                  payload: {
                      productId: action.payload.productId,
                      gencode: action.payload.gencode,
                      obfuscated: action.payload.obfuscated
                  },
                  meta: action.meta
              });
          }
    }
    dispatch(hideSpinner());
  }
};

export const validateCardFail = ({ dispatch }) => next => action => {
  next(action);

  if (action.type === VALIDATE_CARD_FAILED) {
    const { status, data } = action.payload;
    if (status === 400 || status === 404) {
      dispatch(setCardValidationResult(false, status, data));
    } else {
      dispatch(setError(action.payload));
    }

    const { onValidateCardFail } = action.meta;
    if (onValidateCardFail) {
      dispatch({
        type: onValidateCardFail,
        payload: action.payload,
        meta: action.meta
      });
    }
    dispatch(hideSpinner());
  }
};

export default [
  validateCardNumberFlow,
  validateCardFlow,
  validateCardSuccess,
  validateCardFail
];
