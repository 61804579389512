import { object, string } from 'yup';

const invalidTextLabel = 'isInValidText';

const schemaValidation = object({
  wasStable: string()
    .required(invalidTextLabel)
    .typeError(invalidTextLabel),
  deathCertificate: string()
    .required(invalidTextLabel)
    .typeError(invalidTextLabel),
  medicalCertificate: string()
    .required(invalidTextLabel)
    .typeError(invalidTextLabel)
});

export default schemaValidation;
