import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const Title = ({ value }) => {
  const { t } = useTranslation();

  return (
    <>
      <h5>{t('commonAmericanExpress')}</h5>
      {value && <h4 style={{ wordBreak: 'break-word' }}>{t(value)}</h4>}
    </>
  );
};

Title.propTypes = {
  value: PropTypes.string
};

Title.defaultProps = {
  value: undefined
};

export default Title;
