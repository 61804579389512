import React, { useState } from 'react';
import PropTypes from 'prop-types';

import CreatableSelect from 'components/shared/Select/CreatableSelectComponent';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'components/shared/Table';

import './_PolicyNumberSelect.scss';

const PolicyNumberSelect = ({
  onChange,
  value,
  list,
  regexValidation,
  maxLength
}) => {
  const [loading, setLoading] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [isInputValid, setIsInputValid] = useState(true);
  const [options, setOptions] = useState(
    list.map(x => ({
      value: x.number,
      label: x.number
    }))
  );

  const { t } = useTranslation();

  const getNumberElementByValue = number =>
    options.find(option => option.value === number);

  const createPolicyObject = newPolicy => ({
    value: newPolicy,
    label: newPolicy
  });

  const handleOnChange = inputPolicy => {
    const policy = createPolicyObject(inputPolicy.value);
    if (!options.some(option => option.value === inputPolicy.value)) {
      setOptions([...options, policy]);
    }
    setInputValue('');
    setIsInputValid(true);
    setLoading(false);
    onChange(policy);
  };

  const handleValidateToAddPolicy = inputPolicy => {
    if (!inputPolicy) return false;

    return (
      (options.length
        ? !options.some(option => option.value === inputPolicy)
        : true) && regexValidation.test(inputPolicy)
    );
  };

  const handleOnInputChange = valueOnChange => {
    if (!valueOnChange) setLoading(false);
    else setLoading(!handleValidateToAddPolicy(valueOnChange));

    setIsInputValid(!valueOnChange || handleValidateToAddPolicy(valueOnChange));
    setInputValue(valueOnChange);

    return valueOnChange;
  };

  const policyComponentLabelForLoadingAndNoOptions =
    options.length > 0
      ? t('policyComponentNoMatches')
      : t('policyComponentNoPolicies');

  return (
    <Row>
      <Col className="col-12 col-sm-12">
        <CreatableSelect
          className="w-100"
          name="select"
          clearable={false}
          placeholder={t('policyComponentPlaceholder')}
          onChange={handleOnChange}
          onValidateOption={handleValidateToAddPolicy}
          onInputChange={handleOnInputChange}
          options={options}
          value={getNumberElementByValue(value)}
          isInputValueValid={isInputValid}
          inputValue={inputValue}
          isLoading={loading}
          loadingMessage={policyComponentLabelForLoadingAndNoOptions}
          noOptionsMessage={policyComponentLabelForLoadingAndNoOptions}
          createNewOptionText={t('commonAddNew')}
          maxLenght={maxLength}
        />
      </Col>
    </Row>
  );
};

PolicyNumberSelect.propTypes = {
  value: PropTypes.string,
  list: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string
    })
  ),
  onChange: PropTypes.func.isRequired,
  regexValidation: PropTypes.instanceOf(RegExp).isRequired,
  maxLength: PropTypes.number.isRequired
};

PolicyNumberSelect.defaultProps = {
  value: '',
  list: []
};

export default PolicyNumberSelect;
