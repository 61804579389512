import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { withConfig } from 'ConfigurationProvider/index';

import './_BenefitList.scss';

export const BenefitList = props => {
  const { t } = useTranslation();

  const getOrderFromConfigurationByBenefitLabel = label => {
    const item = props.config.BENEFITS_ORDER.find(x => x.label === label);
    if (!item) return 99;
    return item.order;
  };

  const benefitSortedByConfiguration = () => {
    if (!props.config.BENEFITS_ORDER) return props.benefits;
    return props.benefits.sort((x, y) => {
      const orderX = getOrderFromConfigurationByBenefitLabel(
        x.benefitLabelCode
      );
      const orderY = getOrderFromConfigurationByBenefitLabel(
        y.benefitLabelCode
      );

      if (orderX > orderY) {
        return 1;
      }
      if (orderX < orderY) {
        return -1;
      }

      return 0;
    });
  };

  const renderBeneftiWithDescription = (benefitName, benefitDescription) => (
    <div className="list-item" key={benefitName}>
      <p>
        <strong>{benefitName}</strong>
      </p>
      <p
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: benefitDescription }}
      />
    </div>
  );
  return (
    <div className="benefit-card mb-4">
      <div className="card-body">
        <h3 className="card-title">{t(props.titleLabel)}</h3>
        {benefitSortedByConfiguration().map(x =>
          renderBeneftiWithDescription(
            t(x.benefitLabelCode),
            t(x.benefitDescriptionLabel)
          )
        )}
      </div>
    </div>
  );
};

BenefitList.propTypes = {
  titleLabel: PropTypes.string,
  benefits: PropTypes.arrayOf(
    PropTypes.shape({
      benefitLabelCode: PropTypes.string,
      benefitDescriptionLabel: PropTypes.string
    })
  ),
  config: PropTypes.shape({
    BENEFITS_ORDER: PropTypes.arrayOf(
      PropTypes.shape({
        labe: PropTypes.string,
        order: PropTypes.number
      })
    )
  }).isRequired
};

BenefitList.defaultProps = {
  titleLabel: 'commonPolicySummary',
  benefits: []
};

export default withConfig(BenefitList);
