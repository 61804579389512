import React from 'react';
import { ARUNDEL_BASE_URL } from 'constants/constants';

const MarketNotFoundPage = () => (
  <div>
    <h1 className="text-center text-white bg-danger">No market specified</h1>
    <h3 className="text-center">
      remember to write the url with welcome/:market
    </h3>
    <h6 className="text-center">
      This is a temporary page until we decide what to show in this case
    </h6>
    <p className="text-center">
      <a
        href={`${ARUNDEL_BASE_URL}amexUK`}
        className="text-white btn btn-primary"
      >
        Go to amexUK
      </a>
    </p>
  </div>
);

export default MarketNotFoundPage;
