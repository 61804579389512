import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';

import { onLogout } from 'actions/authenticationActions';
import * as activePageActions from 'actions/activePageActions';
import * as decisionTreeActions from 'actions/decisionTreeActions';
import * as navigationActions from 'actions/core/app.navigation';
import { validateAndGetBenefits, setActiveCard } from 'actions/feature/card';
import { setActivePolicy } from 'actions/feature/policy';
import setShowPopupV2 from 'actions/popupV2Actions';
import actionProvider from 'utils/action-provider/action-provider';

import NewsCarousel from 'Bodiam/containers/NewsCarousel/NewsCarousel';
import TopHeader from 'shared/containers/TopHeader';
import Header from 'components/LandingPage/components/header';
import { withConfig } from 'ConfigurationProvider';
import Body from 'components/LandingPage/components/body';
import Footer from 'shared/components/Footer';

import Alerts from 'containers/Alerts';

import {
  ACCOUNT_TYPE,
  MARKETS,
  ARUNDEL_BASE_URL,
  CHECK_POLICY_COVER,
  LANDING_PAGE,
  COVER_PAGE
} from 'constants/constants';
import { GA_EVENTS, GA_ACTIONS } from 'constants/googleAnalytics';

import { pageview, event } from 'utils/analytics';
import { isUserLoggedIn } from 'utils/sessionController';
import { getRoute } from 'utils/routeHelper';
import { appInsights } from 'ApplicationInsights';

export class LandingPage extends Component {
  constructor(props) {
    super(props);
    this.handleContinueClaim = this.handleContinueClaim.bind(this);
    this.handleNewClaim = this.handleNewClaim.bind(this);
    this.handleOpenCheckCoverModal = this.handleOpenCheckCoverModal.bind(this);
    this.handleCloseCheckCoverModal = this.handleCloseCheckCoverModal.bind(
      this
    );
    this.handleSelectUserAccount = this.handleSelectUserAccount.bind(this);
    this.handleOnCheckCoverModalSubmitClick = this.handleOnCheckCoverModalSubmitClick.bind(
      this
    );
    this.handleCheckPolicyCoverClick = this.handleCheckPolicyCoverClick.bind(
      this
    );
    this.handleAxaRetail = this.handleAxaRetail.bind(this);
    this.state = {
      showModal: false,
      showUserAccountModal: false
    };
  }

  componentDidMount() {
    
    pageview({
      title: 'Home',
      page: getRoute('home')
    });
    appInsights.trackPageView(
      {
        name: 'Home',
        uri: getRoute('home')
      }
    )
    if (this.props.config.RETAIL.IN_OLC) {
      this.setState({ showUserAccountModal: true });
    }
    this.props.actions.emptyCurrentClaim();
  }

  handleContinueClaim() {
    event(GA_EVENTS.HOME, GA_ACTIONS.CLICK, { label: 'continueClaim' });
    appInsights.trackEvent(
      {
        name:'continueClaim'
      },
      {
        EventCategory: GA_EVENTS.HOME,
        EventAction: GA_ACTIONS.CLICK,
        EventLabel: 'continueClaim'
      }
      )
    if (isUserLoggedIn()) {
      this.props.actions.gotoMyClaims();
    } else {
      this.props.actions.gotoSignIn();
    }
  }

  handleNewClaim() {
    event(GA_EVENTS.HOME, GA_ACTIONS.CLICK, { label: 'newClaim' });
    appInsights.trackEvent(
      {
        name:'newClaim'
      },
      {
        EventCategory: GA_EVENTS.HOME,
        EventAction: GA_ACTIONS.CLICK,
        EventLabel: 'newClaim'
      }
      )
    // If the user click on new claim, I have to clean the active card in the store
    if (isUserLoggedIn()) {
      this.props.actions.setActiveCard(null);
      this.props.actions.setActivePolicy(null);
    }
    this.props.actions.gotoTriageWrapper();
  }

  handleOpenCheckCoverModal() {
    event(GA_EVENTS.HOME, GA_ACTIONS.CLICK, { label: 'cardCheckCover' });
    appInsights.trackEvent(
      {
        name:'cardCheckCover'
      },
      {
        EventCategory: GA_EVENTS.HOME,
        EventAction: GA_ACTIONS.CLICK,
        EventLabel: 'cardCheckCover'
      }
      )
    this.props.actions.goTo(COVER_PAGE);
  }

  handleCloseCheckCoverModal() {
    this.setState({ showModal: false });
  }

  handleOnCheckCoverModalSubmitClick(answerValue, isCardNumber, callback) {
    this.props.actions.validateAndGetBenefits(answerValue, isCardNumber);
  }

  handleSelectUserAccount(accountType) {
    this.props.actions.setShowPopupV2();
    if (accountType === ACCOUNT_TYPE.RETAIL) {
      this.handleAxaRetail();
    } else {
      this.setState({ showUserAccountModal: false });
    }
  }

  handleAxaRetail() {
    if (isUserLoggedIn()) this.props.actions.onLogout(LANDING_PAGE);
    window.location.href = `${ARUNDEL_BASE_URL}${MARKETS.AXA_UK}`;
  }

  handleCheckPolicyCoverClick() {
    event(GA_EVENTS.HOME, GA_ACTIONS.CLICK, { label: 'policyCheckCover' });
    appInsights.trackEvent(
      {
        name:'policyCheckCover'
      },
      {
        EventCategory: GA_EVENTS.HOME,
        EventAction: GA_ACTIONS.CLICK,
        EventLabel: 'policyCheckCover'
      }
      )
    this.props.actions.goTo(CHECK_POLICY_COVER);
  }

  render() {
    const {
      props: { language, userInfo, policiesList, showPopupV2 },
      state: { showModal, showUserAccountModal }
    } = this;
    return (
      <div className="row no-gutters landing-page">
        <div className="col">
          <TopHeader />
          <Header />
          <Alerts />
          <NewsCarousel />
          <Body
            onContinueExistingClaim={this.handleContinueClaim}
            onStartNewClaim={this.handleNewClaim}
            onProfileTrial={this.handleProfileTrial}
            isOpenCheckCoverModal={showModal}
            isOpenCheckUserAccountModal={showUserAccountModal && showPopupV2}
            onOpenCheckCoverModal={this.handleOpenCheckCoverModal}
            onCloseCheckCoverModal={this.handleCloseCheckCoverModal}
            onSelectUserAccount={this.handleSelectUserAccount}
            onCheckCoverModalSubmit={this.handleOnCheckCoverModalSubmitClick}
            language={language}
            userInfo={userInfo}
            policiesList={policiesList}
            onCheckPolicyCoverClick={this.handleCheckPolicyCoverClick}
          />
          <Footer />
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  language: state.marketLanguages.selectedLanguage,
  config: PropTypes.shape({
    RETAIL: PropTypes.shape({
      IN_OLC: PropTypes.bool.isRequired
    }).isRequired
  }).isRequired,
  userInfo: state.userInfo,
  policiesList: state.policiesList,
  showPopupV2: state.showPopupV2
});

const mapDispatchToProps = dispatch => ({
  actions: {
    ...bindActionCreators(activePageActions, dispatch),
    ...bindActionCreators(decisionTreeActions, dispatch),
    ...bindActionCreators(navigationActions, dispatch),
    ...bindActionCreators(
      {
        setShowPopupV2,
        onLogout,
        validateAndGetBenefits,
        setActiveCard,
        setActivePolicy,
        emptyCurrentClaim: actionProvider.getClaimActions().emptyCurrentClaim
      },
      dispatch
    )
  }
});

LandingPage.propTypes = {
  language: PropTypes.number,
  config: PropTypes.shape({
    RETAIL: PropTypes.shape({
      IN_OLC: PropTypes.bool.isRequired
    }).isRequired
  }).isRequired,
  actions: PropTypes.shape({
    gotoSignIn: PropTypes.func.isRequired,
    gotoTriageWrapper: PropTypes.func.isRequired,
    gotoMyClaims: PropTypes.func.isRequired,
    goTo: PropTypes.func.isRequired,
    validateAndGetBenefits: PropTypes.func.isRequired,
    setShowPopupV2: PropTypes.func,
    onLogout: PropTypes.func,
    setActiveCard: PropTypes.func,
    setActivePolicy: PropTypes.func,
    emptyCurrentClaim: PropTypes.func
  }).isRequired,
  userInfo: PropTypes.shape({}).isRequired,
  policiesList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  showPopupV2: PropTypes.bool
};

LandingPage.defaultProps = {
  language: 0,
  showPopupV2: false
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withConfig(LandingPage));
