import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Label, Button, ValidatorText } from 'components/shared/Forms';
import { YESNO_ANSWER } from 'constants/constants';

const YesNo = props => {
  const { t } = useTranslation();

  const isInvalid =
    props.errorFields[props.name] && props.errorFields[props.name].hasError;

  const selectedStyle = (value, reference) => {
    return value === reference ? 'btn-amex-selected' : '';
  };

  const renderErrorMessage = errorMessages => (
    <div className="invalid-feedback">{t(errorMessages)}</div>
  );
  const onClick = value => props.onChange(props.name, value);

  const handleSelectedYes = props.booleanValues
    ? () => onClick(true)
    : () => onClick(YESNO_ANSWER.YES);
  const handleSelectedNO = props.booleanValues
    ? () => onClick(false)
    : () => onClick(YESNO_ANSWER.NO);

  const styleSeletedYes = props.booleanValues ? true : YESNO_ANSWER.YES;
  const styleSeletedNO = props.booleanValues ? false : YESNO_ANSWER.NO;

  const hasValue = props.value !== undefined;

  return (
    <>
      <Label text={t(props.label)} isRequired />
      {props.hasHelp && (
        <ValidatorText
          name={props.name}
          text={t('commonSelectAnswer')}
          isSelected={hasValue}
        />
      )}
      <div className="row mb-2 mt-3">
        <div className="col">
          <Button
            id="btn-yesNo-Yes"
            type="button"
            className={`btn btn-outline-primary btn-lg btn-amex mr-1 ${selectedStyle(
              props.value,
              styleSeletedYes
            )}`}
            onClick={handleSelectedYes}
          >
            {t('commonYes')}
          </Button>
          <Button
            id="btn-yesNo-No"
            type="button"
            className={`btn btn-outline-primary btn-lg btn-amex ${selectedStyle(
              props.value,
              styleSeletedNO
            )}`}
            onClick={handleSelectedNO}
          >
            {t('commonNo')}
          </Button>
        </div>
      </div>
      {isInvalid && renderErrorMessage(props.errorFields[props.name].message)}
    </>
  );
};

YesNo.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  booleanValues: PropTypes.bool,
  hasHelp: PropTypes.bool,
  errorFields: PropTypes.shape({
    hasError: PropTypes.bool,
    message: PropTypes.string
  })
};
YesNo.defaultProps = {
  value: undefined,
  booleanValues: false,
  errorFields: {},
  hasHelp: false
};

export default YesNo;
