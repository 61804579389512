export const CURRENCY_TYPES = {
  AED: {
    symbol: 'AED',
    code: 'currencyAED'
  },
  ARS: {
    symbol: 'ARS',
    code: 'currencyARS'
  },
  AUD: {
    symbol: 'AUD',
    code: 'currencyAUD'
  },
  AWG: {
    symbol: 'AWG',
    code: 'currencyAWG'
  },
  BBD: {
    symbol: 'BBD',
    code: 'currencyBBD'
  },
  BGN: {
    symbol: 'BGN',
    code: 'currencyBGN'
  },
  BHD: {
    symbol: 'BHD',
    code: 'currencyBHD'
  },
  BOB: {
    symbol: 'BOB',
    code: 'currencyBOB'
  },
  BRL: {
    symbol: 'BRL',
    code: 'currencyBRL'
  },
  BSD: {
    symbol: 'BSD',
    code: 'currencyBSD'
  },
  BZD: {
    symbol: 'BZD',
    code: 'currencyBZD'
  },
  CAD: {
    symbol: 'CAD',
    code: 'currencyCAD'
  },
  CHF: {
    symbol: 'CHF',
    code: 'currencyCHF'
  },
  CLP: {
    symbol: 'CLP',
    code: 'currencyCLP'
  },
  CNY: {
    symbol: 'CNY',
    code: 'currencyCNY'
  },
  COP: {
    symbol: 'COP',
    code: 'currencyCOP'
  },
  CRC: {
    symbol: 'CRC',
    code: 'currencyCRC'
  },
  // CUC: {
  //  symbol: 'CUC',
  //  code: 'currencyCUC'
  //},
  CUP: {
    symbol: 'CUP',
    code: 'currencyCUP'
  },
  CZK: {
    symbol: 'CZK',
    code: 'currencyCZK'
  },
  DKK: {
    symbol: 'DKK',
    code: 'currencyDKK'
  },
  DOP: {
    symbol: 'DOP',
    code: 'currencyDOP'
  },
  EGP: {
    symbol: 'EGP',
    code: 'currencyEGP'
  },
  FJD: {
    symbol: 'FJD',
    code: 'currencyFJD'
  },
  FKP: {
    symbol: 'FKP',
    code: 'currencyFKP'
  },
  GIP: {
    symbol: 'GIP',
    code: 'currencyGIP'
  },
  GTQ: {
    symbol: 'GTQ',
    code: 'currencyGTQ'
  },
  HKD: {
    symbol: 'HKD',
    code: 'currencyHKD'
  },
  HNL: {
    symbol: 'HNL',
    code: 'currencyHNL'
  },
  HRK: {
    symbol: 'HRK',
    code: 'currencyHRK'
  },
  HUF: {
    symbol: 'HUF',
    code: 'currencyHUF'
  },
  IDR: {
    symbol: 'IDR',
    code: 'currencyIDR'
  },
  ILS: {
    symbol: 'ILS',
    code: 'currencyILS'
  },
  INR: {
    symbol: 'INR',
    code: 'currencyINR'
  },
  ISK: {
    symbol: 'ISK',
    code: 'currencyISK'
  },
  JMD: {
    symbol: 'JMD',
    code: 'currencyJMD'
  },
  JOD: {
    symbol: 'JOD',
    code: 'currencyJOD'
  },
  JPY: {
    symbol: 'JPY',
    code: 'currencyJPY'
  },
  KES: {
    symbol: 'KES',
    code: 'currencyKES'
  },
  KHR: {
    symbol: 'KHR',
    code: 'currencyKHR'
  },
  KRW: {
    symbol: 'KRW',
    code: 'currencyKRW'
  },
  KYD: {
    symbol: 'KYD',
    code: 'currencyKYD'
  },
  KZT: {
    symbol: 'KZT',
    code: 'currencyKZT'
  },
  LKR: {
    symbol: 'LKR',
    code: 'currencyLKR'
  },
  LTL: {
    symbol: 'LTL',
    code: 'currencyLTL'
  },
  LVL: {
    symbol: 'LVL',
    code: 'currencyLVL'
  },
  MAD: {
    symbol: 'MAD',
    code: 'currencyMAD'
  },
  MKD: {
    symbol: 'MKD',
    code: 'currencyMKD'
  },
  MUR: {
    symbol: 'MUR',
    code: 'currencyMUR'
  },
  MVR: {
    symbol: 'MVR',
    code: 'currencyMVR'
  },
  MWK: {
    symbol: 'MWK',
    code: 'currencyMWK'
  },
  MXN: {
    symbol: 'MXN',
    code: 'currencyMXN'
  },
  MYR: {
    symbol: 'MYR',
    code: 'currencyMYR'
  },
  NAD: {
    symbol: 'NAD',
    code: 'currencyNAD'
  },
  NOK: {
    symbol: 'NOK',
    code: 'currencyNOK'
  },
  NPR: {
    symbol: 'NPR',
    code: 'currencyNPR'
  },
  NZD: {
    symbol: 'NZD',
    code: 'currencyNZD'
  },
  OMR: {
    symbol: 'OMR',
    code: 'currencyOMR'
  },
  PEN: {
    symbol: 'PEN',
    code: 'currencyPEN'
  },
  PHP: {
    symbol: 'PHP',
    code: 'currencyPHP'
  },
  PKR: {
    symbol: 'PKR',
    code: 'currencyPKR'
  },
  PLN: {
    symbol: 'PLN',
    code: 'currencyPLN'
  },
  PYG: {
    symbol: 'PYG',
    code: 'currencyPYG'
  },
  RON: {
    symbol: 'RON',
    code: 'currencyRON'
  },
  SAR: {
    symbol: 'SAR',
    code: 'currencySAR'
  },
  SCR: {
    symbol: 'SCR',
    code: 'currencySCR'
  },
  SEK: {
    symbol: 'SEK',
    code: 'currencySEK'
  },
  SGD: {
    symbol: 'SGD',
    code: 'currencySGD'
  },
  SVC: {
    symbol: 'SVC',
    code: 'currencySVC'
  },
  THB: {
    symbol: 'THB',
    code: 'currencyTHB'
  },
  TND: {
    symbol: 'TND',
    code: 'currencyTND'
  },
  TRY: {
    symbol: 'TRY',
    code: 'currencyTRY'
  },
  TTD: {
    symbol: 'TTD',
    code: 'currencyTTD'
  },
  TWD: {
    symbol: 'TWD',
    code: 'currencyTWD'
  },
  UAH: {
    symbol: 'UAH',
    code: 'currencyUAH'
  },
  VND: {
    symbol: 'VND',
    code: 'currencyVND'
  },
  XCD: {
    symbol: 'XCD',
    code: 'currencyXCD'
  },
  ZAR: {
    symbol: 'ZAR',
    code: 'currencyZAR'
  },
  RSD: {
    symbol: 'RSD',
    code: 'currencyRSD'
  }
};

export const CURRENCY_INITIALS = {
  EURO: {
    EUR: {
      symbol: 'EUR',
      code: 'currencyEUR'
    },
    GBP: {
      symbol: 'GBP',
      code: 'currencyGBP'
    },
    USD: {
      symbol: 'USD',
      code: 'currencyUSD'
    }
  },
  UK: {
    GBP: {
      symbol: 'GBP',
      code: 'currencyGBP'
    },
    EUR: {
      symbol: 'EUR',
      code: 'currencyEUR'
    },
    USD: {
      symbol: 'USD',
      code: 'currencyUSD'
    }
  },
  SPECIAL: {
    EUR: {
      symbol: 'EUR',
      code: 'currencyEUR'
    },
    USD: {
      symbol: 'USD',
      code: 'currencyUSD'
    },
    GBP: {
      symbol: 'GBP',
      code: 'currencyGBP'
    }
  }
};
