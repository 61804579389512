import { CLAIM_STATUS_CODES } from 'constants/constants';

export function getStatusDescription(status) {
  let result;
  switch (status) {
    case CLAIM_STATUS_CODES.IN_PROGRESS:
      result = 'commonStatusInProgress';
      break;
    case CLAIM_STATUS_CODES.DECLINED:
      result = 'commonStatusDeclined';
      break;
    case CLAIM_STATUS_CODES.WITHDRAWN:
      result = 'commonStatusWithdrawn';
      break;
    case CLAIM_STATUS_CODES.SUBMITTED:
      result = 'commonStatusSubmitted';
      break;
    case CLAIM_STATUS_CODES.APPROVED:
      result = 'commonStatusApproved';
      break;
    case CLAIM_STATUS_CODES.SETTLED:
      result = 'commonStatusSettled';
      break;
    case CLAIM_STATUS_CODES.UNAVAILABLE:
      result = 'commonStatusUnavailable';
      break;
    default:
      result = 'commonStatusUnknow';
      break;
  }
  return result;
}

export function getStatusToolTip(status) {
  let result;
  switch (status) {
    case CLAIM_STATUS_CODES.IN_PROGRESS:
      result = 'commonStatusToolTipInProgress';
      break;
    case CLAIM_STATUS_CODES.DECLINED:
      result = 'commonStatusToolTipDeclined';
      break;
    case CLAIM_STATUS_CODES.WITHDRAWN:
      result = 'commonStatusToolTipWithdrawn';
      break;
    case CLAIM_STATUS_CODES.SUBMITTED:
      result = 'commonStatusToolTipSubmitted';
      break;
    case CLAIM_STATUS_CODES.APPROVED:
      result = 'commonStatusToolTipApproved';
      break;
    case CLAIM_STATUS_CODES.SETTLED:
      result = 'commonStatusToolTipSettled';
      break;
    case CLAIM_STATUS_CODES.UNAVAILABLE:
      result = 'commonStatusToolTipUnavailable';
      break;
    default:
      result = 'commonStatusUnknow';
      break;
  }
  return result;
}
