import React, { useState } from 'react';
import PropTypes from 'prop-types';

import HolderCardOptions from 'components/ContactsQuestion/Shared/HolderCardOptions';
import AffectedPersonWithDateOfBirth from './AffectedPersonWithDateOfBirth';

const AffectedPersonWithDateOfBirthCard = props => {
  const person = {
    id: props.person.id,
    typeObjectId: props.person.typeObjectId,
    isValid: props.person.isValid,
    payload: {
      ...props.person.payload,
      cardOption: props.person.payload.cardOption
    }
  };

  const [formValues, setFormValues] = useState(person);

  const handleFieldFormValueChange = value => {
    const clone = {
      ...formValues,
      payload: { ...formValues.payload, cardOption: value }
    };
    validateAndUpdate(clone);
  };

  const validateAndUpdate = values => {
    setFormValues(values);

    const clone = { ...values, isValid: values.isValid && isFormValid(values) };
    props.onUpdateContact(clone);
  };

  const isFormValid = values => values.isValid && values.payload.cardOption;

  const handleOnUpdateContactBase = values => {
    const clone = {
      ...formValues,
      ...values,
      payload: { ...formValues.payload, ...values.payload }
    };
    validateAndUpdate(clone);
  };

  return (
    <>
      <AffectedPersonWithDateOfBirth
        person={props.person}
        isRemovable={props.isRemovable}
        onRemoveContact={props.onRemoveContact}
        onUpdateContact={handleOnUpdateContactBase}
      />
      <HolderCardOptions
        value={props.person.payload.cardOption}
        isPrimaryCard={props.isPrimaryCard}
        onChange={handleFieldFormValueChange}
      />
    </>
  );
};

AffectedPersonWithDateOfBirthCard.propTypes = {
  person: PropTypes.shape({
    id: PropTypes.string,
    typeObjectId: PropTypes.number.isRequired,
    isValid: PropTypes.bool.isRequired,
    payload: PropTypes.shape({
      cardOption: PropTypes.string
    })
  }).isRequired,
  isRemovable: PropTypes.bool,
  isPrimaryCard: PropTypes.bool.isRequired,
  onUpdateContact: PropTypes.func.isRequired,
  onRemoveContact: PropTypes.func.isRequired
};
AffectedPersonWithDateOfBirthCard.defaultProps = {
  isRemovable: false
};

export default AffectedPersonWithDateOfBirthCard;
