import React, { Component } from 'react';
import Moment from 'moment';
import PropTypes from 'prop-types';

import { DATE_FORMAT } from 'constants/constants';
import { Col } from 'components/shared/Table';
import CalendarHeaderComponent from './CalendarHeaderComponent';
import CalendarDayComponent from './CalendarDayComponent';

class CalendarComponent extends Component {
  constructor(props) {
    super(props);
    this.weekComponent = this.weekComponent.bind(this);
  }

  dayComponent(day) {
    return (
      <CalendarDayComponent
        key={day.format(DATE_FORMAT)}
        handleDaySelect={this.props.handleDaySelect}
        questions={this.props.questions}
        day={Moment(day)}
        displayMonth={this.props.displayMonth}
        activeQuestion={this.props.activeQuestion}
      />
    );
  }

  weekComponent(displayDate) {
    displayDate.startOf('isoWeek').add(-1, 'days');

    const weekArray = [1, 1, 1, 1, 1, 1, 1];
    const weekComp = weekArray.map(weekday =>
      this.dayComponent(displayDate.add(weekday, 'days'))
    );

    return <div className="row no-gutters">{weekComp}</div>;
  }

  render() {
    const displayDate = this.props.displayDate.startOf('month');
    const mobilClass = this.props.left
      ? ''
      : ' d-sm-none d-none d-md-none d-lg-none d-xl-block';

    return (
      <Col className={`calendar ${mobilClass}`}>
        <CalendarHeaderComponent
          displayDate={Moment(displayDate).locale(this.props.languageCode)}
        />
        {this.weekComponent(displayDate)}
        {this.weekComponent(displayDate.add(1, 'weeks'))}
        {this.weekComponent(displayDate.add(1, 'weeks'))}
        {this.weekComponent(displayDate.add(1, 'weeks'))}
        {this.weekComponent(displayDate.add(1, 'weeks'))}
        {this.weekComponent(displayDate.add(1, 'weeks'))}
      </Col>
    );
  }
}

CalendarComponent.propTypes = {
  displayDate: PropTypes.shape({
    startOf: PropTypes.func.isRequired
  }).isRequired,
  questions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  left: PropTypes.bool.isRequired,
  displayMonth: PropTypes.string.isRequired,
  handleDaySelect: PropTypes.func.isRequired,
  activeQuestion: PropTypes.shape({}),
  languageCode: PropTypes.string.isRequired
};

CalendarComponent.defaultProps = {
  activeQuestion: null
};

export default CalendarComponent;
