import React from 'react';
import PropTypes from 'prop-types';

const Col = props => (
  // eslint-disable-next-line react/prop-types
  // eslint-disable-next-line jsx-a11y/click-events-have-key-events
  <div className={`col ${props.className}`} onClick={props.onClick}>
    {props.children}
  </div>
);

Col.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func
};

Col.defaultProps = {
  className: '',
  onClick: () => {}
};

export default Col;
