import { useState } from 'react';

const useDatePicker = onChangeDate => {
  const [dateValue, setValue] = useState();
  const handleChangeDate = dateChanged => {
    const dateInput = new Date(dateChanged);
    if (!isNaN(Number(dateInput)) && dateInput.getFullYear() > 1900) {
      const date = getDateAsUTC(dateChanged);
      onChangeDate(date);
      setValue(date);
    }
  };
  const updateState = dateInput => {
    const date = new Date(dateInput);
    if (!isNaN(Number(date)) && date.getFullYear() > 1900) {
      const onlyDate = getDateAsUTC(date);
      setValue(onlyDate);
    }
  };

  const getDateAsUTC = date => {
    date.setHours(0, 0, 0, 0);

    return new Date(
      Date.UTC(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        date.getHours(),
        date.getMinutes(),
        date.getSeconds(),
        date.getMilliseconds()
      )
    );
  };

  return {
    handleChangeDate,
    dateValue,
    updateState
  };
};

export default useDatePicker;
