import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { event } from 'utils/analytics';
import { validateField, validateForm } from 'utils/formValidationHelper';
import { isUserLoggedIn } from 'utils/sessionController';

import { REGEX } from 'constants/constants';
import { GA_EVENTS, GA_ACTIONS } from 'constants/googleAnalytics';

import { TextField, DateField, Button } from 'components/shared/Forms';
import { Row, Col } from 'components/shared/Table';
import PolicyNumberField from 'components/shared/PolicyNumberField';
import {
  schemaValidation,
  maxLengthConstants,
  renderPolicyHelpText
} from './PolicyNumberComponentSchemaValidation';
import { appInsights } from 'ApplicationInsights';

const PolicyNumberComponent = props => {
  const [errorFields, setErrorFields] = useState({});
  const [formValues, setFormValues] = useState({});

  React.useEffect(() => {
    setFormValues({ ...props.userInfo, policyNumber: props.activePolicy });
  }, [props.userInfo, props.activePolicy]);

  const { t } = useTranslation();

  const handleOnPolicyFieldChange = policyNumber => {
    handleValueChange({
      target: { name: 'policyNumber', value: policyNumber }
    });
  };

  const handleValueChange = e => {
    setNewValues(e.target.name, e.target.value);
  };

  const setNewValues = (name, value) => {
    const newFormValues = {
      ...formValues,
      [name]: value
    };
    setFormValues(newFormValues);
    props.onChange();
  };

  const handleSubmit = () => {
    if (validForm()) {
      event(GA_EVENTS.POLICY, GA_ACTIONS.SUBMIT, {
        label: `${props.guideWireField}`
      });
      appInsights.trackEvent(
        {
          name:`${props.guideWireField}`
        },
        {
          EventCategory: GA_EVENTS.POLICY,
          EventAction: GA_ACTIONS.SUBMIT,
          EventLabel: `${props.guideWireField}`
        }
        )
      props.onValidPolicyNumber(
        props.id,
        formValues.policyNumber,
        formValues.name,
        formValues.surname,
        formValues.dateOfBirth
      );
    }
  };

  const isUserLogged = isUserLoggedIn();

  const validForm = () => {
    const result = validateForm(schemaValidation, formValues);
    return result.isValid;
  };

  const handleValidation = (fieldName, value) => {
    const result = validateField(schemaValidation, fieldName, value);
    setErrorFields({
      ...errorFields,
      ...result.errorFields
    });
  };

  return (
    <Row>
      <Col>
        <Row>
          <Col
            className={`col-12 col-md-6${props.isAdding ? '' : ' full-row'}`}
          >
            <PolicyNumberField
              text={t(props.textLabel)}
              warningText={!props.isAdding && t('personalDataPolicyWarning')}
              helpText={
                !props.isAdding && t(renderPolicyHelpText(props.policiesList))
              }
              value={formValues.policyNumber}
              list={props.policiesList}
              onValueChange={handleOnPolicyFieldChange}
              regexValidation={REGEX.AMEX_POLICY}
              maxLength={maxLengthConstants.policyNumber}
              isAdding={props.isAdding}
            />
          </Col>
          <Col
            className={`col-12 col-md-6${props.isAdding ? '' : ' full-row'}`}
          >
            <DateField
              label={t('policyDateOfBirthLabel')}
              name="dateOfBirth"
              value={formValues.dateOfBirth}
              disabled={isUserLogged}
              onValueChange={value => setNewValues('dateOfBirth', value)}
              errorFields={errorFields}
              placeholder={t('policyDateOfBirthPlaceholder')}
              ariaDescribedby={t('policyDateOfBirthPlaceholder')}
              required
              onValidate={handleValidation}
              maxLength={maxLengthConstants.policyDate}
              toolTipText={
                isUserLogged && t('toolTip_PolicyDataRestrictionDateOfBirth')
              }
            />
          </Col>
        </Row>
        <Row>
          <Col
            className={`col-12 col-md-6${props.isAdding ? '' : ' full-row'}`}
          >
            <TextField
              label={t('policyNameLabel')}
              name="name"
              value={formValues.name}
              disabled={isUserLogged}
              onValueChange={handleValueChange}
              errorFields={errorFields}
              ariaDescribedby={t('policyNameLabel')}
              required
              onValidate={handleValidation}
              toolTipText={
                isUserLogged && t('toolTip_PolicyDataRestrictionFirstName')
              }
            />
          </Col>
          <Col
            className={`col-12 col-md-6${props.isAdding ? '' : ' full-row'}`}
          >
            <TextField
              label={t('policyLastNameLabel')}
              name="surname"
              value={formValues.surname}
              disabled={isUserLogged}
              onValueChange={handleValueChange}
              errorFields={errorFields}
              ariaDescribedby={t('policyLastNameLabel')}
              required
              onValidate={handleValidation}
              toolTipText={
                isUserLogged && t('toolTip_PolicyDataRestrictionLastName')
              }
            />
          </Col>
        </Row>
        <Button
          id="policy-number-question-lufthansa-submit-btn"
          type="button"
          className="btn btn-primary btn-lg btn-block btn-amex btn-check-policy"
          onClick={handleSubmit}
          disabled={!validForm()}
          text={t('checkPolicyContinue')}
        />
      </Col>
    </Row>
  );
};

PolicyNumberComponent.propTypes = {
  id: PropTypes.number,
  textLabel: PropTypes.string,
  userAnswer: PropTypes.shape({ value: PropTypes.string }),
  onValidPolicyNumber: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  policiesList: PropTypes.arrayOf(PropTypes.shape({})),
  activePolicy: PropTypes.string,
  isAdding: PropTypes.bool,
  userInfo: PropTypes.shape({
    name: PropTypes.string,
    surname: PropTypes.string,
    dateOfBirth: PropTypes.string,
    number: PropTypes.string
  }),
  guideWireField: PropTypes.string
};
PolicyNumberComponent.defaultProps = {
  id: PropTypes.number,
  textLabel: '',
  userAnswer: {
    value: ''
  },
  policiesList: [],
  activePolicy: '',
  userInfo: {
    number: ''
  },
  isAdding: false,
  onChange: () => {},
  guideWireField: 'policyNumber'
};

export default PolicyNumberComponent;
