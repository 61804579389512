import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Grow from '@material-ui/core/Grow';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { useTranslation } from 'react-i18next';

import { Row, Col } from 'components/shared/Table';
import UploadQuestion from 'components/UploadQuestion';

const UploadDocument = props => {
  const [showDescription, setShowDescription] = useState(false);
  const { t } = useTranslation();

  const handleFieldFormValueChange = (selectedOption, fieldId) => {
    const result = selectedOption > 1 ? selectedOption : fieldId;
    props.onChange(result);
  };

  const displayUploadComponentDescription = (label, showHelp) => {
    const isDescription = (
      <Grow
        in={showDescription}
        style={{ transformOrigin: '0 0 0' }}
        {...(showDescription ? { timeout: 1000 } : {})}
      >
        <Row>
          <Col className="description">{t(`${label}Description`)}</Col>
        </Row>
      </Grow>
    );
    return (
      <Row>
        <Col>
          <Row>
            <Col className="label">
              <span
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: `${t(label)} *`
                }}
              />
              {showHelp && (
                <button
                  type="button"
                  className="btn btn-link"
                  onClick={() => setShowDescription(!showDescription)}
                >
                  <HelpOutlineIcon />
                </button>
              )}
            </Col>
          </Row>
          {showDescription && isDescription}
        </Col>
      </Row>
    );
  };

  return (
    <>
      <Row className="expenses">
        <Col>
          <h5 className="expenses-title">
            {displayUploadComponentDescription(props.label, props.showHelp)}
          </h5>
          <Row className="mb-3 mt-3">
            <Col>
              <UploadQuestion
                id={props.id}
                claimId={props.claimId}
                answer={props.value}
                onUserAnswer={handleFieldFormValueChange}
                isMedical={props.isMedical}
                benefitId={props.benefitId}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

UploadDocument.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  isMedical: PropTypes.bool.isRequired,
  showHelp: PropTypes.bool.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  claimId: PropTypes.string.isRequired,
  benefitId: PropTypes.number.isRequired
};
UploadDocument.defaultProps = {
  value: undefined
};

export default UploadDocument;
