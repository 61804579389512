import * as types from './actionTypes';

import authenticationApi from '../api/authenticationApi';
import { beginAjaxCall, ajaxCallError } from './ajaxStatusActions';
import { handleApiError } from './alertsActions';

export function getUserInfoSuccess() {
  return { type: types.USER_INFO_SUCCESS };
}

export function setUserInfo(userInfo) {
  return { type: types.SET_USER_INFO, info: userInfo };
}

export function addUserInfo() {
  return dispatch => {
    dispatch(beginAjaxCall());
    return authenticationApi
      .getUserInfo()
      .then(userInfo => {
        dispatch(setUserInfo(userInfo));
        dispatch(getUserInfoSuccess());
      })
      .catch(error => {
        dispatch(handleApiError(error));
        dispatch(ajaxCallError());
      });
  };
}
