import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import CardsCarousel from 'Bodiam/components/CardsCarousel';
import MainMenu from 'Bodiam/components/MainMenu';
import EmergencyCall from 'Bodiam/components/EmergencyCall';
import NewsCarousel from 'Bodiam/containers/NewsCarousel/NewsCarousel';

import * as activePageActions from 'actions/activePageActions';
import history from 'utils/history';
import { getMarketFromUrl } from 'utils/urlHelpers';
import { resetHistory } from 'utils/sessionController';
import { scrollTop } from 'utils/pageUtils';
import {
  BODIAM_DASHBOARD,
  BODIAM_BASE_URL,
  ARUNDEL_BASE_URL
} from 'constants/constants';
import { getActiveCard } from 'store/selectors/activeCard';

export class MainPage extends React.Component {
  componentDidMount() {
    scrollTop();
  }

  goToPolicyDetails = () => {
    history.push(`${BODIAM_BASE_URL}${getMarketFromUrl()}/your-cover`);
  };

  goToMyAccount = () => {
    this.props.actions.bodiamViewProfileAction();
    this.props.actions.gotoMyClaims();
    history.push(`${ARUNDEL_BASE_URL}${getMarketFromUrl()}`);
  };

  goToNewClaim = () => {
    const newClaimUrlWithMarket = `${ARUNDEL_BASE_URL}${getMarketFromUrl()}`;
    const {
      props: { actions }
    } = this;
    resetHistory(BODIAM_DASHBOARD);
    actions.bodiamMakeClaimAction();
    history.push(newClaimUrlWithMarket);
    actions.gotoTriageWrapper();
  };

  render() {
    const { goToNewClaim, goToPolicyDetails, props } = this;
    const isGlobalAssistProduct =
      props.activeCard && !props.activeCard.hasMedExpense;
    return (
      <div className="bodiam-landing">
        <div className="section-container">
          <CardsCarousel labels={props.labels} />
          <EmergencyCall
            labels={props.labels}
            globalAssist={isGlobalAssistProduct}
          />
          <div className="landing-page container">
            <button
              className="call-to-action-bodiam-button d-flex continue"
              type="submit"
              onClick={goToPolicyDetails}
            >
              <div className="d-flex flex-1 justify-content-center align-items-center">
                <span className="mr-auto">
                  {props.labels.landingCardQuestionH3}
                </span>

                <i className="material-icons arrow">navigate_next</i>
              </div>
            </button>
          </div>
          <MainMenu
            newClaim={goToNewClaim}
            goToMyAccount={this.goToMyAccount}
            labels={props.labels}
          />
          {props.labels && <NewsCarousel />}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  labels: state.labels,
  marketLanguages: state.marketLanguages,
  prismicEvents: state.prismicEvents,
  activeCard: getActiveCard(state)
});

const mapDispatchToProps = dispatch => ({
  actions: {
    ...bindActionCreators(activePageActions, dispatch)
  }
});

MainPage.propTypes = {
  actions: PropTypes.shape({
    bodiamViewProfileAction: PropTypes.func.isRequired,
    gotoMyClaims: PropTypes.func.isRequired,
    bodiamMakeClaimAction: PropTypes.func.isRequired,
    gotoTriageWrapper: PropTypes.func.isRequired
  }).isRequired,
  marketLanguages: PropTypes.shape({
    selectedLanguage: PropTypes.string
  }),
  activeCard: PropTypes.shape({
    product: PropTypes.string,
    hasMedExpense: PropTypes.bool
  }).isRequired,
  labels: PropTypes.shape({
    bodiamGetMedicalHelp: PropTypes.string,
    landingCardQuestionH3: PropTypes.string
  }).isRequired
};
MainPage.defaultProps = {
  marketLanguages: {}
};

export default connect(mapStateToProps, mapDispatchToProps)(MainPage);
