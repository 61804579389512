import {
  ADD_AFFECTED_PERSON,
  UPDATE_AFFECTED_PERSON,
  REMOVE_AFFECTED_PERSON
} from 'actions/feature/claim';
import { UPDATE_CLAIM } from '../actions/feature/claim';
import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function claim(state = initialState.currentClaim, action) {
  const claimData = action.claim;
  switch (action.type) {
    case UPDATE_CLAIM:
      return { ...state, ...action.payload };
    case types.UPDATE_CLAIM_DATA:
      return { ...state, ...claimData };
    case types.EMPTY_CURRENT_CLAIM:
      return initialState.currentClaim;
    case types.CLEAR_BENEFIT_ID:
      return { ...state, benefitId: null };
    case ADD_AFFECTED_PERSON:
      return {
        ...state,
        affectedPersons: [...state.affectedPersons, action.payload]
      };
    case UPDATE_AFFECTED_PERSON:
      return {
        ...state,
        affectedPersons: [...action.payload]
      };
    case REMOVE_AFFECTED_PERSON:
      return {
        ...state,
        affectedPersons: state.affectedPersons.filter(
          person => person.id !== action.payload
        )
      };
    default:
      return state;
  }
}
