import { DOCUMENT_TYPE } from 'constants/constants';
import { endPointCallGet } from './endPointCallerApi';
import * as apiRoutes from './apiRoutes';

class HowToClaimApi {
  static getHowToClaim(benefit, productId) {
    const url = `${apiRoutes.HOW_TO_CLAIM}/${productId}/benefit/${benefit}`;
    return endPointCallGet(url);
  }

  static getMedicalDocument() {
    const url = `${apiRoutes.HOW_TO_CLAIM}/doctype/${DOCUMENT_TYPE.MEDICAL_REPORT}`;
    return endPointCallGet(url);
  }

  static getGDPRDocument() {
    const url = `${apiRoutes.HOW_TO_CLAIM}/doctype/${DOCUMENT_TYPE.GDPR_REPORT}`;
    return endPointCallGet(url);
  }
}
export default HowToClaimApi;
