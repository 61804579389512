import { values } from 'lodash';
import { EURO_MARKETS, UK_MARKETS, SPECIAL_MARKETS } from 'constants/constants';
import { CURRENCY_TYPES, CURRENCY_INITIALS } from 'constants/currency';
import { getMarketFromUrl } from './urlHelpers';

export function getCurrencyInitialsByMarket(market) {
  if (UK_MARKETS.indexOf(market) > -1) return CURRENCY_INITIALS.UK;
  if (SPECIAL_MARKETS.indexOf(market) > -1) return CURRENCY_INITIALS.SPECIAL;
  if (EURO_MARKETS.indexOf(market) > -1) return CURRENCY_INITIALS.EURO;

  // by default
  return CURRENCY_INITIALS.EURO;
}

export function getCurrencySymbol(currencyCode) {
  const currenciesObj = getAllCurrencies();
  if (currenciesObj[currencyCode]) {
    return currenciesObj[currencyCode].symbol;
  }
  return '$';
}

export function getAllCurrencies() {
  const initialCurrencies = getCurrencyInitialsByMarket(getMarketFromUrl());
  return Object.assign(initialCurrencies, CURRENCY_TYPES);
}

export function getCurrencyTypes() {
  const currenciesObj = getAllCurrencies();
  return values(currenciesObj).map(currency => ({
    value: currency.symbol,
    label: currency.code,
    key: currency.code
  }));
}
