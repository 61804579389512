import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
  WHAT_HAPPENED_STEP,
  REVIEW_STEP,
  DATES_STEP,
  EXPENSES_STEP,
  PREVIOUS_STEP,
  BANK_STEP,
  PAGE_BY_STEP_ID
} from 'constants/claim';
import { GA_EVENTS, GA_ACTIONS } from 'constants/googleAnalytics';

import { scrollTop } from 'utils/pageUtils';
import { pageview, event } from 'utils/analytics';
import { getRouteForClaim } from 'utils/routeHelper';

import SummaryCard from 'components/Review/SummaryCard';
import CheckedContentQA from 'components/Review/CheckedContentQA';
import CheckedContentExpenses from 'components/Review/CheckedContentExpenses';
import AcceptConditionsCard from 'components/Review/AcceptConditionsCard';
import AnythingElse from 'components/Review/AnythingElse';
import CheckedCard from 'components/Review/CheckedCard';
import { appInsights } from 'ApplicationInsights';
import UploadClaimDocumentPage from 'containers/UploadClaimDocument';

const Review = props => {
  useEffect(() => {
    pageview({
      title: 'Claim-Wizard',
      page: getRouteForClaim(
        props.languageId,
        props.claim.benefitId,
        REVIEW_STEP
      )
    });
    appInsights.trackPageView(
      { 
        name: 'Claim-Wizard', 
        uri: getRouteForClaim(
        props.languageId,
        props.claim.benefitId,
        REVIEW_STEP
      )
     }
    )
  }, [props.claim.benefitId, props.languageId]);
  const { t } = useTranslation();
  const handleChangeStep = step => {
    event(GA_EVENTS.REVIEW, GA_ACTIONS.CLICK, {
      label: PAGE_BY_STEP_ID[step].stepName
    });
    appInsights.trackEvent(
      {
        name:PAGE_BY_STEP_ID[step].stepName
      },
      {
        EventCategory: GA_EVENTS.REVIEW,
        EventAction: GA_ACTIONS.CLICK,
        EventLabel: PAGE_BY_STEP_ID[step].stepName
      }
      )
    scrollTop();
    props.setActivePage(step);
  };
  return (
    <>
      <SummaryCard
        amountLabel={t('commonTotal')}
        amount={props.claim.amount}
        reviewClaimPolicyLink={t('reviewClaimReturnCheckPolicy')}
        reviewClaimReturn={t('reviewClaimReturn')}
      />
      <h1>{t('reviewCorrectInfo')}</h1>
      <div className="mb-4">{t('reviewCorrectInfoDescription')}</div>
      <CheckedCard
        labels={{ commonEdit: t('commonEdit') }}
        title={t('stepHappenTitle')}
        onEdit={() => {
          handleChangeStep(WHAT_HAPPENED_STEP);
        }}
        completed={props.claim.step > REVIEW_STEP}
      >
        <CheckedContentQA
          questions={props.claim?.trees?.incidentDescription?.questions}
          claimContacts={props.claim.contacts}
          userInfo={props.userInfo}
          claim={props.claim}
        />
      </CheckedCard>
      <CheckedCard
        labels={{ commonEdit: t('commonEdit') }}
        title={t('stepDatesTitle')}
        onEdit={() => {
          handleChangeStep(DATES_STEP);
        }}
        completed={props.claim.step > REVIEW_STEP}
      >
        <CheckedContentQA
          questions={props.claim?.trees?.dates?.questions}
          claimContacts={props.claim.contacts}
          claim={props.claim}
        />
      </CheckedCard>
      <CheckedCard
        labels={{ commonEdit: t('commonEdit') }}
        title={t('stepExpensesTitle')}
        onEdit={() => {
          handleChangeStep(EXPENSES_STEP);
        }}
        completed={props.claim.step > REVIEW_STEP}
      >
        <CheckedContentExpenses expenses={props.claim.expenses} />
      </CheckedCard>
      <CheckedCard
        labels={{ commonEdit: t('commonEdit') }}
        title={t('stepPreviousInsuranceTitle')}
        onEdit={() => {
          handleChangeStep(PREVIOUS_STEP);
        }}
        completed={props.claim.step > REVIEW_STEP}
      >
        <CheckedContentQA
          questions={props.claim?.trees?.insurances?.questions}
          claimContacts={props.claim.contacts}
          claim={props.claim}
        />
      </CheckedCard>
      <CheckedCard
        labels={{ commonEdit: t('commonEdit') }}
        title={t('stepBankTitle')}
        onEdit={() => {
          handleChangeStep(BANK_STEP);
        }}
        completed={props.claim.step > REVIEW_STEP}
      >
        <CheckedContentQA
          questions={props.claim.bankData}
          claimContacts={props.claim.contacts}
          bankData
          claim={props.claim}
              />              
          </CheckedCard>                       
      <AnythingElse
        onAddComment={props.onAddComment}
        notes={props.claim.notes}
          />
          <UploadClaimDocumentPage claim={props.claim} />
          <AcceptConditionsCard onSubmit={props.onSubmitClaim} />          
    </>
  );
};

Review.propTypes = {
  setActivePage: PropTypes.func.isRequired,
  onAddComment: PropTypes.func.isRequired,
  onSubmitClaim: PropTypes.func.isRequired,
  userInfo: PropTypes.shape({}).isRequired,
  claim: PropTypes.shape({
    id: PropTypes.string,
    amount: PropTypes.string,
    trees: PropTypes.shape({
      insurances: PropTypes.shape({
        questions: PropTypes.shape({})
      }),
      incidentDescription: PropTypes.shape({
        questions: PropTypes.shape({})
      }),
      dates: PropTypes.shape({
        questions: PropTypes.shape({})
      })
    }),
    bankData: PropTypes.bool,
    notes: PropTypes.string.isRequired,
    benefitId: PropTypes.number.isRequired,
    step: PropTypes.number.isRequired,
    expenses: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    contacts: PropTypes.arrayOf(PropTypes.shape({})).isRequired
  }).isRequired,
  languageId: PropTypes.number.isRequired
};

export default Review;
