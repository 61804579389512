import React from 'react';
import { useTranslation } from 'react-i18next';
import InfoIcon from './InfoIcon';

const EmailAdditionalText = () => {
  const { t } = useTranslation();
  return (
    <span className="d-block mt-2">
      <InfoIcon />
      <span className="upload__info-text mt-2">{t('uploadEmailText')}</span>
    </span>
  );
};

export default EmailAdditionalText;
