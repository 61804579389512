import { SET_ERROR } from '../../actions/core/error';
import initialState from '../initialState';

export default function error(state = initialState.error, action) {
  switch (action.type) {
    case SET_ERROR:
      return { ...state, error: action.payload };
    default:
      return state;
  }
}
