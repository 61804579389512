/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { event } from 'utils/analytics';
import { GA_EVENTS, GA_ACTIONS } from 'constants/googleAnalytics';
import history from 'utils/history';
import { appInsights } from 'ApplicationInsights';
import {
  MARKETS,
  BODIAM_DASHBOARD,
  APP_VERSION,
  BODIAM_BASE_URL,
  ARUNDEL_BASE_URL,
  LANDING_PAGE
} from 'constants/constants';
import { onLogout } from 'actions/authenticationActions';
import * as activePageActions from 'actions/activePageActions';
import {
  isMarketAmexUK,
  isMarketLufthansa,
  getMarketFromUrl,
  inBodiam,
  fromBodiam 
} from 'utils/urlHelpers';
import { isUserLoggedIn } from 'utils/sessionController';
import { withTranslation } from 'react-i18next';
import { Row, Col } from 'components/shared/Table';

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hiddenFooter: true
    };
  }

  onRedirect(action) {
    const arundelUrl = `${ARUNDEL_BASE_URL}${getMarketFromUrl()}`;
    if (inBodiam()) {
      window.history.pushState(null, null, window.href);
      localStorage.removeItem('history');
      localStorage.setItem(
        'history',
        JSON.stringify([BODIAM_DASHBOARD, BODIAM_DASHBOARD])
      );
      this.props.actions.bodiamStaticPageArundel();
      history.push(arundelUrl);
    }
    action();
    history.push(arundelUrl);
  }

  toggleHiddenFooter = () => {
    event(GA_EVENTS.FOOTER, GA_ACTIONS.CLICK, {
      label: 'learnmore-Link'
    });
    appInsights.trackEvent(
    {
      name:'learnmore-Link'
    },
    {
      EventCategory: GA_EVENTS.FOOTER,
      EventAction: GA_ACTIONS.CLICK,
      EventLabel: 'learnmore-Link'
    }
    )
    this.setState(prevState => ({
      hiddenFooter: !prevState.hiddenFooter
    }));
  };

  goMyDetails = () => {
    if (inBodiam() || fromBodiam(this.props.bodiamAction)) {
      this.props.actions.bodiamViewProfileAction();
    }
    this.props.actions.gotoMyClaims();
    history.push(`${ARUNDEL_BASE_URL}${getMarketFromUrl()}`);
  };

  getFooterContent = (content, btnLabel) => (
    <Row className="no-gutters align-items-center footer-bar py-4">
      <div className="offset-2 col-8 text-center align-middle">
        <div dangerouslySetInnerHTML={{ __html: this.props.t(content) }} />
        <div className="footer-more__btn" onClick={this.toggleHiddenFooter}>
          {this.props.t(btnLabel)}
        </div>
      </div>
    </Row>
  );

  goHome = () => {
    if (inBodiam() || fromBodiam(this.props.bodiamAction)) {
      history.push(`${BODIAM_BASE_URL}${getMarketFromUrl()}`);
    } else {
      this.props.actions.gotoLandingPage();
      history.push(`${ARUNDEL_BASE_URL}${getMarketFromUrl()}`);
    }
  };

  goAxaRetail = () => {
    if (this.props.isLoggedIn) this.props.actions.onLogout(LANDING_PAGE);
    window.location.href = `${ARUNDEL_BASE_URL}${MARKETS.AXA_UK}`;
  };

  footerLinks() {
    const setClickEventGA = label =>
    {
      event(GA_EVENTS.FOOTER, GA_ACTIONS.CLICK, {
        label
      });
      appInsights.trackEvent(
        {
          name:label
        },
        {
          EventCategory: GA_EVENTS.FOOTER,
          EventAction: GA_ACTIONS.CLICK,
          EventLabel: label
        }
        );
      
    }
      
    const isInBodiam = inBodiam() || fromBodiam(this.props.bodiamAction);
    return (
      <Row className="no-gutters">
        <Col className="footer-toolbar">
          <span
            className="btn btn-link"
            onClick={() => {
              setClickEventGA('home');
              this.goHome();
            }}
          >
            {this.props.t('commonHome')}
          </span>
          <span className="btn-separator">|</span>
          {this.props.isLoggedIn && (
            <>
              <span
                className="btn btn-link"
                onClick={() => {
                  setClickEventGA('myAccount');
                  this.goMyDetails();
                }}
              >
                {this.props.t('landingPageButtonContSubtitle')}
              </span>
              <span className="btn-separator">|</span>
            </>
          )}
          {isMarketAmexUK(getMarketFromUrl()) && !isInBodiam && (
            <>
              <span
                className="btn btn-link"
                onClick={() => {
                  setClickEventGA('retailPolicy');
                  this.goAxaRetail();
                }}
              >
                {this.props.t('retailPolicy')}
              </span>
              <span className="btn-separator">|</span>
            </>
          )}
          <span
            className="btn btn-link"
            onClick={() => {
              setClickEventGA('contactUs');
              this.onRedirect(this.props.actions.gotoContactsPage);
            }}
          >
            {this.props.t('thanksLinkContactUs')}
          </span>
          <span className="btn-separator">|</span>
          <span
            className="btn btn-link"
            onClick={() => {
              setClickEventGA('FAQ');
              this.onRedirect(this.props.actions.gotoFAQPage);
            }}
          >
            {this.props.t('thanksLinkFAQ')}
          </span>
          <span className="btn-separator">|</span>
          <span
            className="btn btn-link"
            onClick={() => {
              setClickEventGA('cookies');
              this.onRedirect(this.props.actions.gotoCookiesPage);
            }}
          >
            {this.props.t('commonCookies')}
          </span>
          {isMarketLufthansa() && (
            <>
              <span className="btn-separator">|</span>
              <span
                className="btn btn-link"
                onClick={() => {
                  setClickEventGA('DataProtection');
                  this.onRedirect(this.props.actions.gotoDataProtectionPage);
                }}
              >
                {this.props.t('[dataProtectionPage] Common')}
              </span>
              <span className="btn-separator">|</span>
              <span
                className="btn btn-link"
                onClick={() => {
                  setClickEventGA('LegalNotice');
                  this.onRedirect(this.props.actions.gotoLegalNoticePage);
                }}
              >
                {this.props.t('[legalNoticePage] Common')}
              </span>

            </>
          )}
        </Col>
      </Row>
    );
  }

  render() {
    const content = this.state.hiddenFooter
      ? this.getFooterContent('footerShort', 'footerLearn')
      : this.getFooterContent('footerBS', 'footerHide');
    return (
      <Row className="no-gutters footer footer-bar-middle">
        <Col>
          <Row className="no-gutters align-items-center footer-bar">
            <div className="col text-center align-middle">
              {`${this.props.t('footerMark')} `}
              {APP_VERSION}
            </div>
          </Row>
          <div className="container">
            <Row className="no-gutters align-items-center ">
              <div className="col">{this.footerLinks()}</div>
            </Row>
          </div>
          {content}
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = state => ({
  isLoggedIn: isUserLoggedIn(),
  labels: state.labels,
  bodiamAction: state.bodiamAction
});

const mapDispatchToProps = dispatch => ({
  actions: {
    ...bindActionCreators(activePageActions, dispatch),
    ...bindActionCreators({ onLogout }, dispatch)
  }
});

Footer.propTypes = {
  t: PropTypes.func,
  actions: PropTypes.shape({
    bodiamViewProfileAction: PropTypes.func.isRequired,
    gotoContactsPage: PropTypes.func.isRequired,
    gotoFAQPage: PropTypes.func.isRequired,
    gotoCookiesPage: PropTypes.func.isRequired,
    gotoLandingPage: PropTypes.func.isRequired,
    gotoSignIn: PropTypes.func.isRequired,
    gotoTriageWrapper: PropTypes.func.isRequired,
    gotoMyClaims: PropTypes.func.isRequired,
    bodiamStaticPageArundel: PropTypes.func.isRequired,
    onLogout: PropTypes.func.isRequired
  }).isRequired,
  isLoggedIn: PropTypes.bool,
  bodiamAction: PropTypes.string
};

Footer.defaultProps = {
  t: result => result,
  bodiamAction: null,
  isLoggedIn: false
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(Footer));
