import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Moment from 'moment';
import './CalendarLanguages';

import Grow from '@material-ui/core/Grow';
import { Row, Col } from 'components/shared/Table';
import { Button } from 'components/shared/Forms';

import { withTranslation } from 'react-i18next';
import {
  CALENDAR_DATETIME_TYPE,
  CALENDAR_DATETIME_ROUGHLY_TYPE,
  CALENDAR_DATETIME_SCHEDULED_TYPE,
  CALENDAR_DATETIME_ACTUAL_TYPE
} from 'constants/constants';

import Md from 'utils/TextHelpers';
import { withConfig } from 'ConfigurationProvider';

export class CalendarListComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.renderListItem = this.renderListItem.bind(this);
  }

  renderListItem(item) {
    let dateFormated =
      item.answer &&
      Moment(item.answer)
        .locale(this.props.lang)
        .format(this.props.config.DATE_FORMAT);

    if (
      item.type === CALENDAR_DATETIME_TYPE ||
      item.type === CALENDAR_DATETIME_ROUGHLY_TYPE ||
      item.type === CALENDAR_DATETIME_SCHEDULED_TYPE ||
      item.type === CALENDAR_DATETIME_ACTUAL_TYPE
    ) {
      dateFormated =
        item.answer &&
        `${Moment(item.answer)
          .locale(this.props.lang)
          .format(this.props.config.DATE_FORMAT)} ${Moment(item.answer)
          .locale(this.props.lang)
          .format(this.props.config.TIME_FORMAT)}`;
    }
    const renderItem = (
      <li key={item.id} className="ml-3 mr-3">
        <Grow
          in={Boolean(item.id)}
          style={{ transformOrigin: '0 0 0' }}
          {...(item.id ? { timeout: 1000 } : {})}
        >
          <Row className="no-gutters mb-2 mr-3 ">
            <Col className="col-1 pt-2">
              <i
                className={`material-icons align-middle small-date-icon icon-calendar-color-${item.color}`}
              >
                fiber_manual_record
              </i>
            </Col>
            <Col className="col-7 pt-2">{Md.render(item.label)}</Col>
            <Col className="pt-2">{item.answer && dateFormated}</Col>
            <Col className="col-1">
              <Button
                type="submit"
                className="btn btn-link"
                onClick={() => this.props.handleChangeDate(item.id)}
              >
                {this.props.t('CommonChange')}
              </Button>
            </Col>
          </Row>
        </Grow>
      </li>
    );
    if (item.answer !== null) {
      return renderItem;
    }
    return '';
  }

  renderList(questions) {
    const items = questions.map(item => this.renderListItem(item)).reverse();
    return <ul>{items}</ul>;
  }

  render() {
    return this.renderList(this.props.questions);
  }
}

CalendarListComponent.propTypes = {
  handleChangeDate: PropTypes.func.isRequired,
  lang: PropTypes.string.isRequired,
  questions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  config: PropTypes.shape({
    DATE_FORMAT: PropTypes.string.isRequired,
    TIME_FORMAT: PropTypes.string.isRequired
  }).isRequired,
  t: PropTypes.func.isRequired
};

export default withConfig(withTranslation()(CalendarListComponent));
