import { object } from 'yup';
import {
  commonEmailValidations,
  commonPasswordValidations
} from '../shared/CommonSchemaValidations';

export default function getSchemaValidation() {
  return object({
    ...commonEmailValidations,
    ...commonPasswordValidations
  });
}
