import {
  SET_DATES_VALIDATION_RESULT,
  RESET_DATES_VALIDATION_RESULT
} from 'actions/feature/dates';
import initialState from 'reducers/initialState';

export default function datesValidation(
  state = initialState.datesValidation,
  action
) {
  switch (action.type) {
    case SET_DATES_VALIDATION_RESULT:
      return action.payload.result;
    case RESET_DATES_VALIDATION_RESULT:
      return initialState.datesValidation;
    default:
      return state;
  }
}
