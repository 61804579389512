import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { noop } from 'lodash';
import { useTranslation } from 'react-i18next';

import RecoverPasswordForm from 'components/RecoverPasswordForm';
import RegistrationForm from 'components/RegistrationForm';
import SignInForm from 'components/SignInForm';
import { Row, Col } from 'components/shared/Table';

import {
  signInUserAndCreateClaim,
  signInUser,
  createUser
} from 'actions/feature/user';
import { addAlert } from 'actions/alertsActions';
import { userRecoverPassword } from 'actions/authenticationActions';

import { formTypes, BODIAM_DASHBOARD, TASKLIST } from 'constants/constants';
import { GA_EVENTS, GA_ACTIONS } from 'constants/googleAnalytics';

import { inBodiam } from 'utils/urlHelpers';
import { pageview, event } from 'utils/analytics';
import { getRoute } from 'utils/routeHelper';
import { appInsights } from 'ApplicationInsights';

export const RegistrationPage = props => {
  const [activeForm, setActiveForm] = useState('');
  const [registrationData, setRegistrationData] = useState({
    title: '',
    firstName: '',
    surname: '',
    phone: '',
    email: '',
    confirmEmail: '',
    dateOfBirth: '',
    password: '',
    productId: null,
    cardNumber: '',
    address: '',
    addressNumber: '',
    addressPC: '',
    addressCity: '',
    obfuscated: ''
  });
  const { t } = useTranslation();

  useEffect(() => {
    pageview({ title: 'User', page: getRoute('user', 'registration') });
    appInsights.trackPageView(
      { name: 'User', 
      uri: getRoute('user', 'registration') }
    )
  }, []);

  const handleChangeDisplay = type => () => {
    event(GA_EVENTS.USERACCOUNT, GA_ACTIONS.CLICK, { label: type });
    appInsights.trackEvent(
      {
        name:type
      },
      {
        EventCategory: GA_EVENTS.USERACCOUNT,
        EventAction: GA_ACTIONS.CLICK,
        EventLabel: type
      }
      )
    changeDisplay(type);
  };

  const handleChangeForgotPass = () => {
    event(GA_EVENTS.USERACCOUNT, GA_ACTIONS.CLICK, {
      label: formTypes.FORGOT_PASSWORD
    });
    appInsights.trackEvent(
      {
        name:formTypes.FORGOT_PASSWORD
      },
      {
        EventCategory: GA_EVENTS.USERACCOUNT,
        EventAction: GA_ACTIONS.CLICK,
        EventLabel: formTypes.FORGOT_PASSWORD
      }
      )
    changeDisplay(formTypes.FORGOT_PASSWORD);
  };

  const changeDisplay = type => {
    props.onSelect(
      type === formTypes.REGISTRATION
        ? 'registrationBodiamTitle'
        : 'genericLogin'
    );
    setActiveForm(type);
  };

  const handleRecoverInputUpdate = e =>
    setRegistrationData({
      ...registrationData,
      [e.target.name]: e.target.value
    });

  const handleSignIn = (email, password) => {
    event(GA_EVENTS.USERACCOUNT, GA_ACTIONS.SUBMIT, {
      label: formTypes.SIGN_IN
    });
    appInsights.trackEvent(
      {
        name:formTypes.SIGN_IN
      },
      {
        EventCategory: GA_EVENTS.USERACCOUNT,
        EventAction: GA_ACTIONS.CLICK,
        EventLabel: formTypes.SIGN_IN
      }
      )
    if (inBodiam()) {
      props.actions.signInUser(email, password, BODIAM_DASHBOARD);
    } else {
      props.actions.signInUserAndCreateClaim(email, password, TASKLIST);
    }
  };

  const handleSignUp = formData => {
    event(GA_EVENTS.USERACCOUNT, GA_ACTIONS.SUBMIT, {
      label: formTypes.REGISTRATION
    });
    appInsights.trackEvent(
      {
        name:formTypes.REGISTRATION
      },
      {
        EventCategory: GA_EVENTS.USERACCOUNT,
        EventAction: GA_ACTIONS.CLICK,
        EventLabel: formTypes.REGISTRATION
      }
      )
    props.actions.createUser(formData, TASKLIST, inBodiam());
  };

  const handleRecoverPasswordAction = () => {
    event(GA_EVENTS.USERACCOUNT, GA_ACTIONS.SUBMIT, {
      label: formTypes.FORGOT_PASSWORD
    });
    appInsights.trackEvent(
      {
        name:formTypes.FORGOT_PASSWORD
      },
      {
        EventCategory: GA_EVENTS.USERACCOUNT,
        EventAction: GA_ACTIONS.CLICK,
        EventLabel: formTypes.FORGOT_PASSWORD
      }
      )
    props.actions.userRecoverPassword(registrationData.email);
    handleChangeDisplay(formTypes.FORGOT_PASSWORD_SENT);
  };

  const displayContent = () => {
    switch (activeForm) {
      case formTypes.REGISTRATION:
        return (
          <RegistrationForm
            onSubmitForm={handleSignUp}
            inBodiam={inBodiam()}
            availableTitles={props.config.TITLES}
            showPersonalNumber={props.config.PERSONALNUMBER.enabled}
            policy={props.policy}
          />
        );

      case formTypes.SIGN_IN:
        return (
          <SignInForm
            onForgotPassword={handleChangeForgotPass}
            handleSignAction={handleSignIn}
          />
        );

      case formTypes.FORGOT_PASSWORD:
        return (
          <RecoverPasswordForm
            formValues={registrationData}
            onInputChange={handleRecoverInputUpdate}
            handleRecoverPasswordAction={handleRecoverPasswordAction}
          />
        );
      default:
        return '';
    }
  };

  const bodiamRegistrationParagraphs = (
    <Col>
      <h5>{t('bodiamRegistrationParagraph1')}</h5>
      <h5>{t('bodiamRegistrationParagraph2')}</h5>
      <br />
      <h5>{t('bodiamRegistrationParagraph3')}</h5>
      <h5>{t('bodiamRegistrationParagraph4')}</h5>
      <br />
    </Col>
  );

  const bodiamRegistrationParagraphFooter = (
    <h6>{t('bodiamRegistrationParagraph5')}</h6>
  );

  const registrationParagraphTitles = (
    <Col>
      <h4>{t('registrationFormTitle')}</h4>
      <h4>{t('registrationFormSubtitle')}</h4>
    </Col>
  );

  const logo = (
    <Col className="col-2 col-lg-1 mr-4">
      <img className="axa-logo-reg" alt="axa logo" src="assets/img/u35.png" />
    </Col>
  );

  const isInBodiam = inBodiam();

  return (
    <Row className="registration-page">
      <Col>
        <Row className="mb-5">
          {!isInBodiam && logo}
          {!isInBodiam && registrationParagraphTitles}
        </Row>
        <Row>
          {isInBodiam && bodiamRegistrationParagraphs}
          <Col>
            <label className="radio-container">
              <span className="font-weight-bold">
                {t('registrationFormNewAccount')}
              </span>
              <br />
              {t('registrationFormNewAccountSecondLine')}
              <input
                type="radio"
                name="radio"
                onClick={handleChangeDisplay(formTypes.REGISTRATION)}
              />
              <span className="checkmark" />
            </label>
            <label className="radio-container">
              <span className="font-weight-bold">
                {t('registrationFormUsedAccount')}
              </span>
              <br />
              {t('registrationFormUsedAccountSecondLine')}
              <input
                type="radio"
                name="radio"
                onClick={handleChangeDisplay(formTypes.SIGN_IN)}
              />
              <span className="checkmark" />
            </label>
          </Col>
        </Row>
        {isInBodiam && bodiamRegistrationParagraphFooter}
        {displayContent()}
      </Col>
    </Row>
  );
};

const mapStateToProps = state => ({
  claim: state.currentClaim,
  errorOnLogIn: state.authentication.errorMessageOnLogin,
  selectedLanguage: state.marketLanguages.selectedLanguage,
  config: state.configuration,
  policy: state.policy
});

const mapDispatchToProps = dispatch => ({
  actions: {
    ...bindActionCreators(
      {
        userRecoverPassword,
        addAlert,
        signInUserAndCreateClaim,
        signInUser,
        createUser
      },
      dispatch
    )
  }
});

RegistrationPage.propTypes = {
  actions: PropTypes.shape({
    userRecoverPassword: PropTypes.func,
    addAlert: PropTypes.func,
    createUserAndClaim: PropTypes.func,
    createUser: PropTypes.func,
    signInUser: PropTypes.func,
    signInUserAndCreateClaim: PropTypes.func
  }).isRequired,
  claim: PropTypes.shape({
    productId: PropTypes.number
  }),
  policy: PropTypes.shape({
    name: PropTypes.string,
    lastName: PropTypes.string,
    date: PropTypes.string
  }),
  onSelect: PropTypes.func,
  config: PropTypes.shape({
    TITLES: PropTypes.shape({
      enabled: PropTypes.bool.isRequired,
      values: PropTypes.arrayOf(PropTypes.string).isRequired
    }),
    PERSONALNUMBER: PropTypes.shape({
      enabled: PropTypes.bool.isRequired
    }).isRequired
  }).isRequired
};

RegistrationPage.defaultProps = {
  claim: {},
  onSelect: noop,
  policy: {}
};

export default connect(mapStateToProps, mapDispatchToProps)(RegistrationPage);
