import React from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

const PageTitle = props => (
  <Helmet>
    <title>{props.title}</title>
    <link
      rel="icon"
      type="image/png"
      href={`assets/img/${props.favicon}.ico`}
      sizes="16x16"
    />
  </Helmet>
);

function mapStateToProps(state) {
  return {
    title: state.configuration && state.configuration.PAGETITLE,
    favicon: state.configuration && state.configuration.FAVICON
  };
}

PageTitle.propTypes = {
  title: PropTypes.string,
  favicon: PropTypes.string
};

PageTitle.defaultProps = {
  title: null,
  favicon: null
};

export default connect(mapStateToProps)(PageTitle);
