import { object, string, number, date } from 'yup';
import moment from 'moment';
import { ERRORS } from 'constants/constants';
import { maxLength } from 'constants/inputsLengths';
import { isValidPersonalNumberNorway } from 'utils/validationHelper';

const dobAndPNSchemaValidation = {
  personalNumber: string().test(
    'Test personalNumber valid',
    ERRORS.PERSONAL_NUMBER_INCORRECT,
    value => (value ? isValidPersonalNumberNorway(value) : true)
  ),
  dob: date()
    .transform(rawValue => moment(rawValue).toDate())
    .max(moment(), ERRORS.DOB_INCORRECT)
    .required(ERRORS.INVALID_TEXT)
    .typeError(ERRORS.DOB_INCORRECT)
};
const personalNumberSchemaValidation = object({
  ...dobAndPNSchemaValidation
});

const schemaValidation = object({
  firstName: string()
    .max(maxLength.firstName)
    .required(ERRORS.INVALID_TEXT)
    .typeError(ERRORS.INVALID_TEXT),
  surnames: string()
    .max(maxLength.surnames)
    .required(ERRORS.INVALID_TEXT)
    .typeError(ERRORS.INVALID_TEXT),
  ...dobAndPNSchemaValidation,
  relationship: number()
    .required(ERRORS.INVALID_TEXT)
    .typeError(ERRORS.INVALID_TEXT),
  isSecondaryCardHolder: string().required(ERRORS.INVALID_TEXT),
  isCoLiving: string().required(ERRORS.INVALID_TEXT)
});

export { schemaValidation, personalNumberSchemaValidation };
