import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function currentStep(state = initialState.currentStep, action) {
  switch (action.type) {
    case types.UPDATE_CURRENT_STEP:
      return {
        step: action.step.step,
        lastStep: action.step.lastStep
      };

    case types.EMPTY_CURRENT_CLAIM:
      return initialState.currentStep;

    default:
      return state;
  }
}
