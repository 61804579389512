export const DEFAULT_EXPENSE_TYPES = {
  1: {
    label: 'expenseCategoryAccomodation',
    icon: 'local_hotel'
  },
  2: {
    label: 'expenseCategoryEntertainmentAndExcursions',
    icon: 'local_play'
  },
  3: {
    label: 'expenseCategoryPackageTrip',
    icon: ['local_hotel', 'flight_takeoff']
  },
  4: {
    label: 'expenseCategoryPersonalItems',
    icon: 'local_mall'
  },
  5: {
    label: 'expenseCategoryMedical',
    icon: 'local_hospital'
  },
  6: {
    label: 'expenseCategoryMoneyAndTravelDocuments',
    icon: ['attach_money', 'receipt']
  },
  7: {
    label: 'expenseCategoryTransport',
    icon: 'commute'
  },
  8: {
    label: 'expenseCategoryRefreshment',
    icon: 'restaurant'
  },
  9: {
    label: 'expenseCategoryCarRental',
    icon: 'directions_car'
  },
  10: {
    label: 'expenseCategoryLumpSum',
    icon: 'attach_money'
  }
};

export default { DEFAULT_EXPENSE_TYPES };
