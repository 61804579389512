export const get = Symbol('get');
export const set = Symbol('set');
export const isCheckOnSet = Symbol('isCheckOnSet');
export const startImplementations = Symbol('startImplementations');
export const ACTION_IMPLEMENTATIONS = Symbol('ACTION_IMPLEMENTATIONS');

export const AUTHENTICATION_ACTIONS = 'AuthenticationActions';
export const CLAIM_ACTIONS = 'ClaimsActions';
export const LABEL_ACTIONS = 'LabelActions';
export const ALERT_ACTIONS = 'AlertActions';
export const PRISMIC_ACTIONS = 'PrismicActions';
export const ACTIVE_PAGE_ACTIONS = 'ActivePageActions';
