import {
  VALIDATE_AND_APPEND_CARD_TO_TREE_REQUESTED,
  VALIDATE_AND_APPEND_CARD_NUMBER_TO_TREE_REQUESTED,
  APPEND_CARD_TO_TREE_ADD_CARD_SUCCESS,
  APPEND_CARD_TO_TREE_VALIDATION_SUCCEEDED,
  APPEND_CARD_TO_TREE_SUCCEEDED,
  validateCard,
  transformNumberAndValidate,
  setCardInformation,
  setActiveCard,
  addCardAndAppendToTree
} from 'actions/feature/card';
import { appendCreditCardTree } from 'actions/decisionTreeActions';
import { setInTriage } from 'actions/triageStatusActions';
import { isUserLoggedIn } from 'utils/sessionController';

export const validateAndAppendCardNumberToTreeFlow = ({
  dispatch
}) => next => action => {
  next(action);

  if (action.type === VALIDATE_AND_APPEND_CARD_NUMBER_TO_TREE_REQUESTED) {
    dispatch(
      transformNumberAndValidate(
        { ...action.payload },
        APPEND_CARD_TO_TREE_VALIDATION_SUCCEEDED,
        null,
        action.meta
      )
    );
  }
};

export const validateAndAppendCardToTreeFlow = ({
  dispatch
}) => next => action => {
  next(action);

  if (action.type === VALIDATE_AND_APPEND_CARD_TO_TREE_REQUESTED) {
    dispatch(
      validateCard(
        action.payload.number,
        action.payload.obfuscated,
        APPEND_CARD_TO_TREE_VALIDATION_SUCCEEDED,
        null,
        action.meta
      )
    );
  }
};

const isCardNew = (cardsList, gencode) => {
  return !cardsList.filter(c => c.number === gencode).length;
};

export const validationSucceededAndAddCardAndAppendCardToTreeFlowRequested = ({
  dispatch,
  getState
}) => next => action => {
  next(action);

  if (action.type === APPEND_CARD_TO_TREE_VALIDATION_SUCCEEDED) {
    // We need to check if the user is looged in because the user can start with a claim and doesn't have an account
    if (
      isUserLoggedIn() &&
      isCardNew(getState().cardsList, action.payload.gencode)
    ) {
      dispatch(
        addCardAndAppendToTree(
          action.payload.gencode,
          action.payload.obfuscated,
          action.payload.productId,
          action.meta.questionId
        )
      );
    } else {
      dispatch({
        type: APPEND_CARD_TO_TREE_ADD_CARD_SUCCESS,
        payload: {
          number: action.payload.gencode,
          obfuscated: action.payload.obfuscated
        },
        meta: { ...action.meta, productId: action.payload.productId }
      });
    }
  }
};

export const addedCardSuccessAppendCardToTreeFlowRequested = ({
  dispatch
}) => next => action => {
  next(action);

  if (action.type === APPEND_CARD_TO_TREE_ADD_CARD_SUCCESS) {
    dispatch(
      setCardInformation(
        action.meta.productId,
        action.payload.number,
        action.payload.obfuscated
      )
    );
    dispatch(setActiveCard(action.payload.number));
    dispatch(
      appendCreditCardTree(
        action.meta.productId,
        action.meta.questionId,
        action.payload.number
      )
    );
    dispatch(setInTriage());
    dispatch({ type: APPEND_CARD_TO_TREE_SUCCEEDED });
  }
};

export default [
  addedCardSuccessAppendCardToTreeFlowRequested,
  validationSucceededAndAddCardAndAppendCardToTreeFlowRequested,
  validateAndAppendCardNumberToTreeFlow,
  validateAndAppendCardToTreeFlow
];
