import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useTranslation } from 'react-i18next';

import { userResetPassword } from 'actions/authenticationActions';
import {
  gotoLandingPage,
  gotoCookiesPage,
  gotoFAQPage,
  gotoContactsPage
} from 'actions/activePageActions';

import { Row, Col } from 'components/shared/Table';
import { Button } from 'components/shared/Forms';
import RegistrationPasswordComponent from 'components/RegistrationForm/RegistrationPasswordComponent';
import LayoutPage from 'components/LayoutPage';

import { pageview, event } from 'utils/analytics';
import { getRoute } from 'utils/routeHelper';

import { GA_EVENTS, GA_ACTIONS } from 'constants/googleAnalytics';
import { appInsights } from 'ApplicationInsights';

export const ResetPasswordPage = props => {
  const { t } = useTranslation();
  const [password, setPassword] = useState('');
  const [validated, setValidated] = useState(false);

  useEffect(() => {
    pageview({ title: 'User', page: getRoute('user', 'resetPassword') });
    appInsights.trackPageView(
      { name: 'User', 
      uri: getRoute('user', 'resetPassword') }
    )
  }, []);

  const handleResetPasswordAction = e => {
    e.preventDefault();
    const urlFields = window.location.pathname.split('/');
    const recoverIndex = urlFields.findIndex(
      f => f.toLowerCase() === 'recover'
    );

    let token = '';
    if (recoverIndex > 0) {
      token = urlFields[recoverIndex - 1];
    }
    event(GA_EVENTS.USERACCOUNT, GA_ACTIONS.CLICK, { label: 'resetPassword' });
    appInsights.trackEvent(
      {
        name:'resetPassword'
      },
      {
        EventCategory: GA_EVENTS.USERACCOUNT,
        EventAction: GA_ACTIONS.CLICK,
        EventLabel: 'resetPassword'
      }
      )
    props.actions.userResetPassword(password, token, props.match.params.app);
  };

  const handleRecoverInputUpdate = e => setPassword(e.target.value);
  const handlePasswordValidationEnd = isValid => setValidated(isValid);

  const labelsRegistrationPasswordComponent = {
    passwordErrorNoUppercase: t('passwordErrorNoUppercase'),
    passwordErrorNoLowercase: t('passwordErrorNoLowercase'),
    passwordErrorNoDigit: t('passwordErrorNoDigit'),
    passwordErrorNoMinLength: t('passwordErrorNoMinLength'),
    passwordErrorHasSpaces: t('passwordErrorHasSpaces'),
    passwordErrorNoSymbol: t('passwordErrorNoSymbol'),
    registrationPasswordsNotMatch: t('registrationPasswordsNotMatch'),
    commonFromPasswordConfrimLabel: t('commonFromPasswordConfrimLabel')
  };

  return (
    <Row className="registration-page">
      <Col>
        <LayoutPage
          Title={t('RecoverTitle')}
          SubTitle={t('RecoverSubTitle')}
          topHeaderIsVisible
          headerFooterVisibles
          actions={props.actions}
          hasBackground={false}
        >
          <form className="mt-4">
            <Col>
              <RegistrationPasswordComponent
                label={t('commonFromPasswordnewLabel')}
                value={password}
                onValidationExit={handlePasswordValidationEnd}
                onInputChange={handleRecoverInputUpdate}
                name="password"
                labels={labelsRegistrationPasswordComponent}
                placeholder={t('commonFormForgotPasswordPlaceholder')}
                helpText={t('commonFromPasswordComplexityErrorMsg')}
                type="password"
                ariaDescribedby={t('commonFromPasswordnewLabel')}
              />

              <Row>
                <Col>
                  <Button
                    type="submit"
                    className="btn btn-primary btn-lg btn-block btn-amex"
                    onClick={handleResetPasswordAction}
                    disabled={!validated}
                  >
                    {t('formResetPasswordbtnLabel')}
                  </Button>
                </Col>
              </Row>
            </Col>
          </form>
        </LayoutPage>
      </Col>
    </Row>
  );
};

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
  actions: {
    ...bindActionCreators(
      {
        userResetPassword,
        gotoLandingPage,
        gotoCookiesPage,
        gotoFAQPage,
        gotoContactsPage
      },
      dispatch
    )
  }
});

ResetPasswordPage.propTypes = {
  actions: PropTypes.shape({
    userResetPassword: PropTypes.func,
    gotoLandingPage: PropTypes.func,
    gotoContactsPage: PropTypes.func,
    gotoFAQPage: PropTypes.func,
    gotoCookiesPage: PropTypes.func
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      app: PropTypes.string.isRequired
    }).isRequired
  }).isRequired
};

ResetPasswordPage.defaultProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordPage);
