import { ALERT_TYPES, SIGNIN_PAGE, APPS } from 'constants/constants';

import authenticationApi from 'api/authenticationApi';
import claimsApi from 'api/claimsApi';
import CreditCardApi from 'api/creditCardApi';

import actionProvider from 'utils/action-provider';

import { removeToken, getActiveCardIndex } from 'utils/sessionController';
import { getMarketFromUrl, inBodiam, fromBodiam } from 'utils/urlHelpers';

import {
  redirectToArundel,
  redirectToBodiam,
  redirectToBodiamLogin,
  redirectToArundelWithReload,
  redirectToBodiamWithReload
} from 'utils/historyHelper';

import PolicyApi from 'api/policyApi';
import { setNotInTriage } from 'actions/triageStatusActions';
import setActiveCard, { setActiveCardCookies } from 'actions/activeCardActions';
import { beginAjaxCall, ajaxCallError } from 'actions/ajaxStatusActions';
import * as types from 'actions/actionTypes';
import { cleanCardInformation } from 'actions/feature/card';
import { setActivePolicy } from 'actions/feature/policy';

export function setCurrentAccount(account) {
  return { type: types.SET_CURRENT_ACCOUNT, account };
}

export function cleanCurrentAccount() {
  return { type: types.CLEAN_CURRENT_ACCOUNT };
}

export function setClaimsList(claims) {
  return { type: types.SET_CLAIMS_LIST, claims };
}

// TODO: move to card middl
export function setCardsList(cards) {
  return { type: types.SET_CARDS_LIST, cards };
}

export function setPoliciesList(policies) {
  return { type: types.SET_POLICIES_LIST, policies };
}

function resetPasswordSuccess() {
  return { type: types.RESET_PASSWORD_SUCCESS };
}

export function handleResetPasswordResponse(data, app) {
  return (dispatch, getState) => {
    if (!data.errorCode) {
      const msg = getState().labels.formResetPasswordResetSuccesfullmsg;
      dispatch(
        actionProvider.getAlertActions().addAlert(ALERT_TYPES.SUCCESS, msg)
      );
      if (
        inBodiam() ||
        fromBodiam() ||
        app.toLocaleLowerCase() === APPS.BODIAM
      ) {
        redirectToBodiam(getMarketFromUrl());
      } else {
        redirectToArundel(getMarketFromUrl());
        dispatch(actionProvider.getActivePageActions().gotoSignIn());
      }
      dispatch(resetPasswordSuccess());
    } else {
      const msg = getState().labels[data.errorCode];
      dispatch(
        actionProvider.getAlertActions().addAlert(ALERT_TYPES.ERROR, msg)
      );
      setTimeout(() => {
        window.location.reload();
      }, 5000);
    }
  };
}

export function userRecoverPasswordSuccess(lang = null) {
  return (dispatch, getState) => {
    const msg = getState().labels.ForgotPasswardEmailSent;
    dispatch(
      actionProvider.getAlertActions().addAlert(ALERT_TYPES.SUCCESS, msg)
    );
    if (inBodiam()) {
      redirectToBodiam(getMarketFromUrl(), lang);
    } else {
      dispatch(actionProvider.getActivePageActions().gotoSignIn());
    }
  };
}

export function signInSuccess() {
  return { type: types.SIGN_IN_SUCCESS };
}

export function setCurrentSuccess() {
  return { type: types.SET_CURRENT_ACCOUNT_SUCCESS };
}

export function getUserInfo() {
  return (dispatch, getState) => {
    dispatch(beginAjaxCall());
    return authenticationApi
      .getUserInfo()
      .then(account => {
        const { market } = getState().configuration;
        dispatch(setCurrentAccount(account));
        if (
          market &&
          account.marketURL.toLowerCase() !== market.toLocaleLowerCase()
        ) {
          const userMarket = account.marketURL;
          if (inBodiam()) {
            redirectToBodiamWithReload(userMarket);
          } else {
            redirectToArundelWithReload(userMarket);
          }
        }

        dispatch(setCurrentSuccess());
      })
      .catch(error => {
        dispatch(ajaxCallError());
        throw error;
      });
  };
}

// TODO: move card middle
export function getCardsSuccess() {
  return { type: types.GET_CARDS_SUCCESS };
}
// TODO: move card middle
export function getCardsFailure() {
  return { type: types.GET_CARDS_FAILURE };
}
// TODO: move card middle
export function getUserCards() {
  return async dispatch => {
    dispatch(beginAjaxCall());

    await CreditCardApi.getUserCards()
      .then(cards => {
        const cardsWithNames = [];
        for (let i = 0; i < cards.length; i += 1) {
          cardsWithNames[i] = {
            ...cards[i],
            realName: cards[i].product
          };
        }
        dispatch(setCardsList(cardsWithNames));
        if (inBodiam()) {
          let index = getActiveCardIndex() || 0;
          if (!cardsWithNames[index]) {
            index = 0;
          }
          dispatch(setActiveCardCookies(cardsWithNames[index], index));
        }
        dispatch(getCardsSuccess());
      })
      .catch(error => {
        dispatch(getCardsFailure());
        dispatch(ajaxCallError());
        throw error;
      });
  };
}

export function getPoliciesSuccess() {
  return { type: types.GET_POLICIES_SUCCESS };
}

export function getPoliciesFailure() {
  return { type: types.GET_POLICIES_FAILURE };
}

export function getUserPolicies() {
  return dispatch => {
    dispatch(beginAjaxCall());
    return authenticationApi
      .getUserPolicies()
      .then(policies => {
        dispatch(setPoliciesList(policies));
        dispatch(getPoliciesSuccess());
      })
      .catch(error => {
        dispatch(getPoliciesFailure());
        dispatch(ajaxCallError());
        throw error;
      });
  };
}

export function getClaimsSuccess() {
  return { type: types.CLAIMS_LIST_SUCCESS };
}

export function getClaimsFailure() {
  return { type: types.CLAIMS_LIST_FAILURE };
}

export function getUserClaims() {
  return dispatch => {
    dispatch(beginAjaxCall());
    return claimsApi
      .getClaims()
      .then(claims => {
        dispatch(setClaimsList(claims));
        dispatch(getClaimsSuccess());
      })
      .catch(error => {
        dispatch(getClaimsFailure());
        dispatch(ajaxCallError());
        throw error;
      });
  };
}

export const onSignInAccepted = () => dispatch =>
  Promise.all([
    dispatch(getUserInfo()),
    dispatch(getUserCards()),
    dispatch(getUserPolicies()),
    dispatch(getUserClaims()),
    dispatch(setActiveCard(null)),
    dispatch(setActivePolicy(null))
  ]);

export function userRecoverPassword(email) {
  return (dispatch, getState) => {
    const lang = getState().marketLanguages.selectedLanguage;
    dispatch(beginAjaxCall());
    return authenticationApi
      .recoverPassword(email, lang)
      .then(token => {
        dispatch(signInSuccess(token));
        dispatch(userRecoverPasswordSuccess(lang));
      })
      .catch(error => {
        dispatch(actionProvider.getAlertActions().handleApiError(error));
        dispatch(ajaxCallError());
      });
  };
}

export function userResetPassword(password, token, app) {
  return dispatch => {
    dispatch(beginAjaxCall());
    return authenticationApi
      .resetPassword(password, token)
      .then(data => {
        removeToken();
        dispatch(cleanCurrentAccount());
        dispatch(setClaimsList([]));
        dispatch(setCardsList([]));
        dispatch(setPoliciesList([]));
        dispatch(handleResetPasswordResponse(data, app));
      })
      .catch(error => {
        dispatch(actionProvider.getAlertActions().handleApiError(error));
        dispatch(ajaxCallError());
      });
  };
}
// TODO: move card middle
export function getCardSuccess() {
  return { type: types.CLAIMS_LIST_SUCCESS };
}

export function getPolicySuccess() {
  return { type: types.POLICY_DETAIL_SUCCESS };
}

export function setProductDetail(card) {
  return { type: types.SET_PRODUCT_DETAIL, card };
}
// TODO: move card middle
// We are using it in CardsCarousel, MyClaimsPage
export function getCardDetails(id, toggleCardProfile) {
  return (dispatch, getState) => {
    dispatch(beginAjaxCall());
    const lang = getState().marketLanguages.selectedLanguage;
    return authenticationApi
      .getCard(id, lang)
      .then(card => {
        dispatch(setProductDetail(card));
        dispatch(getCardSuccess());
        toggleCardProfile();
      })
      .catch(() => {
        dispatch(ajaxCallError());
      });
  };
}

// We are unsing it in MyClaimsPage
export function getPolicyDetails(id, togglePolicyProfile) {
  return (dispatch, getState) => {
    dispatch(beginAjaxCall());
    const lang = getState().marketLanguages.selectedLanguage;

    return authenticationApi
      .getPolicy(id, lang)
      .then(policy => {
        dispatch(setProductDetail(policy));
        dispatch(getPolicySuccess());
        togglePolicyProfile();
      })
      .catch(() => {
        dispatch(ajaxCallError());
      });
  };
}

export function updateProfileSuccess() {
  return { type: types.UPDATE_PROFILE_SUCCESS };
}

export function userUpdateProfile(userInfo, msg) {
  return dispatch => {
    dispatch(beginAjaxCall());
    return authenticationApi
      .userUpdateProfile(userInfo)
      .then(updated => {
        if (updated) {
          dispatch(setCurrentAccount(userInfo));
          dispatch(
            actionProvider.getAlertActions().addAlert(ALERT_TYPES.SUCCESS, msg)
          );
        }
        dispatch(updateProfileSuccess());
      })
      .catch(error => {
        dispatch(actionProvider.getAlertActions().handleApiError(error));
        dispatch(ajaxCallError());
      });
  };
}
// TODO: move card middle
export function deleteCardSuccess() {
  return { type: types.DELETE_CARD_SUCCESS };
}
// TODO: move card middle
export function deleteCardAction(id, toggleCardProfile, msg) {
  return (dispatch, getState) => {
    dispatch(beginAjaxCall());
    return CreditCardApi.deleteCardApi(id)
      .then(() => {
        dispatch(
          actionProvider.getAlertActions().addAlert(ALERT_TYPES.SUCCESS, msg)
        );
        dispatch(deleteCardSuccess());
        dispatch(getUserCards());
        toggleCardProfile();
      })
      .catch(error => {
        dispatch(actionProvider.getAlertActions().handleApiError(error));
        dispatch(ajaxCallError());
      });
  };
}

export function deletePolicySuccess() {
  return { type: types.DELETE_POLICY_SUCCESS };
}

export function deletePolicyAction(id, togglePolicyProfile, msg) {
  return dispatch => {
    dispatch(beginAjaxCall());
    return PolicyApi.deletePolicyApi(id)
      .then(() => {
        dispatch(
          actionProvider.getAlertActions().addAlert(ALERT_TYPES.SUCCESS, msg)
        );
        dispatch(deletePolicySuccess());
        dispatch(getUserPolicies());
        togglePolicyProfile();
      })
      .catch(error => {
        dispatch(actionProvider.getAlertActions().handleApiError(error));
        dispatch(ajaxCallError());
      });
  };
}

function logOutSuccess() {
  return { type: types.LOG_OUT_SUCCESS };
}

export function onLogout(redirectArundelPage = SIGNIN_PAGE) {
  return (dispatch, getState) => {
    removeToken();
    const { bodiamAction } = getState();
    if (inBodiam() || (bodiamAction && bodiamAction.length)) {
      redirectToBodiamLogin(getMarketFromUrl());
    } else {
      dispatch(
        actionProvider
          .getActivePageActions()
          .updateActivePage(redirectArundelPage)
      );
    }

    dispatch(
      actionProvider
        .getAlertActions()
        .addAlert(
          ALERT_TYPES.SUCCESS,
          getState().labels.genericLogOutSuccessful
        )
    );

    // removing old user information from global state
    dispatch(cleanCurrentAccount());
    dispatch(setClaimsList([]));
    dispatch(setCardsList([]));
    dispatch(setPoliciesList([]));
    dispatch(setActiveCard(null));
    dispatch(setActivePolicy(null));
    dispatch(setNotInTriage());
    dispatch(logOutSuccess());
    dispatch(cleanCardInformation());
  };
}
