import { string } from 'yup';
import { REGEX } from 'constants/constants';

export const minLengthPassword = 8;
const maxLengthEmail = 50;
export const invalidTextLabel = 'isInValidText';

export const commonEmailValidations = {
  email: string()
    .max(maxLengthEmail)
    .required(invalidTextLabel)
    .matches(REGEX.VALIDATE_EMAIL, 'commonFromEmailValidErrorMsg')
};

export const commonPasswordValidations = {
  password: string()
    .min(minLengthPassword, 'passwordErrorNoMinLength')
    .required(invalidTextLabel)
};
