import React from 'react';
import PropTypes from 'prop-types';

const Label = props => (
  <strong>{`${props.text}${props.isRequired ? ' *' : ''}`}</strong>
);

Label.propTypes = {
  text: PropTypes.string.isRequired,
  isRequired: PropTypes.bool
};

Label.defaultProps = {
  isRequired: false
};

export default Label;
