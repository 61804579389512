/* eslint-disable react/no-danger */
import { appInsights } from 'ApplicationInsights';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { pageview } from 'utils/analytics';
import { getRoute } from 'utils/routeHelper';

export const ContactsPage = () => {
  const { t } = useTranslation();

  useEffect(() => {
    pageview({ title: 'Contacts', page: getRoute('contacts') });
    appInsights.trackPageView(
      {
        name:'Contacts',
        uri: getRoute('contacts')
      }
    )
  }, []);

  const emailText = t('contactUsPageEmail');
  return (
    <div className="row no-gutters contact-page-box">
      <div className="contact-page pt-4">
        <div className="col">
          <h4>{t('contactUsPageTitle')}</h4>
        </div>
        <div className="contact-box mb-4 p-4 mt-4">
          <span>{t('contactUsPageMessage')}</span>
          <ul>
            <li dangerouslySetInnerHTML={{ __html: emailText }} />
            <li>{t('contactUsPageTelephone')}</li>
          </ul>
        </div>
        <div className="contact-box mb-4 p-4 mt-4">
          <span>{t('contactUsPageOpeningHours')}</span>
        </div>
      </div>
    </div>
  );
};

export default ContactsPage;
