export const VALIDATE_DETAILS_POLICY_REQUESTED =
  '[policy] validate & get details';
export const DETAILS_POLICY_REQUESTED = '[policy] get';
export const DETAILS_POLICY_SUCCEEDED = '[policy] get success';
export const DETAILS_POLICY_FAILED = '[policy] get fail';

export const CLEAN_POLICY_DETAIL = '[policy] clean details';
export const SET_POLICY_DETAIL = '[policy] set details';
export const SET_ACTIVE_POLICY = '[policy] set as active';

export const VALIDATE_POLICY_REQUESTED = '[policy] validate';
export const VALIDATE_POLICY_SUCCEEDED = '[policy] validation success';
export const VALIDATE_POLICY_FAILED = '[policy] validation fail';

export const VALIDATE_ADD_POLICY_REQUESTED = '[policy] validate & add policy';
export const ADD_POLICY_REQUESTED = '[policy] add';
export const ADD_POLICY_SUCCEEDED = '[policy] add success';
export const ADD_POLICY_FAILED = '[policy] add fail';

export const VAIDATE_APPEND_POLICY_TREE_REQUESTED =
  '[policy] validate & append to tree';
export const APPEND_POLICY_TO_TREE_ADD_POLICY_SUCCESS =
  '[policy] add policy success & append to tree requested';
export const APPEND_POLICY_TO_TREE_VALIDATION_SUCCEEDED =
  '[policy] append to tree validation success';

export const APPEND_POLICY_TREE_REQUESTED = '[policy] append to tree';
export const APPEND_POLICY_TREE_SUCCEEDED = '[policy] append to tree success';
export const APPEND_POLICY_TREE_FAILED = '[policy] append to tree fail';

export const SELECT_POLICY_REQUESTED = '[policy] selected';
export const SET_POLICY_VALIDATION_RESULT = '[policy] validation result';
export const CLEAN_POLICY_VALIDATION_RESULT = '[policy] validation clean';

export const getDetails = (number, name, surname, date) => ({
  type: VALIDATE_DETAILS_POLICY_REQUESTED,
  payload: { number, name, surname, date }
});

export const addPolicyToUser = (number, name, surname, date, redirectTo) =>
  addPolicyToUserEvent(number, name, surname, date, null, null, redirectTo);

export const addPolicyToUserEvent = (
  number,
  name,
  surname,
  date,
  onSuccess,
  onFail,
  redirectTo
) => ({
  type: VALIDATE_ADD_POLICY_REQUESTED,
  payload: { number, name, surname, date },
  meta: { redirectTo, onAddPolicySuccess: onSuccess, onAddPolicyFail: onFail }
});

export const appendPolicyToTree = (
  number,
  name,
  surname,
  date,
  questionId
) => ({
  type: VAIDATE_APPEND_POLICY_TREE_REQUESTED,
  payload: { number, name, surname, date },
  meta: { questionId }
});

export const addPolicyAndAppendToTree = (
  number,
  name,
  surname,
  date,
  productId,
  questionId
) => ({
  type: ADD_POLICY_REQUESTED,
  payload: { number, name, surname, date },
  meta: {
    productId,
    questionId,
    onAddPolicySuccess: APPEND_POLICY_TO_TREE_ADD_POLICY_SUCCESS,
    onAddPolicyFail: null
  }
});

export const cleanDetails = () => ({
  type: CLEAN_POLICY_DETAIL
});

export const selectPolicy = (number, name, surname, date) => ({
  type: SELECT_POLICY_REQUESTED,
  payload: { number, name, surname, date }
});

export const setPolicyValidationResult = (isValid, message) => ({
  type: SET_POLICY_VALIDATION_RESULT,
  payload: { isValid, message }
});

export const cleanPolicyValidationResult = () => ({
  type: CLEAN_POLICY_VALIDATION_RESULT
});

export const setPolicyDetails = policy => ({
  type: SET_POLICY_DETAIL,
  payload: { policy }
});

export const validatePolicy = (
  number,
  name,
  surname,
  date,
  onSuccess,
  onError,
  meta
) => ({
  type: VALIDATE_POLICY_REQUESTED,
  payload: { number, name, surname, date },
  meta: { ...meta, onValidationSuccess: onSuccess, onValidationFail: onError }
});

export const setActivePolicy = policyNumber => ({
  type: SET_ACTIVE_POLICY,
  payload: policyNumber
});
