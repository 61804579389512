import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import CustomModal from 'shared/components/CustomModal';

const PolicyNumberValidationResultModal = ({
  modalErrorMessage,
  modalContentLabel,
  closeModal
}) => {
  const { t } = useTranslation();

  return (
    <CustomModal
      isOpen={modalErrorMessage !== ''}
      contentLabel={modalContentLabel}
      shouldCloseOnOverlayClick={false}
      overlayClassName="overlay"
      className="modal-frame"
      handleConfirmationOk={closeModal}
      description={modalErrorMessage}
      labelOk={t('commonOk')}
    />
  );
};

PolicyNumberValidationResultModal.propTypes = {
  modalErrorMessage: PropTypes.string.isRequired,
  modalContentLabel: PropTypes.string.isRequired,
  closeModal: PropTypes.func.isRequired
};

export default PolicyNumberValidationResultModal;
