import * as authenticationActions from './actions/authenticationActions';
import * as claimActions from './actions/claimActions';
import * as labelActions from './actions/labelActions';
import * as alertActions from './actions/alertsActions';
import * as prismicActions from './actions/prismicActions';
import * as activePageActions from './actions/activePageActions';
import actionProvider from './utils/action-provider';

const startActionProvider = () => {
  actionProvider.setAlertActions(alertActions);
  actionProvider.setAuthenticationActions(authenticationActions);
  actionProvider.setClaimActions(claimActions);
  actionProvider.setLabelActions(labelActions);
  actionProvider.setPrismicActions(prismicActions);
  actionProvider.setActivePageActions(activePageActions);
};

export default startActionProvider;
