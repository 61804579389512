import React, { useState } from 'react';
import Proptypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green, blue } from '@material-ui/core/colors';
import { useDispatch, useSelector } from 'react-redux';
import { downloadFile, resetFileStatus } from 'actions/feature/file';
import getFile from 'store/selectors/files';
import CheckIcon from '@material-ui/icons/Check';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';

export const styles = theme => ({
  root: {
    display: 'flex',
    alignItems: 'center'
  },
  wrapper: {
    margin: theme.spacing.unit,
    position: 'relative'
  },
  buttonDownload: {
    backgroundColor: blue[800]
  },
  buttonSuccess: {
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700]
    }
  },
  button: {
    color: 'white',
    height: 38,
    width: 38
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  }
});

export const DownloadButton = ({ fileId, claimId, classes }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const dispatch = useDispatch();
  const file = useSelector(getFile(fileId));
    
  const buttonClassname = clsx(classes.button, {
    [classes.buttonDownload]: !success,
    [classes.buttonSuccess]: success
  });

  React.useEffect(() => {
    if (!file) return;
    setLoading(file.loading);
    setSuccess(file.downloaded);
  }, [file]);

  React.useEffect(() => {
    if (success) {
      window.setTimeout(() => {
        setSuccess(false);
        dispatch(resetFileStatus(fileId));
      }, 5000);
    }
  });

  const handleDownloadClick = () => {
    if (!loading) {
      setSuccess(false);
      setLoading(true);
        dispatch(downloadFile(fileId, claimId));

    }
  };    

        return (
            <div className={classes.root}>
                <div className={classes.wrapper}>
                    <Tooltip
                        id="downloadTootip"
                        title={t('downloadButtonTooltip')}
                        placement="right"
                        open={success}
                        onOpen={() => success}
                        onClose={() => !success}
                    >
                        
                        <IconButton
                            id="downloadButton"                            
                            className={buttonClassname}                            
                            disabled={loading}
                            onClick={handleDownloadClick}
                        >
                            {success ? <CheckIcon /> :  <CloudDownloadIcon />}
                        </IconButton>                        
                    </Tooltip>
                    {loading && (
                        <CircularProgress size={24} className={classes.buttonProgress} />
                    )}
                </div>
            </div>
        );
};

DownloadButton.propTypes = {
  fileId: Proptypes.string.isRequired,
  claimId: Proptypes.string.isRequired,
  classes: Proptypes.shape({
    root: Proptypes.shape({}),
    wrapper: Proptypes.shape({}),
    buttonDownload: Proptypes.shape({}),
    buttonSuccess: Proptypes.shape({}),
    button: Proptypes.shape({}),
    buttonProgress: Proptypes.shape({})
  }).isRequired
};

export default withStyles(styles)(DownloadButton);
