import React from 'react';
import PropTypes from 'prop-types';

const HowToClaimLearnMore = props => (
  <div className="row">
    <div className="col-10 offset-1">
      <button
        type="submit"
        className="btn btn-primary btn-block amex-learn p-3 "
        onClick={() => props.onLearnMoreAction()}
      >
        <i className="material-icons md-24 align-middle ">help</i>
        <span className="align-middle">{`  ${props.title}`}</span>
      </button>
    </div>
    <div className="col-12 text-center mt-4">{props.learnMoreFooter}</div>
  </div>
);

HowToClaimLearnMore.propTypes = {
  title: PropTypes.string.isRequired,
  onLearnMoreAction: PropTypes.func.isRequired,
  learnMoreFooter: PropTypes.string.isRequired
};

export default HowToClaimLearnMore;
