import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import Slide from '@material-ui/core/Slide';

import { EXPENSES_SECTION } from 'constants/claim';

import ExpensesSection from './ExpensesSectionComponents/ExpensesSection';
import PurchasesSection from './PurchasesSectionComponents/PurchasesSection';

const ExpensesComponent = props => {
  const [activeSection, setActiveSection] = useState(
    props.expenses.length ? 'purchases' : 'beneficiaries'
  );
  const [contacts, setContacts] = useState(props.contacts || []);
  const [categories, setCategories] = useState({});

  useEffect(() => {
    setCategories(getCategoriesFromExpenses());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getCategoriesFromExpenses = () => {
    if (props.expenses && props.expenses.length > 0) {
      const cats = {};
      props.expenses.map(cat => {
        cats[cat.type] = cat.type;
        return null;
      });

      return cats;
    }
    return {};
  };

  const handleClick = title => {
    const newCategories = { ...categories };

    if (newCategories[title]) {
      delete newCategories[title];
    } else {
      newCategories[title] = title;
    }

    setCategories(newCategories);
  };

  const updateContacts = newContacts => {
    setContacts(newContacts);
    props.updateContacts(newContacts);
  };

  const updateExpenses = newExpenses => {
    props.updateExpenses(newExpenses);
  };

  const updateExpensesAndContinue = () => props.saveClaimAndContinue();

  const handleSection = section => {
    setActiveSection(section);
    props.setDisplaySection(section);
  };

  const handleDisplayPurchases = () => {
    handleSection(
      activeSection === EXPENSES_SECTION.beneficiaries
        ? EXPENSES_SECTION.purchases
        : EXPENSES_SECTION.beneficiaries
    );
  };

  const beneficiariesSection = (
    <ExpensesSection
      typeOfExpenses={props.typeOfExpenses}
      claimId={props.claimId}
      handleDisplayPurchases={handleDisplayPurchases}
      handleClick={handleClick}
      categories={categories}
      contacts={contacts}
      updateContacts={updateContacts}
      languageId={props.languageId}
    />
  );

  const purchasesSection = (
    <Slide in direction="up" mountOnEnter unmountOnExit>
      <div className="col">
        <PurchasesSection
          showHowPaid={props.showHowPaid}
          handleDisplayPurchases={handleDisplayPurchases}
          categories={categories}
          expenses={props.expenses}
          updateExpenses={updateExpenses}
          updateExpensesAndContinue={updateExpensesAndContinue}
          benefitId={props.benefitId}
          claimId={props.claimId}
        />
      </div>
    </Slide>
  );

  return (
    <div className="row no-gutters">
      {activeSection === EXPENSES_SECTION.beneficiaries && beneficiariesSection}
      {activeSection === EXPENSES_SECTION.purchases && purchasesSection}
    </div>
  );
};

ExpensesComponent.propTypes = {
  typeOfExpenses: PropTypes.arrayOf(PropTypes.number).isRequired,
  claimId: PropTypes.string.isRequired,
  benefitId: PropTypes.number.isRequired,
  contacts: PropTypes.arrayOf(PropTypes.shape({})),
  expenses: PropTypes.arrayOf(PropTypes.shape({})),
  updateContacts: PropTypes.func.isRequired,
  updateExpenses: PropTypes.func.isRequired,
  setDisplaySection: PropTypes.func.isRequired,
  showHowPaid: PropTypes.string.isRequired,
  saveClaimAndContinue: PropTypes.func.isRequired,
  languageId: PropTypes.number.isRequired
};

ExpensesComponent.defaultProps = {
  contacts: [],
  expenses: []
};

export default ExpensesComponent;
