import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function languages(
  state = initialState.marketLanguages,
  action
) {
  switch (action.type) {
    case types.SET_SELECTED_LANGUAGE_SUCCESS:
      return { ...state, selectedLanguage: action.selectedLanguage };

    default:
      return state;
  }
}
