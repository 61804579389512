export const APP_GO_TO_REQUESTED = '[app] go to';
export const APP_GO_TO_BODIAM_REQUESTED = '[app] go to bodiam to';

export const goTo = page => ({
  type: APP_GO_TO_REQUESTED,
  payload: page
});

export const goToBodiamTo = page => ({
  type: APP_GO_TO_BODIAM_REQUESTED,
  payload: page
});
