import React, { useState, useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { loadLanguagesAndDefaultLabels } from 'actions/labelActions';
import setAppConfiguration from 'actions/configurationActions';
import {
  getMarketFromLocalStorage,
  setMarketInLocalStorage
} from 'utils/sessionController';

const ConfigurationContext = React.createContext(null);
ConfigurationContext.displayName = 'Configuration';

export const useConfigurationContext = () => useContext(ConfigurationContext);

export const useMarketConfiguration = marketCode => {
  const [market, setMarket] = useState(() => {
    if (marketCode) return marketCode;
    return getMarketFromLocalStorage();
  });
  const [config, setConfig] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchConfigAsync = async m => {
      const configResponse = await fetch(
        `assets/config/${m.toLowerCase()}.json`
      );
      const marketConfiguration = await configResponse.json();
      dispatch(setAppConfiguration({ market, ...marketConfiguration }));
      dispatch(loadLanguagesAndDefaultLabels(market));
      setConfig({ market, ...marketConfiguration });
    };

    fetchConfigAsync(market);
  }, [market, dispatch]);

  useEffect(() => {
    setMarketInLocalStorage(market);
  }, [market]);

  return [config, setMarket];
};

export const { Provider, Consumer } = ConfigurationContext;

export default ConfigurationContext;
