import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'components/shared/Table';
import { Button } from 'components/shared/Forms';

function BackgroundLayout({
  children,
  title,
  onBackButtonClick,
  backButtonText
}) {
  return (
    <Row className="no-gutters">
      <Col>
        <Row className="mb-3">
          <Col className="col-2 col-lg-1 mr-4">
            <img
              className="axa-logo-reg"
              alt="axa logo"
              src="assets/img/u35.png"
            />
          </Col>
          <Col className="pl-3 ml-3">
            <h4>{title}</h4>
          </Col>
        </Row>
        <Row className="no-gutters">
          <Col>{children}</Col>
        </Row>
        <Row className="no-gutters">
          <Col>
            <Button
              type="button"
              className="btn btn-light btn-sm"
              onClick={onBackButtonClick}
              text={backButtonText}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

BackgroundLayout.propTypes = {
  children: PropTypes.element.isRequired,
  title: PropTypes.string.isRequired,
  onBackButtonClick: PropTypes.func.isRequired,
  backButtonText: PropTypes.string.isRequired
};

export default BackgroundLayout;
