import React from 'react';
import PropTypes from 'prop-types';
import { CLAIMS as CLAIMS_ROOT_URL } from 'api/apiRoutes';
import Button from 'components/shared/Forms/Button';
import ThankYouSummary from './ThankYouSummary';
import ThankYouNextSteps from './ThankYouNextSteps';
import ThankYouContact from './ThankYouContact';

const ThankYou = props => (
  <div className="thank-you">
    <Button
      onClick={() => props.goToHome()}
      text={props.labels.thanksbackHome}
      className="btn btn-primary btn-amex mb-3"
      type="submit"
    />
    <ThankYouSummary
      claimId={props.claimId}
      claimRootURL={CLAIMS_ROOT_URL}
      userEmail={props.userDetails.email}
      labels={props.labels}
      language={props.language}
    />
    <ThankYouNextSteps
      thanksNextSteps={props.labels.thanksNextSteps}
      thanksClaimAssessed={props.labels.thanksClaimAssessed}
      thanksProcessClaim={props.labels.thanksProcessClaim}
    />
    <ThankYouContact userDetails={props.userDetails} labels={props.labels} />
  </div>
);
ThankYou.propTypes = {
  claimId: PropTypes.string.isRequired,
  userDetails: PropTypes.shape({
    email: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired
  }).isRequired,
  goToHome: PropTypes.func.isRequired,
  labels: PropTypes.shape({
    thanksbackHome: PropTypes.string.isRequired,
    thanksUsefulLinks: PropTypes.string.isRequired,
    thanksLinkContactUs: PropTypes.string.isRequired,
    thanksLinkFAQ: PropTypes.string.isRequired,
    commonMyAccount: PropTypes.string.isRequired,
    commonHome: PropTypes.string.isRequired,
    thanksNextSteps: PropTypes.string.isRequired,
    thanksClaimAssessed: PropTypes.string.isRequired,
    thanksProcessClaim: PropTypes.string.isRequired
  }).isRequired,
  actions: PropTypes.shape({
    gotoContactsPage: PropTypes.func.isRequired,
    gotoFAQPage: PropTypes.func.isRequired,
    gotoCookiesPage: PropTypes.func.isRequired,
    gotoClaimWrapper: PropTypes.func.isRequired
  }).isRequired,
  language: PropTypes.number.isRequired
};

export default ThankYou;
