import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { event } from 'utils/analytics';
import { GA_EVENTS, GA_ACTIONS } from 'constants/googleAnalytics';
import Checkbox from '@material-ui/core/Checkbox';
import { appInsights } from 'ApplicationInsights';

const CheckQuestion = props => {
  const [showContinueButton, setShowContinueButton] = useState(
    !!props.userAnswer
  );
  const handleCheck = () => {
    event(GA_EVENTS.QUESTION, GA_ACTIONS.ANSWER, {
      label: props.guideWireField
    });
    appInsights.trackEvent(
      {
        name:props.guideWireField
      },
      {
        EventCategory: GA_EVENTS.QUESTION,
        EventAction: GA_ACTIONS.ANSWER,
        EventLabel: props.guideWireField
      }
      )
    setShowContinueButton(!showContinueButton);
    props.onUserAnswer(props.id, !showContinueButton, props.nextGroup);
  };

  return (
    <div className="check-question">
      <div className="row beneficiary-form-checkbox mb-2 mx-0">
        <div className="col-1 beneficiary-checkbox mr-2 pl-0">
          <Checkbox
            id="checkbox-checkQuestion"
            onClick={handleCheck}
            checked={showContinueButton}
            className="checkbox"
          />
        </div>
        <div className="col beneficiary-checkbox-label">
          {props.description}
        </div>
      </div>
    </div>
  );
};

CheckQuestion.propTypes = {
  userAnswer: PropTypes.shape({
    value: PropTypes.bool
  }),
  onUserAnswer: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired,
  nextGroup: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  guideWireField: PropTypes.string
};

CheckQuestion.defaultProps = {
  userAnswer: null,
  guideWireField: 'genericCheckBox'
};

export default CheckQuestion;
