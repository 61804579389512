import React from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import { getMarketFromUrl, isUKMarket } from 'utils/urlHelpers';

import './_NewsModal.scss';

const MARKET = getMarketFromUrl();
const isAmexUk = isUKMarket(MARKET);
const zIndex = isAmexUk ? 0 : 2;
const styles = {
  newsModal: {
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.75)',
      zIndex: { zIndex }
    }
  }
};
const NewsModal = props => (
  <ReactModal
    isOpen={props.isOpen}
    className={props.modalClassName}
    style={styles.newsModal}
  >
    <div className="row custom-modal-content h-100">
      {props.header}
      {props.content}
      {props.footer}
    </div>
  </ReactModal>
);

NewsModal.propTypes = {
  content: PropTypes.element.isRequired,
  isOpen: PropTypes.bool.isRequired,
  header: PropTypes.element,
  footer: PropTypes.element,
  modalClassName: PropTypes.string
};

NewsModal.defaultProps = {
  header: undefined,
  footer: undefined,
  modalClassName: 'bodiam-modal-frame'
};

export default NewsModal;
