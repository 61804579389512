/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import { blue } from '@material-ui/core/colors';
import { ReactComponent as UploadIcon } from 'assets/img/uploadIcon.svg';

const ClaimCard = props => {
  const styles = {
    uploadIcon: { marginRight: '8px', height: '14px', width: '14px' },
    paper: {
      minHeight: '70px',
      margin: '10px',
      border: '1px solid rgba(0, 0, 0, 0.125)'
    },
    mainContainer: {
      alignItems: 'center',
      justifyContent: 'center',
      minHeight: '70px',
      padding: '10px 0px'
    },
    uploadButton: { fontSize: '12px', textTransform: 'none' },
    actionsContainer: { alignItems: 'center', justifyContent: 'flex-end' },
    decisionDate: { textAlign: 'right', wordBreak: 'break-word' },
    clickableDiv: { cursor: 'pointer' }
  };

  const UploadButton = withStyles(theme => ({
    root: {
      color: theme.palette.getContrastText(blue[900]),
      backgroundColor: blue[900],
      '&:hover': {
        backgroundColor: blue[700]
      },
      maxHeight: '70%',
      width: '100%'
    }
  }))(Button);

  return (
    <div>
      <Paper style={styles.paper} elevation={0}>
        <Grid container style={styles.mainContainer}>
          <Grid item xs={10} sm={3} style={{ marginLeft: '10px' }}>
            <Grid container>
              <Grid item xs={12} sm={12}>
                {props.id}
              </Grid>

              <Grid item xs={12} sm={12} style={{ wordBreak: 'break-word' }}>
                <strong>{props.benefit}</strong>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={10} sm={3}>
            {props.showUploadButton ? (
              <UploadButton
                style={styles.uploadButton}
                variant="contained"
                color="primary"
                onClick={() => {
                  props.uploadButtonAction();
                }}
              >
                <UploadIcon style={styles.uploadIcon} />
                {props.uploadButtonText}
              </UploadButton>
            ) : null}
          </Grid>
          <Grid item xs={10} sm={5} style={{ wordBreak: 'break-word' }}>
            <div
              onClick={props.action}
              title={props.title}
              style={styles.clickableDiv}
            >
              <Grid container item sm={11} style={styles.actionsContainer}>
                <Grid item xs={9} sm={9} style={styles.decisionDate}>
                  <strong>{props.decisionDate}</strong>
                </Grid>
                <Grid item xs={3} sm={2}>
                  <Tooltip
                    id="tooltip-top"
                    title={props.actionTitle}
                    placement="top"
                  >
                    {props.claimActionButton}
                  </Tooltip>
                  <Grid />
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

ClaimCard.propTypes = {
  id: PropTypes.string.isRequired,
  benefit: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  actionTitle: PropTypes.string.isRequired,
  uploadButtonText: PropTypes.string.isRequired,
  decisionDate: PropTypes.string.isRequired,
  uploadButtonAction: PropTypes.func,
  showUploadButton: PropTypes.bool.isRequired,
  claimActionButton: PropTypes.element,
  action: PropTypes.func
};

ClaimCard.defaultProps = {
  claimActionButton: null,
  action: () => {},
  uploadButtonAction: () => {}
};

export default ClaimCard;
