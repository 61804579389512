// TODO: technical debts
/* eslint-disable jsx-a11y/tabindex-no-positive */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { validateField, validateForm } from 'utils/formValidationHelper';
import { Form, TextField, Button } from 'components/shared/Forms';
import { useTranslation } from 'react-i18next';
import getSchemaValidation from './SignInFormSchemaValidation';

const SignInForm = props => {
  const { t } = useTranslation();
  const [errorFields, setErrorFields] = useState({});
  const [formValues, setFormValues] = useState({});
  const schemaValidation = getSchemaValidation();

  const handleOnSubmit = () => {
    const result = validateForm(schemaValidation, formValues);
    setErrorFields({
      ...errorFields,
      ...result.errorFields
    });
    if (result.isValid)
      props.handleSignAction(formValues.email, formValues.password);
  };

  const handleValueChange = event => {
    setFormValues({
      ...formValues,
      [event.target.name]: event.target.value
    });
  };

  const handleValidation = (fieldName, value) => {
    const result = validateField(schemaValidation, fieldName, value);
    setErrorFields({
      ...errorFields,
      ...result.errorFields
    });
  };

  return (
    <Form className="col-12 col-md-6 mt-4">
      <TextField
        id="txt-signInForm-email"
        label={t('commonFromEmailLabel')}
        name="email"
        value={formValues.email}
        onValidate={handleValidation}
        onValueChange={handleValueChange}
        type="email"
        errorFields={errorFields}
        ariaDescribedby={t('commonFromEmailLabel')}
        required
        sanitize={false}
      />
      <TextField
        id="txt-signInForm-password"
        label={t('commonFromPasswordLabel')}
        name="password"
        value={formValues.password}
        onValidate={handleValidation}
        onValueChange={handleValueChange}
        type="password"
        errorFields={errorFields}
        ariaDescribedby={t('commonFromPasswordLabel')}
        required
        sanitize={false}
      />
      <div className="form-group col">
        <Button
          id="btn-signInForm-signin"
          type="button"
          onClick={handleOnSubmit}
          className="btn btn-primary btn-lg btn-amex btn-sign-in"
          text={t('commonFormSignInBtnLabel')}
        />
        <p>
          <Button
            id="btn-signInForm-cancel"
            type="button"
            onClick={props.onForgotPassword}
            className="btn btn-link forgot-password"
            text={t('commonFormForgotPasswordLinkLabel')}
            tabIndex={3}
          />
        </p>
      </div>
    </Form>
  );
};

SignInForm.propTypes = {
  onForgotPassword: PropTypes.func.isRequired,
  handleSignAction: PropTypes.func.isRequired
};

export default SignInForm;
