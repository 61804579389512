import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withTranslation } from 'react-i18next';

import * as activePageActions from 'actions/activePageActions';
import * as authenticationActions from 'actions/authenticationActions';
import * as alertsActions from 'actions/alertsActions';

import history from 'utils/history';
import { getMarketFromUrl, inBodiam } from 'utils/urlHelpers';
import { resetHistory, isUserLoggedIn } from 'utils/sessionController';
import { event } from 'utils/analytics';

import TopHeaderBodiam from 'shared/components/TopHeader/TopHeaderBodiam';
import TopHeaderArundel from 'shared/components/TopHeader/TopHeaderArundel';

import {
  ARUNDEL_BASE_URL,
  BODIAM_DASHBOARD,
  LANDING_PAGE
} from 'constants/constants';
import { GA_EVENTS, GA_ACTIONS } from 'constants/googleAnalytics';
import { appInsights } from 'ApplicationInsights';

class TopHeader extends Component {
  constructor(props) {
    super(props);
    this.onMakeAClaim = this.onMakeAClaim.bind(this);
    this.onLogOut = this.onLogOut.bind(this);
  }

  onMakeAClaim() {
    event(GA_EVENTS.HEADER, GA_ACTIONS.CLICK, { label: 'makeClaim' });
    appInsights.trackEvent(
      {
        name:'makeClaim'
      },
      {
        EventCategory: GA_EVENTS.HEADER,
        EventAction: GA_ACTIONS.CLICK,
        EventLabel: 'makeClaim'
      }
      )
    const newClaimUrlWithMarket = `${ARUNDEL_BASE_URL}${getMarketFromUrl()}`;
    resetHistory(BODIAM_DASHBOARD);
    this.props.actions.bodiamMakeClaimAction();
    history.push(newClaimUrlWithMarket);
    this.props.actions.gotoTriageWrapper();
  }

  onLogOut() {
    const {
      props: { actions, isLoggedIn, t }
    } = this;
    event(GA_EVENTS.HEADER, GA_ACTIONS.CLICK, { label: 'logOut' });
    appInsights.trackEvent(
      {
        name:'logOut'
      },
      {
        EventCategory: GA_EVENTS.HEADER,
        EventAction: GA_ACTIONS.CLICK,
        EventLabel: 'logOut'
      }
      )
    if (isLoggedIn) {
      actions.onLogout(LANDING_PAGE);
    } else {
      actions.addAlert('info', t('genericLogOutNotLogged'));
    }
  }

  render() {
    const {
      props: { actions, isLoggedIn, bodiamAction, t }
    } = this;

    const header =
      inBodiam() || (bodiamAction && bodiamAction.length) ? (
        <TopHeaderBodiam
          onLogOut={this.onLogOut}
          actions={actions}
          makeAClaim={this.onMakeAClaim}
          isLoggedIn={isLoggedIn}
        />
      ) : (
        <TopHeaderArundel
          onLogOut={this.onLogOut}
          makeAClaim={this.onMakeAClaim}
          isLoggedIn={isLoggedIn}
          logoutText={t('genericLogOut')}
        />
      );
    return header;
  }
}

const mapStateToProps = () => ({
  isLoggedIn: isUserLoggedIn()
});

const mapDispatchToProps = dispatch => ({
  actions: {
    ...bindActionCreators(activePageActions, dispatch),
    ...bindActionCreators(authenticationActions, dispatch),
    ...bindActionCreators(alertsActions, dispatch)
  }
});

TopHeader.propTypes = {
  actions: PropTypes.shape({
    onLogout: PropTypes.func.isRequired,
    gotoTriageWrapper: PropTypes.func.isRequired,
    gotoLandingPage: PropTypes.func.isRequired,
    cleanCurrentAccount: PropTypes.func.isRequired,
    setClaimsList: PropTypes.func.isRequired,
    setCardsList: PropTypes.func.isRequired,
    setPoliciesList: PropTypes.func.isRequired,
    addAlert: PropTypes.func.isRequired,
    bodiamMakeClaimAction: PropTypes.func.isRequired
  }).isRequired,
  isLoggedIn: PropTypes.bool,
  bodiamAction: PropTypes.string,
  t: PropTypes.func.isRequired
};

TopHeader.defaultProps = {
  isLoggedIn: false,
  bodiamAction: null
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(TopHeader));
