export const APP_VERSION = process.env.REACT_APP_VERSION || '19.3.3.0';

export const MARKETS = {
  AMEX_UK: 'amexuk',
  AMEX_FR: 'amexfr',
  AMEX_DE: 'amexde',
  AMEX_AT: 'amexat',
  AMEX_ES: 'amexes',
  AMEX_NL: 'amexnl',
  AMEX_IT: 'amexit',
  AMEX_MX: 'amexmx',
  AMEX_SE: 'amexse',
  AMEX_FI: 'amexfi',
  AMEX_NO: 'amexno',
  AMEX_BE: 'amexbe',
  AXA_UK: 'axauk',
  LUFTHANSA_DE: 'lufthansade'
};

export const COUNTRIES_BY_MARKET = {
  amexuk: 'GB',
  amexfr: 'FR',
  amexde: 'DE',
  amexat: 'AT',
  amexes: 'ES',
  amexnl: 'NL',
  amexit: 'IT',
  amexmx: 'MX',
  amexse: 'SE',
  amexfi: 'FI',
  amexno: 'NO',
  amexbe: 'BE',
  axauk: 'GB',
  lufthansade: 'DE'
};

export const newDAta = 'New Data';
// TODO replace these values with the real ones
export const GLOBAL_ASSIST_PRODS = [
  'The Platinum Card',
  'American Express Platinum Business Card',
  'American Express® Corporate Platinum Card',
  'The Gold Card',
  'American Express® Corporate Gold Card',
  'American Express Gold Business Card',
  'The Centurion Card',
  'Centurion d´American Express',
  'Gold Card American Express',
  'Carte Business Gold American Express',
  'Carte Corporate Gold American Express',
  'Carte Platinum American Express',
  'Carte Business Platinum American Express',
  'Carte Corporate Platinum American Express'
];

export const START = 0;
export const COMBO = 1;
export const YES_NO = 2;
export const TEXT = 3;
export const TEXTBOX_WIDE = 4;
export const TEXT_AREA = 5;
export const PARTIAL_VIEW = 6;
export const DATE = 7;
export const DATE_TIME = 8;
export const TIME = 9;
export const NUMBER = 10;
export const TEXT_AREA_WIDE = 11;
export const FILE = 12;
export const INTEGER = 13;
export const CARD_NUMBER = 14;
export const HOW_TO_CLAIM = 15;
export const DECLINED = 16;
export const CONTACTS = 17;
export const CURRENCY = 18;
export const CHECKBOX = 19;
export const FILE_MEDICAL = 20;
export const CONTACTS_DEATH = 21;
export const CONTACTS_MEDICAL = 22;
export const CONTACTS_MEDICAL_HEALTH = 23;
export const CONTACTS_MEDICAL_DEATH = 24;
export const WARNING = 25;
export const COUNTRY = 26;
export const DATE_TIME_ROUGHLY = 27;
export const DATE_TIME_SCHEDULED = 28;
export const DATE_TIME_ACTUAL = 29;
export const POLICY_NUMBER = 31;
export const CONTACTS_MEDICAL_CERTIFICATE = 32;
export const CONTACTS_MEDICAL_REPORT = 33;
export const CONTACTS_MEDICAL_HEALTH_REPORT = 34;
export const CONTACTS_MEDICAL_HEALTH_CERTIFICATE = 35;
export const CONTACTS_DEATH_HEALTH = 36;
export const CONTACTS_MEDICAL_DEATH_HEALTH = 37;
export const CONTACTS_MEDICAL_DISABILITY_REPORT = 38;
export const CONTACTS_MEDICAL_DEATH_DOB_PRIMARYCARD = 39;
export const CONTACTS_DOB_CARD = 40;
export const PUSH_AWAY = 41;
export const CONTACTS_HEALTH = 42;

export const COMBO_TYPES = {
  COMBO: 1,
  RADIO: 2,
  MULTISELECT: 3
};

export const PAID_HOW = {
  AMEX: 1,
  OTHER: 2,
  CASH: 3
};

export const FILE_ANSWER = {
  FILE: '1',
  EMAIL: '2',
  UNAVAILABLE: '3'
};

export const YESNO_ANSWER = {
  YES: 'YES',
  NO: 'NO'
};

export const BLANK_CONTACT = {
  id: 0,
  localId: 0,
  title: '',
  firstName: '',
  surnames: '',
  personalNumber: '',
  relationship: undefined,
  fileSendingType: 0,
  files: null,
  dob: '',
  isAdult: false,
  isClaimOnBehalfConsent: false,
  isSecondaryCardHolder: undefined,
  isCoLiving: undefined,
  wasStable: true,
  medicalFileType: 0,
  medicalFiles: null,
  deathFileType: 0,
  deathFiles: null,
  isTitleEnabled: true
};

export const formTypes = {
  REGISTRATION: 'REGISTRATION',
  SIGN_IN: 'SIGN_IN',
  FORGOT_PASSWORD: 'FORGOT_PASSWORD',
  FORGOT_PASSWORD_SENT: 'FORGOT_PASSWORD_SENT',
  UPDATE_USER: 'UPDATE_USER'
};

export const YOUR_PURCHASES_ONLY_ME = 'YOUR_PURCHASES_ONLY_ME';
export const YOUR_PURCHASES_ME_AND_OTHERS = 'YOUR_PURCHASES_ME_AND_OTHERS';

export const UPLOAD_FILE = 1;
export const EMAIL_FILE = 2;
export const NO_FILE = 3;

export const MAX_LIMITS_DOCUMENTS = 10;

export const FAKE_UPLOAD_ID = 999999999;
export const FAKE_UPLOAD_ID_ALT = 9999999999;

export const TOKEN = 'TOKEN';
export const TOKEN_MAX_AGE = 18000;
export const SAVE_TOKEN = 'SAVE_TOKEN';
export const GET_TOKEN = 'GET_TOKEN';
export const DELETE_TOKEN = 'DELETE_TOKEN';

export const LANDING_PAGE = 'LANDING_PAGE';
export const SIGNIN_PAGE = 'SIGNIN_PAGE';
export const REGISTRATION_PAGE = 'REGISTRATION_PAGE';
export const TRIAGE_WRAPPER = 'TRIAGE_WRAPPER';
export const CLAIM_WRAPPER = 'CLAIM_WRAPPER';
export const MY_CLAIMS_PAGE = 'MY_CLAIMS_PAGE';
export const ANYTHING = 'ANYTHING';
export const RECOVER_PASSWORD = 'RECOVER_PASSWORD';
export const PROFILE_DASHBOARD_PAGE = 'PROFILE_DASHBOARD_PAGE';
export const BODIAM_DASHBOARD = 'BODIAM_DASHBOARD';
export const BODIAM_YOUR_COVER = 'BODIAM_YOUR_COVER';
export const CHECK_POLICY_COVER = 'CHECK_POLICY_COVER';
export const UPLOAD_CLAIM_DOCUMENTS = 'UPLOAD_CLAIM_DOCUMENTS';
export const FAQ_PAGE = 'FAQ_PAGE';
export const CONTACTS_PAGE = 'CONTACTS_PAGE';
export const COOKIES_PAGE = 'COOKIES_PAGE';
export const DATAPROTECTION_PAGE = 'DATAPROTECTION_PAGE';
export const LEGALNOTICE_PAGE = 'LEGALNOTICE_PAGE';
export const COVER_PAGE = 'COVER_PAGE';
export const COVER_PAGE_BYDATE = 'COVER_PAGE_BYDATE';
export const COVER_PAGE_FORM = 'COVER_PAGE_FORM';
export const GENERATE_CERTIFICATE_SUCCESS = 'GENERATE_CERTIFICATE_SUCCESS';
export const CHECK_CLAIM_COVER_DATE = 'CHECK_CLAIM_COVER_DATE';
export const CHECK_SiGNIN_PAGE = 'CHECK_SiGNIN_PAGE';

export const PAGES_REQUIRE_AUTHENTICATION = [
  MY_CLAIMS_PAGE,
  TRIAGE_WRAPPER,
  CLAIM_WRAPPER,
  PROFILE_DASHBOARD_PAGE,
  UPLOAD_CLAIM_DOCUMENTS
];

export const CALENDAR_DATE_TYPE = 7;
export const CALENDAR_DATETIME_TYPE = 8;
export const CALENDAR_DATETIME_ROUGHLY_TYPE = 27;
export const CALENDAR_DATETIME_SCHEDULED_TYPE = 28;
export const CALENDAR_DATETIME_ACTUAL_TYPE = 29;

export const TASKLIST = 'CLAIM_WRAPPER__TASKLIST';
export const WHAT_HAPPENED = 'CLAIM_WRAPPER__WHAT_HAPPEN';
export const DATES = 'CLAIM_WRAPPER__DATES';
export const EXPENSES = 'CLAIM_WRAPPER__EXPENSES';
export const PREVIOUS = 'CLAIM_WRAPPER__PREVIOUS';
export const BANK = 'CLAIM_WRAPPER__BANK';
export const REVIEW = 'CLAIM_WRAPPER__REVIEW';
export const THANKS = 'CLAIM_WRAPPER__THANKS';

export const WHAT_HAPPENED_LABEL = 'incidentDescription';
export const DATES_LABEL = 'dates';
export const INSURANCES_LABEL = 'insurances';
export const BANK_LABEL = 'bankData';

export const CARDNUMBER_INPUT_NAME = 'cardNumber';
export const PERSONALNUMBER_INPUT_NAME = 'personalNumber';

export const PLACEHOLDER_CARD = '0001';

export const DECLINE_REASONS = {
  WITHDRAW: 0
};

export const ADDITIONAL_RELATIONSHIPS = {
  PRIMARY_CLAIMANT: 0,
  CDW_INVOLVED_PERSON: 27,
  CDW_3RD_PARTY_INVOLVED_PERSON: 28
};

export const BENEFICIARY_CONTACT_FORM_RELATIONSHIPS = {
  notGrouped: [14, 10, 6],
  grouped: [
    1,
    2,
    3,
    4,
    5,
    7,
    8,
    9,
    11,
    12,
    13,
    15,
    16,
    17,
    18,
    19,
    20,
    21,
    22,
    23,
    24,
    25,
    26,
    29,
    30,
    31,
    32,
    33
  ]
};

export const PURCHASES_FORM_PAID_METHODS = {
  notGrouped: [
    {
      key: 'AMEX',
      id: 1
    },
    {
      key: 'Other',
      id: 2
    },
    {
      key: 'Cash',
      id: 3
    },
    {
      key: 'Else',
      id: 4
    }
  ]
};

export const CLAIM_STATUS = {
  1: 'In progress',
  2: 'Submitted',
  3: 'Declined',
  4: 'Approved',
  5: 'Withdrawn',
  6: 'Settled',
  91: 'In progress',
  92: 'Submitted',
  93: 'Declined',
  94: 'Approved',
  95: 'Withdrawn',
  96: 'Settled',
  97: 'Unavailable'
};

export const CLAIM_STATUS_CODES = {
  IN_PROGRESS: 1,
  SUBMITTED: 2,
  DECLINED: 3,
  APPROVED: 4,
  WITHDRAWN: 5,
  SETTLED: 6,
  UNAVAILABLE: 7
};

export const CARD_TYPES = {
  1: 'typeOfCardPersonal',
  2: 'typeOfCardCommonCompany',
  3: 'typeOfCardSmallBusiness'
};

/**  REGULAR EXPRESSIONS */
// eslint-disable-line no-useless-escape
export const REGEX = {
  // eslint-disable-line no-useless-escape
  AMEX_CARD: /^\d{15}$/, // eslint-disable-line no-useless-escape
  AMEX_POLICY: /^[a-zA-Z0-9-_\/\\]*$/,
  // eslint-disable-line no-useless-escape
  // eslint-disable-next-line no-useless-escape
  VALIDATE_EMAIL: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  // eslint-disable-line no-useless-escape
  VALIDATION_IS_NUMBER: /^(?=.+)(?:[1-9]\d*|0)?(?:\.\d+)?$/, // eslint-disable-line no-useless-escape
  ONLY_DIGITS: /^\d*$/,
  // eslint-disable-line no-useless-escape
  DATE_amexuk: /^((0?[1-9]|1[012])[- /.](0?[1-9]|[12][0-9]|3[01])[- /.](19|20)?[0-9]{2})*$/, // eslint-disable-line no-useless-escape,
  ALPHANUMERIC: /^[a-zA-Z0-9_]*$/, // eslint-disable-line no-useless-escape
  ALPHANUMERIC_NOT_BLANK: /^([\s]*\w+[\s\w]*)$/, // eslint-disable-line no-useless-escape
  DANGEROUS_CHARACTERS: /[|<>\{\}\[\]\\\/]/, // eslint-disable-line no-useless-escape
  SWIFT_CODE: /^[A-Z]{6}[A-Z0-9]{2}([A-Z0-9]{3})?$/,
  SWEDISH_PERSONALNUMBER: /^$|^[0-9]{6}[-+][0-9]{4}$/,
  VALIDATE_LUFTHANSA_POLICY: /^[0-9]{4}/,
  // eslint-disable-next-line no-useless-escape
  VALIDATE_PHONE_NUMBER: /^[+]?[(?[\/\s\-\.\)0-9]{1,6}[\(\)\/\s\-\.0-9]{2,15}$/,
  VALIDATE_PASSWORD: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-_]).{8,}$/,
  CONTAINS_ONE_LOWER_CASE_LETTER: /.*[a-z].*/,
  CONTAINS_ONE_UPPER_CASE_LETTER: /.*[A-Z].*/,
  CONTAINS_ONE_SPECIAL_CHAR: /[#.'`~()|+\-=;:",<>{}?!\\[\]@\\/$%^&_*]/,
  CONTAINS_ONE_NUMBER: /[0-9]/,
  UK_ACCOUNT_NUMBER: /^(\d){8}$/,
  UK_SORT_CODE: /^(\d){6}$/,
  FINNISH_PERSONAL_NUMBER: /^$|^[0-9]{6}[-A+](?:[0-9]+){1,3}[0-9A-Za-z]$/
};

export const GENERIC_FORM_INPUT_MAX_LENGTH = 500;

export const CARD_VISIBLE_DIGITS_NUMBER = 4;
export const NUMBER_ASTERICS_OBFUSCATED = 11;

export const CAPTCHA_KEY = '6LdKlrUUAAAAACh1FHCvfqdHIqHuj32R6NQ4gWVT';

export const DATES_VALIDATION_RESULT = {
  OK: 1,
  KO: 2,
  DECLINE: 3
};

export const ACCOUNT_TYPE = {
  RETAIL: 'RETAIL',
  NAC: 'NAC'
};

export const ACTIVE_CARD_INDEX = 'ACTIVE_CARD_INDEX';

export const ARUNDEL_BASE_URL = '/OLC_V3/welcome/';
export const BODIAM_BASE_URL = '/OLC_V3/portal/';
export const YOUR_COVER = '/your-cover/';
export const REPORT = '/report/';
export const CERTIFICATE = '/certificate';
export const PROVIDERS = '/providers';

export const ERRORS = {
  EXCEPTION_GENERIC: 'GenericErrorMessage',
  EXCEPTION_NOTAUTHORIZE: 'genericUnauthorizedError',
  DOB_INCORRECT: 'exceptionUserIncorrectDateofBirth',
  DOB_YOUNGER: 'exceptionUserYounger18',
  PERSONAL_NUMBER_INCORRECT: 'commonFormPersonalNumberValidErrorMsg',
  INVALID_TEXT: 'isInValidText',
  FAILED_CARDVALIDATION: 'CardValidationError'

};

export const LANGUAGE_SELECTOR_CODES = {
  1: 'gb',
  2: 'es',
  3: 'fr',
  4: 'de',
  5: 'nl',
  6: 'at',
  7: 'it',
  8: 'mx',
  9: 'se',
  10: 'fi',
  11: 'se',
  12: 'no'
};

export const BODIAM_ACTIONS = {
  ADD_CARD: 'ADD_CARD',
  EDIT_PROFILE: 'EDIT_PROFILE',
  MAKE_CLAIM: 'MAKE_CLAIM',
  REMOVE_CARD: 'REMOVE_CARD',
  REMOVED_CARD: 'REMOVED_CARD',
  STATIC_PAGES: 'STATIC_PAGES',
  VIEW_PROFILE: 'VIEW_PROFILE'
};

export const PrismicConf = {
  apiEndpoint: 'https://trasteo.prismic.io/api/v2'
};

// SHOULD GO IN ENV FILE
export const CONTENT_TAGS = {
  POLICY_SUMMARY: 'policy_summary'
};

export const PRISMIC_CONTENT_TYPES = {
  BENEFIT_LIST: 'cover_benefitlist',
  COVER_SUMMARY: 'cover_summary',
  COVER_FULL_POLICY_LINK: 'cover_fullpolicylink',
  FAQ_PREFIX: 'FQ',
  SINGLE_FAQ: 'furtherquestion',
  CARD_NAME: 'card_name',
  LIVE_EVENT: 'liveevent'
};

export const MEDICAL_FACILITIES = {
  CATEGORIES: [
    { label: 'BIOLOGY_LABORATORY', hasSpecialities: false },
    { label: 'HOSPITAL', hasSpecialities: true },
    { label: 'IMAGING_CENTER', hasSpecialities: false },
    { label: 'MEDICAL_CLINIC', hasSpecialities: true },
    { label: 'NURSING_CARE', hasSpecialities: false },
    { label: 'PHARMACY', hasSpecialities: false },
    { label: 'SPECIALIST', hasSpecialities: true }
  ],
  SPECIALITIES: [
    'ALLERGO',
    'CARDIOVASCULAR_SURGERY',
    'DENTIST',
    'DERMATO_VENERO',
    'OTORHINOLARYNGO',
    'ENDOCRINO',
    'GEN_PRACTICE',
    'GASTROENTERO',
    'GEN_SURGERY',
    'GERIATRICS',
    'GYNECO_OBSTETRICS',
    'HAND_SURGERY',
    'GEN_HEMATO',
    'INFECTIOUS_DISEASES',
    'INTENSIVIST',
    'INTERNAL_MED',
    'NEPHRO',
    'NEURO',
    'NEUROSURGERY',
    'ONCOLOGY',
    'OPHTHALMO',
    'ORTHOPEDIC_SURGERY_TRAUMATO',
    'ORTHOPAEDICS',
    'PATHOLOGY',
    'PAEDIATRIC_SURGERY',
    'PAEDIATRICS',
    'PODIATRIC_SURGERY',
    'PSYCHIATRY',
    'PULMONOLOGY',
    'RADIOTHERAPY',
    'RADIOLOGY',
    'PHYSICAL_MED_REHABILITATION',
    'RHEUMATO',
    'SPORT_MED',
    'THORACIC_SURGERY',
    'URO',
    'VASCULAR_SURGERY',
    'PLASTIC_SURGERY',
    'ANGIO_PHLEBOLOGY',
    'GASTRO_ENTERO_SURGERY',
    'STOMATO',
    'CARDIAC_SURGERY',
    'PSYCHO',
    'ANAESTHETICS',
    'CLINICAL_BIOLOGY',
    'CHILD_PSYCHIATRY',
    'CLINICAL_NEUROPHYSIO',
    'CRANIOFACIAL_SURGERY',
    'DENTAL_OMFS',
    'ACCIDENT_EMERGENCY_MED',
    'IMMUNO',
    'LABORATORY_MED',
    'MAXILLO_FACIAL_SURGERY',
    'MICROBIOLOGY',
    'NEURO_PSYCHIATRY',
    'NUCLEAR_MED',
    'OCCUPATIONAL_MED',
    'PHYSIOTHERAPY',
    'PROSTHETICS',
    'PUBLIC_HEALTH_PREVENT_MED',
    'TROPICAL_MED'
  ],
  ROUTE_MODES: {
    driving: 'driving',
    transit: 'transit',
    walking: 'walking'
  }
};

export const POLICY_FAQ_CODES = [
  'WHO', // corresponding code for prismic: 1
  'WHAT', // corresponding code for prismic: 2
  'CLAIM-PROCESS', // corresponding code for prismic: 3
  'PRIVACY', // corresponding code for prismic: 4
  'COMPLAIN' // corresponding code for prismic: 5
];
export const POLICY_FAQ_TITLES = [
  'faqTitleWho', // corresponding code for prismic: 1
  'faqTitleWhat', // corresponding code for prismic: 2
  'faqTitleProcess', // corresponding code for prismic: 3
  'faqTitlePrivacy', // corresponding code for prismic: 4
  'faqTitleComplaint' // corresponding code for prismic: 5
];
export const LETTERS = [
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T'
];

export const BING_MAPS_URL =
  'https://www.bing.com/api/maps/mapcontrol?callback=';

export const CHATBOT_URL = process.env.REACT_APP_CHATBOT_URL;
export const ENGLISH_LANGUAGE = 1;
export const ENGLISH_LANGUAGE_VALUE = 'en-GB';

export const DEFAULT_LANGUAGES = {
  amexuk: ENGLISH_LANGUAGE,
  amexfr: 3,
  amexde: 4,
  amexat: 6,
  amexes: 2,
  amexnl: 5,
  amexit: 7,
  amexmx: 8,
  amexse: 9,
  amexfi: 10,
  amexno: 12,
  amexbe: 3,
  axauk: ENGLISH_LANGUAGE,
  lufthansade: 4
};

// TODO: refactor and crate a new component make an import to get the default card image.
export const DEFAULT_CARD_IMAGE = './assets/img/default-amex-card.png';

export const MAX_CARDS = 5;

export const EURO_MARKETS = [MARKETS.AMEX_FR, MARKETS.LUFTHANSA_DE];
export const UK_MARKETS = [MARKETS.AMEX_UK, MARKETS.AXA_UK];
export const SPECIAL_MARKETS = [MARKETS.LUFTHANSA_DE];

export const NA = 'N/A';

export const CUSTOM_CONTACTS_FORM_MARKETS = [MARKETS.AMEX_FR];

export const CARD_HOLDERS = {
  PRIMARY: 0,
  SUPPLEMENTARY: 1,
  NOCARD: null
};

export const CARD_HOLDER_OPTIONS = {
  PRIMARY: 'PRIMARY',
  SUPPLEMENTARY: 'SUPPLEMENTARY',
  NOCARD: 'NOCARD'
};

export const APPS = {
  BODIAM: 'portal',
  ARUNDEL: 'welcome'
};

export const CARD_NUMBER_MASK = '**** **** ****';

export const CAROUSEL_CLASSES = {
  CARD: 'cards',
  BENEFITS: 'benefit'
};

export const LANGUAGE_MAPS = {
  'en-GB': 'default',
  'es-ES': 'es',
  'fr-FR': 'fr',
  'de-DE': 'de',
  'de-AT': 'at',
  'sv-SE': 'sv',
  'it-IT': 'it',
  'nl-NL': 'nl',
  fi: 'fi',
  no: 'no'
};

export const LANGUAGE_ID_MAPS = {
  1: 'en-GB',
  2: 'es-ES',
  3: 'fr-FR',
  4: 'de-DE',
  5: 'nl-NL',
  6: 'de-AT',
  7: 'it-IT',
  9: 'sv-SE',
  10: 'fi',
  12: 'no'
};

export const LANGUAGE_ID_LOCALES = {
  1: 'en-GB',
  2: 'es-ES',
  3: 'fr-FR',
  4: 'de-DE',
  5: 'nl-NL',
  6: 'de-AT',
  7: 'it-IT',
  9: 'sv-SE',
  10: 'fi-FI',
  12: 'nb-NO'
};

export const ALERT_TYPES = {
  SUCCESS: 'success',
  WARNING: 'warning',
  ERROR: 'error',
  INFO: 'info'
};

export const DATE_FORMAT = 'YYYY-MM-DD';

export const DOCUMENT_TYPE = {
  MEDICAL_REPORT: 'GetMedicalReport',
  GDPR_REPORT: 'GetGDPRReport'
};

export const PHONE_WIDTH = 576;
