import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function claim(state = initialState.policiesList, action) {
  switch (action.type) {
    case types.SET_POLICIES_LIST:
      return action.policies;
    default:
      return state;
  }
}
