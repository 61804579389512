import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Row, Col } from 'components/shared/Table';
import { Button } from 'components/shared/Forms';
import ProductItemComponent from '../ProductItemComponent';
import IconLabel from '../shared/IconLabel';

const scramblePhone = phone => {
  if (phone && phone.length) {
    const visibles = phone.slice(-5);
    const toHide = phone.substring(0, phone.length - 5);
    const hidden = [];
    toHide.split('').forEach(() => {
      hidden.push('*');
    });
    return `${hidden.join('')}${visibles}`;
  }
  return '';
};

const scrambleEmail = email => {
  if (email && email.length) {
    const firstVisible = email.slice(0, 1);
    const lastVisible = email.slice(-(email.length - email.indexOf('@') + 1));
    const toHide = email.substring(firstVisible.length, email.indexOf('@') - 1);
    const hidden = [];
    toHide.split('').forEach(() => {
      hidden.push('*');
    });
    return `${firstVisible}${hidden.join('')}${lastVisible}`;
  }
  return '';
};

const UserInfoCard = props => {
  const { t } = useTranslation();
  return (
    <ProductItemComponent classes="mb-3">
      <Row>
        <Col>
          <IconLabel
            icon="person"
            label={`${props.userInfo.name} ${props.userInfo.surname}`}
            labelClassName="name-icon-label"
          />
          <IconLabel icon="email" label={scrambleEmail(props.userInfo.email)} />
          <IconLabel
            icon="smartphone"
            label={scramblePhone(props.userInfo.phone)}
          />
          <div className="edit-info-btn-box">
            <Button
              type="button"
              onClick={() => {
                props.toggleEditProfile();
              }}
              className="btn btn-link btn-edit-info"
              text={t('commonEdit')}
            />
          </div>
        </Col>
      </Row>
    </ProductItemComponent>
  );
};

UserInfoCard.propTypes = {
  toggleEditProfile: PropTypes.func.isRequired,
  userInfo: PropTypes.shape({
    email: PropTypes.string,
    phone: PropTypes.string,
    name: PropTypes.string,
    surname: PropTypes.string
  }).isRequired
};

export default UserInfoCard;
