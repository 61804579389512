import axios from 'axios';
import { API_REQUEST } from '../../actions/core/api';

import { getToken } from '../../utils/sessionController';
import { createHeader } from '../../utils/apiHelper';

const getContentTypeHeader = type => ({ 'Content-Type': type });
const getContentTypeHeaderAppJson = () =>
  getContentTypeHeader('application/json');

const createHeaderWithToken = customValues => {
  let token = getToken();

  if (!token) {
    token = { token: '' };
  }

  return createHeader({
    Authorization: `Bearer ${token.token}`,
    ...customValues
  });
};

export const apiMiddleware = ({ dispatch }) => next => action => {
  next(action);

  if (action.type === API_REQUEST) {
    const {
      url,
      method,
      headers,
      onSuccess,
      onError,
      responseType
    } = action.meta;
    const contentHeader = getContentTypeHeaderAppJson();
    const customHeaders = createHeaderWithToken({
      ...contentHeader,
      ...headers
    });

    axios({
      method,
      url,
      headers: customHeaders,
      data: action.payload,
      responseType
    })
      .then(response =>
        dispatch({
          type: onSuccess,
          payload: response.data,
          meta: { ...action.meta, response }
        })
      )
      .catch(error =>
        dispatch({ type: onError, payload: error.response, meta: action.meta })
      );
  }
};

export default [apiMiddleware];
