import {
  endPointCallDelete,
  endPointCallPost,
  endPointCallGet
} from './endPointCallerApi';
import * as apiRoutes from './apiRoutes';
import {
  createCardObjectByNumber,
  createCardObject
} from '../utils/CardHelper';

/**
 * @typedef {{ value: string, obfuscated: string }} TransformResponseDto
 */

class CreditCardApi {
  /**
   * @param {string} ccNumber
   * @returns {Promise<TransformResponseDto>}
   * */
  static getCreditCardObject(ccNumber) {
    return CreditCardApi.transformCreditCard(ccNumber).then(res =>
      createCardObject(res.responseValue, res.responseObfuscated)
    );
  }

  /**
   * @param {string} ccNumber
   * @returns {Promise<TransformResponseDto>}
   * */
  static getCreditCardObjectUsingNumber(ccNumber) {
    return new Promise(resolve =>
      resolve(createCardObjectByNumber(ccNumber, ccNumber))
    );
  }

  /**
   * @param {TransformResponseDto} card
   */
  static validateGencode(card, recaptcha) {
    return endPointCallPost(
      `${apiRoutes.VALIDATE_CREDIT_CARD}/gencode`,
      {
        number: card.value,
        obfuscated: card.obfuscated
      },
      null,
      { recaptcha }
    ).then(responseData => responseData);
  }

  static deleteCardApi(cardId) {
    const url = `${apiRoutes.GET_CARDS}/${cardId}`;
    return endPointCallDelete(url);
  }

  static addCardApi(response) {
    const cardResquest = {
      card: response.value,
      obfuscated: response.obfuscated
    };
    const url = `${apiRoutes.ADD_CARDS}`;
    return endPointCallPost(url, cardResquest);
  }

  static getUserCards() {
    const url = `${apiRoutes.GET_CARDS}`;
    return endPointCallGet(url);
  }

  static getCardBenefits(productId) {
    const url = `${apiRoutes.GET_CARDS}/${productId}/benefits`;
    return endPointCallGet(url);
  }

  static transformCreditCard(cardNumber) {
    const url = `${apiRoutes.GET_GENCODE}`;
    return endPointCallPost(url, { number: cardNumber });
  }
}

export default CreditCardApi;
