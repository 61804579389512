import { endPointCallGet } from './endPointCallerApi';
import * as apiRoutes from './apiRoutes';

class DecisionTreeApi {
  /**
   * benefitId && productId != null ---> URL = /api/tree/product/{product_id}/benefit/{benefit_id}
   * productId != null              ---> URL = /api/tree/product/{product_id}
   * benefitId && productId == null ---> URL = /api/trees/market
   * @param {*} productId
   * @param {*} benefitId
   */
  static getTriagedDecisionTree(language, productId, benefitId) {
    let url;
    if (benefitId) {
      url = `${apiRoutes.TRIAGED_DECISION_TREE_BY_PRODUCT_ID}/${productId}/benefit/${benefitId}/${language}`;
    } else if (productId) {
      url = `${apiRoutes.TRIAGED_DECISION_TREE_BY_PRODUCT_ID}/${productId}/${language}`;
    } else {
      url = `${apiRoutes.TRIAGED_DECISION_TREE}/${language}`;
    }
    return endPointCallGet(url);
  }

  static getBenefitsdDecisionTree(language, productId, benefitId) {
    const url = `${apiRoutes.TRIAGED_DECISION_TREE_BY_PRODUCT_ID}/${productId}/benefit/${benefitId}/${language}`;
    return endPointCallGet(url);
  }
}
export default DecisionTreeApi;
