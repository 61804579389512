import { object, string, date } from 'yup';
import moment from 'moment';
import { REGEX, ERRORS } from 'constants/constants';

const invalidTextLabel = 'isInValidText';

const maxLengthConstants = {
  policyNumber: 30,
  policyDate: 10
};

const birthday18Year = value => {
  const today = moment();
  const birthDate = moment(value);
  const diff = today.diff(birthDate, 'year');
  return diff >= 18;
};

const schemaValidation = object({
  policyNumber: string()
    .max(maxLengthConstants.policyNumber)
    .required(invalidTextLabel)
    .typeError(invalidTextLabel)
    .matches(REGEX.AMEX_POLICY),
  name: string()
    .required(invalidTextLabel)
    .typeError(invalidTextLabel),
  surname: string()
    .required(invalidTextLabel)
    .typeError(invalidTextLabel),
  dateOfBirth: date()
    .transform((value, rawValue) => moment(rawValue).toDate())
    .required(invalidTextLabel)
    .typeError(ERRORS.DOB_INCORRECT)
    .test('policydate-birthday', ERRORS.DOB_INCORRECT, birthday18Year)
});

const renderPolicyHelpText = policiesList => {
  if (policiesList.length === 0) return 'policyComponentHelpEmpty';
  return 'policyComponentHelpWithPolicies';
};

export { maxLengthConstants, schemaValidation, renderPolicyHelpText };
