import {
  CARD_VISIBLE_DIGITS_NUMBER,
  NUMBER_ASTERICS_OBFUSCATED,
  REGEX
} from 'constants/constants';

export const obfuscateCard = creditCardNumber => {
  const lastDigits = creditCardNumber
    ? creditCardNumber.slice(-CARD_VISIBLE_DIGITS_NUMBER)
    : '';

  return `${'*'.repeat(NUMBER_ASTERICS_OBFUSCATED)}${lastDigits}`;
};

export const createCardObject = (creditCardNumber, obfuscatedValue) => ({
  value: creditCardNumber,
  obfuscated: obfuscatedValue
});

export const createCardObjectByNumber = creditCardNumber => ({
  value: creditCardNumber,
  obfuscated: creditCardNumber
    ? creditCardNumber.slice(-CARD_VISIBLE_DIGITS_NUMBER)
    : creditCardNumber
});

export const isCardAcceptableNumber = numberField =>
  REGEX.AMEX_CARD.test(String(numberField).toLowerCase());
