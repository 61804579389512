import React from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import NumberFormat from 'react-number-format';

import { getExpenseTypes } from 'utils/expensesHelper';
import { getCurrencySymbol } from 'utils/currencyHelper';
import { withConfig } from 'ConfigurationProvider';

import { Row, Col } from 'components/shared/Table';
import { Button } from 'components/shared/Forms';

export const PurchasesCategoryItem = props => {
  const { t } = useTranslation();
  const expenseTypes = getExpenseTypes(props.config.EXPENSE_TYPES);
  const getExpenseLabel = () => expenseTypes[props.category].label;

  const getPurchaseItems = () =>
    props.expenses.map(item => (
      <Collapse in key={item.id} className="expense-item">
        <Row className="no-gutters bg-light text-dark p-1 pl-3 mb-1">
          <Col className="col-6 col-sm-5 col-md-5 text-left pt-3">
            {item.description}
          </Col>
          <Col className="col-6 col-sm-4 col-md-4 text-left pt-3">
            <NumberFormat
              thousandSeparator={
                props.config.REGIONAL_SETTINGS.thousandSeparator
              }
              decimalSeparator={props.config.REGIONAL_SETTINGS.decimalSeparator}
              displayType="text"
              isNumericString
              value={item.amount}
              prefix={`${getCurrencySymbol(item.currencyCode)} `}
              fixedDecimalScale
              decimalScale={2}
            />
          </Col>
          <Col className="col-12 col-sm-3 col-md-3 text-right">
            <IconButton
              color="primary"
              onClick={() => props.onEditeExpenseClick(props.category, item.id)}
              disabled={props.isEditing}
              className="expense-edit"
            >
              <i className="material-icons">create</i>
            </IconButton>
            <IconButton
              className="expense-remove"
              onClick={() => props.onRemoveExpenseClick(item.id)}
            >
              <i className="material-icons text-danger">delete</i>
            </IconButton>
          </Col>
        </Row>
      </Collapse>
    ));

  const getExpenseIcon = () => {
    if (expenseTypes[props.category]) {
      return expenseTypes[props.category].icon;
    }
    return 'credit_card';
  };

  const displayExpenses = props.expenses.length > 0 && (
    <Row className="no-gutters">
      <Col>{getPurchaseItems()}</Col>
    </Row>
  );

  const addNewPurchaseButton = !props.isEditing && (
    <Collapse in={!props.isEditing} timeout={1000}>
      <Row className="justify-content-center">
        <Col className="col-8">
          <Button
            id={`btn-purchasesCategory-${props.category}`}
            type="button"
            className="btn btn-amex btn-lg btn-purchases-list"
            onClick={() => props.onAddExpenseClick(props.category)}
            text={t('purchasesAddPurchaseBtnLabel')}
          />
        </Col>
      </Row>
    </Collapse>
  );

  return (
    <Row className="no-gutters">
      <Col className="purchases-list-item mb-3">
        <Row className="no-gutters mx-3">
          <Col className="col-md-4 col-sm-4 col-xs-4">
            <i className="material-icons expnses-icon">{getExpenseIcon()}</i>
            <div className="purchases-list-item-label">
              <strong>{t(getExpenseLabel())}</strong>
            </div>
          </Col>
        </Row>
        <Row>
          <Col className="text-center pb-3 ">{displayExpenses}</Col>
        </Row>
        <Row>
          <Col className="text-center pb-3 ">{addNewPurchaseButton}</Col>
        </Row>
      </Col>
    </Row>
  );
};

PurchasesCategoryItem.propTypes = {
  expenses: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired,
  category: PropTypes.number.isRequired,
  onAddExpenseClick: PropTypes.func.isRequired,
  onEditeExpenseClick: PropTypes.func.isRequired,
  onRemoveExpenseClick: PropTypes.func.isRequired,
  isEditing: PropTypes.bool.isRequired,
  config: PropTypes.shape({
    REGIONAL_SETTINGS: PropTypes.shape({
      thousandSeparator: PropTypes.string.isRequired,
      decimalSeparator: PropTypes.string.isRequired
    }).isRequired,
    EXPENSE_TYPES: PropTypes.shape({
      label: PropTypes.string,
      icon: PropTypes.arrayOf(PropTypes.string)
    })
  })
};

PurchasesCategoryItem.defaultProps = {
  config: {
    REGIONAL_SETTINGS: {
      thousandSeparator: ',',
      decimalSeparator: '.'
    },
    EXPENSE_TYPES: null
  }
};

export default withConfig(PurchasesCategoryItem);
