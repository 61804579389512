import { object, string, date, ref } from 'yup';
import { REGEX, MARKETS, ERRORS } from 'constants/constants';
import { length, maxLength } from 'constants/inputsLengths';
import moment from 'moment';
import {
  convertSwedishPersonalNumberToDate,
  convertFinnishPersonalNumberToDate,
  convertNorwayPersonalNumberToDate
} from 'utils/convertHelper';
import {
  minLengthPassword,
  commonEmailValidations
} from 'components/shared/CommonSchemaValidations';
import { isValidPersonalNumberNorway } from 'utils/validationHelper';

export const emailValidations = {
  ...commonEmailValidations,
  emailConfirmation: string()
    .required(ERRORS.INVALID_TEXT)
    .oneOf([ref('email'), ''], 'commonFromEmailNotMatchingErrorMsg')
};
const cardNumberValidations = {
  cardNumber: string()
    .required()
    .max(length.cardNumber)
};

export const passwordValidations = {
  password: string()
    .min(minLengthPassword, 'passwordErrorNoMinLength')
    .matches(REGEX.CONTAINS_ONE_LOWER_CASE_LETTER, 'passwordErrorNoLowercase')
    .matches(REGEX.CONTAINS_ONE_SPECIAL_CHAR, 'passwordErrorNoSymbol')
    .matches(REGEX.CONTAINS_ONE_UPPER_CASE_LETTER, 'passwordErrorNoUppercase')
    .matches(REGEX.CONTAINS_ONE_NUMBER, 'passwordErrorNoDigit')
    .required(ERRORS.INVALID_TEXT),
  passwordConfirmation: string()
    .required(ERRORS.INVALID_TEXT)
    .oneOf([ref('password'), ''], 'commonFromPasswordMatchErrorMsg')
};

const isPersonOverAge = value => {
  const today = moment();
  const birthDate = moment(value);
  const diff = today.diff(birthDate, 'year');
  return diff >= 18;
};

const titleValidations = {
  title: string().required()
};

const isSwedishPersonalNumberOverAge = value => {
  const birthDate = convertSwedishPersonalNumberToDate(value);
  return isPersonOverAge(birthDate);
};

const isFinnishPersonalNumberOverAge = value => {
  const birthDate = convertFinnishPersonalNumberToDate(value);
  return isPersonOverAge(birthDate);
};

const isNorwayPersonalNumberOverAge = value => {
  const birthDate = convertNorwayPersonalNumberToDate(value);
  return isPersonOverAge(birthDate);
};

export const swedishPersonalNumberValidations = {
  personalNumber: string()
    .min(length.personalNumber, 'personalNumberMinLengthError')
    .required(ERRORS.INVALID_TEXT)
    .typeError(ERRORS.INVALID_TEXT)
    .matches(
      REGEX.SWEDISH_PERSONALNUMBER,
      'commonFormPersonalNumberValidErrorMsg'
    )
    .test(
      'form-personalNumber',
      ERRORS.DOB_YOUNGER,
      isSwedishPersonalNumberOverAge
    )
};

export const finnishPersonalNumberValidations = {
  personalNumber: string()
    .min(length.personalNumber, 'personalNumberMinLengthError')
    .required(ERRORS.INVALID_TEXT)
    .typeError(ERRORS.INVALID_TEXT)
    .matches(
      REGEX.FINNISH_PERSONAL_NUMBER,
      'commonFormPersonalNumberValidErrorMsg'
    )
    .test(
      'form-personalNumber',
      ERRORS.DOB_YOUNGER,
      isFinnishPersonalNumberOverAge
    )
};

export const norwayPersonalNumberValidations = {
  personalNumber: string()
    .min(length.personalNumber, 'personalNumberMinLengthError')
    .required(ERRORS.INVALID_TEXT)
    .typeError(ERRORS.INVALID_TEXT)
    .test(
      'Test personalNumber valid',
      ERRORS.PERSONAL_NUMBER_INCORRECT,
      isValidPersonalNumberNorway
    )
    .test(
      'Test personalNumber is Adult',
      ERRORS.DOB_YOUNGER,
      isNorwayPersonalNumberOverAge
    )
};

export const emailSchemaValidations = object({ ...emailValidations });
export const passwordSchemaValidations = object({
  ...passwordValidations
});

export const getPersonalNumberSchema = market => {
  switch (market) {
    case MARKETS.AMEX_SE:
      return swedishPersonalNumberValidations;
    case MARKETS.AMEX_FI:
      return finnishPersonalNumberValidations;
    case MARKETS.AMEX_NO:
      return norwayPersonalNumberValidations;
    default:
      return null;
  }
};

/**
 *
 * @param {boolean} isBodiam Determines if the form should validate credit card
 * @param {boolean} market Determines if form should ignore title validation and if should validate personal number
 * @returns {Object} returns the set of fields yup should validate
 */
export const getSchemaValidation = (isBodiam, market) =>
  object({
    firstName: string()
      .max(maxLength.firstName)
      .required(ERRORS.INVALID_TEXT)
      .typeError(ERRORS.INVALID_TEXT),
    surname: string()
      .max(maxLength.surnames)
      .required(ERRORS.INVALID_TEXT)
      .typeError(ERRORS.INVALID_TEXT),
    dateOfBirth: date()
      .transform(rawValue => moment(rawValue).toDate())
      .required(ERRORS.INVALID_TEXT)
      .typeError(ERRORS.DOB_INCORRECT)
      .test('form-birthday', ERRORS.DOB_YOUNGER, isPersonOverAge),
    phone: string()
      .max(maxLength.phone)
      .matches(REGEX.VALIDATE_PHONE_NUMBER, 'commonFormPhoneValidErrorMsg')
      .required(ERRORS.INVALID_TEXT)
      .typeError(ERRORS.INVALID_TEXT),
    address: string()
      .max(maxLength.address)
      .required(ERRORS.INVALID_TEXT)
      .typeError(ERRORS.INVALID_TEXT),
    addressNumber: string().max(maxLength.addressNumber),
    addressPC: string()
      .max(maxLength.addressPC)
      .required(ERRORS.INVALID_TEXT)
      .typeError(ERRORS.INVALID_TEXT),
    addressCity: string()
      .max(maxLength.addressCity)
      .required(ERRORS.INVALID_TEXT)
      .typeError(ERRORS.INVALID_TEXT),
    ...emailValidations,
    ...passwordValidations,
    ...(market === MARKETS.AMEX_SE ||
    market === MARKETS.AMEX_FI ||
    market === MARKETS.AMEX_NO
      ? getPersonalNumberSchema(market)
      : titleValidations),
    ...(isBodiam ? cardNumberValidations : {})
  });
