import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { LANGUAGE_ID_LOCALES } from 'constants/constants';
import ReactDatePicker from 'react-date-picker';
import HelpText from './HelpText';
import Label from './Label';
import LabelFor from './LabelFor';
import ToolTipButton from './ToolTipButton';
import useDatePicker from '../CustomHooks/useDate';
import './_DateField.scss';

const DateField = props => {
  const { handleChangeDate, dateValue, updateState } = useDatePicker(
    props.onValueChange
  );
  const { t } = useTranslation();
  const languageId = useSelector(
    state => state.marketLanguages.selectedLanguage
  );
  const locale = LANGUAGE_ID_LOCALES[languageId];
  const isInvalid =
    props.errorFields[props.name] && props.errorFields[props.name].hasError;
  const getClass = base =>
    // eslint-disable-next-line no-nested-ternary
    `${base}  ${isInvalid ? 'is-invalid' : props.value ? 'is-valid' : ''}`;

  const handleOnChange = e => {
    props.onValidate(props.name, e);
    handleChangeDate(e);
  };

  useEffect(() => {
    updateState(props.value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.value]);

  return (
    <div className={getClass('form-group')}>
      <LabelFor
        labelRef={props.dateRef}
        name={props.name}
        className="col col-md-12 col-lg-12 px-0"
      >
        <Label text={props.label} isRequired={props.required} />
        <ToolTipButton text={props.toolTipText} />
        {props.helpText && <HelpText name={props.name} text={props.helpText} />}
        <ReactDatePicker
          id={`${props.id}-dateInput`}
          name={props.name}
          calendarType="US"
          className={getClass('form-control')}
          disabled={props.disabled}
          clearIcon={null}
          value={dateValue}
          onChange={handleOnChange}
          locale={locale}
          minDate={props.minDate}
          dayPlaceholder="DD"
          monthPlaceholder="MM"
          yearPlaceholder="YYYY"
          calendarIcon={null}
        />
        {isInvalid && (
          <div className="invalid-feedback">
            {t(props.errorFields[props.name].message)}
          </div>
        )}
      </LabelFor>
    </div>
  );
};

DateField.propTypes = {
  id: PropTypes.string,
  dateRef: PropTypes.shape({}),
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  value: PropTypes.string,
  onValueChange: PropTypes.func,
  helpText: PropTypes.string,
  toolTipText: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  errorFields: PropTypes.shape({
    hasError: PropTypes.bool,
    message: PropTypes.string
  }),
  onValidate: PropTypes.func,
  minDate: PropTypes.instanceOf(Date)
};

DateField.defaultProps = {
  id: `input-dateField`,
  dateRef: null,
  label: '',
  value: undefined,
  helpText: '',
  toolTipText: '',
  disabled: false,
  required: false,
  errorFields: {},
  onValidate: () => {},
  onValueChange: () => {},
  minDate: null
};

export default DateField;
