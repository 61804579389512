// TODO: technical debts
/* eslint-disable jsx-a11y/iframe-has-title */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import {
  ATTESTATION_CARDS,
  MAP_ATTESTATIONS,
  ATTESTATIONS_ENV,
  ATTESTATIONS_TITLE,
  PROD_NOMATCH
} from 'constants/attestationMapping';
import PageHeader from 'Bodiam/components/PageHeader';
import { getActiveCard } from 'store/selectors/activeCard';

const ATTESTATIONS_URL = {
  amexUK: process.env.REACT_APP_ATTESTATIONS_UK,
  amexFR: process.env.REACT_APP_ATTESTATIONS_FR,
  amexDE: process.env.REACT_APP_ATTESTATIONS_DE,
  amexAT: process.env.REACT_APP_ATTESTATIONS_AT
};
const ENV = process.env.REACT_APP_ATTESTATIONS_ENV || ATTESTATIONS_ENV.UAT;

const isAnInvalidCard = card => !card || card === PROD_NOMATCH;

export const CertificatePage = props => {
  const product = `${props.activeCard.amexMarket}${props.activeCard.amexCode}`;

  const info = props.userInfo;
  let content = (
    <span> Loading Attestations site with user information... </span>
  );

  if (!isEmpty(info) && !isEmpty(props.activeCard)) {
    const market = `${info.marketURL}`;
    const card = ATTESTATION_CARDS[market][ENV][MAP_ATTESTATIONS[product]];
    if (isAnInvalidCard(card)) {
      content = (
        <div className="text-center certificates-forbidden">
          <span>{props.labels.certificateCantRetrieveCertificate}</span>
        </div>
      );
    } else {
      const title = ATTESTATIONS_TITLE[market][ENV][info.title];
      const url = `${ATTESTATIONS_URL[market]}iframe?card=${card}&name=${info.name}&surname=${info.surname}&email=${info.email}&title=${title}`;
      content = <iframe src={url} className="iframe" />;
    }
  }
  return (
    <>
      <PageHeader
        title={props.labels.certificatePageTitle}
        productImage={props.activeCard.imageURL}
      />
      <div className="px-2 px-md-4">{content}</div>
    </>
  );
};

export const mapStateToProps = state => ({
  labels: state.labels,
  activeCard: getActiveCard(state),
  userInfo: state.userInfo
});

CertificatePage.propTypes = {
  labels: PropTypes.shape({
    certificatePageTitle: PropTypes.string,
    certificateCantRetrieveCertificate: PropTypes.string
  }).isRequired,
  activeCard: PropTypes.shape({
    amexCode: PropTypes.string,
    amexMarket: PropTypes.string,
    imageURL: PropTypes.string
  }).isRequired,
  userInfo: PropTypes.shape({
    title: PropTypes.string,
    name: PropTypes.string,
    surname: PropTypes.string,
    email: PropTypes.string,
    marketURL: PropTypes.string
  }).isRequired
};

export default connect(mapStateToProps)(CertificatePage);
