import React, { useState } from 'react';
import PropTypes from 'prop-types';

import UploadDocument from 'components/ContactsQuestion/Shared/UploadDocument';
import { isFileIdValid } from 'components/ContactsQuestion/AffectedPerson/AffectedPeopleUtils';
import AffectedPerson from './AffectedPerson';

const AffectedPersonMedicalDeathCertificate = props => {
  const person = {
    id: props.person.id,
    typeObjectId: props.person.typeObjectId,
    isValid: props.person.isValid,
    payload: {
      ...props.person.payload,
      medicalCertificate: props.person.payload.medicalCertificate,
      deathCertificate: props.person.payload.deathCertificate
    }
  };

  const [formValues, setFormValues] = useState(person);

  const handleFieldFormValueChange = (field, value) => {
    const clone = {
      ...formValues,
      payload: { ...formValues.payload, [field]: value }
    };
    validateAndUpdate(clone);
  };

  const validateAndUpdate = values => {
    setFormValues(values);

    const clone = { ...values, isValid: values.isValid && isFormValid(values) };
    props.onUpdateContact(clone);
  };

  const isFormValid = values =>
    values.isValid &&
    isFileIdValid(values.payload.deathCertificate) &&
    isFileIdValid(values.payload.medicalCertificate);

  const handleOnUpdateContactBase = values => {
    const clone = {
      ...formValues,
      ...values,
      payload: { ...formValues.payload, ...values.payload }
    };
    validateAndUpdate(clone);
  };

  return (
    <>
      <AffectedPerson
        person={props.person}
        isRemovable={props.isRemovable}
        onRemoveContact={props.onRemoveContact}
        onUpdateContact={handleOnUpdateContactBase}
      />
      <UploadDocument
        id={`medical${props.person.id}`}
        label="contactsHasMedical"
        isMedical
        showHelp
        value={props.person.payload.medicalCertificate}
        claimId={props.claimId}
        benefitId={props.benefitId}
        onChange={value =>
          handleFieldFormValueChange('medicalCertificate', value)
        }
      />
      <UploadDocument
        id={`death${props.person.id}`}
        label="contactsHasDeath"
        isMedical={false}
        showHelp={false}
        value={props.person.payload.deathCertificate}
        claimId={props.claimId}
        benefitId={props.benefitId}
        onChange={value =>
          handleFieldFormValueChange('deathCertificate', value)
        }
      />
    </>
  );
};

AffectedPersonMedicalDeathCertificate.propTypes = {
  person: PropTypes.shape({
    id: PropTypes.string,
    typeObjectId: PropTypes.number.isRequired,
    isValid: PropTypes.bool.isRequired,
    payload: PropTypes.shape({
      deathCertificate: PropTypes.string,
      medicalCertificate: PropTypes.string
    })
  }).isRequired,
  isRemovable: PropTypes.bool,
  onUpdateContact: PropTypes.func.isRequired,
  onRemoveContact: PropTypes.func.isRequired,
  claimId: PropTypes.string.isRequired,
  benefitId: PropTypes.number.isRequired
};
AffectedPersonMedicalDeathCertificate.defaultProps = {
  isRemovable: false
};

export default AffectedPersonMedicalDeathCertificate;
