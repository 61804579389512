import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { GA_EVENTS, GA_ACTIONS } from 'constants/googleAnalytics';
import { event } from 'utils/analytics';
import { validateField, validateForm } from 'utils/formValidationHelper';

import TitleSelectionGroup from 'components/TitleSelectionGroup';
import {
  Form,
  TextField,
  TextFieldButton,
  DateField,
  PhoneField,
  Button
} from 'components/shared/Forms';
import { Row, Col } from 'components/shared/Table';
import {
  schemaValidation,
  maxLengthConstants
} from './EditProfileSchemaValidation';
 
import { appInsights } from 'ApplicationInsights';

const EditProfileComponent = props => {
  const [errorFields, setErrorFields] = useState({});
  const [formValues, setFormValues] = useState({});

  useEffect(() => {
    setFormValues({ ...props.userInfo });
  }, [props.userInfo]);

  const { t } = useTranslation();

  const handleValueChange = e => {
    const newFormValues = {
      ...formValues,
      [e.target.name]: e.target.value
    };
    setFormValues(newFormValues);
  };

  /**
   * @param {string} selected
   */
  const handleOnChangeTitle = selected => {
    setFormValues({ ...formValues, title: selected });
  };

  const handleSaveProfile = () => {
    const result = validateForm(schemaValidation, formValues);
    if (!result.isValid) {
      setErrorFields(result.errorFields);
    } else {
      event(GA_EVENTS.PROFILE, GA_ACTIONS.SUBMIT);
      appInsights.trackEvent(
        {
          name:'Save-Profile'
        },
        {
          EventCategory: GA_EVENTS.PROFILE,
          EventAction: GA_ACTIONS.SUBMIT,
          EventLabel: 'Save-Profile'
        }
        )
      props.handleUpdate(formValues, t('commonProfileUpdated'));
      props.toggleEditProfile();
    }
  };

  const handleOnCancelProfile = () => {
    event(GA_EVENTS.PROFILE, GA_ACTIONS.CANCEL);
    appInsights.trackEvent(
      {
        name:'Cancel-Profile'
      },
      {
        EventCategory: GA_EVENTS.PROFILE,
        EventAction: GA_ACTIONS.CANCEL,
        EventLabel: 'Cancel-Profile'
      }
      )
    props.toggleEditProfile();
  };

  const handleValidation = (fieldName, value) => {
    const result = validateField(schemaValidation, fieldName, value);
    setErrorFields({
      ...errorFields,
      ...result.errorFields
    });
  };

  return (
    <Row>
      <Col>
        <Form>
          <Row className="form-group form-check form-check-inline">
            <TitleSelectionGroup
              availableTitles={props.availableTitles}
              handleTitleSelection={handleOnChangeTitle}
              selectedTitle={formValues.title}
              classNames={{
                labelWrapper: 'label-input col-12'
              }}
            />
          </Row>
          <Row>
            <Col className="col-12 col-md-6">
              <TextField
                label={t('commonFormUserNameLabel')}
                name="name"
                value={formValues.name}
                ariaDescribedby={t('commonFormUserNameLabel')}
                required
                disabled
              />
            </Col>
            <Col className="col-12 col-md-6">
              <TextField
                label={t('commonFormSurnameLabel')}
                name="surname"
                value={formValues.surname}
                ariaDescribedby={t('commonFormSurnamePlaceholder')}
                required
                disabled
              />
            </Col>
          </Row>
          <Row>
            {props.personalNumber.enabled && (
              <Col className="col-12 col-md-6">
                <TextField
                  value={formValues.personalNumber}
                  name="personalNumber"
                  label={t('commonFormPersonalNumberLabel')}
                  ariaDescribedby={t('commonFormPersonalNumberPlaceholder')}
                  required
                  disabled
                />
              </Col>
            )}
            <Col className="col-12 col-md-6">
              <DateField
                label={t('commonFormDateLabel')}
                name="dateOfBirth"
                value={formValues.dateOfBirth}
                errorFields={errorFields}
                ariaDescribedby={t('commonFormDatePlaceholder')}
                required
                disabled
              />
            </Col>
          </Row>
          <Row>
            <Col className="col-12 col-md-6">
              <PhoneField
                name="phone"
                label={t('commonFormPhoneLabel')}
                value={formValues.phone}
                onValueChange={handleValueChange}
                onValidate={handleValidation}
                errorFields={errorFields}
                required
              />
            </Col>
            <Col className="col-12 col-md-6">
              <TextFieldButton
                label={t('commonFromEmailLabel')}
                labelLink={t('commonEdit')}
                name="email"
                value={formValues.email}
                errorFields={errorFields}
                ariaDescribedby={t('commonFromEmailPlaceholder')}
                required
                disabled
                buttonAction={props.handleUpdateEmail}
              />
            </Col>
          </Row>
          <Row>
            <Col className="col-12 col-md-6">
              <TextField
                label={t('commonFromPostCodeLabel')}
                name="addressPC"
                value={formValues.addressPC}
                onValueChange={handleValueChange}
                errorFields={errorFields}
                ariaDescribedby={t('commonFromPostCodePlaceholder')}
                required
                onValidate={handleValidation}
                maxLength={maxLengthConstants.addressPC}
              />
            </Col>
            <Col className="col-12 col-md-6">
              <TextField
                label={t('commonFromCityLabel')}
                name="addressCity"
                value={formValues.addressCity}
                onValueChange={handleValueChange}
                errorFields={errorFields}
                ariaDescribedby={t('commonFromCityPlaceholder')}
                required
                onValidate={handleValidation}
                maxLength={maxLengthConstants.addressCity}
              />
            </Col>
          </Row>
          <Row>
            <Col className="col-12 col-md-6">
              <TextField
                label={t('commonFormAddressLabel')}
                name="address"
                value={formValues.address}
                onValueChange={handleValueChange}
                errorFields={errorFields}
                ariaDescribedby={t('commonFormAddressPlaceholder')}
                required
                onValidate={handleValidation}
                maxLength={maxLengthConstants.address}
              />
            </Col>
            <Col className="col-12 col-md-6">
              <TextField
                label={t('commonAddressNumber')}
                name="addressNumber"
                value={formValues.addressNumber}
                onValueChange={handleValueChange}
                errorFields={errorFields}
                ariaDescribedby={t('commonFormAddressNumberPlaceholder')}
                onValidate={handleValidation}
                maxLength={maxLengthConstants.addressNumber}
              />
            </Col>
          </Row>
        </Form>
        <Row>
          <Col className="col-md-4 col-sm-12 text-center mx-auto">
            <Button
              type="button"
              className="btn btn-primary btn-large btn-block btn-amex mt-3"
              onClick={handleOnCancelProfile}
              text={t('commonCancel')}
            />
          </Col>
          <Col className="col-md-4 col-sm-12 text-center mx-auto">
            <Button
              type="button"
              className="btn btn-primary btn-large btn-block btn-amex mt-3"
              onClick={handleSaveProfile}
              text={t('commonUpdate')}
            />
          </Col>
        </Row>
        <Row>
          <Col />
        </Row>
      </Col>
    </Row>
  );
};

EditProfileComponent.propTypes = {
  userInfo: PropTypes.shape({
    title: PropTypes.string.isRequired
  }).isRequired,
  handleUpdate: PropTypes.func.isRequired,
  toggleEditProfile: PropTypes.func.isRequired,
  availableTitles: PropTypes.shape({
    enabled: PropTypes.bool.isRequired,
    values: PropTypes.arrayOf(PropTypes.string)
  }).isRequired,
  personalNumber: PropTypes.shape({
    enabled: PropTypes.bool.isRequired,
    maxLenght: PropTypes.number.isRequired
  }).isRequired,
  handleUpdateEmail: PropTypes.func.isRequired
};

EditProfileComponent.defaultProps = {};

export default EditProfileComponent;
