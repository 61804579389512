import {
  VALIDATE_BENEFITS_CARD_REQUESTED,
  BENEFITS_CARD_REQUESTED,
  BENEFITS_CARD_SUCCEEDED,
  BENEFITS_CARD_FAILED,
  GENERATE_BENEFITS_FILE,
  transformNumberAndValidate,
  setCardBeneftis,
  GENERATE_BENEFITS_FILE_SUCCESS,
  GENERATE_BENEFITS_FILE_FAIL
} from 'actions/feature/card';
import { goTo } from 'actions/core/app.navigation';
import { setError } from 'actions/core/error';
import { GET_CARDS, DOWNLOAD_BENEFIT_FILE } from 'api/apiRoutes';
import { apiRequest } from 'actions/core/api';
import { hideSpinner, showSpinner } from 'actions/core/ui';
import { GENERATE_CERTIFICATE_SUCCESS } from 'constants/constants';

export const validateAndGetBenefitsCardFlow = ({
  dispatch
}) => next => action => {
  next(action);

  if (action.type === VALIDATE_BENEFITS_CARD_REQUESTED) {
    dispatch(
      transformNumberAndValidate(
        { ...action.payload },
        BENEFITS_CARD_REQUESTED,
        null,
        action.meta
      )
    );
  }
};

export const beneftisCardFlow = ({ dispatch }) => next => action => {
  next(action);

  if (action.type === BENEFITS_CARD_REQUESTED) {
    dispatch(showSpinner());
    dispatch(
      apiRequest(
        'GET',
        `${GET_CARDS}/${action.payload.productId}/benefits`,
        {},
        {},
        {
          ...action.meta,
          productId:action.payload?.productId
        },
        BENEFITS_CARD_SUCCEEDED,
        BENEFITS_CARD_FAILED
      )
    );
  }
};

export const beneftisCardFlowSuccess = ({ dispatch }) => next => action => {
  next(action);

  if (action.type === BENEFITS_CARD_SUCCEEDED) {
    dispatch(setCardBeneftis(action.payload,action.meta?.productId));
    dispatch(hideSpinner());
  }
};

export const beneftisCardFlowFail = ({ dispatch }) => next => action => {
  next(action);

  if (action.type === BENEFITS_CARD_FAILED) {
    dispatch(setError(action.payload));
    dispatch(hideSpinner());
  }
};

export const downloadReport = ({ dispatch }) => next => action => {
  next(action);

  if (action.type === GENERATE_BENEFITS_FILE) {
    dispatch(showSpinner());
    dispatch(
      apiRequest(
        'POST',
        `${DOWNLOAD_BENEFIT_FILE}/${action.payload.languageId}/${action.payload.marketUrl}`,
        {},
        {
          ...action.payload.formValues
        },
        {
          ...action.meta
        },
        GENERATE_BENEFITS_FILE_SUCCESS,
        GENERATE_BENEFITS_FILE_FAIL
      )
    );
  }
};

export const generateCertificateSuccess = ({ dispatch }) => next => action => {
  next(action);

  if (action.type === GENERATE_BENEFITS_FILE_SUCCESS) {
    dispatch(hideSpinner());
    dispatch(goTo(GENERATE_CERTIFICATE_SUCCESS));
  }
};

export const generateCertificateFail = ({ dispatch }) => next => action => {
  next(action);

  if (action.type === GENERATE_BENEFITS_FILE_FAIL) {
    dispatch(hideSpinner());
    dispatch(setError(action.payload));
  }
};

export default [
  validateAndGetBenefitsCardFlow,
  beneftisCardFlow,
  beneftisCardFlowSuccess,
  beneftisCardFlowFail,
  downloadReport,
  generateCertificateSuccess,
  generateCertificateFail
];
