import { ALERT_TYPES } from 'constants/constants';

export const SHOW_SPINNER = '[ui] show spinner';
export const HIDE_SPINNER = '[ui] hide spinner';

export const SET_SUCCESS_ALERT = '[ui] show success alert';
export const SET_ERROR_ALERT = '[ui] show error alert';
export const SET_WARNING_ALERT = '[ui] show warning alert';

export const showSpinner = () => ({
  type: SHOW_SPINNER
});

export const hideSpinner = () => ({
  type: HIDE_SPINNER
});

export const notifySuccess = (code, message = undefined) => ({
  type: SET_SUCCESS_ALERT,
  payload: { type: ALERT_TYPES.SUCCESS, code, msg: message }
});

export const notifyError = (code, message = undefined) => ({
  type: SET_ERROR_ALERT,
  payload: { type: ALERT_TYPES.ERROR, code, msg: message }
});

export const notifyWarning = (code, message = undefined) => ({
  type: SET_WARNING_ALERT,
  payload: { type: ALERT_TYPES.WARNING, code, msg: message }
});
