import TitleSelectionGroup from './TitleSelectionGroup';

export const getTitleLabels = labels => {
  const {
    BeneficiaryContactFormTitlesMr,
    BeneficiaryContactFormTitlesMrs,
    BeneficiaryContactFormTitlesMs,
    commonFormUserTitleLabel,
    commonSelectTitle
  } = labels;
  return {
    BeneficiaryContactFormTitlesMr,
    BeneficiaryContactFormTitlesMrs,
    BeneficiaryContactFormTitlesMs,
    commonFormUserTitleLabel,
    commonSelectTitle
  };
};
export default TitleSelectionGroup;
