import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Slide from '@material-ui/core/Slide';
import { useTranslation } from 'react-i18next';
import { REGEX } from 'constants/constants';
import { Row, Col } from 'components/shared/Table';
import { Button as SharedButton, TextField } from 'components/shared/Forms';
import { makeRecaptchaVisible } from 'utils/recaptcha/core';

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

const AddCardComponent = props => {
  const [openDialog, setOpenDialog] = useState(false);
  const [cardNumber, setCardNumber] = useState('');

  const { t } = useTranslation();

  useEffect(() => {
    makeRecaptchaVisible(true);
    return () => makeRecaptchaVisible(false);
  }, []);
  
  // It's a hack to go to My claim page, because it's handling all by state
  useEffect(() => {
    if (props.cardValidation) {
      if (props.cardValidation.isValid) {
        props.onCleanCardInformation();
        props.toggleMethod();
      } else {
        setOpenDialog(true);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.cardValidation]);

  const handleCreditCardInput = event => {
    event.preventDefault();
    const userInput = event.target.value;
    validateCCNumber(event.target.name, userInput);
  };

  const validateCCNumber = (name, value) => {
    const digitsOnly = value.match(/\d+/g);
    const newInput = digitsOnly ? digitsOnly[0] : '';

    setCardNumber(newInput);
  };

  const handleClose = () => {
    setOpenDialog(false);
    props.onCleanCardInformation();
  };

  const handleSubmit = () => props.addUserCard(cardNumber);

  const isAcceptableCardNumber = numberField =>
    REGEX.AMEX_CARD.test(String(numberField).toLowerCase());

  const buttonClass = isAcceptableCardNumber(cardNumber)
    ? 'btn-primary btn-amex'
    : 'btn-disabled';

  const validationDialog = () =>
    !props.cardValidation.isValid && (
      <Dialog
        open={openDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {t(props.cardValidation.message)}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {t('commonClose')}
          </Button>
        </DialogActions>
      </Dialog>
    );

  return (
    <>
      <Row>
        <Col>
          <Row className="pt-3">
            <Col className="col-md-1" />
            <Col className="col-12 col-xl-5 d-flex justify-content-center d-xl-block">
              <img
                src="assets/img/american-express-blank.png"
                alt=""
                className="cc-image"
              />
            </Col>
            <Col className="col-12 col-xl-5 mt-4 mt-xl-0 add-card-input">
            <TextField
                label={t('commonCardNumberTitle')}
                name="cardNumber"
                value={cardNumber}
                onValueChange={handleCreditCardInput}
                ariaDescribedby={t('commonCardNumberPlaceHolder')}
                placeholder={t('commonCardNumberPlaceHolder')}
                required
                maxLength={15}
             />
            </Col>
            <Col className="col-md-1" />
          </Row>

          <Row className="mt-2 mt-xl-5">
            <Col className="col-md-4" />
            <Col className="text-center">
              <SharedButton
                type="button"
                className={`btn ${buttonClass} btn-large btn-block  mt-3 `}
                onClick={handleSubmit}
                text={t('commonAdd')}
              />
            </Col>
            <Col className="col-md-4" />
          </Row>
        </Col>
      </Row>
      {props.cardValidation && validationDialog()}
    </>
  );
};

AddCardComponent.propTypes = {
  cardValidation: PropTypes.shape({
    isValid: PropTypes.bool,
    message: PropTypes.string
  }),
  toggleMethod: PropTypes.func.isRequired,
  addUserCard: PropTypes.func.isRequired,
  onCleanCardInformation: PropTypes.func.isRequired
};
AddCardComponent.defaultProps = {
  cardValidation: null
};

export default AddCardComponent;
