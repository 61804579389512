import React from 'react';
import PropTypes from 'prop-types';

import { getMarketFromUrl } from 'utils/urlHelpers';
import history from 'utils/history';
import { BODIAM_BASE_URL } from 'constants/constants';

const FaqLinks = props => {
  const market = getMarketFromUrl();

  const handleOnClick = link => () => {
    history.push(link);
  };

  const submenuItems = [
    {
      label: 'navbarCoverWho',
      link: `${BODIAM_BASE_URL}${market}/your-cover/who`,
      icon: 'person'
    },
    {
      label: 'navbarCoverWhat',
      link: `${BODIAM_BASE_URL}${market}/your-cover/what`,
      icon: 'work'
    },
    {
      label: 'navbarCoverProcess',
      link: `${BODIAM_BASE_URL}${market}/your-cover/claim-process`,
      icon: 'settings'
    },
    {
      label: 'navbarCoverPrivacy',
      link: `${BODIAM_BASE_URL}${market}/your-cover/privacy`,
      icon: 'lock'
    },
    {
      label: 'navbarCoverComplaint',
      link: `${BODIAM_BASE_URL}${market}/your-cover/complain`,
      icon: 'mail'
    }
  ];

  return (
    <div className="your-policy-faqs pt-4">
      <h6 className="further-questions-header">
        {props.labels.bodiamFurtherPolicyQuestions}
      </h6>
      {submenuItems.map((item, key) => (
        <div
          className="faqItem p-2 pb-0"
          onClick={handleOnClick(item.link)}
          onKeyUp={handleOnClick(item.link)}
          role="button"
          tabIndex={key}
          key={item.link}
        >
          <i className="material-icons d-inline-block my-auto">{item.icon}</i>
          <span className="d-inline my-auto">{props.labels[item.label]}</span>
        </div>
      ))}
    </div>
  );
};

FaqLinks.props = {
  labels: PropTypes.shape({
    bodiamFurtherPolicyQuestions: PropTypes.string.isRequired,
    navbarCoverWho: PropTypes.string.isRequired,
    navbarCoverWhat: PropTypes.string.isRequired,
    navbarCoverProcess: PropTypes.string.isRequired,
    navbarCoverPrivacy: PropTypes.string.isRequired,
    navbarCoverComplaint: PropTypes.string.isRequired
  }).isRequired
};

export default FaqLinks;
