import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { pageview, event } from 'utils/analytics';
import { getLanguageIsoCode } from 'utils/languageUtils';
import { getRouteForClaim } from 'utils/routeHelper';

import { userUpdateProfile } from 'actions/authenticationActions';
import { updatePhoneUser } from 'actions/feature/user';
import {
  gotoMyClaims,
  gotoLandingPage,
  gotoRegistrationPage,
  gotoCookiesPage,
  gotoFAQPage,
  gotoContactsPage,
  updateActivePage
} from 'actions/activePageActions';
import {
  updateCurrentStep,
  submitClaim,
  updateClaimData,
  updateClaimStep
} from 'actions/claimActions';
import { addUserInfo } from 'actions/userInfoActions';
import { setNotInTriage } from 'actions/triageStatusActions';
import { saveClaim, saveClaimAndDecline } from 'actions/feature/claim';

import LayoutPage from 'components/LayoutPage';
import TaskList from 'components/TaskList/index';
import Review from 'components/Review';
import ThankYou from 'components/ThankYou/ThankYou';

import WhatHappened from 'containers/WhatHappened';
import DatesAndTimesPage from 'containers/DatesAndTimesPage';
import ExpensesPage from 'containers/ExpensesPage';
import PreviousInsurances from 'containers/PreviousInsurances';
import BankData from 'containers/BankData';
import { appInsights } from 'ApplicationInsights';

import {
  WHAT_HAPPENED,
  TASKLIST,
  DATES,
  EXPENSES,
  REVIEW,
  PREVIOUS,
  BANK,
  THANKS
} from 'constants/constants';

import {
  MAX_STEP,
  BANK_STEP,
  REVIEW_STEP,
  TASKLIST_STEP,
  THANKS_STEP,
  WHAT_HAPPENED_STEP,
  EXPENSES_STEP,
  DATES_STEP,
  PREVIOUS_STEP,
  PAGE_BY_STEP_ID
} from 'constants/claim';

import { GA_EVENTS, GA_ACTIONS } from 'constants/googleAnalytics';

export const findCurrentStep = (state, step) => {
  if (state && state.lastStep === MAX_STEP && step <= MAX_STEP) {
    if (step < state.step) {
      return {
        ...state,
        step
      };
    }
    return {
      lastStep: MAX_STEP,
      step: MAX_STEP
    };
  }
  return {
    lastStep: step,
    step
  };
};
export class ClaimWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeSection: ''
    };

    this.setActivePage = this.setActivePage.bind(this);
    this.setDisplaySection = this.setDisplaySection.bind(this);
    this.onUserPhoneNumberChange = this.onUserPhoneNumberChange.bind(this);
    this.onAddComment = this.onAddComment.bind(this);
    this.onSubmitClaim = this.onSubmitClaim.bind(this);
    this.handleOnClaimDecline = this.handleOnClaimDecline.bind(this);
    this.handleOnSaveClaim = this.handleOnSaveClaim.bind(this);
    this.layoutBaseProps = this.layoutBaseProps.bind(this);
    this.getNewUserInfo = this.getNewUserInfo.bind(this);
  }

  componentDidMount() {
    this.props.actions.setNotInTriage();
  }

  onUserPhoneNumberChange(userInfo) {
    const newUserInfo = this.getNewUserInfo(userInfo);
    this.props.actions.updatePhoneUser(newUserInfo);
  }

  onAddComment(comments) {
    event(GA_EVENTS.CLAIMWIZARD, GA_ACTIONS.ADD, { label: 'notes' });
    const newClaim = {
      notes: comments
    };
    appInsights.trackEvent(
      {
        name:'notes'
      },
      {
        EventCategory: GA_EVENTS.CLAIMWIZARD,
        EventAction: GA_ACTIONS.ADD,
        EventLabel: 'notes'
      }
      )
    this.props.actions.updateClaimData(newClaim);
  }

  onSubmitClaim() {
    event(GA_EVENTS.CLAIMWIZARD, GA_ACTIONS.CLICK, { label: 'submitClaim' });

    appInsights.trackEvent(
      {
        name:'submitClaim'
      },
      {
        EventCategory: GA_EVENTS.CLAIMWIZARD,
        EventAction: GA_ACTIONS.CLICK,
        EventLabel: 'submitClaim'
      }
      )

    this.props.actions.updateClaimStep(THANKS_STEP);
    this.props.actions.submitClaim(this.props.claim.id);
  }

  getNewUserInfo(userInfo) {
    return { ...this.props.userInfo, ...userInfo };
  }

  setActivePage(step) {
    const myStep = findCurrentStep(this.props.currentStep, step);
    this.props.actions.updateCurrentStep(myStep);
    switch (myStep.step) {
      case TASKLIST_STEP:
        this.props.actions.updateActivePage(TASKLIST);
        break;
      case WHAT_HAPPENED_STEP:
        this.props.actions.updateActivePage(WHAT_HAPPENED);
        break;
      case DATES_STEP:
        this.props.actions.updateActivePage(DATES);
        break;
      case EXPENSES_STEP:
        this.props.actions.updateActivePage(EXPENSES);
        break;
      case REVIEW_STEP:
        this.props.actions.updateActivePage(REVIEW);
        break;
      case PREVIOUS_STEP:
        this.props.actions.updateActivePage(PREVIOUS);
        break;
      case BANK_STEP:
        this.props.actions.updateActivePage(BANK);
        break;
      case THANKS_STEP:
        this.props.actions.updateActivePage(THANKS);
        break;
      default:
        this.props.actions.updateActivePage(TASKLIST);
        break;
    }
  }

  setDisplaySection(section) {
    this.setState({ activeSection: section });
  }

  getCurrentStep() {
    let title =
      this.props.claim.step < BANK_STEP
        ? this.props.labels.taskListTitleStart
        : this.props.labels.taskListTitle;
    switch (this.props.activePage) {
      case TASKLIST:
        return (
          <LayoutPage
            {...this.layoutBaseProps()}
            hasBackground={false}
            sidebarIsVisible
            stepsIsVisible
            Title={title}
          >
            <TaskList
              setActivePage={this.setActivePage}
              currentStep={this.props.claim.step}
              labels={this.props.labels}
              benefit={this.props.claim.benefitId}
              productId={this.props.claim.productId}
              language={this.props.language}
              gotoLandingPage={this.props.actions.gotoLandingPage}
            />
          </LayoutPage>
        );
      case WHAT_HAPPENED:
        return (
          <LayoutPage
            {...this.layoutBaseProps()}
            hasBackground
            sidebarIsVisible
            stepsIsVisible
            Title={this.props.labels.stepHappenTitle}
          >
            <WhatHappened />
          </LayoutPage>
        );
      case DATES:
        return (
          <LayoutPage
            {...this.layoutBaseProps()}
            sidebarIsVisible
            stepsIsVisible
            hasBackground={false}
            Title={this.props.labels.stepDatesTitle2}
            isCalander
          >
            <DatesAndTimesPage
              questions={this.props.claim?.trees?.dates?.questions || []}
              language={this.props.languageCode}
            />
          </LayoutPage>
        );
      case EXPENSES:
        title =
          this.state.activeSection && this.state.activeSection === 'purchases'
            ? this.props.labels.stepExpensesPurchases
            : this.props.labels.expensePageTitle;
        return (
          <LayoutPage
            {...this.layoutBaseProps()}
            hasBackground
            sidebarIsVisible
            stepsIsVisible
            Title={title}
          >
            <ExpensesPage
              setActivePage={this.setActivePage}
              typeOfExpenses={this.props.claim.typeOfExpenses}
              setDisplaySection={this.setDisplaySection}
            />
          </LayoutPage>
        );
      case REVIEW:
        return (
          <div>
            <LayoutPage
              {...this.layoutBaseProps()}
              onUserInfoChange={this.onUserPhoneNumberChange}
              hasBackground={false}
              sidebarIsVisible
              userDetailsIsVisible
              stepsIsVisible
              Title={this.props.labels.stepReviewTitle}
              userDetails={this.props.userInfo}
            >
              <Review
                setActivePage={this.setActivePage}
                onAddComment={this.onAddComment}
                onSubmitClaim={this.onSubmitClaim}
                claim={this.props.claim}
                userInfo={this.props.userInfo}
                languageId={this.props.language}
              />
            </LayoutPage>
          </div>
        );
      case PREVIOUS:
        return (
          <LayoutPage
            {...this.layoutBaseProps()}
            hasBackground
            sidebarIsVisible
            stepsIsVisible
            Title={this.props.labels.insurancePageTitle}
          >
            <PreviousInsurances />
          </LayoutPage>
        );
      case BANK:
        return (
          <LayoutPage
            {...this.layoutBaseProps()}
            hasBackground
            sidebarIsVisible
            stepsIsVisible
            Title={this.props.labels.bankPageTitle}
          >
            <BankData />
          </LayoutPage>
        );
      case THANKS:
        pageview({
          title: 'Claim-Wizard',
          page: getRouteForClaim(
            this.props.language,
            this.props.claim.benefitId,
            THANKS_STEP
          )
        });
        appInsights.trackPageView(
          {
            name:'Claim-Wizard',
            uri:getRouteForClaim(
              this.props.language,
              this.props.claim.benefitId,
              THANKS_STEP
            )
          }
        )
        return (
          <LayoutPage
            hasBackground={false}
            fullWidth
            actions={this.props.actions}
          >
            <ThankYou
              claimId={this.props.claim.id}
              userDetails={this.props.userInfo}
              goToHome={this.props.actions.gotoLandingPage}
              goToMyProfile={this.props.actions.gotoMyClaims}
              labels={this.props.labels}
              actions={this.props.actions}
              language={this.props.language}
            />
          </LayoutPage>
        );
      default:
        return <div>{this.props.labels.GenericErrorMessage}</div>;
    }
  }

  layoutBaseProps() {
    return {
      onClaimSave: this.handleOnSaveClaim,
      onClaimDecline: this.handleOnClaimDecline,
      currentStep: this.props.currentStep,
      lastStep: this.props.claim.step,
      claimId: this.props.claim.id,
      actions: this.props.actions
    };
  }

  handleOnClaimDecline() {
    event(GA_EVENTS.CLAIMWIZARD, GA_ACTIONS.DECLINE, {
      label: PAGE_BY_STEP_ID[this.props.claim.step].stepName
    });
    appInsights.trackEvent(
      {
        name:PAGE_BY_STEP_ID[this.props.claim.step].stepName
      },
      {
        EventCategory: GA_EVENTS.CLAIMWIZARD,
        EventAction: GA_ACTIONS.DECLINE,
        EventLabel: PAGE_BY_STEP_ID[this.props.claim.step].stepName
      }
      )
    this.props.actions.saveClaimAndDecline(false);
  }

  handleOnSaveClaim() {
    event(GA_EVENTS.CLAIMWIZARD, GA_ACTIONS.SAVE, {
      label: PAGE_BY_STEP_ID[this.props.claim.step].stepName
    });
    appInsights.trackEvent(
      {
        name:PAGE_BY_STEP_ID[this.props.claim.step].stepName
      },
      {
        EventCategory: GA_EVENTS.CLAIMWIZARD,
        EventAction: GA_ACTIONS.SAVE,
        EventLabel: PAGE_BY_STEP_ID[this.props.claim.step].stepName
      }
      )
     this.props.actions.saveClaim(this.props.claim.step, this.props.claim.benefitId, true);
  }

  render() {
    return <div>{this.getCurrentStep()}</div>;
  }
}

const mapStateToProps = state => ({
  claim: state.currentClaim,
  labels: state.labels,
  userInfo: state.userInfo,
  activePage: state.activePage,
  language: state.marketLanguages.selectedLanguage,
  languageCode: getLanguageIsoCode(state.marketLanguages.selectedLanguage),
  currentStep: state.currentStep
});

const mapDispatchToProps = dispatch => ({
  actions: {
    ...bindActionCreators(
      {
        gotoMyClaims,
        gotoLandingPage,
        gotoRegistrationPage,
        gotoCookiesPage,
        gotoFAQPage,
        gotoContactsPage,
        updateActivePage
      },
      dispatch
    ),
    ...bindActionCreators({ userUpdateProfile }, dispatch),
    ...bindActionCreators({ updatePhoneUser }, dispatch),
    ...bindActionCreators({ addUserInfo }, dispatch),
    ...bindActionCreators({ setNotInTriage }, dispatch),
    ...bindActionCreators(
      {
        updateCurrentStep,
        submitClaim,
        updateClaimData,
        updateClaimStep,
        saveClaim,
        saveClaimAndDecline
      },
      dispatch
    )
  }
});

ClaimWrapper.propTypes = {
  claim: PropTypes.shape({
    id: PropTypes.string.isRequired,
    benefitId: PropTypes.number.isRequired,
    productId: PropTypes.number.isRequired,
    typeOfExpenses: PropTypes.arrayOf(PropTypes.number).isRequired,
    trees: PropTypes.shape({
      dates: PropTypes.shape({
        questions: PropTypes.arrayOf(PropTypes.shape({})).isRequired
      }).isRequired
    }).isRequired,
    bankData: PropTypes.shape({}),
    step: PropTypes.number.isRequired,
    notes: PropTypes.string
  }).isRequired,
  currentStep: PropTypes.shape({
    step: PropTypes.number.isRequired,
    lastStep: PropTypes.number.isRequired
  }).isRequired,
  actions: PropTypes.shape({
    updateCurrentStep: PropTypes.func.isRequired,
    saveClaim: PropTypes.func.isRequired,
    submitClaim: PropTypes.func.isRequired,
    saveClaimAndDecline: PropTypes.func.isRequired,
    gotoRegistrationPage: PropTypes.func.isRequired,
    gotoLandingPage: PropTypes.func.isRequired,
    gotoMyClaims: PropTypes.func.isRequired,
    addUserInfo: PropTypes.func.isRequired,
    updateClaimData: PropTypes.func.isRequired,
    setNotInTriage: PropTypes.func.isRequired,
    updateClaimStep: PropTypes.func.isRequired,
    gotoContactsPage: PropTypes.func.isRequired,
    gotoFAQPage: PropTypes.func.isRequired,
    gotoCookiesPage: PropTypes.func.isRequired,
    userUpdateProfile: PropTypes.func.isRequired,
    updatePhoneUser: PropTypes.func.isRequired,
    updateActivePage: PropTypes.func.isRequired
  }).isRequired,
  labels: PropTypes.shape({
    GenericErrorMessage: PropTypes.string.isRequired,
    bankPageTitle: PropTypes.string.isRequired,
    insurancePageTitle: PropTypes.string.isRequired,
    stepReviewTitle: PropTypes.string.isRequired,
    expensePageTitle: PropTypes.string.isRequired,
    stepDatesTitle2: PropTypes.string.isRequired,
    stepExpensesPurchases: PropTypes.string.isRequired,
    stepDatesTitle: PropTypes.string.isRequired,
    stepHappenTitle: PropTypes.string.isRequired,
    taskListTitle: PropTypes.string.isRequired,
    taskListTitleStart: PropTypes.string.isRequired,
    commonProfileUpdated: PropTypes.string.isRequired
  }).isRequired,
  userInfo: PropTypes.shape({}).isRequired,
  language: PropTypes.string.isRequired,
  languageCode: PropTypes.string.isRequired,
  activePage: PropTypes.string.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(ClaimWrapper);
