import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { goTo } from 'actions/core/app.navigation';
import { cleanCardInformation } from 'actions/feature/card';
import { useTranslation } from 'react-i18next';
import ButtonBack from 'components/shared/Custom/ButtonBack';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import './_GenerateCertificateSuccess.scss';
import { COVER_PAGE } from 'constants/constants';

export const GenerateCertificateSuccess = props => {
  const { t } = useTranslation();

  useEffect(() => {
    props.actions.cleanCardInformation();
  }, []);

  const goToCheckCoverPage = () => {
    props.actions.goTo(COVER_PAGE);
  };
  return (
    <div className="certificate-successs-container">
      <ButtonBack
        onClick={goToCheckCoverPage}
        labelText={t('buttonBackCheckCoverPage')}
      />
      <CheckBoxIcon style={{ fontSize: 50, color: '#0b3283' }} />
      <p> {t('BenefitCertificateSuccessMessage1')}</p>
      <p> {t('BenefitCertificateSuccessMessage2')}</p>
      <p> {t('BenefitCertificateSuccessMessage3')}</p>
    </div>
  );
};

const mapDispatchToProps = dispatch => ({
  actions: {
    ...bindActionCreators({ goTo, cleanCardInformation }, dispatch)
  }
});

GenerateCertificateSuccess.propTypes = {
  actions: PropTypes.shape({
    goTo: PropTypes.func.isRequired,
    cleanCardInformation: PropTypes.func.isRequired
  }).isRequired
};

export default connect(null, mapDispatchToProps)(GenerateCertificateSuccess);
